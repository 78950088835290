<template>
  <div>
    <h3 class="mt-4">
      {{
        $getVisibleNames(
          "manual.administrar_seccion",
          true,
          "Administrar Secciones"
        )
      }}
      <!-- Administrar Secciones -->
    </h3>
    <div class="section-container">
      <!-- :level="level"
        :period_id="period_id"
        :career_id="career_id"
        :campus_id="campus_id"
        :modality_id="modality_id" -->
      <MatterList :allows_crud="allows_crud"></MatterList>
      <!-- @update_section_list="slotUpdateSectionList"
        @changed_egress_profile="slotChangeEgressProfile" -->
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "SectionView",
  components: {
    MatterList: () => import("./MatterList"),
  },
  data() {
    return {
      periods: [],
      sections: [],
      // level: 1,
      // period_id: this.$store.getters.getInstitution.actual_period.id,
      // period_id: 1,
      // career_id: 1,
      // modality_id: 1,
      // campus_id: 1,
    };
  },
  computed: {
    ...mapGetters({
      careers: names.CAREERS,
      cycles: names.CYCLES,
      faculties: names.FACULTIES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("teaching.add_section");
      if (has_permission) return has_permission;
      else return false;
    },
    careerList() {
      return this.careers;
    },
  },
  methods: {
    // fetchPeriods() {
    //   this.$restful
    //     .Get(
    //       `/mesh/period/?institution=${this.$store.getters.getInstitutionId}`
    //     )
    //     .then((response) => {
    //       this.periods = response;
    //     });
    // },
    // slotUpdateSectionList(section_list) {
    //   this.sections = section_list;
    // },
    // slotChangeEgressProfile(egress_profile_id) {
    //   this.selected_egress_profile_id = egress_profile_id;
    // },
  },
  created() {
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_USERS);
    // this.allows_crud = this.$store.getters.hasPermission(
    //   "teaching.add_section"
    // );
    // if (this.$route.query.career_id) {
    //   this.career_id = Number(this.$route.query.career_id);
    // }
    // if (this.$route.query.period_id) {
    //   this.period_id = Number(this.$route.query.period_id);
    // }
    // if (this.$route.query.modality_id) {
    //   this.modality_id = Number(this.$route.query.modality_id);
    // }
    // if (this.$route.query.level) {
    //   this.level = Number(this.$route.query.level);
    // }
  },
};
</script>

<style scoped>
.section-container {
  padding: 1rem !important;
  width: 100%;
  background: #eeeeee;
  border-radius: 10px;
}
</style>