var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('b-breadcrumb',{staticClass:"breadcrumb pb-2 pt-1 noprint",attrs:{"items":_vm.breadcrumb_items}}),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_formationarea'),expression:"'mesh.add_formationarea'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-formation-area-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames("mesh.formationarea", false, "Área De Formación"))+" ")]):_vm._e()])]),_c('h3',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.formationarea", true, "Áreas De Formación"))+" ")]),(_vm.searchFormationArea.length > 0)?_c('GenericBTable',{staticClass:"formation-area-table",attrs:{"items":_vm.formation_areas,"pagination":_vm.formation_areas.length,"fields":_vm.FormationAreasFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Editar ${_vm.$getVisibleNames(
              'mesh.formationarea',
              false,
              'Área De Formación'
            )}`
          ),expression:"\n            `Editar ${$getVisibleNames(\n              'mesh.formationarea',\n              false,\n              'Área De Formación'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-formation-area-modal-${row.item.id}`)}}}),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Eliminar ${_vm.$getVisibleNames(
              'mesh.formationarea',
              false,
              'Área De Formación'
            )}`
          ),expression:"\n            `Eliminar ${$getVisibleNames(\n              'mesh.formationarea',\n              false,\n              'Área De Formación'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteFormationArea(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-formation-area-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.formationarea',
            false,
            'Área De Formación'
          )}`,"size":"md","hide-footer":""}},[_c('FormationAreaForm',{attrs:{"FormationArea":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedFormationArea}})],1)]}}:null],null,true)}):_c('div',[_c('br'),_c('strong',[_vm._v("No se han encontrado "+_vm._s(_vm.$getVisibleNames( "mesh.formationarea", true, "Áreas De Formación" ).toLowerCase())+".")])])],1),_c('b-modal',{attrs:{"id":`new-formation-area-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.formationarea',
      false,
      'Área De Formación'
    )}`,"size":"md"}},[_c('FormationAreaForm',{attrs:{"show_title":false,"institution_id":this.user.school},on:{"created":_vm.slotCreatedFormationArea}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }