<template>
  <div>
    <b-form-group
      label="Nombre:"
      label-for="input-name"
      label-cols="3"
      label-cols-sm="2"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.profile_competence_mention.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
        class="input-name"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción:"
      label-for="input-description"
      label-cols="3"
      label-cols-sm="2"
      class="label"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="profile_competence_mention-form-description rich-text-content mr-0 mt-1"
        v-html="$v.profile_competence_mention.description.$model"
        @click="
          $bvModal.show(
            `edit-profile_competence_mention-description-modal-${profile_competence_mention.id}`
          )
        "
      ></div>
      <b-modal
        :id="`edit-profile_competence_mention-description-modal-${profile_competence_mention.id}`"
        title="Editar Descripción"
        size="lg"
        hide-footer
      >
        <RichTextEditor
          :Object="profile_competence_mention"
          :Text="profile_competence_mention.description"
          @update="patchProfileCompetenceMention"
          @close="
            $bvModal.hide(
              `edit-profile_competence_mention-description-modal-${profile_competence_mention.id}`
            )
          "
        ></RichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="$getVisibleNames('mesh.competence', true, 'Competencias') + ':'"
      label-for="input-egress_profile_competences"
      label-cols="3"
      label-cols-sm="2"
      class="label"
    >
      <b-form-select
        v-if="competenceOptions.length > 0"
        id="input-egress_profile_competences"
        v-model="
          $v.profile_competence_mention.egress_profile_competences.$model
        "
        :state="validateState('egress_profile_competences')"
        aria-describedby="input-egress_profile_competences-feedback"
        size="sm"
        multiple
        :select-size="
          competenceOptions.length <= 5 ? competenceOptions.length : 9
        "
        class="select-form"
      >
        <b-select-option
          class="select-option"
          v-for="item in competenceOptions"
          :value="item.id"
          :key="item.id"
          :title="item.name"
        >
          {{ item.name }}
        </b-select-option>
      </b-form-select>
      <div class="pt-2" v-else>
        El
        {{ $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso") }}
        no cuenta con
        {{ $getVisibleNames("mesh.competence", true, "Competencias") }}.
      </div>
      <b-form-invalid-feedback id="input-egress_profile_competences-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row mt-3">
      <div
        v-if="show_delete_button && !isNaN(this.profile_competence_mention.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDeleteObject"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0 mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "EgressProfileCompetenceMetionForm",
  mixins: [validationMixin],
  components: {
    RichTextEditor: () => import("@/components/reusable/RichTextEditor"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    ProfileCompetenceMention: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          egress_profile: this.egress_profile_id,
          egress_profile_competences: [],
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profile_competence_mention: { ...this.ProfileCompetenceMention },
    };
  },
  validations: {
    profile_competence_mention: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {},
      egress_profile: {
        required,
      },
      egress_profile_competences: {},
    },
  },
  computed: {
    ...mapGetters({
      competences: names.COMPETENCES,
      profile_competences: names.PROFILE_COMPETENCES,
      egressProfileCycles: names.PROFILE_CYCLES,
    }),
    competenceOptions() {
      let list = [];
      list = this.profile_competences
        .filter((x) => x.egress_profile == this.egress_profile_id)
        .map((objeto) => {
          return {
            ...objeto,
            name:
              objeto.order +
                "." +
                ((
                  this.egressProfileCycles.find(
                    (x) =>
                      x.egress_profile == this.egress_profile_id &&
                      x.cycle == objeto.cycle
                  ) || {}
                ).order || "-") +
                " " +
                (this.competences.find((x) => x.id == objeto.competence) || {})
                  .full_sentence || "",
            profile_cycle_order:
              (
                this.egressProfileCycles.find(
                  (x) =>
                    x.egress_profile == this.egress_profile_id &&
                    x.cycle == objeto.cycle
                ) || {}
              ).order || 1000,
          };
        });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.profile_cycle_order > b.profile_cycle_order) return 1;
        if (a.profile_cycle_order < b.profile_cycle_order) return -1;
        return 0;
      });
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.profile_competence_mention[name];
      return $dirty ? !$error : null;
    },
    patchProfileCompetenceMention(object, text) {
      this.profile_competence_mention.description = text;
    },
    save() {
      this.$v.profile_competence_mention.$touch();
      if (this.$v.profile_competence_mention.$anyError) {
        return;
      }
      if (isNaN(this.profile_competence_mention.id))
        this.createProfileCompetenceMention();
      else this.updateProfileCompetenceMention();
    },
    createProfileCompetenceMention() {
      this.$store
        .dispatch(
          names.POST_PROFILE_COMPETENCE_MENTION,
          this.profile_competence_mention
        )
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.egressprofilecompetencemention",
                false,
                "Mención"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateProfileCompetenceMention() {
      this.$store
        .dispatch(
          names.UPDATE_PROFILE_COMPETENCE_MENTION,
          this.profile_competence_mention
        )
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.egressprofilecompetencemention",
                false,
                "Mención"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    askForDeleteObject() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.egressprofilecompetencemention",
          false,
          "Mención"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_PROFILE_COMPETENCE_MENTION,
            this.profile_competence_mention.id
          );
          toast(
            String(
              this.$getVisibleNames(
                "mesh.egressprofilecompetencemention",
                false,
                "Mención"
              ) + " eliminada."
            )
          );
          this.$emit("deleted", this.profile_competence_mention);
        }
      });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.select-form {
  display: block !important;
}
.profile_competence_mention-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.select-option {
  overflow: auto;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-bottom: 0.25rem;
}
</style>