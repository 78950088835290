<template>
  <div>
    <SentenceForm
      :first_content="ra_macro.action"
      :second_content="ra_macro.content"
      :third_content="ra_macro.condition"
      :full_sentence="ra_macro.full_sentence"
      :verb="ra_macro.verb"
      third_content_comment
      @update="slotUpdateSentence"
    ></SentenceForm>
    <div class="row mt-2">
      <div v-if="show_delete_button" class="col" style="text-align: left">
        <b-button class="mr-1" size="sm" variant="danger" @click="remove"
          >Eliminar
          {{
            $getVisibleNames("teaching.ramacro", false, "RA Macro")
          }}</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save"
          >Guardar
          {{
            $getVisibleNames("teaching.ramacro", false, "RA Macro")
          }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "RAMacroForm",
  mixins: [validationMixin],
  components: {
    SentenceForm: () => import("@/components/reusable/SentenceForm"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    RAMacro: {
      type: Object,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: true,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ra_macro: {
        id: this.RAMacro ? this.RAMacro.id : generateUniqueId(),
        matter: this.RAMacro ? this.RAMacro.matter : this.matter_id,
        action: this.RAMacro ? this.RAMacro.action : null,
        content: this.RAMacro ? this.RAMacro.content : null,
        condition: this.RAMacro ? this.RAMacro.condition : null,
        full_sentence: this.RAMacro ? this.RAMacro.full_sentence : null,
        verb: this.RAMacro ? this.RAMacro.verb : null,
      },
      base_sentence_toggle: this.RAMacro
        ? this.RAMacro.content
          ? true
          : false
        : true,
    };
  },
  validations() {
    return {
      ra_macro: {
        action: { required },
        content: { required },
        condition: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      raMacros: names.RA_MACROS,
    }),
    matter() {
      return this.matters.find((x) => x.id == this.matter_id);
    },
    matterRAMacros() {
      return this.raMacros.filter((x) => x.matter == this.matter_id);
    },
  },
  methods: {
    slotUpdateSentence(item) {
      this.ra_macro.verb = item.verb;
      this.ra_macro.action = item.first_content;
      this.ra_macro.content = item.second_content;
      this.ra_macro.condition = item.third_content;
      this.ra_macro.full_sentence = item.full_sentence;
      this.base_sentence_toggle = item.base_sentence_toggle;
    },
    validateState(key) {
      const { $dirty, $error } = this.$v.ra_macro[key];
      return $dirty ? !$error : null;
    },
    save() {
      if (this.base_sentence_toggle) {
        this.$v.ra_macro.$touch();
        if (this.$v.ra_macro.$anyError) return;
        this.ra_macro.full_sentence = String(
          this.ra_macro.action +
            " " +
            this.ra_macro.content +
            " " +
            this.ra_macro.condition
        );
        if (isNaN(this.ra_macro.id)) this.createRAMacro();
        else this.updateRAMacro();
      } else {
        if (
          this.ra_macro.full_sentence ||
          !this.ra_macro.full_sentence.trim() == ""
        ) {
          if (isNaN(this.ra_macro.id)) this.createRAMacro();
          else this.updateRAMacro();
        }
      }
    },
    remove() {
      this.$swal({
        title: "¿Está seguro de que lo desea eliminar?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          if (!this.allows_crud) {
            this.$emit("delete", this.ra_macro);
            return;
          }
          this.$store
            .dispatch(names.DELETE_RA_MACRO, this.ra_macro.id)
            .then(() => {
              this.$emit("deleted", this.ra_macro);
              toast(
                String(
                  this.$getVisibleNames("teaching.ramacro", false, "RA Macro") +
                    " eliminado."
                )
              );
            });
        }
      });
    },
    createRAMacro() {
      this.$store
        .dispatch(names.POST_RA_MACRO, this.ra_macro)
        .then((response) => {
          this.$emit("created", response);
          toast(
            String(
              this.$getVisibleNames("teaching.ramacro", false, "RA Macro") +
                " creado."
            )
          );
        });
    },
    updateRAMacro() {
      this.$store
        .dispatch(names.UPDATE_RA_MACRO, this.ra_macro)
        .then((response) => {
          this.$emit("updated", response);
          toast(
            String(
              this.$getVisibleNames("teaching.ramacro", false, "RA Macro") +
                " actualizado."
            )
          );
        });
    },
  },
};
</script>

<style scoped>
</style>