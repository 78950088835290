<template>
  <div>
    <b-breadcrumb
      class="breadcrumb pb-2 pt-1 noprint"
      :items="breadcrumb_items"
    ></b-breadcrumb>
    <div style="display: flex">
      <div class="col" style="text-align: left">
        <button
          v-can="'mesh.add_faculty'"
          v-if="
            allows_crud &&
            ((user_position &&
              [1].includes(user_position.position) &&
              [1].includes(user.groups[0])) ||
              user.is_superuser ||
              $hasObjectPermission('mesh.faculty', 'add'))
          "
          class="btn btn-secondary btn-sm"
          v-b-modal.new-faculty-modal
        >
          Agregar {{ $getVisibleNames("mesh.faculty", false, "Facultad") }}
        </button>
      </div>
    </div>
    <h3>{{ $getVisibleNames("mesh.faculty", true, "Facultades") }}</h3>
    <GenericBTable
      v-if="facultyList"
      :items="facultyList"
      :filterCustom="filterCustom"
      :pagination="facultyList.length"
      @emitChangeSearchField="changeInputSearch"
      :fields="fields_faculties"
      primary-key="id"
      :filter="input_search"
      :show_pagination="true"
      :search_filter="true"
      :columns_display="true"
      :allows_crud="allows_crud"
      :display_id="display_id"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:cell(campus)="row">
        <div class="campus-container">
          <div
            v-for="item in getCampusName(row.item.campuses)"
            :key="item.id"
            class="campus-div"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- {{ getCampusName(row.item.campuses) }} -->
        <!-- {{row.item.campuses}} -->
      </template>
      <template #cell(color)="row">
        <div
          class="faculty-color"
          :style="{
            'background-color': row.item.color,
            color: row.item.color,
          }"
        ></div>
      </template>
      <template #cell(faculty_area)="row">
        <div class="campus-container">
          <div
            v-for="item in faculty_areas.filter(
              (x) => x.faculty == row.item.id
            )"
            :key="item.id"
            class="campus-div"
          >
            {{ item.name }}
          </div>
        </div>
      </template>
      <template v-slot:cell(actions)="row">
        <template
          v-if="
            allows_crud &&
            ((user_position &&
              [1].includes(user_position.position) &&
              [1].includes(user.groups[0])) ||
              user.is_superuser ||
              $hasObjectPermission('mesh.faculty', 'change', row.item.id))
          "
        >
          <b-modal
            :id="`edit-faculty-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.faculty',
              false,
              'Facultad'
            )}`"
            size="md"
            hide-footer
          >
            <FacultyForm
              :Faculty="row.item"
              @createdArea="slotCreatedArea"
              @updated="slotUpdatedFaculty"
              @updatedArea="slotUpdatedArea"
              @deletedArea="slotDeletedArea"
              :show_title="false"
            ></FacultyForm>
          </b-modal>
          <button-edit
            v-can="'mesh.change_faculty'"
            @click="$bvModal.show(`edit-faculty-modal-${row.item.id}`)"
            v-b-tooltip.v-secondary.auto.noninteractive="{
              customClass: 'custom-tooltip',
            }"
            :title="`Editar ${$getVisibleNames(
              'mesh.faculty',
              false,
              'Facultad'
            )}`"
          ></button-edit>
          <button-delete
            v-can="'mesh.delete_faculty'"
            v-if="isValidDelete(row.item.id)"
            @click="askForDeleteFaculty(row.item.id)"
            v-b-tooltip.v-secondary.auto.noninteractive="{
              customClass: 'custom-tooltip',
            }"
            :title="`Eliminar ${$getVisibleNames(
              'mesh.faculty',
              false,
              'Facultad'
            )}`"
          ></button-delete>
          <span
            v-else
            v-b-tooltip.v-secondary.auto.noninteractive="
              `No se puede eliminar la ${$getVisibleNames(
                'mesh.faculty',
                false,
                'Facultad'
              )} si cuenta con ${$getVisibleNames(
                'mesh.career',
                true,
                'Programas'
              )} asignado`
            "
          >
            <button-delete disabled></button-delete>
          </span>
        </template>
      </template>
    </GenericBTable>
    <div>
      <b-modal
        id="new-faculty-modal"
        :title="`Crear ${$getVisibleNames('mesh.faculty', false, 'Facultad')}`"
        size="md"
        hide-footer
      >
        <FacultyForm
          @created="slotCreatedFaculty"
          @createdAreas="slotCreatedAreas"
          :show_title="false"
        ></FacultyForm>
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "FacultyListView",
  components: {
    FacultyForm: () => import("./FacultyForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    display_id: {},
  },
  data() {
    return {
      input_search: "",
      sortBy: "",
      sortDesc: false,
      faculty_areas: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      faculties: names.FACULTIES,
      careers: names.CAREERS,
      campuses: names.CAMPUSES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_faculty");
      if (has_permission) return has_permission;
      else return false;
    },
    facultyList() {
      return this.faculties;
    },
    breadcrumb_items() {
      return [
        { text: "Definiciones Organizacionales", active: true },
        {
          text: this.$getVisibleNames("mesh.faculty", true, "Facultades"),
          active: true,
        },
      ];
    },
    fields_faculties() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames("mesh.faculty", true, "Facultades")
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "color",
          label: "Color",
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "faculty_area",
          label: String(
            this.$getVisibleNames(
              "mesh.facultyarea",
              true,
              "Áreas de las Facultades"
            )
          ),
          display_column: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "campus",
          label: String(this.$getVisibleNames("mesh.campus", true, "Sedes")),
          sortable: true,
          display_column: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.campuses.filter(
          (x) =>
            this.$filtered(x.name, this.input_search) &&
            row.campuses.includes(x.id)
        ).length > 0 ||
        this.faculty_areas.filter(
          (x) =>
            this.$filtered(x.name, this.input_search) && row.id == x.faculty
        ).length > 0
      ) {
        return true;
      } else return false;
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    getCampusName(campus) {
      let list = [];
      campus.forEach((x) => {
        const instance = this.campuses.find((element) => element.id == x);
        if (instance) list.push(instance);
      });
      return list;
    },
    slotCreatedFaculty() {
      this.$bvModal.hide("new-faculty-modal");
    },
    slotCreatedArea(area) {
      this.faculty_areas.push(area);
    },
    slotCreatedAreas(faculties_areas) {
      faculties_areas.forEach((x) => {
        this.faculty_areas.push(x);
      });
      this.$bvModal.hide("new-faculty-modal");
    },
    slotUpdatedFaculty(faculty) {
      this.$bvModal.hide(`edit-faculty-modal-${faculty.id}`);
    },
    slotUpdatedArea(area) {
      const index = this.faculty_areas.findIndex((x) => x.id == area.id);
      if (index != -1) this.faculty_areas.splice(index, 1, area);
    },
    slotDeletedArea(area_id) {
      const index = this.faculty_areas.findIndex((x) => x.id == area_id);
      if (index != -1) this.faculty_areas.splice(index, 1);
    },
    isValidDelete(faculty_id) {
      const list = this.careers.filter((x) => x.faculty == faculty_id);
      if (list.length > 0) return false;
      else return true;
    },
    askForDeleteFaculty(faculty_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.faculty",
          false,
          "Facultad"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_FACULTY, faculty_id).then(() => {
            toast(
              String(
                this.$getVisibleNames("mesh.faculty", false, "Facultad") +
                  " eliminada."
              )
            );
          });
        }
      });
    },
    fetchFacultiesAreas() {
      this.$restful.Get(`/mesh/faculty-area/`).then((response) => {
        this.faculty_areas = response;
      });
    },
  },
  created() {
    this.sortBy = "name";
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_CAREERS);
    this.fetchFacultiesAreas();
  },
};
</script>

// TODO: Revisar el ámbito de los estilos, debe usar scoped. custom-td parece sin sentido. 
<style>
.custom-td {
  width: 45% !important;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
.custon-tooltip {
  margin-top: 10px;
  z-index: 1;
}
.custon-tooltip::after {
  margin-right: 0.2em;
  transition: 0.2s ease-out;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1 !important;
  content: "❯ " !important;
  padding: 0;
  padding-right: 0.65em;
}
.faculty-color::before {
  min-width: 100px !important;
  height: 20px !important;
  opacity: 1;
  content: " #";
}
</style>