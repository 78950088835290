import { render, staticRenderFns } from "./RegionForm.vue?vue&type=template&id=962e7950&scoped=true&"
import script from "./RegionForm.vue?vue&type=script&lang=js&"
export * from "./RegionForm.vue?vue&type=script&lang=js&"
import style0 from "./RegionForm.vue?vue&type=style&index=0&id=962e7950&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "962e7950",
  null
  
)

export default component.exports