<template>
  <div>
    <div class="h-auto">
      <!-- <div class="search-career">
        <b-input-group size="sm" class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="search-form"
            type="search"
            v-model="input_search"
            placeholder="Buscar..."
          >
          </b-form-input>
        </b-input-group>
      </div> -->
      <!-- <b-tabs content-class="mt-3 h-auto" v-model="selected_tab">
        <b-tab :title="$getVisibleNames('mesh.campus', true, 'Sedes')">
          <CampusListView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'campus'"
          ></CampusListView>
        </b-tab>
        <b-tab :title="$getVisibleNames('mesh.faculty', true, 'Facultades')">
          <FacultyListView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'faculty'"
          ></FacultyListView>
        </b-tab>
        <b-tab :title="$getVisibleNames('mesh.cycle', true, 'Ciclos Formativos')">
          <CyclesListView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'cycle'"
          ></CyclesListView>
        </b-tab>
        <b-tab
          :title="
            $getVisibleNames('mesh.mattertype', true, 'Tipos De Asignatura')
          "
        >
          <MatterTypeView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'matter-type'"
          ></MatterTypeView>
        </b-tab>
        <b-tab
          :title="
            $getVisibleNames('mesh.formationarea', true, 'Áreas De Formación')
          "
        >
          <FormationAreaView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'formation-area'"
          ></FormationAreaView>
        </b-tab>
        <b-tab title="Estrategias Curriculares">
          <MatterContributionView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'matter-contribution'"
          ></MatterContributionView>
        </b-tab>
        <b-tab :title="$getVisibleNames('mesh.modality', true, 'Modalidades')">
          <ModalityView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'modality'"
          ></ModalityView>
        </b-tab>
        <b-tab :title="$getVisibleNames('mesh.period', true, 'Períodos')">
          <PeriodView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'period'"
          ></PeriodView>
        </b-tab> 
        <b-tab
          :title="`${$getVisibleNames('manual.programa', true, 'Programas')}`"
        > -->
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_career'"
            v-if="
              allows_crud &&
              ((user_position &&
                [1, 2].includes(user_position.position) &&
                [1, 2].includes(user.groups[0])) ||
                user.is_superuser ||
                $hasObjectPermission('mesh.faculty', 'add') ||
                $hasObjectPermission('mesh.career', 'add'))
            "
            class="btn btn-secondary btn-sm btn-add"
            v-b-modal.new-career-modal
          >
            Agregar
            {{ $getVisibleNames("manual.programa", false, "Programa") }}
            <!-- Programa -->
          </button>
        </div>
      </div>
      <h4 class="mt-0">
        <!-- {{
        $getVisibleNames(
          "manual.recurso_curricular",
          true,
          "Recursos Curriculares"
        )
      }} -->
        {{ $getVisibleNames("mesh.career", true, "Programas") }}
      </h4>
      <b-form-select
        name="type-select"
        class="w-25"
        v-model="profile_type_id"
        text-field="name"
        value-field="id"
      >
        <!-- :options="profileTypes" -->
        <option :value="null" selected>
          -- Seleccione Tipo de
          {{ $getVisibleNames("mesh.career", false, "Programa") }} --
        </option>
        <option v-for="type in profileTypes" :key="type.id" :value="type.id">
          {{ type.name }}
        </option>
      </b-form-select>
      <!-- <div
        class="toggle-types noprint"
        v-b-tooltip.v-secondary.hover="'Filtrar por Tipo de Programa'"
      >
        <span :class="{ 'font-weight-bold': !checked }">Pregrado</span>
        <b-button
          class="check-type"
          @click="checked = !checked"
          variant="primary"
        >
          <b-icon
            icon="circle-fill"
            class="icon-check"
            :class="{ 'icon-check-active': checked }"
            scale="0.7"
          ></b-icon>
        </b-button>
        <span :class="{ 'font-weight-bold': checked }">Postgrado</span>
      </div> -->
      <div class="table-responsive">
        <GenericBTable
          v-if="careerList"
          :items="careerList"
          :fields="fields"
          :filter="input_search"
          :filterCustom="filterCustom"
          primary-key="id"
          :show_pagination="true"
          :search_filter="true"
          :columns_display="true"
          :allows_crud="allows_crud"
          :display_id="'career'"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @emitChangeSearchField="changeInputSearch"
        >
          <template v-slot:cell(faculty)="row">
            <div class="tdClass">
              {{ getFacultyName(row.item.faculty) }}
            </div>
          </template>
          <template v-slot:cell(name)="row">
            <div class="tdClass">
              {{ row.item.name }}
            </div>
          </template>
          <template v-slot:cell(type)="row">
            <div class="tdClass">
              {{ getCareerType(row.item.egress_profile_type) }}
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="float-right noprint">
              <template v-if="row.item.egress_profiles.length == 0">
                <b-button
                  v-if="
                    allows_crud &&
                    ((user_position &&
                      [1, 2].includes(user_position.position) &&
                      [1, 2].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.faculty',
                        'change',
                        row.item.faculty
                      ) ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        row.item.id
                      ))
                  "
                  variant="secondary"
                  size="sm"
                  @click="slotCreateEgressProfile(row.item)"
                  >Crear
                  {{
                    $getVisibleNames(
                      "mesh.egressprofile",
                      false,
                      "Perfil de Egreso"
                    )
                  }}
                  <!-- Perfil de Egreso -->
                </b-button>
              </template>
              <template v-else>
                <b-dropdown
                  id="dropdown-grouped"
                  text="Dirigirse a..."
                  size="sm"
                  class="mr-1 navigation-dropdown"
                >
                  <b-dropdown
                    id="dropdown-2"
                    :text="
                      $getVisibleNames(
                        'mesh.egressprofile',
                        false,
                        'Perfil de Egreso'
                      )
                    "
                    class="dropdown-class"
                  >
                    <b-dropdown-item
                      size="sm"
                      v-for="egress_profile_id in row.item.egress_profiles"
                      v-b-tooltip.v-secondary.top.noninteractive="
                        'Ir al perfil'
                      "
                      :key="egress_profile_id"
                      @click="redirectToEgressProfile(egress_profile_id)"
                      >{{
                        getEgressProfile(egress_profile_id)
                      }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown
                    id="dropdown-2"
                    :text="
                      $getVisibleNames('manual.matriz_1', false, 'Matriz 1')
                    "
                    v-b-tooltip.left.v-secondary.noninteractive="
                      `
                      Progresión de ${$getVisibleNames(
                        'mesh.competence',
                        true,
                        'Competencias'
                      )} por ${$getVisibleNames(
                        'mesh.cycle',
                        true,
                        'Ciclos Formativos'
                      )}
                    `
                    "
                    class="dropdown-class"
                  >
                    <b-dropdown-item
                      size="sm"
                      v-for="egress_profile_id in row.item.egress_profiles"
                      v-b-tooltip.v-secondary.top.noninteractive="
                        'Ir a la ' +
                        $getVisibleNames('manual.matriz_1', false, 'Matriz 1')
                      "
                      :key="egress_profile_id"
                      @click="redirectToMatrix1(egress_profile_id)"
                      >{{
                        getEgressProfile(egress_profile_id)
                      }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown
                    id="dropdown-2"
                    :text="
                      $getVisibleNames('manual.matriz_2', false, 'Matriz 2')
                    "
                    v-b-tooltip.left.v-secondary.noninteractive="
                      `
                          Progresión de ${$getVisibleNames(
                            'mesh.egressprofilematter',
                            true,
                            'Asignaturas'
                          )} de ${row.item.name}
                        `
                    "
                    class="dropdown-class"
                  >
                    <b-dropdown-item
                      size="sm"
                      v-for="egress_profile_id in row.item.egress_profiles"
                      v-b-tooltip.v-secondary.top.noninteractive="
                        'Ir a la ' +
                        $getVisibleNames('manual.matriz_2', false, 'Matriz 2')
                      "
                      :key="egress_profile_id"
                      @click="redirectToMatrix2(egress_profile_id)"
                      >{{
                        getEgressProfile(egress_profile_id)
                      }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown
                    id="dropdown-2"
                    :text="
                      $getVisibleNames('manual.matriz_3', false, 'Matriz 3')
                    "
                    v-b-tooltip.left.v-secondary.noninteractive="
                      `
                      Tributación de las ${$getVisibleNames(
                        'mesh.egressprofilematter',
                        true,
                        'Asignaturas'
                      )} al Perfil de Egreso
                    `
                    "
                    class="dropdown-class"
                  >
                    <b-dropdown-item
                      size="sm"
                      v-for="egress_profile_id in row.item.egress_profiles"
                      :key="egress_profile_id"
                      v-b-tooltip.v-secondary.top.noninteractive="
                        'Ir a la ' +
                        $getVisibleNames('manual.matriz_3', false, 'Matriz 3')
                      "
                      @click="redirectToMatrix3(egress_profile_id)"
                      >{{
                        getEgressProfile(egress_profile_id)
                      }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown
                    id="dropdown-2"
                    text="Plan de Estudio"
                    class="dropdown-class"
                  >
                    <b-dropdown-item
                      size="sm"
                      v-for="egress_profile_id in row.item.egress_profiles"
                      :key="egress_profile_id"
                      v-b-tooltip.v-secondary.top.noninteractive="
                        'Ir al plan de estudio'
                      "
                      @click="redirectToStudyPlan(egress_profile_id)"
                      >{{
                        getEgressProfile(egress_profile_id)
                      }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown
                    id="dropdown-2"
                    text="Malla Curricular"
                    class="dropdown-class"
                  >
                    <b-dropdown-item
                      size="sm"
                      v-for="egress_profile_id in row.item.egress_profiles"
                      :key="egress_profile_id"
                      v-b-tooltip.v-secondary.top.noninteractive="
                        'Ir a la malla curricular'
                      "
                      @click="redirectToCurricularMesh(egress_profile_id)"
                      >{{
                        getEgressProfile(egress_profile_id)
                      }}</b-dropdown-item
                    >
                  </b-dropdown>
                </b-dropdown>
              </template>
              <button-edit
                v-if="
                  allows_crud &&
                  ((user_position &&
                    [1, 2].includes(user_position.position) &&
                    [1, 2].includes(user.groups[0])) ||
                    user.is_superuser ||
                    $hasObjectPermission(
                      'mesh.faculty',
                      'change',
                      row.item.faculty
                    ) ||
                    $hasObjectPermission('mesh.career', 'change', row.item.id))
                "
                v-can="'mesh.change_career'"
                @click="$bvModal.show(`edit-career-modal-${row.item.id}`)"
                v-b-tooltip.v-secondary.topright.noninteractive="
                  `
                      Editar ${$getVisibleNames(
                        'mesh.career',
                        false,
                        'Programa'
                      )}
                    `
                "
              ></button-edit>
              <button-edit
                v-else-if="allows_crud"
                v-can="'mesh.change_career'"
                :disabled="true"
              ></button-edit>
              <button-delete
                v-if="
                  allows_crud &&
                  ((user_position &&
                    [1, 2].includes(user_position.position) &&
                    [1, 2].includes(user.groups[0])) ||
                    user.is_superuser ||
                    $hasObjectPermission(
                      'mesh.faculty',
                      'change',
                      row.item.faculty
                    ) ||
                    $hasObjectPermission('mesh.career', 'delete', row.item.id))
                "
                v-can="'mesh.delete_career'"
                @click="askForDeleteCareer(row.item.id)"
                v-b-tooltip.v-secondary.topright.noninteractive="
                  `
                      Eliminar ${$getVisibleNames(
                        'mesh.career',
                        false,
                        'Programa'
                      )}
                    `
                "
              ></button-delete>
              <button-delete
                v-else-if="allows_crud"
                v-can="'mesh.change_career'"
                :disabled="true"
              ></button-delete>
            </div>

            <b-modal
              :id="`edit-career-modal-${row.item.id}`"
              :title="`Editar ${$getVisibleNames(
                'mesh.career',
                false,
                'Programa'
              )}
                    `"
              size="md"
              hide-footer
            >
              <CareerForm
                :Career="row.item"
                @updated="slotUpdatedCareer"
                :show_title="false"
              ></CareerForm>
            </b-modal>
          </template>
        </GenericBTable>
      </div>
      <!-- <div v-else>
            <b-table
              v-if="careerList"
              :items="careerList"
              :fields="fields"
              :filter="input_search"
              primary-key="id"
              thead-class="b-card-style"
              stacked="md"
              show-empty
              small
              bordered
              borderless
              striped
              empty-text="No hay datos para mostrar. Su perfil no cuenta con ningún Programa asociado a su usuario."
              empty-filtered-text="No hay datos que coincidan con su búsqueda."
            >
              <template v-slot:cell(faculty)="row">{{
                getFacultyName(row.item.faculty)
              }}</template>
              <template v-slot:cell(actions)="row">
                <template v-if="row.item.egress_profiles.length == 0">
                  <b-button
                    variant="secondary"
                    size="sm"
                    @click="slotCreateEgressProfile(row.item)"
                    >Crear Perfil de Egreso</b-button
                  >
                </template>
                <template v-else>
                  <div style="display: flex">
                    <b-button
                      size="sm"
                      @click="redirectToEgressProfile(row.item)"
                      :title="
                        row.item.egress_profiles.length == 0
                          ? 'No tiene Perfil de Egreso creado.'
                          : ''
                      "
                      class="career-redirect-button"
                      ><b-icon
                        class="navigation-icon"
                        icon="book"
                        color="#ddd"
                      ></b-icon
                      ><span class="career-redirect-button-text"
                        >Ir a Perfil de Egreso</span
                      >
                    </b-button>
                    <b-button
                      size="sm"
                      @click="redirectToMatrix1(row.item)"
                      :disabled="row.item.egress_profiles.length == 0"
                      :title="
                        row.item.egress_profiles.length == 0
                          ? 'No tiene Perfil de Egreso creado.'
                          : ''
                      "
                      class="career-redirect-button"
                      ><b-icon
                        class="navigation-icon"
                        icon="layout-three-columns"
                        color="#ddd"
                      ></b-icon
                      ><span class="career-redirect-button-text"
                        >Matriz 1</span
                      ></b-button
                    >
                    <b-button
                      size="sm"
                      @click="redirectToMatrix2(row.item)"
                      :disabled="row.item.egress_profiles.length == 0"
                      class="career-redirect-button"
                      ><b-icon
                        class="navigation-icon"
                        icon="grid"
                        color="#ddd"
                      ></b-icon
                      ><span class="career-redirect-button-text">Matriz 2</span>
                    </b-button>
                    <b-button
                      size="sm"
                      @click="redirectToMatrix3(row.item)"
                      :disabled="row.item.egress_profiles.length == 0"
                      class="career-redirect-button"
                      ><b-icon
                        class="navigation-icon"
                        icon="grid"
                        color="#ddd"
                      ></b-icon
                      ><span class="career-redirect-button-text">Matriz 3</span>
                    </b-button>
                    <b-button
                      size="sm"
                      @click="redirectToStudyPlan(row.item)"
                      :title="
                        row.item.egress_profiles.length == 0
                          ? 'No tiene Perfil de Egreso creado.'
                          : ''
                      "
                      class="career-redirect-button"
                      ><b-icon
                        class="navigation-icon"
                        icon="book-half"
                        color="#ddd"
                      ></b-icon
                      ><span class="career-redirect-button-text"
                        >Plan de Estudio</span
                      ></b-button
                    >
                    <b-button
                      v-if="row.item.egress_profiles.length < 2"
                      size="sm"
                      @click="redirectToCurricularMesh(row.item)"
                      :disabled="row.item.egress_profiles.length == 0"
                      :title="
                        row.item.egress_profiles.length == 0
                          ? 'No tiene Perfil de Egreso creado.'
                          : ''
                      "
                      class="career-redirect-button"
                      ><b-icon
                        class="navigation-icon"
                        icon="table"
                        color="#ddd"
                      ></b-icon
                      ><span class="career-redirect-button-text"
                        >Malla Curricular</span
                      ></b-button
                    >
                    <b-button v-else right text="Malla Curricular" size="sm">
                      <template
                        v-for="egress_profile_id in row.item.egress_profiles"
                      >
                        <b-button
                          :key="egress_profile_id"
                          @click="redirectToCurricularMesh()"
                          size="sm"
                          >Malla Curricular {{ egress_profile_id }}</b-button
                        >
                      </template>
                    </b-button>
                    <div class="edit">
                      <button-edit
                        v-can="'mesh.change_career'"
                        @click="
                          $bvModal.show(`edit-career-modal-${row.item.id}`)
                        "
                        v-b-tooltip.v-secondary.topright.noninteractive="
                          'Editar Programa'
                        "
                      ></button-edit>
                      <button-delete
                        v-can="'mesh.delete_career'"
                        @click="askForDeleteCareer(row.item.id)"
                        v-b-tooltip.v-secondary.topright.noninteractive="
                          'Eliminar Programa'
                        "
                      ></button-delete>
                    </div>
                  </div>
                </template>
                <b-modal
                  :id="`edit-career-modal-${row.item.id}`"
                  title="Editar Programa"
                  size="md"
                  hide-footer
                >
                  <CareerForm
                    :Career="row.item"
                    @updated="slotUpdatedCareer"
                    :show_title="false"
                  ></CareerForm>
                </b-modal>
              </template>
            </b-table>
          </div> -->
      <!-- </b-tab>
        <b-tab
          :title="
            $getVisibleNames('mesh.formationline', true, 'Líneas De Formación')
          "
        >
          <FormationLineView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'formation-line'"
          ></FormationLineView>
        </b-tab>
        <b-tab
          :title="`${$getVisibleNames(
            'mesh.egressprofiletype',
            true,
            'Tipos De Perfiles De Egreso'
          )}`"
        >
          <EgressProfileTypeView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'egress-profile-type'"
          ></EgressProfileTypeView>
        </b-tab>
        <b-tab
          :title="
            $getVisibleNames('mesh.egressprofile', true, 'Perfiles De Egreso')
          "
        >
          <EgressProfileList
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'egress-profile'"
          ></EgressProfileList>
        </b-tab>
        <b-tab
          :title="
            $getVisibleNames(
              'mesh.competencetype',
              true,
              'Tipos De Competencias'
            )
          "
        >
          <CompetenceTypeView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'competence-type'"
          ></CompetenceTypeView>
        </b-tab>
        <b-tab
          :title="$getVisibleNames('mesh.competence', true, 'Competencias')"
        >
          <CompetenceTable
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'competence'"
          ></CompetenceTable>
        </b-tab>
        <b-tab title="Asignaturas">
          <MatterListView
            :input_search="input_search"
            :allows_crud="allows_crud"
            :display_id="'matter'"
          ></MatterListView>
        </b-tab> -->
      <!-- <div v-if="careerList.length == 0 && selected_tab == 3">
          Su perfil no cuenta con ningún Programa asociado a su usuario.
        </div> -->
      <!-- </b-tabs> -->
    </div>
    <div>
      <b-modal
        id="new-career-modal"
        :title="`Crear ${$getVisibleNames('mesh.career', false, 'Programa')}`"
        size="md"
        hide-footer
      >
        <CareerForm
          @created="slotCreatedCareer"
          :show_title="false"
        ></CareerForm>
      </b-modal>
    </div>
    <b-modal
      id="modal-create-egress-profile"
      :title="`Crear ${$getVisibleNames(
        'mesh.egressprofile',
        false,
        'Perfil de Egreso'
      )}`"
      size="lg"
      hide-footer
    >
      <EgressProfileForm
        :career_id="selected_career_id"
        @created="slotCreatedEgreesProfile"
      ></EgressProfileForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "CareerListView",
  components: {
    CareerForm: () => import("./Career/CareerForm"),
    // FacultyListView: () => import("./Faculty/FacultyListView"),
    // CampusListView: () => import("./Campus/CampusListView.vue"),
    EgressProfileForm: () =>
      import("@/components/mesh/EgressProfile/EgressProfileForm"),
    // MatterListView: () => import("./Matter/MatterListView.vue"),
    // EgressProfileTypeView: () =>
    //   import(
    //     "@/components/mesh/EgressProfile/EgressProfileType/EgressProfileTypeView"
    //   ),
    // EgressProfileList: () =>
    //   import("@/components/mesh/EgressProfile/EgressProfilesList.vue"),
    // CyclesListView: () =>
    //   import("@/components/mesh/CurricularResources/Cycle/CyclesListView.vue"),
    // CompetenceTable: () => import("./Competence/CompetenceTable"),
    // CompetenceTypeView: () => import("./CompetenceType/CompetenceTypeView"),
    // MatterTypeView: () => import("./MatterType/MatterTypeView"),
    // FormationLineView: () => import("./FormationLine/FormationLineView"),
    // MatterContributionView: () => import("./MatterContribution/MatterContributionView"),
    // ModalityView: () => import("./Modality/ModalityView"),
    // PeriodView: () => import("./Period/PeriodView"),
    // FormationAreaView: () => import("./FormationArea/FormationAreaView"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  data() {
    return {
      is_hidden: true,
      isDropdown2Visible: false,
      input_search: "",
      profile_type_id: null,
      selected_career_id: null,
      selected_tab: 8,
      checked: false,
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      user_position: names.USER_POSITION,
      careers: names.CAREERS,
      cycles: names.CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
      egressProfileTypes: names.PROFILE_TYPES,
      faculties: names.FACULTIES,
      profileCompetences: names.PROFILE_COMPETENCES,
      profileTypes: names.PROFILE_TYPES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_career");
      if (has_permission) return has_permission;
      else return false;
    },
    careerList() {
      let list = [];
      this.careers.forEach((x) => {
        list.push({
          id: x.id,
          egress_profiles: x.egress_profiles,
          egress_profile: x.egress_profile,
          egress_profile_type: x.egress_profile_type,
          egress_profile_type_name: this.getCareerType(x.egress_profile_type),
          school: x.school,
          name: x.name,
          faculty: x.faculty,
          faculty_name: this.getFacultyName(x.faculty),
        });
        // let program_type = this.getCareerType(x.egress_profile_type);
        // if (this.checked) {
        //   list = list.filter((x) => x.egress_profile_type_name == "Postgrado");
        // } else {
        //   list = list.filter((x) => x.egress_profile_type_name == "Pregrado");
        // }
      });
      if (!this.profile_type_id) return list;
      return list.filter((x) => x.egress_profile_type == this.profile_type_id);
    },
    fields() {
      return [
        {
          key: "faculty",
          label: String(
            this.$getVisibleNames("mesh.faculty", true, "Facultades")
          ),
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "name",
          label: String(
            this.$getVisibleNames("mesh.career", true, "Programas")
          ),
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "type",
          label: String("Tipo de Programa"),
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(
          this.getCareerType(row.egress_profile_type),
          this.input_search
        ) ||
        this.$filtered(this.getFacultyName(row.faculty), this.input_search)
      ) {
        return true;
      } else {
        return false;
      }
    },
    getCareerType(type_id) {
      let profile_type = this.egressProfileTypes.find((x) => x.id == type_id);
      return profile_type ? profile_type.name : "";
    },
    getFacultyName(faculty_id) {
      const instance = this.faculties.find((x) => x.id == faculty_id);
      if (instance) return instance.name;
      return "...";
    },
    getEgressProfile(egress_profile_id) {
      const instance = this.egressProfiles.find(
        (x) => x.id == egress_profile_id
      );
      let arr = [];
      if (instance) {
        if (instance.code.trim() != "") arr.push(instance.code);
        if (
          this.institution.show_ep_full_init_date &&
          instance.init_date != null
        )
          arr.push(instance.init_date);
        else if (instance.init_date != null)
          arr.push(parseInt(instance.init_date.substr(0, 4)));
        if (instance.professional_title.trim() != "")
          arr.push(instance.professional_title);
        return arr.join(" - ");
      } else return null;
    },
    slotCreatedCareer(/*career*/) {
      this.$bvModal.hide("new-career-modal");
    },
    slotUpdatedCareer(career) {
      this.$bvModal.hide(`edit-career-modal-${career.id}`);
    },
    askForDeleteCareer(career_id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_CAREER, career_id).then(() => {
            toast(
              String(this.$getVisibleNames("mesh.career", false, "Programa")) +
                " eliminado."
            );
          });
        }
      });
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },

    redirectToEgressProfile(egress_profile_id) {
      // let egress_profile_id = -1;
      // if (career.egress_profiles.length > 0)
      //   egress_profile_id = career.egress_profiles[0];
      this.$router.push({
        name: "EgressProfileView",
        params: { egress_profile_id: egress_profile_id },
      });
    },
    redirectToMatrix1(egress_profile_id) {
      // let egress_profile_id = career.egress_profiles[0];
      this.$router.push({
        name: "Matrix1View",
        params: { egress_profile_id: egress_profile_id },
      });
    },
    redirectToMatrix2(egress_profile_id) {
      // let egress_profile_id = career.egress_profiles[0];
      this.$router.push({
        name: "Matrix2View",
        params: {
          egress_profile_id: egress_profile_id,
        },
      });
    },
    redirectToMatrix3(egress_profile_id) {
      // let egress_profile_id = career.egress_profiles[0];
      this.$router.push({
        name: "Matrix3View",
        params: {
          egress_profile_id: egress_profile_id,
        },
      });
    },
    redirectToStudyPlan(egress_profile_id) {
      // let egress_profile_id = career.egress_profiles[0];
      this.$router.push({
        name: "StudyPlanView",
        params: {
          egress_profile_id: egress_profile_id,
        },
      });
    },
    redirectToCurricularMesh(egress_profile_id) {
      // let egress_profile_id = career.egress_profiles[0];
      this.$router.push({
        name: "CurricularMeshView",
        params: {
          egress_profile_id: egress_profile_id,
        },
      });
    },
    toggleCycles() {
      return (this.is_hidden = !this.is_hidden);
    },
    slotCreateEgressProfile(career) {
      this.selected_career_id = career.id;
      this.$bvModal.show("modal-create-egress-profile");
    },
    slotCreatedEgreesProfile(egress_profile) {
      this.$store.commit(names.MUTATE_DELETE_CAREER, egress_profile.career);
      this.$bvModal.hide("modal-create-egress-profile");
      this.$store.dispatch(names.FETCH_CAREER, egress_profile.career);
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_CYCLES);
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
    this.$store.dispatch(names.FETCH_PROFILE_TYPES);
    this.$store.dispatch(names.FETCH_COMPETENCE_TYPES);
    this.$store.dispatch(names.FETCH_MODALITIES_EGRESS_PROFILE);
    this.$store.dispatch(names.FETCH_COMPETENCES);
    this.$store.dispatch(names.FETCH_TAXONOMIES);
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$root.$on("bv::dropdown::show", (bvEvent) => {
      if (bvEvent.componentId === "dropdown-2") {
        this.isDropdown2Visible = true;
      }
    });
    this.$root.$on("bv::dropdown::hide", (bvEvent) => {
      if (bvEvent.componentId === "dropdown-2") {
        this.isDropdown2Visible = false;
      }

      if (this.isDropdown2Visible) {
        bvEvent.preventDefault();
      }
    });
  },
};
</script>

<style scoped>
.dropdown-class {
  width: 100%;
  padding: 2px;
}
.tdClass {
  text-align: left !important;
}
.dropdown-class >>> .btn {
  background-color: var(--primary-color) !important;
  border: var(--primary-color) !important;
  color: #212b2e !important;
}
.toggle-types {
  display: flex;
  float: right;
  margin-right: 1em;
  background: rgb(230, 230, 230);
  padding: 0.3em 4.8em;
  border-radius: 3px;
}
.small-table {
  min-height: 475px;
}
.dropdown-header {
  color: #212529;
  font-weight: 400;
  font-size: 1rem;
}
.hidden {
  display: none;
  padding-bottom: 10px;
}
.career-redirect-button-text {
  margin-left: 4px;
}
.career-redirect-button,
.career-m2-redirect-button {
  width: 38px;
  height: 38px;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.6s ease-in-out;
  margin: 2px;
  padding: 0 5px;
  border-radius: 8px;
  color: #6c757d;
}
.career-redirect-button:hover,
.career-m2-redirect-button:hover {
  width: 184px;
  padding-right: 100px !important;
  font-size: 16px !important;
  transition: 0.4s ease-in;
  color: #fff;
}
.career-redirect-button .navigation-icon,
.career-m2-redirect-button .navigation-icon {
  margin: 0 5px;
}

.edit {
  margin-top: 0.3rem;
}
.toggle-height {
  height: 100px !important;
}
.cycles {
  transition: 0.3s ease-in;
  width: 184px;
  text-align: left;
  border-radius: 6px;
  padding: 0 1px;
}
.cycles:hover {
  background-color: rgb(221, 221, 221);
  color: #3f3f3f;
  width: 184px;
  transition: 0.2s ease-in;
}
.add-career {
  margin-left: 76% !important;
  margin-bottom: -8%;
  padding-top: 1.3%;
}
.search-career {
  max-width: 60%;
  margin: 0 15%;
}
.table-responsive >>> .generic-table {
  overflow: inherit;
}
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.1em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.icon-check {
  color: white;
  transform: translate(-70%, -43%);
  transition: 0.2s;
}
.icon-check-active {
  transform: translate(15%, -43%) !important;
}
@media (min-width: 1378px) {
  .add-career {
    margin-left: 78% !important;
  }
}
@media (max-width: 800px) {
  .search-career {
    max-width: 50%;
    margin: 0;
  }
  .add-career {
    margin-left: 70% !important;
  }
}
</style>