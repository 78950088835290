<template>
  <div>
    <div class="mx-2">
      <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_formationarea'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-formation-area-modal`)"
          >
            Agregar
            {{
              $getVisibleNames("mesh.formationarea", false, "Área De Formación")
            }}
            <!-- Área de Formación -->
          </button>
        </div>
      </div>
      <h3>
        {{ $getVisibleNames("mesh.formationarea", true, "Áreas De Formación") }}
      </h3>
      <GenericBTable
        v-if="searchFormationArea.length > 0"
        class="formation-area-table"
        :items="formation_areas"
        :pagination="formation_areas.length"
        :fields="FormationAreasFields"
        :filter="input_search"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="display_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            @click="$bvModal.show(`edit-formation-area-modal-${row.item.id}`)"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.formationarea',
                false,
                'Área De Formación'
              )}`
            "
          ></button-edit>
          <button-delete
            @click="askForDeleteFormationArea(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'mesh.formationarea',
                false,
                'Área De Formación'
              )}`
            "
          ></button-delete>
          <b-modal
            :id="`edit-formation-area-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.formationarea',
              false,
              'Área De Formación'
            )}`"
            size="md"
            hide-footer
          >
            <FormationAreaForm
              :FormationArea="row.item"
              @updated="slotUpdatedFormationArea"
              :institution_id="row.item.school"
              :show_title="false"
            ></FormationAreaForm>
          </b-modal>
        </template>
      </GenericBTable>
      <div v-else>
        <br />
        <!-- áreas de formación -->
        <strong
          >No se han encontrado
          {{
            $getVisibleNames(
              "mesh.formationarea",
              true,
              "Áreas De Formación"
            ).toLowerCase()
          }}.</strong
        >
      </div>
    </div>
    <b-modal
      :id="`new-formation-area-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.formationarea',
        false,
        'Área De Formación'
      )}`"
      size="md"
    >
      <FormationAreaForm
        @created="slotCreatedFormationArea"
        :show_title="false"
        :institution_id="this.user.school"
      ></FormationAreaForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "FormationAreaView",
  components: {
    FormationAreaForm: () =>
      import(
        "@/components/mesh/CurricularResources/FormationArea/FormationAreaForm"
      ),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    input_search: {
      type: String,
      default: "",
    },
    display_id: {},
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      formation_areas: names.FORMATION_AREAS,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_formationarea");
      if (has_permission) return has_permission;
      else return false;
    },
    breadcrumb_items() {
      return [
        { text: "Definiciones Curriculares", active: true },
        {
          text: this.$getVisibleNames(
            "mesh.formationarea",
            true,
            "Áreas De Formación"
          ),
          active: true,
        },
      ];
    },
    formation_areas_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.formationarea",
              true,
              "Áreas De Formación"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "order",
          label: "Orden",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
    searchFormationArea: function () {
      return this.formation_areas
        .filter((item) => {
          return (
            item.name.toLowerCase().includes(this.input_search.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(this.input_search.toLowerCase())
          );
        })
        .sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
    FormationAreasFields() {
      if (!this.allows_crud)
        return this.formation_areas_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.formation_areas_fields;
    },
  },
  methods: {
    askForDeleteFormationArea(formation_area_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.formationarea",
          false,
          "Área De Formación"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_FORMATION_AREA, formation_area_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.formationarea",
                    false,
                    "Área De Formación"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    slotCreatedFormationArea() {
      this.$bvModal.hide("new-formation-area-modal");
    },
    slotUpdatedFormationArea(formation_area) {
      this.$bvModal.hide(`edit-formation-area-modal-${formation_area.id}`);
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch(names.FETCH_FORMATION_AREAS, this.user.school);
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>

<style scoped>
.td-actions {
  max-width: 100px !important;
}
.title-header {
  padding: 0 1rem;
  margin-top: auto;
}
.search-formation-area {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.75em;
}
</style>