var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-4"},[_vm._v("Plan de Estudio")]),_c('b-table-simple',{staticClass:"header-table",attrs:{"bordered":"","responsive":"","outlined":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v(_vm._s(_vm.$getVisibleNames("mesh.career", false, "Programa").toUpperCase()))]),_c('b-th',{staticClass:"program-section"},[(_vm.career)?_c('div',[_vm._v(_vm._s(_vm.career.name.toUpperCase()))]):_c('div',[_vm._v("Cargando...")])])],1),_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.mesh.titulo_profesional", false, "Título Profesional" ).toUpperCase())+" ")]),_c('b-th',{staticClass:"program-section"},[(_vm.egressProfile)?_c('div',[_vm._v(" "+_vm._s(_vm.egressProfile.professional_title.toUpperCase())+" ")]):_c('div',[_vm._v("Cargando...")])])],1),(
          !(
            this.institution.id == 12 ||
            this.$debug_change_duoc ||
            !this.institution.show_academic_degree
          )
        )?_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.grado_academico", false, "Grado Académico" ).toUpperCase())+" ")]),_c('b-th',{staticClass:"program-section"},[(_vm.egressProfile)?_c('div',[_vm._v(" "+_vm._s(_vm.egressProfile.academic_degree.toUpperCase())+" ")]):_c('div',[_vm._v("Cargando...")])])],1):_vm._e()],1)],1),(_vm.egressProfile && _vm.egressProfile.comments)?_c('div',{staticClass:"header-p"},[(!_vm.allows_crud)?_c('div',_vm._l((_vm.egressProfile.comments.split('\n')),function(p,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(p)+" ")])}),0):_c('div',{staticStyle:{"text-align":"center"}},[_c('ResizeAuto',{scopedSlots:_vm._u([{key:"default",fn:function({ resize }){return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.egressProfile.comments),expression:"egressProfile.comments"}],domProps:{"value":(_vm.egressProfile.comments)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.egressProfile, "comments", $event.target.value)},resize],"focus":resize,"blur":_vm.updateComment}})]}}],null,false,3703149707)})],1)]):_vm._e(),(_vm.egressProfile)?_c('div',_vm._l((_vm.egressProfile.semester_amount),function(semester){return _c('BiannualLevel',{key:semester.id,attrs:{"egress_profile_id":_vm.egress_profile_id,"limit_study_plan":_vm.limit_study_plan,"egress_profile_time_allocations":_vm.egress_profile_time_allocations,"semester":semester,"allows_crud":_vm.allows_crud}})}),1):_vm._e(),(_vm.coursesWrapperList.length > 0)?_c('div',{staticClass:"ml-2"},[_c('b-table',{staticClass:"mt-3",attrs:{"items":_vm.coursesWrapperList,"fields":_vm.courses_wrapper_fields,"small":"","bordered":"","noCollapse":"","fixed":""},scopedSlots:_vm._u([{key:"cell(egress_profile_matters)",fn:function(row){return [_c('div',{staticClass:"campus-container"},_vm._l((_vm.getEgressProfileMatterName(
              row.item.egress_profile_matters
            )),function(item){return _c('div',{key:item.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]}}],null,false,2077820435)})],1):_vm._e(),_c('Summary',{attrs:{"egress_profile_id":_vm.egress_profile_id,"limit_study_plan":_vm.limit_study_plan,"egress_profile_time_allocations":_vm.egress_profile_time_allocations}}),_c('MeshCustomFab',{staticClass:"noprint",attrs:{"egress_profile_id":_vm.egress_profile_id,"show_study_plan":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }