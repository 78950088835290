<template>
  <div>
    <b-form-group
      label="Nombre:"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.base_matter_wrapper.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
        class="input-name"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción:"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-description"
        name="input-description"
        v-model="$v.base_matter_wrapper.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      label="Nivel Inicial:"
      label-for="input-init_level"
      class="label my-2 mx-0"
    >
      <b-form-input
        id="input-init_level"
        v-model="$v.base_matter_wrapper.init_level.$model"
        :state="validateState('init_level')"
        type="number"
        aria-describedby="input-init_level-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-init_level-live-feedback">
        <div
          v-if="base_matter_wrapper.init_level > base_matter_wrapper.end_level"
        >
          Nivel inicial no debe ser superior al nivel final
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      label="Nivel Final:"
      label-for="input-end_level"
      class="label my-2 mx-0"
    >
      <b-form-input
        id="input-end_level"
        v-model="$v.base_matter_wrapper.end_level.$model"
        :state="validateState('end_level')"
        type="number"
        aria-describedby="input-end_level-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-end_level-live-feedback">
        <div
          v-if="base_matter_wrapper.init_level > base_matter_wrapper.end_level"
        >
          Nivel final no debe ser menor al nivel inicial
        </div></b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      :label="
        $getVisibleNames('mesh.formationarea', false, 'Área De Formación') + ':'
      "
      label-for="input-formation-area"
      class="label my-2 mx-0"
    >
      <b-form-select
        id="input-formation-area"
        :options="formationAreas"
        v-model="$v.base_matter_wrapper.formation_area.$model"
        value-field="id"
        text-field="name"
        :state="validateState('formation_area')"
        type="number"
        aria-describedby="input-formation-area-feedback"
        size="sm"
      >
        <template #first>
          <b-form-select-option :value="null"
            >N/A
          </b-form-select-option></template
        ></b-form-select
      >
      <b-form-invalid-feedback id="input-formation-area-feedback"
        >Este campo no puede ser vacío.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      :label="
        $getVisibleNames('manual.credits', true, 'Créditos SCT Totales') + ':'
      "
      label-for="input-credits"
      class="label my-2 mx-0"
    >
      <b-form-input
        id="input-credits"
        v-model="$v.base_matter_wrapper.credits.$model"
        :state="validateState('credits')"
        type="number"
        aria-describedby="input-credits-live-feedback"
        size="sm"
      ></b-form-input>
    </b-form-group>
    <!-- <b-form-group
      label="Color"
      label-for="input-color"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        type="color"
        id="input-color"
        name="input-color"
        v-model="$v.base_matter_wrapper.color.$model"
        :state="validateState('color')"
        aria-describedby="input-color-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      >
      </b-form-input>
    </b-form-group> -->
    <b-form-group
      :label="$getVisibleNames('teaching.matter', true, 'Asignaturas') + ':'"
      label-for="input-matters"
      label-cols="0"
      label-cols-sm="3"
      class="sm p-0 m-0"
    >
      <b-input-group class="mb-1">
        <b-form-input
          v-model="input_search"
          type="search"
          size="sm"
          :placeholder="`Buscar ${$getVisibleNames(
            'teaching.matter',
            true,
            'Asignaturas'
          )}...`"
        >
        </b-form-input>
        <b-input-group-append>
          <b-button
            :disabled="!input_search"
            @click="input_search = ''"
            size="sm"
            >Limpiar</b-button
          >
        </b-input-group-append>
        <button
          v-can="'teaching.add_matter'"
          @click="$bvModal.show(`new-old-matter-modal`)"
          class="btn-add"
          v-b-tooltip.v-secondary.noninteractive="
            `Agregar ${$getVisibleNames(
              'teaching.matter',
              false,
              'Asignaturas'
            )}
            `
          "
        >
          <b-icon font-scale="1" icon="plus-square"></b-icon>
        </button>
      </b-input-group>
      <b-form-select
        v-if="mattersOptions.length > 0"
        id="input-matters"
        v-model="$v.base_matter_wrapper.matters.$model"
        :state="validateState('matters')"
        aria-describedby="input-matters-feedback"
        size="sm"
        multiple
        :select-size="
          searchMatterOptions.length <= 5 ? searchMatterOptions.length + 1 : 15
        "
      >
        <b-form-select-option
          class="select-option"
          v-for="option in searchMatterOptions"
          :key="option.id"
          :value="option.id"
        >
          - {{ option.name }}
        </b-form-select-option>
      </b-form-select>
      <div class="pt-2" v-else>
        No cuenta con
        {{ $getVisibleNames("teaching.matter", true, "Asignaturas") }}
        electivas.
      </div>
      <b-form-invalid-feedback id="input-matters-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row mt-3">
      <div
        v-if="show_delete_button && !isNaN(this.base_matter_wrapper.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDeleteObject"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0 mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
    <b-modal
      :id="`new-old-matter-modal`"
      :hide-footer="true"
      :title="`Agregar ${$getVisibleNames(
        'teaching.matter',
        false,
        'Asignaturas'
      )}`"
      size="lg"
    >
      <OldMatterForm
        :BaseMatterWrapperForm="true"
        @created="createdMatter"
      ></OldMatterForm>
    </b-modal>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  minValue,
  numeric,
} from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "BaseMatterWrapperForm",
  mixins: [validationMixin],
  components: {
    OldMatterForm: () => import("@/components/mesh/Matrix2/OldMatterForm"),
  },
  props: {
    BaseMatterWrapper: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          matters: [],
          init_level: 1,
          end_level: 1,
          max_matters: 0,
          formation_area: null,
          credits: 0,
          color: "#CBDDE1",
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input_search: "",
      base_matter_wrapper: { ...this.BaseMatterWrapper },
    };
  },
  validations: {
    base_matter_wrapper: {
      name: {
        required,
        minLength: minLength(2),
      },
      description: {},
      init_level: {
        required,
        numeric,
        minValue: minValue(1),
        valid() {
          if (
            this.base_matter_wrapper.init_level <=
            this.base_matter_wrapper.end_level
          )
            return true;
          else return false;
        },
      },
      end_level: {
        required,
        numeric,
        minValue: minValue(1),
        valid() {
          if (
            this.base_matter_wrapper.init_level <=
            this.base_matter_wrapper.end_level
          )
            return true;
          else return false;
        },
      },
      formation_area: {},
      max_matters: {},
      credits: { numeric },
      color: {},
      matters: {},
    },
  },
  computed: {
    ...mapGetters({
      formationAreas: names.FORMATION_AREAS,
      base_matters_wrapper: names.BASE_MATTERS_WRAPPER,
      matters: names.MATTERS,
    }),
    searchMatterOptions() {
      return this.mattersOptions.filter((item) => {
        return (
          this.$filtered(item.name, this.input_search) ||
          this.base_matter_wrapper.matters.includes(item.id)
        );
      });
    },
    // matterDisabled() {
    //   if (!this.egress_profile_id) return [];
    //   let list = [];
    //   const matters = this.base_matters_wrapper.filter(
    //     (x) =>
    //       x.egress_profile == this.egress_profile_id &&
    //       x.id != this.base_matter_wrapper.id
    //   );
    //   matters.forEach((x) => {
    //     list = list.concat(x.matters);
    //   });
    //   return [...new Set(list)];
    // },
    mattersOptions() {
      let list = [];
      list = this.matters.filter((x) => x.is_elective == true);
      return list.sort(function (a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.base_matter_wrapper[name];
      return $dirty ? !$error : null;
    },
    createdMatter(matter) {
      this.base_matter_wrapper.matters.push(matter.id);
      this.$bvModal.hide(`new-old-matter-modal`);
    },
    save() {
      this.$v.base_matter_wrapper.$touch();
      if (this.$v.base_matter_wrapper.$anyError) {
        return;
      }
      if (isNaN(this.base_matter_wrapper.id)) {
        this.createBaseMatterWrapper();
      } else this.updateBaseMatterWrapper();
    },
    createBaseMatterWrapper() {
      this.$store
        .dispatch(names.POST_BASE_MATTER_WRAPPER, this.base_matter_wrapper)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.basematterwrapper",
                false,
                "Envoltorio de Asignaturas"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateBaseMatterWrapper() {
      this.$store
        .dispatch(names.UPDATE_BASE_MATTER_WRAPPER, this.base_matter_wrapper)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.basematterwrapper",
                false,
                "Envoltorio de Asignaturas"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteBaseMatterWrapper() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.basematterwrapper",
          false,
          "Envoltorio de Asignaturas"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_BASE_MATTER_WRAPPER,
            this.base_matter_wrapper.id
          );
          this.$emit("deleted", this.base_matter_wrapper);
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.btn-add {
  border: none;
  background: transparent;
  font-size: 13pt;
  padding: 0;
  margin-left: 0.7rem;
}
.label {
  margin-top: 1%;
}
.input-name {
  margin-top: -1px;
}
select option:disabled {
  font-weight: bold !important;
  background-color: #e9e5e5 !important;
}
#input-color {
  cursor: pointer;
}
.select-option {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
  text-indent: -12px;
}
</style>