<template>
  <div>
    <div>
      <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_egressprofiletype'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-egress-profile-type-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "mesh.egressprofiletype",
                false,
                "Tipo de Perfil de Egreso"
              )
            }}
            <!-- Tipo de Perfil de Egreso -->
          </button>
        </div>
      </div>
      <h3 class="mb-2">
        {{
          $getVisibleNames(
            "mesh.egressprofiletype",
            true,
            "Tipos de Perfiles de Egreso"
          )
        }}
      </h3>
      <GenericBTable
        v-if="searchEgressProfileType.length > 0"
        class="egress-profile-type-table"
        :items="egress_profile_types"
        :pagination="egress_profile_types.length"
        :fields="egressProfileTypesFields"
        :filter="input_search"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="display_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            @click="
              $bvModal.show(`edit-egress-profile-type-modal-${row.item.id}`)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.egressprofiletype',
                false,
                'Tipo de Perfil de Egreso'
              )}`
            "
          ></button-edit>
          <button-delete
            v-if="isValidDelete(row.item.id)"
            @click="askForDeleteEgressProfileType(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              'Eliminar Tipo de Perfil de Egreso'
            "
          ></button-delete>
          <span
            v-else
            v-b-tooltip.v-secondary.auto.noninteractive="
              `No se puede eliminar la ${$getVisibleNames(
                'mesh.egressprofiletype',
                false,
                'Tipo de Perfil de Egreso'
              )} si cuenta con ${$getVisibleNames(
                'mesh.career',
                true,
                'Programas'
              )}`
            "
          >
            <button-delete disabled></button-delete>
          </span>
          <b-modal
            :id="`edit-egress-profile-type-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.egressprofiletype',
              false,
              'Tipo de Perfil de Egreso'
            )}`"
            size="md"
            hide-footer
          >
            <EgressProfileTypeForm
              :EgressProfileType="row.item"
              @updated="slotUpdatedEgressProfileType"
              :institution_id="row.item.school"
              :show_title="false"
            ></EgressProfileTypeForm>
          </b-modal>
        </template>
      </GenericBTable>
      <div v-else>
        <br />
        <!-- tipo de Perfil de Egreso -->
        <strong
          >No se a encontrado ningún
          {{
            $getVisibleNames(
              "mesh.egressprofiletype",
              false,
              "Tipo de Perfil de Egreso"
            )
          }}.</strong
        >
      </div>
    </div>
    <b-modal
      :id="`new-egress-profile-type-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.egressprofiletype',
        false,
        'Tipo de Perfil de Egreso'
      )}`"
      size="md"
    >
      <EgressProfileTypeForm
        @created="slotCreatedEgressProfileType"
        :show_title="false"
        :institution_id="this.user.school"
      ></EgressProfileTypeForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "EgressProfileTypeView",
  components: {
    EgressProfileTypeForm: () =>
      import(
        "@/components/mesh/EgressProfile/EgressProfileType/EgressProfileTypeForm"
      ),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    input_search: {
      type: String,
      default: "",
    },
    display_id: {},
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      egress_profile_types: names.PROFILE_TYPES,
      egressProfiles: names.EGRESS_PROFILES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_egressprofiletype");
      if (has_permission) return has_permission;
      else return false;
    },
    searchEgressProfileType: function () {
      return this.egress_profile_types
        .filter((item) => {
          return (
            item.name.toLowerCase().includes(this.input_search.toLowerCase()) ||
            item.module_minutes == this.input_search
          );
        })
        .sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
    egressProfileTypesFields() {
      if (!this.allows_crud)
        return this.egress_profile_types_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.egress_profile_types_fields;
    },
    breadcrumb_items() {
      return [
        { text: "Definiciones Curriculares", active: true },
        {
          text: String(
            this.$getVisibleNames(
              "mesh.egressprofiletype",
              true,
              "Tipos de Perfiles de Egreso"
            )
          ),
          active: true,
        },
      ];
    },
    egress_profile_types_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.egressprofiletype",
              true,
              "Tipos de Perfiles de Egreso"
            )
          ),
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          sortable: true,
          display_column: true,
        },
        {
          key: "module_minutes",
          label: "Minutos por Hora Pedagógica",
          thStyle: { background: "var(--primary-color) !important" },
          sortable: true,
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    isValidDelete(egress_profile_type_id) {
      const list = this.egressProfiles.filter(
        (x) => x.egress_profile_type == egress_profile_type_id
      );
      if (list.length > 0) return false;
      else return true;
    },
    askForDeleteEgressProfileType(egress_profile_type_id) {
      this.$swal({
        title:
          "¿Está seguro de que desea eliminar el Tipo de Perfil de Egreso?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_PROFILE_TYPE, egress_profile_type_id)
            .then(() => {
              toast("Tipo de Perfil de Egreso eliminada.");
            });
        }
      });
    },
    slotCreatedEgressProfileType() {
      this.$bvModal.hide("new-egress-profile-type-modal");
    },
    slotUpdatedEgressProfileType(egress_profile_type) {
      this.$bvModal.hide(
        `edit-egress-profile-type-modal-${egress_profile_type.id}`
      );
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
      this.$store.dispatch(names.FETCH_PROFILE_TYPES, this.user.school);
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped>
.td-actions {
  max-width: 100px !important;
}
.title-header {
  padding: 0 1rem;
  margin-top: auto;
}
.search-egress-profile-type {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
</style>