<template>
  <div>
    <b-form-group
      label="Nombre:"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.region.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
        class="input-name"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Orden:"
      label-for="input-order"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-order"
        name="input-order"
        type="number"
        v-model="$v.region.order.$model"
        :state="validateState('order')"
        aria-describedby="input-order-live-feedback"
        size="sm"
        class="input-order"
      ></b-form-input>
      <b-form-invalid-feedback id="input-order-live-feedback"
        >Este campo debe ser numérico y mayor a 0</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="`${$getVisibleNames('common.country', false, 'País')}:`"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-select
        id="select-country"
        name="select-country"
        :options="countries"
        value-field="id"
        text-field="name"
        v-model="$v.region.country.$model"
        :state="validateState('country')"
        aria-describedby="select-country-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      >
        <template #first>
          <b-form-select-option :value="null">
            -- Seleccione
            {{ $getVisibleNames("common.country", false, "País") }} --
          </b-form-select-option>
        </template>
      </b-form-select>
      <b-form-invalid-feedback id="select-country-live-feedback"
        >Debe seleccionar
        {{
          $getVisibleNames("common.country", false, "País")
        }}.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row mt-3">
      <div
        v-if="show_delete_button && !isNaN(this.region.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDeleteObject"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0 mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  numeric,
  minValue,
} from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "RegionForm",
  mixins: [validationMixin],
  props: {
    Region: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          order: 0,
          country: null,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      region: { ...this.Region },
    };
  },
  validations: {
    region: {
      name: {
        required,
        minLength: minLength(3),
      },
      order: {
        required,
        numeric,
        minValue: minValue(1),
      },
      country: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      regions: names.REGIONS,
      countries: names.COUNTRIES,
    }),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.region[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.region.$touch();
      if (this.$v.region.$anyError) {
        return;
      }
      if (isNaN(this.region.id)) {
        this.createRegion();
      } else this.updateRegion();
    },
    createRegion() {
      this.$store.dispatch(names.POST_REGION, this.region).then((response) => {
        toast(
          String(
            this.$getVisibleNames("common.region", false, "Región") + " creada."
          )
        );
        this.$emit("created", response);
      });
    },
    updateRegion() {
      this.$store
        .dispatch(names.UPDATE_REGION, this.region)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames("common.region", false, "Región") +
                " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteRegion() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "common.region",
          false,
          "Región"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_REGION, this.region.id);
          this.$emit("deleted", this.region);
        }
      });
    },
  },
  created() {
    if (isNaN(this.region.id)) this.region.order = this.regions.length + 1;
  },
};
</script>

<style scoped>
.label {
  margin-top: 1%;
}
.input-name {
  margin-top: -1px;
}
#input-color {
  cursor: pointer;
}
</style>