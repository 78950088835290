var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?[_vm._v(" Cargando... ")]:[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"my-0 py-0 w-100",attrs:{"label":_vm.$getVisibleNames('mesh.period', false, 'Período'),"label-cols":"0","label-cols-sm":"2","label-cols-md":"3","label-cols-lg":"4"}},[_c('b-form-select',{attrs:{"value-field":"id","text-field":"name","size":"sm","options":_vm.getPeriodStudent()},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Seleccione una de las opciones")])]},proxy:true}]),model:{value:(_vm.selected_period),callback:function ($$v) {_vm.selected_period=$$v},expression:"selected_period"}})],1)],1),(_vm.selected_period)?_c('div',{staticClass:"row"},[_c('table',{staticClass:"qualifications-table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"th-qualifications"},[_vm._v("Sección")]),_c('th',{staticClass:"th-qualifications"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" ))+" ")]),_vm._l((_vm.sizeEvaluation()),function(n){return _c('th',{key:n,staticClass:"th-qualifications"},[_vm._v(" Nota: "+_vm._s(n)+" ")])}),_c('th',{staticClass:"th-qualifications"},[_vm._v("Nota Ponderada")])],2)]),_c('tbody',_vm._l((_vm.filtersSection),function(section){return _c('tr',{key:section.id,staticClass:"tr-qualifications"},[_c('td',{staticClass:"td-qualifications"},[_vm._v(_vm._s(section.name))]),_c('td',{staticClass:"td-qualifications"},[_vm._v(" "+_vm._s(_vm.getMatterName(section.matter))+" ")]),_vm._l((_vm.filterEvaluation(section)),function(evaluation){return _c('td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
                evaluation.name + ' - ' + _vm.weighingEvaluation(evaluation) + '%'
              ),expression:"\n                evaluation.name + ' - ' + weighingEvaluation(evaluation) + '%'\n              ",modifiers:{"v-secondary":true}}],key:evaluation.id,staticClass:"td-qualifications"},[_c('div',{class:{
                  'red-score': _vm.score(evaluation) < 4,
                  'yellow-score':
                    _vm.score(evaluation) >= 4 && _vm.score(evaluation) < 5.5,
                  'green-score': _vm.score(evaluation) >= 5.5,
                }},[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.score(evaluation)))+" ")])])}),(_vm.sizeEvaluation() > _vm.compareColumnNumber(section))?_vm._l((_vm.sizeEvaluation() - _vm.compareColumnNumber(section)),function(n){return _c('td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:('Sin medición'),expression:"'Sin medición'",modifiers:{"v-secondary":true}}],key:n},[_vm._v(" - ")])}):_vm._e(),_c('td',{staticClass:"td-qualifications"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(_vm.finalScore(section).weighing + '%'),expression:"finalScore(section).weighing + '%'",modifiers:{"v-secondary":true}}],class:{
                  'red-score': _vm.finalScore(section).score < 4,
                  'yellow-score':
                    _vm.finalScore(section).score >= 4 &&
                    _vm.finalScore(section).score < 5.5,
                  'green-score': _vm.finalScore(section).score >= 5.5,
                }},[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.finalScore(section).score))+" ")])])],2)}),0)])]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }