<template>
  <div
    :class="{ 'descriptor-container': !ProgramView, grabbable: allows_crud }"
  >
    <draggable v-model="myList" :disabled="!allows_crud">
      <transition-group>
        <div
          :class="{
            'program-alter-container mb-2': ProgramView,
          }"
          v-for="element in myList"
          :key="element.id"
        >
          <div
            v-if="
              Title.find(
                (x) =>
                  x.id == element.descriptor && x.page_position == PagePosition
              ) &&
              (element.is_visible || allows_crud) &&
              (element.description.trim() != 0 ||
                allows_crud ||
                Title.find((x) => x.id == element.descriptor).is_default)
            "
            class="mb-2 outlined-div-secondary"
            :class="{ 'outlined-tlr-div-black': ProgramView }"
          >
            <div
              v-if="
                element.description.trim() != 0 ||
                allows_crud ||
                Title.find((x) => x.id == element.descriptor).is_default
              "
              class="descriptor-title"
              :class="{
                'text-light border-bottom-black btn-center': ProgramView,
              }"
            >
              <span class="descriptor-order" v-if="VisibleOrder">
                {{ element.order }}
              </span>
              <strong :class="{ 'custom-title': !ProgramView }">{{
                Title.find((x) => x.id == element.descriptor).title
              }}</strong>
              <template v-if="allows_crud">
                <span>
                  <b-button
                    v-if="show_visible"
                    size="sm"
                    class="ml-1 p-0"
                    variant="none"
                    @click="showDescriptor(element)"
                  >
                    <template v-if="element.is_visible">
                      <b-icon
                        class="btn-actions"
                        icon="eye"
                        v-b-tooltip.v-secondary.noninteractive="`Mostrar`"
                        scale="1"
                      ></b-icon>
                    </template>
                    <template v-else>
                      <b-icon
                        class="btn-actions"
                        v-b-tooltip.v-secondary.noninteractive="`Ocultar`"
                        icon="eye-slash"
                        scale="1"
                      ></b-icon>
                    </template>
                  </b-button>
                </span>
                <button-edit
                  class="ml-1 p-0"
                  @click="
                    slotUpdatedTitle(
                      Title.find((x) => x.id == element.descriptor)
                    )
                  "
                  v-b-tooltip.v-secondary.noninteractive="`Editar`"
                >
                </button-edit>
                <button-delete
                  class="ml-1 p-0"
                  v-if="allows_crud"
                  @click="deleteDescriptors(element)"
                  v-b-tooltip.v-secondary.noninteractive="`Eliminar`"
                ></button-delete>
              </template>
            </div>
            <div>
              <div
                class="descriptor-body pt-2 pb-3 rich-text-content"
                v-html="element.description"
              ></div>
              <div v-if="allows_crud">
                <button-edit
                  class="btn-center"
                  @click="
                    $bvModal.show(
                      `${ref_component}edit-description-modal-${element.id}`
                    )
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `${Title.find((x) => x.id == element.descriptor).title}`
                  "
                >
                </button-edit>
                <b-modal
                  :id="`${ref_component}edit-description-modal-${element.id}`"
                  :title="`${
                    Title.find((x) => x.id == element.descriptor).title
                  }`"
                  size="lg"
                  hide-footer
                >
                  <template v-if="rich_text_editor">
                    <RichTextEditor
                      :Object="element"
                      :Text="element.description"
                      @update="updateDescription"
                      @close="
                        $bvModal.hide(
                          `${ref_component}edit-description-modal-${element.id}`
                        )
                      "
                    ></RichTextEditor>
                  </template>
                  <template v-else>
                    <div>
                      <TextArea
                        :showSaveBtn="true"
                        :clearTextArea="false"
                        :Text="element.description"
                        @update="
                          (Text) => {
                            updateDescription(element, Text);
                            $bvModal.hide(
                              `${ref_component}edit-description-modal-${element.id}`
                            );
                          }
                        "
                      ></TextArea>
                    </div>
                  </template>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </draggable>

    <div v-for="item in Title" :key="item.id">
      <div
        class="my-2 outlined-div-secondary"
        :class="{ 'outlined-div-black': ProgramView }"
        v-if="
          (myList.filter((x) => x.descriptor == item.id).length == 0 &&
            item.is_default) ||
          (myList.filter((x) => x.descriptor == item.id).length == 0 &&
            allows_crud)
        "
      >
        <div
          class="descriptor-title"
          :class="{
            'border-bottom-black': ProgramView && allows_crud,
            'text-light btn-center': ProgramView,
          }"
        >
          <strong>{{ item.title }}</strong>
          <template v-if="allows_crud">
            <span
              v-if="
                institution &&
                institution.id == 12 &&
                (item.id == 26 || item.id == 62) &&
                ProgramView
              "
              v-b-tooltip.v-secondary.auto.noninteractive="
                `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`
              "
            >
              <button-edit disabled></button-edit>
            </span>
            <button-edit
              v-else
              class="m-0 ml-1"
              @click="slotUpdatedTitle(item)"
              v-b-tooltip.v-secondary.noninteractive="`Editar`"
            >
            </button-edit>
            <span
              v-if="
                institution &&
                institution.id == 12 &&
                (item.id == 26 || item.id == 62) &&
                ProgramView
              "
              v-b-tooltip.v-secondary.auto.noninteractive="
                `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`
              "
            >
              <button-delete disabled></button-delete>
            </span>
            <button-delete
              v-else
              class="ml-1 p-0"
              @click="deleteTitle(item.id)"
              v-b-tooltip.v-secondary.noninteractive="`Eliminar`"
            ></button-delete>
          </template>
        </div>
        <!-- class="descriptor-body" -->
        <div v-if="allows_crud">
          <button-add
            class="btn-center"
            @click="
              $bvModal.show(`${ref_component}add-description-modal-${item.id}`)
            "
            v-b-tooltip.v-secondary.noninteractive="`${item.title}`"
          >
          </button-add>
          <b-modal
            :id="`${ref_component}add-description-modal-${item.id}`"
            :title="`${item.title}`"
            size="lg"
            hide-footer
          >
            <template v-if="rich_text_editor">
              <RichTextEditor
                :Type="item"
                @create="createDescription"
                @close="
                  $bvModal.hide(
                    `${ref_component}add-description-modal-${item.id}`
                  )
                "
              ></RichTextEditor>
            </template>
            <template v-else>
              <div>
                <TextArea
                  :showSaveBtn="true"
                  :clearTextArea="false"
                  @update="
                    (Text) => {
                      createDescription(Text, item);
                      $bvModal.hide(
                        `${ref_component}add-description-modal-${item.id}`
                      );
                    }
                  "
                ></TextArea>
              </div>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
    <b-modal
      :id="`${ref_component}title-modal`"
      :title="isNaN(title_proccess.id) ? `Crear título` : `Editar título`"
      size="lg"
      @hide="resetTitleModal"
      hide-footer
    >
      <b-form-group>
        <label for="descriptor-title-input" class="descriptor-title-label"
          >Título</label
        >
        <!-- :state="title_proccess.title.trim().length > 0" -->
        <b-form-input
          id="descriptor-title-input"
          v-model="title_proccess.title"
          aria-describedby="input-title-live-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-title-live-feedback">
          <div>Este campo debe tener al menos 1 caracteres.</div>
        </b-form-invalid-feedback>
        <b-form-checkbox
          v-if="show_is_default"
          class="descriptor-title-check w-25"
          v-model="title_proccess.is_default"
          >Predeterminado
          <InfoTooltip
            class="selection-info ml-0"
            :tooltip_text="`${Text_info_tooltip}`"
          >
          </InfoTooltip>
        </b-form-checkbox>
      </b-form-group>
      <b-button
        size="sm"
        variant="primary"
        class="float-right"
        @click="save(title_proccess)"
      >
        <!-- :disabled="title_proccess.title.trim().length < 0" -->
        Guardar Título
      </b-button>
    </b-modal>
    <div>
      <b-button
        v-if="allows_crud"
        size="sm"
        class="secondary-button my-2"
        @click="$bvModal.show(`${ref_component}title-modal`)"
        ><b-icon-plus></b-icon-plus>Agregar Descriptor</b-button
      >
      <b-button
        v-if="PagePosition == 2 && ref_component == 'EgressProfileDescriptor2'"
        variant="secondary"
        size="sm"
        class="secondary-button my-2 ml-3 noprint"
        @click="$bvModal.show('egressprofile-file-modal')"
      >
        Anexos
      </b-button>
    </div>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "DescriprosContainer",
  components: {
    draggable,
    TextArea: () => import("@/components/reusable/TextArea"),
    RichTextEditor: () => import("@/components/reusable/RichTextEditor"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    ref_component: {
      type: String,
      default: "",
    },
    Title: {
      type: Array,
    },
    Descriptors: {
      type: Array,
    },
    Title_text: {
      type: String,
      default: "",
    },
    Text_info_tooltip: {
      type: String,
      default: "",
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    Order: {
      type: Number,
      default: 1,
    },
    PagePosition: {
      type: Number,
      default: 1,
    },
    VisibleOrder: {
      type: Boolean,
      default: false,
    },
    ProgramView: {
      type: Boolean,
      default: false,
    },
    show_is_default: {
      type: Boolean,
      default: true,
    },
    show_visible: {
      type: Boolean,
      default: true,
    },
    rich_text_editor: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      title_proccess: {
        id: generateUniqueId(),
        title: "",
        page_position: this.PagePosition,
        is_default: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
    myList: {
      get() {
        let list = this.Descriptors;
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        return list;
      },
      set(list) {
        let order = this.Order;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        this.$emit("updateOrder", list);
      },
    },
  },
  methods: {
    showDescriptor(item) {
      this.$emit("updateState", item);
    },
    slotUpdatedTitle(item) {
      this.title_proccess.id = item.id;
      this.title_proccess.title = item.title;
      this.title_proccess.is_default = item.is_default;
      this.$bvModal.show(`${this.ref_component}title-modal`);
    },
    resetTitleModal() {
      this.title_proccess.id = generateUniqueId();
      this.title_proccess.title = "";
      this.title_proccess.is_default = false;
    },
    updateDescription(object, text) {
      this.$emit("updateDescription", object, text);
    },
    save(title) {
      title.page_position = this.PagePosition;
      if (isNaN(title.id)) {
        this.$emit("createTitle", title);
        this.$bvModal.hide(`${this.ref_component}title-modal`);
      } else {
        this.$swal({
          title: "¿Está seguro de que desea editar el Descriptor?",
          text: `"Esto podria afectar a otros ${this.Title_text}!"`,
          type: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            this.$emit("updateTitle", title);
            this.$bvModal.hide(`${this.ref_component}title-modal`);
          }
        });
      }
    },
    deleteDescriptors(item) {
      if (item) {
        if (!isNaN(item.id) && !isNaN(item.descriptor)) {
          this.$emit("deleteDescriptors", item);
        }
      }
    },
    deleteTitle(id) {
      if (!isNaN(id)) {
        this.$emit("deleteTitle", id);
      }
    },
    createDescription(text, object) {
      this.$emit("createDescription", text, object);
    },
  },
  created() {},
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.grabbing * {
  cursor: grabbing !important;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.descriptor-container {
  padding-inline: 5px;
}
.program-alter-container {
  padding-inline: 0;
}
.descriptor-order {
  float: left;
  padding-block: 0.3em;
  padding-inline: 7vh;
  border-right: 1px solid var(--secondary-color);
  transform: translateY(-15%);
}
.descriptor-title {
  background-color: var(--primary-color);
  padding-block: 0.3em;
}
.custom-title {
  margin-left: -10vh;
}
.descriptor-body {
  display: block;
  text-align: justify;
  padding: 0.4em;
  padding-inline: 1em;
}
.descriptor-body >>> p {
  margin-bottom: 0 !important;
}
.btn-center {
  display: flex;
  justify-content: center !important;
  margin-inline: auto !important;
}
.outlined-lr-div-secondary {
  border-inline: 1px solid var(--secondary-color) !important;
}
.outlined-lr-div-black {
  border-inline: 1px solid black !important;
}
.outlined-tlr-div-black {
  border: 1px solid black !important;
}
.outlined-div-black {
  border: 1px solid black !important;
}
.outlined-div-secondary {
  border: 1px solid var(--secondary-color);
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
.border-bottom-black {
  border-bottom: 1px solid black !important;
}
.border-bottom-secondary {
  border-bottom: 1px solid var(--secondary-color) !important;
}
.selection-info {
  transform: translate(10%, -60%);
  width: 12px;
  height: 12px;
}
.descriptor-title-label {
  margin-right: 3em;
  margin-left: 1.5em;
}
#descriptor-title-input {
  width: 80%;
  margin-left: auto;
  margin-right: 0;
}
.descriptor-title-check {
  margin-left: 7em;
}
@media (max-width: 991px) {
  #descriptor-title-input {
    width: 70%;
  }
  .selection-info {
    transform: translate(1000%, -260%);
  }
}
@media (max-width: 450px) {
  .descriptor-title-check {
    margin-left: 1em;
  }
}
</style>