var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_autonomousteachingactivity'),expression:"'mesh.add_autonomousteachingactivity'"}],staticClass:"btn btn-secondary btn-sm btn-add",on:{"click":function($event){return _vm.$bvModal.show(`new-autonomous-teaching-activity-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames( "mesh.autonomousteachingactivity", false, "Actividad de Trabajo Autónomo" ))+" ")]):_vm._e()])]),_c('h3',{staticClass:"title-header noprint"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.autonomousteachingactivity", true, "Actividades de Trabajo Autónomo" ))+" ")]),(_vm.autonomous_teaching_activities.length > 0)?_c('GenericBTable',{attrs:{"filterCustom":_vm.filterCustom,"items":_vm.autonomous_teaching_activities,"fields":_vm.autonomousTeachingActivitiesFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":'autonomous_teaching_activities',"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_autonomousteachingactivity'),expression:"'mesh.change_autonomousteachingactivity'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            'Editar Actividad de Trabajo Autónomo'
          ),expression:"\n            'Editar Actividad de Trabajo Autónomo'\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(
              `edit-autonomous-teaching-activity-modal-${row.item.id}`
            )}}}),_c('button-delete',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_autonomousteachingactivity'),expression:"'mesh.delete_autonomousteachingactivity'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            'Eliminar Actividad de Trabajo Autónomo'
          ),expression:"\n            'Eliminar Actividad de Trabajo Autónomo'\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteAutonomousTeachingActivity(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-autonomous-teaching-activity-modal-${row.item.id}`,"title":"Editar Actividad de Trabajo Autónomo","size":"md","hide-footer":""}},[_c('AutonomousTeachingActivityForm',{attrs:{"AutonomousTeachingActivity":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedAutonomousTeachingActivity}})],1)]}}:null],null,true)}):_c('div',[_c('br'),_c('strong',[_vm._v("No se a encontrado ninguna "+_vm._s(_vm.$getVisibleNames( "mesh.autonomousteachingactivity", false, "Actividad de Trabajo Autónomo" )))])])],1),_c('b-modal',{attrs:{"id":`new-autonomous-teaching-activity-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.autonomousteachingactivity',
      false,
      'Actividad de Trabajo Autónomo'
    )}`,"size":"lg"}},[_c('AutonomousTeachingActivityForm',{attrs:{"institution_id":_vm.user.school},on:{"created":_vm.slotCreatedAutonomousTeachingActivity}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }