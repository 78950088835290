<template>
  <div class="mesh-container">
    <!-- <PrintFab>
      <b-button size="sm" variant="none" @click="generateReport">
        <b-icon-printer></b-icon-printer>
      </b-button>
    </PrintFab> -->
    <div class="d-flex justify-content-center mt-4">
      <div class="w-25">
        <template v-if="cyclesList.length > 0">
          <b-form-group
            :label="$getVisibleNames('mesh.cycle', true, 'Ciclos Formativos')"
            label-cols="0"
            label-cols-sm="2"
            class="text-center middle"
          >
            <b-form-select
              :options="cyclesList"
              v-model="cycle_id"
              text-field="name"
              value-field="id"
              size="sm"
            >
              <b-form-select-option :value="null"> Todos </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </template>
      </div>
    </div>

    <CurricularMesh
      :egress_profile_id="egress_profile_id"
      :allows_crud="allows_crud"
      :cycle_id="cycle_id"
    ></CurricularMesh>

    <MeshCustomFab
      :egress_profile_id="egress_profile_id"
      :show_curricular_mesh="false"
    ></MeshCustomFab>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "CurricularMeshView",
  components: {
    CurricularMesh: () => import("./CurricularMesh"),
    MeshCustomFab: () => import("../MeshCustomFab"),
  },
  data() {
    return {
      egress_profile_id: Number(this.$route.params.egress_profile_id),
      cycle_id: null,
      active: false,
    };
  },
  computed: {
    ...mapGetters({
      cycles: names.CYCLES,
      profile_cycles: names.PROFILE_CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      user: "getUser",
      user_position: names.USER_POSITION,
    }),
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    allows_crud() {
      if (
        this.career &&
        this.egressProfile &&
        !this.egressProfile.is_closed &&
        ((this.user_position &&
          [1, 2].includes(this.user_position.position) &&
          [1, 2].includes(this.user.groups[0])) ||
          this.user.is_superuser ||
          this.$hasObjectPermission(
            "mesh.faculty",
            "change",
            this.career.faculty
          ) ||
          this.$hasObjectPermission(
            "mesh.career",
            "change",
            this.egressProfile.career
          ))
      ) {
        const has_permission = this.$hasPermissions(
          "mesh.change_egressprofile"
        );
        if (has_permission) {
          this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
          return has_permission;
        } else {
          return false;
        }
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
    egressProfileCycleId() {
      if (!this.egress_profile_id) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egress_profile_id) list.push(x.cycle);
      });
      return list;
    },
    cyclesList() {
      if (!this.egressProfile) return [];
      let cycles = this.cycles.filter((x) =>
        this.egressProfileCycleId.includes(x.id)
      );
      cycles.forEach((x) => {
        let cycle_list = this.profile_cycles.find(
          (profile_cycle) =>
            profile_cycle.cycle == x.id &&
            profile_cycle.egress_profile == this.egress_profile_id
        );
        if (cycle_list) {
          x.init_semester = cycle_list.init_semester;
          x.end_semester = cycle_list.end_semester;
          x.order = cycle_list.order;
        }
      });
      return cycles.sort(function (a, b) {
        if (a.init_semester < b.init_semester) return -1;
        if (a.init_semester > b.init_semester) return 1;
        if (a.end_semester < b.end_semester) return -1;
        if (a.end_semester > b.end_semester) return 1;
        return 0;
      });
    },
  },
  methods: {
    printingOrientationLandscape() {
      var css = "@media print {@page { size: A4 landscape; }}",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      head.appendChild(style);
    },
    printingOrientationPortrait() {
      var css = "@media print {@page { size: A4 portrait !important; }}",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      head.appendChild(style);
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_CURRENT_POSITION, this.user.id);
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id)
      .then((response) => {
        this.$store.dispatch(names.FETCH_CAREER, response.career);
      });
    this.$store.dispatch(names.FETCH_CYCLES);
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_EP_MATTER_TIME_ALLOCATIONS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_MATTER_TIME_ALLOCATIONS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_MATTERS, {
      egress_profile_id: this.egress_profile_id,
    });
  },
  mounted() {
    window.scrollTo(0, 0);
    this.printingOrientationLandscape();
  },
  beforeDestroy() {
    this.printingOrientationPortrait();
  },
};
</script>

<style scoped>
#hintCard {
  position: absolute;
  z-index: 9;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  text-align: center;
  width: 280px;
  height: 142px auto;
  top: 20%;
  left: 70%;
}
#hintCardHeader {
  padding: 5px;
  cursor: move;
  z-index: 10;
  background-color: #7c7c7c;
  color: #fff;
  font-size: small;
  font-weight: bold;
  margin-bottom: 0.7em;
}
.hint {
  color: white;
  background-color: #f06643;
  height: 16px;
  width: 32px;
  font-size: 10px;
  font-weight: bold;
  border: 1px solid #7c7c7c;
  margin-bottom: 0.3em;
  margin-left: 1em;
  padding-left: 8px;
  padding-bottom: 1px;
  display: flex;
}
.hintCaption {
  font-size: 11px;
  color: #2e2e2e;
  white-space: nowrap;
  top: 1px;
  padding-left: 16px;
}
#button {
  width: 23px;
  height: 20px;
  float: right;
  cursor: pointer;
  color: white;
}
.totalTitle {
  background-color: var(--secondary-color);
  border: 2px solid white;
  color: var(--secondary-font-color);
  max-width: 70px !important;
  font-size: var(--thirth-font-size);
}
.total {
  border: 1px solid #d3d3d3;
  font-size: 12px;
}
.total td {
  padding: 10px 5px;
  border: 1px solid #d3d3d3;
}
.total:nth-of-type(even) {
  background-color: #f0f0f0;
}
.table-container {
  overflow: scroll;
  height: 80%;
}
@media print {
  /* @page {
    size: landscape letter;
  } */

  .mesh-container {
    min-height: 115vh;
    width: 100% !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
    zoom: 56%;
    display: table;
    table-layout: inherit;
    width: 100%;
    margin: 0 !important;
  }
}
</style>