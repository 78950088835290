<template>
  <div>
    <div class="card mb-3 mt-3">
      <div class="text-container">
        <div class="text">
          <div v-html="Comment.comment"></div>
        </div>
        <div class="description card">
          <div class="title">
            {{ getUser + " - " }}
            {{ Comment.creation_date | FormatToDateTime }}
            <b-button
              v-if="user && Comment.created_by == user.id"
              size="sm"
              variant="none"
              @click="askForDeleteComment(Comment.id)"
            >
              <b-icon-trash></b-icon-trash>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "ActComments",
  props: {
    Comment: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      user: "getUser",
    }),
    getUser() {
      const user = this.users.find((x) => x.id == this.Comment.created_by);
      return user ? user.first_name + " " + user.last_name : "";
    },
  },
  methods: {
    askForDeleteComment(id) {
      this.$emit("delete_comment", id);
    },
  },
  created() {
    if (this.Comment.created_by)
      this.$store.dispatch(names.FETCH_USER, this.Comment.created_by);
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  height: min-content;
  transition: all 0.3s;
  position: relative;
  border-radius: 0.5rem 0.5rem;
  box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.5);
}

.card:hover {
  transform: scale(1.0081);
}

.text {
  background-color: var(--primary-color);
  text-align: justify;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-left: 0.8rem;
  padding-bottom: 1rem;
  width: 100%;
}

.text-container {
  background-color: var(--primary-color) !important;
  width: 100% !important;
  display: grid;
  border-radius: 0.5rem 0.5rem;
  height: auto;
  min-height: 1rem;
  overflow: hidden;
}

.description {
  position: absolute;
  bottom: -0.5rem;
  right: 0.5rem;
  text-align: end;
  padding: 0.2rem 1em;
  width: auto;
  transition: all 0.5s ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  backdrop-filter: blur(0.1rem);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem 0.5rem;
}

.description:hover {
  transform: perspective(1000px) translateX(7px) rotateX(3deg) rotateY(3deg)
    scale(1);
  box-shadow: none;
}

.title {
  color: aliceblue;
}
</style>