import Vue from "vue";
import Router from "vue-router";

import public_routes from "./public"
import mesh_routes from "./mesh"
import teaching_routes from "./teaching"
import evaluation_routes from "./evaluations"
import report_routes from "./reports"

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/register',
            name: 'Register',
            component: () =>
                import("@/components/users/Register"),
        },
        {
            path: '/profile',
            name: 'Profile',
            component: () =>
                import("@/components/users/Profile"),
        },
        {
            path: '/students/',
            name: 'StudentListView',
            component: () =>
                import("@/components/users/StudentListView"),
        },
        {
            path: '/user-permissions',
            name: 'PermissionsView',
            component: () =>
                import("@/components/users/PermissionsView"),
        },
        {
            path: '/admin-user',
            name: 'AdminUsersView',
            component: () =>
                import("@/components/users/AdminUsersView"),
        },
        {
            path: '/dashboard/:matter_id/:tab_index/:section_id/:evaluation_id',
            name: 'Dashboard',
            component: () =>
                import("@/components/dashboard/Dashboard"),
        },
        {
            path: '/student-dashboard',
            name: 'StudentDashboardView',
            component: () =>
                import("@/components/dashboard/StudentDashboard/StudentDashboardView"),
        },
        {
            path: '/evaluation/assessment/:section_id/',
            name: 'AssessmentView',
            component: () =>
                import("@/components/dashboard/Assessment/AssessmentView"),
        },
        {
            path: '/evaluation/achievements/',
            name: 'AchievementsPerCompetenceView',
            component: () =>
                import("@/components/dashboard/AchievementsPerCompetence/AchievementsPerCompetenceView"),
        },
        {
            path: '/session-planning/:egress_profile_matter_id/:section_id/',
            name: 'SessionPlanningView',
            component: () =>
                import("@/components/dashboard/SessionPlanning/SessionPlanningView"),
        },
        {
            path: '/evaluation/assessment-report/:section_id/',
            name: 'AssessmentReportView',
            component: () =>
                import("@/components/dashboard/AssessmentReport/AssessmentReportView"),
        },
        {
            path: '/acts/form/',
            name: 'ActsView',
            component: () =>
                import("@/components/acts/ActView"),
        },
        {
            path: '/admission/form/',
            name: 'AdmissionProfileView',
            component: () =>
                import("@/components/cideu/AdmissionProfileView"),
        },
        {
            path: '/student/form/',
            name: 'StudentCardView',
            component: () =>
                import("@/components/cideu/StudentCard/StudentCardView"),
        },
        ...public_routes,
        ...mesh_routes,
        ...teaching_routes,
        ...evaluation_routes,
        ...report_routes,
    ],
    mode: 'history'
});