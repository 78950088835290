var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'descriptor-container': !_vm.ProgramView, grabbable: _vm.allows_crud }},[_c('draggable',{attrs:{"disabled":!_vm.allows_crud},model:{value:(_vm.myList),callback:function ($$v) {_vm.myList=$$v},expression:"myList"}},[_c('transition-group',_vm._l((_vm.myList),function(element){return _c('div',{key:element.id,class:{
          'program-alter-container mb-2': _vm.ProgramView,
        }},[(
            _vm.Title.find(
              (x) =>
                x.id == element.descriptor && x.page_position == _vm.PagePosition
            ) &&
            (element.is_visible || _vm.allows_crud) &&
            (element.description.trim() != 0 ||
              _vm.allows_crud ||
              _vm.Title.find((x) => x.id == element.descriptor).is_default)
          )?_c('div',{staticClass:"mb-2 outlined-div-secondary",class:{ 'outlined-tlr-div-black': _vm.ProgramView }},[(
              element.description.trim() != 0 ||
              _vm.allows_crud ||
              _vm.Title.find((x) => x.id == element.descriptor).is_default
            )?_c('div',{staticClass:"descriptor-title",class:{
              'text-light border-bottom-black btn-center': _vm.ProgramView,
            }},[(_vm.VisibleOrder)?_c('span',{staticClass:"descriptor-order"},[_vm._v(" "+_vm._s(element.order)+" ")]):_vm._e(),_c('strong',{class:{ 'custom-title': !_vm.ProgramView }},[_vm._v(_vm._s(_vm.Title.find((x) => x.id == element.descriptor).title))]),(_vm.allows_crud)?[_c('span',[(_vm.show_visible)?_c('b-button',{staticClass:"ml-1 p-0",attrs:{"size":"sm","variant":"none"},on:{"click":function($event){return _vm.showDescriptor(element)}}},[(element.is_visible)?[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Mostrar`),expression:"`Mostrar`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-actions",attrs:{"icon":"eye","scale":"1"}})]:[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Ocultar`),expression:"`Ocultar`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-actions",attrs:{"icon":"eye-slash","scale":"1"}})]],2):_vm._e()],1),_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Editar`),expression:"`Editar`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-1 p-0",on:{"click":function($event){_vm.slotUpdatedTitle(
                    _vm.Title.find((x) => x.id == element.descriptor)
                  )}}}),(_vm.allows_crud)?_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Eliminar`),expression:"`Eliminar`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-1 p-0",on:{"click":function($event){return _vm.deleteDescriptors(element)}}}):_vm._e()]:_vm._e()],2):_vm._e(),_c('div',[_c('div',{staticClass:"descriptor-body pt-2 pb-3 rich-text-content",domProps:{"innerHTML":_vm._s(element.description)}}),(_vm.allows_crud)?_c('div',[_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `${_vm.Title.find((x) => x.id == element.descriptor).title}`
                ),expression:"\n                  `${Title.find((x) => x.id == element.descriptor).title}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-center",on:{"click":function($event){return _vm.$bvModal.show(
                    `${_vm.ref_component}edit-description-modal-${element.id}`
                  )}}}),_c('b-modal',{attrs:{"id":`${_vm.ref_component}edit-description-modal-${element.id}`,"title":`${
                  _vm.Title.find((x) => x.id == element.descriptor).title
                }`,"size":"lg","hide-footer":""}},[(_vm.rich_text_editor)?[_c('RichTextEditor',{attrs:{"Object":element,"Text":element.description},on:{"update":_vm.updateDescription,"close":function($event){return _vm.$bvModal.hide(
                        `${_vm.ref_component}edit-description-modal-${element.id}`
                      )}}})]:[_c('div',[_c('TextArea',{attrs:{"showSaveBtn":true,"clearTextArea":false,"Text":element.description},on:{"update":(Text) => {
                          _vm.updateDescription(element, Text);
                          _vm.$bvModal.hide(
                            `${_vm.ref_component}edit-description-modal-${element.id}`
                          );
                        }}})],1)]],2)],1):_vm._e()])]):_vm._e()])}),0)],1),_vm._l((_vm.Title),function(item){return _c('div',{key:item.id},[(
        (_vm.myList.filter((x) => x.descriptor == item.id).length == 0 &&
          item.is_default) ||
        (_vm.myList.filter((x) => x.descriptor == item.id).length == 0 &&
          _vm.allows_crud)
      )?_c('div',{staticClass:"my-2 outlined-div-secondary",class:{ 'outlined-div-black': _vm.ProgramView }},[_c('div',{staticClass:"descriptor-title",class:{
          'border-bottom-black': _vm.ProgramView && _vm.allows_crud,
          'text-light btn-center': _vm.ProgramView,
        }},[_c('strong',[_vm._v(_vm._s(item.title))]),(_vm.allows_crud)?[(
              _vm.institution &&
              _vm.institution.id == 12 &&
              (item.id == 26 || item.id == 62) &&
              _vm.ProgramView
            )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
              `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`
            ),expression:"\n              `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`\n            ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}]},[_c('button-edit',{attrs:{"disabled":""}})],1):_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Editar`),expression:"`Editar`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"m-0 ml-1",on:{"click":function($event){return _vm.slotUpdatedTitle(item)}}}),(
              _vm.institution &&
              _vm.institution.id == 12 &&
              (item.id == 26 || item.id == 62) &&
              _vm.ProgramView
            )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
              `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`
            ),expression:"\n              `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`\n            ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}]},[_c('button-delete',{attrs:{"disabled":""}})],1):_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Eliminar`),expression:"`Eliminar`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-1 p-0",on:{"click":function($event){return _vm.deleteTitle(item.id)}}})]:_vm._e()],2),(_vm.allows_crud)?_c('div',[_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`${item.title}`),expression:"`${item.title}`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-center",on:{"click":function($event){return _vm.$bvModal.show(`${_vm.ref_component}add-description-modal-${item.id}`)}}}),_c('b-modal',{attrs:{"id":`${_vm.ref_component}add-description-modal-${item.id}`,"title":`${item.title}`,"size":"lg","hide-footer":""}},[(_vm.rich_text_editor)?[_c('RichTextEditor',{attrs:{"Type":item},on:{"create":_vm.createDescription,"close":function($event){return _vm.$bvModal.hide(
                  `${_vm.ref_component}add-description-modal-${item.id}`
                )}}})]:[_c('div',[_c('TextArea',{attrs:{"showSaveBtn":true,"clearTextArea":false},on:{"update":(Text) => {
                    _vm.createDescription(Text, item);
                    _vm.$bvModal.hide(
                      `${_vm.ref_component}add-description-modal-${item.id}`
                    );
                  }}})],1)]],2)],1):_vm._e()]):_vm._e()])}),_c('b-modal',{attrs:{"id":`${_vm.ref_component}title-modal`,"title":isNaN(_vm.title_proccess.id) ? `Crear título` : `Editar título`,"size":"lg","hide-footer":""},on:{"hide":_vm.resetTitleModal}},[_c('b-form-group',[_c('label',{staticClass:"descriptor-title-label",attrs:{"for":"descriptor-title-input"}},[_vm._v("Título")]),_c('b-form-input',{attrs:{"id":"descriptor-title-input","aria-describedby":"input-title-live-feedback","size":"sm"},model:{value:(_vm.title_proccess.title),callback:function ($$v) {_vm.$set(_vm.title_proccess, "title", $$v)},expression:"title_proccess.title"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-title-live-feedback"}},[_c('div',[_vm._v("Este campo debe tener al menos 1 caracteres.")])]),(_vm.show_is_default)?_c('b-form-checkbox',{staticClass:"descriptor-title-check w-25",model:{value:(_vm.title_proccess.is_default),callback:function ($$v) {_vm.$set(_vm.title_proccess, "is_default", $$v)},expression:"title_proccess.is_default"}},[_vm._v("Predeterminado "),_c('InfoTooltip',{staticClass:"selection-info ml-0",attrs:{"tooltip_text":`${_vm.Text_info_tooltip}`}})],1):_vm._e()],1),_c('b-button',{staticClass:"float-right",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.save(_vm.title_proccess)}}},[_vm._v(" Guardar Título ")])],1),_c('div',[(_vm.allows_crud)?_c('b-button',{staticClass:"secondary-button my-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`${_vm.ref_component}title-modal`)}}},[_c('b-icon-plus'),_vm._v("Agregar Descriptor")],1):_vm._e(),(_vm.PagePosition == 2 && _vm.ref_component == 'EgressProfileDescriptor2')?_c('b-button',{staticClass:"secondary-button my-2 ml-3 noprint",attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show('egressprofile-file-modal')}}},[_vm._v(" Anexos ")]):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }