<template>
  <div>
    <template v-if="loading"> Cargando... </template>
    <template v-else>
      <div class="row">
        <b-form-group
          :label="$getVisibleNames('mesh.period', false, 'Período')"
          label-cols="0"
          label-cols-sm="2"
          label-cols-md="3"
          label-cols-lg="4"
          class="my-0 py-0 w-100"
        >
          <b-form-select
            value-field="id"
            text-field="name"
            size="sm"
            v-model="selected_period"
            :options="getPeriodStudent()"
            ><template #first>
              <b-form-select-option :value="null"
                >Seleccione una de las opciones</b-form-select-option
              ></template
            >
          </b-form-select>
        </b-form-group>
      </div>
      <div class="row" v-if="selected_period">
        <table class="qualifications-table">
          <thead>
            <tr>
              <th class="th-qualifications">Sección</th>
              <th class="th-qualifications">
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    false,
                    "Asignatura"
                  )
                }}
              </th>
              <th
                class="th-qualifications"
                v-for="n in sizeEvaluation()"
                v-bind:key="n"
              >
                Nota: {{ n }}
              </th>
              <th class="th-qualifications">Nota Ponderada</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="tr-qualifications"
              v-for="section in filtersSection"
              v-bind:key="section.id"
            >
              <td class="td-qualifications">{{ section.name }}</td>
              <td class="td-qualifications">
                {{ getMatterName(section.matter) }}
              </td>
              <td
                class="td-qualifications"
                v-for="evaluation in filterEvaluation(section)"
                v-bind:key="evaluation.id"
                v-b-tooltip.v-secondary="
                  evaluation.name + ' - ' + weighingEvaluation(evaluation) + '%'
                "
              >
                <div
                  :class="{
                    'red-score': score(evaluation) < 4,
                    'yellow-score':
                      score(evaluation) >= 4 && score(evaluation) < 5.5,
                    'green-score': score(evaluation) >= 5.5,
                  }"
                >
                  {{ score(evaluation) | Round }}
                </div>
              </td>
              <template v-if="sizeEvaluation() > compareColumnNumber(section)">
                <td
                  v-for="n in sizeEvaluation() - compareColumnNumber(section)"
                  v-bind:key="n"
                  v-b-tooltip.v-secondary="'Sin medición'"
                >
                  -
                </td>
              </template>
              <td class="td-qualifications">
                <div
                  :class="{
                    'red-score': finalScore(section).score < 4,
                    'yellow-score':
                      finalScore(section).score >= 4 &&
                      finalScore(section).score < 5.5,
                    'green-score': finalScore(section).score >= 5.5,
                  }"
                  v-b-tooltip.v-secondary="finalScore(section).weighing + '%'"
                >
                  {{ finalScore(section).score | Round }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";

export default {
  name: "StudentEvaluationComponent",
  props: {
    User: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
      selected_period: null,
      evaluation_score: null,
      user: {
        id: this.User ? this.User.id : this.$store.state.user.id,
        sections_as_student: this.User
          ? this.User.sections_as_student
          : this.$store.state.user.sections_as_student,
      },
    };
  },
  computed: {
    ...mapGetters({
      evaluations: names.EVALUATIONS,
      period: names.PERIODS,
      section: names.SECTIONS,
      matter: names.MATTERS,
      study_unit: names.STUDY_UNITS,
    }),
    validateSection() {
      let vsection = [];
      this.section.forEach((item) => {
        let svalidate = this.user.sections_as_student.find((x) => x == item.id);
        if (svalidate) {
          vsection.push(item);
        }
      });
      return vsection;
    },
    filtersSection() {
      return this.validateSection.filter(
        (x) => x.period == this.selected_period
      );
    },
  },
  methods: {
    finalScore(section) {
      let finalScore = 0;
      let total_weighing = 0;
      this.study_unit.forEach((u) => {
        let score = 0;

        this.evaluations.forEach((x) => {
          if (x.section == section.id && x.type == 3 && x.study_unit == u.id) {
            if (this.evaluation_score) {
              let result = this.evaluation_score.find(
                (a) => a.evaluation == x.id && a.student == this.user.id
              );
              if (result) {
                total_weighing += (x.weighing * u.weighing) / 100;
                if (result.score) {
                  score += result.score * (x.weighing / 100);
                } else if (result.automated_score) {
                  score += result.automated_score * (x.weighing / 100);
                }
              }
            }
          }
        });
        finalScore += score * (u.weighing / 100);
      });
      return { score: finalScore, weighing: total_weighing };
    },
    weighingEvaluation(evaluation) {
      const weighing = this.study_unit.find(
        (x) => x.id == evaluation.study_unit
      );
      return weighing ? (evaluation.weighing * weighing.weighing) / 100 : "";
    },
    compareColumnNumber(section) {
      return this.evaluations.filter(
        (x) => x.section == section.id && x.type == 3
      ).length;
    },
    sizeEvaluation() {
      let max = 0;
      this.section.forEach((section) => {
        if (
          section.period == this.selected_period &&
          section.students.includes(this.user.id)
        ) {
          let filterEvaluation = this.evaluations.filter(
            (x) => x.section == section.id && x.type == 3
          );
          if (filterEvaluation) {
            if (max <= filterEvaluation.length) max = filterEvaluation.length;
          }
        }
      });
      return max;
    },
    score(evaluation) {
      const score = this.evaluation_score.find(
        (item) =>
          item.evaluation == evaluation.id && item.student == this.user.id
      );

      return score ? (score.score ? score.score : score.automated_score) : "";
    },
    filterEvaluation(section) {
      return this.evaluations.filter(
        (x) => x.section == section.id && x.type == 3
      );
    },
    getMatterName(matter_id) {
      const matter = this.matter.find((x) => x.id == matter_id);
      return matter ? matter.name : "";
    },
    getPeriodStudent() {
      let periodStudent = [];
      this.validateSection.forEach((item) => {
        let periodname = this.period.find((x) => x.id == item.period);
        if (periodname) {
          periodStudent.push(periodname);
        }
      });
      return [...new Set(periodStudent)];
    },
  },
  created() {
    // let matter_ids = [];
    this.user.sections_as_student.forEach((section) => {
      this.$store.dispatch(names.FETCH_SECTION, section).then((response) => {
        // if (!matter_ids.includes(response.matter))
        //   matter_ids.push(response.matter);
        this.$store.dispatch(names.FETCH_MATTER, response.matter);
        this.$store.dispatch(names.FETCH_STUDY_UNITS, response.matter);
      });
      this.$store.dispatch(names.FETCH_EVALUATIONS_BY_SECTION, section);
    });
    //  matter_ids.forEach(matter_id => {
    // No se recorre el array
    //  console.log(matter_id);
    //  this.$store.dispatch(names.FETCH_MATTER, matter_id);
    //  this.$store.dispatch(names.FETCH_STUDY_UNITS, matter_id);
    //  });
    this.$store.dispatch(names.FETCH_PERIODS);
    this.$restful
      .Get(`/evaluation/evaluation-score/?user=${this.user.id}`)
      .then((response) => {
        this.evaluation_score = response;
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.qualifications-table {
  width: 100%;
  border: 0.5px solid rgb(235, 235, 235);
}
.th-qualifications {
  background-color: var(--primary-color) !important;
  padding: 8px 16px;
  border: 0.7px solid #666;
}
.tr-qualifications {
  margin: 5px 0 !important;
}
.tr-qualifications:hover {
  background-color: rgb(238, 238, 238);
}
.td-qualifications {
  padding: 8px 0;
}
.td-final-score {
  margin: 8px 0;
}
.red-score {
  background-color: rgb(225, 109, 109);
}
.yellow-score {
  background-color: rgb(245, 227, 122);
}
.green-score {
  background-color: rgb(119, 225, 109);
}
</style>