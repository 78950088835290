<template>
  <div>
    <b-form-group
      v-if="!Career"
      :label="`${$getVisibleNames('mesh.career', false)}:`"
      label-for="select-career"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-select
        id="select-career"
        name="select-career"
        :options="careers"
        value-field="id"
        text-field="name"
        v-model="$v.certification.career.$model"
        @change="
          () => {
            certification.matters = [];
            fetchMatter();
          }
        "
        :state="validateState('career')"
        aria-describedby="select-career-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      >
        <template #first>
          <b-form-select-option :value="null">
            -- Seleccione
            {{ $getVisibleNames("mesh.career", false, "Programa") }} --
          </b-form-select-option>
        </template>
      </b-form-select>
      <b-form-invalid-feedback id="select-career-live-feedback"
        >Debe seleccionar
        {{
          $getVisibleNames("mesh.career", false, "Programa")
        }}.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Nombre:"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.certification.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
        class="input-name"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción:"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-description"
        name="input-description"
        v-model="$v.certification.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="
        $getVisibleNames('mesh.egressprofilematter', true, 'Asignaturas') + ':'
      "
      label-for="input-matters"
      label-cols="0"
      label-cols-sm="3"
      class="sm p-0 m-0"
    >
      <b-form-select
        v-if="prerequisitesOptions.length > 0"
        id="input-matters"
        v-model="$v.certification.matters.$model"
        :options="prerequisitesOptions"
        value-field="id"
        text-field="name"
        :state="validateState('matters')"
        aria-describedby="input-matters-feedback"
        size="sm"
        multiple
        :select-size="
          prerequisitesOptions.length <= 5
            ? prerequisitesOptions.length + 1
            : 15
        "
      ></b-form-select>
      <div class="pt-2" v-else>
        Esta {{ $getVisibleNames("mesh.career", false) }} no cuenta con
        {{ $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas") }}.
      </div>
      <b-form-invalid-feedback id="input-matters-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row mt-3">
      <div
        v-if="show_delete_button && !isNaN(this.certification.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDeleteObject"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0 mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "CertificationForm",
  mixins: [validationMixin],
  props: {
    Career: {
      type: Object,
      default: null,
    },
    Certification: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          career: this.Career ? this.Career.id : null,
          matters: [],
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      certification: { ...this.Certification },
      matters_in_career: [],
    };
  },
  validations: {
    certification: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {},
      matters: {},
      career: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      careers: names.CAREERS,
    }),
    prerequisitesOptions() {
      let list = [];
      if (!this.certification.career) return list;
      list = this.matters_in_career;
      return list.sort(function (a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.certification[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.certification.$touch();
      if (this.$v.certification.$anyError) {
        return;
      }
      if (isNaN(this.certification.id)) {
        this.createCertification();
      } else this.updateCertification();
    },
    fetchMatter() {
      if (this.certification.career) {
        this.$restful
          .Get(`/teaching/matter/?career=${this.certification.career}`)
          .then((response) => (this.matters_in_career = response));
      }
    },
    createCertification() {
      this.$store
        .dispatch(names.POST_CERTIFICATION, this.certification)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.certification",
                false,
                "Certificación de Credenciales"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateCertification() {
      this.$store
        .dispatch(names.UPDATE_CERTIFICATION, this.certification)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.certification",
                false,
                "Certificación de Credenciales"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteCertification() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.certification",
          false,
          "Certificación de Credenciales"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_CERTIFICATION,
            this.certification.id
          );
          this.$emit("deleted", this.certification);
        }
      });
    },
  },
  created() {
    this.fetchMatter();
  },
};
</script>

<style scoped>
.label {
  margin-top: 1%;
}
.input-name {
  margin-top: -1px;
}
#input-color {
  cursor: pointer;
}
</style>