
export default [
    {
        path: '/matter/form/:matter_id',
        name: 'MatterProgramView',
        component: () =>
            import("@/components/teaching/MatterProgram/MatterProgramView"),
    },
    {
        path: '/planification/form/:matter_id/',
        name: 'PlanificationView',
        component: () =>
            import("@/components/teaching/Planification/PlanificationView"),
    },
    {
        path: '/scheduler/form/',
        name: 'SchedulerView',
        component: () =>
            import("@/components/teaching/Scheduler/SchedulerView"),
    },
    {
        path: '/section/form/',
        name: 'SectionView',
        component: () =>
            import("@/components/teaching/Section/SectionView"),
    },
    {
        path: '/user_evaluation/:matter_id',
        name: 'EvaluationTableView',
        component: () =>
            import("@/components/teaching/Evaluation/EvaluationTableView"),
    },
    {
        path: '/learning-suitcase/:egress_profile_matter_id',
        name: 'LearningSuitcaseView',
        component: () =>
            import("@/components/teaching/MatterProgram/LearningSuitcase/LearningSuitcaseView"),
    },
    {
        path: '/learning-suitcase-student/:egress_profile_matter_id',
        name: 'LearningSuitcaseStudentView',
        component: () =>
            import("@/components/teaching/MatterProgram/LearningSuitcase/LearningSuitcaseStudentView"),
    },
    {
        path: '/learning-suitcase-teacher/:egress_profile_matter_id',
        name: 'LearningSuitcaseTeacherView',
        component: () =>
            import("@/components/teaching/MatterProgram/LearningSuitcase/LearningSuitcaseTeacherView"),
    },
    {
        path: '/learning-experience/:egress_profile_matter_id',
        name: 'LearningExperiencesView',
        component: () =>
            import("@/components/teaching/MatterProgram/LearningExperiencesView"),
    },
];
