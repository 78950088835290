import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    matters: [],
    matters_url: '/teaching/matter',
    m_middle_descriptors: [],
    m_middle_descriptor_url: '/mesh/m-middle-descriptor'
}

const getters = {
    [names.MATTERS]: state => {
        if (!get.store.state.user) return [];
        return state.matters.filter((x) => x.school == get.store.state.user.school).sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
    [names.M_MIDDLE_DESCRIPTORS]: state => {
        if (!get.store.state.user) return [];
        return state.m_middle_descriptors.filter((x) => x.school == get.store.state.user.school);
    },
}

const mutations = {
    [names.MUTATE_MATTERS]: (state, matters) => {
        matters.forEach(element => {
            let index = state.matters.findIndex(x => x.id == element.id);
            if (index != -1) state.matters.splice(index, 1, element);
            else state.matters.push(element);
        });
    },
    [names.MUTATE_MATTER]: (state, matter) => {
        let index = state.matters.findIndex(x => x.id == matter.id);
        if (index != -1) state.matters.splice(index, 1, matter);
        else state.matters.push(matter);
    },
    [names.MUTATE_DELETE_MATTER]: (state, matter_id) => {
        let index = state.matters.findIndex(x => x.id == matter_id);
        if (index != -1) state.matters.splice(index, 1);
    },
    [names.MUTATE_M_MIDDLE_DESCRIPTOR]: (state, m_middle_descriptor) => {
        let index = state.m_middle_descriptors.findIndex(x => x.id == m_middle_descriptor.id);
        if (index != -1) state.m_middle_descriptors.splice(index, 1, m_middle_descriptor);
        else state.m_middle_descriptors.push(m_middle_descriptor);
    },
    [names.MUTATE_M_MIDDLE_DESCRIPTORS]: (state, m_middle_descriptors) => {
        m_middle_descriptors.forEach(element => {
            let index = state.m_middle_descriptors.findIndex(x => x.id == element.id);
            if (index != -1) state.m_middle_descriptors.splice(index, 1, element);
            else state.m_middle_descriptors.push(element);
        });
    },
    [names.MUTATE_DELETE_M_MIDDLE_DESCRIPTORS]: (state, m_middle_descriptor_id) => {
        let index = state.m_middle_descriptors.findIndex(x => x.id == m_middle_descriptor_id);
        if (index != -1) state.m_middle_descriptors.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_MATTER]: ({
        commit,
        state
    }, matter_id) => {
        return new Promise((resolve) => {
            const matter = state.matters.find(x => x.id == matter_id);
            if (matter) {
                resolve(matter);
                return;
            }
            restful.Get(`${state.matters_url}/${matter_id}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_MATTERS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.egress_profile_id && payload.cycle_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id + '&cycle=' + payload.cycle_id;
                else if (payload.egress_profile_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id;
                else if (payload.cycle_id)
                    url_append += '?cycle=' + payload.cycle_id;
                if (payload.career_id)
                    url_append += '?career=' + payload.career_id;
                if (payload.is_elective)
                    url_append += '?is_elective=' + payload.is_elective;
            }
            restful.Get(`${state.matters_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_MATTERS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_MATTER]: ({
        commit,
        state
    }, matter) => {
        return new Promise((resolve) => {
            restful.Post(`${state.matters_url}/`, matter)
                .then(response => {
                    commit(names.MUTATE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_MATTER]: ({
        commit,
        state
    }, matter) => {
        return new Promise((resolve) => {
            restful.Put(`${state.matters_url}/${matter.id}/`, matter)
                .then(response => {
                    commit(names.MUTATE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_MATTER]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.matters_url}/${payload.matter_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_MATTER]: ({
        commit,
        state
    }, matter_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.matters_url}/${matter_id}/`)
                .then(response => {
                    commit("MUTATE_DELETE_EGRESS_PROFILE_MATTER_BY_BASE_MATTER", matter_id);
                    commit("MUTATE_DELETE_MATTER_IN_BASE_MATTER_WRAPPER", matter_id);
                    commit(names.MUTATE_DELETE_MATTER, matter_id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_M_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, search_id) => {
        return new Promise((resolve) => {
            let arr = [];
            if (search_id) arr.push('matter=' + search_id);
            let partial_url = '';
            if (arr.length > 0) partial_url = '?' + arr.join('&&');
            restful.Get(`${state.m_middle_descriptor_url}/${partial_url}`)
                .then(response => {
                    commit(names.MUTATE_M_MIDDLE_DESCRIPTORS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_M_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, m_middle_descriptor) => {
        return new Promise((resolve) => {
            restful.Post(`${state.m_middle_descriptor_url}/`, m_middle_descriptor)
                .then(response => {
                    commit(names.MUTATE_M_MIDDLE_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_M_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.m_middle_descriptor_url}/${payload.id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_M_MIDDLE_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_M_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.m_middle_descriptor_url}/${id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_M_MIDDLE_DESCRIPTORS, id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};