<template>
  <div>
    <h4 v-if="isNaN(performance_indicator.id) && show_title">
      Crear Competencia Genérica
    </h4>
    <h4 v-else-if="show_title">Editar Competencia Genérica</h4>
    <div>
      <b-form>
        <b-form-group
          label="Acción"
          label-for="input-action"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-action"
            v-model="$v.performance_indicator.action.$model"
            :state="validateState('action')"
            aria-describedby="input-action-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-action-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label="Contenido"
          label-for="input-content"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-content"
            v-model="$v.performance_indicator.content.$model"
            :state="validateState('content')"
            aria-describedby="input-content-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-content-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label="Condición"
          label-for="input-condition"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-condition"
            v-model="$v.performance_indicator.condition.$model"
            :state="validateState('condition')"
            aria-describedby="input-condition-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-condition-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          :label="$getVisibleNames('mesh.cycle', false, 'Ciclo Formativo')"
          label-for="input-cycle"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-select
            id="input-cycle"
            :options="cycles"
            text-field="name"
            value-field="id"
            v-model="$v.performance_indicator.cycle.$model"
            :state="validateState('cycle')"
            aria-describedby="input-cycle-feedback"
            size="sm"
          ></b-form-select>
          <b-form-invalid-feedback id="input-cycle-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label="Orden"
          label-for="input-order"
          label-cols="0"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-order"
            type="number"
            v-model="$v.performance_indicator.order.$model"
            :state="validateState('order')"
            aria-describedby="input-order-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-order-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>

        <div class="row">
          <div
            v-can="'mesh.delete_performanceindicator'"
            v-if="show_delete_button && !isNaN(performance_indicator.id)"
            class="col"
            style="text-align: left"
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="danger"
              @click="deletePerformanceIndicator"
              >Eliminar</b-button
            >
          </div>
          <div
            v-can="
              'mesh.add_performanceindicator mesh.change_performanceindicator'
            "
            v-if="show_save_button"
            class="col"
            style="text-align: right"
          >
            <b-button class="mr-1" size="sm" @click="savePerformanceIndicator"
              >Guardar</b-button
            >
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "PerformanceIndicatorForm",
  mixins: [validationMixin],
  props: {
    institution_competence_id: {
      type: Number,
      required: true,
    },
    cycle_id: {
      type: Number,
      required: true,
    },
    PerformanceIndicator: {
      type: Object,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      performance_indicator: {
        id: this.PerformanceIndicator
          ? this.PerformanceIndicator.id
          : generateUniqueId(),
        action: this.PerformanceIndicator
          ? this.PerformanceIndicator.action
          : "",
        content: this.PerformanceIndicator
          ? this.PerformanceIndicator.content
          : "",
        condition: this.PerformanceIndicator
          ? this.PerformanceIndicator.condition
          : "",
        institution_competence: this.PerformanceIndicator
          ? this.PerformanceIndicator.institution_competence
          : this.institution_competence_id,
        cycle: this.PerformanceIndicator
          ? this.PerformanceIndicator.cycle
          : this.cycle_id,
        order: this.PerformanceIndicator ? this.PerformanceIndicator.order : 1,
      },
    };
  },
  validations: {
    performance_indicator: {
      action: { required },
      content: { required },
      condition: { required },
      institution_competence: { required },
      cycle: { required },
      order: { required },
    },
  },
  computed: {
    ...mapGetters({
      cycles: names.CYCLES,
    }),
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.performance_indicator[key];
      return $dirty ? !$error : null;
    },
    savePerformanceIndicator() {
      this.$v.performance_indicator.$touch();
      if (this.$v.performance_indicator.$anyError) {
        return;
      }
      if (isNaN(this.performance_indicator.id))
        this.createPerformanceIndicator();
      else this.updatePerformanceIndicator();
    },
    createPerformanceIndicator() {
      this.$store
        .dispatch(names.POST_PERFORMANCE_INDICATOR, this.performance_indicator)
        .then((response) => {
          this.performance_indicator = response;
          this.$emit("created", this.performance_indicator);
        });
    },
    updatePerformanceIndicator() {
      this.$store
        .dispatch(
          names.UPDATE_PERFORMANCE_INDICATOR,
          this.performance_indicator
        )
        .then((response) => {
          this.performance_indicator = response;
          this.$emit("updated", this.performance_indicator);
        });
    },
    deletePerformanceIndicator() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "manual.indicador_de_logro",
          false,
          "Indicador de Logro"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              names.DELETE_PERFORMANCE_INDICATOR,
              this.performance_indicator.id
            )
            .then(() => {
              this.$emit("deleted", this.performance_indicator);
            });
        }
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
</style>