<template>
  <div class="text-center">
    <h4>
      Malla Curricular
      {{
        career
          ? String($getVisibleNames("mesh.career", false, "Programa") + ": ") +
            career.name +
            " - SCT"
          : " - SCT"
      }}
    </h4>
    <div v-if="egressProfile" class="table-container">
      <table>
        <thead>
          <tr v-if="egressProfileCycleSort.length > 0">
            <td></td>
            <td
              v-for="cycle in egressProfileCycleSort"
              :key="cycle.id"
              :colspan="cycleColspan(cycle)"
              class="cycle-cell"
            >
              <!-- :colspan="cycle.end_semester - cycle.init_semester + 1" -->
              {{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}
              {{ cycle.name }}
            </td>
          </tr>
          <tr v-else>
            <td></td>
            <td :colspan="semesterAmount.length" class="cycle-cell empty"></td>
          </tr>
          <tr>
            <th></th>
            <th
              v-for="index in semesterAmount"
              :key="index"
              class="semester-cell"
            >
              {{ index }}° Nivel
            </th>
          </tr>
        </thead>
        <template v-for="formation_area in formationAreasList">
          <FormationAreaRow
            v-if="hideFormationArea(formation_area.id)"
            :key="formation_area.id"
            :EgressProfile="egressProfile"
            :FormationArea="formation_area"
            :cycle_id="cycle_id"
          ></FormationAreaRow>
        </template>
        <!-- <template>
          <CoursesWrapperRow
            :EgressProfile="egressProfile"
            :cycle_id="cycle_id"
          >
          </CoursesWrapperRow>
        </template> -->
        <tr class="total" style="margin-top: 1em !important">
          <td class="totalTitle">
            <div class>
              <b>{{
                $getVisibleNames(
                  "manual.credits",
                  true,
                  "Créditos SCT Totales"
                ).toUpperCase()
              }}</b>
            </div>
          </td>
          <td v-for="index in semesterAmount" :key="index">
            <div v-if="profileType && isAnual">
              {{ totalSCT(index) }}
            </div>
          </td>
        </tr>
        <tr class="total">
          <td class="totalTitle">
            <b>
              {{
                $getVisibleNames(
                  "manual.total_pedagogical_hours",
                  true,
                  "Total de Horas Pedagógicas"
                ).toUpperCase()
              }}
            </b>
          </td>
          <td v-for="index in semesterAmount" :key="index">
            <template v-if="isAnual">
              {{ totalPedagogical(index) }}
            </template>
          </td>
        </tr>
      </table>
    </div>
    <template>
      <div class="curricular-mesh-info">
        <div class="formation-lines-container">
          <b-table
            class="mt-3 formation-lines-table"
            :items="filteredFormationLines"
            :fields="formationLinesFields"
            small
          >
            <template #cell(color)="row">
              <div
                class="formation-line-color"
                :style="{
                  'background-color': row.item.color,
                  color: row.item.color,
                }"
              ></div>
            </template>
          </b-table>
          <div class="footer-info">
            * En caso de que la
            {{
              $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
            }}
            no tenga
            {{
              String(
                $getVisibleNames(
                  "mesh.formationline",
                  false,
                  "Línea De Formación"
                )
              )
            }}, se mostrará de color
            <div
              class="formation-line-color"
              style="background-color: #99c9d8; color: #99c9d8"
            ></div>
          </div>
        </div>
        <div
          class="formation-lines-container ml-2"
          v-if="filteredCertifications.length > 0 || allows_crud"
        >
          <b-table
            class="mt-3 formation-lines-table"
            :items="filteredCertifications"
            :fields="certifications_fields"
            small
            show-empty
          >
            <template #head(name)>
              <div class="d-flex" style="align-items: center">
                <button-add
                  v-if="allows_crud"
                  v-can="'mesh.add_certification'"
                  @click="$bvModal.show(`new-certification-modal`)"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Agregar ${$getVisibleNames(
                      'mesh.certification',
                      false,
                      'Certificación de Credenciales'
                    )}`
                  "
                >
                </button-add>
                <span>
                  {{
                    $getVisibleNames(
                      "mesh.certification",
                      true,
                      "Certificaciones de Credenciales"
                    )
                  }}
                </span>
              </div>
            </template>
            <template #empty> No hay datos para mostrar. </template>
            <template #cell(matters)="row">
              <div class="campus-container">
                <div
                  v-for="item in getMatterName(row.item.matters)"
                  :key="item.id"
                  class="campus-div"
                >
                  {{ item.name }}
                </div>
              </div>
            </template>
            <template v-if="allows_crud" #cell(actions)="row">
              <button-edit
                v-can="'mesh.change_certification'"
                @click="
                  $bvModal.show(`edit-certification-modal-${row.item.id}`)
                "
                v-b-tooltip.v-secondary.noninteractive="
                  `Editar ${$getVisibleNames(
                    'mesh.certification',
                    false,
                    'Certificación de Credenciales'
                  )}`
                "
              ></button-edit>
              <button-delete
                v-can="'mesh.delete_certification'"
                @click="askForDeleteCertification(row.item.id)"
                v-b-tooltip.v-secondary.noninteractive="
                  `Eliminar ${$getVisibleNames(
                    'mesh.certification',
                    false,
                    'Certificación de Credenciales'
                  )}`
                "
              ></button-delete>
              <b-modal
                :id="`edit-certification-modal-${row.item.id}`"
                :title="`Editar ${$getVisibleNames(
                  'mesh.certification',
                  false,
                  'Certificación de Credenciales'
                )}`"
                size="md"
                hide-footer
              >
                <CertificationForm
                  :Certification="row.item"
                  :Career="career"
                  @updated="
                    $bvModal.hide(`edit-certification-modal-${row.item.id}`)
                  "
                  :show_title="false"
                ></CertificationForm>
              </b-modal>
            </template>
          </b-table>
        </div>
        <div
          class="formation-lines-container ml-2"
          v-if="modalities.length > 0"
        >
          <b-table
            class="mt-3 formation-lines-table"
            :items="modalities"
            :fields="modalities_fields"
            small
          >
            <template #cell(matters)="row">
              <div class="campus-container">
                <div
                  v-for="item in getMatterName(row.item.matters)"
                  :key="item.id"
                  class="campus-div"
                >
                  {{ item.name }}
                </div>
              </div>
            </template>
          </b-table>
        </div>
        <EgressProfileCompetenceMention
          v-if="
            egress_profile_id &&
            profile_competences_mention.filter(
              (x) => x.egress_profile == egress_profile_id
            ).length > 0
          "
          class="ml-2"
          style="width: 400px"
          :allows_crud="false"
          :egress_profile_id="egress_profile_id"
          :curricular_mesh="true"
        ></EgressProfileCompetenceMention>
        <div
          class="formation-lines-container ml-2"
          v-if="coursesWrapperList.length > 0"
        >
          <b-table
            class="mt-3 formation-lines-table"
            :items="coursesWrapperList"
            :fields="courses_wrapper_fields"
            small
          >
            <template #cell(egress_profile_matters)="row">
              <div class="campus-container">
                <div
                  v-for="item in getEgressProfileMatterName(
                    row.item.egress_profile_matters
                  )"
                  :key="item.id"
                  class="campus-div"
                >
                  {{ item.name }}
                </div>
              </div>
            </template>
          </b-table>
        </div>

        <div class="d-flex matter-card-container">
          <!-- <b-img
            class="matter-card-img"
            :src="require('@/assets/matter-card.png')"
            fluid
            alt="Responsive image"
          ></b-img> -->

          <div class="matter-card">
            <div class="left-col">
              <div class="left-label" id="matter-number-box">1</div>
              <div class="left-label" id="hour-modules-box">2</div>
              <div class="left-label" id="matter-credits-box">3</div>
              <div class="left-label" id="modalities-box">4</div>
              <b-popover
                target="matter-number-box"
                placement="topleft"
                triggers="hover"
                v-b-visible
                :content="`N° de orden de la ${$getVisibleNames(
                  'mesh.egressprofilematter',
                  false,
                  'Asignatura'
                )}`"
              ></b-popover>
              <b-popover
                custom-class="example-popover-1"
                target="hour-modules-box"
                placement="left"
                :offset="-50"
                triggers="hover"
              >
                <template #default>
                  <div class="hour-modules-popover-content">
                    {{
                      $getVisibleNames(
                        "manual.total_pedagogical_hours",
                        true,
                        "Total de Horas Pedagógicas"
                      )
                    }}
                  </div>
                </template>
              </b-popover>
              <b-popover
                custom-class="example-popover-2"
                target="matter-credits-box"
                placement="left"
                triggers="hover"
                :offset="50"
                :content="`${this.$getVisibleNames(
                  'manual.credits',
                  true,
                  'Créditos SCT'
                )}`"
              ></b-popover>
              <b-popover
                target="modalities-box"
                placement="bottomleft"
                triggers="hover"
                :content="
                  $getVisibleNames(
                    'mesh.modality',
                    false,
                    'Modalidad de Asignaturas'
                  )
                "
              ></b-popover>
            </div>
            <div class="matter-title">
              <div class="matter-link">
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    false,
                    "Asignatura"
                  )
                }}
              </div>
            </div>
            <div class="right-col">
              <div class="preq-label" id="prerequisites-box">
                5
                <b-popover
                  target="prerequisites-box"
                  triggers="hover"
                  placement="top"
                >
                  <template>
                    <div>Prerrequisitos</div>
                  </template>
                </b-popover>
              </div>
              <template>
                <div class="comp-label" id="competences-box">
                  <template>
                    <div>6</div>
                  </template>
                  <b-popover
                    target="competences-box"
                    triggers="hover"
                    placement="righttop"
                  >
                    <template #default>
                      {{
                        $getVisibleNames(
                          "mesh.competence",
                          true,
                          "Competencias"
                        )
                      }}
                    </template>
                  </b-popover>
                </div>
              </template>
            </div>
          </div>
          <div>
            <b-table
              class="ml-4 formation-lines-table"
              :items="matterCardList"
              :fields="matter_card_fields"
              small
            >
            </b-table>
          </div>
        </div>
      </div>
    </template>
    <b-modal
      :id="`new-certification-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.certification',
        false,
        'Certificación de Credenciales'
      )}`"
      size="lg"
    >
      <CertificationForm
        @created="$bvModal.hide('new-certification-modal')"
        :Career="career"
      ></CertificationForm>
    </b-modal>
    <MeshCustomFab
      :egress_profile_id="egress_profile_id"
      :show_curricular_mesh="false"
    ></MeshCustomFab>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  components: {
    CertificationForm: () =>
      import(
        "@/components/mesh/CurricularResources/Certification/CertificationForm"
      ),
    FormationAreaRow: () => import("./FormationAreaRow"),
    // CoursesWrapperRow: () => import("./CoursesWrapperRow"),
    MeshCustomFab: () => import("../MeshCustomFab"),
    EgressProfileCompetenceMention: () =>
      import("@/components/mesh/EgressProfile/EgressProfileCompetenceMention"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    cycle_id: {
      type: Number,
    },
  },
  data() {
    return {
      active: false,
      pop1: true,
    };
  },
  computed: {
    ...mapGetters({
      cycles: names.CYCLES,
      formationAreas: names.FORMATION_AREAS,
      profile_cycles: names.PROFILE_CYCLES,
      profile_types: names.PROFILE_TYPES,
      regimes: names.REGIMES,
      matters: names.MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      school: "getInstitution",
      certifications: names.CERTIFICATIONS,
      careers: names.CAREERS,
      formation_lines: names.FORMATION_LINES,
      modalities: names.MODALITIES,
      time_allocations: names.TIME_ALLOCATIONS,
      courses_wrapper: names.COURSES_WRAPPER,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      profile_competences_mention: names.PROFILE_COMPETENCES_MENTION,
    }),
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    isAnual() {
      const months_amount_egress_profile = this.regimes.find(
        (x) => x.id == this.egressProfile.regime
      );
      if (months_amount_egress_profile) {
        if (months_amount_egress_profile.months_amount == 6)
          return this.regimes.find(
            (x) =>
              x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
          );
        else if (months_amount_egress_profile.months_amount == 3)
          return this.regimes.find(
            (x) =>
              x.name.includes("Semestral") || parseInt(x.months_amount) / 3 == 2
          );
        else if (months_amount_egress_profile.months_amount == 2)
          return this.regimes.find(
            (x) =>
              x.name.includes("Trimestral") || parseInt(x.months_amount) == 3
          );
      }
      return this.regimes.find(
        (x) => x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
      );
    },
    profileType() {
      if (!this.egressProfile) return null;
      return this.profile_types.find(
        (x) => x.id == this.egressProfile.egress_profile_type
      );
    },
    matterCardList() {
      const list = [
        {
          id: 1,
          order: 1,
          name: `N° de orden de la ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )}`,
        },
        {
          id: 2,
          order: 2,
          name: this.$getVisibleNames(
            "manual.total_pedagogical_hours",
            true,
            "Total de Horas Pedagógicas"
          ),
        },
        {
          id: 3,
          order: 3,
          name: `${this.$getVisibleNames(
            "manual.credits",
            true,
            "Créditos SCT"
          )}`,
        },
        {
          id: 4,
          order: 4,
          name: `${this.$getVisibleNames(
            "mesh.modality",
            false,
            "Modalidad de Asignaturas"
          )}`,
        },
        {
          id: 5,
          order: 5,
          name: "Prerrequisitos",
        },
        {
          id: 6,
          order: 6,
          name: `${this.$getVisibleNames(
            "mesh.competence",
            true,
            "Competencias"
          )}`,
        },
      ];
      return list;
    },
    formationAreasList() {
      let list = [];
      this.formationAreas.forEach((item) => {
        list.push(item);
      });
      list.push({
        id: 0,
        name: String(
          "Sin " +
            this.$getVisibleNames(
              "mesh.formationarea",
              false,
              "Área De Formación"
            )
        ),
        description: String(
          "Sin " +
            this.$getVisibleNames(
              "mesh.formationarea",
              false,
              "Área De Formación"
            )
        ),
        order: 0,
      });
      return list;
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    formationLinesMatterIds() {
      const formation_line_ids = this.matterList
        .filter((x) => x.formation_line != null)
        .map((x) => x.formation_line);
      return [...new Set(formation_line_ids)];
    },
    formationLinesCourseWrapperIds() {
      const formation_line_ids = this.coursesWrapperList
        .filter((x) => x.formation_line != null)
        .map((x) => x.formation_line);
      return [...new Set(formation_line_ids)];
    },
    filteredFormationLines() {
      if (!this.career) return [];
      return this.formation_lines
        .filter(
          (x) =>
            x.faculties.includes(this.career.faculty) &&
            (this.formationLinesMatterIds.includes(x.id) ||
              this.formationLinesCourseWrapperIds.includes(x.id))
        )
        .sort(function (a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
    },
    filteredCertifications() {
      if (!this.career) return [];
      return this.certifications
        .filter((x) => x.career == this.career.id)
        .sort(function (a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
    },
    filteredCycles() {
      if (!this.cycle_id) return this.cycles;
      return this.cycles.filter((x) => x.id == this.cycle_id);
    },
    egressProfileCycleId() {
      if (!this.egress_profile_id) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egress_profile_id) list.push(x.cycle);
      });
      return list;
    },
    egressProfileCycleSort() {
      if (!this.egressProfile) return [];
      let cycles = this.cycles.filter((x) =>
        this.egressProfileCycleId.includes(x.id)
      );
      cycles.forEach((x) => {
        let cycle_list = this.profile_cycles.find(
          (profile_cycle) =>
            profile_cycle.cycle == x.id &&
            profile_cycle.egress_profile == this.egress_profile_id
        );
        if (cycle_list) {
          x.init_semester = cycle_list.init_semester;
          x.end_semester = cycle_list.end_semester;
          x.order = cycle_list.order;
        }
      });
      if (this.cycle_id) return cycles.filter((x) => x.id == this.cycle_id);
      return cycles.sort(function (a, b) {
        if (a.init_semester < b.init_semester) return -1;
        if (a.init_semester > b.init_semester) return 1;
        if (a.end_semester < b.end_semester) return -1;
        if (a.end_semester > b.end_semester) return 1;
        return 0;
      });
    },
    // semesterAmount() {
    //   let min = 14;
    //   let max = 0;
    //   this.filteredCycles.forEach((cycle) => {
    //     if (cycle.init_semester < min) min = cycle.init_semester;
    //     if (cycle.end_semester > max) max = cycle.end_semester;
    //   });
    //   if (this.egressProfile && max > this.egressProfile.semester_amount) {
    //     max = this.egressProfile.semester_amount;
    //   }
    //   let list = [];
    //   for (let index = min; index <= max; index++) {
    //     list.push(index);
    //   }
    //   return list;
    // },
    maxSemesterAmount() {
      let min = 14;
      let max = 0;
      if (!this.egressProfile) return 0;
      if (this.egressProfileCycleId.length == 0) {
        min = 1;
        max = this.egressProfile.semester_amount;
      } else {
        this.profile_cycles.forEach((cycle) => {
          if (
            cycle.init_semester < min &&
            cycle.egress_profile == this.egressProfile.id
          )
            min = cycle.init_semester;
          if (
            cycle.end_semester > max &&
            cycle.egress_profile == this.egressProfile.id
          )
            max = cycle.end_semester;
        });
      }
      if (max > this.egressProfile.semester_amount) {
        max = this.egressProfile.semester_amount;
      }
      return max;
    },
    semesterAmount() {
      let min = 14;
      let max = 0;
      if (this.egressProfileCycleId.length == 0) {
        min = 1;
        max = this.egressProfile.semester_amount;
      } else {
        if (!this.cycle_id) {
          this.profile_cycles.forEach((cycle) => {
            if (
              cycle.init_semester < min &&
              cycle.egress_profile == this.egressProfile.id
            )
              min = cycle.init_semester;
            if (
              cycle.end_semester > max &&
              cycle.egress_profile == this.egressProfile.id
            )
              max = cycle.end_semester;
          });
        } else {
          this.profile_cycles.forEach((cycle) => {
            if (
              this.cycle_id == cycle.cycle &&
              cycle.init_semester < min &&
              cycle.egress_profile == this.egressProfile.id
            )
              min = cycle.init_semester;
            if (
              this.cycle_id == cycle.cycle &&
              cycle.end_semester > max &&
              cycle.egress_profile == this.egressProfile.id
            )
              max = cycle.end_semester;
          });
        }
      }
      if (max > this.egressProfile.semester_amount) {
        max = this.egressProfile.semester_amount;
      }
      let list = [];
      for (let index = min; index <= max; index++) {
        list.push(index);
      }
      return list;
    },
    formationLinesFields() {
      return [
        {
          key: "name",
          label: this.career
            ? String(
                this.$getVisibleNames(
                  "mesh.formationline",
                  true,
                  "Líneas De Formación"
                )
              ) +
              " " +
              String(
                this.$getVisibleNames("mesh.career", false, "Programa") + ": "
              ) +
              this.career.name
            : String(
                this.$getVisibleNames(
                  "mesh.formationline",
                  true,
                  "Líneas De Formación"
                )
              ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        {
          key: "color",
          label: "Color",
          sortable: false,
          thStyle: {
            background: "var(--primary-color) !important",
            verticalAlign: "middle",
          },
        },
      ];
    },
    certifications_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.certification",
              true,
              "Certificaciones de Credenciales"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "align-middle",
          tdClass: "text-left",
        },
        {
          key: "matters",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            true,
            "Asignaturas"
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "align-middle",
          tdClass: "text-left",
        },
        {
          key: "actions",
          label: "",
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
    courses_wrapper_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.coursewrapper",
              false,
              "Envoltorio de Asignaturas"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "align-middle",
          tdClass: "text-left",
        },
        // {
        //   key: "init_level",
        //   label: "init",
        //   sortable: true,
        //   thStyle: { background: "var(--primary-color) !important" },
        //   thClass: "align-middle",
        //   tdClass: "text-center",
        // },
        // {
        //   key: "end_level",
        //   label: "end",
        //   sortable: true,
        //   thStyle: { background: "var(--primary-color) !important" },
        //   thClass: "align-middle",
        //   tdClass: "text-center",
        // },
        {
          key: "credits",
          label: this.$getVisibleNames(
            "manual.credits",
            true,
            "Créditos SCT Totales"
          ),
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "align-middle",
          tdClass: "text-center",
        },
        {
          key: "egress_profile_matters",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            true,
            "Asignaturas"
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "align-middle",
          tdClass: "text-left",
        },
      ];
    },
    modalities_fields() {
      return [
        {
          key: "order",
          label: "N°",
          sortable: true,
          thStyle: {
            background: "var(--primary-color) !important",
            textAlign: "center",
          },
          tdClass: "text-center",
        },
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.modality",
              true,
              "Modalidades de Asignaturas"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
      ];
    },
    matter_card_fields() {
      return [
        {
          key: "order",
          label: "N°",
          sortable: false,
          thStyle: {
            background: "var(--primary-color) !important",
            textAlign: "center",
          },
          tdClass: "text-center",
        },
        {
          key: "name",
          label: "Nombre",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
      ];
    },
    coursesWrapperList() {
      return this.courses_wrapper
        .filter((x) => x.egress_profile == this.egress_profile_id)
        .sort(function (a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          if (a.init_level < b.init_level) return -1;
          if (a.init_level > b.init_level) return 1;
          if (a.end_level < b.end_level) return -1;
          if (a.end_level > b.end_level) return 1;
          return 0;
        });
    },
    wrapperMatterIds() {
      let list = [];
      this.coursesWrapperList.forEach((x) => {
        list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    matterList() {
      return this.egress_profiles_matters.filter(
        (x) =>
          x.egress_profile == this.egress_profile_id &&
          !this.wrapperMatterIds.includes(x.id)
      );
    },
  },
  methods: {
    cycleColspan(cycle) {
      let init_semester = cycle.init_semester;
      this.egressProfileCycleSort.forEach((x) => {
        if (
          cycle.init_semester <= x.end_semester &&
          cycle.end_semester > x.end_semester
        )
          init_semester = x.end_semester + 1;
      });
      return cycle.end_semester - init_semester + 1;
    },
    hideFormationArea(formation_area_id) {
      if (formation_area_id != 0) {
        const egress_profile_matters = this.matterList.filter(
          (matter) => matter.formation_area == formation_area_id
        );
        const courses_wrappers = this.coursesWrapperList.filter(
          (course) => course.formation_area == formation_area_id
        );
        if (egress_profile_matters.length > 0 || courses_wrappers.length > 0)
          return true;
        return false;
      } else {
        const egress_profile_matters = this.matterList.filter(
          (matter) => matter.formation_area == null
        );
        const courses_wrappers = this.coursesWrapperList.filter(
          (course) => course.formation_area == null
        );
        if (egress_profile_matters.length > 0 || courses_wrappers.length > 0)
          return true;
        return false;
      }
    },
    getEgressProfileMatterName(egress_profile_matters) {
      let matters = this.egress_profiles_matters
        .filter((x) => egress_profile_matters.includes(x.id))
        .map((x) => x.matter);
      matters = [...new Set(matters)];
      if (matters.length > 0) {
        const list = this.matters.filter((element) =>
          matters.includes(element.id)
        );
        return list.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
      } else return [];
    },
    getMatterName(matters) {
      if (matters.length > 0) {
        const list = this.matters.filter((element) =>
          matters.includes(element.id)
        );
        return list.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
      } else return [];
    },
    totalPedagogical(plan_level) {
      let hours = 0;
      const pedagogical_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == true)
        .map((x) => x.id);
      this.matterList.forEach((matter) => {
        let matter_hours = [];
        if (matter.use_matter_values) {
          matter_hours = this.matter_time_allocations.filter(
            (x) =>
              x.matter == matter.matter &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        } else {
          matter_hours = this.ep_matter_time_allocations.filter(
            (x) =>
              x.egress_profile_matter == matter.id &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        }
        matter_hours.forEach((hour) => {
          if (
            matter.plan_level == plan_level &&
            matter.regime == this.isAnual.id
          )
            hours += parseFloat(hour.hours) / 2;
          else if (matter.plan_level == plan_level)
            hours += parseFloat(hour.hours);
          if (
            matter.regime == this.isAnual.id &&
            plan_level % 2 == 0 &&
            matter.plan_level == plan_level - 1
          )
            hours += parseFloat(hour.hours) / 2;
        });
      });
      return Math.round(hours);
    },
    totalSCT(plan_level) {
      let totalSCT = 0;
      this.matterList.forEach((matter) => {
        if (matter.use_matter_values) {
          const old_matter = this.matters.find((x) => x.id == matter.matter);
          if (old_matter) {
            if (
              matter.regime == this.isAnual.id &&
              plan_level % 2 == 0 &&
              matter.plan_level == plan_level - 1
            ) {
              totalSCT += parseFloat(old_matter.credits) / 2;
            }
            if (
              matter.plan_level == plan_level &&
              matter.regime == this.isAnual.id
            )
              totalSCT += parseFloat(old_matter.credits) / 2;
            else if (matter.plan_level == plan_level) {
              totalSCT += parseFloat(old_matter.credits);
            }
          }
        } else {
          if (
            matter.regime == this.isAnual.id &&
            plan_level % 2 == 0 &&
            matter.plan_level == plan_level - 1
          ) {
            totalSCT += parseFloat(matter.credits) / 2;
          }
          if (
            matter.plan_level == plan_level &&
            matter.regime == this.isAnual.id
          )
            totalSCT += parseFloat(matter.credits) / 2;
          else if (matter.plan_level == plan_level) {
            totalSCT += parseFloat(matter.credits);
          }
        }
      });
      // if (plan_level == this.maxSemesterAmount) {
      //   const credits = this.coursesWrapperList
      //     .map((x) => x.credits)
      //     .reduce((a, b) => a + b, 0);
      //   if (credits > 0) totalSCT += credits;
      // }
      return Math.round(totalSCT);
    },
    askForDeleteCertification(certification_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.certification",
          false,
          "Certificación de Credenciales"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_CERTIFICATION, certification_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.certification",
                    false,
                    "Certificación de Credenciales"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    popOpen() {
      this.pop1 = true;
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_FORMATION_LINES);
    this.$store.dispatch(names.FETCH_FORMATION_AREAS);
    this.$store.dispatch(names.FETCH_REGIMES);
    this.$store.dispatch(names.FETCH_PROFILE_TYPES);
    this.$store.dispatch(names.FETCH_TIME_ALLOCATIONS);
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_COMPETENCES, this.egress_profile_id);
    this.$store.dispatch(names.FETCH_COURSES_WRAPPER, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES_MENTION, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_MODALITIES);
    this.$store.dispatch(names.FETCH_CERTIFICATIONS);
  },
};
</script>

<style scoped>
table {
  width: 100%;
}
.cycle-cell {
  background-color: var(--primary-color);
  border: 2px solid white;
  color: var(--primary-font-color);
}
.cycle-cell.empty::before {
  color: var(--primary-color);
  content: " #";
}
.semester-cell {
  background-color: var(--secondary-color);
  border: 1px solid white;
  color: var(--secondary-font-color);
}
#hintCard {
  position: absolute;
  z-index: 9;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  text-align: center;
  width: 280px;
  height: 142px auto;
  top: 20%;
  left: 70%;
}
#hintCardHeader {
  padding: 5px;
  cursor: move;
  z-index: 10;
  background-color: #7c7c7c;
  color: #fff;
  font-size: small;
  font-weight: bold;
  margin-bottom: 0.7em;
}
.hint {
  color: white;
  background-color: #f06643;
  height: 16px;
  width: 32px;
  font-size: 10px;
  font-weight: bold;
  border: 1px solid #7c7c7c;
  margin-bottom: 0.3em;
  margin-left: 1em;
  padding-left: 8px;
  padding-bottom: 1px;
  display: flex;
}
.hintCaption {
  font-size: 11px;
  color: #2e2e2e;
  white-space: nowrap;
  top: 1px;
  padding-left: 16px;
}
#button {
  width: 23px;
  height: 20px;
  float: right;
  cursor: pointer;
  color: white;
}
.totalTitle {
  background-color: var(--secondary-color);
  border: 2px solid white;
  color: var(--secondary-font-color);
  font-size: var(--thirth-font-size);
  word-wrap: break-word;
}
.total {
  border: 1px solid #d3d3d3;
  font-size: 12px;
}
.total td {
  padding: 10px 5px;
  border: 1px solid #d3d3d3;
}
.total:nth-of-type(even) {
  background-color: #f0f0f0;
}
.table-container {
  overflow: scroll;
  height: 80%;
}
.formation-lines-container {
  width: 500px;
}
.formation-lines-table,
.footer-info {
  font-size: 10pt;
}
.formation-line-color {
  padding: 0 1rem;
  max-width: 4rem;
  margin-inline: auto;
}
.formation-line-color::before {
  content: " #";
}
.table.b-table >>> thead th {
  max-width: 100px !important;
}

.curricular-mesh-info {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

/* Ejemplo de tarjeta de asignatura en malla */

/* .matter-card-img {
  margin-left: 2rem;
  margin-top: 1rem;
} */

.matter-card-container {
  margin: 4em 18em;
}
.example-popover-1 {
  top: 5px !important;
}
.example-popover-1 >>> .arrow {
  top: 15px !important;
  margin: 0 !important;
}
.example-popover-2 {
  top: 7px !important;
}
.example-popover-2 >>> .arrow {
  top: -5px !important;
  margin: 0;
  margin-top: 0.45rem !important;
}

.popover.b-popover {
  display: block !important;
  opacity: 1 !important;
}
.matter-card {
  min-width: 160px;
  min-height: 88px;
  max-height: 100px;
  box-sizing: border-box;
  display: flex;
  font-size: var(--thirth-font-size);
  color: #2e2e2e;
}
.matter-title {
  padding: 15px 4px;
  width: 100%;
  font-size: var(--thirth-font-size);
  background-color: #f4f4f4;
  border: 1px solid #7c7c7c;
  margin-bottom: 0;
  background: #99c9d8;
}
.matter-title .matter-link {
  background-color: #f1f1f1;
  padding: 0.2rem;
  border-radius: 4px;
  line-height: 1.2;
}
.matter-link {
  min-width: 6rem;
}
.matter-link > a {
  color: #2e2e2e !important;
  text-decoration: underline;
}
.matter-link > a:hover {
  color: #007bff !important;
}
.left-col {
  width: 30px;
  float: left;
  background: transparent;
  font-size: var(--thirth-font-size);
}
.right-col {
  width: 40px;
  background: transparent;
  font-size: var(--thirth-font-size);
}

.left-label,
.preq-label,
.comp-label {
  color: #2e2e2e;
  background: transparent;
  transition: 0.2s ease-out;
}

.preq-label,
.comp-label {
  width: 40px;
  height: auto;
  border: 1px solid #575757;
  border-style: solid solid solid none;
  margin-bottom: 0px;
  font-size: 8pt;
}

.left-label {
  height: 20px;
  width: 30px;
  border-top: 1px dotted #575757;
  border-bottom: 1px dotted #575757;
  border-left: 1px dotted #575757;
}
.preq-label {
  padding-left: 0.1em;
  border-bottom: 0 !important;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
</style>