import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    users: [],
    users_url: '/authentication/user'
};

const getters = {
    [names.USERS]: state => {
        if (!get.store.state.user) return [];
        return state.users.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
};

const mutations = {
    [names.MUTATE_USERS]: (state, users) => {
        users.forEach(element => {
            let index = state.users.findIndex(x => x.id == element.id);
            if (index != -1) state.users.splice(index, 1, element);
            else state.users.push(element);
        });
    },
    [names.MUTATE_USER]: (state, user) => {
        let index = state.users.findIndex(x => x.id == user.id);
        if (index != -1) state.users.splice(index, 1, user);
        else state.users.push(user);
    },
    [names.MUTATE_DELETE_USER]: (state, user_id) => {
        let index = state.users.findIndex(x => x.id == user_id);
        if (index != -1) state.users.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_USER]: ({
        commit,
        state
    }, user_id) => {
        return new Promise((resolve) => {
            // Se comentó dado que al perdir las secciones en el dashboard y la planificación se mandaba el usuario antiguo sin actualizar las nuevas creadas.
            // const user = state.users.find(x => x.id == user_id);
            // if (user) {
            //     resolve(user);
            //     return;
            // }
            restful.Get(`${state.users_url}/${user_id}/`)
                .then(response => {
                    commit(names.MUTATE_USER, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_USERS]: ({
        commit,
        state,
    }, ) => {
        return new Promise((resolve) => {
            restful.Get(`${state.users_url}/`)
                .then(response => {
                    commit(names.MUTATE_USERS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_USER]: ({
        commit,
        state
    }, user) => {
        return new Promise((resolve) => {
            restful.Post(`${state.users_url}/`, user)
                .then(response => {
                    commit(names.MUTATE_USER, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_USER]: ({
        commit,
        state
    }, user) => {
        return new Promise((resolve) => {
            restful.Put(`${state.users_url}/${user.id}/`, user)
                .then(response => {
                    commit(names.MUTATE_USER, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_USER]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.users_url}/${payload.user_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_USER, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_USER]: ({
        commit,
        state
    }, user_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.users_url}/${user_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_USER, user_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};