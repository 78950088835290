<template>
  <div>
    <h4 v-if="isNaN(career.id) && show_title">
      Crear {{ $getVisibleNames("mesh.career", false, "Programa") }}
      <!-- Programa -->
    </h4>
    <h4 v-else-if="show_title">
      Editar {{ $getVisibleNames("mesh.career", false, "Programa") }}
      <!-- Programa -->
    </h4>
    <div>
      <b-form-group
        label="Nombre"
        label-for="name-input"
        label-cols="0"
        label-cols-sm="3"
      >
        <b-form-input
          id="name-input"
          name="name-input"
          v-model="$v.career.name.$model"
          :state="validateState('name') && !SameCareer"
          aria-describedby="input-name-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-name-live-feedback">
          <div v-if="career.name.length < 3">
            Este campo es obligatorio y debe tener al menos 3 caracteres.
          </div>
          <div v-if="SameCareer">
            Ya se cuenta con una
            {{ $getVisibleNames("manual.programa", false, "Programa") }} con
            este nombre.
          </div></b-form-invalid-feedback
        >
        <b-tooltip
          :target="`name-input`"
          variant="secondary"
          placement="right"
          :noninteractive="true"
          v-if="SameCareer"
        >
          <div v-if="SameCareer">
            {{ $getVisibleNames("manual.programa", false, "Programa")
            }}{{ filterSameCareer.length > 0 ? "s" : "" }} Existente{{
              filterSameCareer.length > 0 ? "s" : ""
            }}:
            <div v-for="Career in filterSameCareer" :key="Career.id">
              <p>
                {{ Career.name }}
              </p>
            </div>
          </div>
        </b-tooltip>
      </b-form-group>
      <b-form-group
        :label="`${$getVisibleNames(
          'mesh.egressprofiletype',
          false,
          'Tipo de Programa'
        )}`"
        label-for="type-select"
        label-cols="0"
        label-cols-sm="3"
      >
        <b-form-select
          id="type-select"
          name="type-select"
          v-model="$v.career.egress_profile_type.$model"
          :options="profileTypes"
          text-field="name"
          value-field="id"
          :state="validateState('egress_profile_type')"
          aria-describedby="select-type-live-feedback"
        >
        </b-form-select>
        <b-form-invalid-feedback id="select-type-live-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        :label="$getVisibleNames('mesh.faculty', false, 'Facultad')"
        label-for="faculty-input"
        label-cols="0"
        label-cols-sm="3"
      >
        <b-form-select
          id="faculty-input"
          name="faculty-input"
          v-model="$v.career.faculty.$model"
          :options="faculties"
          text-field="name"
          value-field="id"
          :state="validateState('faculty')"
          aria-describedby="input-faculty-live-feedback"
        ></b-form-select>

        <b-form-invalid-feedback id="input-faculty-live-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>

      <div class="row">
        <div
          v-if="show_delete_button && !isNaN(this.career.id)"
          class="col"
          style="text-align: left"
        >
          <b-button
            class="mr-1"
            size="sm"
            variant="danger"
            @click="askForDeleteObject"
            >Eliminar</b-button
          >
        </div>
        <div v-if="show_save_button" class="col" style="text-align: right">
          <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength, numeric } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";

export default {
  name: "CareerForm",
  mixins: [validationMixin],
  props: {
    Career: {
      type: Object,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      career: this.Career
        ? {
            id: this.Career.id,
            name: this.Career.name,
            egress_profile_type: this.Career.egress_profile_type,
            faculty: this.Career.faculty,
          }
        : {
            id: generateUniqueId(),
            name: "",
            egress_profile_type: null,
            faculty: generateUniqueId(),
          },
    };
  },
  validations: {
    career: {
      faculty: {
        required,
        numeric,
      },
      name: {
        required,
        minLength: minLength(3),
      },
      egress_profile_type: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      faculties: names.FACULTIES,
      careers: names.CAREERS,
      profileTypes: names.PROFILE_TYPES,
    }),
    SameCareer() {
      if (!this.career) return false;
      if (!this.career.name) return false;
      const SameCareer = this.careers.filter(
        (career) =>
          career.name
            .normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase() ==
          this.career.name
            .normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase()
      );
      if (
        (SameCareer.length > 0 && isNaN(this.career.id)) ||
        (SameCareer.length > 0 &&
          !SameCareer.find((career) => career.id == this.career.id))
      )
        return true;
      return false;
    },
    filterSameCareer() {
      if (!this.career) return [];
      if (!this.career.name) return [];
      const SameCareer = this.careers.filter(
        (career) =>
          career.name
            .normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase() ==
          this.career.name
            .normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase()
      );
      if (
        (SameCareer.length > 0 && isNaN(this.career.id)) ||
        (SameCareer.length > 0 &&
          !SameCareer.find((career) => career.id == this.career.id))
      )
        return SameCareer;
      return [];
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.career[key];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.career.$touch();
      if (this.$v.career.$anyError) {
        return;
      }
      if (!this.SameCareer) {
        if (isNaN(this.career.id)) this.createCareer();
        else this.updateCareer();
      }
    },
    createCareer() {
      this.$store.dispatch(names.POST_CAREER, this.career).then((response) => {
        toast(
          String(this.$getVisibleNames("mesh.career", false, "Programa")) +
            " creado."
        );
        this.$store.dispatch(names.FETCH_CURRENT_POSITION, this.user.id);
        this.$emit("created", response);
      });
    },
    updateCareer() {
      this.$store
        .dispatch(names.UPDATE_CAREER, this.career)
        .then((response) => {
          toast(
            String(this.$getVisibleNames("mesh.career", false, "Programa")) +
              " actualizado."
          );
          this.$emit("updated", response);
        });
    },
    deleteCareer() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_CAREER, this.career.id);
          this.$emit("deleted", this.career);
        }
      });
    },
  },
  watch: {},
  created() {
    this.$store.dispatch(names.FETCH_CAREERS);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
</style>