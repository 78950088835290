<template>
  <div>
    <div class="container-group">
      <div class="group">
        <b-form-group
          label="Nombre"
          label-for="input-name"
          label-cols="4"
          label-cols-sm="3"
          class="m-0"
        >
          <b-form-input
            id="input-name"
            v-model="$v.section.name.$model"
            :state="validateState('name')"
            :disabled="!allows_crud"
            aria-describedby="input-name-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-name-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
      </div>
      <div class="group">
        <b-form-group
          label="Color"
          label-for="input-color"
          label-cols="4"
          label-cols-sm="3"
          class="m-0"
        >
          <b-form-input
            id="input-color"
            v-model="$v.section.color.$model"
            :state="validateState('color')"
            :disabled="!allows_crud"
            aria-describedby="input-color-feedback"
            size="sm"
            type="color"
          ></b-form-input>
          <b-form-invalid-feedback id="input-color-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
      </div>
      <div class="group">
        <b-form-group
          v-if="egressProfile"
          label="Nivel"
          label-for="select-level"
          label-cols="4"
          label-cols-sm="3"
          class="m-0"
        >
          <b-form-select
            id="select-level"
            :options="levels"
            v-model="$v.section.plan_level.$model"
            :state="validateState('plan_level')"
            :disabled="!allows_crud || !edit_level"
            aria-describedby="select-level-feedback"
            size="sm"
          ></b-form-select>
          <b-form-invalid-feedback id="select-level-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
      </div>
      <div class="group">
        <b-form-group
          v-if="egressProfile"
          :label="
            $getVisibleNames('mesh.egressprofilematter', false, 'Asignatura')
          "
          label-for="select-level"
          label-cols="4"
          label-cols-sm="3"
          class="m-0"
        >
          <b-form-select
            id="select-level"
            :options="matterList"
            text-field="name"
            value-field="id"
            v-model="$v.section.matter.$model"
            :state="validateState('matter')"
            :disabled="!allows_crud || !edit_matter"
            aria-describedby="select-level-feedback"
            size="sm"
          ></b-form-select>
          <b-form-invalid-feedback id="select-level-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
      </div>
      <div class="group">
        <b-form-group
          :label="$getVisibleNames('mesh.period', false, 'Período')"
          label-for="select-period"
          label-cols="4"
          label-cols-sm="3"
          class="m-0"
        >
          <b-form-select
            id="select-period"
            :options="periodList"
            value-field="id"
            text-field="name"
            v-model="$v.section.period.$model"
            :state="validateState('period')"
            :disabled="!allows_crud || !edit_period"
            aria-describedby="select-period-feedback"
            size="sm"
          ></b-form-select>
          <b-form-invalid-feedback id="select-period-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
      </div>
      <div class="group">
        <b-form-group
          :label="$getVisibleNames('mesh.shift', false, 'Jornada')"
          label-for="select-shift"
          label-cols="4"
          label-cols-sm="3"
          class="m-0"
        >
          <b-form-select
            id="select-shift"
            :options="shifts"
            value-field="id"
            text-field="name"
            v-model="$v.section.shift.$model"
            :state="validateState('shift')"
            :disabled="!allows_crud || !edit_shift"
            aria-describedby="select-shift-feedback"
            size="sm"
          ></b-form-select>
          <b-form-invalid-feedback id="select-shift-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
      </div>
      <div class="group">
        <b-form-group
          :label="$getVisibleNames('mesh.campus', false, 'Sede')"
          label-for="select-campus"
          label-cols="4"
          label-cols-sm="3"
          class="m-0"
        >
          <b-form-select
            id="select-campus"
            :options="campuses"
            value-field="id"
            text-field="name"
            v-model="$v.section.campus.$model"
            :state="validateState('campus')"
            :disabled="!allows_crud || !edit_campus"
            aria-describedby="select-campus-feedback"
            size="sm"
          ></b-form-select>
          <b-form-invalid-feedback id="select-campus-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
      </div>
    </div>
    <h4>Estudiantes</h4>
    <UserList
      :section_id="section.id"
      :filter_by_professor="false"
      :filter_by_student="true"
      :allows_crud="allows_crud"
      @changed="slotStudentsChanged"
    ></UserList>
    <h4>Profesores</h4>
    <UserList
      :section_id="section.id"
      :filter_by_professor="true"
      :filter_by_student="false"
      :allows_crud="allows_crud"
      @changed="slotProfessorsChanged"
    ></UserList>
    <div class="row" v-if="allows_crud">
      <div
        v-if="show_delete_button && !isNaN(section.id)"
        class="col"
        style="text-align: left"
      >
        <b-button class="mr-1" size="sm" variant="danger" @click="askForDelete"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  minValue,
  maxValue,
  numeric,
} from "vuelidate/lib/validators";

export default {
  name: "SectionForm",
  mixins: [validationMixin],
  components: {
    UserList: () => import("./UserList"),
  },
  props: {
    Section: {
      type: Object,
    },
    egress_profile_id: {
      type: Number,
      required: true,
    },
    matter_id: {
      type: Number,
    },
    period_id: {
      type: Number,
    },
    shift_id: {
      type: Number,
    },
    campus_id: {
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    show_delete_button: {
      type: Boolean,
      default: true,
    },
    edit_matter: {
      type: Boolean,
      default: true,
    },
    edit_level: {
      type: Boolean,
      default: true,
    },
    edit_period: {
      type: Boolean,
      default: true,
    },
    edit_shift: {
      type: Boolean,
      default: true,
    },
    edit_campus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      section: {
        id: this.Section ? this.Section.id : generateUniqueId(),
        name: this.Section ? this.Section.name : "",
        color: this.Section ? this.Section.color : "#000000",
        attention_hours: this.Section ? this.Section.attention_hours : "",
        coexistence_ruls: this.Section ? this.Section.coexistence_ruls : "",
        plan_level: this.Section ? this.Section.plan_level : 1,
        matter: this.Section ? this.Section.matter : this.matter_id,
        period: this.Section ? this.Section.period : this.period_id,
        parent: this.Section ? this.Section.parent : null,
        shift: this.Section ? this.Section.shift : this.shift_id,
        campus: this.Section ? this.Section.campus : this.campus_id,
        students: this.Section ? this.Section.students : [],
        professors: this.Section ? this.Section.professors : [],
        sessions: this.Section ? this.Section.sessions : [],
        session_dates: this.Section ? this.Section.session_dates : [],
      },
    };
  },
  validations() {
    return {
      section: {
        name: { required },
        color: {},
        plan_level: {
          required,
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(18),
        },
        matter: { required },
        period: { required },
        shift: { required },
        campus: { required },
        students: {},
        professors: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
      periods: names.PERIODS,
      shifts: names.SHIFTS,
      campuses: names.CAMPUSES,
    }),
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    levels() {
      let list = [];
      if (!this.egressProfile) return list;
      for (
        let index = 1;
        index <= this.egressProfile.semester_amount;
        index++
      ) {
        list.push(index);
      }
      return list;
    },
    matterList() {
      return this.matters.filter(
        (x) =>
          x.egress_profile == this.egress_profile_id &&
          x.plan_level == this.section.plan_level
      );
    },
    periodList() {
      return this.periods;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.section[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.section.$touch();
      if (this.$v.section.$anyError) {
        return;
      }
      if (isNaN(this.section.id)) this.create();
      else this.update();
    },
    create() {
      this.$store
        .dispatch(names.POST_SECTION, this.section)
        .then((response) => {
          this.$emit("created", response);
          toast(
            this.$getVisibleNames("teaching.section", false, "Sección") +
              " creada."
          );
        });
    },
    update() {
      this.$store
        .dispatch(names.UPDATE_SECTION, this.section)
        .then((response) => {
          this.$emit("updated", response);
          toast(
            this.$getVisibleNames("teaching.section", false, "Sección") +
              " modificada."
          );
        });
    },
    askForDelete() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Sección?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) this.delete();
      });
    },
    delete() {
      this.$store.dispatch(names.DELETE_SECTION, this.section.id).then(() => {
        this.$emit("deleted", this.section);
        toast(
          this.$getVisibleNames("teaching.section", false, "Sección") +
            " eliminada."
        );
      });
    },
    slotStudentsChanged(students) {
      this.section.students = [];
      students.forEach((user) => {
        const index = this.section.students.findIndex((x) => x == user.id);
        if (index == -1) this.section.students.push(user.id);
      });
    },
    slotProfessorsChanged(professors) {
      this.section.professors = [];
      professors.forEach((user) => {
        const index = this.section.professors.findIndex((x) => x == user.id);
        if (index == -1) this.section.professors.push(user.id);
      });
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_SHIFTS);
  },
  mounted() {
    if (this.matter_id) {
      this.$store
        .dispatch(names.FETCH_MATTER, this.matter_id)
        .then((matter) => {
          this.section.plan_level = matter.plan_level;
        });
    }
  },
};
</script>

<style scoped>
.container-group {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  flex-wrap: wrap;
}
.group {
  width: 320px;
}
</style>