<template>
  <div ref="matrix_3">
    <!-- <div style="overflow: auto"> -->
    <div class="table-container cm-table-w-scroll table-responsive">
      <table class="table-responsive-lx">
        <thead :class="{ 'sticky-header': window_top > 49 }">
          <!-- <tr>
            <th class="div-header" colspan="100%">
              Matriz de Tributación de las Asignaturas al Perfil de Egreso
            </th>
          </tr> -->
          <tr>
            <!-- <th class="th-header" colspan="3"> -->
            <th class="th-header" colspan="2">
              Plan de
              <br />
              <span v-if="career">{{ career.name.toUpperCase() }}</span>
              <span v-else>... Cargando</span>
            </th>
            <template v-for="type in competenceTypes">
              <th
                class="th-header"
                :colspan="getMaxCompetencePerType(type.id)"
                :key="type.id"
                v-if="getMaxCompetencePerType(type.id) != 0"
              >
                <!-- rowspan="2" -->
                <!-- {{ $getVisibleNames("mesh.competence", true, "Competencias") }} -->
                {{ type.name.toUpperCase() }}
              </th>
            </template>
            <template
              ><th
                class="th-header"
                :colspan="getMaxCompetencePerType(null)"
                :key="'competence-type-null'"
                v-if="getMaxCompetencePerType(null) != 0"
              >
                {{
                  $getVisibleNames(
                    "mesh.competence",
                    true,
                    "Competencias"
                  ).toUpperCase()
                }}
              </th>
            </template>
          </tr>
          <tr>
            <th class="th-plan-level">Nivel</th>
            <!-- <th class="">Área de Formación</th> -->
            <th class="th-matters">
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                )
              }}
            </th>
            <th v-for="competence in sortcompentece" :key="competence.id">
              {{ competence.order + "."
              }}{{
                competence.profile_cycle ? competence.profile_cycle.order : ""
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <Matrix3Table
            v-for="item in sortedArray"
            :key="item.id"
            :matter="item"
            :matters="sortedArray"
            :cycles="cycles"
            :competence="sortcompentece"
            :egress_profile_id="egress_profile_id"
            :formationAreas="formationAreas"
          ></Matrix3Table>
        </tbody>
      </table>
      <div v-if="competencesList.length == 0" class="empty-text">
        No hay
        {{ $getVisibleNames("mesh.competence", true, "Competencias") }} que
        tributen a este
        {{ $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso") }}.
      </div>
    </div>
    <!-- </div> -->
    <!-- <MeshCustomFab
      :egress_profile_id="egress_profile_id"
      :show_matrix3="false"
    ></MeshCustomFab> -->
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "Matrix3View",
  components: {
    Matrix3Table: () => import("@/components/mesh/Matrix3/Matrix3Table"),
    // MeshCustomFab: () => import("../MeshCustomFab"),
    // SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    // cycle_id: {
    //   type: Number,
    //   required: true,
    // },
    isActive: {
      value: false,
    },
    printing: {
      value: false,
    },
  },
  data() {
    return {
      institution_id: this.$store.getters.getInstitutionId,
      title: "Expandir/Contraer",
      window_top: 0,
      current_table_height: null,
    };
  },
  computed: {
    ...mapGetters({
      formationAreas: names.FORMATION_AREAS,
      matters: names.MATTERS,
      competenceTypes: names.COMPETENCE_TYPES,
      competences: names.COMPETENCES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      profile_competences: names.PROFILE_COMPETENCES,
      cycles: names.CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      egressProfileCycles: names.PROFILE_CYCLES,
      courses_wrapper: names.COURSES_WRAPPER,
    }),
    sortcompentece: function () {
      function order(a, b) {
        if (a.type_order > b.type_order) return 1;
        if (a.type_order < b.type_order) return -1;
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.profile_cycle_order > b.profile_cycle_order) return 1;
        if (a.profile_cycle_order < b.profile_cycle_order) return -1;
        return 0;
      }
      return [...this.competencesList].sort(order);
    },
    sortedArray: function () {
      function order(a, b) {
        if (a.plan_level < b.plan_level) return -1;
        if (a.plan_level > b.plan_level) return 1;
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      }
      return [...this.matterList].sort(order);
    },
    competencesList() {
      let list = [];
      let competences = this.profile_competences.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
      competences.forEach((competence) => {
        if (competence) {
          let get_competence = this.competences.find(
            (x) => x.id == competence.competence
          );
          let get_cycle = null;
          let profile_cycle_order = 0;
          this.egressProfileCycles.forEach((profile_cycle) => {
            if (
              profile_cycle.egress_profile == competence.egress_profile &&
              profile_cycle.cycle == competence.cycle
            ) {
              get_cycle = profile_cycle;
              profile_cycle_order = profile_cycle.order;
            }
          });
          let type_order = 1000;
          if (get_competence && get_competence.type != null) {
            const competence_type = this.competenceTypes.find(
              (x) => x.id == get_competence.type
            );
            if (competence_type) type_order = competence_type.order;
          }

          if (get_competence) {
            list.push({
              id: competence.competence,
              order: competence.order,
              egress_profile: competence.egress_profile,
              cycle: competence.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              type: get_competence.type,
              type_order: type_order,
              verb: get_competence.verb,
              full_sentence: get_competence.full_sentence,
              name: get_competence.name,
              profile_cycle: get_cycle,
              profile_cycle_order: profile_cycle_order,
            });
          }
        }
      });
      return list;
      // return list.filter((x) => x.cycle == this.cycle_id);
    },
    coursesWrapperList() {
      return this.courses_wrapper.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
    },
    wrapperMatterIds() {
      let list = [];
      this.coursesWrapperList.forEach((x) => {
        list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    matterList() {
      if (!this.egress_profile_id) return [];
      return this.egress_profiles_matters.filter(
        (x) =>
          x.egress_profile == this.egress_profile_id &&
          !this.wrapperMatterIds.includes(x.id) &&
          this.semesterAmount.includes(x.plan_level)
      );
    },
    egressProfile() {
      if (!this.egress_profile_id) return [];
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    egressProfileCycleId() {
      if (!this.egress_profile_id) return [];
      return this.egressProfileCycles
        .filter((x) => x.egress_profile == this.egress_profile_id)
        .map((x) => x.cycle);
    },
    semesterAmount() {
      let min = 14;
      let max = 0;
      if (this.egressProfileCycleId.length == 0) {
        min = 1;
        max = this.egressProfile.semester_amount;
      } else {
        this.egressProfileCycles.forEach((cycle) => {
          if (
            cycle.init_semester < min &&
            cycle.egress_profile == this.egressProfile.id
          )
            min = cycle.init_semester;
          if (
            cycle.end_semester > max &&
            cycle.egress_profile == this.egressProfile.id
          )
            max = cycle.end_semester;
        });
      }
      if (max > this.egressProfile.semester_amount) {
        max = this.egressProfile.semester_amount;
      }
      let list = [];
      for (let index = min; index <= max; index++) {
        list.push(index);
      }
      return list;
    },
  },
  methods: {
    // redirectToMatrix2(cycle_id) {
    //   let egress_profile_id = this.egressProfile.id;
    //   this.$router.push({
    //     path: `/matrix2/form/${egress_profile_id}/${cycle_id}`,
    //   });
    // },
    getMaxCompetencePerType(type) {
      let max = 0;
      let list = [];
      let competences = this.profile_competences.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
      competences.forEach((competence) => {
        if (competence) {
          let get_competence = this.competences.find(
            (x) => x.id == competence.competence
          );
          if (get_competence) {
            list.push({
              id: competence.competence,
              order: competence.order,
              egress_profile: competence.egress_profile,
              cycle: competence.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              type: get_competence.type,
              verb: get_competence.verb,
              sentence: get_competence.sentence,
              name: get_competence.name,
            });
          }
        }
      });
      if (!this.cycle_id) {
        list.forEach((element) => {
          if (element.type == type) {
            max += 1;
          }
        });
      }
      // else {
      //   list.forEach((element) => {
      //     if (element.type == type && element.cycle == this.cycle_id) {
      //       max += 1;
      //     }
      //   });
      // }
      return max;
    },
    onScroll(e) {
      this.window_top = e.target.documentElement.scrollTop;
    },
  },
  watch: {
    egressProfile: function () {
      if (this.egressProfile)
        this.$store.dispatch(names.FETCH_CAREER, this.egressProfile.career);
    },
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
    setTimeout(() => {
      this.current_tab_height = this.$refs.matrix_3.clientHeight;
    }, 1500);
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
<style scoped>
table {
  width: 100%;
}
th {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
  font-size: var(--secondary-font-size);
  border: 1px solid black !important;
}
.cm-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.cm-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.cm-scrollbar::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

.cm-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #919191;
}

.cm-table-w-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  max-height: 760px;
}

.cm-table-w-scroll table {
  width: 100%;
  margin: auto;
  /* border-collapse: separate; */
  border-spacing: 0;
}

.cm-table-w-scroll table th,
.cm-table-w-scroll table td {
  font-size: 13px;
  padding: 5px 10px;
  vertical-align: top;
  white-space: nowrap;
}

.cm-table-w-scroll thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border: 1px solid black !important;
  font-weight: 600;
}
.rotated {
  transform: rotate(270deg);
}
.thd {
  vertical-align: middle;
  border: 1px solid black;
  border-width: thin;
  text-align: center;
  font-size: var(--thirth-font-size);
  height: 7rem;
  letter-spacing: 0.1px;
  max-width: 4.5rem;
  width: 3.5rem;
  margin: 0px;
  padding: 12px 1px;
  word-wrap: break-word;
  hyphens: auto;
  text-overflow: ellipsis;
  vertical-align: top !important;
}
.th-plan-level {
  width: 10%;
}
.th-matters {
  width: 30%;
}
.th-header {
  height: 5rem;
  vertical-align: middle !important;
}
.table-container {
  /* overflow: scroll;
  height: 40rem; */
  text-align: center;
  width: 100%;
}
.div-header {
  background-color: var(--primary-color);
  border: 1px solid var(--kl-menu-hover-color);
  color: var(--primary-font-color);
  min-width: 60px;
  width: 100% auto;
  margin-bottom: 0px;
}
.content-loader tr td {
  white-space: nowrap;
}
.empty-text {
  background: rgb(245, 245, 245);
  margin: auto;
  padding: 0.2em 1em;
  width: 82vh;
  border-radius: 3px;
  font-weight: bold;
  font-size: 11pt;
}

@media (max-width: 600px) {
  .empty-text {
    width: 75vh;
  }
}

@media print {
  .cm-table-w-scroll {
    overflow: visible;
    max-height: 100%;
  }
}
/* .competences {
  margin-left: -7px;
  margin-right: -9px;
  word-spacing: normal;
  max-height: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 12px;
  transition: max-height 1.5s ease cubic-bezier(0, 1.05, 0, 1);
}
.generic-competences {
  margin-top: 0 !important;
  padding-top: 0;
}
.view-more {
  max-height: none !important;
  top: 0 !important;
  transition: max-height 1.5s ease;
}
 .show-th-competences {
  transition-duration: 180ms !important;
  width: 25px;
  height: 12px;
  padding: 0 0 20px 0;
}
.show-th-competences:hover {
  background-color: #838383;
} 
.show-competences {
  background-color: #6c757d;
  margin-left: 10px;
  border-radius: 30px;
  padding: 1px;
}
.show-competences:hover{
  background-color: #838383;
  cursor: pointer !important;
} */
</style>