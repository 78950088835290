<template>
  <div>
    <b-form-group
      label="Nombre:"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.course_wrapper.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
        class="input-name"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción:"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-description"
        name="input-description"
        v-model="$v.course_wrapper.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      label="Nivel Inicial:"
      label-for="input-init_level"
      class="label my-2 mx-0"
    >
      <b-form-input
        id="input-init_level"
        v-model="$v.course_wrapper.init_level.$model"
        :state="validateState('init_level')"
        type="number"
        aria-describedby="input-init_level-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-init_level-live-feedback">
        <div v-if="course_wrapper.init_level > egressProfile.semester_amount">
          Este campo debe ser mayor que 0 y menor o igual de
          {{ egressProfile ? egressProfile.semester_amount : 0 }}
        </div>
        <div v-if="course_wrapper.init_level > course_wrapper.end_level">
          Nivel inicial no debe ser superior al nivel final
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      label="Nivel Final:"
      label-for="input-end_level"
      class="label my-2 mx-0"
    >
      <b-form-input
        id="input-end_level"
        v-model="$v.course_wrapper.end_level.$model"
        :state="validateState('end_level')"
        type="number"
        aria-describedby="input-end_level-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-end_level-live-feedback">
        <div v-if="course_wrapper.end_level > egressProfile.semester_amount">
          Este campo debe ser mayor que 0 y menor o igual de
          {{ egressProfile ? egressProfile.semester_amount : 0 }}
        </div>
        <div v-if="course_wrapper.init_level > course_wrapper.end_level">
          Nivel final no debe ser menor al nivel inicial
        </div></b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      :label="
        $getVisibleNames('mesh.formationarea', false, 'Área De Formación') + ':'
      "
      label-for="input-formation-area"
      class="label my-2 mx-0"
    >
      <b-form-select
        id="input-formation-area"
        :options="formationAreas"
        v-model="$v.course_wrapper.formation_area.$model"
        value-field="id"
        text-field="name"
        :state="validateState('formation_area')"
        type="number"
        aria-describedby="input-formation-area-feedback"
        size="sm"
      >
        <template #first>
          <b-form-select-option :value="null"
            >N/A
          </b-form-select-option></template
        ></b-form-select
      >
      <b-form-invalid-feedback id="input-formation-area-feedback"
        >Este campo no puede ser vacío.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      :label="
        $getVisibleNames('mesh.formationline', false, 'Línea De Formación')
      "
      label-for="input-formation-line"
      class="label my-2 mx-0"
    >
      <div class="d-flex">
        <div class="d-block w-100">
          <v-select
            id="input-formation-line"
            v-model="$v.course_wrapper.formation_line.$model"
            :options="formationLinesList"
            :reduce="(formationLinesList) => formationLinesList.id"
            :placeholder="`${
              formationLinesList.length > 0
                ? String(
                    'Seleccione una ' +
                      $getVisibleNames(
                        'mesh.formationline',
                        false,
                        'Línea de Formación'
                      ).toLowerCase()
                  )
                : String(
                    'No cuenta con ' +
                      $getVisibleNames(
                        'mesh.formationline',
                        true,
                        'Líneas de Formación'
                      ).toLowerCase() +
                      ' creadas'
                  )
            }`"
            :dropdown-should-open="dropdownShouldOpen"
            :state="validateState('formation_line')"
            label="name"
            track-by="id"
            size="sm"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No se encontraron resultados para: "<em>{{ search }}</em
                >"
              </template>
            </template>
          </v-select>
        </div>
        <button
          v-if="career"
          v-can="'mesh.add_formationline'"
          @click="openModalFormationLine"
          class="btn-add"
          v-b-tooltip.v-secondary.noninteractive="
            `Agregar ${$getVisibleNames(
              'mesh.formationline',
              false,
              'Línea De Formación'
            )}
            `
          "
        >
          <b-icon font-scale="1" icon="plus-square"></b-icon>
        </button>
      </div>
      <b-tooltip
        target="input-formation-line"
        variant="secondary"
        :noninteractive="true"
        triggers="hover"
      >
        <div v-if="formationLinesList.length == 0">
          Diríjase a Definiciones Curriculares para poder crear
          {{
            $getVisibleNames(
              "mesh.formationline",
              true,
              "Líneas De Formación"
            ).toLowerCase()
          }}.
        </div>
      </b-tooltip>
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      :label="
        $getVisibleNames('manual.credits', true, 'Créditos SCT Totales') + ':'
      "
      label-for="input-credits"
      class="label my-2 mx-0"
    >
      <b-form-input
        id="input-credits"
        v-model="$v.course_wrapper.credits.$model"
        :state="validateState('credits')"
        type="number"
        aria-describedby="input-credits-live-feedback"
        size="sm"
      ></b-form-input>
    </b-form-group>
    <!-- <b-form-group
      label="Color"
      label-for="input-color"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        type="color"
        id="input-color"
        name="input-color"
        v-model="$v.course_wrapper.color.$model"
        :state="validateState('color')"
        aria-describedby="input-color-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      >
      </b-form-input>
    </b-form-group> -->
    <b-form-group
      :label="
        $getVisibleNames('mesh.egressprofilematter', true, 'Asignaturas') + ':'
      "
      label-for="input-egress_profile_matters"
      label-cols="0"
      label-cols-sm="3"
      class="sm p-0 m-0"
    >
      <b-input-group class="mb-1">
        <b-form-input
          v-model="input_search"
          type="search"
          size="sm"
          :placeholder="`Buscar ${$getVisibleNames(
            'mesh.egressprofilematter',
            true,
            'Asignaturas'
          )}...`"
        >
        </b-form-input>
        <b-input-group-append>
          <b-button
            :disabled="!input_search"
            @click="input_search = ''"
            size="sm"
            >Limpiar</b-button
          >
        </b-input-group-append>
      </b-input-group>
      <b-form-select
        v-if="mattersOptions.length > 0"
        id="input-egress_profile_matters"
        v-model="$v.course_wrapper.egress_profile_matters.$model"
        :state="validateState('egress_profile_matters')"
        aria-describedby="input-egress_profile_matters-feedback"
        size="sm"
        multiple
        :select-size="
          searchMatterOptions.length <= 10 ? searchMatterOptions.length : 15
        "
      >
        <b-form-select-option
          class="select-option"
          v-for="option in searchMatterOptions"
          :key="option.id"
          :value="option.id"
          :disabled="matterDisabled.includes(option.id)"
        >
          {{ option.label }}
        </b-form-select-option>
      </b-form-select>
      <div class="pt-2" v-else>
        Esta {{ $getVisibleNames("mesh.career", false) }} no cuenta con
        {{ $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas") }}.
      </div>
      <b-form-invalid-feedback id="input-egress_profile_matters-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-modal
      :id="`new-formation-line-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.formationline',
        false,
        'Línea De Formación'
      )}`"
      size="md"
    >
      <FormationLineForm
        :faculty_id="career.faculty"
        @created="slotCreatedFormationLine"
      ></FormationLineForm>
    </b-modal>
    <div class="row mt-3">
      <div
        v-if="show_delete_button && !isNaN(this.course_wrapper.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDeleteObject"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0 mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import "vue-multiselect/dist/vue-multiselect.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {
  required,
  minLength,
  minValue,
  numeric,
} from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "CourseWrapperForm",
  mixins: [validationMixin],
  components: {
    vSelect,
    FormationLineForm: () =>
      import(
        "@/components/mesh/CurricularResources/FormationLine/FormationLineForm"
      ),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    CourseWrapper: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          egress_profile: this.egress_profile_id,
          egress_profile_matters: [],
          init_level: 1,
          end_level: 1,
          max_matters: 0,
          formation_area: null,
          formation_line: null,
          credits: 0,
          color: "#CBDDE1",
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input_search: "",
      course_wrapper: { ...this.CourseWrapper },
    };
  },
  validations: {
    course_wrapper: {
      name: {
        required,
        minLength: minLength(2),
      },
      description: {},
      init_level: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue() {
          // return true;
          if (
            this.course_wrapper.init_level <= this.egressProfile.semester_amount
          )
            return true;
          else return false;
        },
        valid() {
          if (this.course_wrapper.init_level <= this.course_wrapper.end_level)
            return true;
          else return false;
        },
      },
      end_level: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue() {
          // return true;
          if (
            this.course_wrapper.end_level <= this.egressProfile.semester_amount
          )
            return true;
          else return false;
        },
        valid() {
          if (this.course_wrapper.init_level <= this.course_wrapper.end_level)
            return true;
          else return false;
        },
      },
      formation_area: {},
      formation_line: {},
      max_matters: {},
      credits: { numeric },
      color: {},
      egress_profile_matters: {},
      egress_profile: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      courses_wrapper: names.COURSES_WRAPPER,
      matters: names.MATTERS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      egress_profiles: names.EGRESS_PROFILES,
      formationAreas: names.FORMATION_AREAS,
      formationLines: names.FORMATION_LINES,
      careers: names.CAREERS,
      faculties: names.FACULTIES,
    }),
    egressProfile() {
      if (!this.egress_profile_id) return [];
      return this.egress_profiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    formationLinesList() {
      if (!this.career) return [];
      return this.formationLines.filter((x) =>
        x.faculties.includes(this.career.faculty)
      );
    },
    searchMatterOptions() {
      return this.mattersOptions.filter((item) => {
        return (
          this.$filtered(item.name, this.input_search) ||
          this.course_wrapper.egress_profile_matters.includes(item.id)
        );
      });
    },
    matterDisabled() {
      if (!this.egress_profile_id) return [];
      let list = [];
      const courses = this.courses_wrapper.filter(
        (x) =>
          x.egress_profile == this.egress_profile_id &&
          x.id != this.course_wrapper.id
      );
      courses.forEach((x) => {
        list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    mattersOptions() {
      let list = [];
      list = this.egress_profiles_matters.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
      list.forEach((x) => {
        const matter = this.matters.find((matter) => matter.id == x.matter);
        if (matter) {
          x.name = matter.name;
          x.label = String(x.order + " - " + matter.name);
          //   x.label = String(x.order + " - " + x.code + " - " + matter.name);
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.course_wrapper[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.course_wrapper.$touch();
      if (this.$v.course_wrapper.$anyError) {
        return;
      }
      if (isNaN(this.course_wrapper.id)) {
        this.createCourseWrapper();
      } else this.updateCourseWrapper();
    },
    createCourseWrapper() {
      this.$store
        .dispatch(names.POST_COURSE_WRAPPER, this.course_wrapper)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.coursewrapper",
                false,
                "Envoltorio de Asignaturas"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateCourseWrapper() {
      this.$store
        .dispatch(names.UPDATE_COURSE_WRAPPER, this.course_wrapper)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.coursewrapper",
                false,
                "Envoltorio de Asignaturas"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteCourseWrapper() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.coursewrapper",
          false,
          "Envoltorio de Asignaturas"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_COURSE_WRAPPER,
            this.course_wrapper.id
          );
          this.$emit("deleted", this.course_wrapper);
        }
      });
    },
    dropdownShouldOpen(VueSelect) {
      if (this.formationLinesList.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    openModalFormationLine() {
      if (this.faculties.length == 0)
        this.$store.dispatch(names.FETCH_FACULTIES);
      this.$bvModal.show(`new-formation-line-modal`);
    },
    slotCreatedFormationLine(respone) {
      this.course_wrapper.formation_line = respone.id;
      this.$bvModal.hide("new-formation-line-modal");
    },
  },
  created() {},
};
</script>

<style scoped>
.label {
  margin-top: 1%;
}
.input-name {
  margin-top: -1px;
}
select option:disabled {
  font-weight: bold !important;
  background-color: #e9e5e5 !important;
}
#input-color {
  cursor: pointer;
}
.select-option {
  overflow-wrap: break-word;
  white-space: pre-line;
}
.v-select >>> .vs__search,
.v-select >>> .vs__search:focus,
.v-select >>> .vs__selected {
  font-size: 0.875rem !important;
  color: #495057 !important;
}
.v-select >>> .vs__clear {
  margin-bottom: 0.2rem !important;
}
.btn-add {
  border: none;
  background: transparent;
  font-size: 13pt;
  padding: 0;
  margin-left: 0.7rem;
}
</style>