var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('b-breadcrumb',{staticClass:"breadcrumb pb-2 pt-1 noprint",attrs:{"items":_vm.breadcrumb_items}}),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('common.add_region'),expression:"'common.add_region'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-region-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames("common.region", false, "Región"))+" ")]):_vm._e()])]),_c('h3',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("common.region", false, "Región"))+" ")]),_c('GenericBTable',{attrs:{"items":_vm.regions,"fields":_vm.regionsFields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('common.change_region'),expression:"'common.change_region'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Editar ${_vm.$getVisibleNames('common.region', false, 'Región')}`
          ),expression:"\n            `Editar ${$getVisibleNames('common.region', false, 'Región')}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-region-modal-${row.item.id}`)}}}),_c('button-delete',{directives:[{name:"can",rawName:"v-can",value:('common.delete_region'),expression:"'common.delete_region'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Eliminar ${_vm.$getVisibleNames('common.region', false, 'Región')}`
          ),expression:"\n            `Eliminar ${$getVisibleNames('common.region', false, 'Región')}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteRegion(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-region-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'common.region',
            false,
            'Región'
          )}`,"size":"lg","hide-footer":""}},[_c('RegionForm',{attrs:{"Region":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedRegion}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-region-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames('common.region', false, 'Región')}`,"size":"lg"}},[_c('RegionForm',{on:{"created":_vm.slotCreatedRegion}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }