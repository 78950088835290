<template>
  <span
    @click="showText = !showText"
    class="text-container px-1"
    :class="{ 'cursor-help': !showText }"
  >
    <span v-if="!showText">
      <span class="bounded-text">{{ Text.substring(0, Large).trim() }}</span>
      <b-icon
        v-if="Text.length > Large"
        class="icon-class"
        icon="three-dots"
        v-b-tooltip.right.v-secondary.noninteractive="tooltip_text"
      ></b-icon
      ><b-icon
        v-if="Text.length > Large"
        icon="chevron-right"
        class="cursor-pointer"
        v-b-tooltip.top.v-secondary.noninteractive="'Click para ver más'"
      ></b-icon>
    </span>
    <span v-else
      >{{ Text }}
      <b-icon
        v-if="Text.length > Large"
        icon="chevron-left"
        class="cursor-pointer"
        v-b-tooltip.top.v-secondary.noninteractive="'Click para ver menos'"
      ></b-icon
    ></span>
  </span>
</template>
<script>
export default {
  name: "BoundedTextContainer",
  props: {
    Text: {
      type: String,
      required: true,
    },
    Large: {
      type: Number,
      default: 100,
    },
    tooltip_text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showText: false,
    };
  },
};
</script>
<style>
.text-container {
  width: max-content !important;
}
.icon-class {
  vertical-align: bottom !important;
}
.cursor-help {
  cursor: help;
}
.cursor-pointer {
  cursor: pointer;
}
</style>