var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAnual)?_c('tbody',{staticStyle:{"border":"1.5px solid #e2e2e2"}},[_vm._l((_vm.MatrixList),function(row,i){return [_c('tr',{key:i},[(i == 0)?_c('td',{staticClass:"formation-area-col",attrs:{"rowspan":_vm.getMaxMatterPerSemester}},[_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.v-secondary.noninteractive",value:(
            _vm.FormationArea.description
          ),expression:"\n            FormationArea.description\n          ",modifiers:{"top":true,"v-secondary":true,"noninteractive":true}}],staticClass:"wrap-text"},[_c('b',[_vm._v(_vm._s(_vm.FormationArea.name))])])]):_vm._e(),_vm._l((row),function(matter,j){return [(matter && matter.is_matter == true)?[(matter)?[(
                (matter.regime == _vm.isAnual.id &&
                  matter.plan_level == _vm.semesterAmount[j]) ||
                (matter.regime == _vm.isAnual.id &&
                  matter.plan_level == _vm.semesterAmount[j] - 1 &&
                  _vm.semesterAmount[j] == _vm.semesterAmount[0])
              )?_c('td',{key:`${i}-${j}`,staticClass:"matter-cel",attrs:{"colspan":_vm.semesterAmount[_vm.semesterAmount.length - 1] ==
                  matter.plan_level ||
                _vm.semesterAmount[0] - 1 == matter.plan_level
                  ? 1
                  : 2}},[_c('MatterCard',{key:matter.id,attrs:{"Matter":matter,"EgressProfile":_vm.EgressProfile}})],1):(
                matter.regime == _vm.isAnual.id &&
                matter.plan_level < _vm.semesterAmount[j]
              )?void 0:_c('td',{key:`${i}-${j}`,staticClass:"matter-cel"},[_c('MatterCard',{key:matter.id,attrs:{"Matter":matter,"EgressProfile":_vm.EgressProfile}})],1)]:_c('td',{key:`${i}-${j}`,staticClass:"matter-cel"})]:(matter && matter.is_matter == false)?[(matter)?[(
                (matter.init_level == _vm.semesterAmount[j] &&
                  matter.end_level - matter.init_level != 0) ||
                (_vm.cycle_id &&
                  matter.end_level - matter.init_level != 0 &&
                  _vm.semesterAmount[0] <= matter.end_level &&
                  _vm.semesterAmount[0] == _vm.semesterAmount[j])
              )?_c('td',{key:`${i}-${j}`,staticClass:"matter-cel",attrs:{"colspan":matter.end_level >= _vm.semesterAmount[j] &&
                matter.init_level < _vm.semesterAmount[0]
                  ? matter.end_level - _vm.semesterAmount[0] + 1
                  : matter.end_level - matter.init_level + 1}},[_c('CourseCard',{key:matter.id,attrs:{"CourseWrapper":matter,"EgressProfile":_vm.EgressProfile}})],1):(
                matter.end_level - matter.init_level != 0 &&
                matter.init_level < _vm.semesterAmount[j]
              )?void 0:_c('td',{key:`${i}-${j}`,staticClass:"matter-cel"},[_c('CourseCard',{key:matter.id,attrs:{"CourseWrapper":matter,"EgressProfile":_vm.EgressProfile}})],1)]:_c('td',{key:`${i}-${j}`,staticClass:"matter-cel"})]:_c('td',{key:`${i}-${j}`,staticClass:"matter-cel"})]})],2)]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }