<template>
  <b-modal
    :id="modal_id"
    :title="`Seleccionar ${$getVisibleNames(
      'mesh.cycle',
      true,
      'Ciclos Formativos'
    )}`"
    size="lg"
    hide-footer
  >
    <!-- @ok="accept" -->
    <b-button class="mb-2" v-b-modal.new-egress-profile-cycle-modal
      >Crear
      {{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}</b-button
    >
    <EgressProfileCycleTable
      :allows_crud="false"
      :selection_mode="true"
      :egress_profile_id="egress_profile.id"
      :selected_ids="selected_cycle_ids"
      @cycle_changed="slotCycleChanged"
    ></EgressProfileCycleTable>
    <div>
      <b-modal
        id="new-egress-profile-cycle-modal"
        :title="`Crear ${$getVisibleNames(
          'mesh.cycle',
          false,
          'Ciclo Formativo'
        )} en ${$getVisibleNames(
          'mesh.egressprofile',
          false,
          'Perfil de Egreso'
        )}`"
        size="lg"
        hide-footer
      >
        <EgressProfileCycleForm
          @created="slotCreatedCycle"
          @close="hideModal()"
          :show_title="false"
          :modal_id="modal_id"
          :egress_profile="egress_profile"
          :egress_profile_cycles="selected_cycle_ids"
        ></EgressProfileCycleForm>
      </b-modal>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="accept">Guardar</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "CycleModal",
  components: {
    EgressProfileCycleTable: () =>
      import(
        "@/components/mesh/EgressProfile/EgressProfileCycle/EgressProfileCycleTable"
      ),
    EgressProfileCycleForm: () =>
      import(
        "@/components/mesh/EgressProfile/EgressProfileCycle/EgressProfileCycleForm.vue"
      ),
  },
  props: {
    modal_id: {
      type: String,
      default: "modal-select-cycle",
      required: false,
    },
    selected_ids: {
      type: Array,
    },
    egress_profile: {
      type: Object,
    },
  },
  data() {
    return {
      selected_cycle_ids: this.selected_ids ? this.selected_ids : [],
      cycles_list: [],
    };
  },
  computed: {
    ...mapGetters({
      profileCycles: names.PROFILE_CYCLES,
      profile_competences: names.PROFILE_COMPETENCES,
    }),
    egressProfileCycles() {
      if (this.egress_profile && !isNaN(this.egress_profile.id))
        return this.profileCycles.filter(
          (x) => x.egress_profile == this.egress_profile.id
        );
      else return [];
    },
    egressProfileCompetence() {
      if (this.egress_profile && !isNaN(this.egress_profile.id))
        return this.profile_competences.filter(
          (x) => x.egress_profile == this.egress_profile.id
        );
      else return [];
    },
  },
  methods: {
    slotCycleChanged(cycles) {
      this.cycles_list = cycles;
      // const index = this.selected_cycle_ids.findIndex((x) => x == selected_cycle.id);
      // if (index == -1) {
      //   if (selected_cycle.selected) this.selected_cycle_ids.push(cycle_id);
      // } else {
      //   if (!selected) this.selected_cycle_ids.splice(index, 1);
      // }
    },
    slotCreatedCycle() {
      this.$bvModal.hide("new-egress-profile-cycle-modal");
    },
    hideModal() {
      this.$bvModal.hide(this.modal_id);
    },
    createProfileCycle(profile_cycle) {
      this.$store
        .dispatch(names.POST_PROFILE_CYCLE, profile_cycle)
        .then((response) => {
          if (response) {
            toast(
              String(
                this.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo") +
                  " creado en el " +
                  this.$getVisibleNames(
                    "mesh.egressprofile",
                    false,
                    "Perfil de Egreso"
                  ) +
                  "."
              )
            );
          }
        });
    },
    updateProfileCycle(profile_cycle) {
      this.$store
        .dispatch(names.UPDATE_PROFILE_CYCLE, profile_cycle)
        .then(() => {
          toast(
            String(
              "Relación entre " +
                this.$getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil de Egreso"
                ) +
                " y " +
                this.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo") +
                " actualizada."
            )
          );
        });
    },
    deleteProfileCycle(egress_profile_cycle) {
      if (isNaN(egress_profile_cycle.id) || !egress_profile_cycle.id) return;
      this.$store
        .dispatch(names.DELETE_PROFILE_CYCLE, egress_profile_cycle.id)
        .then(() => {
          // let competences = this.profile_competences.filter(
          //   (x) =>
          //     x.egress_profile == egress_profile_cycle.egress_profile &&
          //     x.cycle == egress_profile_cycle.cycle
          // );
          // competences.forEach((x) => {
          //   this.$store.dispatch(names.DELETE_PROFILE_COMPETENCE, x.id);
          // });
          toast(
            String(
              this.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo") +
                " eliminado del " +
                this.$getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil de Egreso"
                ) +
                "."
            )
          );
        });
    },
    async changeProfileCycles() {
      for await (let profile_cycle of this.cycles_list) {
        if (isNaN(profile_cycle.id) && profile_cycle.selected == true) {
          // this.createProfileCycle(profile_cycle);
          await this.$store.dispatch(names.POST_PROFILE_CYCLE, profile_cycle);
        } else if (!isNaN(profile_cycle.id) && profile_cycle.selected == true) {
          // this.updateProfileCycle(profile_cycle);
          await this.$store.dispatch(names.UPDATE_PROFILE_CYCLE, profile_cycle);
        } else if (
          !isNaN(profile_cycle.id) &&
          profile_cycle.selected == false
        ) {
          await this.$store
            .dispatch(names.DELETE_PROFILE_CYCLE, profile_cycle.id)
            .then(() => {
              this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES, {
                cycle_id: null,
              });
            });
          // this.deleteProfileCycle(profile_cycle);
        }
      }
      // this.cycles_list.forEach((profile_cycle) => {
      // });
    },
    accept() {
      let save = true;
      let count = 0;
      this.cycles_list.forEach((x) => {
        count++;
        let filter = this.cycles_list.filter(
          (element) =>
            x.init_semester == element.init_semester &&
            x.end_semester == element.end_semester &&
            x.egress_profile == element.egress_profile &&
            element.selected == true
        );
        if (x.temp_end_semester) {
          x.end_semester = parseFloat(x.temp_end_semester);
        }
        if (
          filter.length > 1 ||
          parseFloat(x.init_semester) > parseFloat(x.end_semester) ||
          parseFloat(x.init_semester) < 0 ||
          parseFloat(x.end_semester) < 0
        ) {
          save = false;
        }
        if (this.cycles_list.length == count) {
          if (save) {
            if (
              this.cycles_list.filter((x) => x.selected == true).length <
                this.egressProfileCycles.length &&
              this.egressProfileCompetence.filter((x) =>
                this.cycles_list
                  .filter((c) => c.selected == false)
                  .map((i) => i.cycle)
                  .includes(x.cycle)
              ).length > 0
            ) {
              this.$swal({
                title: `¿Está seguro de que desea cambiar los ${this.$getVisibleNames(
                  "mesh.cycle",
                  true,
                  "Ciclos Formativos"
                )}?`,
                text: `Esta acción va a afectar a ${
                  this.egressProfileCompetence.filter((x) =>
                    this.cycles_list
                      .filter((c) => c.selected == false)
                      .map((i) => i.cycle)
                      .includes(x.cycle)
                  ).length
                } ${this.$getVisibleNames(
                  "mesh.competence",
                  true,
                  "Competencias"
                )}, las cuales pasarán a no tener ${this.$getVisibleNames(
                  "mesh.cycle",
                  false,
                  "Ciclo Formativo"
                )}.`,
                type: "warning",
                showCancelButton: true,
              }).then((result) => {
                if (result.value) {
                  this.changeProfileCycles();
                  this.$bvModal.hide(this.modal_id);
                }
              });
            } else {
              this.changeProfileCycles();
              this.$bvModal.hide(this.modal_id);
            }
          } else {
            if (filter.length > 1)
              return this.$swal({
                title:
                  "Debe indicar un nivel inicial y final diferente a los que existen actualmente en el perfil.",
                type: "warning",
              });
            else if (parseFloat(x.init_semester) > parseFloat(x.end_semester))
              return this.$swal({
                title: "El nivel Inicial no debe ser mayor que el nivel Final.",
                type: "warning",
              });
            else if (
              parseFloat(x.init_semester) < 0 ||
              parseFloat(x.end_semester) < 0
            )
              return this.$swal({
                title: "El nivel Inicial y Final no debe ser menor de 0",
                type: "warning",
              });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
</style>