<template>
  <div>
    <div class="mx-2">
      <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_basematterwrapper'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-base-matter-wrapper-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "mesh.basematterwrapper",
                false,
                "Envoltorio de Asignaturas"
              )
            }}
          </button>
        </div>
      </div>
      <h3>
        {{
          $getVisibleNames(
            "mesh.basematterwrapper",
            false,
            "Envoltorio de Asignaturas"
          )
        }}
      </h3>
      <GenericBTable
        :items="baseMattersWrapperList"
        :pagination="base_matters_wrapper.length"
        :fields="base_matters_wrapper_fields"
        :filter="input_search"
        :filterCustom="filterCustom"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @emitChangeSearchField="changeInputSearch"
      >
        <template #cell(color)="row">
          <div
            class="base-matter-wrapper-color"
            :style="{
              'background-color': row.item.color,
              color: row.item.color,
            }"
          ></div>
        </template>
        <template #cell(matters)="row">
          <div class="campus-container">
            <div
              v-for="item in getMatterName(row.item.matters)"
              :key="item.id"
              class="campus-div"
            >
              {{ item.name }}
            </div>
          </div>
        </template>
        <template #cell(actions)="row">
          <div class="d-flex">
            <template v-if="allows_crud">
              <button-edit
                v-can="'mesh.change_basematterwrapper'"
                @click="
                  $bvModal.show(`edit-base-matter-wrapper-modal-${row.item.id}`)
                "
                v-b-tooltip.v-secondary.noninteractive="
                  `Editar ${$getVisibleNames(
                    'mesh.basematterwrapper',
                    false,
                    'Envoltorio de Asignaturas'
                  )}`
                "
              ></button-edit>
              <button-delete
                v-can="'mesh.delete_basematterwrapper'"
                @click="askForDeleteBaseMatterWrapper(row.item.id)"
                v-b-tooltip.v-secondary.noninteractive="
                  `Eliminar ${$getVisibleNames(
                    'mesh.basematterwrapper',
                    false,
                    'Envoltorio de Asignaturas'
                  )}`
                "
              ></button-delete>
            </template>
          </div>
          <b-modal
            :id="`edit-base-matter-wrapper-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.basematterwrapper',
              false,
              'Envoltorio de Asignaturas'
            )}`"
            size="lg"
            hide-footer
          >
            <BaseMattersWrapperForm
              :BaseMatterWrapper="row.item"
              @updated="slotUpdatedBaseMatterWrapper"
            ></BaseMattersWrapperForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-base-matter-wrapper-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.basematterwrapper',
        false,
        'Envoltorio de Asignaturas'
      )}`"
      size="lg"
    >
      <BaseMattersWrapperForm
        @created="slotCreatedBaseMatterWrapper"
      ></BaseMattersWrapperForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "BaseMattersWrapperView",
  components: {
    BaseMattersWrapperForm: () =>
      import(
        "@/components/mesh/Matrix2/BaseMattersWrapper/BaseMattersWrapperForm"
      ),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    // allows_crud: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  data() {
    return {
      input_search: "",
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      formationAreas: names.FORMATION_AREAS,
      base_matters_wrapper: names.BASE_MATTERS_WRAPPER,
      matters: names.MATTERS,
    }),
    baseMattersWrapperList() {
      return this.base_matters_wrapper;
    },
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "mesh.change_basematterwrapper"
      );
      if (has_permission) return has_permission;
      else return false;
    },
    base_matters_wrapper_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.basematterwrapper",
              true,
              "Envoltorios de Asignaturas"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "init_level",
          label: "Nivel Inicial",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "end_level",
          label: "Nivel Final",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "formation_area",
          label: this.$getVisibleNames(
            "mesh.formationarea",
            false,
            "Área De Formación"
          ),
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
          thClass: "text-center align-middle",
          tdClass: "text-center",
          formatter: (value) => {
            return this.getFormationArea(value);
          },
        },
        {
          key: "credits",

          label: this.$getVisibleNames(
            "manual.credits",
            true,
            "Créditos SCT Totales"
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-center",
          display_column: true,
        },
        // {
        //   key: "color",
        //   label: "Color",
        //   sortable: false,
        //   thStyle: { background: "var(--primary-color) !important" },
        //   display_column: true,
        // },
        {
          key: "matters",
          label: String(
            this.$getVisibleNames("teaching.matter", true, "Asignaturas")
          ),
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
    breadcrumb_items() {
      return [
        { text: "Definiciones Curriculares", active: true },
        {
          text: this.$getVisibleNames(
            "mesh.basematterwrapper",
            false,
            "Envoltorio de Asignaturas"
          ),
          active: true,
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(String(row.name), this.input_search) ||
        this.$filtered(String(row.description), this.input_search) ||
        this.$filtered(String(row.init_level), this.input_search) ||
        this.$filtered(String(row.end_level), this.input_search) ||
        this.$filtered(String(row.credits), this.input_search) ||
        this.$filtered(
          String(this.getFormationArea(row.formation_area)),
          this.input_search
        ) ||
        this.matters.filter(
          (x) =>
            row.matters.includes(x.id) &&
            this.$filtered(x.name, this.input_search)
        ).length > 0
      ) {
        return true;
      } else {
        false;
      }
    },
    getMatterName(matters_ids) {
      const matters = [...new Set(matters_ids)];
      if (matters.length > 0) {
        const list = this.matters.filter((element) =>
          matters.includes(element.id)
        );
        return list.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
      } else return [];
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    askForDeleteBaseMatterWrapper(base_matter_wrapper_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.basematterwrapper",
          false,
          "Envoltorio de Asignaturas"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_BASE_MATTER_WRAPPER, base_matter_wrapper_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.basematterwrapper",
                    false,
                    "Envoltorio de Asignaturas"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    slotCreatedBaseMatterWrapper() {
      this.$bvModal.hide("new-base-matter-wrapper-modal");
    },
    slotUpdatedBaseMatterWrapper(base_matter_wrapper) {
      this.$bvModal.hide(
        `edit-base-matter-wrapper-modal-${base_matter_wrapper.id}`
      );
    },
    getFormationArea(formation_area_id) {
      if (!this.formationAreas) return "N/A";
      const formation_area = this.formationAreas.find(
        (x) => x.id == formation_area_id
      );
      return formation_area ? formation_area.name : "N/A";
    },
  },
  created() {
    if (this.formationAreas.length == 0) {
      this.$store.dispatch(names.FETCH_FORMATION_AREAS);
    }
    this.$store.dispatch(names.FETCH_MODALITIES);
    this.$store.dispatch(names.FETCH_MATTER_CONTRIBUTIONS);
    this.$store.dispatch(names.FETCH_MATTER_TYPES);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS);
    this.$store.dispatch(names.FETCH_TIME_ALLOCATIONS);
    this.$store.dispatch(names.FETCH_BASE_MATTERS_WRAPPER);
    this.$store.dispatch(names.FETCH_MATTERS, { is_elective: true });
  },
};
</script>
<style scoped>
.campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}
.base-matter-wrapper-color::before {
  min-width: 100px !important;
  height: 20px !important;
  opacity: 1;
  content: " #";
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
</style>