import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    study_environments: [],
    study_environments_url: '/mesh/study-environment',
    study_environments_time_allocation: [],
    study_environments_time_allocation_url: '/mesh/study-environment-time-allocation'
}

const getters = {
    [names.STUDY_ENVIRONMENTS]: state => {
        if (!get.store.state.user) return [];
        return state.study_environments.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.STUDY_ENVIRONMENTS_TIME_ALLOCATION]: state => {
        if (!get.store.state.user) return [];
        return state.study_environments_time_allocation.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_STUDY_ENVIRONMENTS]: (state, study_environments) => {
        study_environments.forEach(element => {
            let index = state.study_environments.findIndex(x => x.id == element.id);
            if (index != -1) state.study_environments.splice(index, 1, element);
            else state.study_environments.push(element);
        });
    },
    [names.MUTATE_STUDY_ENVIRONMENT]: (state, study_environment) => {
        let index = state.study_environments.findIndex(x => x.id == study_environment.id);
        if (index != -1) state.study_environments.splice(index, 1, study_environment);
        else state.study_environments.push(study_environment);
    },
    [names.MUTATE_DELETE_STUDY_ENVIRONMENT]: (state, study_environment_id) => {
        let index = state.study_environments.findIndex(x => x.id == study_environment_id);
        if (index != -1) state.study_environments.splice(index, 1);
    },
    // study_enviroment_time_allocation
    [names.MUTATE_STUDY_ENVIRONMENTS_TIME_ALLOCATION]: (state, study_environments_time_allocation) => {
        study_environments_time_allocation.forEach(element => {
            let index = state.study_environments_time_allocation.findIndex(x => x.id == element.id);
            if (index != -1) state.study_environments_time_allocation.splice(index, 1, element);
            else state.study_environments_time_allocation.push(element);
        });
    },
    [names.MUTATE_STUDY_ENVIRONMENT_TIME_ALLOCATION]: (state, study_environment_time_allocation) => {
        let index = state.study_environments_time_allocation.findIndex(x => x.id == study_environment_time_allocation.id);
        if (index != -1) state.study_environments_time_allocation.splice(index, 1, study_environment_time_allocation);
        else state.study_environments_time_allocation.push(study_environment_time_allocation);
    },
    [names.MUTATE_DELETE_STUDY_ENVIRONMENT_TIME_ALLOCATION]: (state, study_environment_time_allocation_id) => {
        let index = state.study_environments_time_allocation.findIndex(x => x.id == study_environment_time_allocation_id);
        if (index != -1) state.study_environments_time_allocation.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_STUDY_ENVIRONMENT]: ({
        commit,
        state
    }, study_environment_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.study_environments_url}/${study_environment_id}/`)
                .then(response => {
                    commit(names.MUTATE_STUDY_ENVIRONMENT, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_STUDY_ENVIRONMENTS]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.study_environments.length > 0) {
                resolve(state.study_environments);
                return;
            }
            restful.Get(`${state.study_environments_url}/`)
                .then(response => {
                    commit(names.MUTATE_STUDY_ENVIRONMENTS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_STUDY_ENVIRONMENT]: ({
        commit,
        state
    }, study_environment) => {
        return new Promise((resolve) => {
            restful.Post(`${state.study_environments_url}/`, study_environment)
                .then(response => {
                    commit(names.MUTATE_STUDY_ENVIRONMENT, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_STUDY_ENVIRONMENT]: ({
        commit,
        state
    }, study_environment) => {
        return new Promise((resolve) => {
            restful.Put(`${state.study_environments_url}/${study_environment.id}/`, study_environment)
                .then(response => {
                    commit(names.MUTATE_STUDY_ENVIRONMENT, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_STUDY_ENVIRONMENT]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.study_environments_url}/${payload.study_environment_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_STUDY_ENVIRONMENT, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_STUDY_ENVIRONMENT]: ({
        commit,
        state
    }, study_environment_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.study_environments_url}/${study_environment_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_STUDY_ENVIRONMENT, study_environment_id);
                    resolve(response);
                });
        });
    },
    // study_enviroment_time_allocation
    [names.FETCH_STUDY_ENVIRONMENT_TIME_ALLOCATION]: ({
        commit,
        state
    }, study_environment_time_allocation_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.study_environments_time_allocation_url}/${study_environment_time_allocation_id}/`)
                .then(response => {
                    commit(names.MUTATE_STUDY_ENVIRONMENT_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_STUDY_ENVIRONMENTS_TIME_ALLOCATION]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.study_environment) {
                    if (state.study_environments_time_allocation.filter(x => x.study_environment == payload.study_environment).length > 0) {
                        resolve(state.study_environments_time_allocation.filter(x => x.study_environment == payload.study_environment))
                        return;
                    } else
                        url_append += '?study_environment=' + payload.study_environment;
                } else
                    if (payload.time_allocation) {
                        if (state.study_environments_time_allocation.filter(x => x.time_allocation == payload.time_allocation).length > 0) {
                            resolve(state.study_environments_time_allocation.filter(x => x.time_allocation == payload.time_allocation))
                            return;
                        } else
                            url_append += '?time_allocation=' + payload.time_allocation;
                    }
            } else if (state.study_environments_time_allocation.length > 0) {
                resolve(state.study_environments_time_allocation);
                return;
            }
            restful.Get(`${state.study_environments_time_allocation_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_STUDY_ENVIRONMENTS_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.POST_STUDY_ENVIRONMENT_TIME_ALLOCATION]: ({
        commit,
        state
    }, study_environment) => {
        return new Promise((resolve) => {
            restful.Post(`${state.study_environments_time_allocation_url}/`, study_environment)
                .then(response => {
                    commit(names.MUTATE_STUDY_ENVIRONMENT_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_STUDY_ENVIRONMENT_TIME_ALLOCATION]: ({
        commit,
        state
    }, study_environment) => {
        return new Promise((resolve) => {
            restful.Put(`${state.study_environments_time_allocation_url}/${study_environment.id}/`, study_environment)
                .then(response => {
                    commit(names.MUTATE_STUDY_ENVIRONMENT_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_STUDY_ENVIRONMENT_TIME_ALLOCATION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.study_environments_time_allocation_url}/${payload.study_environment_time_allocation_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_STUDY_ENVIRONMENT_TIME_ALLOCATION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_STUDY_ENVIRONMENT_TIME_ALLOCATION]: ({
        commit,
        state
    }, study_environment_time_allocation_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.study_environments_time_allocation_url}/${study_environment_time_allocation_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_STUDY_ENVIRONMENT_TIME_ALLOCATION, study_environment_time_allocation_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};