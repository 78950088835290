<template>
  <div>
    <b-form-group
      v-if="!matrix2_view"
      :label="$getVisibleNames('mesh.competence', false, 'Competencia')"
      label-for="sort-by-select"
      label-cols-sm="2"
      label-align-sm="left"
      label-size="sm"
      class="mb-4"
    >
      <v-select
        id="input-selected_competence"
        v-model="selected_competence"
        :options="filteredCompetences"
        :reduce="(competences) => competences.id"
        :placeholder="`${String(
          'Seleccione la ' +
            $getVisibleNames(
              'mesh.competence',
              false,
              'Competencia'
            ).toLowerCase()
        )}`"
        :dropdown-should-open="dropdownShouldOpen"
        label="full_sentence"
        track-by="id"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No se encontraron resultados para: "<em>{{ search }}</em
            >"
          </template>
        </template>
      </v-select>
    </b-form-group>
    <div v-if="selected_competence || matrix2_view">
      <GenericBTable
        :items="filteredRaBases"
        :fields="fields"
        :filter="input_search"
        :filter-included-fields="filterOn"
        :filterCustom="filterCustom"
        :show_pagination="true"
        :search_filter="true"
        :selection_mode="true"
        :columns_display="false"
        :pagination="10"
        :per_page="10"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @emitChangeSearchField="changeInputSearch"
      >
        <template #head(selected)>
          <b-form-checkbox
            v-model="select_all"
            @change="selectAllRaBase()"
          ></b-form-checkbox>
        </template>
        <template #cell(selected)="row">
          <b-checkbox
            v-model="row.item.selected"
            @change="selectRaBase(row.item.id, $event)"
          ></b-checkbox>
        </template>
        <template #cell(sentence)="row">
          <div class="d-flex">
            <div>
              <b-badge
                v-if="row.item.competence_order && row.item.cycle_order"
                :id="`base-ra-${row.item.id}`"
              >
                {{ row.item.competence_order }}.{{ row.item.cycle_order }}.{{
                  row.item.order
                }}
              </b-badge>
            </div>
            <SentenceContainer :Sentence="row.item"> </SentenceContainer>
          </div>
        </template>
      </GenericBTable>
      <div class="row">
        <div class="col" style="text-align: right">
          <b-button class="mr-1" size="sm" @click="saveRaBase()"
            >Guardar</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "SelectedRaBase",
  components: {
    vSelect,
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    competence_id: {
      default: null,
    },
    egress_profile_id: {
      default: null,
    },
    egress_profile_matter: {
      default: null,
    },
    matter_id: {
      default: null,
    },
    matrix2_view: {
      type: Boolean,
      default: false,
    },
    competences_ids: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      ra_bases_list: [],
      selected: [],
      filterOn: [],
      input_search: "",
      allows_crud: false,
      sortBy: "",
      sortDesc: false,
      select_all: false,
      selected_competence: null,
    };
  },
  computed: {
    ...mapGetters({
      ra_bases: names.RA_BASES,
      profile_ra_base: names.EGR_PROFILE_RA_BASES,
      competences: names.COMPETENCES,
      studyUnits: names.STUDY_UNITS,
      profileCycles: names.PROFILE_CYCLES,
      profile_competences: names.PROFILE_COMPETENCES,
    }),
    studyUnitsIds() {
      if (!this.matter_id) return [];
      return [
        ...new Set(
          this.studyUnits
            .filter((x) => x.matter == this.matter_id)
            .map((x) => x.id)
        ),
      ];
    },
    maxOrdenStudyUnit() {
      const study_units = this.studyUnits
        .filter((a) => a.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.order < b.order) return 1;
          if (a.order > b.order) return -1;
          return 0;
        });
      return study_units[0] ? study_units[0].order : 0;
    },
    profileRaBaseIds() {
      if (!this.egress_profile_id) return [];
      if (
        this.egress_profile_matter &&
        this.egress_profile_matter.is_integrative
      )
        return [
          ...new Set(
            this.profile_ra_base
              .filter(
                (x) =>
                  x.egress_profile == this.egress_profile_id &&
                  this.studyUnitsIds.includes(x.study_unit)
              )
              .map((x) => x.base_ra)
          ),
        ];
      else
        return [
          ...new Set(
            this.profile_ra_base
              .filter((x) => x.egress_profile == this.egress_profile_id)
              .map((x) => x.base_ra)
          ),
        ];
    },
    filteredRaBases() {
      if (!this.selected_competence && !this.matrix2_view) return [];
      if (this.matrix2_view) {
        return this.ra_bases_list.filter(
          (x) =>
            x.competences.some((a) => this.competences_ids.includes(a)) &&
            !this.profileRaBaseIds.includes(x.id)
        );
      } else
        return this.ra_bases_list.filter(
          (x) =>
            x.competences.includes(this.selected_competence) &&
            !x.competences.includes(this.competence_id)
        );
    },
    filteredCompetences() {
      if (this.matrix2_view) return this.competences;
      else return this.competences.filter((x) => x.id != this.competence_id);
    },
    fields() {
      let list = [
        {
          key: "selected",
          label: "Selecionar",
          display_column: true,
          thStyle: { width: "1%" },
        },
        {
          key: "sentence",
          label: "Redacción",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
      ];
      return list;
    },
  },
  methods: {
    filterCustom(row) {
      if (this.$filtered(row.full_sentence, this.input_search)) {
        return true;
      } else {
        return false;
      }
    },
    selectRaBase(ra_base_id) {
      const index = this.selected.findIndex((x) => x == ra_base_id);
      if (index != -1) this.selected.splice(index, 1);
      else this.selected.push(ra_base_id);
    },
    selectAllRaBase() {
      let list = [];
      if (!this.select_all) {
        this.filteredRaBases.forEach((x) => {
          x.selected = false;
        });
        this.selected = [];
      } else {
        this.filteredRaBases.forEach((x) => {
          list.push(x.id);
          x.selected = true;
        });
        this.selected = [...new Set(list)];
      }
    },
    saveRaBase() {
      if (this.selected.length > 0) {
        if (!this.matrix2_view)
          this.selected.forEach((ra_base_id) => {
            const ra = this.ra_bases.find((x) => x.id == ra_base_id);
            if (ra && !isNaN(this.competence_id)) {
              let competences = ra.competences;
              competences.push(this.competence_id);
              let payload = {
                ra_base_id: ra.id,
                item: {
                  competences: competences,
                },
              };
              this.$store.dispatch(names.PATCH_RA_BASE, payload);
            }
          });
        else if (this.matrix2_view) {
          this.saveAsyncStudyUnit();
        }
      }
      this.$emit("closeModal");
    },
    async saveAsyncStudyUnit() {
      for await (let ra_id of this.selected) {
        const ra_base = this.ra_bases.find((x) => x.id == ra_id);
        if (ra_base && this.egress_profile_id && this.matter_id) {
          const study_unit = {
            verb: ra_base.verb,
            action: ra_base.action,
            content: ra_base.content,
            condition: ra_base.condition,
            full_sentence: ra_base.full_sentence,
            order: this.maxOrdenStudyUnit + 1,
            matter: this.matter_id,
            methodological_strategies: [],
            study_environments: [],
          };
          await this.$store
            .dispatch(names.POST_STUDY_UNIT, study_unit)
            .then((response) => {
              const payload = {
                egress_profile: this.egress_profile_id,
                base_ra: ra_id,
                study_unit: response.id,
              };
              this.$store.dispatch(names.POST_EGR_PROFILE_RA_BASE, payload);
            });
        }
      }
    },
    changeInputSearch(input_search) {
      if (input_search) this.input_search = input_search;
    },
    dropdownShouldOpen(VueSelect) {
      if (this.competences.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
  },
  watch: {
    selected_competence() {
      if (this.selected_competence != null) {
        this.$store
          .dispatch(names.FETCH_RA_BASES, {
            competence_id: this.selected_competence,
          })
          .then((response) => {
            response.forEach((element) => {
              let object = { ...element };
              object.selected = false;
              this.ra_bases_list.push(object);
            });
          });
      }
    },
    selected() {
      if (
        this.selected.length == this.filteredRaBases.length &&
        this.filteredRaBases.length > 0
      ) {
        this.select_all = true;
      } else if (
        this.selected.length != this.filteredRaBases.length &&
        this.filteredRaBases.length > 0
      )
        this.select_all = false;
    },
  },
  mounted() {
    this.select_all = false;
    this.selected = [];
    if (this.matrix2_view && this.egress_profile_id != null) {
      this.ra_bases.forEach((element) => {
        let object = { ...element };
        let profile_competence = this.profile_competences.find(
          (x) =>
            x.egress_profile == this.egress_profile_id &&
            element.competences.includes(x.competence)
        );
        if (profile_competence) {
          let profile_cycle = this.profileCycles.find(
            (x) =>
              x.egress_profile == this.egress_profile_id &&
              x.cycle == profile_competence.cycle
          );
          object.competence_order = profile_competence.order;
          if (profile_cycle) object.cycle_order = profile_cycle.order;
        }
        object.selected = false;
        this.ra_bases_list.push(object);
      });
      this.$store.dispatch(names.FETCH_EGR_PROFILE_RA_BASES, {
        egress_profile_id: this.egress_profile_id,
      });
    }
  },
  created() {},
};
</script>

<style scoped>
@media print {
  .badge {
    border: none;
  }
}
</style>