
export const APP_NAME = "KEY LEARNING";
export const APP_LOGO = "@/assets/logo_klearning.png";

// // PROD_URL_KL apunta al servidor de produccion en AWS 2.
// const PROD_URL_KL = "https://backend.klearning.cl/api/v1.0";
// // STAGE_URL_KL apunta al servidor de desarrollo y pruebas en AWS 2.
// // Como todo ahí es local, esta configuración es similar a la de desarrollo.
// const STAGE_URL_KL = "https://devback.klearning.cl/api/v1.0";
// // DEV_URL_KL es para desarrollo local.
// const DEV_URL_KL = "http://localhost:9000/api/v1.0";

// let url = DEV_URL_KL;
// // Get the current host name
// const hostname = window.location.hostname;
// // Check the host name against known URLs
// if (hostname === 'localhost') {
//     url = DEV_URL_KL;
// } else if (hostname === 'dev.klearning.cl') {
//     url = STAGE_URL_KL;
// } else if (hostname === 'klearning.cl') {
//     url = PROD_URL_KL;
// }

export const URL = 'https://demoback.klearning.cl/api/v1.0';
