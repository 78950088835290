import { render, staticRenderFns } from "./MatterTimeAllocationComponent.vue?vue&type=template&id=f43dc016&scoped=true&"
import script from "./MatterTimeAllocationComponent.vue?vue&type=script&lang=js&"
export * from "./MatterTimeAllocationComponent.vue?vue&type=script&lang=js&"
import style0 from "./MatterTimeAllocationComponent.vue?vue&type=style&index=0&id=f43dc016&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f43dc016",
  null
  
)

export default component.exports