<template>
  <div class="d-flex">
    <div class="w-100">
      <b-form-input
        v-model="matter_time_allocation.hours"
        @update="
          (value) => {
            if (parseFloat(value) < 0 || value == null || value == '') {
              matter_time_allocation.hours = 0;
              patchMatterTimeAllocation();
            } else {
              matter_time_allocation.hours = value;
              patchMatterTimeAllocation();
            }
          }
        "
        size="sm"
      >
      </b-form-input>
    </div>
    <b-form-checkbox
      class="mt-2 ml-2"
      size="lg"
      v-model="matter_time_allocation.counts_towards_credits"
      @input="patchMatterTimeAllocation()"
    ></b-form-checkbox>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { generateUniqueId, toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "InputMTimeAllocation",
  components: {},
  props: {
    MatterTimeAllocation: {
      default: function () {
        return {
          id: generateUniqueId(),
          matter: null,
          time_allocation: null,
          counts_towards_credits: false,
          attendance_requirement: 0,
          hours: 0,
        };
      },
    },
    TimeAllocation: {
      type: Object,
      required: true,
    },
    OldMatter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      matter_time_allocation: { ...this.MatterTimeAllocation },
    };
  },
  computed: {
    ...mapGetters({
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
    }),
  },
  methods: {
    patchMatterTimeAllocation() {
      if (this.timeout) clearTimeout(this.timeout);
      if (
        parseFloat(this.matter_time_allocation.hours) < 0 ||
        this.matter_time_allocation.hours == null ||
        this.matter_time_allocation.hours == ""
      )
        return;
      else if (this.matter_time_allocation.hours >= 0) {
        this.timeout = setTimeout(() => {
          this.matter_time_allocation.matter = this.OldMatter.id;
          this.matter_time_allocation.time_allocation = this.TimeAllocation.id;
          if (isNaN(this.matter_time_allocation.id))
            this.createMatterTimeAllocation();
          else this.updateMatterTimeAllocation();
        }, 500);
      }
    },
    createMatterTimeAllocation() {
      this.matter_time_allocation.counts_towards_credits =
        this.TimeAllocation.counts_towards_credits;
      this.matter_time_allocation.attendance_requirement =
        this.TimeAllocation.attendance_requirement;
      this.$store
        .dispatch(
          names.POST_MATTER_TIME_ALLOCATION,
          this.matter_time_allocation
        )
        .then((response) => {
          this.matter_time_allocation = response;
          toast("Cambio realizado");
          this.$emit("changed_hour");
        });
    },
    updateMatterTimeAllocation() {
      this.$store
        .dispatch(
          names.UPDATE_MATTER_TIME_ALLOCATION,
          this.matter_time_allocation
        )
        .then((response) => {
          this.matter_time_allocation = response;
          toast("Cambio realizado");
          this.$emit("changed_hour");
        });
    },
  },
  mounted() {},
  created() {
    const matter_time_allocation = this.matter_time_allocations.find(
      (x) =>
        x.time_allocation == this.TimeAllocation.id &&
        x.matter == this.OldMatter.id
    );
    if (matter_time_allocation)
      this.matter_time_allocation = matter_time_allocation;
  },
};
</script>

<style scoped>
input {
  padding: 1px 1px 1px 5px;
  height: 30px;
  max-width: 50px;
  font-size: var(--secondary-font-size);
}
.inputNumber {
  width: 70px;
  justify-content: center;
}
</style>