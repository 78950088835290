<template>
  <div>
    <div class="suitcase-header mt-4" v-if="suitcase_report">
      <div
        class="left-header-text"
        :style="{
          'background-color': suitcase_report.faculty_color + '!important',
          color: $lightOrDark(suitcase_report.faculty_color)
            ? 'black'
            : 'white',
        }"
      >
        <h1
          class="font-weight-bold text-center"
          :style="{ 'background-color': suitcase_report.faculty_color }"
        >
          PIA
        </h1>
      </div>
      <h4 class="suitcase-faculty">{{ suitcase_report.faculty_name }}</h4>
      <div class="logo-container">
        <h4 class="suitcase-title mr-2">
          MALETA <span class="suitcase-title-2">DIDÁCTICA</span>
        </h4>
        <div class="logo-section ml-3">
          <b-img class="institution-logo" :src="institution_logo"></b-img>
        </div>
      </div>
    </div>
    <!-- Encabezado principal con información de asignatura -->
    <div class="synthesis-header-container">
      <div
        class="empty-block"
        :style="{
          'background-color': suitcase_report
            ? suitcase_report.faculty_color + '!important'
            : '',
        }"
      ></div>
      <h5 class="synthesis-header mb-0 py-2 text-white">
        <strong>PROGRAMA DE IMPLEMENTACIÓN DE ASIGNATURA</strong>
      </h5>
    </div>
    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th
            class="second-header"
            :style="{
              'background-color': suitcase_report.faculty_color + '!important',
              color: $lightOrDark(suitcase_report.faculty_color)
                ? 'black'
                : 'white',
            }"
            >{{ $getVisibleNames("manual.matter_code", false, "Código") }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': suitcase_report.faculty_color + '!important',
              color: $lightOrDark(suitcase_report.faculty_color)
                ? 'black'
                : 'white',
            }"
            >Nombre</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': suitcase_report.faculty_color + '!important',
              color: $lightOrDark(suitcase_report.faculty_color)
                ? 'black'
                : 'white',
            }"
            >{{
              $getVisibleNames("manual.credits", true, "Créditos SCT Totales")
            }}</b-th
          >
          <!-- <b-th
            class="second-header"
            :style="{
              'background-color': suitcase_report.faculty_color + '!important',
              color: $lightOrDark(suitcase_report.faculty_color)
                ? 'black'
                : 'white',
            }"
            >{{
              $getVisibleNames(
                "manual.institutional_credits",
                true,
                "Créditos Institucionales"
              )
            }}</b-th
          > -->
          <b-th
            class="second-header"
            :style="{
              'background-color': suitcase_report.faculty_color + '!important',
              color: $lightOrDark(suitcase_report.faculty_color)
                ? 'black'
                : 'white',
            }"
            >{{
              $getVisibleNames(
                "mesh.modality",
                false,
                "Modalidad de Asignaturas"
              )
            }}</b-th
          >
        </b-tr>
      </b-thead>
      <b-tbody
        :style="{
          'background-color': suitcase_report.faculty_color + '!important',
          color: $lightOrDark(suitcase_report.faculty_color)
            ? 'black'
            : 'white',
        }"
      >
        <b-tr>
          <b-td>{{ suitcase_report.matter_code }}</b-td>
          <b-td>{{ suitcase_report.matter_name }}</b-td>
          <b-td>{{ suitcase_report.sct_credits }}</b-td>
          <!-- <b-td>{{
            suitcase_report.duoc_credits ? suitcase_report.duoc_credits : "-"
          }}</b-td> -->
          <b-td>
            <template v-if="suitcase_report.modalities.length > 0">
              <b-badge
                v-for="modality in suitcase_report.modalities"
                :key="modality.id"
                :variant="
                  $lightOrDark(suitcase_report.faculty_color) ? 'dark' : 'light'
                "
                class="mr-1"
              >
                {{ modality.name }}
              </b-badge>
            </template>
            <template v-else>
              <div>N/A</div>
            </template></b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- *********************** SECCIÓN DE ANTECEDENTES GENERALES ***********************-->

    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th colspan="9" class="first-header"
            ><span class="general-background-icon mr-2">
              <b-icon icon="list-check"></b-icon>
            </span>
            1. ANTECEDENTES GENERALES
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="second-header">Horas totales</b-th>
          <b-td>
            <template v-if="suitcase_report.total_hours">
              <div>
                {{ suitcase_report.total_hours }}
              </div>
            </template>
            <template v-else>
              <div>-</div>
            </template></b-td
          >
          <b-th class="second-header">Presenciales</b-th>
          <b-th class="second-header">Horas</b-th>
          <b-th class="second-header">N° Estudiantes</b-th>
          <b-th class="second-header">No Presenciales</b-th>
          <b-th class="second-header">Horas</b-th>
          <b-th class="second-header">N° Estudiantes</b-th>
          <b-th class="second-header"
            >Total horas de trabajo Autónomo (Cronológicas)</b-th
          >
        </b-tr>
        <b-tr>
          <b-th colspan="2" class="second-header">Ambiente/Horas</b-th>
          <b-td>
            <template
              v-if="
                suitcase_report.in_person_environments &&
                suitcase_report.in_person_environments.length > 0
              "
            >
              <b-badge
                v-for="environment in suitcase_report.in_person_environments"
                :key="environment.id"
                class="mr-1"
              >
                {{ environment.name }}
              </b-badge>
            </template>
            <template v-else>
              <div>N/A</div>
            </template>
          </b-td>
          <b-td>{{
            suitcase_report.in_person_hours
              ? suitcase_report.in_person_hours
              : "-"
          }}</b-td>
          <b-td>{{
            suitcase_report.in_person_student_amount
              ? suitcase_report.in_person_student_amount
              : "-"
          }}</b-td>
          <b-td>
            <template
              v-if="
                suitcase_report.online_environments &&
                suitcase_report.online_environments.length > 0
              "
            >
              <b-badge
                v-for="environment in suitcase_report.online_environments"
                :key="environment.id"
                class="mr-1"
              >
                {{ environment.name }}
              </b-badge>
            </template>
            <template v-else>
              <div>N/A</div>
            </template>
          </b-td>
          <b-td>
            {{
              suitcase_report.online_hours ? suitcase_report.online_hours : "-"
            }}
          </b-td>
          <b-td>
            {{
              suitcase_report.online_student_amount
                ? suitcase_report.online_student_amount
                : "-"
            }}
          </b-td>
          <b-td> {{ suitcase_report.autonomous_work_hours }} </b-td>
        </b-tr>
        <b-tr>
          <b-th colspan="3" class="second-header">N° Horas Ayudantía</b-th>
          <b-td colspan="2">{{ suitcase_report.assistantship_hours }}</b-td>
          <b-th colspan="2" class="second-header">Prerrequisito</b-th>
          <b-td colspan="2"
            ><b-badge
              class="pre-requisites-tag"
              v-for="prerequisite in suitcase_report.prerequisites"
              :key="prerequisite.id"
              :style="{
                'background-color': prerequisite
                  ? prerequisite.color
                  : default_bg_color,
                color:
                  (prerequisite && $lightOrDark(prerequisite.color)) ||
                  !prerequisite
                    ? default_color
                    : default_bg_color,
              }"
            >
              {{ prerequisite.matter__name }}
            </b-badge></b-td
          >
        </b-tr>
      </b-thead>
    </b-table-simple>

    <!-- *********************** SECCIÓN OTRAS INDICACIONES ***********************-->
    <template v-if="suitcase_report">
      <div class="suitcase-program-indications">
        <div class="second-header pt-3 pb-3 pl-3 text-left">
          <strong> Otras indicaciones para la programación </strong>
        </div>
        <template v-if="suitcase_report.program_indications">
          <div
            class="descriptor-body pt-2 pb-3 rich-text-content"
            v-html="suitcase_report.program_indications"
          ></div>
        </template>
        <template v-else>
          <div class="program-indications-body">No hay Orientaciones.</div>
        </template>
      </div>
      <template>
        <div class="suitcase-matter-description mt-2">
          <div class="matter-description-header text-left">
            <span class="general-background-icon mr-2">
              <b-icon icon="gear"></b-icon>
            </span>
            2. DESCRIPCIÓN DE LA ASIGNATURA
          </div>
          <div class="matter-description-body">
            <template v-if="suitcase_report.matter_description">
              <div
                class="rich-text-content"
                v-html="suitcase_report.matter_description"
              ></div>
            </template>
            <template v-else>
              <div>No hay descripción para la asignatura.</div>
            </template>
          </div>
        </div>
      </template>
    </template>

    <!-- *********************** SECCIÓN TABLA DE COMPETENCIAS ***********************-->

    <b-table-simple
      v-if="suitcase_report && suitcase_report.competence_dict_list.length > 0"
      class="mt-2 mb-2 page-break"
      bordered
      responsive
    >
      <template v-if="suitcase_report.competence_dict_list.length > 0">
        <template
          v-for="competence_dict_list in suitcase_report.competence_dict_list"
        >
          <template
            v-for="(competence, index) in competence_dict_list.competences"
          >
            <b-tr
              :key="
                competence_dict_list.egress_profile_name + '-' + competence.id
              "
            >
              <b-th
                :rowspan="competence_dict_list.competences.length"
                v-if="index == 0"
                class="suitcase-competences-th"
              >
                {{ $getVisibleNames("mesh.competence", true, "Competencias") }}
                <template
                  v-if="suitcase_report.competence_dict_list.length > 1"
                >
                  de "{{ competence_dict_list.egress_profile_name }}"
                </template>
              </b-th>
              <b-td class="suitcase-competences-td">
                <!-- Falta Orden de la competencia del perfil... -->
                <span v-if="competence.order">{{
                  "C" + competence.order + "."
                }}</span>
                {{ competence.full_sentence }}</b-td
              >
            </b-tr>
          </template>
        </template>
      </template>
    </b-table-simple>

    <!-- *********************** SECCIÓN TABLA DE RESULTADOS DE APRENDIZAJE ***********************-->

    <b-table-simple
      v-if="suitcase_report && suitcase_report.learning_results.length"
      bordered
      responsive
      class="mt-1 page-break"
    >
      <template
        v-for="(learning_result, index) in suitcase_report.learning_results"
      >
        <b-tr :key="learning_result.id">
          <b-th
            :rowspan="suitcase_report.learning_results.length"
            v-if="index == 0"
            class="suitcase-learning-results-th"
          >
            Resultados de Aprendizaje</b-th
          >
          <b-td class="suitcase-learning-results-td">
            <!-- Falta Orden del resultado de aprendizaje del perfil... -->
            <span v-if="learning_result.order">{{
              "RA" + learning_result.order + "."
            }}</span>
            {{ learning_result.full_sentence }}</b-td
          >
        </b-tr>
      </template>
    </b-table-simple>

    <!-- *********************** SÍNTESIS RUTA DE APRENDIZAJE ***********************-->

    <b-table-simple
      v-if="suitcase_report"
      bordered
      responsive
      class="page-break"
    >
      <b-thead>
        <b-tr>
          <b-th class="learning-results-synthesis-th text-left" colspan="5">
            <span class="general-background-icon mr-2">
              <b-icon icon="clipboard-data"></b-icon>
            </span>
            3. SÍNTESIS DE LA RUTA DE APRENDIZAJE
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="fifth-header align-middle"
            >EXPERIENCIA DE APRENDIZAJE</b-th
          >
          <b-th class="fifth-header align-middle"
            >RESULTADOS DE APRENDIZAJE</b-th
          >
          <b-th class="fifth-header align-middle">HORAS</b-th>
          <b-th class="fifth-header align-middle">AMBIENTE DE APRENDIZAJE</b-th>
          <b-th class="fifth-header align-middle">TIPO DE EVALUACIÓN</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-if="
            suitcase_report.learning_experiences.length == 0 &&
            suitcase_report.transversal_exams.length == 0
          "
        >
          <b-tr>
            <b-td :colspan="5">No hay datos para mostrar.</b-td>
          </b-tr>
        </template>
        <template
          v-for="learning_result in suitcase_report.learning_experiences"
        >
          <b-tr :key="learning_result.id">
            <b-td class="text-left"
              ><span v-if="learning_result.order">{{
                "EA " + learning_result.order + " - "
              }}</span
              >{{ learning_result.name }}</b-td
            >
            <b-td>
              <template v-if="learning_result.study_units.length > 0">
                <b-badge
                  v-for="study_unit in learning_result.study_units"
                  :key="study_unit.id"
                  variant="info"
                  class="mr-1"
                  v-b-tooltip.v-secondary.noninteractive.bottom="
                    study_unit.full_sentence
                  "
                >
                  RA{{ study_unit.order }}
                </b-badge>
              </template>
              <template v-else>
                <div>N/A</div>
              </template>
            </b-td>
            <b-td>
              {{ learning_result.le_epm_evaluations_hours }}
            </b-td>
            <b-td>
              <template
                v-if="learning_result.le_study_environments_list.length > 0"
              >
                <b-badge
                  v-for="(
                    environment, index
                  ) in learning_result.le_study_environments_list"
                  :key="'le_study_environments_list:' + index"
                  variant="info"
                  class="mr-1"
                >
                  {{ environment }}
                </b-badge>
              </template>
              <template v-else>
                <div>N/A</div>
              </template>
            </b-td>
            <b-td>
              <template
                v-if="learning_result.le_evaluations_name_list.length > 0"
              >
                <div
                  v-for="(
                    evaluation, index
                  ) in learning_result.le_evaluations_name_list"
                  :key="'le_evaluations_name_list:' + index"
                >
                  {{ evaluation }}
                </div>
              </template>
              <template v-else>
                <div>N/A</div>
              </template>
            </b-td>
          </b-tr>
        </template>
        <template
          v-if="
            suitcase_report.transversal_exams &&
            suitcase_report.transversal_exams.length > 0
          "
        >
          <template
            v-for="transversal_exam in suitcase_report.transversal_exams"
          >
            <b-tr :key="transversal_exam.id">
              <b-td colspan="2">
                Examen
                {{ transversal_exam.type_name }}
              </b-td>
              <b-td>{{ transversal_exam.suggested_hours }}</b-td>
              <b-td
                ><template
                  v-if="transversal_exam.study_environments.length > 0"
                >
                  <b-badge
                    v-for="environment in transversal_exam.study_environments"
                    :key="environment.id"
                    variant="info"
                    class="mr-1"
                  >
                    {{ environment.name }}
                  </b-badge>
                </template></b-td
              >
              <b-td>{{ transversal_exam.name }}</b-td>
            </b-tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
    <!-- *********************** SECCIÓN PERFILES DE DOCENTES ***********************-->
    <b-table-simple
      v-if="suitcase_report && suitcase_report.teaching_profiles.length"
      bordered
      responsive
      class="page-break"
    >
      <b-thead>
        <b-th class="first-header text-left" colspan="3">
          <span class="general-background-icon mr-2">
            <b-icon icon="person"></b-icon>
          </span>
          4. DOCENTE(S)
        </b-th>
        <b-th class="third-header"
          >Número de Docentes:
          {{ suitcase_report.teaching_profiles.length }}</b-th
        >
        <b-th
          class="fourth-header"
          v-b-tooltip.v-secondary.noninteractive.bottom="
            'Prerrequisitos de la Asignatura'
          "
          ><template v-if="suitcase_report.prerequisites">
            <b-badge
              class="pre-requisites-tag"
              v-for="prerequisite in suitcase_report.prerequisites"
              :key="prerequisite.id"
              variant="light"
            >
              {{ prerequisite.matter__name }}
            </b-badge>
          </template>
          <template v-else>
            <div>Sin Prerrequisitos</div>
          </template></b-th
        >
      </b-thead>
      <b-tbody>
        <template
          v-for="(teaching_profile, index) in suitcase_report.teaching_profiles"
        >
          <b-tr :key="teaching_profile.id + 'first'">
            <b-th class="teaching-profile-header" rowspan="3">
              Perfil Docente {{ index + 1 }}
            </b-th>
            <b-th class="teaching-profile-header">Horas totales</b-th>
            <b-td>{{
              teaching_profile.total_hours ? teaching_profile.total_hours : "-"
            }}</b-td>
            <b-th class="teaching-profile-header"
              >Competencias de especialización y/o certificaciones</b-th
            >
            <b-td v-html="teaching_profile.specialization_competences"></b-td>
          </b-tr>
          <b-tr :key="teaching_profile.id + 'second'">
            <b-th class="teaching-profile-header"
              >Años de experiencia laboral en el área</b-th
            >
            <b-td>{{
              teaching_profile.experience_years
                ? teaching_profile.experience_years
                : "-"
            }}</b-td>
            <b-th class="teaching-profile-header">
              Habilitaciones docente requeridas
            </b-th>
            <b-td>
              <template v-if="teaching_profile.teacher_habilitations">
                <div
                  class="rich-text-content"
                  v-html="teaching_profile.teacher_habilitations"
                ></div>
              </template>
              <template v-else>
                <div>N/A</div>
              </template>
            </b-td>
          </b-tr>
          <b-tr :key="teaching_profile.id + 'third'">
            <b-th class="teaching-profile-header"
              >Experiencia laboral y/o profesión</b-th
            >
            <b-td>
              <template v-if="teaching_profile.work_experience">
                <div
                  class="rich-text-content"
                  v-html="teaching_profile.work_experience"
                ></div>
              </template>
              <template v-else>
                <div>N/A</div>
              </template>
            </b-td>
            <b-th class="teaching-profile-header">Observaciones</b-th>
            <b-td>
              <template v-if="teaching_profile.observations">
                <div v-html="teaching_profile.observations"></div>
              </template>
              <template v-else>
                <div>N/A</div>
              </template>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>

    <!-- *********************** SECCIÓN AYUDANTES ***********************-->

    <template v-if="suitcase_report">
      <div class="assistant-header text-left">
        <span class="general-background-icon mr-2">
          <b-icon icon="person"></b-icon>
        </span>
        5. AYUDANTE
      </div>
      <div class="assistant-container">
        <div class="w-50">
          <div class="assistant-drop-header">Perfil Ayudante</div>
          <template v-if="suitcase_report.assistant_profile">
            <div
              class="assistant-content"
              v-html="suitcase_report.assistant_profile"
            ></div>
          </template>
          <template v-else>
            <div class="assistant-content">N/A</div>
          </template>
        </div>
        <div class="w-50">
          <div class="assistant-drop-header">Rol del Ayudante</div>
          <template v-if="suitcase_report.assistant_role">
            <div
              class="assistant-content"
              v-html="suitcase_report.assistant_role"
            ></div>
          </template>
          <template v-else>
            <div class="assistant-content">N/A</div>
          </template>
        </div>
      </div>
    </template>

    <!-- *********************** SECCIÓN RECURSOS BIBLIOGRÁFICOS ***********************-->

    <b-table-simple
      v-if="suitcase_report"
      bordered
      responsive
      class="mt-2 page-break"
    >
      <b-thead>
        <b-th class="first-header text-left" colspan="4">
          <span class="general-background-icon mr-2">
            <b-icon icon="gear-wide-connected"></b-icon>
          </span>
          6. RECURSOS PARA LA IMPLEMENTACIÓN
        </b-th>
      </b-thead>
      <b-tbody>
        <template
          v-if="
            suitcase_report.bibliographic_resources.filter((x) =>
              $equals(x.type__name, 'Obligatoria')
            ).length > 0
          "
        >
          <template
            v-for="(
              biblio, index
            ) in suitcase_report.bibliographic_resources.filter((x) =>
              $equals(x.type__name, 'Obligatoria')
            )"
          >
            <b-tr :key="biblio.id">
              <b-th
                v-if="index == 0"
                class="biblio-header"
                :rowspan="suitcase_report.bibliographic_resources.length"
                >Bibliografía Obligatoria</b-th
              >
              <b-td>
                <template v-if="biblio.description">
                  <div
                    class="rich-text-content text-justify bibliography-content"
                    v-html="biblio.description"
                  ></div>
                </template>
                <template v-else>
                  <div>N/A</div>
                </template>
                <!-- {{ biblio.type__name }}, {{ biblio.description }} -->
              </b-td>
              <b-th
                v-if="index == 0"
                class="biblio-header"
                :rowspan="suitcase_report.bibliographic_resources.length"
                >Recursos por Ambiente de Aprendizaje (referencia Ficha
                FER)</b-th
              >
              <b-td
                v-if="index == 0"
                :rowspan="suitcase_report.bibliographic_resources.length"
                class="text-center align-middle"
              >
                <div v-if="oldMatter">
                  <div
                    class="rich-text-content"
                    v-html="oldMatter.fer_record"
                  ></div>
                  <!-- <div v-if="allows_crud">
                  <button-edit
                    tabindex="-1"
                    class="w-30"
                    @click="
                      $bvModal.show(`edit-fer_record-modal-${oldMatter.id}`)
                    "
                    v-b-tooltip.v-secondary.noninteractive="
                      'Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)'
                    "
                  >
                  </button-edit>
                  <b-modal
                    :id="`edit-fer_record-modal-${oldMatter.id}`"
                    title="Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)"
                    size="lg"
                    hide-footer
                  >
                    <RichTextEditor
                      :Object="oldMatter"
                      :Text="oldMatter.fer_record"
                      @update="updateFerRecord"
                      @close="
                        $bvModal.hide(`edit-fer_record-modal-${oldMatter.id}`)
                      "
                    ></RichTextEditor>
                  </b-modal>
                </div> -->
                </div>
              </b-td>
            </b-tr>
          </template>
        </template>
        <template v-else>
          <b-tr>
            <b-th class="biblio-header">Bibliografía Obligatoria</b-th>
            <b-td>
              <div></div>
            </b-td>
            <b-th class="biblio-header"
              >Recursos por Ambiente de Aprendizaje (referencia Ficha FER)</b-th
            >
            <b-td class="text-center align-middle">
              <div v-if="oldMatter">
                <div
                  class="rich-text-content"
                  v-html="oldMatter.fer_record"
                ></div>
                <!-- <div v-if="allows_crud">
                  <button-edit
                    tabindex="-1"
                    class="w-30"
                    @click="
                      $bvModal.show(`edit-fer_record-modal-${oldMatter.id}`)
                    "
                    v-b-tooltip.v-secondary.noninteractive="
                      'Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)'
                    "
                  >
                  </button-edit>
                  <b-modal
                    :id="`edit-fer_record-modal-${oldMatter.id}`"
                    title="Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)"
                    size="lg"
                    hide-footer
                  >
                    <RichTextEditor
                      :Object="oldMatter"
                      :Text="oldMatter.fer_record"
                      @update="updateFerRecord"
                      @close="
                        $bvModal.hide(`edit-fer_record-modal-${oldMatter.id}`)
                      "
                    ></RichTextEditor>
                  </b-modal>
                </div> -->
              </div>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <EvaluationCustomFab
      class="noprint"
      :matter_id="egress_profile_matter_id"
      :show_matter_program="true"
    ></EvaluationCustomFab>
  </div>
</template>
<script>
// import { toast } from "@/utils/utils";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "LearningSuitcaseView",
  components: {
    EvaluationCustomFab: () =>
      import("@/components/evaluations/EvaluationCustomFab"),
    // RichTextEditor: () => import("@/components/reusable/RichTextEditor"),
  },
  data() {
    return {
      egress_profile_matter_id: Number(
        this.$route.params.egress_profile_matter_id
      ),
      // egress_profile_linkage: null,
      suitcase_report: null,
      default_color: "var(--primary-font-color)",
      default_bg_color: "#f8f9fa",
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      institution_logo: "getInstitutionLogo",
      user: "getUser",
      user_position: names.USER_POSITION,
      images: names.IMAGES,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
    }),
    egressProfileMatter() {
      if (!this.egress_profile_matter_id) return null;
      return this.egressProfileMatters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    oldMatter() {
      if (!this.egressProfileMatter) return null;
      return this.matters.find((x) => x.id == this.egressProfileMatter.matter);
    },
    egressProfile() {
      if (!this.egressProfileMatter) return null;
      return this.egressProfiles.find(
        (x) => x.id == this.egressProfileMatter.egress_profile
      );
    },
    // egressProfileLinked() {
    //   if (!this.egress_profile_linkage) return null;
    //   return this.egressProfiles.find(
    //     (x) => x.id == this.egress_profile_linkage.linked_egress_profile
    //   );
    // },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    // allows_crud() {
    //   if (
    //     this.career &&
    //     this.egressProfile &&
    //     !this.egressProfile.is_closed &&
    //     this.egressProfileMatter &&
    //     !this.egressProfileMatter.is_closed &&
    //     ((this.user_position &&
    //       [1, 2].includes(this.user_position.position) &&
    //       [1, 2].includes(this.user.groups[0])) ||
    //       this.user.is_superuser ||
    //       this.$hasObjectPermission(
    //         "mesh.faculty",
    //         "change",
    //         this.career.faculty
    //       ) ||
    //       this.$hasObjectPermission(
    //         "mesh.career",
    //         "change",
    //         this.egressProfile.career
    //       ))
    //   ) {
    //     const has_permission = this.$hasPermissions("teaching.change_matter");
    //     if (has_permission) {
    //       this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
    //       return has_permission;
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
    //     return false;
    //   }
    // },
  },
  methods: {
    // updateFerRecord(old_matter, text) {
    //   let payload = {
    //     matter_id: old_matter.id,
    //     item: {
    //       fer_record: text,
    //     },
    //   };
    //   this.$store.dispatch(names.PATCH_MATTER, payload).then(() => {
    //     toast(
    //       "Recursos por Ambiente de Aprendizaje (referencia Ficha FER) actualizado."
    //     );
    //   });
    // },
    fetchSuitcaseReport() {
      if (this.egress_profile_matter_id) {
        this.$restful
          .Get(
            `/teaching/learning_suitcase/?egress_profile_matter=${this.egress_profile_matter_id}&learning_suitcase_option=4`
          )
          .then((response) => {
            this.suitcase_report = response;
          });
      }
    },
  },
  mounted() {
    this.fetchSuitcaseReport();
    if (this.egress_profile_matter_id)
      this.$store
        .dispatch(
          names.FETCH_EGRESS_PROFILE_MATTER,
          this.egress_profile_matter_id
        )
        .then((egress_profile_matter) => {
          this.$store.dispatch(
            names.FETCH_MATTER,
            egress_profile_matter.matter
          );
          // this.$restful
          //   .Get(
          //     `/mesh/egress-profile-linkage/?linker_egress_profile=${egress_profile_matter.egress_profile}`
          //   )
          //   .then((response) => {
          //     if (response[0]) {
          //       this.egress_profile_linkage = response[0];
          //       this.$store.dispatch(
          //         names.FETCH_EGRESS_PROFILE,
          //         response[0].linked_egress_profile
          //       );
          //     }
          //   });
          this.$store
            .dispatch(
              names.FETCH_EGRESS_PROFILE,
              egress_profile_matter.egress_profile
            )
            .then((egress_profile) => {
              this.$store.dispatch(names.FETCH_CAREER, egress_profile.career);
            });
        });
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.suitcase-header {
  display: flex;
  position: relative;
  height: 50px;
}
.suitcase-faculty {
  position: absolute;
  bottom: 0;
  left: 20px;
  margin-left: 15%;
  margin-right: 2em;
}
.suitcase-title {
  max-width: 100px;
  vertical-align: top;
  font-weight: bold;
  letter-spacing: 2.5px !important;
  line-height: 0.9;
}
.suitcase-title > .suitcase-title-2 {
  letter-spacing: -2px !important;
  font-weight: 100 !important;
}
.logo-container {
  display: flex;
  margin-left: auto;
  margin-right: 5em;
}
.logo-section {
  border-left: 1px solid;
  padding-left: 1em;
}
.logo-section > .institution-logo {
  max-height: 100px;
  transform: translateY(-25%);
  z-index: 1;
}
.left-header-text {
  width: 10%;
  margin-left: 1px;
}
.synthesis-header-container {
  display: flex;
  margin-left: 1px;
}
.empty-block {
  width: 5%;
}
.synthesis-header {
  background-color: black !important;
  width: 95%;
  margin-right: 1px;
  z-index: 10;
}
.first-header {
  background-color: #808080 !important;
  color: white;
}
.second-header {
  vertical-align: middle;
  background-color: #d9d9d9 !important;
}
.general-background-icon {
  padding: 0.3em;
  border-radius: 30px;
  border: 1px dotted;
}
.pre-requisites-tag {
  font-size: var(--secondary-font-size);
}
.suitcase-program-indications {
  border: 1px solid #dee2e6;
}
.program-indications-header {
  background: #808080 !important;
  color: white;
  border-bottom: 1px solid #dee2e6;
  font-weight: bold;
  padding: 0.7em;
}
.program-indications-body {
  padding: 1em;
}
.suitcase-matter-description {
  border: 1px solid #dee2e6;
}
.matter-description-header {
  padding: 0.7em;
  background-color: #808080 !important;
  color: white;
  font-weight: bold;
}
.matter-description-body {
  padding: 1em;
}
.suitcase-competences-th {
  vertical-align: middle;
  background-color: #808080 !important;
  color: white;
  min-width: 15em;
}
.suitcase-competences-td {
  text-align: left;
}
.suitcase-learning-results-th {
  vertical-align: middle;
  background-color: #808080 !important;
  color: white;
  min-width: 15em;
}
.suitcase-learning-results-td {
  text-align: left;
}
.learning-results-synthesis-th {
  background-color: #808080 !important;
  color: white;
}
.teaching-profile-header {
  vertical-align: middle;
  background-color: #d9d9d9 !important;
}
.third-header {
  background-color: #525252 !important;
  color: white;
}
.fourth-header {
  background-color: #3c3c3c !important;
}
.fifth-header {
  background-color: #bfbfbf !important;
}
.assistant-header {
  padding: 0.7em;
  background-color: #808080 !important;
  color: white;
  font-weight: bold;
}
.assistant-container {
  display: flex;
  border: 1px solid #dee2e6;
}
.assistant-drop-header {
  background-color: #d9d9d9 !important;
  font-weight: bold;
}
.assistant-content {
  padding: 1em;
}
.biblio-header {
  vertical-align: middle;
  background-color: #d9d9d9 !important;
}
@media print {
  .badge {
    border: none;
  }
  .bibliography-content {
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 600px !important;
  }
}
.page-break {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
.descriptor-body {
  display: block;
  text-align: justify;
  padding: 0.4em;
  padding-inline: 1em;
}
.descriptor-body >>> p {
  margin-bottom: 0 !important;
}
</style>