<template>
  <div class="mt-5">
    <div class="container-login">
      <div class="mx-auto mt-1" style="max-width: 300px">
        <h2>Login</h2>
        <hr />
        <b-form-group
          id="input-group-user"
          label="Usuario"
          label-for="input-user"
        >
          <b-form-input
            id="input-user"
            type="email"
            required
            placeholder="Usuario"
            @keyup.enter="authenticate"
            v-model="$v.user_form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-user-name-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-user-name-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group
          id="input-group-password"
          label="Clave"
          label-for="input-password"
        >
          <b-form-input
            id="input-password"
            v-model="user_form.password"
            type="password"
            required
            placeholder="Clave"
            @keyup.enter="authenticate"
          ></b-form-input>
        </b-form-group>
        <div class="div-href mb-3" @click="launchRecoverPasswordModal()">
          <!-- @click="$bvModal.show('modal-recovery-password')" -->
          ¿Olvidó su contraseña?
        </div>
        <b-button @click="authenticate" variant="primary">Entrar</b-button>
        <hr class="ml-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Login",
  mixins: [validationMixin],
  data() {
    return {
      user_form: {
        email: "",
        password: "",
      },
      recovery_password_email: "",
      user: null,
    };
  },
  validations: {
    user_form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
    recovery_password_email: {
      email,
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.user_form[key];
      return $dirty ? !$error : null;
    },
    validateRecoveryPassword() {
      const { $dirty, $error } = this.$v.recovery_password_email;
      return $dirty ? !$error : null;
    },
    launchRecoverPasswordModal() {
      // #TODO: Tomar el correo si está disponible y rellenar el input.
      // Será mejor crear un formulario para colocarlo dentro del modal.
      this.$swal
        .fire({
          title: "Ingresa tu correo electrónico para buscar tu cuenta.",
          input: "email",
          confirmButtonText: "Enviar",
          inputValue: this.user_form.email,
          reverseButtons: true,
          showLoaderOnConfirm: false,
          showCancelButton: true,
          preConfirm: (login) => {
            this.$swal.fire({
              title: "Cargando...",
              onBeforeOpen: () => {
                this.$swal.showLoading();
              },
            });
            return this.$restful
              .Get(`/authentication/send_reset_code/?email=${login}`)
              .then(() => {
                return { isConfirmed: true };
              })
              .catch((error) => {
                this.$swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
        })
        .then((result) => {
          if (result && result.value && result.value.isConfirmed) {
            this.$swal.fire({
              type: "success",
              title: `Revise su correo electrónico.`,
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
    },
    authenticate() {
      this.$v.user_form.$touch();
      if (this.$v.user_form.$anyError) {
        return;
      }
      this.$store.dispatch("login", {
        email: this.user_form.email,
        password: this.user_form.password,
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.container-login {
  position: relative;
  margin: auto;
  width: 100%;
}
.div-href {
  color: #007bff;
}
.div-href:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
</style>
