<template>
  <div>
    <div class="m-1 mt-2">
      <div class="matterCard">
        <div
          class="matterTitle"
          :style="{
            'background-color': courseWrapperFormationLine,
          }"
          :title="CourseWrapper.name"
        >
          <div class="matterLink">
            <span>{{ CourseWrapper.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "CourseCard",
  components: {},
  props: {
    CourseWrapper: {
      type: Object,
      required: true,
    },
    EgressProfile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { default_bg_color: "#CBDDE1" };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      school: "getInstitution",
      formation_lines: names.FORMATION_LINES,
      careers: names.CAREERS,
    }),
    career() {
      if (!this.EgressProfile) return null;
      return this.careers.find((x) => x.id == this.EgressProfile.career);
    },
    courseWrapperFormationLine() {
      if (!this.CourseWrapper) return this.default_bg_color;
      const formation_line = this.formation_lines.find(
        (x) =>
          x.id == this.CourseWrapper.formation_line &&
          x.faculties.includes(this.career.faculty)
      );
      if (formation_line) return formation_line.color;
      else return this.default_bg_color;
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.matterCard {
  min-width: 160px;
  min-height: 88px;
  box-sizing: border-box;
  display: flex;
  font-size: var(--thirth-font-size);
  color: #2e2e2e;
}

.matterTitle {
  padding: 15px 4px;
  width: 100%;
  font-size: var(--thirth-font-size);
  background-color: #f4f4f4;
  border: 1px solid #7c7c7c;
  margin-bottom: 0;
}
.matterTitle .matterLink {
  background-color: #f1f1f1;
  padding: 0.2rem;
  border-radius: 4px;
  line-height: 1.2;
}
.matterLink {
  min-width: 6rem;
}
.matterLink > a {
  color: #2e2e2e !important;
  text-decoration: underline;
}
.matterLink > a:hover {
  color: #007bff !important;
}

.leftCol {
  width: 30px;
  float: left;
  background: transparent;
  font-size: var(--thirth-font-size);
}

.rightCol {
  width: 40px;
  background: transparent;
  font-size: var(--thirth-font-size);
}

.leftLabel {
  color: #0c0c0c;
  background: transparent;
  height: 20px;
  width: 30px;
  font-size: var(--thirth-font-size);
  transition: 0.2s ease-out;
  border-top: 1px dotted #575757;
  border-bottom: 1px dotted #575757;
  border-left: 1px dotted #575757;
}

.preqLabel {
  color: black;
  border: 1px solid #575757;
  height: auto;
  width: 40px;
  padding-left: 0.1em;
  margin-bottom: 0px;
  font-size: 8pt;
  transition: 0.2s ease-out;
  border-style: solid solid solid none;
  border-bottom: 0 !important;
  background: transparent;
}

.compLabel {
  color: black;
  border: 1px solid #575757;
  height: auto;
  width: 40px;
  margin-bottom: 0px;
  font-size: 8pt;
  transition: 0.2s ease-out;
  border-style: solid solid solid none;
  background: transparent;
}

.competence-container {
  display: flex;
}

.modalities-badge {
  font-size: 0.6rem;
  vertical-align: top !important;
  padding: 0.15rem 0.2rem;
  cursor: default !important;
  margin-left: 0.1rem;
}

@media print {
  .matterTitle .matterLink a {
    text-decoration: none !important;
    line-height: 1.5;
  }
}
</style>