<template>
  <div>
    <h5 class="mb-3" v-if="!egress_profile_id">
      Asociar {{ $getVisibleNames("mesh.competence", false, "Competencia") }} a
      {{ $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso") }}
      <!-- Perfil de Egreso -->
    </h5>
    <h6 class="mb-3" v-else>
      Definir posición de la
      {{ $getVisibleNames("mesh.competence", false, "Competencia") }} con
      respecto al
      {{ $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso") }}
      .
      <!-- Perfil de Egreso -->
    </h6>
    <!-- <div class="search-competence" v-if="!egress_profile_id">
      <b-input-group size="sm">
        <b-input-group-prepend is-text>
          <b-icon icon="search"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          id="search-form"
          type="search"
          v-model="input_search"
          placeholder="Buscar..."
        >
        </b-form-input>
      </b-input-group>
    </div> -->
    <GenericBTable
      :items="profile_competences_list"
      :fields="fields"
      :filterCustom="filterCustom"
      :show_pagination="true"
      :search_filter="true"
      :selection_mode="true"
      :columns_display="true"
      :display_id="Competence.id"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @emitChangeSearchField="changeInputSearch"
    >
      <template #cell(career)="row">
        {{ getCareer(row.item.egress_profile) }}
      </template>
      <template #cell(professional_title)="row">
        <template v-if="getEgressProfile(row.item.egress_profile)">
          {{ getEgressProfile(row.item.egress_profile).professional_title }}
        </template>
      </template>
      <template #cell(academic_degree)="row">
        <template v-if="getEgressProfile(row.item.egress_profile)">
          {{ getEgressProfile(row.item.egress_profile).academic_degree }}
        </template>
      </template>
      <template #cell(cycle)="row">
        <template>
          <CycleEgressProfileCompetence
            :cycle_id="cycle_id"
            :egress_profile_id="row.item.egress_profile"
            :can_edit_cycle="can_edit_cycle"
            :egress_profile_competence_id="row.item.id"
            :disabled="!row.item.selected"
            @updated="slotCycleUpdated"
          ></CycleEgressProfileCompetence>
        </template>
      </template>
      <template #cell(order)="row">
        <b-form-input
          type="number"
          class="sm m-0 py-0"
          v-model="row.item.order"
          min="1"
          @change="slotProfilesSelectionChanged"
          :state="orderState(row.item)"
          v-b-tooltip.left.v-secondary.noninteractive="
            `${
              !orderState(row.item)
                ? String(
                    'Este perfil ya cuenta con una ' +
                      $getVisibleNames(
                        'mesh.competence',
                        false,
                        'Competencia'
                      ) +
                      ' en este orden.'
                  )
                : ''
            }`
          "
          :disabled="!row.item.selected || !can_edit_order"
        >
        </b-form-input>
      </template>
      <template #cell(selected)="row">
        <b-form-checkbox
          v-model="row.item.selected"
          @input="slotProfilesSelectionChanged"
          class="check-profile-competence"
          :disabled="!isNaN(egress_profile_id)"
        >
        </b-form-checkbox>
      </template>
    </GenericBTable>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "EgressProfileCompetence",
  components: {
    CycleEgressProfileCompetence: () =>
      import("@/components/reusable/CycleEgressProfileCompetence.vue"),
    GenericBTable: () => import("@/components/reusable/GenericBTable.vue"),
  },
  props: {
    Competence: {
      type: Object,
    },
    egress_profile_id: {
      type: Number,
    },
    cycle_id: {
      type: Number,
    },
    order: {
      type: Number,
    },
    can_edit_order: {
      type: Boolean,
      default: true,
    },
    can_edit_cycle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      profile_competences_list: [],
      check_selected: [],
      selected_cycle: null,
      is_checked: false,
      input_search: "",
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      cycles: names.CYCLES,
      storedCompetences: names.COMPETENCES,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      profileCompetences: names.PROFILE_COMPETENCES,
    }),
    fields() {
      return [
        {
          key: "selected",
          label: "",
          thStyle: { width: "min-content" },
          display_column: true,
        },
        {
          key: "career",
          label: String(
            this.$getVisibleNames("mesh.career", false, "Programa")
          ),
          thStyle: { font: "font-weight-bold" },
          sortable: true,
          display_column: true,
        },
        {
          key: "professional_title",
          label: String(
            this.$getVisibleNames(
              "manual.mesh.titulo_profesional",
              false,
              "Título Profesional"
            )
          ),
          sortable: true,
          display_column: true,
        },
        !(
          this.institution.id == 12 ||
          this.$debug_change_duoc ||
          !this.institution.show_academic_degree
        )
          ? {
              key: "academic_degree",
              label: String(
                this.$getVisibleNames(
                  "manual.grado_academico",
                  false,
                  "Grado Académico"
                )
              ),
              sortable: true,
              display_column: true,
            }
          : { key: "academic_degree", label: "", display_column: false },
        {
          key: "order",
          label: "Orden",
          thStyle: { width: "12%" },
          requires_check_selected: true,
          sortable: true,
          display_column: true,
        },
        {
          key: "cycle",
          label: this.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo"),
          thStyle: { width: "18%" },
          requires_check_selected: true,
          sortable: true,
          display_column: true,
        },
      ];
    },
  },
  methods: {
    orderState(item) {
      if (item.egress_profile) {
        const profile_competence = this.profileCompetences.filter(
          (x) =>
            x.egress_profile == item.egress_profile &&
            x.order == item.order &&
            x.cycle == item.cycle
        );
        if (
          profile_competence.length > 1 ||
          (profile_competence.length > 0 && isNaN(item.id))
        ) {
          return false;
        }
      }
      return true;
    },
    filterCustom(row) {
      if (
        this.$filtered(this.getCareer(row.egress_profile), this.input_search) ||
        this.$filtered(
          this.getEgressProfile(row.egress_profile).professional_title,
          this.input_search
        ) ||
        this.$filtered(
          this.getEgressProfile(row.egress_profile).academic_degree,
          this.input_search
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    fetchEgressprofileCompetences() {
      this.profile_competences_list = [];
      this.$store
        .dispatch(names.FETCH_PROFILE_COMPETENCES)
        .then((profile_competences) => {
          if (!isNaN(this.egress_profile_id)) {
            const profile_competence = profile_competences.find(
              (x) =>
                x.competence == this.Competence.id &&
                x.egress_profile == this.egress_profile_id
            );
            if (profile_competence) {
              profile_competence.selected = true;
              this.profile_competences_list.push(profile_competence);
            } else {
              this.profile_competences_list.push({
                id: generateUniqueId(),
                egress_profile: this.egress_profile_id,
                cycle: null,
                order: this.order ? this.order : 1,
                competence: this.Competence.id,
                selected: true,
              });
            }
          } else {
            this.$store
              .dispatch(names.FETCH_EGRESS_PROFILES)
              .then((egress_profiles) => {
                egress_profiles.forEach((egress_profile) => {
                  const profile_competence = profile_competences.find(
                    (x) =>
                      x.competence == this.Competence.id &&
                      x.egress_profile == egress_profile.id
                  );

                  if (profile_competence) {
                    profile_competence.selected = true;
                    this.profile_competences_list.push(profile_competence);
                  } else {
                    this.profile_competences_list.push({
                      id: generateUniqueId(),
                      egress_profile: egress_profile.id,
                      cycle: null,
                      order: this.order ? this.order : 1,
                      competence: this.Competence.id,
                      selected: false,
                    });
                  }
                });
              });
          }
        });
    },
    getEgressProfile(egress_profile) {
      let get_egress_profile = this.egressProfiles.find(
        (x) => x.id == egress_profile
      );
      return get_egress_profile ? get_egress_profile : [];
    },
    getCareer(egress_profile) {
      let get_career = this.careers.find((x) =>
        x.egress_profiles.includes(egress_profile)
      );
      return get_career ? get_career.name : "";
    },
    cyclesToSelect(egress_id) {
      return [{ id: null, name: "-- No Aplica --" }].concat(
        this.cycles.filter((cycle) => cycle.egress_profiles.includes(egress_id))
      );
    },
    slotProfilesSelectionChanged() {
      // Emite la señal updated junto a todas las instancias de Egressprofile_Competence.
      // Las seleccionadas y las que no.
      this.$emit("updated", this.profile_competences_list);
    },
    slotCycleUpdated(cycle_id, egress_profile_competence_id) {
      this.profile_competences_list.find(
        (x) => x.id == egress_profile_competence_id
      ).cycle = cycle_id;
      // Se llama el siguiente metodo para que el componente padre se entere que algo cambio.
      this.slotProfilesSelectionChanged();
    },
  },
  created() {
    this.fetchEgressprofileCompetences();
    this.$store.dispatch(names.FETCH_CYCLES);
  },
  mounted() {
    this.slotProfilesSelectionChanged();
  },
};
</script>

<style scoped>
.search-competence {
  margin-bottom: 0.8em !important;
}
.form-control.is-valid,
.form-control.is-invalid {
  padding-right: calc(0.9em + 0.75rem);
  background-position: right calc(0.1em + 0.1875rem) center;
}
@media (max-width: 991px) {
  .form-control.is-valid,
  .form-control.is-invalid {
    padding-right: calc(0.1em + 0.2rem);
    background: none;
  }
}
</style>