<template>
  <div class="files-container">
    <div class="file-div" v-for="file in fileList" :key="file.id">
      -
      <b-link :href="file.file" target="_blank">
        <!-- {{ file.file.substring(45) }} -->
        {{ file.name }}
      </b-link>
      <b-button
        class="delete-file"
        variant="none"
        v-if="allows_crud"
        size="sm"
        @click="deleteFile(file.id)"
      >
        <b-icon
          icon="x"
          variant="danger"
          scale="1.2"
          v-b-tooltip.v-secondary.noninteractive="'Eliminar archivo'"
        ></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "FileContainer",
  components: {},
  props: {
    Files: {
      type: Array,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      storedFiles: names.FILES,
    }),
    fileList() {
      return this.storedFiles.filter((x) => this.Files.includes(x.id));
    },
  },
  methods: {
    deleteFile(file_id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el archivo adjunto?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_FILE, file_id).then(() => {
            this.$emit("deleteFile", file_id);
            toast("Archivo eliminado.");
          });
        }
      });
    },
  },
  created() {
    this.Files.forEach((element) => {
      this.$store.dispatch(names.FETCH_FILE, element);
    });
  },
};
</script>
<style scoped>
.delete-file {
  padding: 0 !important;
}
.delete-file:focus {
  outline: none;
  box-shadow: none;
}
</style>