var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{staticClass:"label m-0",attrs:{"label-cols":"0","label-cols-sm":"3","label-for":"weighing"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"weighing-label"},[_c('div',{staticClass:"label-content"},[_vm._v("Ingresar datos por:")])])]},proxy:true}])},[_c('div',{attrs:{"id":"toggle_in_weeks"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"toggle-types noprint"},[_c('span',{class:{
              'font-weight-bold': _vm.egressProfileMatter.toggle_in_weeks,
            }},[_vm._v("Semana")]),_c('b-button',{staticClass:"check-type",class:{ 'check-type-disabled': _vm.egressProfileMatter.is_closed },attrs:{"variant":"primary"},on:{"click":_vm.changeToggleInWeeks}},[_c('b-icon',{staticClass:"icon-check",class:{
                'icon-check-active': !_vm.egressProfileMatter.toggle_in_weeks,
              },attrs:{"icon":"circle-fill","scale":"0.7"}})],1),_c('span',{class:{
              'font-weight-bold': !_vm.egressProfileMatter.toggle_in_weeks,
            }},[_vm._v("Total")])],1)])])]),(
      _vm.egressProfileMatterHoursgFilter.filter(
        (x) => x.uses_module_minutes == true
      ).length > 0
    )?_c('b-table-simple',{attrs:{"borderless":"","small":"","responsive":""}},[_c('b-thead',[_c('b-th',{staticClass:"w-25"},[_vm._v("Horas Pedagógicas:")]),_c('b-th',{staticClass:"w-75"}),_c('b-th',{staticClass:"text-center"},[_c('div',[_vm._v(" + "),_c('InfoTooltip',{staticClass:"info-icon",attrs:{"tooltip_text":`Incluir en ${_vm.$getVisibleNames(
              'manual.total_pedagogical_hours',
              true,
              'Total de Horas Pedagógicas'
            )}`}})],1)]),_c('b-th',{staticClass:"text-center"},[_c('div',[_vm._v(" % "),_c('InfoTooltip',{staticClass:"info-icon",attrs:{"tooltip_text":`Porcentaje de asistencia requerido`}})],1)])],1),_c('b-tbody',_vm._l((_vm.egressProfileMatterHoursgFilter.filter(
          (x) => x.uses_module_minutes == true
        )),function(hour){return _c('b-tr',{key:hour.id},[_c('b-td',{staticClass:"p-0"},[_c('b-form-group',{attrs:{"label":hour.name,"label-for":`hour-${hour.uses_module_minutes}-input-${hour.id}`,"label-class":"label-matter mt-2"}})],1),_c('b-td',{staticClass:"p-0"},[(_vm.egressProfileMatter.toggle_in_weeks)?_c('b-form-input',{attrs:{"id":`hour-${hour.uses_module_minutes}-input-${hour.id}`,"disabled":_vm.egressProfileMatter.is_closed,"aria-describedby":`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`,"size":"sm"},on:{"update":(value) => {
                if (parseFloat(value) < 0 || value == null || value == '')
                  hour.tem_hours = 0;
                else if (parseFloat(value) >= 0)
                  hour.hours =
                    parseFloat(value) * parseFloat(_vm.egressProfileMatter.weeks);
              }},model:{value:(hour.tem_hours),callback:function ($$v) {_vm.$set(hour, "tem_hours", $$v)},expression:"hour.tem_hours"}}):_c('b-form-input',{attrs:{"id":`hour-${hour.uses_module_minutes}-input-${hour.id}`,"disabled":_vm.egressProfileMatter.is_closed,"aria-describedby":`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`,"size":"sm"},on:{"update":(value) => {
                if (parseFloat(value) < 0 || value == null || value == '')
                  hour.hours = 0;
                else if (parseFloat(value) >= 0) {
                  if (
                    _vm.egressProfileMatter.weeks == 0 ||
                    parseFloat(value) == 0
                  )
                    hour.tem_hours = 0;
                  else
                    hour.tem_hours = Math.round(
                      parseFloat(value) /
                        parseFloat(_vm.egressProfileMatter.weeks)
                    );
                }
              }},model:{value:(hour.hours),callback:function ($$v) {_vm.$set(hour, "hours", $$v)},expression:"hour.hours"}}),_c('b-form-invalid-feedback',{staticClass:"mt-0 mb-1",attrs:{"id":`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`}},[_vm._v("Este campo no debe estar vacío y no debe ser menor a 0.")])],1),_c('b-td',{staticClass:"p-0"},[_c('b-form-checkbox',{staticClass:"mt-2 ml-2",attrs:{"id":`checkbox-${hour.uses_module_minutes}-input-${hour.id}`,"size":"lg","disabled":_vm.egressProfileMatter.is_closed},model:{value:(hour.counts_towards_credits),callback:function ($$v) {_vm.$set(hour, "counts_towards_credits", $$v)},expression:"hour.counts_towards_credits"}})],1),_c('b-td',{staticClass:"p-0"},[_c('b-form-input',{staticStyle:{"min-width":"4rem"},attrs:{"id":`attendance_requirement-${hour.uses_module_minutes}-input-${hour.id}`,"disabled":_vm.egressProfileMatter.is_closed,"aria-describedby":`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`,"size":"sm"},on:{"update":(value) => {
                if (parseFloat(value) < 0 || value == null || value == '')
                  hour.attendance_requirement = 0;
                else if (parseFloat(value) > 100)
                  hour.attendance_requirement = 100;
              }},model:{value:(hour.attendance_requirement),callback:function ($$v) {_vm.$set(hour, "attendance_requirement", $$v)},expression:"hour.attendance_requirement"}}),_c('b-form-invalid-feedback',{staticClass:"mt-0 mb-1",attrs:{"id":`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`}},[_vm._v("Este campo no debe estar vacío y no debe ser menor a 0.")])],1)],1)}),1)],1):_vm._e(),_c('b-form-group',{staticClass:"label-matter mb-0 mx-0",attrs:{"label-cols":"0","label-cols-sm":"4","label-for":"total_hours"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours", true, "Total de Horas Pedagógicas" ))+" ")])]},proxy:true}])},[_c('div',[_c('b-badge',{staticClass:"total-hours-value mt-1",attrs:{"id":"total_hours","variant":"light"}},[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.totalPedagogical))+" ")])],1)]),_c('b-form-group',{staticClass:"label mb-0 mx-0",attrs:{"label-cols":"0","label-cols-sm":"4","label-for":"total_hours"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours_to_chronological", true, "Total de Horas Pedagógicas (En Cronológicas)" ))+" "),_c('span',[(_vm.profileType)?_c('InfoTooltip',{staticClass:"info-icon",attrs:{"tooltip_text":`${_vm.$getVisibleNames(
              'manual.total_pedagogical_hours',
              true,
              'Total de Horas Pedagógicas'
            )}(${_vm.totalPedagogical}) * Cantidad de Minutos por Hora Pedagógica(${
              _vm.profileType ? _vm.profileType.module_minutes : 0
            }/60)= ${
              _vm.totalPedagogical * (parseFloat(_vm.profileType.module_minutes) / 60)
            }`}}):_vm._e()],1)])]},proxy:true}])},[_c('div',[(_vm.profileType)?_c('b-badge',{staticClass:"total-hours-value mt-1",attrs:{"id":"total_hours","variant":"light"}},[_vm._v(" "+_vm._s(_vm._f("Round")((_vm.totalPedagogical * (parseFloat(_vm.profileType.module_minutes) / 60))))+" ")]):_vm._e()],1)]),(
      _vm.egressProfileMatterHoursgFilter.filter(
        (x) => x.uses_module_minutes == false
      ).length > 0
    )?_c('b-table-simple',{attrs:{"borderless":"","small":"","responsive":""}},[_c('b-thead',[_c('b-th',{staticClass:"w-25"},[_vm._v("Horas Cronológicas:")]),_c('b-th',{staticClass:"w-75"}),_c('b-th',{staticClass:"text-center"},[_c('div',[_vm._v(" + "),_c('InfoTooltip',{staticClass:"info-icon",attrs:{"tooltip_text":`Incluir en ${_vm.$getVisibleNames(
              'manual.total_chronological_hours',
              true,
              'Total de Horas Cronológicas'
            )}`}})],1)]),_c('b-th',{staticClass:"text-center"},[_c('div',[_vm._v(" % "),_c('InfoTooltip',{staticClass:"info-icon",attrs:{"tooltip_text":`Porcentaje de asistencia requerido`}})],1)])],1),_c('b-tbody',_vm._l((_vm.egressProfileMatterHoursgFilter.filter(
          (x) => x.uses_module_minutes == false
        )),function(hour){return _c('b-tr',{key:hour.id},[_c('b-td',{staticClass:"p-0"},[_c('b-form-group',{attrs:{"label":hour.name,"label-for":`hour-${hour.uses_module_minutes}-input-${hour.id}`,"label-class":"label-matter mt-2"}})],1),_c('b-td',{staticClass:"p-0"},[(_vm.egressProfileMatter.toggle_in_weeks)?_c('b-form-input',{attrs:{"id":`hour-${hour.uses_module_minutes}-input-${hour.id}`,"disabled":_vm.egressProfileMatter.is_closed,"aria-describedby":`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`,"size":"sm"},on:{"update":(value) => {
                if (parseFloat(value) < 0 || value == null || value == '')
                  hour.tem_hours = 0;
                else if (parseFloat(value) >= 0)
                  hour.hours =
                    parseFloat(value) * parseFloat(_vm.egressProfileMatter.weeks);
              }},model:{value:(hour.tem_hours),callback:function ($$v) {_vm.$set(hour, "tem_hours", $$v)},expression:"hour.tem_hours"}}):_c('b-form-input',{attrs:{"id":`hour-${hour.uses_module_minutes}-input-${hour.id}`,"disabled":_vm.egressProfileMatter.is_closed,"aria-describedby":`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`,"size":"sm"},on:{"update":(value) => {
                if (parseFloat(value) < 0 || value == null || value == '')
                  hour.hours = 0;
                else if (parseFloat(value) >= 0) {
                  if (
                    _vm.egressProfileMatter.weeks == 0 ||
                    parseFloat(value) == 0
                  )
                    hour.tem_hours = 0;
                  else
                    hour.tem_hours = Math.round(
                      parseFloat(value) /
                        parseFloat(_vm.egressProfileMatter.weeks)
                    );
                }
              }},model:{value:(hour.hours),callback:function ($$v) {_vm.$set(hour, "hours", $$v)},expression:"hour.hours"}}),_c('b-form-invalid-feedback',{staticClass:"mt-0 mb-1",attrs:{"id":`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`}},[_vm._v("Este campo no debe estar vacío y no debe ser menor a 0.")])],1),_c('b-td',{staticClass:"p-0"},[_c('b-form-checkbox',{staticClass:"mt-2 ml-2",attrs:{"id":`checkbox-${hour.uses_module_minutes}-input-${hour.id}`,"size":"lg","disabled":_vm.egressProfileMatter.is_closed},model:{value:(hour.counts_towards_credits),callback:function ($$v) {_vm.$set(hour, "counts_towards_credits", $$v)},expression:"hour.counts_towards_credits"}})],1),_c('b-td',{staticClass:"p-0"},[_c('b-form-input',{staticStyle:{"min-width":"4rem"},attrs:{"id":`attendance_requirement-${hour.uses_module_minutes}-input-${hour.id}`,"disabled":_vm.egressProfileMatter.is_closed,"aria-describedby":`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`,"size":"sm"},on:{"update":(value) => {
                if (parseFloat(value) < 0 || value == null || value == '')
                  hour.attendance_requirement = 0;
                else if (parseFloat(value) > 100)
                  hour.attendance_requirement = 100;
              }},model:{value:(hour.attendance_requirement),callback:function ($$v) {_vm.$set(hour, "attendance_requirement", $$v)},expression:"hour.attendance_requirement"}}),_c('b-form-invalid-feedback',{staticClass:"mt-0 mb-1",attrs:{"id":`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`}},[_vm._v("Este campo no debe estar vacío y no debe ser menor a 0.")])],1)],1)}),1)],1):_vm._e(),_c('b-form-group',{staticClass:"label mb-0 mx-0",attrs:{"label-cols":"0","label-cols-sm":"4","label-for":"total_hours"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_chronological_hours", true, "Total de Horas Cronológicas" ))+" ")])]},proxy:true}])},[_c('div',[_c('b-badge',{staticClass:"total-hours-value mt-1",attrs:{"id":"total_hours","variant":"light"}},[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.totalChronological))+" ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }