<template>
  <div class="text-editor-container">
    <h5 class="mb-2">Descripción</h5>
    <vue-editor
      v-model.trim="text"
      @keydown.enter.prevent="update"
      :editorToolbar="customToolbar"
      id="editor"
      ref="editor"
    ></vue-editor>
    <div class="row">
      <div class="col mt-1" style="text-align: right">
        <b-button class="mr-0" :disabled="text == ''" size="sm" @click="save"
          >Guardar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "RichTextEditor",
  components: {
    VueEditor,
  },
  props: {
    Object: {
      type: Object,
    },
    Type: {
      type: Object,
    },
    Text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      text: this.Text,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        // Se comenta tipo de lista de check por desuso.
        // [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["image", "video"],
        ["clean"], // remove formatting button
      ],
    };
  },
  methods: {
    update() {
      if (this.text != this.Text)
        this.$emit("update", this.Object, this.text.trim());
      this.text = "";
    },
    save() {
      if (this.Object) {
        this.$emit("update", this.Object, this.text.trim());
        this.$emit("close");
      } else {
        if (this.Type) {
          this.$emit("create", this.text.trim(), this.Type);
          this.$emit("close");
        } else {
          this.$emit("create", this.text.trim());
          this.$emit("close");
        }
      }
    },
  },
  watch: {
    // text: function () {
    //   this.text = this.text.replace(/\d\.\s+|•\s+|[A-Z]\.\s+|[IVX]+\.\s+/g, "");
    // },
  },
  mounted() {
    this.$refs.editor.quill.format("align", "left");
  },
};
</script>

<style scoped>
label {
  cursor: pointer;
}
textarea {
  width: 100%;
  border: 1px solid var(--secondary-color);
}
.text-editor-container {
  width: 100%;
  height: auto;
}
p {
  width: fit-content;
}

/*    --------------------------------------------
      | Añadiendo padding en elementos listados  |
      | para vista previa de texto enriquecido.  |
      --------------------------------------------
     *              ***
    *             ***
   *           *******
  *            ****
 *             *
*/
div >>> ul,
div >>> ol {
  padding-left: 0 !important;
}
</style>