<template>
  <div v-if="matterTimeAllocation && matterTimeAllocation.hours > 0">
    <div class="d-flex mb-3">
      <div class="w-10">
        <b-form-input
          v-model="tmp_mat_eva_stu_env_tim_all.hours"
          @update="updateInput()"
          :state="timeAvailable >= 0"
          type="number"
          min="0"
          size="sm"
        ></b-form-input>
      </div>
      <div
        class="ml-2"
        :class="{ 'text-red': !isValidStudyEnvironment }"
        v-b-tooltip.v-secondary.noninteractive="
          `${
            !isValidStudyEnvironment
              ? 'Son horas que no están asociadas a los ' +
                $getVisibleNames(
                  'mesh.studyenvironment',
                  false,
                  'Ambiente de Aprendizaje'
                ) +
                ' seleccionados.'
              : ''
          }`
        "
      >
        <span class="align-middle" v-if="timeAllocation">
          {{ timeAllocation.name }}({{ matterTimeAllocation.hours }}Hrs)
        </span>
      </div>
      <template v-if="timeAvailable >= 0">
        <div
          class="ml-2"
          v-b-tooltip.v-secondary.noninteractive="
            `${!isValidStudyEnvironment ? '' : ''}`
          "
        >
          <span class="align-middle"
            >(Disponible: {{ timeAvailable | Round }}Hrs)</span
          >
        </div>
      </template>
      <template v-else>
        <div
          class="ml-2 text-red"
          v-b-tooltip.v-secondary.noninteractive="
            `${!isValidStudyEnvironment ? '' : ''}`
          "
        >
          <span class="align-middle"
            >(Excedente: {{ (timeAvailable * -1) | Round }}Hrs)</span
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
export default {
  name: "MatEvaStuEnvTimAllInput",
  components: {},
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    matter_evaluation_id: {
      required: true,
    },
    time_allocation_id: {
      type: Number,
      required: true,
    },
    study_environment_id: {
      required: true,
    },
    StudyEnvironmentIds: {
      type: Array,
    },
    isPedagogical: {
      type: Boolean,
      required: true,
    },
    isOlMatter: {
      type: Boolean,
      required: true,
    },
    MatEvaStuEnvTimAll: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          matter_evaluation: this.matter_evaluation_id,
          egress_profile_matter_evaluation: this.matter_evaluation_id,
          study_environment: this.study_environment_id,
          time_allocation: this.time_allocation_id,
          hours: 0,
        };
      },
    },
    tmpMatEvaStuEnvTimAll: {
      type: Array,
    },
    MatEvaStuEnvTimAllList: {
      type: Array,
    },
  },
  data() {
    return {
      tmp_mat_eva_stu_env_tim_all: { ...this.MatEvaStuEnvTimAll },
    };
  },
  computed: {
    ...mapGetters({
      time_allocations: names.TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
    }),
    timeAllocation() {
      return this.time_allocations.find((x) => x.id == this.time_allocation_id);
    },
    matterTimeAllocation() {
      if (this.isOlMatter)
        return this.matter_time_allocations.find(
          (x) =>
            x.time_allocation == this.time_allocation_id &&
            x.matter == this.matter_id
        );
      else
        return this.ep_matter_time_allocations.find(
          (x) =>
            x.time_allocation == this.time_allocation_id &&
            x.egress_profile_matter == this.matter_id
        );
    },
    timeAvailable() {
      if (!this.matterTimeAllocation) return 0;
      return (
        this.matterTimeAllocation.hours -
        this.tmp_mat_eva_stu_env_tim_all.hours -
        this.MatEvaStuEnvTimAllList.filter(
          (x) =>
            x.time_allocation == this.time_allocation_id &&
            x.id != this.tmp_mat_eva_stu_env_tim_all.id &&
            x.hours > 0
        ).reduce((acumulador, objeto) => acumulador + objeto.hours, 0) -
        this.tmpMatEvaStuEnvTimAll
          .filter(
            (x) =>
              x.time_allocation == this.time_allocation_id &&
              x.id != this.tmp_mat_eva_stu_env_tim_all.id &&
              x.hours > 0
          )
          .reduce((acumulador, objeto) => acumulador + objeto.hours, 0)
      );
    },
    isValidStudyEnvironment() {
      if (this.StudyEnvironmentIds.length > 0) {
        if (
          this.StudyEnvironmentIds.includes(this.study_environment_id) ||
          !this.timeAllocation.has_study_environments
        )
          return true;
        else return false;
      } else if (this.study_environment_id == null) return true;
      else return false;
    },
  },
  methods: {
    updateInput() {
      if (
        parseInt(this.tmp_mat_eva_stu_env_tim_all.hours) < 0 ||
        this.tmp_mat_eva_stu_env_tim_all.hours == null ||
        this.tmp_mat_eva_stu_env_tim_all.hours == ""
      ) {
        this.tmp_mat_eva_stu_env_tim_all.hours = 0;
        this.patchMatStuEnvTimeAll();
      } else if (parseInt(this.timeAvailable) < 0) {
        this.tmp_mat_eva_stu_env_tim_all.hours =
          parseInt(this.tmp_mat_eva_stu_env_tim_all.hours) +
          parseInt(this.timeAvailable);
        this.patchMatStuEnvTimeAll();
      } else {
        this.patchMatStuEnvTimeAll();
      }
    },
    patchMatStuEnvTimeAll() {
      if (this.timeout) clearTimeout(this.timeout);
      if (
        parseInt(this.tmp_mat_eva_stu_env_tim_all.hours) < 0 ||
        this.tmp_mat_eva_stu_env_tim_all.hours == null ||
        this.tmp_mat_eva_stu_env_tim_all.hours == ""
      )
        return;
      else if (this.tmp_mat_eva_stu_env_tim_all.hours >= 0) {
        this.timeout = setTimeout(() => {
          this.tmp_mat_eva_stu_env_tim_all.hours = parseInt(
            this.tmp_mat_eva_stu_env_tim_all.hours
          );
          if (isNaN(this.tmp_mat_eva_stu_env_tim_all.id))
            this.createMatEvaStuEnvTimAll();
          else this.updateMatEvaStuEnvTimAll();
        }, 500);
      }
    },
    createMatEvaStuEnvTimAll() {
      this.$emit("created", this.tmp_mat_eva_stu_env_tim_all);
    },
    updateMatEvaStuEnvTimAll() {
      this.$emit("updated", this.tmp_mat_eva_stu_env_tim_all);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.w-10 {
  width: 10%;
}
.text-red {
  color: red;
}
</style>