<template>
  <div>
    <h3>Administrar Usuarios</h3>
    <b-tabs content-class="mt-3">
      <b-tab v-if="user.is_superuser" title="Administrar Institución">
        <InstitutionCard :allows_crud="allows_crud"></InstitutionCard
      ></b-tab>
      <b-tab title="Administrar Roles">
        <GroupNameAssignment
          :allows_crud="allows_crud"
          @created="slotCreatedRole"
          @updated="slotUpdatedRole"
          @delete="slotDeleteRole"
        ></GroupNameAssignment
      ></b-tab>
      <b-tab title="Administrar Permisos de Usuarios" active>
        <div style="display: flex">
          <div class="col" style="text-align: left">
            <button
              v-can="'mesh.add_cycle'"
              v-if="allows_crud"
              class="btn btn-secondary btn-sm btn-add"
              v-b-modal.new-user-modal
            >
              Agregar Usuario
            </button>
          </div>
        </div>
        <GenericBTable
          :items="users"
          :fields="fields"
          thead-class="b-card-style"
          stacked="md"
          :show_pagination="true"
          :search_filter="true"
          :selection_mode="true"
          :allows_crud="allows_crud"
          :filterCustom="filterCustom"
          @emitChangeSearchField="changeInputSearch"
          :display_id="'admin-user'"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template #cell(avatar)="data">
            <div>
              <b-avatar
                :id="`user-avatar-${data.item.id}`"
                size="2rem"
                :text="`${
                  data.item.first_name.charAt(0) + data.item.last_name.charAt(0)
                }`"
                variant="info"
              ></b-avatar>
            </div>
          </template>
          <template #cell(is_professor)="data">
            {{ getRole(data.item) }}
          </template>
          <template #cell(actions)="data">
            <div v-if="!allows_crud" class="noprint">
              <b-button
                size="sm"
                variant="none"
                class="p-1"
                @click="openUserInfoModal(data.item.id)"
                ><b-icon icon="eye" scale="1"></b-icon
              ></b-button>
              <b-modal
                :id="`user-info-modal-${data.item.id}`"
                title="Información del Usuario"
                size="lg"
                hide-footer
              >
                <UserForm
                  :User="data.item"
                  :IdentifierTypes="identifier_types"
                  :Roles="roles"
                  :allows_crud="allows_crud"
                ></UserForm>
              </b-modal>
            </div>
            <div v-if="allows_crud">
              <button-edit
                @click="$bvModal.show(`edit-user-modal-${data.item.id}`)"
                title="Editar Usuario"
              ></button-edit>
              <template
                v-if="
                  ![6, 7].includes(data.item.groups[0]) &&
                  !data.item.is_superuser &&
                  data.item.groups.length > 0
                "
              >
                <b-button
                  class="m-1 p-0"
                  variant="none"
                  @click="
                    $bvModal.show(`edit-user-permission-modal-${data.item.id}`)
                  "
                  v-b-tooltip.v-secondary.auto.noninteractive="
                    'Administrar Permisos para el Usuario.'
                  "
                >
                  <b-icon icon="person-lines-fill"></b-icon>
                </b-button>
                <b-button
                  size="sm"
                  variant="none"
                  @click="
                    $bvModal.show(`copy-user-permission-modal-${data.item.id}`)
                  "
                  v-b-tooltip.v-secondary.auto.noninteractive="
                    'Copiar Permisos'
                  "
                  ><b-icon class="btn-actions" icon="files"></b-icon
                ></b-button>
              </template>
              <template v-else>
                <b-button
                  class="m-1 p-0"
                  variant="none"
                  :disabled="true"
                  v-b-tooltip.v-secondary.auto.noninteractive="
                    'Administrar Permisos para el Usuario.'
                  "
                >
                  <b-icon icon="person-lines-fill"></b-icon>
                </b-button>
                <b-button
                  size="sm"
                  variant="none"
                  :disabled="true"
                  v-b-tooltip.v-secondary.auto.noninteractive="
                    'Copiar Permisos'
                  "
                  ><b-icon class="btn-actions" icon="files"></b-icon
                ></b-button>
              </template>
              <template v-if="user.id != data.item.id">
                <button-delete
                  @click="askForDeleteUser(data.item.id)"
                  v-b-tooltip.v-secondary.auto.noninteractive="{
                    customClass: 'custom-tooltip',
                  }"
                  title="Eliminar Usuario"
                ></button-delete>
              </template>
              <template v-else>
                <span
                  v-b-tooltip.v-secondary.auto.noninteractive="
                    'No puedes eliminar el usuario con el que ingresaste'
                  "
                >
                  <button-delete disabled></button-delete>
                </span>
              </template>
            </div>
            <b-modal
              :id="`edit-user-permission-modal-${data.item.id}`"
              title="Editar Permisos"
              size="xl"
              hide-footer
            >
              <AccessForm
                :User="data.item"
                :IdentifierTypes="identifier_types"
                :Roles="roles"
                :allows_crud="allows_crud"
              ></AccessForm>
            </b-modal>
            <b-modal
              :id="`copy-user-permission-modal-${data.item.id}`"
              title="Copiar Permisos"
              size="md"
              hide-footer
            >
              <b-form-group
                label="Usuario"
                label-for="name-input"
                label-cols="0"
                label-cols-sm="3"
              >
                <b-form-select
                  id="name-select"
                  name="name-select"
                  v-model="selected_user"
                  value-field="id"
                  text-field="name"
                >
                  <b-form-select-option
                    v-for="user in filterUserByGroups(data.item)"
                    :key="user.id"
                    :value="user.id"
                  >
                    {{ user.first_name }} {{ user.last_name }} -
                    {{ user.email }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
              <div class="col" style="text-align: right">
                <b-button
                  class="mr-1"
                  size="sm"
                  :disabled="!selected_user"
                  @click="copyTableAccess(selected_user, data.item.id)"
                  >Guardar</b-button
                >
              </div>
            </b-modal>
            <b-modal
              :id="`edit-user-modal-${data.item.id}`"
              title="Editar Usuario"
              size="lg"
              hide-footer
            >
              <UserForm
                :User="data.item"
                :IdentifierTypes="identifier_types"
                :Roles="roles"
                :allows_crud="allows_crud"
                @updated="slotCloseModal"
              ></UserForm>
            </b-modal>
          </template>
        </GenericBTable>
        <div>
          <b-modal
            id="new-user-modal"
            title="Crear Usuario"
            size="lg"
            hide-footer
          >
            <UserForm
              :IdentifierTypes="identifier_types"
              :Roles="roles"
              :allows_crud="allows_crud"
              @created="slotCreatedUser"
            ></UserForm>
          </b-modal>
        </div>
      </b-tab>
      <!-- **************************************** Pestaña de Recuadro Roles y Funciones **************************************** -->
    </b-tabs>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "AdminUsersView",
  components: {
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    UserForm: () => import("./UserForm.vue"),
    AccessForm: () => import("./AccessForm.vue"),
    GroupNameAssignment: () => import("./GroupNameAssignment"),
    InstitutionCard: () => import("./InstitutionCard"),
  },
  data() {
    return {
      roles: [],
      identifier_types: [],
      selected_user: null,
      // users_data: [],
      fields: [
        {
          key: "avatar",
          label: "",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "first_name",
          label: "Nombres",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        {
          key: "last_name",
          label: "Apellidos",
          sortable: true,
          display_column: true,
          sortDirection: "desc",
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        {
          key: "email",
          label: "E-mail",
          sortable: true,
          display_column: true,
          class: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "is_professor",
          label: "Rol",
          display_column: true,
          class: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
        },
        { key: "actions", label: "", class: "noprint", display_column: true },
      ],
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      users: names.USERS,
    }),
    allows_crud() {
      // this.$hasPermissions("");
      // return false;
      const has_permission = this.$hasPermissions("teaching.change_access");
      if (has_permission) return has_permission;
      else return false;
    },
  },
  methods: {
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    filterCustom(row) {
      if (
        this.$filtered(row.first_name, this.input_search) ||
        this.$filtered(row.last_name, this.input_search) ||
        this.$filtered(row.email, this.input_search) ||
        this.$filtered(this.getRole(row), this.input_search)
      ) {
        return true;
      } else {
        return false;
      }
    },
    slotCreatedRole(role) {
      this.roles.push(role);
    },
    slotDeleteRole(role_id) {
      const index = this.roles.findIndex((x) => x.id == role_id);
      if (index != -1) this.roles.splice(index, 1);
    },
    filterUserByGroups(user) {
      const users = this.users.filter(
        (x) => x.groups.includes(user.groups[0]) && x.id != user.id
      );
      return users;
    },
    slotUpdatedRole(role) {
      const index = this.roles.findIndex((x) => x.id == role.id);
      if (index != -1) {
        this.roles[index].position_name = role.position_name;
        this.roles[index].position_access = role.position_access;
      }
    },
    getRole(user) {
      if (user.is_superuser == true) return "Super Usuario";
      const role = this.roles.find((x) => x.id == user.role);
      return role ? role.position_name : "";
    },
    openUserInfoModal(user_id) {
      this.$bvModal.show(`user-info-modal-${user_id}`);
    },
    askForDeleteUser(user_id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el usuario?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_USER, user_id).then(() => {
            toast("Usuario eliminado.");
          });
        }
      });
    },
    copyTableAccess(from_user, to_user) {
      if (from_user && to_user && from_user != to_user) {
        this.$restful
          .Get(`teaching/copy_table_access/?from=${from_user}&to=${to_user}`)
          .then(() => {
            toast("Permiso copiado");
            this.$bvModal.hide(`copy-user-permission-modal-${to_user}`);
          });
      }
    },
    slotCreatedUser() {
      this.$bvModal.hide("new-user-modal");
    },
    slotCloseModal(user) {
      this.$bvModal.hide(`edit-user-modal-${user.id}`);
    },
  },
  created() {
    // this.$restful.Get(`/authentication/user-data/`).then((response) => {
    //   this.users_data = response;
    // });
    this.$restful.Get(`/common/identifer-type/`).then((response) => {
      this.identifier_types = response;
    });
    this.$restful.Get(`/teaching/roles/`).then((response) => {
      this.roles = response;
    });
    this.$store.dispatch(names.FETCH_USERS);
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
    // .then((response) => {
    //   response.forEach((egress_profile) =>
    //     this.$store.dispatch(names.FETCH_MATTERS, {
    //       egress_profile_id: egress_profile.id,
    //     })
    //   );
    // });
    this.$store.dispatch(names.FETCH_MATTERS);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS);
  },
};
</script>

<style scoped>
</style>