<template>
  <div class="content-div">
    <div>
      <label class="study-unit-label">{{ study_unit.name }}</label>
      <p>
        <!-- <b-icon
          v-if="getRaMicro(study_unit.id)"
          :id="`ra-micro-content-${study_unit.id}`"
          class="ra-micro-icon"
          style="cursor: pointer"
          icon="info-circle"
        ></b-icon> -->
        <SentenceContainer :Sentence="study_unit"> </SentenceContainer>
      </p>
      <div>
        <section>
          <!-- <SentenceContainer :Sentence="getRaMicro(study_unit.id)">
              </SentenceContainer> -->
        </section>
      </div>
      <div
        class="d-flex justify-content-center"
        v-can="'teaching.change_studyunit'"
        v-if="allows_crud"
      >
        <LinkingCompetenceToRa
          v-if="(institution && institution.show_base_ra) || $debug_change_duoc"
          class="noprint"
          :study_unit_id="study_unit_id"
          :egress_profile_matter_id="egress_profile_matter_id"
          :allows_crud="allows_crud"
        ></LinkingCompetenceToRa>
        <!-- class="button-div" -->
        <button-edit
          @click="$bvModal.show(`modal-study-unit-${study_unit.id}`)"
          v-b-tooltip.v-secondary.noninteractive="
            `Editar ${$getVisibleNames('teaching.ramicro', false, 'RA Micro')}`
          "
        ></button-edit>
        <b-modal
          :id="`modal-study-unit-${study_unit.id}`"
          :title="`Editar ${$getVisibleNames(
            'teaching.ramicro',
            false,
            'RA Micro'
          )}`"
          size="lg"
          hide-footer
        >
          <!-- <p>El Indicador de Desempeño o Aprendizaje Esperado de la Asignatura constituye el último Resultado de Aprendizaje (Unidad de Aprendizaje) de la Asigntura.</p> -->
          <StudyUnitForm
            :egress_profile_matter_id="egress_profile_matter_id"
            :matter_id="matter_id"
            :StudyUnit="study_unit"
            :show_delete_button="true"
            @updated="$bvModal.hide(`modal-study-unit-${study_unit.id}`)"
            @deleted="$bvModal.hide(`modal-study-unit-${study_unit.id}`)"
          ></StudyUnitForm>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "StudyUnitCell",
  components: {
    LinkingCompetenceToRa: () =>
      import("@/components/mesh/Matrix2/LinkingCompetenceToRa"),
    StudyUnitForm: () => import("@/components/reusable/StudyUnitForm"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    study_unit_id: {
      type: [Number, String],
      default: function () {
        return generateUniqueId();
      },
    },
    matter_id: {
      type: Number,
      required: true,
    },
    egress_profile_matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      study_unit: {
        id: this.study_unit_id,
        name: "",
        order: 1,
        weeks: 6,
        matter: this.matter_id,
        methodological_strategies: [],
        performance_indicators: [],
        action: "",
        content: "",
        condition: "",
        verb: null,
      },
    };
  },
  methods: {
    getRaMicro(study_unit_id) {
      const ra = this.ra_micros.find((x) => x.study_unit == study_unit_id);
      return ra ? ra : null;
    },
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      studyUnits: names.STUDY_UNITS,
      ra_micros: names.RA_MICROS,
    }),
    storedStudyUnit() {
      return this.studyUnits.find((x) => x.id == this.study_unit_id);
    },
  },
  mounted() {
    if (this.storedStudyUnit) this.study_unit = this.storedStudyUnit;
  },
  watch: {
    storedStudyUnit() {
      if (this.storedStudyUnit) this.study_unit = this.storedStudyUnit;
    },
  },
};
</script>

<style scoped>
p {
  font-size: var(--thirth-font-size);
  text-align: left;
}
.button-div {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.content-div {
  margin-bottom: 25px;
}
.ra-micro-icon {
  float: right;
  transform: translateX(60%);
  margin-top: 1em;
  margin-right: 1em;
}
.study-unit-label {
  font-size: var(--secondary-font-size);
  margin-bottom: 0.3em;
  display: block;
  text-align: left;
  margin-left: 0.5em;
  font-weight: bold;
}
</style>