<template>
  <div>
    <h4 v-if="isNaN(faculty.id) && show_title">
      Crear {{ $getVisibleNames("mesh.faculty", false, "Facultad") }}
    </h4>
    <h4 v-else-if="show_title">
      Editar {{ $getVisibleNames("mesh.faculty", false, "Facultad") }}
    </h4>
    <div class="form-container">
      <b-form-group
        label="Nombre:"
        label-for="name-input"
        label-cols="0"
        label-cols-sm="3"
      >
        <b-form-input
          id="name-input"
          name="name-input"
          v-model="$v.faculty.name.$model"
          :state="validateState('name')"
          aria-describedby="input-name-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-name-live-feedback"
          >Este campo es obligatorio y debe tener al menos 3
          caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Color:"
        label-for="input-color"
        label-cols="4"
        label-cols-sm="3"
        class="label"
      >
        <b-form-input
          type="color"
          id="input-color"
          name="input-color"
          v-model="$v.faculty.color.$model"
          :state="validateState('color')"
          aria-describedby="input-color-live-feedback"
          size="md"
          rows="3"
          max-rows="8"
        >
        </b-form-input>
      </b-form-group>
      <!-- Faculty_Areas -->
      <b-form-group
        :label="
          $getVisibleNames('mesh.facultyarea', false, 'Área de Facultad') + ':'
        "
        label-for="faculty_area"
        label-cols-sm="3"
        label-align-sm="left"
        class="m-0 mb-2"
      >
        <div class="d-flex">
          <div class="d-block w-100">
            <b-form-input
              id="faculty_area-input"
              name="faculty_area-input"
              v-model="faculty_area_name"
              aria-describedby="input-faculty_area-live-feedback"
            ></b-form-input>
          </div>
          <button
            @click="createFacultyArea()"
            class="btn-add"
            v-b-tooltip.v-secondary.noninteractive="``"
          >
            <b-icon font-scale="1" icon="plus-square"></b-icon>
          </button>
        </div>
        <div class="campus-container">
          <div
            v-for="(area, index) in faculty_areas"
            :key="index"
            class="campus-div"
          >
            <TagFacultyArea
              :Faculty="faculty"
              :FacultyArea="area"
              @updated="updateFacultyArea"
              @deleted="deleteFacultyArea"
            >
            </TagFacultyArea>
          </div>
        </div>
      </b-form-group>
      <b-form-group
        :label="$getVisibleNames('mesh.campus', true, 'Sedes') + ':'"
        label-for="input-campus"
        label-cols="0"
        label-cols-sm="3"
        class="sm p-0 m-0"
      >
        <v-select
          id="input-campus"
          :options="campuses"
          v-model="$v.faculty.campuses.$model"
          :reduce="(campuses) => campuses.id"
          :selectable="(option) => !faculty.campuses.includes(option.id)"
          :state="validateState('campuses')"
          :placeholder="`${
            campuses.length > 0
              ? String(
                  'Seleccione una ' +
                    $getVisibleNames('mesh.campus', false, 'Sede').toLowerCase()
                )
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'mesh.campus',
                      true,
                      'Sedes'
                    ).toLowerCase() +
                    ' creados'
                )
          }`"
          :dropdown-should-open="dropdownShouldOpen"
          multiple
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
        </v-select>
        <b-form-invalid-feedback id="input-campuses-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>

      <!-- img -->
      <!-- <div class="flex-row" v-if="file">
        <template v-for="splited in [file.file.split('/')]">
          <b-link
            class="file-name"
            :href="file.file"
            target="_blank"
            :key="`${file.id}-${splited.length}`"
            >{{ splited[splited.length - 1] }}</b-link
          >
        </template>
        <div>
          <b-button
            class="act-button mr-1"
            v-b-tooltip.v-secondary.noninteractive="'Remover Archivo'"
            size="sm"
            @click="deleteImage(image)"
          >
            <b-icon-x-circle></b-icon-x-circle>
          </b-button>
        </div>
      </div> -->

      <b-form-file
        v-if="faculty.image == null"
        class="mt-3 mb-1"
        v-model="faculty_image"
        placeholder="Seleccione o arrastre la imagen aquí..."
        drop-placeholder="Arrastre la imagen aquí..."
        accept="image/*"
      ></b-form-file>
      <template v-else>
        <ImageEditor
          class="mt-3 mb-3"
          :key="faculty.id"
          :display_options="false"
          :portrait_size="false"
          :image_id="faculty.image"
          :allows_crud="true"
          :zoom_image="false"
          :deleted_botton="false"
          :updated_botton="true"
          @deleted="deleteImage"
        ></ImageEditor>
      </template>
      <div class="row">
        <div
          v-if="show_delete_button && !isNaN(this.career.id)"
          class="col"
          style="text-align: left"
        >
          <b-button
            class="mr-1"
            size="sm"
            variant="danger"
            @click="askForDeleteObject"
            >Eliminar</b-button
          >
        </div>
        <div v-if="show_save_button" class="col" style="text-align: right">
          <b-button class="mr-1 mt-2" size="sm" @click="save">Guardar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "FacultyForm",
  mixins: [validationMixin],
  components: {
    vSelect,
    TagFacultyArea: () => import("./TagFacultyArea"),
    ImageEditor: () => import("@/components/reusable/ImageEditor"),
  },
  props: {
    Faculty: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          campuses: [],
          color: "#14acb4",
          school: this.$store.getters.getInstitutionId,
          image: null,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      faculty_areas: [],
      faculty_area_name: "",
      faculty_image: null,
      url: null,
      faculty: { ...this.Faculty },
    };
  },
  validations: {
    faculty: {
      campuses: { required },
      name: {
        required,
        minLength: minLength(3),
      },
      color: {},
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      campuses: names.CAMPUSES,
    }),
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.faculty[key];
      return $dirty ? !$error : null;
    },
    dropdownShouldOpen(VueSelect) {
      if (this.campuses.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    save() {
      this.$v.faculty.$touch();
      if (this.$v.faculty.$anyError) {
        return;
      }
      if (this.faculty.image == null && this.faculty_image != null)
        this.createImage();
      else {
        if (isNaN(this.faculty.id)) this.createFaculty();
        else this.updateFaculty();
      }
    },
    createFaculty() {
      this.$store
        .dispatch(names.POST_FACULTY, this.faculty)
        .then((response) => {
          this.$store.dispatch(names.FETCH_CURRENT_POSITION, this.user.id);
          toast(
            String(
              this.$getVisibleNames("mesh.faculty", false, "Facultad") +
                " creada."
            )
          );
          this.bulkCreateFacultyAreas(response.id);
          // this.$emit("created", response);
        });
    },
    updateFaculty() {
      this.$store
        .dispatch(names.UPDATE_FACULTY, this.faculty)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames("mesh.faculty", false, "Facultad") +
                " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    createImage() {
      const formData = new FormData();
      formData.append("image", this.faculty_image);
      formData.append("name", this.faculty_image.name);
      this.$store.dispatch(names.POST_IMAGE, formData).then((response) => {
        this.faculty.image = response.id;
        if (isNaN(this.faculty.id)) this.createFaculty();
        else this.updateFaculty();
      });
    },
    patchFaculty(items) {
      this.$store
        .dispatch(names.PATCH_FACULTY, {
          faculty_id: this.faculty.id,
          item: items,
        })
        .then(() => {
          toast(
            String(
              this.$getVisibleNames("mesh.faculty", false, "Facultad") +
                " actualizada."
            )
          );
        });
    },
    deleteFaculty() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.faculty",
          false,
          "Facultad"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_FACULTY, this.faculty.id);
          this.$emit("deleted", this.faculty);
        }
      });
    },
    async bulkCreateFacultyAreas(faculty_id) {
      let index = 0;
      if (this.faculty_areas.length == 0) this.$emit("created");
      else
        for await (let area of this.faculty_areas) {
          await this.$restful
            .Post(`/mesh/faculty-area/`, {
              faculty: faculty_id,
              name: area.name,
            })
            .then((response) => {
              const index = this.faculty_areas.findIndex(
                (x) => x.id == area.id
              );
              if (index != -1) this.faculty_areas.splice(index, 1, response);
            });
          index += 1;
          if (this.faculty_areas.length == index) {
            this.$emit("createdAreas", this.faculty_areas);
          }
        }
    },
    createFacultyArea() {
      if (
        this.faculty_area_name.trim() != "" &&
        this.faculty_area_name != null
      ) {
        if (isNaN(this.faculty.id)) {
          this.faculty_areas.push({
            id: generateUniqueId(),
            name: this.faculty_area_name,
            faculty: this.faculty.id,
          });
        } else {
          this.$restful
            .Post(`/mesh/faculty-area/`, {
              faculty: this.faculty.id,
              name: this.faculty_area_name,
            })
            .then((response) => {
              this.$emit("createdArea", response);
              this.faculty_areas.push({
                id: response.id,
                name: response.name,
                faculty: response.faculty,
              });
            });
        }
        this.faculty_area_name = "";
      }
    },
    updateFacultyArea(faculty_area) {
      this.$emit("updatedArea", faculty_area);
    },
    deleteFacultyArea(area) {
      if (isNaN(this.faculty.id)) {
        const index = this.faculty_areas.findIndex((x) => x.id == area.id);
        if (index != -1) this.faculty_areas.splice(index, 1);
      } else {
        if (isNaN(area.id)) {
          const index = this.faculty_areas.findIndex((x) => x.id == area.id);
          if (index != -1) this.faculty_areas.splice(index, 1);
        } else {
          this.$restful.Delete(`/mesh/faculty-area/${area.id}/`).then(() => {
            this.$emit("deletedArea", area.id);
            const index = this.faculty_areas.findIndex((x) => x.id == area.id);
            if (index != -1) this.faculty_areas.splice(index, 1);
          });
        }
      }
    },
    deleteImage() {
      this.faculty.image = null;
      this.patchFaculty({ image: this.faculty.image });
    },
    fetchFacultyArea() {
      this.$restful
        .Get(`/mesh/faculty-area/?faculty=${this.faculty.id}`)
        .then((response) => {
          this.faculty_areas = response;
        });
    },
  },
  watch: {
    faculty_image(newFile) {
      this.url = URL.createObjectURL(this.faculty_image);
      if (newFile && !newFile.type.startsWith("image/")) {
        this.$nextTick(() => {
          this.faculty_image = null;
        });
      }
    },
  },
  created() {
    if (!isNaN(this.faculty.id)) this.fetchFacultyArea();
    this.$store.dispatch(names.FETCH_CAMPUSES);
  },
};
</script>

<style scoped>
.btn-add {
  border: none;
  background: transparent;
  font-size: 13pt;
  padding: 0;
  margin-left: 0.7rem;
}
.btn-div {
  color: white;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  /* text-align: center; */
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
</style>