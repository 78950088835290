<template>
  <div class="d-flex">
    <div class="w-100">
      <b-form-input
        v-model="epm_time_allocation.hours"
        @update="
          (value) => {
            if (parseFloat(value) < 0 || value == null || value == '') {
              epm_time_allocation.hours = 0;
              patchEPMTimeAllocation();
            } else {
              epm_time_allocation.hours = value;
              patchEPMTimeAllocation();
            }
          }
        "
        size="sm"
      >
      </b-form-input>
    </div>
    <b-form-checkbox
      class="mt-2 ml-2"
      size="lg"
      v-model="epm_time_allocation.counts_towards_credits"
      @input="patchEPMTimeAllocation()"
    ></b-form-checkbox>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { generateUniqueId, toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "InputEPMTimeAllocation",
  components: {},
  props: {
    EPMTimeAllocation: {
      default: function () {
        return {
          id: generateUniqueId(),
          egress_profile_matter: null,
          time_allocation: null,
          counts_towards_credits: false,
          attendance_requirement: 0,
          hours: 0,
        };
      },
    },
    TimeAllocation: {
      type: Object,
      required: true,
    },
    Matter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      epm_time_allocation: { ...this.EPMTimeAllocation },
    };
  },
  computed: {
    ...mapGetters({
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
    }),
  },
  methods: {
    patchEPMTimeAllocation() {
      if (this.timeout) clearTimeout(this.timeout);
      if (
        parseFloat(this.epm_time_allocation.hours) < 0 ||
        this.epm_time_allocation.hours == null ||
        this.epm_time_allocation.hours == ""
      )
        return;
      else if (this.epm_time_allocation.hours >= 0) {
        this.timeout = setTimeout(() => {
          this.epm_time_allocation.egress_profile_matter = this.Matter.id;
          this.epm_time_allocation.time_allocation = this.TimeAllocation.id;
          if (isNaN(this.epm_time_allocation.id))
            this.createEPMTimeAllocation();
          else this.updateEPMTimeAllocation();
        }, 500);
      }
    },
    createEPMTimeAllocation() {
      this.epm_time_allocation.counts_towards_credits =
        this.TimeAllocation.counts_towards_credits;
      this.epm_time_allocation.attendance_requirement =
        this.TimeAllocation.attendance_requirement;
      this.$store
        .dispatch(
          names.POST_EP_MATTER_TIME_ALLOCATION,
          this.epm_time_allocation
        )
        .then((response) => {
          this.epm_time_allocation = response;
          toast("Cambio realizado");
          this.$emit("changed_hour");
        });
    },
    updateEPMTimeAllocation() {
      this.$store
        .dispatch(
          names.UPDATE_EP_MATTER_TIME_ALLOCATION,
          this.epm_time_allocation
        )
        .then((response) => {
          this.epm_time_allocation = response;
          toast("Cambio realizado");
          this.$emit("changed_hour");
        });
    },
  },
  mounted() {},
  // watch: {
  //   "epm_time_allocation.hours": function (n, o) {
  //     if (parseFloat(n) < 0 || n == null || n == "" || n == o) return;
  //     else if (n >= 0) this.patchEPMTimeAllocation();
  //   },
  //   "epm_time_allocation.counts_towards_credits": function () {
  //     this.patchEPMTimeAllocation();
  //   },
  // },
  created() {
    const epm_time_allocation = this.ep_matter_time_allocations.find(
      (x) =>
        x.time_allocation == this.TimeAllocation.id &&
        x.egress_profile_matter == this.Matter.id
    );
    if (epm_time_allocation) this.epm_time_allocation = epm_time_allocation;
  },
};
</script>

<style scoped>
input {
  padding: 1px 1px 1px 5px;
  height: 30px;
  max-width: 50px;
  font-size: var(--secondary-font-size);
}
.inputNumber {
  width: 70px;
  justify-content: center;
}
</style>