<template>
  <div v-if="Sentence">
    <p v-if="!boundedTextContainer" :id="`order-hint-${unique_id}`">
      <span
        :class="{ verb: cognitiveLevel }"
        :id="`sentence-action-${unique_id}`"
        ><u>{{ String(Sentence.action).trim() }}</u></span
      >
      {{
        Sentence.full_sentence
          ? String(Sentence.full_sentence)
              .substr(String(Sentence.full_sentence).indexOf(" ") + 1)
              .trim() ==
            String(Sentence.full_sentence.trim().split(" ").shift())
            ? ""
            : String(Sentence.full_sentence).substr(
                String(Sentence.full_sentence).indexOf(" ") + 1
              )
          : ""
      }}
    </p>
    <p v-else :id="`order-hint-${unique_id}`">
      <span
        :class="{ verb: cognitiveLevel }"
        :id="`sentence-action-${unique_id}`"
        ><u>{{ Sentence.action }} </u></span
      >
      <BoundedTextContainer
        :Text="fullSentenceText"
        :Large="Large"
        :tooltip_text="tooltipText"
      ></BoundedTextContainer>
    </p>
    <b-tooltip
      v-if="cognitiveLevel"
      class="hint"
      variant="secondary"
      delay="300"
      :target="`sentence-action-${unique_id}`"
    >
      {{ $getVisibleNames("mesh.cognitivelevel", false, "Nivel Cognitivo") }}
      {{ cognitiveLevel.level }}:
      {{ cognitiveLevel.name }}
    </b-tooltip>
    <b-tooltip
      v-else
      class="hint"
      variant="secondary"
      delay="300"
      :target="`sentence-action-${unique_id}`"
      placement="top"
    >
      Verbo sin nivel cognitivo.
    </b-tooltip>
    <b-tooltip
      v-if="order_hint"
      variant="danger"
      delay="300"
      :target="`order-hint-${unique_id}`"
      placement="bottom"
    >
      Este
      {{ $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso") }}
      ya cuenta con
      {{ $getVisibleNames("mesh.competence", false, "Competencia") }} en ese
      orden.
    </b-tooltip>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";

export default {
  components: {
    BoundedTextContainer: () =>
      import("@/components/reusable/BoundedTextContainer"),
  },
  props: {
    Sentence: {
      type: Object,
      required: true,
    },
    order_hint: {
      type: Boolean,
      default: false,
    },
    boundedTextContainer: {
      type: Boolean,
      default: false,
    },
    Large: {
      type: Number,
      default: 100,
    },
  },

  data() {
    return {
      unique_id: generateUniqueId(),
      tooltip_text: "",
    };
  },
  computed: {
    ...mapGetters({
      taxonomy: names.TAXONOMY,
    }),
    fullSentenceText() {
      if (this.Sentence.full_sentence) {
        if (
          String(this.Sentence.full_sentence)
            .substr(String(this.Sentence.full_sentence).indexOf(" ") + 1)
            .trim() ==
          String(this.Sentence.full_sentence.trim().split(" ").shift())
        ) {
          return "";
        } else {
          return String(this.Sentence.full_sentence).substr(
            String(this.Sentence.full_sentence).indexOf(" ") + 1
          );
        }
      } else {
        return "";
      }
    },
    tooltipText() {
      if (this.Sentence.full_sentence) {
        if (
          String(this.Sentence.full_sentence)
            .substr(String(this.Sentence.full_sentence).indexOf(" ") + 1)
            .trim() ==
          String(this.Sentence.full_sentence.trim().split(" ").shift())
        ) {
          return "";
        } else {
          return String(this.Sentence.full_sentence);
        }
      } else {
        return "";
      }
    },
    cognitiveLevel() {
      if (!this.taxonomy && !this.Sentence) return null;
      if (!this.Sentence.action) return null;
      let flag = true;
      let level = null;
      if (this.taxonomy) {
        this.taxonomy.cognitive_levels.forEach((cognitive_level) => {
          cognitive_level.verbs.forEach((instance_verb) => {
            if (
              (instance_verb.id == this.Sentence.verb ||
                instance_verb.name.toLowerCase() ==
                  this.Sentence.action.toLowerCase()) &&
              flag
            ) {
              flag = false;
              level = cognitive_level;
            }
          });
        });
      }
      return level;
    },
  },
  methods: {
    // hideTooltipLater() {
    //   setTimeout(() => {
    //     this.showTooltip = false;
    //   }, 3000);
    // },
  },
};
</script>

<style scoped>
.sentenceContainer {
  padding: 2px;
  top: 0;
  white-space: nowrap;
  font-size: 16px;
}

p {
  text-align: left;
  margin-left: 2px;
  padding-left: 10px;
  margin-right: 2px;
  padding-right: 10px;
  font-size: var(--secondary-font-size);
}

.verb {
  transition: ease-in-out 0.3s;
}

.verb:hover {
  font-weight: bold;
  color: #0d1525;
  background-color: #ebebeb;
  /* padding: 0 5px; */
  transition: 0.2s;
  cursor: help;
}
</style>