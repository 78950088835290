<template>
  <div>
    <div class="mx-2">
      <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_mattertype'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-matter-type-modal`)"
          >
            Agregar
            {{
              $getVisibleNames("mesh.mattertype", false, "Tipo De Asignatura")
            }}
            <!-- Tipo de Asignatura -->
          </button>
        </div>
      </div>
      <h3>
        {{ $getVisibleNames("mesh.mattertype", true, "Tipos de Asignatura") }}
      </h3>
      <GenericBTable
        v-if="searchMatterType.length > 0"
        class="matter-type-table"
        :items="matter_types"
        :pagination="matter_types.length"
        :fields="matterTypesFields"
        :filter="input_search"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="display_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            @click="$bvModal.show(`edit-matter-type-modal-${row.item.id}`)"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.mattertype',
                false,
                'Tipo De Asignatura'
              )}`
            "
          ></button-edit>
          <button-delete
            @click="askForDeleteMatterType(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'mesh.mattertype',
                false,
                'Tipo De Asignatura'
              )}`
            "
          ></button-delete>
          <b-modal
            :id="`edit-matter-type-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.mattertype',
              false,
              'Tipo De Asignatura'
            )}`"
            size="md"
            hide-footer
          >
            <MatterTypeForm
              :MatterType="row.item"
              @updated="slotUpdatedMatterType"
              :institution_id="row.item.school"
              :show_title="false"
            ></MatterTypeForm>
          </b-modal>
        </template>
      </GenericBTable>
      <div v-else>
        <br />
        <!-- tipo de asignatura -->
        <strong
          >No se a encontrado ningún
          {{
            $getVisibleNames(
              "mesh.mattertype",
              false,
              "Tipo De Asignatura"
            ).toLowerCase()
          }}.</strong
        >
      </div>
    </div>
    <b-modal
      :id="`new-matter-type-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.mattertype',
        false,
        'Tipo De Asignatura'
      )}`"
      size="md"
    >
      <MatterTypeForm
        @created="slotCreatedMatterType"
        :show_title="false"
        :institution_id="this.user.school"
      ></MatterTypeForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "MatterTypeView",
  components: {
    MatterTypeForm: () =>
      import("@/components/mesh/CurricularResources/MatterType/MatterTypeForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    input_search: {
      type: String,
      default: "",
    },
    display_id: {},
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      matter_types: names.MATTER_TYPES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_mattertype");
      if (has_permission) return has_permission;
      else return false;
    },
    breadcrumb_items() {
      return [
        { text: "Definiciones Curriculares", active: true },
        {
          text: this.$getVisibleNames(
            "mesh.mattertype",
            true,
            "Tipos de Asignatura"
          ),
          active: true,
        },
      ];
    },
    matter_types_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.mattertype",
              true,
              "Tipos de Asignatura"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
    searchMatterType: function () {
      return this.matter_types
        .filter((item) => {
          return (
            item.name.toLowerCase().includes(this.input_search.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(this.input_search.toLowerCase())
          );
        })
        .sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
    matterTypesFields() {
      if (!this.allows_crud)
        return this.matter_types_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.matter_types_fields;
    },
  },
  methods: {
    askForDeleteMatterType(matter_type_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.mattertype",
          false,
          "Tipo De Asignatura"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_MATTER_TYPE, matter_type_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.mattertype",
                    false,
                    "Tipo De Asignatura"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    slotCreatedMatterType() {
      this.$bvModal.hide("new-matter-type-modal");
    },
    slotUpdatedMatterType(matter_type) {
      this.$bvModal.hide(`edit-matter-type-modal-${matter_type.id}`);
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch(names.FETCH_MATTER_TYPES, this.user.school);
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped>
.td-actions {
  max-width: 100px !important;
}
.title-header {
  padding: 0 1rem;
  margin-top: auto;
}
.search-matter-type {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
</style>