<template>
  <div class="learning-experiences-container">
    <b-table-simple
      bordered
      fixed
      no-border-collapse
      small
      responsive
      sticky-header="1000px"
      class="txt-custom"
    >
      <b-thead>
        <b-tr>
          <b-th class="text-center primary-header" colspan="3"
            >EXPERIENCIA DE APRENDIZAJE
          </b-th>
          <b-th class="text-center primary-header" colspan="12"
            >ACTIVIDADES Y EVALUACIONES SUMATIVAS Y FORMATIVAS</b-th
          >
        </b-tr>
        <b-tr>
          <b-th class="text-center align-middle secondary-header"
            >N° y NOMBRE EA</b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >DESCRIPCIÓN (Propósito, metodología, evaluaciones, ambientes,
            tiempos y recursos)</b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >Resultado(s) de Aprendizaje</b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >N° y NOMBRE ACTIVIDAD DOCENCIA DIRIGIDA</b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >Descripción breve DIRIGIDO AL DOCENTE</b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >Indicador(es) de logro</b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >Tiempo horas docencia dirigida <br />
            <span class="font-weight-normal">(Horas pedagógicas)</span></b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >Actividad de trabajo autónomo del/la estudiante</b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >Tiempo horas trabajo autónomo <br />
            <span class="font-weight-normal">(Horas cronológicas)</span></b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >Recursos de aprendizaje <br />
            <span class="font-weight-normal"
              >(PPT Interactiva, Videos, Tutoriales, Links, Podcast, etc)<br />(Guías,
              Instrucciones, Pautas de Evaluación)</span
            ></b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >Tecnología educativa <br />
            <span class="font-weight-normal">
              Kaltura, C1DO1, Software, Aplicaciones Web (MIRO, Mentimeter,
              Jamboard)</span
            ></b-th
          >
          <b-th class="text-center align-middle secondary-header">
            BIBLIOGRAFÍA OBLIGATORIA <br />
            <span class="font-weight-normal"
              >Incluir n°capítulos, y n°páginas.</span
            >
          </b-th>
          <b-th class="text-center align-middle secondary-header"
            >BIBLIOGRAFÍA COMPLEMENTARIA</b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >AMBIENTES DE APRENDIZAJE</b-th
          >
          <b-th class="text-center align-middle secondary-header"
            >Nº ESTUDIANTES</b-th
          >
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-if="
            learning_experiences &&
            (learning_experiences.learning_experiences.length > 0 ||
              learning_experiences.transversal_exams.length > 0)
          "
        >
          <template
            v-for="(
              learning_experience, index
            ) in learning_experiences.learning_experiences"
          >
            <template
              v-if="
                learning_experience.study_units.length > 0 ||
                learning_experience.epm_evaluations.length > 0
              "
            >
              <template v-if="learning_experience.study_units.length == 0">
                <b-tr
                  v-for="(
                    epm_evaluation, index2
                  ) in learning_experience.epm_evaluations"
                  :key="
                    'learning_experience' + index + 'epm-evaluation' + index2
                  "
                >
                  <b-td
                    v-if="index2 == 0"
                    :rowspan="learning_experience.epm_evaluations.length"
                    style="vertical-align: middle; text-align: justify"
                  >
                    <!-- N° y NOMBRE EA -->
                    {{
                      "EA " +
                      learning_experience.order +
                      " - " +
                      learning_experience.name
                    }}</b-td
                  >
                  <b-td
                    v-if="index2 == 0"
                    :rowspan="learning_experience.epm_evaluations.length"
                    style="vertical-align: middle; text-align: justify"
                  >
                    <div
                      class="text-break"
                      @click="(e) => e.target.classList.toggle('text-break')"
                    >
                      <!-- DESCRIPCIÓN  -->
                      <template v-if="learning_experience.description">
                        <div
                          :id="`popover-learning_experience.description-${index}-${index2}`"
                          class="rich-text-content"
                          v-html="learning_experience.description"
                        ></div>
                        <b-popover
                          :target="`popover-learning_experience.description-${index}-${index2}`"
                          triggers="hover"
                          placement="right"
                        >
                          <div
                            class="rich-text-content popover-container"
                            v-html="learning_experience.description"
                          ></div>
                        </b-popover>
                      </template>
                      <template v-else>
                        <div>N/A</div>
                      </template>
                    </div>
                  </b-td>
                  <b-td
                    v-if="index2 == 0"
                    :rowspan="learning_experience.epm_evaluations.length"
                  >
                    <!-- Resultado(s) de Aprendizaje -->
                  </b-td>
                  <b-td>
                    <!-- N° y NOMBRE ACTIVIDAD DOCENCIA DIRIGIDA -->
                    <span v-if="epm_evaluation.type_name == 'Actividad'"
                      >Act {{ learning_experience.order }}.{{
                        epm_evaluation.order
                      }}
                      - {{ epm_evaluation.name }}</span
                    >
                    <template v-else>
                      <span
                        >Ev {{ epm_evaluation.type_name }}
                        {{ learning_experience.order }}.{{
                          epm_evaluation.order
                        }}
                        - {{ epm_evaluation.name }}</span
                      >
                    </template></b-td
                  >
                  <b-td>
                    <div
                      class="text-break"
                      @click="(e) => e.target.classList.toggle('text-break')"
                    >
                      <!-- Descripción breve DIRIGIDO AL DOCENTE -->
                      <template v-if="epm_evaluation.description">
                        <div
                          :id="`popover-epm_evaluation.description-${index}-${index2}`"
                          class="rich-text-content"
                          v-html="epm_evaluation.description"
                        ></div>
                        <b-popover
                          :target="`popover-epm_evaluation.description-${index}-${index2}`"
                          triggers="hover"
                          placement="right"
                        >
                          <div
                            class="rich-text-content popover-container"
                            v-html="epm_evaluation.description"
                          ></div>
                        </b-popover>
                      </template>
                      <template v-else>
                        <div>N/A</div>
                      </template>
                    </div>
                  </b-td>
                  <b-td
                    :class="[
                      epm_evaluation.evaluation_criterias_micro.length > 0
                        ? 'text-left'
                        : 'text-center',
                    ]"
                  >
                    <!-- Indicador(es) de logro -->
                    <template
                      v-if="
                        epm_evaluation.evaluation_criterias_micro.length > 0
                      "
                    >
                      <template
                        v-for="indicator in epm_evaluation.evaluation_criterias_micro"
                        class="indicator-content"
                      >
                        <b-badge
                          :key="indicator.id"
                          :id="`ec-badge-${indicator.id}`"
                          class="ec-micro-badge mr-1"
                          variant="info"
                          v-b-tooltip.v-secondary.noninteractive="
                            `${indicator.full_sentence}`
                          "
                        >
                          {{ indicator.study_unit__order }}.{{
                            indicator.order
                          }}
                        </b-badge>
                      </template>
                    </template>
                    <template v-else>
                      <div>N/A</div>
                    </template>
                  </b-td>
                  <b-td>
                    <!-- Tiempo horas docencia dirigida -->
                    {{
                      epm_evaluation.suggested_hours
                        ? epm_evaluation.suggested_hours
                        : "-"
                    }}
                  </b-td>
                  <b-td class="text-justify">
                    <!-- Actividad de trabajo autónomo del/la estudiante -->
                    <template
                      v-if="
                        epm_evaluation.epm_evaluation_autonomous_activities
                          .length > 0
                      "
                    >
                      <p
                        v-for="learning_resource in epm_evaluation.epm_evaluation_autonomous_activities"
                        :key="learning_resource.id"
                        variant="info"
                        class="mr-1"
                        v-html="learning_resource.name"
                      ></p>
                    </template>
                  </b-td>
                  <b-td>
                    <!-- Tiempo horas trabajo autónomo -->
                    {{
                      epm_evaluation.autonomous_work_hours
                        ? epm_evaluation.autonomous_work_hours
                        : "-"
                    }}
                  </b-td>
                  <b-td>
                    <!-- Recursos de aprendizaje -->
                    <template
                      v-if="
                        epm_evaluation.epm_evaluation_learning_resources
                          .length > 0
                      "
                    >
                      <div
                        v-for="learning_resource in epm_evaluation.epm_evaluation_learning_resources"
                        :key="learning_resource.id"
                      >
                        <div v-html="learning_resource.name"></div>
                      </div>
                    </template>
                    <template v-else>N/A</template>
                  </b-td>
                  <b-td>
                    <!-- Tecnología educativa  -->
                    <template
                      v-if="
                        epm_evaluation.epm_evaluation_educational_technology
                          .length > 0
                      "
                    >
                      <div
                        v-for="epm_evaluation_educational_technology in epm_evaluation.epm_evaluation_educational_technology"
                        :key="epm_evaluation_educational_technology.id"
                      >
                        <div
                          v-html="epm_evaluation_educational_technology.name"
                        ></div>
                      </div>
                    </template>
                    <template v-else>N/A</template>
                  </b-td>
                  <b-td class="bibliography-table-cell">
                    <!-- BIBLIOGRAFÍA OBLIGATORIA -->
                    <template
                      v-if="
                        epm_evaluation.epm_evaluation_mandatory_bibliography
                          .length > 0
                      "
                    >
                      <template
                        v-for="epm_evaluation_mandatory in epm_evaluation.epm_evaluation_mandatory_bibliography"
                      >
                        <div
                          :key="'mandatory' + epm_evaluation_mandatory.id"
                          v-html="
                            epm_evaluation_mandatory.bibliographic_resource__description
                          "
                          class="rich-text-content bibliography-content"
                        ></div>
                        <div
                          :key="
                            'mandatory-details' + epm_evaluation_mandatory.id
                          "
                          class="bibliography-details"
                        >
                          {{ epm_evaluation_mandatory.details }}
                        </div>
                        <BoundedRichText
                          class="bounded-biblio-content"
                          :key="
                            'bounded-biblio-mandatory' +
                            epm_evaluation_mandatory.id
                          "
                          :text="
                            epm_evaluation_mandatory.bibliographic_resource__description
                          "
                          :drop_text="epm_evaluation_mandatory.details"
                          :large="18"
                          :content_width="400"
                        ></BoundedRichText>
                      </template>
                    </template>
                    <template v-else>N/A</template>
                  </b-td>
                  <b-td>
                    <!-- BIBLIOGRAFÍA COMPLEMENTARIA -->
                    <template
                      v-if="
                        epm_evaluation.epm_evaluation_supplementary_bibliography
                          .length > 0
                      "
                    >
                      <template
                        v-for="epm_evaluation_supplementary in epm_evaluation.epm_evaluation_supplementary_bibliography"
                      >
                        <div
                          :key="
                            'supplementary' + epm_evaluation_supplementary.id
                          "
                          v-html="
                            epm_evaluation_supplementary.bibliographic_resource__description
                          "
                          class="rich-text-content bibliography-content"
                        ></div>
                        <div
                          :key="
                            'supplementary-details' +
                            epm_evaluation_supplementary.id
                          "
                          class="bibliography-details"
                        >
                          {{ epm_evaluation_supplementary.details }}
                        </div>
                        <BoundedRichText
                          class="bounded-biblio-content"
                          :key="
                            'bounded-biblio-supplementary' +
                            epm_evaluation_supplementary.id
                          "
                          :text="
                            epm_evaluation_supplementary.bibliographic_resource__description
                          "
                          :drop_text="epm_evaluation_supplementary.details"
                          :large="18"
                          :content_width="400"
                        ></BoundedRichText>
                      </template>
                    </template>
                    <template v-else>N/A</template>
                  </b-td>
                  <b-td
                    :class="[
                      epm_evaluation.study_environments.length > 0
                        ? 'text-left'
                        : 'text-center',
                    ]"
                  >
                    <!-- AMBIENTES DE APRENDIZAJE -->
                    <template
                      v-if="epm_evaluation.study_environments.length > 0"
                    >
                      <div
                        v-for="environment in epm_evaluation.study_environments"
                        :key="environment.id"
                        variant="info"
                        class="mr-1"
                      >
                        - {{ environment.name }}
                      </div>
                    </template>
                    <template v-else>N/A</template>
                  </b-td>
                  <b-td>
                    <!-- Nº ESTUDIANTES -->
                    {{
                      epm_evaluation.suggested_students
                        ? epm_evaluation.suggested_students
                        : "-"
                    }}
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <template
                  v-for="(
                    study_unit, index2
                  ) in learning_experience.study_units"
                >
                  <template v-if="study_unit.epm_evaluations.length > 0">
                    <b-tr
                      v-for="(
                        epm_evaluation, index3
                      ) in study_unit.epm_evaluations"
                      :key="
                        'learning_experience' +
                        index +
                        'study_unit' +
                        index2 +
                        'epm-evaluation' +
                        index3
                      "
                    >
                      <b-td
                        v-if="index2 == 0 && index3 == 0"
                        :rowspan="
                          StudySuEpmEvaluationCount(learning_experience.id) +
                          learning_experience.epm_evaluations.length
                        "
                        style="vertical-align: middle; text-align: justify"
                      >
                        <!-- N° y NOMBRE EA -->
                        {{
                          "EA " +
                          learning_experience.order +
                          " - " +
                          learning_experience.name
                        }}
                      </b-td>
                      <b-td
                        v-if="index2 == 0 && index3 == 0"
                        :rowspan="
                          StudySuEpmEvaluationCount(learning_experience.id) +
                          learning_experience.epm_evaluations.length
                        "
                        style="vertical-align: middle; text-align: justify"
                      >
                        <div
                          class="text-break"
                          @click="
                            (e) => e.target.classList.toggle('text-break')
                          "
                        >
                          <!-- DESCRIPCIÓN  -->
                          <template v-if="learning_experience.description">
                            <div
                              :id="`popover-learning_experience.description-${index}-${index2}-${index3}`"
                              class="rich-text-content"
                              v-html="learning_experience.description"
                            ></div>
                            <b-popover
                              :target="`popover-learning_experience.description-${index}-${index2}-${index3}`"
                              triggers="hover"
                              placement="right"
                            >
                              <div
                                class="rich-text-content popover-container"
                                v-html="learning_experience.description"
                              ></div>
                            </b-popover>
                          </template>
                          <template v-else>
                            <div>N/A</div>
                          </template>
                        </div>
                      </b-td>
                      <b-td
                        v-if="index2 == 0 && index3 == 0"
                        :rowspan="
                          StudySuEpmEvaluationCount(learning_experience.id) +
                          learning_experience.epm_evaluations.length
                        "
                        style="vertical-align: middle; text-align: justify"
                      >
                        <!-- Resultado(s) de Aprendizaje -->
                        <div
                          v-for="(
                            item, index4
                          ) in learning_experience.study_units"
                          :key="
                            'learning_experience' +
                            index +
                            'study_unit' +
                            index2 +
                            'epm-evaluation' +
                            index3 +
                            '-' +
                            index4
                          "
                        >
                          {{ "RA" + item.order + ". " + item.full_sentence }}
                        </div>
                      </b-td>
                      <b-td>
                        <!-- N° y NOMBRE ACTIVIDAD DOCENCIA DIRIGIDA -->
                        <span v-if="epm_evaluation.type_name == 'Actividad'"
                          >Act {{ learning_experience.order }}.{{
                            epm_evaluation.order
                          }}
                          - {{ epm_evaluation.name }}</span
                        >
                        <template v-else>
                          <span
                            >Ev {{ epm_evaluation.type_name }}
                            {{ learning_experience.order }}.{{
                              epm_evaluation.order
                            }}
                            - {{ epm_evaluation.name }}</span
                          >
                        </template></b-td
                      >
                      <b-td>
                        <div
                          class="text-break"
                          @click="
                            (e) => e.target.classList.toggle('text-break')
                          "
                        >
                          <!-- Descripción breve DIRIGIDO AL DOCENTE -->
                          <template v-if="epm_evaluation.description">
                            <div
                              :id="`popover-epm_evaluation.description-${index}-${index2}-${index3}`"
                              class="rich-text-content"
                              v-html="epm_evaluation.description"
                            ></div>
                            <b-popover
                              :target="`popover-epm_evaluation.description-${index}-${index2}-${index3}`"
                              triggers="hover"
                              placement="right"
                            >
                              <div
                                class="rich-text-content popover-container"
                                v-html="epm_evaluation.description"
                              ></div>
                            </b-popover>
                          </template>
                          <template v-else>
                            <div>N/A</div>
                          </template>
                        </div>
                      </b-td>
                      <b-td
                        :class="[
                          epm_evaluation.evaluation_criterias_micro.length > 0
                            ? 'text-left'
                            : 'text-center',
                        ]"
                      >
                        <!-- Indicador(es) de logro -->
                        <template
                          v-if="
                            epm_evaluation.evaluation_criterias_micro.length > 0
                          "
                        >
                          <template
                            v-for="indicator in epm_evaluation.evaluation_criterias_micro"
                            class="indicator-content"
                          >
                            <b-badge
                              :key="indicator.id"
                              :id="`ec-badge-${indicator.id}`"
                              class="ec-micro-badge mr-1"
                              variant="info"
                              v-b-tooltip.v-secondary.noninteractive="
                                `${indicator.full_sentence}`
                              "
                            >
                              {{ indicator.study_unit__order }}.{{
                                indicator.order
                              }}
                            </b-badge>
                          </template>
                        </template>
                        <template v-else>
                          <div>N/A</div>
                        </template>
                      </b-td>
                      <b-td>
                        <!-- Tiempo horas docencia dirigida -->
                        {{
                          epm_evaluation.suggested_hours
                            ? epm_evaluation.suggested_hours
                            : "-"
                        }}
                      </b-td>
                      <b-td class="text-justify">
                        <!-- Actividad de trabajo autónomo del/la estudiante -->
                        <template
                          v-if="
                            epm_evaluation.epm_evaluation_autonomous_activities
                              .length > 0
                          "
                        >
                          <p
                            v-for="learning_resource in epm_evaluation.epm_evaluation_autonomous_activities"
                            :key="learning_resource.id"
                            variant="info"
                            class="mr-1"
                            v-html="learning_resource.name"
                          ></p>
                        </template>
                      </b-td>
                      <b-td>
                        <!-- Tiempo horas trabajo autónomo -->
                        {{
                          epm_evaluation.autonomous_work_hours
                            ? epm_evaluation.autonomous_work_hours
                            : "-"
                        }}
                      </b-td>
                      <b-td>
                        <!-- Recursos de aprendizaje -->
                        <template
                          v-if="
                            epm_evaluation.epm_evaluation_learning_resources
                              .length > 0
                          "
                        >
                          <div
                            v-for="learning_resource in epm_evaluation.epm_evaluation_learning_resources"
                            :key="learning_resource.id"
                          >
                            <div v-html="learning_resource.name"></div>
                          </div>
                        </template>
                        <template v-else>N/A</template>
                      </b-td>
                      <b-td>
                        <!-- Tecnología educativa  -->
                        <template
                          v-if="
                            epm_evaluation.epm_evaluation_educational_technology
                              .length > 0
                          "
                        >
                          <div
                            v-for="epm_evaluation_educational_technology in epm_evaluation.epm_evaluation_educational_technology"
                            :key="epm_evaluation_educational_technology.id"
                          >
                            <div
                              v-html="
                                epm_evaluation_educational_technology.name
                              "
                            ></div>
                          </div>
                        </template>
                        <template v-else>N/A</template>
                      </b-td>
                      <b-td class="bibliography-table-cell">
                        <!-- BIBLIOGRAFÍA OBLIGATORIA -->
                        <template
                          v-if="
                            epm_evaluation.epm_evaluation_mandatory_bibliography
                              .length > 0
                          "
                        >
                          <template
                            v-for="epm_evaluation_mandatory in epm_evaluation.epm_evaluation_mandatory_bibliography"
                          >
                            <div
                              :key="'mandatory' + epm_evaluation_mandatory.id"
                              v-html="
                                epm_evaluation_mandatory.bibliographic_resource__description
                              "
                              class="rich-text-content bibliography-content"
                            ></div>
                            <div
                              :key="
                                'mandatory-details' +
                                epm_evaluation_mandatory.id
                              "
                              class="bibliography-details"
                            >
                              {{ epm_evaluation_mandatory.details }}
                            </div>
                            <BoundedRichText
                              class="bounded-biblio-content"
                              :key="
                                'bounded-biblio-mandatory' +
                                epm_evaluation_mandatory.id
                              "
                              :text="
                                epm_evaluation_mandatory.bibliographic_resource__description
                              "
                              :drop_text="epm_evaluation_mandatory.details"
                              :large="18"
                              :content_width="400"
                            ></BoundedRichText>
                          </template>
                        </template>
                        <template v-else>N/A</template>
                      </b-td>
                      <b-td>
                        <!-- BIBLIOGRAFÍA COMPLEMENTARIA -->
                        <template
                          v-if="
                            epm_evaluation
                              .epm_evaluation_supplementary_bibliography
                              .length > 0
                          "
                        >
                          <template
                            v-for="epm_evaluation_supplementary in epm_evaluation.epm_evaluation_supplementary_bibliography"
                          >
                            <div
                              :key="
                                'supplementary' +
                                epm_evaluation_supplementary.id
                              "
                              v-html="
                                epm_evaluation_supplementary.bibliographic_resource__description
                              "
                              class="rich-text-content bibliography-content"
                            ></div>
                            <div
                              :key="
                                'supplementary-details' +
                                epm_evaluation_supplementary.id
                              "
                              class="bibliography-details"
                            >
                              {{ epm_evaluation_supplementary.details }}
                            </div>
                            <BoundedRichText
                              class="bounded-biblio-content"
                              :key="
                                'bounded-biblio-supplementary' +
                                epm_evaluation_supplementary.id
                              "
                              :text="
                                epm_evaluation_supplementary.bibliographic_resource__description
                              "
                              :drop_text="epm_evaluation_supplementary.details"
                              :large="18"
                              :content_width="400"
                            ></BoundedRichText>
                          </template>
                        </template>
                        <template v-else>N/A</template>
                      </b-td>
                      <b-td
                        :class="[
                          epm_evaluation.study_environments.length > 0
                            ? 'text-left'
                            : 'text-center',
                        ]"
                      >
                        <!-- AMBIENTES DE APRENDIZAJE -->
                        <template
                          v-if="epm_evaluation.study_environments.length > 0"
                        >
                          <div
                            v-for="environment in epm_evaluation.study_environments"
                            :key="environment.id"
                            variant="info"
                            class="mr-1"
                          >
                            - {{ environment.name }}
                          </div>
                        </template>
                        <template v-else>N/A</template>
                      </b-td>
                      <b-td>
                        <!-- Nº ESTUDIANTES -->
                        {{
                          epm_evaluation.suggested_students
                            ? epm_evaluation.suggested_students
                            : "-"
                        }}
                      </b-td>
                    </b-tr>
                  </template>
                  <template v-else>
                    <b-tr
                      v-if="
                        StudySuEpmEvaluationCount(learning_experience.id) > 0 &&
                        study_unit.epm_evaluations.length == 0 &&
                        index2 == 0
                      "
                      :key="
                        'learning_experience' +
                        index +
                        'study_unit' +
                        index2 +
                        'epm-evaluation'
                      "
                    >
                      <b-td
                        v-if="index2 == 0"
                        :rowspan="
                          StudySuEpmEvaluationCount(learning_experience.id) +
                          learning_experience.epm_evaluations.length +
                          1
                        "
                        style="vertical-align: middle; text-align: justify"
                      >
                        <!-- N° y NOMBRE EA -->
                        {{
                          "EA " +
                          learning_experience.order +
                          " - " +
                          learning_experience.name
                        }}
                      </b-td>
                      <b-td
                        v-if="index2 == 0"
                        :rowspan="
                          StudySuEpmEvaluationCount(learning_experience.id) +
                          learning_experience.epm_evaluations.length +
                          1
                        "
                        style="vertical-align: middle; text-align: justify"
                      >
                        <div
                          class="text-break"
                          @click="
                            (e) => e.target.classList.toggle('text-break')
                          "
                        >
                          <!-- DESCRIPCIÓN  -->
                          <template v-if="learning_experience.description">
                            <div
                              :id="`popover-learning_experience.description-${index}-${index2}-`"
                              class="rich-text-content"
                              v-html="learning_experience.description"
                            ></div>
                            <b-popover
                              :target="`popover-learning_experience.description-${index}-${index2}-`"
                              triggers="hover"
                              placement="right"
                            >
                              <div
                                class="rich-text-content popover-container"
                                v-html="learning_experience.description"
                              ></div>
                            </b-popover>
                          </template>
                          <template v-else>
                            <div>N/A</div>
                          </template>
                        </div>
                      </b-td>
                      <b-td
                        v-if="index2 == 0"
                        :rowspan="
                          StudySuEpmEvaluationCount(learning_experience.id) +
                          learning_experience.epm_evaluations.length +
                          1
                        "
                        style="vertical-align: middle; text-align: justify"
                      >
                        <!-- Resultado(s) de Aprendizaje -->
                        <div
                          v-for="(
                            item, index4
                          ) in learning_experience.study_units"
                          :key="
                            'learning_experience' +
                            index +
                            'study_unit' +
                            index2 +
                            'epm-evaluation' +
                            '-' +
                            index4
                          "
                        >
                          {{ "RA" + item.order + ". " + item.full_sentence }}
                        </div>
                      </b-td>
                    </b-tr>
                  </template>
                </template>
                <b-tr
                  v-for="(
                    epm_evaluation, index3
                  ) in learning_experience.epm_evaluations"
                  :key="
                    'learning_experience' + index + 'epm-evaluation' + index3
                  "
                >
                  <b-td
                    v-if="
                      index3 == 0 &&
                      StudySuEpmEvaluationCount(learning_experience.id) == 0
                    "
                    :rowspan="learning_experience.epm_evaluations.length"
                    style="vertical-align: middle; text-align: justify"
                  >
                    <!-- N° y NOMBRE EA -->
                    {{
                      "EA " +
                      learning_experience.order +
                      " - " +
                      learning_experience.name
                    }}
                  </b-td>
                  <b-td
                    v-if="
                      index3 == 0 &&
                      StudySuEpmEvaluationCount(learning_experience.id) == 0
                    "
                    :rowspan="learning_experience.epm_evaluations.length"
                    style="vertical-align: middle; text-align: justify"
                  >
                    <div
                      class="text-break"
                      @click="(e) => e.target.classList.toggle('text-break')"
                    >
                      <!-- DESCRIPCIÓN -->
                      <template v-if="learning_experience.description">
                        <div
                          :id="`popover-learning_experience.description-${index}--${index3}`"
                          class="rich-text-content"
                          v-html="learning_experience.description"
                        ></div>
                        <b-popover
                          :target="`popover-learning_experience.description-${index}--${index3}`"
                          triggers="hover"
                          placement="right"
                        >
                          <div
                            class="rich-text-content popover-container"
                            v-html="learning_experience.description"
                          ></div>
                        </b-popover>
                      </template>
                      <template v-else>
                        <div>N/A</div>
                      </template>
                    </div>
                  </b-td>
                  <b-td
                    v-if="
                      index3 == 0 &&
                      StudySuEpmEvaluationCount(learning_experience.id) == 0
                    "
                    :rowspan="learning_experience.epm_evaluations.length"
                    style="vertical-align: middle; text-align: justify"
                  >
                    <!-- Resultado(s) de Aprendizaje -->
                    <div
                      v-for="(item, index4) in learning_experience.study_units"
                      :key="
                        'learning_experience' +
                        index +
                        'study_unit' +
                        index3 +
                        'epm-evaluation' +
                        '-' +
                        index4
                      "
                    >
                      {{ "RA" + item.order + ". " + item.full_sentence }}
                    </div>
                  </b-td>
                  <b-td>
                    <!-- N° y NOMBRE ACTIVIDAD DOCENCIA DIRIGIDA -->
                    <span v-if="epm_evaluation.type_name == 'Actividad'"
                      >Act {{ learning_experience.order }}.{{
                        epm_evaluation.order
                      }}
                      - {{ epm_evaluation.name }}</span
                    >
                    <template v-else>
                      <span
                        >Ev {{ epm_evaluation.type_name }}
                        {{ learning_experience.order }}.{{
                          epm_evaluation.order
                        }}
                        - {{ epm_evaluation.name }}</span
                      >
                    </template></b-td
                  >
                  <b-td>
                    <div
                      class="text-break"
                      @click="(e) => e.target.classList.toggle('text-break')"
                    >
                      <!-- Descripción breve DIRIGIDO AL DOCENTE -->
                      <template v-if="epm_evaluation.description">
                        <div
                          :id="`popover-epm_evaluation.description-${index}--${index3}`"
                          class="rich-text-content"
                          v-html="epm_evaluation.description"
                        ></div>
                        <b-popover
                          :target="`popover-epm_evaluation.description-${index}--${index3}`"
                          triggers="hover"
                          placement="right"
                        >
                          <div
                            class="rich-text-content popover-container"
                            v-html="epm_evaluation.description"
                          ></div>
                        </b-popover>
                      </template>
                      <template v-else>
                        <div>N/A</div>
                      </template>
                    </div>
                  </b-td>
                  <b-td
                    :class="[
                      epm_evaluation.evaluation_criterias_micro.length > 0
                        ? 'text-left'
                        : 'text-center',
                    ]"
                  >
                    <!-- Indicador(es) de logro -->
                    <template
                      v-if="
                        epm_evaluation.evaluation_criterias_micro.length > 0
                      "
                    >
                      <template
                        v-for="indicator in epm_evaluation.evaluation_criterias_micro"
                        class="indicator-content"
                      >
                        <b-badge
                          :key="indicator.id"
                          :id="`ec-badge-${indicator.id}`"
                          class="ec-micro-badge mr-1"
                          variant="info"
                          v-b-tooltip.v-secondary.noninteractive="
                            `${indicator.full_sentence}`
                          "
                        >
                          {{ indicator.study_unit__order }}.{{
                            indicator.order
                          }}
                        </b-badge>
                      </template>
                    </template>
                    <template v-else>
                      <div>N/A</div>
                    </template>
                  </b-td>
                  <b-td>
                    <!-- Tiempo horas docencia dirigida -->
                    {{
                      epm_evaluation.suggested_hours
                        ? epm_evaluation.suggested_hours
                        : "-"
                    }}
                  </b-td>
                  <b-td class="text-justify">
                    <!-- Actividad de trabajo autónomo del/la estudiante -->
                    <template
                      v-if="
                        epm_evaluation.epm_evaluation_autonomous_activities
                          .length > 0
                      "
                    >
                      <p
                        v-for="learning_resource in epm_evaluation.epm_evaluation_autonomous_activities"
                        :key="learning_resource.id"
                        variant="info"
                        class="mr-1"
                        v-html="learning_resource.name"
                      ></p>
                    </template>
                  </b-td>
                  <b-td>
                    <!-- Tiempo horas trabajo autónomo -->
                    {{
                      epm_evaluation.autonomous_work_hours
                        ? epm_evaluation.autonomous_work_hours
                        : "-"
                    }}
                  </b-td>
                  <b-td>
                    <!-- Recursos de aprendizaje -->
                    <template
                      v-if="
                        epm_evaluation.epm_evaluation_learning_resources
                          .length > 0
                      "
                    >
                      <div
                        v-for="learning_resource in epm_evaluation.epm_evaluation_learning_resources"
                        :key="learning_resource.id"
                      >
                        <div v-html="learning_resource.name"></div>
                      </div>
                    </template>
                    <template v-else>N/A</template>
                  </b-td>
                  <b-td>
                    <!-- Tecnología educativa  -->
                    <template
                      v-if="
                        epm_evaluation.epm_evaluation_educational_technology
                          .length > 0
                      "
                    >
                      <div
                        v-for="epm_evaluation_educational_technology in epm_evaluation.epm_evaluation_educational_technology"
                        :key="epm_evaluation_educational_technology.id"
                      >
                        <div
                          v-html="epm_evaluation_educational_technology.name"
                        ></div>
                      </div>
                    </template>
                    <template v-else>N/A</template>
                  </b-td>
                  <b-td class="bibliography-table-cell">
                    <!-- BIBLIOGRAFÍA OBLIGATORIA -->
                    <template
                      v-if="
                        epm_evaluation.epm_evaluation_mandatory_bibliography
                          .length > 0
                      "
                    >
                      <template
                        v-for="epm_evaluation_mandatory in epm_evaluation.epm_evaluation_mandatory_bibliography"
                      >
                        <div
                          :key="'mandatory' + epm_evaluation_mandatory.id"
                          v-html="
                            epm_evaluation_mandatory.bibliographic_resource__description
                          "
                          class="rich-text-content bibliography-content"
                        ></div>
                        <div
                          :key="
                            'mandatory-details' + epm_evaluation_mandatory.id
                          "
                          class="bibliography-details"
                        >
                          {{ epm_evaluation_mandatory.details }}
                        </div>
                        <BoundedRichText
                          class="bounded-biblio-content"
                          :key="
                            'bounded-biblio-mandatory' +
                            epm_evaluation_mandatory.id
                          "
                          :text="
                            epm_evaluation_mandatory.bibliographic_resource__description
                          "
                          :drop_text="epm_evaluation_mandatory.details"
                          :large="18"
                          :content_width="50"
                        ></BoundedRichText>
                      </template>
                    </template>
                    <template v-else>N/A</template>
                  </b-td>
                  <b-td>
                    <!-- BIBLIOGRAFÍA COMPLEMENTARIA -->
                    <template
                      v-if="
                        epm_evaluation.epm_evaluation_supplementary_bibliography
                          .length > 0
                      "
                    >
                      <template
                        v-for="epm_evaluation_supplementary in epm_evaluation.epm_evaluation_supplementary_bibliography"
                      >
                        <div
                          :key="
                            'supplementary' + epm_evaluation_supplementary.id
                          "
                          v-html="
                            epm_evaluation_supplementary.bibliographic_resource__description
                          "
                          class="rich-text-content bibliography-content"
                        ></div>
                        <div
                          :key="
                            'supplementary-details' +
                            epm_evaluation_supplementary.id
                          "
                          class="bibliography-details"
                        >
                          {{ epm_evaluation_supplementary.details }}
                        </div>
                        <BoundedRichText
                          class="bounded-biblio-content"
                          :key="
                            'bounded-biblio-supplementary' +
                            epm_evaluation_supplementary.id
                          "
                          :text="
                            epm_evaluation_supplementary.bibliographic_resource__description
                          "
                          :drop_text="epm_evaluation_supplementary.details"
                          :large="18"
                          :content_width="400"
                        ></BoundedRichText>
                      </template>
                    </template>
                    <template v-else>N/A</template>
                  </b-td>
                  <b-td
                    :class="[
                      epm_evaluation.study_environments.length > 0
                        ? 'text-left'
                        : 'text-center',
                    ]"
                  >
                    <!-- AMBIENTES DE APRENDIZAJE -->
                    <template
                      v-if="epm_evaluation.study_environments.length > 0"
                    >
                      <div
                        v-for="environment in epm_evaluation.study_environments"
                        :key="environment.id"
                        variant="info"
                        class="mr-1"
                      >
                        - {{ environment.name }}
                      </div>
                    </template>
                    <template v-else>N/A</template>
                  </b-td>
                  <b-td>
                    <!-- Nº ESTUDIANTES -->
                    {{
                      epm_evaluation.suggested_students
                        ? epm_evaluation.suggested_students
                        : "-"
                    }}
                  </b-td>
                </b-tr>
              </template>
            </template>
            <template v-else>
              <b-tr :key="'learning_experience' + index">
                <b-td
                  >{{
                    "EA " +
                    learning_experience.order +
                    " - " +
                    learning_experience.name
                  }}
                </b-td>
                <b-td>
                  <div
                    class="text-break"
                    @click="(e) => e.target.classList.toggle('text-break')"
                  >
                    <template v-if="learning_experience.description">
                      <div
                        :id="`popover-learning_experience.description-${index}-`"
                        class="rich-text-content"
                        v-html="learning_experience.description"
                      ></div>
                      <b-popover
                        :target="`popover-learning_experience.description-${index}-`"
                        triggers="hover"
                        placement="right"
                      >
                        <div
                          class="rich-text-content popover-container"
                          v-html="learning_experience.description"
                        ></div>
                      </b-popover>
                    </template>
                    <template v-else>
                      <div>N/A</div>
                    </template>
                  </div>
                </b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
                <b-td></b-td>
              </b-tr>
            </template>
          </template>
          <template
            v-for="(
              transversal_exam, index
            ) in learning_experiences.transversal_exams"
          >
            <b-tr :key="'transversal_exam' + index">
              <b-td class="align-middle" colspan="3"> Examen Transversal </b-td>
              <b-td class="align-middle">
                <!-- N° y NOMBRE ACTIVIDAD DOCENCIA DIRIGIDA -->
                {{ transversal_exam.name }}
              </b-td>
              <b-td>
                <div
                  class="text-break"
                  @click="(e) => e.target.classList.toggle('text-break')"
                >
                  <!-- Descripción breve DIRIGIDO AL DOCENTE -->
                  <template v-if="transversal_exam.description">
                    <div
                      :id="`popover-transversal_exam.description-${index}-`"
                      class="rich-text-content"
                      v-html="transversal_exam.description"
                    ></div>
                    <b-popover
                      :target="`popover-transversal_exam.description-${index}-`"
                      triggers="hover"
                      placement="right"
                    >
                      <div
                        class="rich-text-content popover-container"
                        v-html="transversal_exam.description"
                      ></div>
                    </b-popover>
                  </template>
                </div>
              </b-td>
              <b-td
                :class="[
                  transversal_exam.evaluation_criterias_micro.length > 0
                    ? 'text-left'
                    : 'text-center',
                ]"
              >
                <!-- Indicador(es) de logro -->
                <template
                  v-if="transversal_exam.evaluation_criterias_micro.length > 0"
                >
                  <template
                    v-for="indicator in transversal_exam.evaluation_criterias_micro"
                    class="indicator-content"
                  >
                    <b-badge
                      :key="indicator.id"
                      :id="`ec-badge-trans-${indicator.id}`"
                      class="ec-micro-badge mr-1"
                      variant="info"
                      v-b-tooltip.v-secondary.noninteractive="
                        `${indicator.full_sentence}`
                      "
                    >
                      {{ indicator.study_unit__order }}.{{ indicator.order }}
                    </b-badge>
                  </template>
                </template>
                <template v-else>
                  <div>N/A</div>
                </template>
              </b-td>
              <b-td class="align-middle">
                <!-- Tiempo horas docencia dirigida(Horas pedagógicas) -->
                {{ transversal_exam.suggested_hours }}
              </b-td>
              <b-td class="text-justify">
                <!-- Actividad de trabajo autónomo del/la estudiante -->
                <template
                  v-if="
                    transversal_exam.transversal_exam_autonomous_activities
                      .length > 0
                  "
                >
                  <p
                    v-for="learning_resource in transversal_exam.transversal_exam_autonomous_activities"
                    :key="learning_resource.id"
                    variant="info"
                    class="mr-1"
                    v-html="learning_resource.name"
                  ></p>
                </template>
              </b-td>
              <b-td class="align-middle">
                <!-- Tiempo horas trabajo autónomo -->
                {{
                  transversal_exam.autonomous_work_hours
                    ? transversal_exam.autonomous_work_hours
                    : "-"
                }}
              </b-td>
              <b-td class="align-middle">
                <!-- Recursos de aprendizaje -->
                <template
                  v-if="
                    transversal_exam.transversal_exam_learning_resources
                      .length > 0
                  "
                >
                  <b-badge
                    v-for="learning_resource in transversal_exam.transversal_exam_learning_resources"
                    :key="learning_resource.id"
                    class="mr-1"
                    variant="info"
                    v-html="learning_resource.name"
                  >
                  </b-badge>
                </template>
                <template v-else>N/A</template>
              </b-td>
              <b-td class="align-middle">
                <!-- Tecnología educativa  -->
                <template
                  v-if="
                    transversal_exam.transversal_exam_educational_technology
                      .length > 0
                  "
                >
                  <b-badge
                    v-for="educational_technology in transversal_exam.transversal_exam_educational_technology"
                    :key="educational_technology.id"
                    class="mr-1"
                    variant="info"
                    v-html="educational_technology.name"
                  >
                  </b-badge>
                </template>
                <template v-else>N/A</template>
              </b-td>
              <b-td class="bibliography-table-cell">
                <!-- BIBLIOGRAFÍA OBLIGATORIA -->
                <template
                  v-if="
                    transversal_exam.transversal_exam_mandatory_bibliography
                      .length > 0
                  "
                >
                  <template
                    v-for="epm_evaluation_mandatory in transversal_exam.transversal_exam_mandatory_bibliography"
                  >
                    <div
                      :key="'trans-mandatory' + epm_evaluation_mandatory.id"
                      v-html="
                        epm_evaluation_mandatory.bibliographic_resource__description
                      "
                      class="rich-text-content bibliography-content"
                    ></div>
                    <div
                      :key="
                        'trans-mandatory-details' + epm_evaluation_mandatory.id
                      "
                      class="bibliography-details"
                    >
                      {{ epm_evaluation_mandatory.details }}
                    </div>
                    <BoundedRichText
                      class="bounded-biblio-content"
                      :key="
                        'bounded-biblio-trans-mandatory' +
                        epm_evaluation_mandatory.id
                      "
                      :text="
                        epm_evaluation_mandatory.bibliographic_resource__description
                      "
                      :drop_text="epm_evaluation_mandatory.details"
                      :large="18"
                      :content_width="400"
                    ></BoundedRichText>
                  </template>
                </template>
                <template v-else>N/A</template>
              </b-td>
              <b-td class="bibliography-table-cell">
                <!-- BIBLIOGRAFÍA COMPLEMENTARIA -->
                <template
                  v-if="
                    transversal_exam.transversal_exam_supplementary_bibliography
                      .length > 0
                  "
                >
                  <template
                    v-for="epm_evaluation_supplementary in transversal_exam.transversal_exam_supplementary_bibliography"
                  >
                    <div
                      :key="
                        'trans-supplementary' + epm_evaluation_supplementary.id
                      "
                      v-html="
                        epm_evaluation_supplementary.bibliographic_resource__description
                      "
                      class="rich-text-content bibliography-content"
                    ></div>
                    <div
                      :key="
                        'trans-supplementary-details' +
                        epm_evaluation_supplementary.id
                      "
                      class="bibliography-details"
                    >
                      {{ epm_evaluation_supplementary.details }}
                    </div>
                    <BoundedRichText
                      class="bounded-biblio-content"
                      :key="
                        'bounded-biblio-trans-supplementary' +
                        epm_evaluation_supplementary.id
                      "
                      :text="
                        epm_evaluation_supplementary.bibliographic_resource__description
                      "
                      :drop_text="epm_evaluation_supplementary.details"
                      :large="18"
                      :content_width="400"
                    ></BoundedRichText>
                  </template>
                </template>
                <template v-else>N/A</template>
              </b-td>
              <b-td
                :class="[
                  transversal_exam.study_environments.length > 0
                    ? 'text-left'
                    : 'text-center',
                ]"
              >
                <!-- AMBIENTES DE APRENDIZAJE -->
                <template v-if="transversal_exam.study_environments.length > 0">
                  <div
                    v-for="environment in transversal_exam.study_environments"
                    :key="environment.id"
                    variant="info"
                    class="mr-1"
                  >
                    - {{ environment.name }}
                  </div>
                </template>
                <template v-else>N/A</template>
              </b-td>
              <b-td class="align-middle">
                <!-- Nº ESTUDIANTES -->
                {{
                  transversal_exam.suggested_students
                    ? transversal_exam.suggested_students
                    : "-"
                }}
              </b-td>
            </b-tr>
          </template>
        </template>
        <template v-else>
          <b-th colspan="15"
            >No hay registro de experiencias de aprendizaje para esta
            {{
              $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
            }}.</b-th
          >
        </template>
      </b-tbody>
    </b-table-simple>
    <b-button
      variant="primary"
      class="btn btn-primary mr-3 btn-download-excel"
      @click="DownloadXLS"
      >Descargar XLS</b-button
    >
  </div>
</template>
<script>
// import { URL } from "@/utils/globals";
export default {
  name: "LearningExperiencesView",
  components: {
    BoundedRichText: () => import("@/components/reusable/BoundedRichText"),
  },
  props: {},
  data() {
    return {
      learning_experiences: null,
      egress_profile_matter_id: Number(
        this.$route.params.egress_profile_matter_id
      ),
    };
  },
  computed: {},
  methods: {
    fetchLearningExperiences() {
      if (this.egress_profile_matter_id) {
        this.$restful
          .Get(
            `/teaching/learning_experience/?egress_profile_matter=${this.egress_profile_matter_id}`
          )
          .then((response) => {
            this.learning_experiences = response;
          });
      }
    },

    StudySuEpmEvaluationCount(learning_experiences_id) {
      if (!this.learning_experiences) return 0;
      let su_epm_evaluation_count = 0;
      const learning_experience =
        this.learning_experiences.learning_experiences.find(
          (x) => x.id == learning_experiences_id
        );
      if (learning_experience) {
        learning_experience.study_units.forEach((study_unit) => {
          su_epm_evaluation_count += study_unit.su_epm_evaluation_count;
        });
        return su_epm_evaluation_count;
      }
    },
    DownloadXLS() {
      // window.open(
      //   URL +
      //     `/teaching/learning_exp_excel_version/?egress_profile_matter=${this.egress_profile_matter_id}`,
      //   "_blank"
      // );
      this.$restful
        .Get(
          `/teaching/learning_exp_excel_version/?egress_profile_matter=${this.egress_profile_matter_id}`
        )
        .then((response) => {
          // URL + `/teaching/learning_exp_excel_version/?egress_profile_matter=${this.egress_profile_matter_id}`,
          if (response && response.file_url)
            window.open(response.file_url, "_blank");
        });
    },
    printingOrientationLandscape() {
      var css = "@media print {@page { size: A4 landscape; }}",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      head.appendChild(style);
    },
    printingOrientationPortrait() {
      var css = "@media print {@page { size: A4 portrait !important; }}",
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      style.type = "text/css";
      style.media = "print";

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      head.appendChild(style);
    },
  },
  mounted() {
    this.fetchLearningExperiences();
    this.printingOrientationLandscape();
  },
  beforeDestroy() {
    this.printingOrientationPortrait();
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.primary-header {
  background: #808080 !important;
  color: white;
}
.secondary-header {
  background: #d9d9d9 !important;
}
.indicator-content:first-of-type {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.bibliography-content {
  /* max-width: 200px; */
  display: none;
  word-break: break-word;
  text-overflow: ellipsis;
}
.txt-custom {
  font-size: 9pt;
}
.bibliography-details {
  display: none;
  border-top: 1px solid #f1f1f1;
}
.text-break >>> div p {
  max-width: 100%; /* Establece el ancho máximo del div o td */
  overflow: hidden; /* Oculta el contenido que se desborde */
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  text-overflow: ellipsis; /* Agrega puntos suspensivos al final del texto desbordado */
}
.popover-container {
  max-height: 400px;
  overflow: auto;
}
.popover-container >>> p {
  padding-bottom: 1rem;
}
@media print {
  .badge {
    border: none !important;
  }
  .learning-experiences-container {
    zoom: 52%;
    display: table;
    table-layout: inherit;
  }
  .bibliography-content,
  .bibliography-details {
    display: block;
  }

  .bounded-biblio-content {
    display: none;
  }
  .ec-micro-badge {
    font-size: var(--thirth-font-size);
    text-align: center;
    width: fit-content !important;
    margin-right: 0.3em;
    border: none;
  }
}
</style>