<template>
  <div>
    <b-tabs content-class="mt-3" v-model="tabIndex">
      <b-tab title="Redacción">
        <div class="top-row">
          <div class="competence-type">
            <b-form-group
              v-if="competenceTypes.length > 0"
              label-cols="6"
              label-cols-sm="6"
              label-cols-lg="6"
              :label="
                $getVisibleNames(
                  'mesh.competencetype',
                  false,
                  'Tipo de Competencia'
                )
              "
              label-for="input-type"
              class="m-0"
            >
              <b-form-select
                id="input-type"
                :options="competenceTypes"
                value-field="id"
                text-field="name"
                v-model="$v.competence.type.$model"
                :state="validateCompetenceState('type')"
                :disabled="!can_edit_type && !isNaN(type_id)"
                size="sm"
                aria-describedby="input-type-feedback"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >Seleccione una de las opciones</b-form-select-option
                  ></template
                >
              </b-form-select>
              <b-form-invalid-feedback id="input-type-feedback"
                >Este campo es requerido.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              v-if="egress_profile_view"
              label-cols="6"
              label-cols-sm="6"
              label-cols-lg="6"
              label="Orden"
              label-for="input-order"
              class="m-0"
            >
              <b-form-input
                type="number"
                class="m-0 py-0"
                v-model="profile_competence.order"
                min="1"
                size="sm"
                :disabled="!can_edit_order"
                :state="orderState"
                v-b-tooltip.left.v-secondary.noninteractive="
                  `${
                    !orderState
                      ? String(
                          'Este perfil ya cuenta con una ' +
                            $getVisibleNames(
                              'mesh.competence',
                              false,
                              'Competencia'
                            ) +
                            ' en este orden.'
                        )
                      : ''
                  }`
                "
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              v-if="egressProfileCycleSort.length > 0 && can_edit_profile_cycle"
              label-cols="6"
              label-cols-sm="6"
              label-cols-lg="6"
              :label="$getVisibleNames('mesh.cycle', false, 'Ciclo Formativo')"
              label-for="input-type"
              class="m-0"
            >
              <b-form-select
                id="input-type"
                :options="egressProfileCycleSort"
                value-field="id"
                text-field="name"
                v-model="profile_competence.cycle"
                size="sm"
                aria-describedby="input-type-feedback"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >Seleccione una de las opciones</b-form-select-option
                  ></template
                >
              </b-form-select>
              <b-form-invalid-feedback id="input-type-feedback"
                >Este campo es requerido.</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
          <div
            class="toggle-container"
            v-b-tooltip.v-secondary.hover.top="
              'Ingresar oración directamente o utilizar el modo de “creación“'
            "
          >
            <span :class="{ 'font-weight-bold': !checked }">Insertar</span>
            <b-button
              class="check-type"
              @click="checked = !checked"
              @change="forceRender"
              variant="primary"
            >
              <b-icon
                icon="circle-fill"
                class="icon-check"
                :class="{ 'icon-check-active': checked }"
                scale="0.7"
              ></b-icon>
            </b-button>
            <span :class="{ 'font-weight-bold': checked }">Crear</span>
          </div>
        </div>
        <CompetenceSentenceForm
          :key="keyRender"
          :verb="competence.verb"
          :first_content="competence.action"
          :second_content="competence.content"
          :third_content="competence.condition"
          :fourth_content="competence.context"
          :full_sentence="competence.full_sentence"
          :checked="checked"
          @update="slotUpdateSentence"
        ></CompetenceSentenceForm>
      </b-tab>
      <b-tab
        v-if="!egress_profile_view"
        :title="`Asociar ${$getVisibleNames(
          'mesh.competence',
          false,
          'Competencia'
        )}`"
      >
        <div class="ml-2">
          <EgressProfileCompetence
            :Competence="competence"
            :egress_profile_id="egress_profile_id"
            :selected_ids="selected_ids"
            :cycle_id="cycle_id"
            :order="order"
            :can_edit_order="can_edit_order"
            :can_edit_cycle="can_edit_cycle"
            @updated="slotUpdatedProfileCompetences"
          ></EgressProfileCompetence>
        </div>
      </b-tab>
      <b-tab
        v-if="!isNaN(competence.id)"
        :title="`Asociar ${$getVisibleNames(
          'mesh.competencedescriptor',
          false,
          'descriptor de la Competencia'
        )}`"
      >
        <CompetenceDescriptors
          :competence_id="competence.id"
          :allows_crud="allows_crud"
          @reset_competence="() => this.$emit('reset_competence')"
          @change_middle_descriptors="
            (value) => this.$emit('change_middle_descriptors', value)
          "
          @reset_competence_table="() => this.$emit('reset_competence_table')"
        >
        </CompetenceDescriptors>
      </b-tab>
    </b-tabs>
    <div class="row">
      <div v-if="show_delete_button" class="col" style="text-align: left">
        <b-button
          v-if="!isNaN(profile_competence.id)"
          variant="danger"
          class="mr-1"
          size="sm"
          @click="askDeleteProfileCompetence(profile_competence.id)"
          :disabled="isNaN(competence.id)"
          v-bind:style="{
            cursor: !isNaN(competence.id) ? 'pointer' : 'not-allowed',
          }"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <template v-if="!isNaN(competence.id)">
          <!-- class="modal-base-ra" -->
          <b-button
            @click="$bvModal.show(`modal-base-ra`)"
            class="mr-2"
            size="sm"
            v-if="
              (institution && institution.show_base_ra) || $debug_change_duoc
            "
          >
            {{ $getVisibleNames("teaching.basera", false, "RA Base") }}
            <b-badge
              v-if="raBasesFiltered"
              class="ra-bases-badge"
              variant="light"
              >{{ raBasesFiltered.length }}</b-badge
            >
          </b-button>
          <b-modal
            :id="`modal-base-ra`"
            hide-footer
            :title="$getVisibleNames('teaching.basera', false, 'RA Base')"
            size="lg"
          >
            <RaBase :competence_id="competence.id"></RaBase>
          </b-modal>
        </template>
        <b-button
          class="mr-1"
          size="sm"
          @click="saveCompetence"
          :disabled="!valid_competence"
          v-bind:style="{
            cursor: valid_competence ? 'pointer' : 'not-allowed',
          }"
          :variant="valid_competence ? 'secondary' : 'light'"
          >Guardar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";

export default {
  name: "CompetenceForm",
  mixins: [validationMixin],
  components: {
    CompetenceDescriptors: () => import("./CompetenceDescriptors"),
    CompetenceSentenceForm: () =>
      import("@/components/reusable/CompetenceSentenceForm"),
    RaBase: () => import("@/components/reusable/RaBase"),
    EgressProfileCompetence: () =>
      import("@/components/mesh/EgressProfile/EgressProfileCompetence"),
  },
  props: {
    Competence: {
      type: Object,
    },
    egress_profile_id: {
      type: Number,
    },
    type_id: {
      type: Number,
      default: null,
    },
    cycle_id: {
      type: Number,
    },
    order: {
      type: Number,
      default: 1,
    },
    can_edit_type: {
      type: Boolean,
      default: true,
    },
    can_edit_cycle: {
      type: Boolean,
      default: true,
    },
    can_edit_profile_cycle: {
      type: Boolean,
      default: false,
    },
    can_edit_order: {
      type: Boolean,
      default: true,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: true,
    },
    egress_profile_view: {
      type: Boolean,
      default: false,
    },
    show_cycle: {
      type: Boolean,
      default: true,
    },
    selected_ids: {
      type: Array,
      default: function () {
        return [];
      },
    },
    matrix1_competence: {
      type: Boolean,
    },
  },
  data() {
    return {
      competence: {
        id: this.Competence ? this.Competence.id : generateUniqueId(),
        verb: this.Competence ? this.Competence.verb : null,
        action: this.Competence ? this.Competence.action : "",
        content: this.Competence ? this.Competence.content : "",
        condition: this.Competence ? this.Competence.condition : "",
        context: this.Competence ? this.Competence.context : "",
        full_sentence: this.Competence ? this.Competence.full_sentence : "",
        type: this.Competence ? this.Competence.type : this.type_id,
      },
      profile_competence: {
        id: generateUniqueId(),
        egress_profile: this.egress_profile_id ? this.egress_profile_id : null,
        cycle: this.cycle_id ? this.cycle_id : null,
        order: this.order ? this.order : 1,
        competence: this.Competence ? this.Competence.id : null,
      },
      tabIndex: 0,
      valid_competence: false,
      egress_profile_competences: [],
      checked: this.Competence
        ? !this.Competence.content ||
          !this.Competence.condition ||
          !this.Competence.context
          ? false
          : true
        : true,
      keyRender: 0,
    };
  },
  validations() {
    if (this.checked)
      return {
        competence: {
          action: { required },
          content: { required },
          condition: { required },
          context: { required },
          full_sentence: {},
          type: {},
        },
      };
    else
      return {
        competence: {
          action: {},
          content: {},
          condition: {},
          context: {},
          full_sentence: { required },
          type: {},
        },
      };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      cycles: names.CYCLES,
      competenceTypes: names.COMPETENCE_TYPES,
      egressProfiles: names.EGRESS_PROFILES,
      profileCompetences: names.PROFILE_COMPETENCES,
      profile_cycles: names.PROFILE_CYCLES,
      ra_bases: names.RA_BASES,
    }),
    raBasesFiltered() {
      if (!this.competence.id) return;
      return this.ra_bases.filter((x) =>
        x.competences.includes(this.competence.id)
      );
    },
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    egressProfileCycleId() {
      if (!this.egress_profile_id) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egress_profile_id) list.push(x.cycle);
      });
      return list;
    },
    egressProfileCycleSort() {
      if (!this.egressProfile) return [];
      let cycles = this.cycles.filter((x) =>
        this.egressProfileCycleId.includes(x.id)
      );
      cycles.forEach((x) => {
        let cycle_list = this.profile_cycles.find(
          (profile_cycle) =>
            profile_cycle.cycle == x.id &&
            profile_cycle.egress_profile == this.egress_profile_id
        );
        if (cycle_list) {
          x.init_semester = cycle_list.init_semester;
          x.end_semester = cycle_list.end_semester;
          x.order = cycle_list.order;
        }
      });
      return cycles.sort(function (a, b) {
        if (a.init_semester < b.init_semester) return -1;
        if (a.init_semester > b.init_semester) return 1;
        if (a.end_semester < b.end_semester) return -1;
        if (a.end_semester > b.end_semester) return 1;
        return 0;
      });
    },
    orderState() {
      if (this.egress_profile_id && this.egress_profile_view) {
        const profile_competence = this.profileCompetences.filter(
          (x) =>
            x.egress_profile == this.egress_profile_id &&
            x.order == this.profile_competence.order &&
            x.cycle == this.cycle_id
        );
        if (
          profile_competence.length > 1 ||
          (profile_competence.length > 0 && isNaN(this.profile_competence.id))
        ) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    validateCompetenceState(atr) {
      const { $dirty, $error } = this.$v.competence[atr];
      return $dirty ? !$error : null;
    },
    saveCompetence() {
      this.$v.competence.$touch();
      if (this.$v.competence.$anyError) return;
      if (this.checked) {
        this.competence.full_sentence = String(
          this.competence.action +
            " " +
            this.competence.content +
            " " +
            this.competence.condition +
            " " +
            this.competence.context
        );
        if (isNaN(this.competence.id)) this.createCompetence();
        else this.updateCompetence();
      } else {
        if (
          this.competence.full_sentence ||
          !this.competence.full_sentence.trim() == ""
        ) {
          if (isNaN(this.competence.id)) this.createCompetence();
          else this.updateCompetence();
        }
      }
    },
    createCompetence() {
      this.$store
        .dispatch(names.POST_COMPETENCE, this.competence)
        .then((response) => {
          this.competence = response;
          this.profile_competence.competence = response.id;
          toast(
            String(
              this.$getVisibleNames("mesh.competence", false, "Competencia") +
                " creada."
            )
          );
          this.saveProfileCompetences();
          setTimeout(() => {
            this.tabIndex = 2;
          }, 500);
          // this.$emit("created", this.competence);
        });
    },
    updateCompetence() {
      this.$store
        .dispatch(names.UPDATE_COMPETENCE, this.competence)
        .then((response) => {
          this.competence = response;
          toast(
            String(
              this.$getVisibleNames("mesh.competence", false, "Competencia") +
                " actualizada."
            )
          );
          this.saveProfileCompetences();
          this.$emit("created", this.competence);
          this.$emit("updated", this.competence);
        });
    },
    deleteCompetence() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.competence",
          false,
          "Competencia"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_COMPETENCE, this.competence.id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.competence",
                    false,
                    "Competencia"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    saveProfileCompetences() {
      if (this.egress_profile_view) {
        if (!this.profile_competence.id || isNaN(this.profile_competence.id))
          this.createProfileCompetence(this.profile_competence);
        else this.updateProfileCompetence(this.profile_competence);
      } else {
        this.egress_profile_competences.forEach((egress_profile_competence) => {
          if (egress_profile_competence.selected) {
            if (
              !egress_profile_competence.id ||
              isNaN(egress_profile_competence.id)
            ) {
              this.createProfileCompetence(egress_profile_competence);
            } else {
              this.updateProfileCompetence(egress_profile_competence);
              // TODO: Garantizar de alguna forma que el objeto no se actualice si no ha sido moficado.
            }
          } else {
            if (
              egress_profile_competence.id &&
              !isNaN(egress_profile_competence.id)
            ) {
              this.deleteProfileCompetence(egress_profile_competence.id);
            }
          }
        });
      }
    },
    createProfileCompetence(profile_competence) {
      const payload = {
        order: profile_competence.order,
        egress_profile: profile_competence.egress_profile,
        competence: this.competence.id,
        cycle: profile_competence.cycle,
      };
      this.$store
        .dispatch(names.POST_PROFILE_COMPETENCE, payload)
        .then((response) => {
          this.profile_competence = response;
          toast(
            String(
              "Relación entre " +
                this.$getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil de Egreso"
                ) +
                " y " +
                this.$getVisibleNames("mesh.competence", false, "Competencia") +
                " creada."
            )
          );
          this.$emit("created_egress_profile_competence", response);
          this.$emit("close", this.competence.id);
        });
    },
    updateProfileCompetence(profile_competence) {
      this.$store
        .dispatch(names.UPDATE_PROFILE_COMPETENCE, profile_competence)
        .then((response) => {
          this.profile_competence = response;
          toast(
            String(
              "Relación entre " +
                this.$getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil de Egreso"
                ) +
                " y " +
                this.$getVisibleNames("mesh.competence", false, "Competencia") +
                " actualizada."
            )
          );
          this.$emit("updated_egress_profile_competence", profile_competence);
          this.$emit("close", this.competence.id);
        });
    },
    askDeleteProfileCompetence(egress_profile_competence_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.competence",
          false,
          "Competencia"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteProfileCompetence(egress_profile_competence_id);
          this.$emit("deleted");
        }
      });
    },
    deleteProfileCompetence(egress_profile_competence_id) {
      if (isNaN(egress_profile_competence_id) || !egress_profile_competence_id)
        return;
      this.$store
        .dispatch(names.DELETE_PROFILE_COMPETENCE, egress_profile_competence_id)
        .then(() => {
          toast(
            String(
              this.$getVisibleNames("mesh.competence", false, "Competencia") +
                " eliminada del " +
                this.$getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil de Egreso"
                ) +
                "."
            )
          );
          this.$emit("close", this.competence.id);
        });
    },
    slotUpdateSentence(sentence) {
      // TODO: Revisar porque se ejecuta sin que haya cambiado nada.
      this.competence.action = sentence.first_content;
      this.competence.verb = sentence.verb;
      this.competence.content = sentence.second_content;
      this.competence.condition = sentence.third_content;
      this.competence.context = sentence.fourth_content;
      this.competence.full_sentence = sentence.full_sentence;
    },
    slotUpdatedProfileCompetences(egress_profile_competences) {
      this.egress_profile_competences = egress_profile_competences;
    },
    forceRender() {
      this.keyRender += 1;
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
    this.$store.dispatch(names.FETCH_COMPETENCE_TYPES);
    this.$store.dispatch(names.FETCH_CYCLES);
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES).then((response) => {
      if (this.Competence) {
        const profileCompetence = response.find(
          (x) =>
            x.competence == this.Competence.id &&
            x.egress_profile == this.egress_profile_id &&
            x.cycle == this.cycle_id
        );
        if (profileCompetence) {
          this.profile_competence.id = profileCompetence.id;
          this.profile_competence.order = profileCompetence.order;
        }
      }
    });
    if (this.matrix1_competence) {
      this.checked = true;
    }
    if (!isNaN(this.competence.id))
      this.$store.dispatch(names.FETCH_RA_BASES, {
        competence_id: this.competence.id,
      });
  },
  updated() {
    // this.$v.competence.$touch();
    this.valid_competence = !this.$v.competence.$anyError;
  },
};
</script>

<style scoped>
.top-row {
  width: 100%;
  display: flex;
}
.competence-type {
  width: 50%;
}
.toggle-container {
  width: 50%;
  padding-left: 6.6em;
  padding-top: 0.6em;
}
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.1em;
  width: 2.2em !important;
  margin-top: 0.1em;
  border-radius: 10px;
}
.icon-check {
  color: white;
  transform: translate(-70%, -52%);
  transition: 0.2s;
}
.icon-check-active {
  transform: translate(15%, -52%) !important;
}
/* .modal-base-ra {
  display: flex;
  margin-right: 0;
  margin-left: auto;
} */
.ra-bases-badge {
  margin: 0.2rem;
  margin-left: 0.5rem;
  vertical-align: middle;
}
@media (max-width: 991px) {
  .competence-type {
    width: 62%;
  }

  .toggle-container {
    width: 38%;
    padding-left: 1em;
  }
}
</style>