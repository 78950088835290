<template>
  <div>
    <div style="display: flex">
      <div class="col" style="text-align: left">
        <button
          v-can="'teaching.add_epmevaluationbibliographicresource'"
          v-if="allows_crud"
          class="btn btn-secondary btn-sm"
          @click="
            $bvModal.show(`new-epm_evaluation_bibliographic_resource-modal`)
          "
        >
          Agregar Recurso Bibliográfico
        </button>
      </div>
    </div>
    <GenericBTable
      :items="epm_evaluation_bibliographic_resource"
      :pagination="epm_evaluation_bibliographic_resource.length"
      :fields="EPMEvaluationBibliographicResourceFields"
      :filter="input_search"
      :filterCustom="filterCustom"
      @emitChangeSearchField="changeInputSearch"
      :show_pagination="true"
      :search_filter="true"
      :columns_display="true"
      :allows_crud="allows_crud"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #cell(bibliographic_resource)="row">
        <span
          v-html="
            getBibliographicResourceDescription(row.item.bibliographic_resource)
          "
        ></span>
      </template>
      <template v-if="allows_crud" #cell(actions)="row">
        <button-edit
          @click="
            $bvModal.show(
              `edit-epm_evaluation_bibliographic_resource-modal-${row.item.id}`
            )
          "
          v-b-tooltip.v-secondary.noninteractive="
            `Editar Recurso Bibliográfico`
          "
        ></button-edit>
        <button-delete
          @click="askForDeleteEPMEvaBiblioResource(row.item.id)"
          v-b-tooltip.v-secondary.noninteractive="
            `Eliminar Recurso Bibliográfico`
          "
        ></button-delete>
        <b-modal
          :id="`edit-epm_evaluation_bibliographic_resource-modal-${row.item.id}`"
          :title="`Editar Recurso Bibliográfico`"
          size="md"
          hide-footer
        >
          <EPMEvaluationBibliographicResourceForm
            :EPMEvaBibliResource="row.item"
            :epm_evaluation_id="epm_evaluation_id"
            :bibliographic_resources="bibliographic_resources"
            :bibliographic_resource_types="bibliographic_resource_types"
            @updated="slotUpdatedEPMEvaBiblioResource"
          ></EPMEvaluationBibliographicResourceForm>
        </b-modal>
      </template>
    </GenericBTable>
    <b-modal
      :id="`new-epm_evaluation_bibliographic_resource-modal`"
      :hide-footer="true"
      :title="`Crear Recurso Bibliográfico`"
      size="lg"
    >
      <EPMEvaluationBibliographicResourceForm
        :epm_evaluation_id="epm_evaluation_id"
        :bibliographic_resources="bibliographic_resources"
        :bibliographic_resource_types="bibliographic_resource_types"
        @created="slotCreatedEPMEvaBiblioResource"
      ></EPMEvaluationBibliographicResourceForm>
    </b-modal>
  </div>
</template>

<script>
import { toast } from "@/utils/utils";
export default {
  name: "EPMEvaluationBibliographicResource",
  components: {
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    EPMEvaluationBibliographicResourceForm: () =>
      import("./EPMEvaluationBibliographicResourceForm"),
  },
  props: {
    epm_evaluation_id: {
      type: Number,
      required: true,
    },
    ep_matter: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      epm_evaluation_bibliographic_resource: [],
      bibliographic_resources: [],
      bibliographic_resource_types: [],
      sortBy: "is_mandatory",
      sortDesc: false,
      input_search: "",
    };
  },
  computed: {
    EPMEvaluationBibliographicResourceFields() {
      return [
        {
          key: "bibliographic_resource",
          label: "Recursos Bibliográficos",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "details",
          label: "Detalles",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "is_mandatory",
          label: "Tipo",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
          formatter: (value) => {
            return value
              ? "Bibliografía Obligatoria"
              : "Bibliografía Complementaria";
          },
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    getType(type) {
      return type ? "Bibliografía Obligatoria" : "Bibliografía Complementaria";
    },
    filterCustom(row) {
      if (
        this.$filtered(
          this.getBibliographicResourceDescription(row.bibliographic_resource),
          this.input_search
        ) ||
        this.$filtered(row.details, this.input_search) ||
        this.$filtered(this.getType(row.is_mandatory), this.input_search)
      ) {
        return true;
      } else {
        return false;
      }
    },
    slotCreatedEPMEvaBiblioResource(response) {
      this.epm_evaluation_bibliographic_resource.push(response);
      this.$bvModal.hide("new-epm_evaluation_bibliographic_resource-modal");
    },
    slotUpdatedEPMEvaBiblioResource(response) {
      const index = this.epm_evaluation_bibliographic_resource.findIndex(
        (x) => x.id == response.id
      );
      if (index != -1) {
        this.epm_evaluation_bibliographic_resource[
          index
        ].bibliographic_resource = response.bibliographic_resource;
        this.epm_evaluation_bibliographic_resource[index].details =
          response.details;
        this.epm_evaluation_bibliographic_resource[index].is_mandatory =
          response.is_mandatory;

        this.$bvModal.hide(
          `edit-epm_evaluation_bibliographic_resource-modal-${response.id}`
        );
      }
    },
    askForDeleteEPMEvaBiblioResource(epm_eva_biblio_resource_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar el Recurso Bibliográfico?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(
              `/teaching/epm-evaluation-bibliographic-resource/${epm_eva_biblio_resource_id}/`
            )
            .then(() => {
              const index =
                this.epm_evaluation_bibliographic_resource.findIndex(
                  (x) => x.id == epm_eva_biblio_resource_id
                );
              if (index != -1) {
                this.epm_evaluation_bibliographic_resource.splice(index, 1);
                toast("Recurso Bibliográfico eliminado.");
              }
            });
        }
      });
    },
    getBibliographicResourceDescription(id) {
      if (!id) return "";
      const bibliographic_resource = this.bibliographic_resources.find(
        (x) => x.id == id
      );
      if (bibliographic_resource) return bibliographic_resource.description;
      return "";
    },
    fetchEPMEvaluationBibliographicResource() {
      if (!isNaN(this.epm_evaluation_id))
        this.$restful
          .Get(
            `/teaching/epm-evaluation-bibliographic-resource/?egress_profile_matter_evaluation=${this.epm_evaluation_id}`
          )
          .then((response) => {
            this.epm_evaluation_bibliographic_resource = response;
          });
    },
    fetchBibliographicResource() {
      if (this.ep_matter)
        this.$restful
          .Get(
            `/teaching/bibliographic-resource/?matter=${this.ep_matter.matter}`
          )
          .then((response) => {
            this.bibliographic_resources = response;
          });
    },
    fetchBibliographicResourceType() {
      this.$restful
        .Get(`/teaching/bibliographic-resource-type/`)
        .then((response) => {
          this.bibliographic_resource_types = response;
        });
    },
  },
  created() {
    this.fetchEPMEvaluationBibliographicResource();
    this.fetchBibliographicResource();
    this.fetchBibliographicResourceType();
  },
};
</script>

<style scoped>
</style>