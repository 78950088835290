<template>
  <div>
    <!-- <b-form-group>
      <label for="select-career" class="career-label">Carrera:</label>
      <b-form-select
        id="select-career"
        v-model="selected_career"
        :options="careers"
        value-field="id"
        text-field="name"
        class="w-25"
      >
        <template #first>
          <b-form-select-option value="null">
            -- Todas las Carreras --
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group> -->
    <div class="mx-2">
      <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_formationline'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-formation-line-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "mesh.formationline",
                false,
                "Línea De Formación"
              )
            }}
            <!-- Línea de Formación -->
          </button>
        </div>
      </div>
      <h3>
        {{
          $getVisibleNames("mesh.formationline", true, "Líneas de Formación")
        }}
      </h3>
      <GenericBTable
        :items="formation_lines"
        :pagination="formation_lines.length"
        :fields="formationLinesFields"
        :filter="input_search"
        :filterCustom="filterCustom"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="display_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @emitChangeSearchField="changeInputSearch"
      >
        <template #cell(faculties)="row">
          <div class="campus-container">
            <div
              v-for="item in getFacultyName(row.item.faculties)"
              :key="item.id"
              class="campus-div"
            >
              {{ item.name }}
            </div>
          </div>
        </template>
        <template #cell(color)="row">
          <div
            class="formation-line-color"
            :style="{
              'background-color': row.item.color,
              color: row.item.color,
            }"
          >
            <!-- {{ row.item.color }} -->
          </div>
        </template>
        <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            v-can="'mesh.change_formationline'"
            @click="$bvModal.show(`edit-formation-line-modal-${row.item.id}`)"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.formationline',
                false,
                'Línea De Formación'
              )}`
            "
          ></button-edit>
          <button-delete
            v-can="'mesh.delete_formationline'"
            @click="askForDeleteFormationLine(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'mesh.formationline',
                false,
                'Línea De Formación'
              )}`
            "
          ></button-delete>
          <b-modal
            :id="`edit-formation-line-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.formationline',
              false,
              'Línea De Formación'
            )}`"
            size="md"
            hide-footer
          >
            <FormationLineForm
              :FormationLine="row.item"
              @updated="slotUpdatedFormationLine"
              :institution_id="row.item.school"
              :show_title="false"
            ></FormationLineForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-formation-line-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.formationline',
        false,
        'Línea De Formación'
      )}`"
      size="md"
    >
      <FormationLineForm
        @created="slotCreatedFormationLine"
        :institution_id="this.user.school"
      ></FormationLineForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "FormationLineView",
  components: {
    FormationLineForm: () =>
      import(
        "@/components/mesh/CurricularResources/FormationLine/FormationLineForm"
      ),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    // input_search: {
    //   type: String,
    //   default: "",
    // },
    display_id: {},
  },
  data() {
    return {
      selected_career: null,
      input_search: "",
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      formation_lines: names.FORMATION_LINES,
      careers: names.CAREERS,
      faculties: names.FACULTIES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.change_formationline");
      if (has_permission) return has_permission;
      else return false;
    },
    formationLinesFields() {
      if (!this.allows_crud)
        return this.formation_lines_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.formation_lines_fields;
    },
    breadcrumb_items() {
      return [
        { text: "Definiciones Curriculares", active: true },
        {
          text: this.$getVisibleNames(
            "mesh.formationline",
            true,
            "Líneas de Formación"
          ),
          active: true,
        },
      ];
    },
    formation_lines_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.formationline",
              true,
              "Líneas De Formación"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "faculties",
          label: String(
            this.$getVisibleNames("mesh.faculty", true, "Facultades")
          ),
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "color",
          label: "Color",
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(String(row.name), this.input_search) ||
        this.$filtered(String(row.description), this.input_search) ||
        this.faculties.filter(
          (x) =>
            this.$filtered(x.name, this.input_search) &&
            row.faculties.includes(x.id)
        ).length > 0
      ) {
        return true;
      } else {
        false;
      }
    },
    getFacultyName(faculties) {
      let list = [];
      faculties.forEach((x) => {
        const instance = this.faculties.find((element) => element.id == x);
        if (instance) list.push(instance);
      });
      return list;
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    askForDeleteFormationLine(formation_line_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.formationline",
          false,
          "Línea De Formación"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_FORMATION_LINE, formation_line_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.formationline",
                    false,
                    "Línea De Formación"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    slotCreatedFormationLine() {
      this.$bvModal.hide("new-formation-line-modal");
    },
    slotUpdatedFormationLine(formation_line) {
      this.$bvModal.hide(`edit-formation-line-modal-${formation_line.id}`);
    },
    saveFormationLines(item) {
      this.$store.dispatch(
        names.UPDATE_FORMATION_LINE,
        (this.formation_line = item.id)
      );
      toast("Se actualizó: " + this.formation_line.name);
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch(names.FETCH_FACULTIES);
      this.$store.dispatch(names.FETCH_FORMATION_LINES, this.user.school);
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped>
.career-label {
  padding-top: 0.3em;
  margin-right: 2%;
  font-weight: bold;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}
.title-header {
  margin-top: auto;
}
.formation-line-table {
  width: 100% !important;
}
.search-formation-line {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
.formation-line-color::before {
  min-width: 100px !important;
  height: 20px !important;
  opacity: 1;
  content: " #";
}
@media (max-width: 800px) {
  .search-formation-line {
    margin-right: 7.5%;
  }
}
</style>