<template>
  <div class="tables-container">
    <div class="table-user-available">
      <b-table
        :items="available_users"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon-left
        responsive="sm"
        small
        :selectable="allows_crud"
        select-mode="range"
        @row-selected="slotAvailableSelected"
        :caption-top="true"
      >
        <template v-slot:table-caption>Disponibles</template></b-table
      >
    </div>
    <div class="buttons-container" v-if="allows_crud">
      <b-button variant="info" @click="moveToSelected" class="mb-2" size="sm">
        <b-icon-arrow-right></b-icon-arrow-right>
      </b-button>
      <b-button variant="info" @click="moveToUnSelected" size="sm">
        <b-icon-arrow-left></b-icon-arrow-left>
      </b-button>
    </div>
    <div v-else class="mx-3"></div>
    <div class="table-user-selected">
      <b-table
        :items="unavailable_users"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon-left
        responsive="sm"
        small
        :selectable="allows_crud"
        select-mode="range"
        @row-selected="slotUnavailableSelected"
        :caption-top="true"
      >
        <template v-slot:table-caption>Seleccionados</template></b-table
      >
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "UserList",
  props: {
    section_id: {
      type: [Number, String],
    },
    filter_by_professor: {
      type: Boolean,
      default: false,
    },
    filter_by_student: {
      type: Boolean,
      default: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortBy: "first_name",
      sortDesc: false,
      currentPage: 1,
      perPage: 40,
      fields: [{ key: "name", sortable: true, label: "Nombre" }],
      available_users: [],
      selected_available: [],
      unavailable_users: [],
      selected_unavailable: [],
    };
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      sections: names.SECTIONS,
    }),
    filteredUsers() {
      let filtered_list = this.users;
      if (this.filter_by_student) {
        filtered_list = filtered_list.filter((x) => x.is_student == true);
      }
      if (this.filter_by_professor) {
        filtered_list = filtered_list.filter((x) => x.is_professor == true);
      }
      let final_list = [];
      filtered_list.forEach((element) => {
        final_list.push({
          id: element.id,
          name:
            element.first_name || element.last_name
              ? (
                  element.id +
                  " " +
                  element.first_name +
                  " " +
                  element.last_name
                ).trim()
              : element.id + " " + element.email,
          _rowVariant: "none",
        });
      });
      return final_list;
    },
    section() {
      return this.sections.find((x) => x.id == this.section_id);
    },
  },
  methods: {
    fillUserLists() {
      this.available_users = [];
      this.unavailable_users = [];
      this.filteredUsers.forEach((user) => {
        if (this.section) {
          if (this.section.students.find((x) => x == user.id)) {
            this.unavailable_users.push(user);
          } else if (this.section.professors.find((x) => x == user.id)) {
            this.unavailable_users.push(user);
          } else {
            this.available_users.push(user);
          }
        } else {
          this.available_users.push(user);
        }
      });
    },
    slotAvailableSelected(rows) {
      this.selected_available = rows;
    },
    slotUnavailableSelected(rows) {
      this.selected_unavailable = rows;
    },
    moveToSelected() {
      this.selected_available.forEach((element) => {
        this.unavailable_users.push(element);
        let index = this.available_users.findIndex((x) => x.id == element.id);
        if (index != -1) this.available_users.splice(index, 1);
      });
      this.$emit("changed", this.unavailable_users);
    },
    moveToUnSelected() {
      this.selected_unavailable.forEach((element) => {
        this.available_users.push(element);
        let index = this.unavailable_users.findIndex((x) => x.id == element.id);
        if (index != -1) this.unavailable_users.splice(index, 1);
      });
      this.$emit("changed", this.unavailable_users);
    },
  },
  watch: {
    users() {
      this.fillUserLists();
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_USERS);
    if (this.section_id && !isNaN(this.section_id)) {
      this.$store.dispatch(names.FETCH_SECTION, this.section_id);
    }
  },
};
</script>

<style scoped>
.tables-container {
  display: flex;
  flex-direction: row;
}
.buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.table-user-selected {
  width: 45%;
}
.table-user-available {
  width: 45%;
}
</style>