<template>
  <div>
    <b-form-group
      v-if="!isNaN(egress_profile.id)"
      :label="`${$getVisibleNames('mesh.career', false, 'Programa')}`"
      label-for="select-career"
      label-cols="0"
      label-cols-sm="3"
      class="p-0 m-0"
    >
      <div class="d-flex">
        <div class="d-block w-100">
          <b-form-select
            id="select-career"
            v-model="$v.egress_profile.career.$model"
            :state="validateState('career')"
            aria-describedby="select-career-feedback"
            size="sm"
            :options="careers"
            @change="() => (egress_profile.campuses = [])"
            value-field="id"
            text-field="name"
          ></b-form-select>
          <b-form-invalid-feedback id="select-career-feedback"
            >Debe seleccionar un
            {{
              $getVisibleNames("mesh.career", false, "Programa")
            }}.</b-form-invalid-feedback
          >
        </div>
        <button
          class="btn-add"
          v-b-tooltip.v-secondary.noninteractive="
            `Crear ${$getVisibleNames('mesh.career', false, 'Programa')}.`
          "
          @click="$bvModal.show(`new-career-modal-${egress_profile.id}`)"
        >
          <b-icon font-scale="1" icon="plus-square"></b-icon>
        </button>
      </div>
    </b-form-group>
    <b-form-group
      :label="`${$getVisibleNames(
        'manual.mesh.titulo_profesional',
        false,
        'Título Profesional'
      )}`"
      label-for="input-title"
      label-cols="0"
      label-cols-sm="3"
      class="p-0 m-0 mt-2"
    >
      <b-form-input
        id="input-title"
        v-model="$v.egress_profile.professional_title.$model"
        :state="validateState('professional_title')"
        aria-describedby="input-title-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-title-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="
        !(
          institution.id == 12 ||
          $debug_change_duoc ||
          !institution.show_academic_degree
        )
      "
      :label="`${$getVisibleNames(
        'manual.grado_academico',
        false,
        'Grado Académico'
      )}`"
      label-for="input-degree"
      label-cols="0"
      label-cols-sm="3"
      class="p-0 m-0"
    >
      <b-form-input
        id="input-degree"
        v-model="$v.egress_profile.academic_degree.$model"
        :state="validateState('academic_degree')"
        aria-describedby="input-degree-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-degree-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="`${$getVisibleNames(
        'manual.egress_profile_code',
        false,
        'Código de Perfil de Egreso'
      )}`"
      label-for="input-code"
      label-cols="0"
      label-cols-sm="3"
      class="p-0 m-0"
    >
      <b-form-input
        id="input-code"
        v-model="$v.egress_profile.code.$model"
        :state="validateState('code')"
        @update="
          (value) => {
            egress_profile.code = value.toUpperCase();
          }
        "
        aria-describedby="input-code-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-code-feedback">
        <div v-if="egress_profile.code.length > 256">
          No puede superar a los 256 caracteres.
        </div>
        <div
          v-else-if="
            this.egress_profile_code_list.find(
              (x) =>
                this.$equals(x.code, this.egress_profile.code) &&
                x.id != this.egress_profile.id
            )
          "
        >
          El código ya está siendo utilizado.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      :label="$getVisibleNames('mesh.matterregime', false, 'Régimen')"
      label-for="input-regime"
      class="label mt-1 mb-2 mx-0"
    >
      <b-form-select
        id="input-regime"
        :options="regimesList"
        v-model="$v.egress_profile.regime.$model"
        value-field="id"
        text-field="name"
        :state="validateState('regime')"
        type="number"
        aria-describedby="input-regime-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-regime-feedback"
        >Debe seleccionar un
        {{
          $getVisibleNames("mesh.matterregime", false, "Régimen")
        }}.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Duración"
      label-for="input-semester"
      label-cols="0"
      label-cols-sm="3"
      class="p-0 m-0"
    >
      <b-form-input
        id="input-semester"
        type="number"
        min="1"
        max="14"
        v-model="$v.egress_profile.semester_amount.$model"
        :state="validateState('semester_amount')"
        aria-describedby="input-semester-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-semester-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Modalidad"
      label-for="select-type"
      label-cols="0"
      label-cols-sm="3"
      class="p-0 m-0"
    >
      <b-form-select
        id="select-type"
        v-model="$v.egress_profile.modality.$model"
        :state="validateState('modality')"
        aria-describedby="select-type-feedback"
        size="sm"
        :options="modalities_egress_profiles"
        value-field="id"
        text-field="name"
        ><template #first>
          <b-form-select-option :value="null"
            >N/A
          </b-form-select-option></template
        ></b-form-select
      >
      <b-form-invalid-feedback id="select-type-feedback"
        >Debe seleccionar un Tipo de Perfil.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="
        $getVisibleNames('mesh.facultyarea', false, 'Área de Facultad') + ':'
      "
      label-for="select-faculty_area"
      label-cols="0"
      label-cols-sm="3"
      class="p-0 m-0"
    >
      <b-form-select
        id="select-faculty_area"
        v-model="$v.egress_profile.faculty_area.$model"
        :state="validateState('faculty_area')"
        aria-describedby="select-faculty_area-feedback"
        size="sm"
        :options="faculty_areas"
        value-field="id"
        text-field="name"
        ><template #first>
          <b-form-select-option :value="null"
            >N/A
          </b-form-select-option></template
        ></b-form-select
      >
      <b-form-invalid-feedback id="select-type-feedback"
        >Debe seleccionar un
        {{
          $getVisibleNames("mesh.facultyarea", false, "Área de Facultad")
        }}.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="institution && institution.show_ep_full_init_date"
      label="Fecha de Inicio"
      label-for="input-start-date"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-datepicker
        id="input-start-date"
        v-model="$v.egress_profile.init_date.$model"
        :state="validateState('init_date')"
        placeholder="Definir fecha de inicio"
        aria-describedby="input-start-date-feedback"
        size="sm"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="input-start-date-feedback"
        >Debe ingresar una fecha de inicio válida.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-else
      label="Año de Entrada en Vigencia"
      label-for="input-start-date"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="init_date-input"
        name="init_date-input"
        v-model="$v.egress_profile.init_date.$model"
        type="number"
        :state="validateState('init_date')"
        aria-describedby="input-init_date-live-feedback"
      ></b-form-input>
      <b-form-invalid-feedback id="input-init_date-live-feedback"
        >Ingrese un año válido</b-form-invalid-feedback
      >
    </b-form-group>

    <!-- <b-form-group
      :label="`${$getVisibleNames(
        'manual.ambito_de_desempeño_profesional',
        false,
        'Ámbito de Desempeño Profesional'
      )}`"
      label-for="input-scope"
      label-cols="0"
      label-cols-sm="3"
      class="p-0 m-0"
    >
      <b-form-textarea
        id="input-scope"
        v-model="$v.egress_profile.scope.$model"
        :state="validateState('scope')"
        aria-describedby="input-scope-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-scope-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group> -->
    <!-- <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="0"
      label-cols-sm="3"
      class="p-0 m-0"
    >
      <b-form-textarea
        id="input-description"
        v-model="$v.egress_profile.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group> -->
    <!-- <b-form-group
      label="Comentarios"
      label-for="input-comments"
      label-cols="0"
      label-cols-sm="3"
      class="p-0 m-0"
    >
      <b-form-textarea
        id="input-comments"
        v-model="egress_profile.comments"
        :state="true"
        aria-describedby="input-comments-feedback"
        size="sm"
        class="textarea"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-comments-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group> -->
    <b-form-group
      :label="$getVisibleNames('mesh.campus', true, 'Sedes')"
      label-for="input-campus"
      label-cols="0"
      label-cols-sm="3"
      class="p-0 m-0"
      :description="`Se muestran las ${$getVisibleNames(
        'mesh.campus',
        true,
        'Sedes'
      )} de la ${$getVisibleNames('mesh.faculty', false, 'Facultad')}.`"
    >
      <!-- <b-form-select
        id="input-campus"
        v-model="egress_profile.campuses"
        :options="filteredCampuses"
        value-field="id"
        text-field="name"
        :state="validateState('campuses')"
        aria-describedby="input-campuses-feedback"
        size="sm"
        multiple
        :select-size="
          filteredCampuses.length > 5
            ? 5
            : filteredCampuses.length < 1
            ? 1
            : filteredCampuses.length
        "
      ></b-form-select> -->
      <v-select
        id="input-modality"
        class="v-select-class"
        :options="filteredCampuses"
        v-model="$v.egress_profile.campuses.$model"
        :reduce="(filteredCampuses) => filteredCampuses.id"
        :state="validateState('campuses')"
        :placeholder="`${
          filteredCampuses.length > 0
            ? String(
                'Seleccione una o varias ' +
                  $getVisibleNames('mesh.campus', true, 'Sedes').toLowerCase()
              )
            : String(
                'No cuenta con ' +
                  $getVisibleNames('mesh.campus', true, 'Sedes').toLowerCase() +
                  ' creadas'
              )
        }`"
        multiple
        :selectable="(option) => !egress_profile.campuses.includes(option.id)"
        :dropdown-should-open="dropdownShouldOpen"
        label="name"
        track-by="id"
        size="sm"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No se encontró resultados para <em>{{ search }}</em
            >.
          </template>
        </template>
        <template v-slot:option="option">
          {{ option.name }}
        </template>
      </v-select>
      <b-form-invalid-feedback id="input-campuses-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>

    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(egress_profile.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteEgressProfile"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="saveEgressProfile"
          >Guardar</b-button
        >
      </div>
    </div>
    <b-modal
      :id="`new-career-modal-${egress_profile.id}`"
      :title="`Crear ${$getVisibleNames('mesh.career', false, 'Programa')}`"
      size="md"
      hide-footer
    >
      <CareerForm @created="slotCreatedCareer" :show_title="false"></CareerForm>
    </b-modal>
    <b-modal
      id="modal-change-ep-matter-coruse-wrapper"
      :title="`Duración del ${$getVisibleNames(
        'mesh.egressprofile',
        false,
        'Perfil de Egreso'
      )}: ${egress_profile.semester_amount}`"
      size="xl"
      @hide="returnSemesterAmount"
      hide-footer
    >
      <template
        v-if="
          courses_wrapper.filter(
            (x) =>
              x.init_level > egress_profile.semester_amount &&
              x.egress_profile == egress_profile.id
          ).length > 0
        "
      >
        <CoursesWrapperView
          :semester_amount="
            egress_profile.semester_amount == '' ||
            egress_profile.semester_amount == null
              ? 0
              : egress_profile.semester_amount
          "
          :egress_profile_id="egress_profile.id"
          :allows_crud="true"
          :matrix2_view="false"
          @list_courses_wrapper="updateCoursesWrapperView"
        ></CoursesWrapperView>
      </template>
      <template
        v-if="
          egressProfileMatters.filter(
            (x) =>
              x.plan_level > egress_profile.semester_amount &&
              x.egress_profile == egress_profile.id
          ).length > 0
        "
      >
        <RemoveEPMattersComponent
          :semester_amount="
            egress_profile.semester_amount == '' ||
            egress_profile.semester_amount == null
              ? 0
              : egress_profile.semester_amount
          "
          :egress_profile_id="egress_profile.id"
          :allows_crud="true"
          :matrix2_view="false"
          @list_matters="updateRemoveEPMattersComponent"
        >
        </RemoveEPMattersComponent>
      </template>
      <div class="d-flex">
        <div style="margin-left: 0; margin-right: auto">
          <b-button
            size="sm"
            class="ml-3"
            v-b-tooltip.v-danger.noninteractive="
              `Al cancelar, la duración del ${$getVisibleNames(
                'mesh.egressprofile',
                false,
                'Perfil de Egreso'
              )} volverá a su estado original.`
            "
            @click="$bvModal.hide(`modal-change-ep-matter-coruse-wrapper`)"
          >
            Cancelar
          </b-button>
        </div>
        <div style="margin-left: auto; margin-right: 0">
          <template
            v-if="
              list_courses_wrapper.filter(
                (x) =>
                  (x.init_level > egress_profile.semester_amount ||
                    x.end_level < x.init_level ||
                    x.end_level > egress_profile.semester_amount ||
                    x.init_level <= 0 ||
                    x.end_semester <= 0 ||
                    x.init_level == '' ||
                    x.end_level == '') &&
                  x.egress_profile == egress_profile.id
              ).length == 0 &&
              list_matters.filter(
                (x) =>
                  (x.plan_level > egress_profile.semester_amount ||
                    x.plan_level == '' ||
                    x.plan_level <= 0) &&
                  x.egress_profile == egress_profile.id
              ).length == 0
            "
          >
            <b-button
              class="mr-1"
              size="sm"
              @click="removeMattersAndCoursesWrapper()"
              >Aplicar cambios</b-button
            >
          </template>
          <template v-else>
            <b-button class="mr-1" size="sm" disabled>Aplicar cambios</b-button>
          </template>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "EgressProfileForm",
  mixins: [validationMixin],
  components: {
    vSelect,
    // StyleTextArea: () => import("@/components/reusable/StyledTextArea"),
    CareerForm: () =>
      import("@/components/mesh/CurricularResources/Career/CareerForm"),
    CoursesWrapperView: () =>
      import("@/components/mesh/Matrix2/CoursesWrapper/CoursesWrapperView"),
    RemoveEPMattersComponent: () =>
      import("@/components/mesh/Matrix2/RemoveEPMattersComponent"),
  },
  props: {
    EgressProfile: {
      type: Object,
    },
    career_id: {
      type: Number,
      required: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      egress_profile: {
        id: this.EgressProfile ? this.EgressProfile.id : generateUniqueId(),
        // description: this.EgressProfile ? this.EgressProfile.description : "",
        // scope: this.EgressProfile ? this.EgressProfile.scope : "",
        academic_degree: this.EgressProfile
          ? this.EgressProfile.academic_degree
          : "",
        professional_title: this.EgressProfile
          ? this.EgressProfile.professional_title
          : "",
        code: this.EgressProfile ? this.EgressProfile.code : "",
        init_date: this.EgressProfile ? this.EgressProfile.init_date : null,
        faculty_area: this.EgressProfile
          ? this.EgressProfile.faculty_area
          : null,
        modality: this.EgressProfile ? this.EgressProfile.modality : null,
        regime: this.EgressProfile ? this.EgressProfile.regime : null,
        // egress_profile_type: this.EgressProfile
        //   ? this.EgressProfile.egress_profile_type
        //   : null,
        career: this.EgressProfile ? this.EgressProfile.career : this.career_id,
        semester_amount: this.EgressProfile
          ? this.EgressProfile.semester_amount
          : 10,
        campuses: this.EgressProfile ? this.EgressProfile.campuses : [],
        // comments: this.EgressProfile ? this.EgressProfile.comments : "",
      },
      egress_profile_code_list: [],
      faculty_areas: [],
      list_matters: [],
      remove_matters: [],
      list_courses_wrapper: [],
      remove_courses_wrapper: [],
      // egress_profile_types: [],
    };
  },
  validations: {
    egress_profile: {
      code: {
        maxLength: maxLength(256),
        isUnique() {
          if (this.egress_profile.code == "") return true;
          else if (
            this.egress_profile_code_list.find(
              (x) =>
                this.$equals(x.code, this.egress_profile.code) &&
                x.id != this.egress_profile.id
            )
          )
            return false;
          else return true;
        },
      },
      regime: { required },
      modality: {},
      init_date: {
        isValid() {
          if (this.institution && !this.institution.show_ep_full_init_date) {
            if (
              (this.egress_profile.init_date > 1900 &&
                this.egress_profile.init_date <
                  parseInt(new Date().getFullYear()) + 30) ||
              this.egress_profile.init_date == "" ||
              this.egress_profile.init_date == null
            )
              return true;
            else return false;
          } else {
            return true;
          }
        },
      },
      academic_degree: {},
      professional_title: { required },
      faculty_area: {},
      // egress_profile_type: { required },
      career: { required },
      semester_amount: { required },
      campuses: {},
    },
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      careers: names.CAREERS,
      faculties: names.FACULTIES,
      campuses: names.CAMPUSES,
      regimes: names.REGIMES,
      modalities_egress_profiles: names.MODALITIES_EGRESS_PROFILE,
      egress_profiles: names.EGRESS_PROFILES,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      courses_wrapper: names.COURSES_WRAPPER,
    }),
    regimesList() {
      //Bimestral(2) - Trimestral(3) - Semestral(6)
      return this.regimes
        .filter((x) => [2, 3, 6].includes(x.months_amount))
        .sort((a, b) => b.months_amount - a.months_amount);
    },
    career() {
      return this.careers.find((x) => x.id == this.egress_profile.career);
    },
    faculty() {
      if (!this.career) return null;
      return this.faculties.find((x) => x.id == this.career.faculty);
    },
    filteredCampuses() {
      let list = [];
      if (!this.faculty) return list;
      this.faculty.campuses.forEach((campus_id) => {
        const instance = this.campuses.find((x) => x.id == campus_id);
        if (instance) list.push(instance);
      });
      return list;
    },
  },
  methods: {
    changeEPMatterAndCourseWrapper() {
      if (
        this.egressProfileMatters.filter(
          (x) =>
            x.plan_level > this.egress_profile.semester_amount &&
            x.egress_profile == this.egress_profile.id
        ).length > 0 ||
        this.courses_wrapper.filter(
          (x) =>
            x.init_level > this.egress_profile.semester_amount &&
            x.egress_profile == this.egress_profile.id
        ).length > 0
      ) {
        if (this.egress_profile.semester_amount >= 0)
          this.$bvModal.show("modal-change-ep-matter-coruse-wrapper");
      } else this.updateEgressProfile();
    },
    returnSemesterAmount() {
      if (
        this.egressProfileMatters.filter(
          (x) =>
            x.plan_level > this.egress_profile.semester_amount &&
            x.egress_profile == this.egress_profile.id
        ).length > 0 ||
        this.courses_wrapper.filter(
          (x) =>
            x.init_level > this.egress_profile.semester_amount &&
            x.egress_profile == this.egress_profile.id
        ).length > 0
      ) {
        this.egress_profile.semester_amount =
          this.egress_profiles.find((x) => x.id == this.egress_profile.id)
            .semester_amount || 1;
      }
    },
    removeMattersAndCoursesWrapper() {
      this.$swal({
        title: `¿Está seguro de aplicar los cambios?`,
        width: "auto",
        text: `Esta acción no se podrá revertir.`,
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          // this.saveMatterAndCoursesWrapper();
          this.$restful
            .Post(`/mesh/edit_or_delete_course_or_epm/`, {
              egress_profile_matters_to_change: this.list_matters.map((x) => ({
                id: x.id,
                plan_level: x.plan_level,
              })),
              course_wrappers_to_change: this.list_courses_wrapper.map((x) => ({
                id: x.id,
                init_level: x.init_level,
                end_level: x.end_level,
              })),
              egress_profile_matters_to_delete: this.remove_matters.map(
                (x) => x.id
              ),
              course_wrappers_to_delete: this.remove_courses_wrapper.map(
                (x) => x.id
              ),
            })
            .then(() => {
              this.list_matters.forEach((x) => {
                this.$store.commit(names.MUTATE_EGRESS_PROFILE_MATTER, x);
              });
              this.remove_matters.forEach((x) => {
                this.$store.commit(
                  names.MUTATE_DELETE_EGRESS_PROFILE_MATTER,
                  x.id
                );
              });
              this.list_courses_wrapper.forEach((x) => {
                this.$store.commit(names.MUTATE_COURSE_WRAPPER, x);
              });
              this.remove_courses_wrapper.forEach((x) => {
                this.$store.commit(names.MUTATE_DELETE_COURSE_WRAPPER, x.id);
              });
              this.updateEgressProfile();
              this.$bvModal.hide("modal-change-ep-matter-coruse-wrapper");
            });
        }
      });
    },
    // async saveMatterAndCoursesWrapper() {
    //   for await (let ep_matter of this.remove_matters) {
    //     await this.$store.dispatch(
    //       names.DELETE_EGRESS_PROFILE_MATTER,
    //       ep_matter.id
    //     );
    //   }
    //   for await (let course_wrapper of this.remove_courses_wrapper) {
    //     await this.$store.dispatch(
    //       names.DELETE_COURSE_WRAPPER,
    //       course_wrapper.id
    //     );
    //   }
    //   for await (let ep_matter of this.list_matters) {
    //     await this.$store.dispatch(names.PATCH_EGRESS_PROFILE_MATTER, {
    //       egress_profile_matter_id: ep_matter.id,
    //       item: { plan_level: ep_matter.plan_level },
    //     });
    //   }
    //   for await (let course_wrapper of this.list_courses_wrapper) {
    //     await this.$store.dispatch(names.PATCH_COURSE_WRAPPER, {
    //       course_wrapper_id: course_wrapper.id,
    //       item: {
    //         init_level: course_wrapper.init_level,
    //         end_level: course_wrapper.end_level,
    //       },
    //     });
    //   }
    //   this.updateEgressProfile();
    //   this.$bvModal.hide("modal-change-ep-matter-coruse-wrapper");
    // },
    updateRemoveEPMattersComponent(list_matters, remove_matters) {
      this.list_matters = list_matters;
      this.remove_matters = remove_matters;
    },
    updateCoursesWrapperView(list_courses_wrapper, remove_courses_wrapper) {
      this.list_courses_wrapper = list_courses_wrapper;
      this.remove_courses_wrapper = remove_courses_wrapper;
    },
    dropdownShouldOpen(VueSelect) {
      if (this.filteredCampuses.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    slotCreatedCareer(career) {
      this.egress_profile.career = career.id;
      this.egress_profile.campuses = [];
      this.$bvModal.hide(`new-career-modal-${this.egress_profile.id}`);
    },
    validateState(key) {
      const { $dirty, $error } = this.$v.egress_profile[key];
      return $dirty ? !$error : null;
    },
    // fetchEgressProfileTypes() {
    //   this.$restful.Get(`/mesh/egress-profile-type/`).then((response) => {
    //     this.egress_profile_types = response;
    //   });
    // },
    saveEgressProfile() {
      this.$v.egress_profile.$touch();
      if (this.$v.egress_profile.$anyError) {
        return;
      }
      if (!isNaN(this.egress_profile.id)) this.changeEPMatterAndCourseWrapper();
      else this.createEgressProfile();
    },
    createEgressProfile() {
      let payload = { ...this.egress_profile };
      if (this.institution && !this.institution.show_ep_full_init_date)
        if (
          this.egress_profile.init_date != "" &&
          this.egress_profile.init_date != null
        )
          payload.init_date = String(this.egress_profile.init_date) + "-01-01";
        else if (this.egress_profile.init_date == "") payload.init_date = null;
      this.$store
        .dispatch(names.POST_EGRESS_PROFILE, payload)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.egressprofile",
                false,
                "Perfil de Egreso"
              ) + " creado."
            )
          );
          this.$emit("created", response);
        });
    },
    updateEgressProfile() {
      let payload = { ...this.egress_profile };
      if (this.institution && !this.institution.show_ep_full_init_date)
        if (
          this.egress_profile.init_date != "" &&
          this.egress_profile.init_date != null
        )
          payload.init_date = String(this.egress_profile.init_date) + "-01-01";
        else if (this.egress_profile.init_date == "") payload.init_date = null;
      this.$store
        .dispatch(names.UPDATE_EGRESS_PROFILE, payload)
        .then((response) => {
          this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
            egress_profile_id: response.id,
          });
          toast(
            String(
              this.$getVisibleNames(
                "mesh.egressprofile",
                false,
                "Perfil de Egreso"
              ) + " actualizado."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteEgressProfile() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.egressprofile",
          false,
          "Perfil de Egreso"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_EGRESS_PROFILE, this.egress_profile.id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.egressprofile",
                    false,
                    "Perfil de Egreso"
                  ) + " eliminado."
                )
              );
              this.$emit("deleted", this.egress_profile);
            });
        }
      });
    },
    fetchFacultyArea() {
      if (this.faculty)
        this.$restful
          .Get(`/mesh/faculty-area/?faculty=${this.faculty.id}`)
          .then((response) => {
            this.faculty_areas = response;
          });
    },
  },
  created() {
    this.$restful.Get(`/mesh/egress_profile_code_list/`).then((response) => {
      this.egress_profile_code_list = response;
    });
    if (!isNaN(this.egress_profile.id)) {
      this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
        egress_profile_id: this.egress_profile.id,
      });
      this.$store.dispatch(names.FETCH_MATTERS, {
        egress_profile_id: this.egress_profile.id,
      });
      this.$store.dispatch(names.FETCH_COURSES_WRAPPER, {
        egress_profile_id: this.egress_profile.id,
      });
    }
    this.$store.dispatch(names.FETCH_REGIMES);
    this.$store.dispatch(names.FETCH_CAMPUSES);
    // this.fetchEgressProfileTypes();
  },
  mounted() {
    if (
      !isNaN(this.egress_profile.id) &&
      this.institution &&
      !this.institution.show_ep_full_init_date
    )
      if (this.egress_profile.init_date != null)
        this.egress_profile.init_date = parseInt(
          this.egress_profile.init_date.substr(0, 4)
        );
    this.fetchFacultyArea();
  },
};
</script>

<style scoped>
.textarea {
  border: 1px solid rgb(160, 160, 160);
}
.btn-add {
  border: none;
  background: transparent;
  font-size: 13pt;
  padding: 0;
  margin-left: 0.7rem;
}
.v-select >>> .vs__search,
.v-select >>> .vs__search:focus {
  font-size: 0.875rem !important;
  color: #495057 !important;
}

.v-select-class >>> .vs__selected {
  background-color: #99c9d8 !important;
  color: #fff !important;
}
.v-select >>> .vs__clear {
  margin-bottom: 0.2rem !important;
}
</style>