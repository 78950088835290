<template>
  <div :id="`wrapper-container-${item_id}`" class="wrapper-section-container">
    <b-button
      :id="`btn-wrapper-${item_id}`"
      v-b-tooltip.hover.v-secondary.noninteractive="'Acciones'"
      ref="btn-toggle"
      class="btn-toggle"
      @click="toggleButtons()"
      ><b-icon icon="three-dots"></b-icon>
    </b-button>
    <b-popover
      ref="popover"
      class="popover-wrapper"
      v-closable="{
        exclude: ['btn-toggle'],
        handler: 'onClose',
      }"
      triggers="click blur"
      :show.sync="is_active"
      :target="`btn-wrapper-${item_id}`"
    >
      <slot name="btn-slot"></slot>
    </b-popover>
  </div>
</template>

<script>
import Vue from "vue";
import VueClosable from "vue-closable";
Vue.use(VueClosable);
export default {
  name: "ButtonWrapper",
  props: {
    item_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      is_active: false,
    };
  },
  methods: {
    toggleButtons() {
      this.is_active = !this.is_active;
      return this.is_active;
    },
    onClose() {
      this.is_active = false;
      this.$refs.popover.$emit("close");
    },
  },
  mounted() {},
};
</script>

<style scoped>
.wrapper-section-container {
  display: block;
  height: auto;
}
.btn-toggle {
  padding: 0.3em;
  margin: 0;
  max-height: 2em !important;
  border-radius: 50%;
}
.popover.b-popover >>> .popover-body {
  padding: 0.2rem 0.4rem !important;
}
</style>