<template>
  <div>
    <h3>RESUMEN PLAN DE ESTUDIO</h3>
    <table class="table">
      <thead style="background-color: var(--primary-color)">
        <tr>
          <th>NIVEL</th>
          <th
            class="header-row-rotated"
            v-for="hour in time_allocations.filter(
              (x) =>
                x.uses_module_minutes == true &&
                egress_profile_time_allocations.includes(x.id)
            )"
            :key="hour.id"
          >
            {{ hour.name }}
          </th>
          <th class="header-row-rotated">
            {{
              $getVisibleNames(
                "manual.total_pedagogical_hours",
                true,
                "Total de Horas Pedagógicas"
              )
            }}
          </th>
          <th class="header-row-rotated">
            {{
              $getVisibleNames(
                "manual.total_pedagogical_hours_to_chronological",
                true,
                "Total de Horas Pedagógicas (En Cronológicas)"
              )
            }}
          </th>
          <th
            v-for="hour in time_allocations.filter(
              (x) =>
                x.uses_module_minutes == false &&
                egress_profile_time_allocations.includes(x.id)
            )"
            :key="hour.id"
          >
            {{ hour.name }}
          </th>
          <th class="header-row-rotated">
            {{
              $getVisibleNames(
                "manual.total_chronological_hours",
                true,
                "Total de Horas Cronológicas"
              )
            }}
          </th>
          <th class="header-row-rotated">
            {{
              $getVisibleNames(
                "manual.total_hours_sum_to_chronologica",
                true,
                "Total de Horas (Pedagógicas + Cronológicas)"
              )
            }}
          </th>
          <!-- <th class="header-row-rotated" title="Módulos Teóricas">
            Módulos Teóricos
          </th>
          <th class="header-row-rotated" title="Módulos Lab/Talleres">
            Módulos Lab/Talleres
          </th>
          <th class="header-row-rotated" title="Módulos Terreno/Campo Clínico">
            Módulos Terreno/Campo Clínico
          </th>
          <th scope="col" class="header-row-rotated" title="Módulos Ayudantía">
            Módulos Ayudantía
          </th>
          <th class="header-row-rotated" title="Módulos Presenciales Semanales">
            Módulos Presenciales Semanales
          </th>
          <th
            class="header-row-rotated"
            title="Total de Horas Presenciales Cronológicas"
          >
            Total de Horas Presenciales Cronológicas
          </th>
          <th class="header-row-rotated" title="Horas Autónomas Totales">
            Horas Autónomas Semanales
          </th>
          <th
            class="header-row-rotated"
            :title="
              $getVisibleNames(
                'manual.total_autonomous_hours',
                false,
                'Total de Horas Autónomas'
              )
            "
          >
            {{
              $getVisibleNames(
                "manual.total_autonomous_hours",
                false,
                "Total de Horas Autónomas"
              )
            }}
          </th> -->
          <th
            class="header-row-rotated"
            :title="
              $getVisibleNames('manual.credits', true, 'Créditos SCT Totales')
            "
          >
            {{
              $getVisibleNames("manual.credits", true, "Créditos SCT Totales")
            }}
          </th>
        </tr>
      </thead>
      <tbody v-if="egressProfile">
        <SummarySemesterRow
          v-for="semester in egressProfile.semester_amount"
          :key="semester"
          :egress_profile_id="egress_profile_id"
          :egress_profile_time_allocations="egress_profile_time_allocations"
          :semester="semester"
        ></SummarySemesterRow>
      </tbody>
      <tfoot>
        <tr v-if="coursesWrapperMaxCredits > 0">
          <td class="font-weight-normal">
            <span
              class="text-break"
              @click="(e) => e.target.classList.toggle('text-break')"
              v-b-tooltip.v-secondary.noninteractive.hover="
                $getVisibleNames(
                  'mesh.coursewrapper',
                  true,
                  'Envoltorios de Asignaturas'
                ).toUpperCase()
              "
            >
              {{
                $getVisibleNames(
                  "mesh.coursewrapper",
                  true,
                  "Envoltorios de Asignaturas"
                ).toUpperCase()
              }}
            </span>
          </td>
          <td
            v-for="hour in time_allocations.filter(
              (x) =>
                x.uses_module_minutes == true &&
                egress_profile_time_allocations.includes(x.id)
            )"
            :key="hour.id"
          ></td>
          <td></td>
          <td></td>
          <td
            v-for="hour in time_allocations.filter(
              (x) =>
                x.uses_module_minutes == false &&
                egress_profile_time_allocations.includes(x.id)
            )"
            :key="hour.id"
          ></td>
          <td></td>
          <td></td>
          <td class="font-weight-normal">
            <span
              v-b-tooltip.v-secondary.top.noninteractive="
                'Máximo de Créditos sugeridos.'
              "
            >
              {{ coursesWrapperMaxCredits }}
            </span>
          </td>
        </tr>
        <tr>
          <td>TOTAL</td>
          <td
            v-for="hour in time_allocations.filter(
              (x) =>
                x.uses_module_minutes == true &&
                egress_profile_time_allocations.includes(x.id)
            )"
            :key="hour.id"
          >
            {{ countTimeAllocationHours(hour.id) }}
          </td>
          <td
            :class="
              limit_study_plan &&
              limit_study_plan.max_hours != 0 &&
              limit_study_plan.max_hours < totalPedagogical
                ? 'limit-class'
                : ''
            "
          >
            {{ totalPedagogical }}
          </td>
          <td v-if="profileType">
            {{
              (totalPedagogical * (parseFloat(profileType.module_minutes) / 60))
                | Round
            }}
          </td>
          <td
            v-for="hour in time_allocations.filter(
              (x) =>
                x.uses_module_minutes == false &&
                egress_profile_time_allocations.includes(x.id)
            )"
            :key="hour.id"
          >
            {{ countTimeAllocationHours(hour.id) }}
          </td>
          <td>{{ totalChronological }}</td>
          <td>{{ totalHours }}</td>
          <!-- <td>{{ totalWeekTheoricHours | Round }}</td>
          <td>{{ totalWeekLabHours | Round }}</td>
          <td>{{ totalWeekGroundHours | Round }}</td>
          <td>{{ totalWeekAssistantshipHours | Round }}</td>
          <td>
            <span
              :class="
                limit_study_plan &&
                limit_study_plan.max_hours != 0 &&
                limit_study_plan.max_hours < totalWeekClassroomHours
                  ? 'limit-class'
                  : ''
              "
              >{{ totalWeekClassroomHours | Round }}</span
            >
          </td>
          <td>{{ Math.round(totalSemesterClassroomHours) }}</td>
          <td>{{ totalWeekAutonomousHours | Round }}</td>
          <td>{{ Math.round(totalSemesterAutonomousHours) }}</td> -->
          <td>
            <span
              :class="
                limit_study_plan &&
                limit_study_plan.max_credits != 0 &&
                limit_study_plan.max_credits < totalCredits
                  ? 'limit-class'
                  : ''
              "
              >{{ totalCredits }}</span
            >
          </td>
        </tr>
        <tr
          v-can="'mesh.change_egressprofile'"
          v-if="
            limit_study_plan &&
            (limit_study_plan.max_hours != 0 ||
              limit_study_plan.max_credits != 0)
          "
        >
          <td>TOTAL SUGERIDO</td>
          <td
            v-for="hour in time_allocations.filter(
              (x) =>
                x.uses_module_minutes == true &&
                egress_profile_time_allocations.includes(x.id)
            )"
            :key="hour.id"
          ></td>
          <td>
            <span
              v-if="limit_study_plan && limit_study_plan.max_hours != 0"
              v-b-tooltip.v-secondary.top.noninteractive="
                'Máximo de Módulos sugerido.'
              "
              >{{ limit_study_plan.max_hours }}</span
            ><span v-else>-</span>
          </td>
          <td></td>
          <td
            v-for="hour in time_allocations.filter(
              (x) =>
                x.uses_module_minutes == false &&
                egress_profile_time_allocations.includes(x.id)
            )"
            :key="hour.id"
          ></td>
          <td></td>
          <td></td>
          <!-- <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <span
              v-if="limit_study_plan && limit_study_plan.max_hours != 0"
              v-b-tooltip.v-secondary.top.noninteractive="
                'Máximo de Módulos sugerido.'
              "
              >{{ limit_study_plan.max_hours }}</span
            ><span v-else>-</span>
          </td>
          <td></td>
          <td></td>
          <td></td> -->
          <td>
            <span
              v-if="limit_study_plan && limit_study_plan.max_credits != 0"
              v-b-tooltip.v-secondary.top.noninteractive="
                'Máximo de Créditos sugeridos.'
              "
              >{{ limit_study_plan.max_credits }}</span
            ><span v-else>-</span>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import SummarySemesterRow from "./SummarySemesterRow";

export default {
  name: "Summary",
  components: {
    SummarySemesterRow,
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    egress_profile_time_allocations: {
      type: Array,
      default: () => [],
    },
    limit_study_plan: {
      type: Object,
      default: null,
    },
  },
  methods: {
    countTimeAllocationHours(time_allocation_id) {
      let hours = 0;
      this.matterList.forEach((matter) => {
        let matter_hour = [];
        if (matter.use_matter_values) {
          matter_hour = this.matter_time_allocations.find(
            (x) =>
              x.matter == matter.matter &&
              x.time_allocation == time_allocation_id
          );
        } else {
          matter_hour = this.ep_matter_time_allocations.find(
            (x) =>
              x.egress_profile_matter == matter.id &&
              x.time_allocation == time_allocation_id
          );
        }
        if (matter_hour && matter_hour.counts_towards_credits) {
          hours += parseFloat(matter_hour.hours);
        }
      });
      return Math.round(hours);
    },
  },
  computed: {
    ...mapGetters({
      egressProfiles: names.EGRESS_PROFILES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      regimes: names.REGIMES,
      profile_types: names.PROFILE_TYPES,
      matters: names.MATTERS,
      school: "getInstitution",
      time_allocations: names.TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      courses_wrapper: names.COURSES_WRAPPER,
    }),
    isAnual() {
      const months_amount_egress_profile = this.regimes.find(
        (x) => x.id == this.egressProfile.regime
      );
      if (months_amount_egress_profile) {
        if (months_amount_egress_profile.months_amount == 6)
          return this.regimes.find(
            (x) =>
              x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
          );
        else if (months_amount_egress_profile.months_amount == 3)
          return this.regimes.find(
            (x) =>
              x.name.includes("Semestral") || parseInt(x.months_amount) / 3 == 2
          );
        else if (months_amount_egress_profile.months_amount == 2)
          return this.regimes.find(
            (x) =>
              x.name.includes("Trimestral") || parseInt(x.months_amount) == 3
          );
      }
      return this.regimes.find(
        (x) => x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
      );
    },
    coursesWrapperList() {
      return this.courses_wrapper.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
    },
    wrapperMatterIds() {
      let list = [];
      this.coursesWrapperList.forEach((x) => {
        list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    matterList() {
      return this.egress_profiles_matters.filter(
        (x) =>
          x.egress_profile == this.egress_profile_id &&
          !this.wrapperMatterIds.includes(x.id)
      );
    },
    coursesWrapperMaxCredits() {
      let max_credits = 0;
      this.coursesWrapperList.forEach((x) => {
        max_credits += x.credits;
      });
      return max_credits;
    },
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    profileType() {
      if (!this.egressProfile) return null;
      return this.profile_types.find(
        (x) => x.id == this.egressProfile.egress_profile_type
      );
    },
    totalPedagogical() {
      let hours = 0;
      const pedagogical_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == true)
        .map((x) => x.id);
      this.matterList.forEach((matter) => {
        let matter_hours = [];
        if (matter.use_matter_values) {
          matter_hours = this.matter_time_allocations.filter(
            (x) =>
              x.matter == matter.matter &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        } else {
          matter_hours = this.ep_matter_time_allocations.filter(
            (x) =>
              x.egress_profile_matter == matter.id &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        }
        matter_hours.forEach((hour) => {
          hours += parseFloat(hour.hours);
        });
      });
      return hours;
    },
    totalChronological() {
      let hours = 0;
      const pedagogical_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == false)
        .map((x) => x.id);
      this.matterList.forEach((matter) => {
        let matter_hours = [];
        if (matter.use_matter_values) {
          matter_hours = this.matter_time_allocations.filter(
            (x) =>
              x.matter == matter.matter &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        } else {
          matter_hours = this.ep_matter_time_allocations.filter(
            (x) =>
              x.egress_profile_matter == matter.id &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        }
        matter_hours.forEach((hour) => {
          hours += parseFloat(hour.hours);
        });
      });
      return hours;
    },
    totalHours() {
      if (!this.profileType) return 0;
      const total_hours =
        this.totalPedagogical * (this.profileType.module_minutes / 60) +
        this.totalChronological;
      return total_hours ? total_hours : 0;
    },
    // totalWeekTheoricHours() {
    //   let hours = 0;
    //   this.matterList.forEach((matter) => {
    //     hours += matter.week_theoric_hours;
    //     if (matter.regime == this.isAnual.id)
    //       hours += matter.week_theoric_hours;
    //   });
    //   return hours;
    // },
    // totalWeekLabHours() {
    //   let hours = 0;
    //   this.matterList.forEach((matter) => {
    //     hours += matter.week_lab_hours;
    //     if (matter.regime == this.isAnual.id) hours += matter.week_lab_hours;
    //   });
    //   return hours;
    // },
    // totalWeekGroundHours() {
    //   let hours = 0;
    //   this.matterList.forEach((matter) => {
    //     hours += matter.week_ground_hours;
    //     if (matter.regime == this.isAnual.id) hours += matter.week_ground_hours;
    //   });
    //   return hours;
    // },
    // totalWeekAssistantshipHours() {
    //   let hours = 0;
    //   this.matterList.forEach((matter) => {
    //     hours += matter.week_assistantship_hours;
    //     if (matter.regime == this.isAnual.id)
    //       hours += matter.week_assistantship_hours;
    //   });
    //   return hours;
    // },
    // totalWeekClassroomHours() {
    //   return (
    //     this.totalWeekTheoricHours +
    //     this.totalWeekLabHours +
    //     this.totalWeekGroundHours +
    //     this.totalWeekAssistantshipHours
    //   );
    // },
    // totalSemesterClassroomHours() {
    //   let hours = 0;
    //   if (!this.profileType) return 0;
    //   this.matterList.forEach((matter) => {
    //     hours +=
    //       (matter.week_theoric_hours +
    //         matter.week_lab_hours +
    //         matter.week_ground_hours +
    //         matter.week_assistantship_hours) *
    //       matter.weeks *
    //       (this.profileType.module_minutes / 60);
    //     if (matter.regime == this.isAnual.id)
    //       hours +=
    //         (matter.week_theoric_hours +
    //           matter.week_lab_hours +
    //           matter.week_ground_hours +
    //           matter.week_assistantship_hours) *
    //         matter.weeks *
    //         (this.profileType.module_minutes / 60);
    //   });
    //   return hours;
    // },
    // totalWeekAutonomousHours() {
    //   let hours = 0;
    //   this.matterList.forEach((matter) => {
    //     hours += matter.week_autonomous_hours;
    //     if (matter.regime == this.isAnual.id)
    //       hours += matter.week_autonomous_hours;
    //   });
    //   return hours;
    // },
    // totalSemesterAutonomousHours() {
    //   let hours = 0;
    //   this.matterList.forEach((matter) => {
    //     hours += matter.total_autonomous_hours;
    //     if (matter.regime == this.isAnual.id)
    //       hours += matter.total_autonomous_hours;
    //   });
    //   return hours;
    // },
    totalCredits() {
      // let totalClassroomHours = 0;
      let totalSCT = 0;
      this.matterList.forEach((matter) => {
        if (matter.use_matter_values) {
          const old_matter = this.matters.find((x) => x.id == matter.matter);
          if (old_matter) totalSCT += old_matter.credits;
        } else {
          totalSCT += matter.credits;
        }
      });
      totalSCT += this.coursesWrapperMaxCredits;
      return totalSCT;
    },
  },
};
</script>

<style scoped>
.table thead th {
  font-size: var(--secondary-font-size);
  vertical-align: middle;
  background-color: var(--kl-menu-color) !important;
  color: white;
  border: solid black;
  border-width: thin;
}
.table tr td {
  font-size: 10px;
  font-weight: bold;
  padding: 2px 2px;
  border: solid black;
  border-width: thin;
}
.table tfoot tr td {
  font-size: var(--secondary-font-size);
  font-weight: bold;
}
.header-row-rotated {
  /* transform: rotate(270deg); */
  text-align: center;
  padding: 0px 0px 0px 4px;
  font-size: var(--secondary-font-size);
  color: white !important;
}
.limit-class {
  color: red;
}
.text-break {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>