var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{attrs:{"small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"pl-3 evaluation-header",staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.allows_crud ? 8 : 7}},[_vm._v(" ACTIVIDADES Y EVALUACIONES "),_c('div',{staticClass:"evaluation-button-div"},[(_vm.allows_crud)?_c('b-button',{staticClass:"mx-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`add-matter-evaluation-modal-${_vm.matter_id}`)}}},[_vm._v("Crear Actividad o Evaluación")]):_vm._e()],1)])],1),_c('b-tr',[(_vm.allows_crud && _vm.matters_evaluations.length > 0)?_c('b-td',{staticClass:"text-center",staticStyle:{"width":"3%"}}):_vm._e(),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"15%"}},[_c('strong',[_vm._v(" NOMBRE ")])]),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"15%"}},[_c('strong',[_vm._v(" DESCRIPCIÓN ")])]),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"15%"}},[_c('strong',[_vm._v(" TIPO ")])]),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"15%"}},[_c('strong',[_vm._v("PONDERACIÓN")])]),(_vm.institution && _vm.institution.show_evaluation_criteria_macro)?_c('b-td',{staticClass:"text-center",staticStyle:{"width":"15%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamacro", true, "Criterios de Evaluación Macro" ).toUpperCase()))])]):_vm._e(),_c('b-td',{staticClass:"text-center",staticStyle:{"width":"15%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamicro", true, "Criterios de Evaluación Micro" ).toUpperCase()))])]),(_vm.allows_crud && _vm.matters_evaluations.length > 0)?_c('b-td',{staticStyle:{"width":"2%"}}):_vm._e()],1)],1),_vm._l((_vm.learning_experiences),function(item){return [(
          _vm.matters_evaluations.filter((x) => x.learning_experience == item.id)
            .length > 0
        )?[_c('b-thead',{key:'learning-experiences-1' + item.id},[_c('b-tr',[_c('b-th',{staticClass:"pl-3 evaluation-header",staticStyle:{"text-align":"left"},attrs:{"colspan":_vm.allows_crud ? 8 : 7}},[_vm._v(_vm._s(item.name))])],1)],1),_c('MatterProgramEvaluationDraggable',{key:'learning-experiences-2' + item.id + _vm.tmp_key,attrs:{"allows_crud":_vm.allows_crud,"mattersEvaluations":_vm.matters_evaluations.filter(
              (x) => x.learning_experience == item.id
            ),"egress_profile_matter_id":_vm.egress_profile_matter_id,"matter_id":_vm.matter_id,"EvaluationType":_vm.evaluation_types,"EvaluationSituations":_vm.evaluation_situations,"ExaminerTypes":_vm.examiner_types,"EvaluativeAgentTypes":_vm.evaluative_agent_types,"WorkTypes":_vm.work_types,"MatEvaStuEnvTimAll":_vm.mat_eva_stu_env_tim_all},on:{"created_support_resource":_vm.createdSupportResource}})]:_vm._e()]}),(
        _vm.matters_evaluations.filter((x) => x.learning_experience == null)
          .length > 0
      )?[(_vm.learning_experiences.length > 0)?_c('b-thead',{key:'learning-experiences-1' + null},[_c('b-tr',[_c('b-th',{staticClass:"p-3 evaluation-header",staticStyle:{"text-align":"left"},attrs:{"colspan":_vm.allows_crud ? 8 : 7}})],1)],1):_vm._e(),_c('MatterProgramEvaluationDraggable',{key:'learning-experiences-2' + null + _vm.tmp_key,attrs:{"allows_crud":_vm.allows_crud,"mattersEvaluations":_vm.matters_evaluations.filter((x) => x.learning_experience == null),"egress_profile_matter_id":_vm.egress_profile_matter_id,"matter_id":_vm.matter_id,"EvaluationType":_vm.evaluation_types,"EvaluationSituations":_vm.evaluation_situations,"ExaminerTypes":_vm.examiner_types,"EvaluativeAgentTypes":_vm.evaluative_agent_types,"WorkTypes":_vm.work_types,"MatEvaStuEnvTimAll":_vm.mat_eva_stu_env_tim_all},on:{"created_support_resource":_vm.createdSupportResource}})]:_vm._e()],2),_c('b-modal',{attrs:{"id":`add-matter-evaluation-modal-${_vm.matter_id}`,"title":"Crear Actividad o Evaluación","size":"xl","hide-footer":""}},[_c('MatterProgramEvaluationForm',{attrs:{"egress_profile_matter_id":_vm.egress_profile_matter_id,"matter_id":_vm.matter_id,"evaluation_list":_vm.matters_evaluations,"EvaluationType":_vm.evaluation_types,"EvaluationSituations":_vm.evaluation_situations,"ExaminerTypes":_vm.examiner_types,"EvaluativeAgentTypes":_vm.evaluative_agent_types,"WorkTypes":_vm.work_types,"MatEvaStuEnvTimAll":_vm.mat_eva_stu_env_tim_all},on:{"created":_vm.createdEvaluation,"updated":_vm.updatedEvaluation,"created_support_resource":_vm.createdSupportResource}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }