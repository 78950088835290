<template>
  <div class="text-center">
    <div class="act-header">ACTA</div>
    <div class="act-body">
      <div class="flex-col act-head">
        <div class="flex-row">
          <div class="act-head-cell text-right mr-3">
            {{ $getVisibleNames("mesh.campus", false, "Sede") }}
          </div>
          <div class="act-head-cell">{{ campus ? campus.name : "" }}</div>
        </div>
        <div class="flex-row">
          <div class="act-head-cell text-right mr-3">
            {{ $getVisibleNames("mesh.career", false, "Programa") }}
          </div>
          <div class="act-head-cell">{{ career ? career.name : "" }}</div>
        </div>
        <div class="flex-row">
          <div class="act-head-cell text-right mr-3">Período</div>
          <div class="act-head-cell">{{ getPeriod }}</div>
        </div>
        <b>Invitados</b>
        <div class="items-list">
          <b-table-simple
            bordered
            small
            responsive
            hover
            v-if="actUsers.length > 0"
          >
            <b-thead>
              <b-tr>
                <b-th class="th-class">Identificador</b-th>
                <b-th class="th-class">Nombre</b-th>
                <b-th class="th-class">Email</b-th>
                <b-th class="th-class">Cargo</b-th>
                <b-th class="th-class">Otra Información</b-th>
                <b-th class="th-class">Usuario</b-th>
                <b-th class="th-class">Puede Editar el Acta</b-th>
                <b-th class="th-class">Asistió</b-th>
                <b-th v-if="allows_crud" class="th-class">Acciones</b-th>
              </b-tr>
            </b-thead>
            <b-tbody
              ><b-tr v-for="user in actUsers" :key="user.id">
                <b-td>
                  <span
                    v-if="user.identifier_type != null && user.identifier != ''"
                  >
                    {{ getIdentifierType(user.identifier_type)
                    }}<template v-if="user.identifier_type != null"
                      >:
                    </template>
                    {{ user.identifier }}
                  </span>
                </b-td>
                <b-td>
                  {{ user.first_name + " " + user.last_name }}
                </b-td>
                <b-td>
                  {{ user.email }}
                </b-td>
                <b-td>
                  {{ user.occupation }}
                </b-td>
                <b-td>
                  {{ user.other_data }}
                </b-td>
                <b-td>
                  <strong class="ml-2" v-if="user.professional == null"
                    >Externo</strong
                  ><strong v-else>Interno</strong>
                </b-td>
                <b-td>
                  <b-icon
                    v-if="user.can_edit"
                    icon="check-square"
                    scale="1.5"
                    variant="success"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="x-square"
                    scale="1.5"
                    variant="danger"
                  ></b-icon>
                </b-td>
                <b-td>
                  <b-icon
                    v-if="user.assisted"
                    icon="check-square"
                    scale="1.5"
                    variant="success"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="x-square"
                    scale="1.5"
                    variant="danger"
                  ></b-icon>
                </b-td>
                <b-td v-if="allows_crud">
                  <div v-if="allows_crud">
                    <b-button
                      class="act-button ml-1 mb-1"
                      size="sm "
                      v-b-tooltip.v-secondary.noninteractive="
                        'Editar Asistente'
                      "
                      @click="$bvModal.show(`modal-support-${user.id}`)"
                    >
                      <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                    </b-button>
                    <b-button
                      class="act-button ml-1 mb-1"
                      size="sm"
                      v-b-tooltip.v-secondary.noninteractive="
                        'Remover Asistente'
                      "
                      @click="launchDeleteUser(user)"
                    >
                      <b-icon-x-circle></b-icon-x-circle>
                    </b-button>
                  </div>
                </b-td>
                <b-modal
                  :id="`modal-support-${user.id}`"
                  :title="`Editar Asistente ${
                    user.professional ? '' : 'Externo'
                  }`"
                  size="lg"
                  hide-footer
                >
                  <SupportForm
                    :SupportTypes="SupportTypes"
                    :identifierTypes="identifierTypes"
                    :Support="user"
                    :act_id="act.id"
                    :allows_crud="allows_crud"
                    @updated="slotUpdateSupport"
                  ></SupportForm>
                </b-modal>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div class="support-button">
          <b-button
            v-if="allows_crud"
            class="act-button"
            size="sm mt-1"
            @click="$bvModal.show('modal-users')"
          >
            <b-icon-file-plus></b-icon-file-plus>Gestionar Asistentes
          </b-button>
          <b-button
            v-if="allows_crud"
            class="act-button"
            size="sm mt-1 ml-3"
            @click="$bvModal.show('modal-support')"
          >
            <b-icon-file-plus></b-icon-file-plus>Agregar Asistente Externo
          </b-button>
        </div>
      </div>
      <div class="flex-col mt-3 act-head">
        <div class="flex-row">
          <div class="act-head-cell text-center mr-3 w-100">
            {{ ActTypes.find((x) => x.id == act.act_type).name }}
          </div>
        </div>
        <div class="flex-row">
          <div class="act-head-cell text-right mr-3">Fecha de Inicio</div>
          <div class="act-head-cell">{{ act.start_date | toDateTime }}</div>
        </div>
        <div class="flex-row">
          <div class="act-head-cell text-right mr-3">Fecha de Término</div>
          <div class="act-head-cell">{{ act.end_date | toDateTime }}</div>
        </div>
        <b>Tabla de Reunión</b>
        <div class="items-list">
          <template v-for="aspect in aspectsList">
            <div :key="aspect.id" class="d-flex">
              <div
                :class="allows_crud ? 'w-95' : ''"
                class="rich-text-content"
                v-html="aspect.text"
              ></div>
              <div v-if="allows_crud">
                <b-button
                  class="act-button ml-1 mb-1"
                  size="sm "
                  @click="$bvModal.show(`edit-aspect-modal-${aspect.id}`)"
                  v-b-tooltip.v-secondary.noninteractive="'Editar Aspecto'"
                >
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>
                <b-button
                  class="act-button ml-1 mb-1"
                  size="sm"
                  @click="deleteAspect(aspect.id)"
                  v-b-tooltip.v-secondary.noninteractive="'Eliminar Aspecto'"
                >
                  <b-icon-x-circle></b-icon-x-circle>
                </b-button>
                <b-modal
                  :id="`edit-aspect-modal-${aspect.id}`"
                  title="Editar"
                  size="lg"
                  hide-footer
                >
                  <RichTextEditor
                    :Object="aspect"
                    :Text="aspect.text"
                    @update="updateAspect"
                    @close="$bvModal.hide(`edit-aspect-modal-${aspect.id}`)"
                  ></RichTextEditor>
                </b-modal>
              </div>
            </div>
          </template>
          <!-- <template v-for="aspect_type in selectedAspectTypes">
            <div :key="`aspect_type-${aspect_type.id}`" class="flex-col">
              <div>{{ aspect_type.text }}</div>
            </div>
            <template
              v-for="aspect in selectedAspects.filter(
                (x) => x.aspect_type == aspect_type.id
              )"
            >
              <div
                :key="`aspect-${aspect.id}`"
                class="flex-row align-items-center"
              >
                <div class="items-text">{{ aspect.text }}</div>
                <div v-if="allows_crud" class="user-buttons">
                  <b-button
                    class="act-button ml-1 mb-1"
                    size="sm"
                    @click="slotRemoveAspect(aspect.id)"
                  >
                    <b-icon-x-circle></b-icon-x-circle>
                  </b-button>
                </div>
              </div>
            </template>
          </template> -->
          <!-- <div class="flex-col" v-if="otherAspects.length > 0">
            <div>Otros</div>
          </div>
          <template v-for="aspect in otherAspects">
            <div
              :key="`aspect-${aspect.id}`"
              class="flex-row align-items-center"
            >
              <div class="items-text">{{ aspect.text }}</div>
              <div v-if="allows_crud" class="user-buttons">
                <b-button
                  class="act-button ml-1 mb-1"
                  size="sm"
                  @click="slotRemoveAspect(aspect.id)"
                >
                  <b-icon-x-circle></b-icon-x-circle>
                </b-button>
              </div>
            </div>
          </template> -->
        </div>
        <b-button
          v-if="allows_crud"
          class="act-button"
          size="sm mt-1"
          v-b-tooltip.v-secondary.noninteractive="'Agregar Aspecto'"
          @click="$bvModal.show('modal-add-aspects')"
        >
          <b-icon-file-plus></b-icon-file-plus>Agregar Aspectos
        </b-button>
      </div>
    </div>
    <div class="act-aspect">
      <div class="act-header">ASPECTOS TRATADOS Y SUS ACUERDOS</div>
      <div class="agreement-container">
        <div class="agrement-list">
          <!-- <template v-for="aspect_type in selectedAspectTypes">
          <div :key="`aspect_type-${aspect_type.id}`" class="flex-col">
            <div>{{ aspect_type.text }}</div>
          </div>
          <template
            v-for="aspect in selectedAspects.filter(
              (x) => x.aspect_type == aspect_type.id
            )"
          >
            <AspectAgreement
              :key="`aspect-${aspect.id}`"
              :act_id="act.id"
              :aspect_id="aspect.id"
              :Aspects="Aspects"
              :FulfillTypes="FulfillTypes"
              :allows_crud="allows_crud"
            >
            </AspectAgreement>
          </template>
        </template> -->
          <template v-for="aspect in aspectsList">
            <!-- <div :key="aspect.id">
            <div class="text-justify m-3" v-html="aspect.text"></div>
          </div> -->
            <AspectAgreement
              class="aspect-container"
              :key="`aspect-${aspect.id}`"
              :Act="Act"
              :act_id="act.id"
              :aspect_id="aspect.id"
              :Aspects="aspectsList"
              :FulfillTypes="FulfillTypes"
              :allows_crud="allows_crud"
            >
            </AspectAgreement>
          </template>
          <!-- <template v-if="otherAspects.length > 0">
          <div class="flex-col">
            <div>Otros</div>
          </div>
        </template>
        <template v-for="aspect in otherAspects">
          <AspectAgreement
            :key="`aspect-${aspect.id}`"
            :act_id="act.id"
            :aspect_id="aspect.id"
            :Aspects="Aspects.concat(other_aspects)"
            :FulfillTypes="FulfillTypes"
            :allows_crud="allows_crud"
          >
          </AspectAgreement>
        </template> -->
        </div>
      </div>
    </div>
    <div class="act-aspect">
      <div class="act-header">COMENTARIOS</div>
      <div class="agreement-container">
        <div class="agrement-list p-2 pb-5">
          <b-button
            class="act-button add-commment ml-2 mb-2 noprint"
            size="sm mt-1"
            @click="$bvModal.show('modal-add-comment')"
          >
            <b-icon-file-plus></b-icon-file-plus> Agregar Comentario
          </b-button>
          <b-button
            class="act-button mr-3 noprint reload-icon"
            size="sm mt-1"
            v-b-tooltip.v-secondary.noninteractive="'Recargar Comentarios.'"
            @click="fetchActComment()"
          >
            <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
          </b-button>
          <span
            :class="!count_comment ? 'hide-comment' : ''"
            class="mr-2 mt-1 reload-icon"
          >
            <strong> Lista de comentarios actualizados! </strong>
          </span>
          <template v-for="comment in commentsList">
            <ActComments
              :key="comment.id"
              :Comment="comment"
              :allows_crud="allows_crud"
              @delete_comment="deleteComment"
            >
            </ActComments>
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="act-header">APOYO RECIBIDO PARA LA GESTIÓN PEDAGÓGICA</div>
    <div class="agreement-container">
      <div class="support-container">
        <template v-for="support in supports">
          <ActSupport
            :Support="support"
            :SupportTypes="SupportTypes"
            :key="support.id"
            :allows_crud="allows_crud"
            @updated="slotUpdatedSupport"
            @deleted="slotDeleteSupport"
          ></ActSupport>
        </template>
        <b-button
          v-if="allows_crud"
          class="act-button"
          size="sm mt-1"
          @click="$bvModal.show('modal-support')"
        >
          <b-icon-file-plus></b-icon-file-plus>Agregar Apoyo
        </b-button>
      </div>
    </div> -->

    <div class="act-header">ARCHIVOS ADJUNTOS</div>
    <div class="agreement-container">
      <ActFiles :Act="act" :allows_crud="allows_crud"></ActFiles>
    </div>
    <b-button v-if="allows_crud" class="act-button mt-1" @click="save">
      <b-icon-file-plus></b-icon-file-plus>Guardar Acta
    </b-button>

    <!-- Modals -->
    <b-modal
      id="modal-add-aspects"
      title="Crear Aspectos"
      size="lg"
      hide-footer
    >
      <RichTextEditor
        @create="createAspect"
        @close="$bvModal.hide('modal-add-aspects')"
      ></RichTextEditor>
      <!-- ok-only
      @ok="slotUpdateAct" -->
      <!-- <AspectsSelector
        :AspectTypes="AspectTypes"
        :Aspects="Aspects"
        :ActAspects="act_aspect_ids"
        :allows_crud="allows_crud"
        @selected="slotSelectedAspects"
        @created="slotCreatedAspect"
      ></AspectsSelector> -->
    </b-modal>
    <b-modal
      id="modal-add-comment"
      title="Crear comentario"
      size="lg"
      hide-footer
    >
      <RichTextEditor
        @create="createComment"
        @close="$bvModal.hide('modal-add-comment')"
      ></RichTextEditor>
    </b-modal>
    <b-modal
      id="modal-support"
      title="Agregar Asistente Externo"
      size="lg"
      hide-footer
    >
      <SupportForm
        :SupportTypes="SupportTypes"
        :act_id="act.id"
        :allows_crud="allows_crud"
        :identifierTypes="identifierTypes"
        @created="slotCreatedSupport"
      ></SupportForm>
    </b-modal>
    <b-modal
      id="modal-users"
      title="Gestionar Asistentes"
      size="lg"
      hide-footer
      @close="slotUsersClosed"
    >
      <UserList
        :usersList="users_list"
        :filter_by_professor="false"
        :filter_by_student="false"
        @selected_users="slotSelectedUsers"
        @close_selected="closeSelectedUsers"
        :users_to_remove="usersToRemove"
        :select_mode="'range'"
      ></UserList>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import {
  // generateUniqueId,
  toast,
} from "@/utils/utils";

export default {
  name: "ActComponent",
  components: {
    // AspectsSelector: () => import("./AspectsSelector"),
    AspectAgreement: () => import("./AspectAgreement"),
    // ActSupport: () => import("./ActSupport"),
    RichTextEditor: () => import("@/components/reusable/RichTextEditor"),
    UserList: () => import("@/components/reusable/UserList"),
    ActFiles: () => import("./ActFiles"),
    SupportForm: () => import("./SupportForm"),
    ActComments: () => import("./ActComments"),
  },
  props: {
    Act: {
      type: Object,
      required: true,
    },
    // AspectTypes: {
    //   type: Array,
    //   required: true,
    // },
    Aspects: {
      type: Array,
      required: true,
    },
    FulfillTypes: {
      type: Array,
      required: true,
    },
    ActTypes: {
      type: Array,
      required: true,
    },
    identifierTypes: {
      type: Array,
      required: true,
    },
    SupportTypes: {
      type: Array,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      act: this.Act,
      act_aspect_ids: [],
      users_list: [],
      act_comments: [],
      count_comment: false,
      // other_aspects: [],
      aspects: [],
      supports: [],
    };
  },
  computed: {
    ...mapGetters({
      campuses: names.CAMPUSES,
      egressProfiles: names.EGRESS_PROFILES,
      carrers: names.CAREERS,
      users: names.USERS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      sections: names.SECTIONS,
      periods: names.PERIODS,
    }),
    getPeriod() {
      const period = this.periods.find((x) => x.id == this.act.period);
      return period ? period.name : "";
    },
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.act.egress_profile);
    },
    campus() {
      return this.campuses.find((x) => x.id == this.act.campus);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.carrers.find((x) => x.id == this.egressProfile.career);
    },
    usersToRemove() {
      const user_support = this.supports.map((x) => x.professional);
      return user_support.filter((x) => x !== null);
    },
    actUsers() {
      const supports = this.supports.filter((x) => (x.act = this.act.id));
      supports.forEach((support_user) => {
        if (support_user.professional) {
          const user = this.users_list.find(
            (x) => x.id == support_user.professional
          );
          if (user) {
            support_user.first_name = user.first_name;
            support_user.last_name = user.last_name;
            support_user.email = user.email;
            if (user.user_data) {
              support_user.identifier_type = user.user_data.identifier_type;
              support_user.identifier = user.user_data.identifier;
            }
          }
        }
      });
      // this.act.professors.forEach((user_id) => {
      //   const user = this.users.find((x) => x.id == user_id);
      //   if (user) list.push(user);
      // });
      return supports;
    },
    // actUsers() {
    //   let list = [];
    //   this.act.professors.forEach((user_id) => {
    //     const user = this.uaspectsListsers.find((x) => x.id == user_id);
    //     if (user) list.push(user);
    //   });
    //   return list;
    // },
    // selectedAspectTypes() {
    //   let list = [];
    //   this.act_aspect_ids.forEach((aspect_id) => {
    //     const aspect = this.Aspects.find((x) => x.id == aspect_id);
    //     if (aspect && aspect.aspect_type) {
    //       const aspect_type = this.AspectTypes.find(
    //         (x) => x.id == aspect.aspect_type
    //       );
    //       if (list.findIndex((x) => x.id == aspect_type.id) == -1)
    //         list.push(aspect_type);
    //     }
    //   });
    //   list = list.sort(function (a, b) {
    //     if (a.order > b.order) return 1;
    //     if (a.order < b.order) return -1;
    //     return 0;
    //   });
    //   return list;
    // },
    aspectsList() {
      let list = [];
      if (this.act) {
        list = this.aspects.filter((x) => x.act == this.act.id);
      }
      return list;
    },
    commentsList() {
      let list = [];
      if (this.act) {
        list = this.act_comments.filter((x) => x.act == this.act.id);
      }
      return list.sort(function (a, b) {
        if (a.creation_date < b.creation_date) return 1;
        if (a.creation_date > b.creation_date) return -1;
        return 0;
      });
    },
    // selectedAspects() {
    //   let list = [];
    //   this.act_aspect_ids.forEach((aspect_id) => {
    //     const aspect = this.Aspects.find((x) => x.id == aspect_id);
    //     if (aspect) list.push(aspect);
    //   });
    //   return list;
    // },
    filteredMatters() {
      return this.egress_profiles_matters.filter(
        (x) =>
          x.egress_profile == this.act.egress_profile &&
          x.plan_level == this.act.level
      );
    },
    filteredSections() {
      let list = [];
      this.filteredMatters.forEach((matter) => {
        let sections = this.sections.filter(
          (x) => x.egress_profile_matter == matter.id
        );
        list = list.concat(sections);
      });
      return list;
    },
  },
  methods: {
    fetchSupports() {
      this.$restful
        .Get(`/cideu/support/?act=${this.act.id}`)
        .then((response) => {
          this.supports = response;
        });
    },
    slotSelectedAspects(selected_aspects) {
      selected_aspects.forEach((id) => {
        const index = this.act_aspect_ids.find((x) => x == id);
        if (!index) this.act_aspect_ids.push(id);
      });
    },
    createAspect(text) {
      if (text != "")
        this.$restful
          .Post(`/cideu/aspect/`, {
            act: this.act.id,
            text: text,
            order: this.aspects.length,
          })
          .then((response) => {
            this.aspects.push(response);
          });
      else toast("No se puede guardar si está vacío.");
    },
    updateAspect(object, text) {
      if (text != "")
        this.$restful
          .Patch(`/cideu/aspect/${object.id}/`, { text: text })
          .then((response) => {
            const index = this.aspects.findIndex((b) => b.id == object.id);
            if (index != -1) this.aspects.splice(index, 1, response);
            this.$bvModal.hide(
              `edit-bibliographic-resource-modal-${object.id}`
            );
          });
      else toast("No se puede guardar si está vacío.");
    },
    deleteAspect(id) {
      this.$swal({
        title: "¿Está seguro de que desea remover el Aspecto?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful.Delete(`/cideu/aspect/${id}/`).then(() => {
            const index = this.aspects.findIndex((b) => b.id == id);
            if (index != -1) this.aspects.splice(index, 1);
          });
        }
      });
    },
    slotUpdateAct() {
      this.$restful
        .Patch(`/cideu/act/${this.act.id}/`, {
          aspects: this.act_aspect_ids,
        })
        .then((response) => {
          this.$emit("updated", response);
          toast("Acta actualizada.");
        });
    },
    createComment(text) {
      if (text != "")
        this.$restful
          .Post(`/cideu/act-comment/`, {
            act: this.act.id,
            comment: text,
          })
          .then((response) => {
            this.act_comments.push(response);
          });
      else toast("No se puede guardar si está vacío.");
    },
    deleteComment(id) {
      this.$swal({
        title: "¿Está seguro de que desea remover el Comentario?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful.Delete(`/cideu/act-comment/${id}/`).then(() => {
            const index = this.act_comments.findIndex((b) => b.id == id);
            if (index != -1) this.act_comments.splice(index, 1);
          });
        }
      });
    },
    getIdentifierType(id) {
      const identifier = this.identifierTypes.find((x) => x.id == id);
      if (identifier) return identifier.identifier_name;
      return "";
    },
    // slotRemoveAspect(aspect_id) {
    //   this.$restful
    //     .Get(`/cideu/agreement/?act=${this.Act.id}&aspect=${aspect_id}`)
    //     .then((agreements) => {
    //       if (agreements.length > 0) {
    //         this.$swal({
    //           title: "¿Está seguro de que desea eliminar al Aspecto?",
    //           text: "Contiene acuerdos que se eliminarán!",
    //           type: "warning",
    //           showCancelButton: true,
    //         }).then((result) => {
    //           if (result.value) {
    //             agreements.forEach((agreement) => {
    //               this.deleteAgreement(agreement);
    //             });
    //             this.removeAspect(aspect_id);
    //           }
    //         });
    //       } else {
    //         this.removeAspect(aspect_id);
    //       }
    //     });
    // },
    // removeAspect(aspect_id) {
    //   let index = this.act_aspect_ids.findIndex((x) => x == aspect_id);
    //   if (index != -1) {
    //     this.act_aspect_ids.splice(index, 1);
    //     this.patchAct({
    //       aspects: this.act_aspect_ids,
    //     });
    //   }
    //   index = this.other_aspects.findIndex((x) => x.id == aspect_id);
    //   if (index != -1) {
    //     this.$restful.Delete(`/cideu/aspect/${aspect_id}/`).then(() => {
    //       this.other_aspects.splice(index, 1);
    //       toast("Aspecto eliminado.");
    //     });
    //   }
    // },
    save() {
      if (!isNaN(this.act.id)) this.updateAct();
    },
    updateAct() {
      this.$restful
        .Put(`/cideu/act/${this.act.id}/`, this.act)
        .then((response) => {
          this.$emit("updated", response);
          toast("Acta actualizada.");
        });
    },
    slotCreatedSupport(support) {
      this.supports.push(support);
      this.$bvModal.hide("modal-support");
    },
    slotUpdateSupport(support) {
      let index = this.supports.findIndex((x) => x.id == support.id);
      if (index != -1) this.supports.splice(index, 1, support);
      else this.supports.push(support);
      this.$bvModal.hide("modal-support-" + support.id);
    },
    // slotUpdatedSupport(support) {
    //   const index = this.supports.find((x) => x.id == support.id);
    //   if (index != -1) {
    //     this.supports.splice(index, 1, support);
    //   } else {
    //     this.supports.push(support);
    //   }
    // },
    slotUsersClosed() {
      this.save();
    },
    slotSelectedUsers(users) {
      users.forEach((user) => {
        const index = this.supports.findIndex((x) => x.professional == user.id);
        let user_identifier = null;
        let user_identifier_type = null;
        if (user.user_data) {
          user_identifier = user.user_data.identifier;
          user_identifier_type = user.user_data.identifier_type;
        }
        if (index == -1) {
          this.$restful
            .Post(`/cideu/support/`, {
              professional: user.id,
              moment: 1,
              act: this.act.id,
              occupation: "",
              identifier: user_identifier,
              identifier_type: user_identifier_type,
            })
            .then((response) => {
              this.supports.push(response);
            });
        } else toast("El usuario seleccionado ya está en la lista.");
      });
    },
    closeSelectedUsers() {
      this.$bvModal.hide("modal-users");
    },
    // slotSelectedUsers(users) {
    //   users.forEach((user) => {
    //     const index = this.act.professors.findIndex((x) => x == user.id);
    //     if (index == -1) this.act.professors.push(user.id);
    //     else toast("El usuario seleccionado ya está en la lista.");
    //   });
    // },
    launchDeleteUser(user) {
      this.$swal({
        title: "¿Está seguro de que desea remover al Asistente?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteUser(user);
        }
      });
    },
    deleteUser(support) {
      const index = this.supports.findIndex((x) => x.id == support.id);
      if (index != -1)
        this.$restful.Delete(`/cideu/support/${support.id}/`).then(() => {
          this.supports.splice(index, 1);
        });
    },
    // launchDeleteUser(user) {
    //   this.$swal({
    //     title: "¿Está seguro de que desea eliminar al Docente?",
    //     text: "¡Esta acción no se podrá revertir!",
    //     type: "warning",
    //     showCancelButton: true,
    //   }).then((result) => {
    //     if (result.value) {
    //       this.deleteUser(user);
    //     }
    //   });
    // },
    // deleteUser(user) {
    //   const index = this.act.professors.findIndex((x) => x == user.id);
    //   if (index != -1) this.act.professors.splice(index, 1);
    // },
    // slotDeleteSupport(support) {
    //   const index = this.supports.findIndex((x) => x.id == support.id);
    //   if (index != -1) {
    //     this.supports.splice(index, 1);
    //   }
    // },
    // slotCreatedAspect(aspect) {
    //   this.act_aspect_ids.push(aspect.id);
    //   this.other_aspects.push(aspect);
    //   this.patchAct({
    //     aspects: this.act_aspect_ids,
    //   });
    // },
    patchAct(item) {
      this.$restful
        .Patch(`/cideu/act/${this.act.id}/`, item)
        .then((response) => {
          this.$emit("updated", response);
          toast("Acta actualizada.");
        });
    },
    deleteAgreement(agreement) {
      this.$restful.Delete(`/cideu/agreement/${agreement.id}/`).then(() => {
        toast("Acuerdo eliminado.");
      });
    },
    fetchAspects() {
      this.$restful
        .Get(`/cideu/aspect/?act=${this.act.id}`)
        .then((response) => {
          this.aspects = response;
        });
    },
    fetchActComment() {
      this.count_comment = true;
      this.$restful
        .Get(`/cideu/act-comment/?act=${this.act.id}`)
        .then((response) => {
          this.act_comments = response;
          this.count_comment = false;
          // toast("Comentarios Actualizados.");
        });
    },
    debouncefetchComment() {
      this.timeout = setTimeout(() => {
        this.fetchActComment();
      }, 15000);
    },
  },
  mounted() {
    this.debouncefetchComment();
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  watch: {
    act_comments: {
      handler() {
        if (this.timeout) clearTimeout(this.timeout);
        this.debouncefetchComment();
      },
    },
  },
  created() {
    this.fetchSupports();
    this.fetchAspects();
    this.fetchActComment();
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.act.egress_profile)
      .then((ep) => {
        this.$store
          .dispatch(names.FETCH_MATTERS, { egress_profile_id: ep.id })
          .then((matters) => {
            matters.forEach((matter) => {
              this.$store.dispatch(names.FETCH_SECTIONS, {
                matter_id: matter.id,
              });
            });
          });
      });
    this.$restful.Get(`/cideu/act_addable_users/`).then((response) => {
      this.users_list = response;
    });
    // this.Act.professors.forEach((user_id) => {
    // this.$store.dispatch(names.FETCH_USER, user_id);
    // });
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.act-head {
  width: 100%;
  border: solid 3px rgb(184, 183, 183);
  background-color: white;
}
.act-head-cell {
  text-align: left;
  width: 50%;
}
.act-header {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
}
.act-body {
  background-color: gray;
  padding: 1rem;
}
.items-list {
  border: 4px solid var(--primary-color);
  padding: 1rem;
  height: auto;
  overflow: auto;
}
.aspect-container {
  border: 4px solid var(--primary-color);
  padding: 1rem;
  height: auto;
}
.add-commment {
  float: left;
}
.reload-icon {
  float: right;
}
.w-95 {
  width: 95%;
}
.agreement-container {
  background-color: gray;
  padding: 1em 0em;
}
.agrement-list {
  background-color: white;
  border: 3px solid var(--secondary-color);
  margin: 0em 1em;
}
.act-button {
  background-color: var(--primary-color);
  max-width: 15rem;
  color: var(--primary-font-color);
  align-self: center;
}
.items-text {
  text-align: justify;
  padding: 0.1em 0.5em;
  /* border-bottom: 1px dashed var(--secondary-color); */
  margin-bottom: 3px;
  min-width: 92%;
}
.support-container {
  margin: 0em 1em;
  border: 2px solid var(--secondary-color);
  background-color: white;
}
.user-rut {
  width: 20%;
}
.user-name {
  text-align: left;
  width: 60%;
}
.user-buttons {
  width: 20%;
}
.th-class {
  background: var(--primary-color) !important;
}
.support-button {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
}
.hide-comment {
  animation: hide 1s linear 2s 1 forwards;
}
@keyframes hide {
  to {
    opacity: 0;
  }
}
</style>