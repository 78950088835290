<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.period.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Inicio"
      label-for="input-execution-date"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-datepicker
        id="input-execution-date"
        v-model="$v.period.start_date.$model"
        :state="validateState('start_date')"
        aria-describedby="input-execution-date-feedback"
        size="sm"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="input-execution-date-feedback"
        >Este campo es obligatorio y no puede ser igual o mayor que la fecha de
        término.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Término"
      label-for="input-end-date"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-datepicker
        id="input-end-date"
        v-model="$v.period.end_date.$model"
        :state="validateState('end_date')"
        aria-describedby="input-end-date-feedback"
        size="sm"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="input-end-date-feedback"
        >Este campo es obligatorio y no puede ser igual o menor que la fecha de
        inicio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      label="Semanas"
      label-for="input-weeks"
      class="label m-0"
    >
      <b-form-input
        id="input-weeks"
        v-model="$v.period.weeks.$model"
        type="number"
        min="1"
        :state="validateState('weeks')"
        aria-describedby="input-weeks-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-weeks-feedback"
        >Este campo debe ser un entero mayor a cero.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.period.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDeleteObject"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength, minValue } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
export default {
  name: "PeriodForm",
  mixins: [validationMixin],
  props: {
    Period: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          start_date: null,
          end_date: null,
          active: true,
          weeks: 0,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      period: this.Period
        ? {
            id: this.Period.id,
            name: this.Period.name,
            start_date: this.Period.start_date,
            end_date: this.Period.end_date,
            weeks: this.Period.weeks,
          }
        : {
            id: generateUniqueId(),
            name: "",
            start_date: null,
            end_date: null,
            weeks: 0,
          },
    };
  },
  validations: {
    period: {
      name: {
        required,
        minLength: minLength(5),
      },
      start_date: {
        required,
        maxValue(val, { end_date }) {
          return new Date(end_date) > new Date(val);
        },
      },
      end_date: {
        required,
        minValue(val, { start_date }) {
          return new Date(val) > new Date(start_date);
        },
      },
      weeks: {
        required,
        minValue: minValue(1),
      },
    },
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.period[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.period.$touch();
      if (this.$v.period.$anyError) {
        return;
      }
      if (isNaN(this.period.id)) {
        this.createPeriod();
      } else this.updatePeriod();
    },
    createPeriod() {
      this.$store.dispatch(names.POST_PERIOD, this.period).then((response) => {
        toast(
          String(
            this.$getVisibleNames("mesh.period", false, "Período") + " creada."
          )
        );
        this.$emit("created", response);
      });
    },
    updatePeriod() {
      this.$store
        .dispatch(names.UPDATE_PERIOD, this.period)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames("mesh.period", false, "Período") +
                " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deletePeriod() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.period",
          false,
          "Período"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_PERIOD, this.period.id);
          this.$emit("deleted", this.period);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>