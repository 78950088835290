<template>
  <div class="color-background">
    <div class="container-contact-banner">
      <div class="flex-container">
        <div class="banner-column-1 img-container">
          <img src="../../assets/new-page/KL_LOGO_CONTACT.svg" alt="" />
        </div>
        <div class="banner-column-2">
          <div class="font-text font-1 mb-3">¡Contáctanos! ;)</div>
          <div class="font-text font-2">
            <a
              style="color: #f5f5f5 !important"
              href="mailto:admin@klearning.cl?Subject=Deseo%20solicitar%20un%20demo%20de%20la%20plataforma."
              >admin@klearning.cl</a
            >
            WhatsApp
            <a
              style="color: #f5f5f5 !important"
              href=" https://wa.me/+56977081206"
              target="_blank"
              >+56(9) 7708 1206</a
            >
          </div>
        </div>
        <div class="banner-column-3">
          <div class="font-text font-1 text-right">Key Learning SPA</div>
          <div class="font-text font-3">Av. Américo Vespucio Sur</div>
          <div class="font-text font-3">
            <strong> #107, of. 207, Las Condes, Santiago. </strong>
          </div>
          <div class="social-icon">
            <a
              style="margin-left: auto; margin-right: 0"
              href="https://www.linkedin.com/company/keylearningcl/"
              target="_blank"
            > 
              <img src="../../assets/new-page/LINKEDIN_ICON.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="font-text footer-container-banner">
      Copyright © KEY LEARNING SPA 2021. Todos los derechos reservados.
    </div>
  </div>
</template>

<script>
// import * as names from "@/store/names";
// import { mapGetters } from "vuex";
// import { generateUniqueId } from "@/utils/utils";
export default {
  name: "ContactBanner",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    // ...mapGetters({}),
  },
  methods: {},
  mounted() {},
  created() {},
};
</script>

<style scoped>
.color-background {
  background: #006ec8;
  height: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.container-contact-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url(../../assets/new-page/Mision-recuadro.svg);
  background-position: left;
  background-repeat: no-repeat;
  height: auto;
  min-height: 252px;
  width: 100%;
}
.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.img-container {
}
.banner-column-1 {
  flex-grow: 1;
  /* background-image: url("../../assets/KL_BACKGROUND.svg"),
    url("../../assets/KL_BACKGROUND.svg");
  background-position: left, right;
  background-repeat: repeat-x, repeat-y;
  transform: rotate(20deg), rotate(30deg); */
}
.banner-column-2 {
  flex-grow: 2;
  text-align: justify;
  align-items: center;
}
.banner-column-3 {
  flex-grow: 1;
  display: block;
  margin-right: 60px;
}
.font-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  line-height: 130%;
}
.font-1 {
  /* font-family: 'Source Sans Pro', sans-serif;
  font-style: normal; */
  font-weight: 700;
  font-size: 20px;
  /* line-height: 130%; */
  /* or 26px */

  color: #ffffff;
}
.font-2 {
  /* font-family: 'Source Sans Pro', sans-serif;
  font-style: normal; */
  font-weight: 400;
  font-size: 14px;
  /* line-height: 130%; */
  /* or 18px */

  color: #f5f5f5;
}
.font-3 {
  /* font-family: 'Source Sans Pro', sans-serif;
  font-style: normal; */
  font-weight: 400;
  font-size: 14px;
  /* line-height: 130%; */
  /* or 18px */

  text-align: right;

  color: #f5f5f5;
}
.social-icon {
  display: flex;
  margin-top: 5px;
}
.footer-container-banner {
  /* font-family: 'Source Sans Pro', sans-serif;
  font-style: normal; */
  font-weight: 300;
  font-size: 12px;
  /* line-height: 130%; */
  /* or 16px */

  text-align: center;

  color: #f5f5f5;
}
@media (max-width: 800px) {
  .flex-container {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .banner-column-1 {
    margin-top: 20px;
  }
  .banner-column-2 {
    text-align: center;
    margin-top: 30px;
  }
  .banner-column-3 {
    margin-top: 50px;
    margin-right: 0px;
  }
  .font-3 {
    text-align: center;
  }
  .social-icon {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>