var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-100"},[_c('b-form-input',{attrs:{"size":"sm"},on:{"update":(value) => {
          if (parseFloat(value) < 0 || value == null || value == '') {
            _vm.matter_time_allocation.hours = 0;
            _vm.patchMatterTimeAllocation();
          } else {
            _vm.matter_time_allocation.hours = value;
            _vm.patchMatterTimeAllocation();
          }
        }},model:{value:(_vm.matter_time_allocation.hours),callback:function ($$v) {_vm.$set(_vm.matter_time_allocation, "hours", $$v)},expression:"matter_time_allocation.hours"}})],1),_c('b-form-checkbox',{staticClass:"mt-2 ml-2",attrs:{"size":"lg"},on:{"input":function($event){return _vm.patchMatterTimeAllocation()}},model:{value:(_vm.matter_time_allocation.counts_towards_credits),callback:function ($$v) {_vm.$set(_vm.matter_time_allocation, "counts_towards_credits", $$v)},expression:"matter_time_allocation.counts_towards_credits"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }