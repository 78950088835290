var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('b-breadcrumb',{staticClass:"breadcrumb pb-2 pt-1 noprint",attrs:{"items":_vm.breadcrumb_items}}),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_timeallocation'),expression:"'mesh.add_timeallocation'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-time-allocation-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames( "mesh.timeallocation", false, "División de tiempo" ))+" ")]):_vm._e()])]),_c('h3',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.timeallocation", true, "Divisiones de tiempo"))+" ")]),_c('GenericBTable',{staticClass:"time-allocation-table",attrs:{"items":_vm.time_allocations,"pagination":10,"filterCustom":_vm.filterCustom,"fields":_vm.time_allocations_fields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(study_environment)",fn:function(row){return [_c('div',{staticClass:"study-environment-container"},[(row.item.has_study_environments)?_vm._l((_vm.getStudyEnvironment(row.item.id)),function(item){return _c('div',{key:item.id,staticClass:"study-environment-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}):[_vm._v("N/A")]],2)]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Editar ${_vm.$getVisibleNames(
              'mesh.timeallocation',
              false,
              'División de tiempo'
            )}`
          ),expression:"\n            `Editar ${$getVisibleNames(\n              'mesh.timeallocation',\n              false,\n              'División de tiempo'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-time-allocation-modal-${row.item.id}`)}}}),(
            _vm.institution &&
            _vm.institution.id == 12 &&
            (row.item.id == 87 || row.item.id == 72)
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
            `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`
          ),expression:"\n            `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`\n          ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}]},[_c('button-delete',{attrs:{"disabled":""}})],1):_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Eliminar ${_vm.$getVisibleNames(
              'mesh.timeallocation',
              false,
              'División de tiempo'
            )}`
          ),expression:"\n            `Eliminar ${$getVisibleNames(\n              'mesh.timeallocation',\n              false,\n              'División de tiempo'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteTimeAllocation(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-time-allocation-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.timeallocation',
            false,
            'División de tiempo'
          )}`,"size":"lg","hide-footer":""}},[_c('TimeAllocationForm',{attrs:{"TimeAllocation":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedTimeAllocation}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-time-allocation-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.timeallocation',
      false,
      'División de tiempo'
    )}`,"size":"lg"}},[_c('TimeAllocationForm',{attrs:{"maxOrder":_vm.maxOrder,"show_title":false},on:{"created":_vm.slotCreatedTimeAllocation}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }