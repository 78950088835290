<template>
  <div>
    <div class="text-center">
      <h4 v-if="courses_wrapper_id &&coursesWrapper">
       {{ $getVisibleNames(
            "mesh.coursewrapper",
            false,
            "Envoltorio de Asignaturas"
        )}}: {{ coursesWrapper.name }}
      </h4>
      <h4 class="mt-4 mb-3" v-else>
        {{$getVisibleNames('manual.matriz_2', false, 'Matriz 2')}}: {{$getVisibleNames('mesh.egressprofilematter', true, 'Asignaturas')}}
        <span v-if="career">{{ " de " + career.name }} </span>
      </h4>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <template v-if="allows_crud &&
            ((user_position &&[1, 2, 3].includes(user_position.position) && 
              [1, 2, 3].includes(user.groups[0])) ||
              user.is_superuser ||
              $hasObjectPermission(
                'mesh.career',
                'change',
                egressProfile.career
              )) && tabIndex == 0">
            <!-- cycle &&  -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-2 ml-3"
                @click="$bvModal.show('new-matter-modal')"
                >Agregar {{$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )}}</b-button
              >
        </template>
          <b-button
            v-if="courses_wrapper_id==null"
            variant="primary"
            size="sm"
            class="mb-2 ml-3 noprint"
            @click="$bvModal.show('course-wrapper-modal')"
            >{{$getVisibleNames(
            'mesh.coursewrapper',
            false,
            'Envoltorio de Asignaturas'
          )}}</b-button>
          <b-button
            v-else
            variant="secondary"
            size="sm"
            class="mb-2 ml-3 noprint"
            @click="()=>{
              $router.push({
                  name: 'Matrix2View',
                  params: {
                    egress_profile_id: egress_profile_id,
                  },
                })
              }"  
            >Volver</b-button>
            <RemoveEPMattersComponent v-if="user && user.is_superuser && allows_crud" :egress_profile_id="egress_profile_id" :allows_crud="allows_crud">
            </RemoveEPMattersComponent>
        </div>
        <template v-if="courses_wrapper_id==null">
          <div>
          <download-excel
            class="btn btn-primary mr-3 btn-download-excel noprint"
            :data="itemsXLS"
            :fields="jsonFields"
            :worksheet="`REPORTE DE ${$getVisibleNames('mesh.egressprofilematter', true, 'Asignaturas').toUpperCase()}`"
            :name="`Reporte de ${$getVisibleNames('mesh.egressprofilematter', true, 'Asignaturas')}.xls`"
          >
            {{$getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                ).toUpperCase()}} XLS
          </download-excel>
          <b-button
          v-if="((institution && institution.id == 12) || $debug_change_duoc)"
            variant="primary"
            class="btn btn-primary mr-3 btn-download-excel"
            @click="DownloadXLS"
            >MAPA FORMATIVO XLS</b-button>
          </div>
        </template>
      </div>
    </div>
    <!-- Tabla -->
    <div class="table-responsive">
      <GenericBTable
        :key="generic_table_key"
        :items="matterList"
        :fields="fields"
        :filterCustom="filterCustom"
        :current-page="currentPage"
        :per-page="perPage"
        :pagination="50"
        :filter="input_search"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
        :show_pagination="true"
        :search_filter="true"
        :view_filter_by="true"
        :columns_display="true"
        :allows_crud="allows_crud &&
            ((user_position &&[1, 2, 3].includes(user_position.position) && 
              [1, 2, 3].includes(user.groups[0])) ||
              user.is_superuser ||
              $hasObjectPermission(
                'mesh.career',
                'change',
                egressProfile.career
              ))"
        :display_id="'profiles-list'"
        :item_created_id="item_created_id"
        @emitChangeSearchField="changeInputSearch"
        @emitChangeFilterOn="changeFilterOn"
        sticky-header="600px"
      >
        <template #cell(matter)="row">
          <div class="align-middle text-left" :class="[ row.item.id == last_matter? 'new-matter-container' : '' ]">
          <div class="d-flex">
            <div class="mr-2">
              <span class="pl-1">{{row.item.order}}</span>
            </div>
          <div>
            <b-link
              :to="{
                name: 'MatterProgramView',
                params: { matter_id: row.item.id },
              }"
              v-b-tooltip.v-secondary.noninteractive="`Ir al ${$getVisibleNames('manual.matter_program',false,'Programa de la Asignatura')}`"
              class="matter-link"
            >
            <template v-if="allows_crud">
                <template v-if="row.item.is_closed">
                  <b-icon icon="lock" scale="1"></b-icon>
                </template>
                <template v-else>
                  <b-icon icon="unlock" scale="1"></b-icon>
                </template>
              </template>
            {{ getMatterName(row.item.matter).toUpperCase() }}
            </b-link>
            <template v-if="!row.item.use_matter_values">
              <template v-if="row.item.modalities.length>0">
                <b-badge :id="`modalities-badge-${row.item.id}`" class="modalities-badge"> 
                {{row.item.modalities? row.item.modalities.length: ''}}
                </b-badge>
                <ModalitiesPopover :object="row.item" :content="matterModalities(row.item.modalities)" :triggers="'hover'"></ModalitiesPopover>
              </template>
            </template>
            <template v-else>
              <template v-if="oldMatter(row.item) && oldMatter(row.item).modalities.length>0 ">
                <b-badge :id="`modalities-badge-${row.item.id}`" class="modalities-badge"> 
                {{oldMatter(row.item).modalities.length}}
                </b-badge>
                <ModalitiesPopover :object="row.item" :content="matterModalities(oldMatter(row.item).modalities)" :triggers="'hover'"></ModalitiesPopover>
              </template>
            </template>
            <div v-if="allows_crud &&
                  ((user_position &&[1, 2, 3].includes(user_position.position) && 
                    [1, 2, 3].includes(user.groups[0])) ||
                    user.is_superuser ||
                    $hasObjectPermission(
                      'mesh.career',
                      'change',
                      egressProfile.career
                    ))" class="button-div">
                    <!-- && row.item && ((!row.item.is_closed &&!row.item.use_matter_values) || row.item.use_matter_values) -->
              <button-edit
                v-can="'teaching.change_matter'"
                @click="$bvModal.show(`modal-edit-matter-${row.item.id}`)"
                v-b-tooltip.v-secondary.noninteractive="`Editar ${$getVisibleNames('mesh.egressprofilematter',false,'Asignatura')}`"
              ></button-edit>
              <b-button
                variant="none"
                v-can="'teaching.change_matter'"
                v-if="((institution && institution.show_base_ra) || $debug_change_duoc) && matterCompetenceIds(row.item).length > 0 && !isClosedOldMatter(row.item)"
                @click="$bvModal.show(`modal-copy-study-unit-${row.item.id}`)"
                v-b-tooltip.v-secondary.noninteractive="`Vincular ${$getVisibleNames('teaching.basera', true, 'RA Base')} a ${getMatterName(row.item.matter)}`"
              >
                <template v-if="row.item.is_integrative">
                  <b-icon icon="ui-checks"></b-icon>
                </template>
                <template v-else><b-icon icon="ui-radios"></b-icon></template>
              </b-button>
              
              <button-add
                v-can="'teaching.change_matter'"
                v-if="!isClosedOldMatter(row.item)"
                @click="$bvModal.show(`modal-new-study-unit-${row.item.id}`)" 
                v-b-tooltip.v-secondary.noninteractive="`Adicionar ${$getVisibleNames('teaching.ramicro',false,'RA Micro')}`"
              ></button-add>
            </div>
              <b-modal
                :id="`modal-new-study-unit-${row.item.id}`"
                :title="`Crear ${$getVisibleNames(
                        'teaching.ramicro',
                        false,
                        'RA Micro'
                      )}`"
                size="lg"
                hide-footer
              >
                <StudyUnitForm
                  :egress_profile_matter_id="row.item.id"
                  :matter_id="row.item.matter"
                  @created="$bvModal.hide(`modal-new-study-unit-${row.item.id}`)"
                  @create="$bvModal.hide(`modal-new-study-unit-${row.item.id}`)"
                ></StudyUnitForm>
              </b-modal>
              <b-modal
                :id="`modal-copy-study-unit-${row.item.id}`"
                :title="`Vincular ${$getVisibleNames('teaching.basera', false, 'RA Base')} a ${getMatterName(row.item.matter)}`"
                size="lg"
                hide-footer
              >
                <SelectedRaBase
                :matrix2_view="true"
                :competences_ids="matterCompetenceIds(row.item)"
                :egress_profile_id="egress_profile_id"
                :egress_profile_matter="row.item"
                :matter_id="row.item.matter"
                @closeModal="$bvModal.hide(`modal-copy-study-unit-${row.item.id}`)"
              ></SelectedRaBase>
              </b-modal>
              <b-modal
                :id="`modal-edit-matter-${row.item.id}`"
                hide-footer
                :title="`Editar ${$getVisibleNames('mesh.egressprofilematter',false,'Asignatura')}`"
                size="lg"
              >
                <b-form-group
                  label-cols="1"
                  label-cols-sm="3"
                  :label="`${$getVisibleNames(
                    'manual.programa',
                    false,
                    'Programa'
                  )}`"
                  class="label m-0 ml-1 pb-0"
                  label-class="label-matter"
                >

                  <template v-if="career">
                <div class="mt-1">
                    {{ career.name }}
                </div>
                  </template>
                </b-form-group>
                <MatterForm
                  :Matter="row.item"
                  :egress_profile_id="row.item.egress_profile"
                  :show_delete_button="true"
                  @update="slotUpdatedMatter"
                  @updated="slotUpdatedMatter"
                ></MatterForm>
              </b-modal>
              </div>
            </div>
          </div>
        </template>
        <template #cell(competences)="row">
          <div class="content-div" :class="[ row.item.id == last_matter? 'new-matter-container' : '' ]">
            <p
              v-for="competence in matterCompetences(row.item)"
              :key="`competence-${row.item.matter}-${competence.id}`"
              :title="competence.type?competenceTypes.find((x) => x.id == competence.type).name:''"
            >
              <section class="d-flex">
                <strong class="mt-1 competence-order">{{competence.order}}.{{cycleOrder(competence.cycle)}}</strong>   
                  <SentenceContainer 
                  class="mt-1"
                    :Sentence="competence">
                  </SentenceContainer>
              </section>
            </p> <MatterCompetencesModal :matter="row.item" :egressProfile="egressProfile" 
            :open_modal="open_competence_modal == row.item.id ? true : false"
            :allows_crud="!row.item.is_closed ? allows_crud &&
                  ((user_position &&[1, 2, 3].includes(user_position.position) && 
                    [1, 2, 3].includes(user.groups[0])) ||
                    user.is_superuser ||
                    $hasObjectPermission(
                      'mesh.career',
                      'change',
                      egressProfile.career
                    )) : false"></MatterCompetencesModal>
          </div>
        </template>
        <template #cell(ra_macro)="row"><div class="ra-macro-div" :class="[ row.item.id == last_matter? 'new-matter-container' : '' ]">
          <section v-if="getRaMacro(row.item.matter)"><SentenceContainer :Sentence="getRaMacro(row.item.matter)">
              </SentenceContainer></section>
              <div v-if="!isClosedOldMatter(row.item) ? allows_crud &&
                          ((user_position &&[1, 2, 3].includes(user_position.position) && 
                            [1, 2, 3].includes(user.groups[0])) ||
                            user.is_superuser ||
                            $hasObjectPermission(
                              'mesh.career',
                              'change',
                              egressProfile.career
                            )) : false"> 
              <button-edit v-if="getRaMacro(row.item.matter)" v-b-tooltip.v-secondary="`Editar ${$getVisibleNames('teaching.ramacro', false, 'RA Macro')}`" v-can="'teaching.change_ramacro'"
              @click="$bvModal.show(`modal-edit-ra-macro-${row.item.id}`)"></button-edit>
              <button-add v-if="!getRaMacro(row.item.matter)" v-b-tooltip.v-secondary="`Crear ${$getVisibleNames('teaching.ramacro', false, 'RA Macro')}`" v-can="'teaching.change_ramacro'"
              @click="$bvModal.show(`modal-new-ra-macro-${row.item.id}`)"></button-add>
              <b-modal
              :id="`modal-new-ra-macro-${row.item.id}`"
              :title="`Crear ${$getVisibleNames('teaching.ramacro', false, 'RA Macro')}.`"
              size="lg"
              hide-footer
            >
              <RAMacroForm
                :matter_id="row.item.matter"
                @created="$bvModal.hide(`modal-new-ra-macro-${row.item.id}`)"
                @create="$bvModal.hide(`modal-new-ra-macro-${row.item.id}`)"
              ></RAMacroForm>
            </b-modal>
            <b-modal
              :id="`modal-edit-ra-macro-${row.item.id}`"
              :title="`Editar ${$getVisibleNames('teaching.ramacro', false, 'RA Macro')}.`"
              size="lg"
              hide-footer
            >
              <RAMacroForm
                :matter_id="row.item.matter"
                :RAMacro="getRaMacro(row.item.matter)"
                @update="$bvModal.hide(`modal-edit-ra-macro-${row.item.id}`)"
                @updated="$bvModal.hide(`modal-edit-ra-macro-${row.item.id}`)"
              ></RAMacroForm>
            </b-modal>
              </div>
              </div></template>
        <template #cell(ra_micro)="row">
          <div class="ra-micro-container" :class="[ row.item.id == last_matter? 'new-matter-container' : '' ]">
            <template v-if="studyUnitList(row.item).length>0">                
              <template v-for="(study_unit) in studyUnitList(row.item)">
                <StudyUnitCell
                v-if="study_unit"
                :egress_profile_matter_id="row.item.id"
                :study_unit_id="study_unit.id"
                :key="`study-unit-cell-${study_unit.id}`"
                :matter_id="row.item.matter"
                :allows_crud="!isClosedOldMatter(row.item) ? allows_crud &&
                    ((user_position &&[1, 2, 3].includes(user_position.position) && 
                      [1, 2, 3].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        egressProfile.career
                      )) : false"
                class="ra-micro-content"
              ></StudyUnitCell>
              </template>
            </template>
            <template v-else>
              N/A
            </template>
          </div>
        </template>
        <template #cell(methodological_strategies)="row">
          <div :class="[ row.item.id == last_matter? 'new-matter-container' : '' ]">
          <MethodologicalStrategiesCell
            :ProfileMatter="row.item"
            :allows_crud="!isClosedOldMatter(row.item) ? allows_crud &&
                    ((user_position &&[1, 2, 3].includes(user_position.position) && 
                      [1, 2, 3].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        egressProfile.career
                      )) : false"
          ></MethodologicalStrategiesCell>
          </div>
          </template>
      </GenericBTable>
    </div>
    <!-- Modals -->
    <!-- cycle &&  -->
      <!-- v-if="allows_crud &&
          (([1, 2, 3].includes(user_position.position) && user_position &&
            [1, 2, 3].includes(user.groups[0])) ||
            user.is_superuser ||
            $hasObjectPermission(
              'mesh.career',
              'change',
              egressProfile.career
            ))" -->
    <b-modal
      id="new-matter-modal"
      :title="`Crear ${$getVisibleNames('mesh.egressprofilematter',false,'Asignatura')}`"
      size="lg"
      hide-footer
    >
      <MatterForm
        :egress_profile_id="egress_profile_id"
        :courses_wrapper_id="courses_wrapper_id"
        @created="slotCreatedMatter"
      ></MatterForm>
    </b-modal>
    <b-modal
      id="course-wrapper-modal"
      :title="`${$getVisibleNames('mesh.coursewrapper',false,'Envoltorio de Asignaturas')}`"
      size="xl"
      hide-footer
    >
      <CoursesWrapperView 
      :egress_profile_id="egress_profile_id"
      :allows_crud="allows_crud"
      @closeModal="$bvModal.hide('course-wrapper-modal')"></CoursesWrapperView>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { URL } from "@/utils/globals";

export default {
  name: "Matrix2",
  components: {
    SelectedRaBase: () => import("@/components/reusable/SelectedRaBase"),
    // EvaluationProcedureCell: () => import("./EvaluationProcedureCell"),
    MatterCompetencesModal: () => import("./MatterCompetencesModal"),
    RemoveEPMattersComponent: () => import("./RemoveEPMattersComponent"),
    MethodologicalStrategiesCell: () =>
      import("./MethodologicalStrategiesCell"),
    StudyUnitForm: () => import("@/components/reusable/StudyUnitForm"),
    // MatterTable: () => import("./MatterTable"),
    RAMacroForm: () => import("./RAMacroForm"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    StudyUnitCell: () => import("./StudyUnitCell"),
    MatterForm: () => import("./MatterForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    ModalitiesPopover: () => import("@/components/reusable/ModalitiesPopover"),
    CoursesWrapperView: () =>
      import("@/components/mesh/Matrix2/CoursesWrapper/CoursesWrapperView"),
    // RaBase: () => import("@/components/reusable/RaBase"),
  },
  props: {
    // cycle_id: {
    //   type: Number,
    //   required: true,
    // },
    isBusy: {
      type: Boolean,
      required: true,
    },
    egress_profile_id: {
      type: Number,
      required: true,
    },
    courses_wrapper_id: {
      default: null,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // selected_cycle_id: this.cycle_id,
      input_search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [5, 10, 15, { value: 1000, text: "Mostrar Todos" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      window_height: 100,
      loading: false,
      tabIndex: 0,
      last_matter: null,
      open_competence_modal: null,
      item_created_id: null,
      generic_table_key: 0,
      matters_reports: null,
      // profile_ra_base: [],
    };
  },
  computed: {
    ...mapGetters({
      user_access: names.USER_POSITION,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      cycles: names.CYCLES,
      user: "getUser",
      institution: "getInstitution",
      user_position: names.USER_POSITION,
      matters: names.MATTERS,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      profile_cycles: names.PROFILE_CYCLES,
      competenceTypes: names.COMPETENCE_TYPES,
      learningResults: names.LEARNING_RESULTS,
      profile_competences: names.PROFILE_COMPETENCES,
      studyUnits: names.STUDY_UNITS,
      evaluationInstruments: names.EVALUATION_INSTRUMENTS,
      methodologicalStrategies: names.METHODOLOGICAL_STRATEGIES,
      competences: names.COMPETENCES,
      ra_macros: names.RA_MACROS,
      modalities: names.MODALITIES,
      ra_bases: names.RA_BASES,
      courses_wrapper: names.COURSES_WRAPPER,
    }),
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    coursesWrapper() {
      if (!this.courses_wrapper_id) return null;
      return this.courses_wrapper.find((x) => x.id == this.courses_wrapper_id);
    },
    coursesWrapperList() {
      return this.courses_wrapper.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
    },
    wrapperMatterIds() {
      let list = [];
      this.coursesWrapperList.forEach((x) => {
        if (this.courses_wrapper_id == null)
          list = list.concat(x.egress_profile_matters);
        else if (
          x.id == this.courses_wrapper_id &&
          this.courses_wrapper_id != null
        )
          list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    matterList() {
      let list = [];
      if (this.courses_wrapper_id == null)
        list = this.egressProfileMatters.filter(
          (x) =>
            x.egress_profile == this.egress_profile_id &&
            !this.wrapperMatterIds.includes(x.id)
          //  &&
          // x.cycle == this.selected_cycle_id
        );
      else if (this.courses_wrapper_id != null)
        list = this.egressProfileMatters.filter(
          (x) =>
            x.egress_profile == this.egress_profile_id &&
            this.wrapperMatterIds.includes(x.id)
        );
      list = list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
      return list;
    },
    fields() {
      return [
        {
          key: "matter",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ).toUpperCase(),
          class: "text-center",
          display_column: true,
          sortable: true,
          thClass: "align-middle",
          thStyle: {
            width: "13%",
            background: "var(--primary-color) !important",
          },
          tdClass: "align-middle",
        },
        {
          key: "competences",
          class: "text-center",
          label: String(
            this.$getVisibleNames(
              "mesh.competence",
              true,
              "Competencias"
            ).toUpperCase()
          ),
          display_column: true,
          // sortable: true,
          thClass: "align-middle",
          tdClass: "align-middle",
          thStyle: {
            width: "30%",
            background: "var(--primary-color) !important",
          },
        },
      ]
        .concat(
          this.institution && this.institution.show_ra_macro
            ? [
                {
                  key: "ra_macro",
                  class: "text-center",
                  label: String(
                    this.$getVisibleNames(
                      "teaching.ramacro",
                      false,
                      "RA MACRO"
                    ).toUpperCase()
                  ),
                  display_column: true,
                  tdClass: "align-middle",
                  thClass: "align-middle",
                  thStyle: {
                    width: "15%",
                    background: "var(--primary-color) !important",
                  },
                },
              ]
            : []
        )
        .concat([
          {
            key: "ra_micro",
            class: "text-center",
            label: String(
              this.$getVisibleNames(
                "teaching.ramicro",
                false,
                "RA MICRO"
              ).toUpperCase()
            ),
            display_column: true,
            thStyle: {
              width: "17%",
              background: "var(--primary-color) !important",
            },
            thClass: "align-middle",
            tdClass: "align-middle",
          },
          {
            key: "methodological_strategies",
            class: "text-center",
            label:
              this.$getVisibleNames(
                "mesh.methodologicalstrategy",
                true,
                "Estrategias Metodológicas"
              ).toUpperCase() + " MÍNIMAS",
            display_column: true,
            thStyle: {
              width: "15%",
              background: "var(--primary-color) !important",
            },
            tdClass: "align-middle",
          },
        ]);
    },
    jsonFields() {
      let json_fields = {};
      if (this.matters_reports != null)
        for (
          let index = 0;
          index < this.matters_reports.labels.length;
          index++
        ) {
          const label = `<table><th style='display:table-cell;background-color: #9BC2E6; vertical-align: middle; border: .5pt solid black' >${this.matters_reports.labels[index]}</th></table>`;
          json_fields[label] = this.matters_reports.labels[index];
        }

      return json_fields;
    },
    itemsXLS() {
      let list = [];
      if (this.matters_reports != null) {
        this.matters_reports.datasets.forEach((elements) => {
          let row = {};
          for (let index = 0; index < elements.data.length; index++) {
            const label = this.matters_reports.labels[index];
            const data = elements.data[index];
            row[label] = data;
          }
          list.push(row);
        });
      }
      return list;
    },
    // cycle() {
    //   return this.cycles.find((x) => x.id == this.selected_cycle_id);
    // },
  },
  methods: {
    handleResize() {
      this.window_height = window.innerHeight - 180;
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    changeFilterOn(filter_on) {
      this.filterOn = filter_on;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    DownloadXLS() {
      // window.open(
      //   URL +
      //     `/teaching/excel_report_formative_map/?egress_profile=${this.egress_profile_id}`,
      //   "_blank"
      // );
      this.$restful
        .Get(
          `/teaching/excel_report_formative_map/?egress_profile=${this.egress_profile_id}`
        )
        .then((response) => {
          // URL + `/teaching/excel_report_formative_map/?egress_profile=${this.egress_profile_id}`,
          if (response && response.file_url)
            window.open(response.file_url, "_blank");
        });
    },
    // raMacro(matter) {
    //   return this.ra_macros.find((x) => x.matter == matter.matter);
    // },
    getMatterName(matter_id) {
      const matter = this.matters.find((x) => x.id == matter_id);
      return matter ? matter.name : "";
    },
    getRaMacro(matter_id) {
      const ra = this.ra_macros.find((x) => x.matter == matter_id);
      return ra ? ra : null;
    },
    cycleOrder(cycle_id) {
      if (!cycle_id && !this.egressProfile) return "-";
      let cycleOrder = this.profile_cycles.find(
        (x) => x.cycle == cycle_id && x.egress_profile == this.egressProfile.id
      );
      return cycleOrder ? cycleOrder.order : "-";
    },
    getCompetence(comtetence_id) {
      const competence = this.competences.find((x) => x.id == comtetence_id);
      return competence ? competence.full_sentence : "";
    },
    filterMethodolicalStategies(matter) {
      const OldMatter = this.oldMatter(matter);
      if (OldMatter) {
        const methodological_strategies = this.methodologicalStrategies;
        if (
          methodological_strategies.find(
            (x) =>
              this.$filtered(x.name, this.input_search) &&
              OldMatter.methodological_strategies.includes(x.id)
          )
        ) {
          return true;
        }
      }
      return false;
    },
    filterEvaluationInstruments(matter) {
      const OldMatter = this.oldMatter(matter);
      if (OldMatter) {
        const evaluation_instruments = this.evaluationInstruments;
        if (
          evaluation_instruments.find(
            (x) =>
              this.$filtered(x.value, this.input_search) &&
              JSON.parse(OldMatter.evaluation_instruments).includes(x.id)
          )
        ) {
          return true;
        }
      }
      return false;
    },
    filterStudyUnits(matter) {
      const OldMatter = this.oldMatter(matter);
      if (OldMatter) {
        const study_units = this.studyUnits;
        if (
          study_units.find(
            (x) =>
              (this.$filtered(x.sentence, this.input_search) ||
                this.$filtered(x.name, this.input_search)) &&
              OldMatter.study_units.includes(x.id)
          )
        ) {
          return true;
        }
      }
      return false;
    },
    filterCustom(row) {
      if (
        (this.$filtered(this.getMatterName(row.matter), this.input_search) ||
          row.competences.find((x) =>
            this.$filtered(this.getCompetence(x), this.input_search)
          ) ||
          this.$filtered(
            this.getRaMacro(row.matter)
              ? this.getRaMacro(row.matter).sentence
              : "",
            this.input_search
          ) ||
          this.filterStudyUnits(row) ||
          this.filterMethodolicalStategies(row) ||
          this.filterEvaluationInstruments(row)) &&
        this.filterOn.length == 0
      ) {
        return true;
      } else if (
        (this.$filtered(this.getMatterName(row.matter), this.input_search) &&
          this.filterOn.includes("matter")) ||
        (row.competences.find((x) =>
          this.$filtered(this.getCompetence(x), this.input_search)
        ) &&
          this.filterOn.includes("competences")) ||
        (this.$filtered(
          this.getRaMacro(row.matter)
            ? this.getRaMacro(row.matter).sentence
            : "",
          this.input_search
        ) &&
          this.filterOn.includes("ra_macro")) ||
        (this.filterStudyUnits(row) && this.filterOn.includes("ra_micro")) ||
        (this.filterMethodolicalStategies(row) &&
          this.filterOn.includes("methodological_strategies")) ||
        (this.filterEvaluationInstruments(row) &&
          this.filterOn.includes("evaluation_instruments"))
      ) {
        return true;
      } else {
        return false;
      }
    },
    studyUnitList(matter) {
      return this.studyUnits.filter((x) => x.matter == matter.matter);
    },
    oldMatter(matter) {
      return this.matters.find((x) => x.id == matter.matter);
    },
    isClosedOldMatter(epm_matter) {
      const matter = this.matters.find((x) => x.id == epm_matter.matter);
      if (!matter) return true;
      else {
        if (matter.is_closed) return true;
        else return false;
      }
    },
    matterCompetenceIds(matter) {
      const competence_ids = this.matterCompetences(matter).map(
        (x) => x.competence
      );
      return competence_ids;
    },
    matterCompetences(matter) {
      let list = [];
      if (
        !matter &&
        !this.egressProfile &&
        !this.profile_competences &&
        !this.competences
      )
        return list;
      matter.competences.forEach((element) => {
        let get_competence = this.competences.find((x) => x.id == element);
        if (get_competence) {
          let instance = this.profile_competences.find(
            (x) =>
              x.competence == get_competence.id &&
              x.egress_profile == this.egressProfile.id
          );
          if (instance) {
            list.push({
              id: instance.id,
              order: instance.order,
              egress_profile: instance.egress_profile,
              competence: instance.competence,
              cycle: instance.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              full_sentence: get_competence.full_sentence,
              type: get_competence.type,
              verb: get_competence.verb,
              sentence: get_competence.sentence,
              name: get_competence.name,
              selected: get_competence.selected,
            });
          }
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.cycle > b.cycle) return 1;
        if (a.cycle < b.cycle) return -1;
        return 0;
      });
    },
    slotUpdatedMatter(matter) {
      this.$bvModal.hide(`modal-edit-matter-${matter.id}`);
      this.fetchMatterReport();
    },
    slotCreatedMatter(matter) {
      this.$bvModal.hide("new-matter-modal");
      this.fetchMatterReport();
      this.last_matter = matter.id;
      this.open_competence_modal = matter.id;
      this.generic_table_key += 1;
      setTimeout(() => {
        this.item_created_id = matter.id;
      }, 500);
      setTimeout(() => {
        this.open_competence_modal = null;
        this.last_matter = null;
      }, 1000);

      // window.scrollTo(0,document.body.scrollHeight)
      // if (this.matterList.length > 1) {
      //   setTimeout(() => {
      //     window.scrollTo(
      //       0,
      //       document.body.scrollHeight || document.documentElement.scrollHeight
      //     );
      //   }, 100);
      // }
    },
    fetchMatterReport() {
      this.$restful
        .Get(
          `/mesh/egress_profile_matter_report/?egress_profile=${this.egress_profile_id}`
        )
        .then((response) => {
          this.matters_reports = response;
        });
    },
    matterModalities(modalities_ids) {
      return this.modalities.filter((x) => modalities_ids.includes(x.id));
    },
    // raBasesFiltered(comptence_id) {
    //   if (!comptence_id) return [];
    //   return this.ra_bases.filter((x) => x.competences.includes(comptence_id));
    // },
    // availableAmountOfRaBase(egress_profile_matter) {
    //   if (egress_profile_matter) {
    //     const study_unit_ids = [
    //       ...new Set(
    //         this.studyUnits
    //           .filter((x) => x.matter == egress_profile_matter.matter)
    //           .map((x) => x.id)
    //       ),
    //     ];
    //     let profile_ra = [];
    //     if (egress_profile_matter.is_integrative) {
    //       profile_ra = [
    //         ...new Set(
    //           this.profile_ra_base
    //             .filter(
    //               (x) =>
    //                 x.egress_profile == this.egress_profile_id &&
    //                 study_unit_ids.includes(x.study_unit)
    //             )
    //             .map((x) => x.base_ra)
    //         ),
    //       ];
    //     } else {
    //       profile_ra = [
    //         ...new Set(
    //           this.profile_ra_base
    //             .filter((x) => x.egress_profile == this.egress_profile_id)
    //             .map((x) => x.base_ra)
    //         ),
    //       ];
    //     }
    //     return this.ra_bases.filter(
    //       (x) =>
    //         x.competences.some((i) =>
    //           egress_profile_matter.competences.includes(i)
    //         ) && !profile_ra.includes(x.id)
    //     );
    //   }
    // },
  },
  created() {
    this.fetchMatterReport();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.$store.dispatch(names.FETCH_MODALITIES);
    // this.$restful
    //   .Get(
    //     `/mesh/egress-profile-base-ra/?egress_profile=${this.egress_profile_id}`
    //   )
    //   .then((response) => {
    //     this.profile_ra_base = response;
    //   });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    egressProfile: function () {
      if (this.egressProfile)
        this.$store.dispatch(names.FETCH_CAREER, this.egressProfile.career);
    },
    item_created_id() {
      this.last_ep = this.item_created_id;
      setTimeout(() => {
        this.item_created_id = null;
      }, 2500);
    },
  },
  mounted() {
    // window.scrollTo(0, 0);
    this.totalRows = this.matterList.length;
  },
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}
/* .top-div {
  z-index: 100;
  position: relative;
  padding-top: 0.8em;
  margin-top: -1.2%;
} */
.table-container {
  padding-top: 0px;
  margin-top: 0px;
  z-index: 0;
}
.table-container-max-height {
  padding-top: 0px;
  margin-top: 0px;
  height: 100% !important;
}
.center-div-child {
  position: absolute;
  /* background: rgb(251, 251, 251); */
  padding: 0.6em 0;
  width: 50vh;
  top: 25%;
  left: 50%;
  border-radius: 3px;
  transform: translate(-50%, -50%);
}
th,
thead {
  position: sticky !important;
  top: 0 !important;
  margin-top: 0 !important;
  border-top: 0 solid black;
  width: 100vh;
}
th {
  position: sticky !important;
  z-index: 100;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  font-size: var(--thirth-font-size);
  margin: 0px 0px;
  padding: 0px 20px;
  text-align: center;
  background: var(--secondary-color);
  color: white;
  border: 1px solid #000;
}
/* tr.nonsticky th {
  position: relative;
  border: 1px solid #000;
} */

.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
.competence-order {
  font-size: var(--secondary-font-size);
}
.bottom-row {
  background: var(--secondary-color);
}
.new-matter-container {
  /* padding: 0.25em;
  width: 100% !important;
  height: max-content !important; */
  animation: blinker 1s linear 7;
}
.table-responsive >>> .table td {
  vertical-align: middle;
}
.form-group >>> .label-matter {
  font-size: var(--secondary-font-size);
}
/* .label {
  font-size: var(--secondary-font-color);
} */

.modalities-badge,
.certification-badge {
  font-size: 0.6rem;
  vertical-align: top;
  padding: 0.15rem 0.2rem;
  cursor: default !important;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.ra-macro-div {
  margin-left: 0em;
  /* word-break: break-all; */
  hyphens: auto;
}
.ra-micro-container {
  display: flex !important;
  flex-direction: column;
}
.ra-micro-content {
  border-bottom: 0.1px solid var(--secondary-color);
  margin: 0 !important;
  margin-top: 0.4em !important;
  min-height: fit-content;
}
.ra-micro-content:last-child {
  border-bottom: none;
}
.matter-link {
  font-size: 10pt;
}
.btn-download-excel {
  min-width: 154px;
}
/* @media (min-width: 1800px) {
  .top-div {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
} */
/* @media (min-width: 1500px) {
  .top-div {
    padding-top: 0.8em;
    margin-top: -1%;
  }
} */
@media (max-width: 800px) {
  thead {
    width: 90vh;
    right: 0 !important;
  }
  .center-div-child {
    top: 30%;
  }
  .content-div {
    hyphens: auto;
  }
}
.pointer-class {
  cursor: pointer;
}
</style>