<template>
  <b-container class="pb-4 container-modal">
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-body>
            <b-icon
              :id="`comment-action-${unique_id}`"
              class="float-right"
              style="cursor: pointer"
              icon="info-circle"
            ></b-icon>
            <div class="card-title ml-3">{{ first_title }}</div>
            <p class="card-text text-center">({{ first_body }})</p>
          </b-card-body>
        </b-card>
        <b-popover
          :target="`comment-action-${unique_id}`"
          placement="bottom"
          title="Información"
          triggers="hover focus"
        >
          <template v-slot:title></template>
          <div>
            <ul>
              <li>
                <p>
                  <strong>¿Qué hace?</strong> ({{
                    $getVisibleNames("mesh.verb", false, "Verbo").toLowerCase()
                  }}):
                  <strong>¿Qué sabrá hacer?</strong>
                </p>
              </li>
              <li>
                <p>
                  La acción a
                  <strong>desempeñar</strong>.
                </p>
              </li>
              <li>
                <p>
                  Se expresa en
                  <strong>indicativo</strong> (diseña, comunica).
                </p>
              </li>
              <li>
                <p>
                  Refleja una
                  <strong>acción factible de evaluar</strong> y observar en
                  tiempo presente.
                </p>
              </li>
            </ul>
          </div>
        </b-popover>
      </b-col>
      <!-- <b-col cols="1" style="vertical-align: middle">
        <unicon name="plus" fill="black" width="35" height="35"></unicon>
      </b-col> -->
      <b-col>
        <b-card no-body>
          <b-card-body>
            <b-icon
              :id="`comment-content-${unique_id}`"
              class="float-right"
              style="cursor: pointer"
              icon="info-circle"
            ></b-icon>
            <div class="card-title ml-3">{{ second_title }}</div>
            <p class="card-text text-center">({{ second_body }})</p>
          </b-card-body>
        </b-card>
        <b-popover
          :target="`comment-content-${unique_id}`"
          placement="bottom"
          title="Información"
          triggers="hover focus"
        >
          <template v-slot:title></template>
          <div>
            <ul>
              <li>
                <p><strong>Sobre Qué</strong> realiza la acción (objeto).</p>
              </li>
              <li>
                <p style="font-style: italic">
                  <strong
                    >¿Qué le pediré que evalúe, maneje, analice,
                    informe?</strong
                  >
                </p>
              </li>
            </ul>
          </div>
        </b-popover>
      </b-col>
      <!-- <b-col cols="1" style="vertical-align: middle">
        <unicon name="plus" fill="black" width="35" height="35"></unicon>
      </b-col> -->
      <b-col>
        <b-card no-body>
          <b-card-body>
            <b-icon
              :id="`comment-condition-${unique_id}`"
              class="float-right"
              style="cursor: pointer"
              icon="info-circle"
            ></b-icon>
            <div class="card-title ml-3 padding-bottom">{{ third_title }}</div>
          </b-card-body>
        </b-card>
        <b-popover
          :target="`comment-condition-${unique_id}`"
          placement="bottom"
          title="Información"
          triggers="hover focus"
        >
          <template v-slot:title></template>
          <div>
            <ul>
              <li>
                <p>
                  <strong>¿Para Qué?</strong>
                </p>
              </li>
              <li>
                <p><strong>Actitudes (Competencias Transversales)</strong>.</p>
              </li>
              <li>
                <p style="font-style: italic">
                  Se consideran en relación al objeto/contenido y a la acción a
                  realizar.
                </p>
              </li>
            </ul>
          </div>
        </b-popover>
      </b-col>
      <b-col>
        <b-card no-body>
          <b-card-body>
            <b-icon
              :id="`comment-context-${unique_id}`"
              class="float-right"
              style="cursor: pointer"
              icon="info-circle"
            ></b-icon>
            <div class="card-title ml-3 padding-bottom">{{ fourth_title }}</div>
          </b-card-body>
        </b-card>
        <b-popover
          :target="`comment-context-${unique_id}`"
          placement="bottom"
          title="Información"
          triggers="hover focus"
        >
          <template v-slot:title></template>
          <div>
            <ul>
              <li>
                <p><strong>En Qué Contexto</strong>.</p>
              </li>
              <li>
                <p style="font-style: italic">
                  <strong
                    >¿Qué medios, escenarios, situaciones, contextos
                    normativos?</strong
                  >
                </p>
              </li>
              <li>
                <p style="font-style: italic">
                  Se consideran en relación a la condición, objeto/contenido y a
                  la acción a realizar.
                </p>
              </li>
            </ul>
          </div>
        </b-popover>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="z-index: 1">
        <!-- <label :for="`taxonomy_select-${unique_id}`">Taxonomía</label> -->
        <span v-b-toggle.collapse-1 class="mb-0">
          <b-button class="btn-transparent" @click="toggleCollapse()">
            <b-icon
              class="taxonomy-icon"
              v-if="!is_collapsed"
              icon="chevron-down"
              v-b-tooltip.v-secondary="
                `Mostrar ${$getVisibleNames(
                  'mesh.taxonomy',
                  false,
                  'Taxonomía'
                )}`
              "
            ></b-icon>
            <b-icon
              v-else
              icon="chevron-up"
              :class="{ 'collapse-icons': !is_collapsed }"
              v-b-tooltip.v-secondary="
                `Ocultar ${$getVisibleNames(
                  'mesh.taxonomy',
                  false,
                  'Taxonomía'
                )}`
              "
            >
            </b-icon>
          </b-button>
        </span>
        <b-collapse id="collapse-1" class="mt-2 mb-3">
          <b-form-select
            v-model="selected_taxonomy"
            class="mb-1"
            :id="`taxonomy_select-${unique_id}`"
            :required="true"
            size="sm"
            :disabled="!allows_crud"
          >
            <option :value="null" selected>--Seleccione Taxomonía--</option>
            <option
              v-for="(taxonomy, index) in taxonomies"
              :key="taxonomy.id"
              :value="index"
            >
              {{ taxonomy.name }}
            </option>
          </b-form-select>
        </b-collapse>
        <div class="container-verb">
          <div
            class="wrapper"
            v-if="selected_taxonomy >= 0 && taxonomies[selected_taxonomy]"
          >
            <label
              :for="`multiselect_verbs-${unique_id}`"
              style="margin-bottom: -3%"
              class="verb-label"
              >{{ $getVisibleNames("mesh.verb", true, "Verbos") }}</label
            >
            <!-- <multiselect
            :id="`multiselect_verbs-${unique_id}`"
            v-model="selected_verb"
            :options="getVerbs"
            :placeholder="
              selected_verb
                ? selected_verb.name
                : firstContent
                ? firstContent
                : 'Seleccione un verbo'
            "
            label="name"
            :custom-label="customLabel"
            track-by="name"
            size="sm"
            :disabled="!allows_crud"
            selectLabel="Seleccionar"
            @select="selectedVerb"
            @remove="removeVerb"
          ></multiselect> -->
            <!-- :placeholder="
                selected_verb
                  ? selected_verb.name
                  : firstContent
                  ? firstContent
                  : 'Seleccione un verbo'
              " -->
            <v-select
              :id="`multiselect_verbs-${unique_id}`"
              v-model="selected_verb"
              :options="getVerbs"
              :reduce="(getVerbs) => getVerbs.id"
              label="name"
              track-by="id"
              size="sm"
              :disabled="!allows_crud"
              selectLabel="Seleccionar"
              @option:selected="selectedVerb"
              @option:deselecting="removeVerb"
              v-b-tooltip.hover.v-secondary="
                selected_verb ? getCognitiveLevel : ''
              "
            >
              <template v-slot:option="option">
                <span>{{ option.cognitive_level }}. </span>
                {{ option.name }}
              </template>
              <template slot="selected-option" slot-scope="option">
                <span
                  v-b-tooltip.v-secondary="
                    `${$getVisibleNames(
                      'mesh.cognitivelevel',
                      false,
                      'Nivel Cognitivo'
                    )}`
                  "
                  >{{ option.cognitive_level }}.
                </span>
                {{ option.name }}
              </template>
              <template slot="no-options">
                <span
                  >No hay
                  {{ $getVisibleNames("mesh.verb", true, "Verbos") }} que
                  coincidan con su búsqueda.</span
                >
              </template>
            </v-select>
          </div>

          <div class="row mt-2 alter-verb-div">
            <label
              class="alternative-verb-label"
              :for="`alternative_verb-${unique_id}`"
              >Personalizar
              {{ $getVisibleNames("mesh.verb", false, "Verbo") }}</label
            >
            <div
              class="alternative-verb"
              style="display: block; margin-top: -4%; margin-left: 5%"
            >
              <b-form-group class="mb-1">
                <b-input-group>
                  <!-- <b-input-group-append>
                    <b-input-group-text
                      class="
                        alter-verb-input-append
                        bg-transparent
                        border-right-0
                        mt-2
                        pr-0
                      "
                    >
                      {{ alternative_verb1 }}
                    </b-input-group-text>
                  </b-input-group-append> -->
                  <b-form-input
                    :id="`alternative_verb-${unique_id} `"
                    class="alter-verb-input ml-1 pl-2 mt-2"
                    v-model="alternative_verb2"
                    @update="fillSelectedVerb"
                  >
                  </b-form-input>
                  <b-tooltip
                    v-if="!this.selected_verb"
                    :id="`alter-verb-tooltip-${unique_id} `"
                    :target="`alternative_verb-${unique_id} `"
                    variant="secondary"
                    triggers="focus"
                    :show="!this.selected_verb && full_sentence_changed"
                    placement="bottom"
                    class="mb-2"
                  >
                    Este verbo se guardará sin nivel cognitivo.
                  </b-tooltip>
                </b-input-group>
              </b-form-group>
              <b-form-text
                v-if="!selected_verb && allows_crud && !alternative_verb2"
                style="color: #dc3545 !important"
                >Este campo es obligatorio.</b-form-text
              >
            </div>
          </div>
        </div>
      </b-col>
      <!-- <b-col></b-col> -->
      <b-col v-if="!checked" cols="9">
        <b-form-textarea
          class="textarea mt-1"
          v-model="fullSentence"
          :state="fullSentenceState"
          aria-describedby="input-full-sentence-feedback"
          :rows="5"
          :disabled="!allows_crud"
          @change="onChangeFullSentenceInput"
        ></b-form-textarea>
        <b-form-invalid-feedback id="input-full-sentence-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-col>
      <b-col v-if="checked">
        <b-form-textarea
          class="textarea mt-1"
          v-model="secondContent"
          :state="secondContentState"
          aria-describedby="input-second-feedback"
          :rows="5"
          :disabled="!allows_crud"
        ></b-form-textarea>
        <b-form-invalid-feedback id="input-second-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-col>
      <!-- <b-col></b-col> -->
      <b-col v-if="checked">
        <b-form-textarea
          class="textarea mt-1"
          v-model="thirdContent"
          :state="thirdContentState"
          aria-describedby="input-third-feedback"
          :rows="5"
          :disabled="!allows_crud"
        ></b-form-textarea>
        <b-form-invalid-feedback id="input-third-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
        <!-- <b-form-text style="margin: 0; padding: 0">{{
          third_content_comment
        }}</b-form-text> -->
      </b-col>
      <b-col v-if="checked">
        <b-form-textarea
          class="textarea mt-1"
          v-model="fourthContent"
          :state="fourthContentState"
          aria-describedby="input-fourth-feedback"
          :rows="5"
          :disabled="!allows_crud"
        ></b-form-textarea>
        <b-form-invalid-feedback id="input-fourth-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-col>
    </b-row>
    <!-- <b-row v-if="validateSentence">
      <b-col colspan="5">
        <p v-for="error in errors" :key="error">
          <span style="color: red; font-size: 8pt">{{ error }}</span>
        </p>
      </b-col>
    </b-row> -->
    <b-row v-if="errors.length == 0">
      <p style="text-align: center" class="w-100" v-if="checked">
        <span style="color: var(--primary-color)">{{
          firstContent + " "
        }}</span>
        <span style="color: gray">{{ secondContent }}</span>
        <span style="color: black">{{ " " + thirdContent }}</span>
        <span style="color: black">{{ " " + fourthContent }}</span>
      </p>
      <p style="text-align: center" class="w-100" v-else>
        <span style="color: var(--primary-color)">{{
          firstContent + " "
        }}</span>
        <span style="color: gray">{{
          full_sentence
            ? full_sentence
                .trim()
                .substr(full_sentence.trim().indexOf(" ") + 1)
                .trim() == full_sentence.trim().split(" ").shift()
              ? ""
              : full_sentence
                  .trim()
                  .substr(full_sentence.trim().indexOf(" ") + 1)
                  .trim()
            : ""
        }}</span>
      </p>
    </b-row>
  </b-container>
</template>

<script>
// import Multiselect from "vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "CompetenceSentenceForm",
  components: {
    // Multiselect,
    vSelect,
  },
  props: {
    first_title: {
      type: String,
      default: "Acción",
    },
    second_title: {
      type: String,
      default: "Objeto",
    },
    third_title: {
      type: String,
      default: "Condición",
    },
    fourth_title: {
      type: String,
      default: "Contexto",
    },
    first_body: {
      type: String,
      default: "Verbo",
    },
    second_body: {
      type: String,
      default: "Contenido",
    },
    first_content: {
      type: String,
      default: "",
    },
    second_content: {
      type: String,
      default: "",
    },
    third_content: {
      type: String,
      default: "",
    },
    fourth_content: {
      type: String,
      default: "",
    },
    full_sentence: {
      type: String,
      default: "",
    },
    // third_content_comment: {
    //   type: String,
    //   default: "Se recomienda responder a una Competencia Genérica.",
    // },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    verb: {
      type: Number,
      default: null,
    },
    checked: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected_taxonomy: 0,
      selected_verb: this.verb ? this.verb : null,
      // alternative_verb1: this.first_content
      //   ? this.first_content.substr(0, 2)
      //   : "",
      alternative_verb2: this.first_content ? this.first_content : "",
      taxonomies: [],
      firstContent: this.first_content ? this.first_content : "",
      secondContent: this.second_content ? this.second_content : "",
      thirdContent: this.third_content ? this.third_content : "",
      fourthContent: this.fourth_content ? this.fourth_content : "",
      unique_id: generateUniqueId(),
      errors: [],
      is_collapsed: false,
      fullSentence: this.full_sentence ? this.full_sentence : "",
      full_sentence_changed: false,
    };
  },
  computed: {
    getVerbs() {
      let verbs = [];
      this.taxonomies[this.selected_taxonomy].cognitive_levels.forEach((cl) => {
        cl.verbs.forEach((v) => {
          verbs.push({
            cognitive_level: cl.level,
            id: v.id,
            name: v.name,
          });
        });
      });
      return verbs;
    },
    getCognitiveLevel() {
      let verb = "";
      this.taxonomies[this.selected_taxonomy].cognitive_levels.forEach((cl) => {
        if (cl.verbs.find((x) => x.id == this.selected_verb)) {
          verb = "Nivel Cognitivo " + cl.level + ": " + cl.name + ".";
          return verb;
        }
      });
      return verb;
    },
    secondContentState() {
      return this.secondContent.length > 1 ? true : false;
    },
    thirdContentState() {
      return this.thirdContent.length > 1 ? true : false;
    },
    fourthContentState() {
      if (!this.fourthContent) return false;
      return this.fourthContent.length > 1 ? true : false;
    },
    fullSentenceState() {
      return this.fullSentence.length > 1 ? true : false;
    },
  },
  methods: {
    validateSentence() {
      this.errors = [];
      let error = true;
      if (this.checked) {
        if (!this.firstContent || this.firstContent == "") {
          this.errors.push("Debe seleccionar un verbo.");
          error = false;
        }
        if (!this.secondContent || this.secondContent == "") {
          this.errors.push("El contenido no puede estar vacío.");
          error = false;
        }
        if (!this.thirdContent || this.thirdContent == "") {
          this.errors.push("La condición no puede estar vacía.");
          error = false;
        }
        if (!this.fourthContent || this.fourthContent == "") {
          this.errors.push("El contexto no puede estar vacío.");
          error = false;
        }
      } else {
        if (!this.fullSentence || this.fullSentence == "") {
          this.errors.push("El contenido no puede estar vacío.");
          error = false;
        }
      }
      this.$emit("validation", error);
      return error;
    },
    selectedVerb(option) {
      // this.alternative_verb1 = option.name.substr(0, 2);
      // this.alternative_verb2 = option.name.substr(2);
      this.alternative_verb2 = option.name;
    },
    removeVerb() {
      // this.alternative_verb1 = "";
      this.alternative_verb2 = "";
    },
    // customLabel(item) {
    //   let label = item.name;
    //   this.taxonomies[this.selected_taxonomy].cognitive_levels.forEach((cl) => {
    //     if (cl.id == item.cognitive_level) {
    //       label = item.name + ". Nivel " + cl.level;
    //       return label;
    //     }
    //   });
    //   return label;
    // },
    fillSelectedVerb(action) {
      const verb = this.getVerbs.find(
        (x) =>
          x.name
            .normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase() ==
          action
            .normalize("NFD")
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize()
            .trim()
            .toLowerCase()
      );
      if (verb) {
        this.selected_verb = verb.id;
      }
    },
    fetchTaxonomies() {
      this.$restful.Get("/mesh/taxonomy/").then((response) => {
        this.taxonomies = response;
        if (this.taxonomies.length == 1) this.selected_taxonomy = 0;
        this.taxonomies.forEach((t, index) => {
          if (t.active) this.selected_taxonomy = index;
        });
        this.findVerb();
      });
    },
    findVerb() {
      this.taxonomies.forEach((t, index) => {
        t.cognitive_levels.forEach((cl) => {
          cl.verbs.forEach((v) => {
            if (v.id == this.verb) {
              this.selected_verb = v.id;
              this.selected_taxonomy = index;
              if (v.name != this.first_content) {
                // this.alternative_verb1 = this.first_content.substr(0, 2);
                this.alternative_verb2 = this.first_content;
              }
            }
          });
        });
      });
    },
    saveVerb() {
      if (this.validateSentence()) {
        if (this.selected_verb) {
          this.$emit("update", {
            verb: this.selected_verb ? this.selected_verb : null,
            // first_content: this.alternative_verb1.concat(this.alternative_verb2),
            first_content: !this.checked
              ? this.fullSentence.split(" ").shift()
              : this.alternative_verb2,
            second_content: this.secondContent,
            third_content: this.thirdContent,
            fourth_content: this.fourthContent,
            full_sentence: this.fullSentence,
          });
        }
      }
      // else {
      //   this.$emit("update", {
      //     verb: null,
      //     // first_content: this.alternative_verb1.concat(this.alternative_verb2),
      //     first_content: this.alternative_verb2,
      //     second_content: this.secondContent,
      //     third_content: this.thirdContent,
      //     fourth_content: this.fourthContent,
      //   });
      // }
    },
    toggleCollapse() {
      return (this.is_collapsed = !this.is_collapsed);
    },
    onChangeFullSentenceInput() {
      return (this.full_sentence_changed = true);
    },
  },
  watch: {
    selected_verb() {
      // if (!this.first_content && this.selected_verb) {
      //   this.firstContent = this.selected_verb.name;
      // }
      if (this.selected_verb && this.checked) {
        this.firstContent = this.alternative_verb2;
      }
      if (!this.checked) {
        this.fullSentence = this.fullSentence.replace(
          this.fullSentence.trim().split(" ").shift(),
          this.alternative_verb2
        );
        this.firstContent = this.alternative_verb2;
      }
      if (this.validateSentence()) {
        this.$emit("update", {
          verb: this.selected_verb ? this.selected_verb : null,
          first_content: !this.checked
            ? this.fullSentence.split(" ").shift()
            : this.firstContent,
          second_content: this.secondContent,
          third_content: this.thirdContent,
          fourth_content: this.fourthContent,
          full_sentence: this.fullSentence,
        });
      }
    },
    alternative_verb2: {
      handler(item) {
        if (!this.allows_crud) return;
        if (this.checked && !this.selected_verb) {
          this.firstContent = this.alternative_verb2;
        }
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.saveVerb(item);
        }, 200);
      },
      deep: true,
    },
    secondContent: function () {
      if (this.validateSentence()) {
        this.$emit("update", {
          verb: this.selected_verb ? this.selected_verb : null,
          first_content: !this.checked
            ? this.fullSentence.trim().split(" ").shift()
            : this.firstContent,
          second_content: this.secondContent,
          third_content: this.thirdContent,
          fourth_content: this.fourthContent,
          full_sentence: this.fullSentence,
        });
      }
    },
    thirdContent: function () {
      if (this.validateSentence()) {
        this.$emit("update", {
          verb: this.selected_verb ? this.selected_verb : null,
          first_content: !this.checked
            ? this.fullSentence.trim().split(" ").shift()
            : this.firstContent,
          second_content: this.secondContent,
          third_content: this.thirdContent,
          fourth_content: this.fourthContent,
          full_sentence: this.fullSentence,
        });
      }
    },
    fourthContent: function () {
      if (this.validateSentence()) {
        this.$emit("update", {
          verb: this.selected_verb ? this.selected_verb : null,
          first_content: !this.checked
            ? this.fullSentence.trim().split(" ").shift()
            : this.firstContent,
          second_content: this.secondContent,
          third_content: this.thirdContent,
          fourth_content: this.fourthContent,
          full_sentence: this.fullSentence,
        });
      }
    },
    fullSentence: function () {
      if (this.validateSentence()) {
        if (!this.checked) {
          this.alternative_verb2 = this.fullSentence.trim().split(" ").shift();
          this.fillSelectedVerb(this.alternative_verb2);
        }
        this.$emit("update", {
          verb: this.selected_verb ? this.selected_verb : null,
          // first_content: this.firstContent,
          first_content: !this.checked
            ? this.fullSentence.trim().split(" ").shift()
            : this.firstContent,
          second_content: this.secondContent,
          third_content: this.thirdContent,
          fourth_content: this.fourthContent,
          full_sentence: this.fullSentence,
        });
      }
    },
    first_content() {
      this.firstContent = this.first_content;
    },
    second_content() {
      this.secondContent = this.second_content;
    },
    third_content() {
      this.thirdContent = this.third_content;
    },
    fourth_content() {
      this.fourthContent = this.fourth_content;
    },
  },
  created() {
    this.fetchTaxonomies();
    this.validateSentence();
  },
};
</script>
<style>
@media (max-width: 991px) {
  .vs__selected-options {
    max-height: 32px !important;
  }
}
</style>

<style scoped>
.card {
  background-color: var(--primary-color);
  border-radius: 1.2rem;
  margin: 1px;
  color: white;
  text-align: center;
}
.card-title {
  text-decoration: underline;
  font-size: var(--secondary-font-size);
  margin-bottom: 0.25rem;
}
.card-body {
  padding: 0.4rem;
  font-size: var(--thirth-font-size);
}
[class*="col"] {
  text-align: center;
  padding-right: 3px;
  padding-left: 3px;
}
.textarea {
  width: 100%;
  min-height: 150px;
  text-align: justify;
  font-size: var(--secondary-font-size);
  border: 1px solid var(--secondary-color);
}
.padding-bottom {
  padding-bottom: 1.36em;
}
.taxonomy-icon {
  align-items: right;
  right: 0;
  float: right;
}
.collapse-icons {
  display: none;
}
.multiselect {
  height: 20px !important;
}
#collapse-1 {
  z-index: 1 !important;
  transition: 0.3s ease-out;
}
#collapse-1::after {
  display: none;
  transition: 0.3s;
}
.verb-label {
  margin-inline: auto;
}
.alternative-verb-label {
  margin-inline: auto;
  margin-top: 0.45em;
  margin-bottom: 0.3em;
}
.alternative-verb {
  display: flex;
  margin-top: -6% !important;
  margin-inline: 6%;
  width: 100%;
}
.alter-verb-input {
  max-height: 1.9em !important;
}
.alter-verb-input-append {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
.alter-verb-input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #ccc;
}
.container-verb {
  margin-top: 1%;
  z-index: 100;
}
.btn-transparent {
  background: transparent;
  border: none;
  color: black !important;
  font-size: var(--thirth-font-size);
  text-decoration: none;
  float: right;
  margin-top: -1% !important;
}
.btn-transparent:hover {
  color: black !important;
}
@media (min-width: 1600px) {
  .alter-verb-input-append {
    padding-top: 12px;
    margin-left: 14%;
  }
  .alter-verb-input {
    padding-bottom: 7%;
  }
}
@media (min-width: 1478px) {
  .alter-verb-input-append {
    padding-top: 18%;
  }
}
@media (max-width: 991px) {
  .vs__dropdown-menu {
    z-index: 1000 !important;
  }
  .alter-verb-div {
    margin-top: -1% !important;
  }
  .alternative-verb-label {
    font-size: 11pt;
  }
  .alter-verb-input {
    padding-bottom: 13px;
  }
  .container-verb {
    transform: translateY(-5%);
  }
  .verb-label {
    font-size: 11pt;
  }
  .btn-transparent {
    margin-top: -18%;
  }
  .textarea {
    z-index: 1 !important;
  }
}
</style>