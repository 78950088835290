<template>
  <fab
    :position="position"
    :bg-color="background_color"
    :actions="fab_actions"
    title="Navegar a"
    tooltip="Navegar a"
    @goToMatterProgram="goToMatterProgram"
    @goToPlanning="goToPlanning"
    @goToEvaluationTable="goToEvaluationTable"
    @goToNeedsGrading="goToNeedsGrading"
    @goToFullGrading="goToFullGrading"
    @goToAssessment="goToAssessment"
    @goToAssessmentReport="goToAssessmentReport"
    @goToAchievements="goToAchievements"
  ></fab>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "DashboardCustomFab",
  components: {
    fab: () => import("vue-fab"),
  },
  props: {
    section_id: {
      type: Number,
      required: true,
    },
    show_matter_program: {
      type: Boolean,
      default: true,
    },
    show_evaluation_table: {
      type: Boolean,
      default: true,
    },
    show_needs_grading: {
      type: Boolean,
      default: true,
    },
    show_full_grading: {
      type: Boolean,
      default: true,
    },
    show_assessment: {
      type: Boolean,
      default: true,
    },
    show_assessment_report: {
      type: Boolean,
      default: true,
    },
    show_achievements: {
      type: Boolean,
      default: true,
    },
    show_plannification: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      matter_id: null,
      egress_profile_id: null,
      background_color: "var(--header-color)",
      position: "bottom-right",
      fab_actions: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
    }),
  },
  methods: {
    goToMatterProgram() {
      this.$router.push({
        name: "MatterProgramView",
        params: { matter_id: this.matter_id },
      });
    },
    //Dashboard
    goToEvaluationTable() {
      this.$router.push({
        name: "EvaluationTableView",
        params: { matter_id: this.matter_id },
      });
    },
    goToNeedsGrading() {
      this.$router.push({
        name: "NeedsGradingView",
        params: { section_id: this.section_id },
      });
    },
    goToFullGrading() {
      this.$router.push({
        name: "FullGradingCenterView",
        params: { section_id: this.section_id },
      });
    },
    goToAssessment() {
      this.$router.push({
        name: "AssessmentView",
        params: { section_id: this.section_id },
      });
    },
    goToAssessmentReport() {
      this.$router.push({
        name: "AssessmentReportView",
        params: {
          section_id: this.section_id,
        },
      });
    },
    goToAchievements() {
      this.$router.push({
        name: "AchievementsPerCompetenceView",
      });
    },
    goToPlanning() {
      this.$router.push({
        name: "SessionPlanningView",
        params: {
          egress_profile_matter_id: this.matter_id,
          section_id: this.section_id,
        },
      });
    },
    //Mesh
    goToEgressProfile() {
      this.$router.push({
        name: "EgressProfileView",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToMatrix1() {
      this.$router.push({
        name: "Matrix1View",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToMatrix2() {
      this.$router.push({
        name: "Matrix2View",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToMatrix3() {
      this.$router.push({
        name: "Matrix3View",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToStudyPlan() {
      this.$router.push({
        name: "StudyPlanView",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToCurricularMesh() {
      this.$router.push({
        name: "CurricularMeshView",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_SECTION, this.section_id)
      .then((response) => {
        if (response) {
          this.matter_id = response.egress_profile_matter;
          this.fab_actions = [];
          if (this.show_matter_program)
            this.fab_actions.push({
              name: "goToMatterProgram",
              icon: "library_books",
              tooltip: this.$getVisibleNames(
                "manual.matter_program",
                false,
                "Programa de Asignatura"
              ),
              color: "var(--secondary-color)",
            });
          // institution.id != 12 Para ocultar la planificación de la institución Duoc
          if (
            this.show_plannification &&
            this.institution &&
            this.institution.id != 12
          ) {
            this.fab_actions.push({
              name: "goToPlanning",
              icon: "fact_check",
              tooltip: `"${this.$getVisibleNames(
                "teaching.mattersessionplanning",
                false,
                "Planificación de la Asignatura"
              )}"`,
              color: "var(--secondary-color)",
            });
          }
          if (this.show_evaluation_table)
            this.fab_actions.push({
              name: "goToEvaluationTable",
              icon: "list_alt",
              tooltip: "Evaluaciones",
              color: "var(--secondary-color)",
            });
          if (this.show_needs_grading && this.user && this.user.is_professor)
            this.fab_actions.push({
              name: "goToNeedsGrading",
              icon: "grading",
              tooltip: "Centro de Calificaciones",
              color: "var(--secondary-color)",
            });
          if (this.show_full_grading && this.user && this.user.is_professor)
            this.fab_actions.push({
              name: "goToFullGrading",
              icon: "fact_check",
              tooltip: "Notas",
              color: "var(--secondary-color)",
            });
          if (this.show_assessment)
            this.fab_actions.push({
              name: "goToAssessment",
              icon: "emoji_events",
              tooltip: "Assessment por Estudiante",
              color: "var(--secondary-color)",
            });
          if (
            this.show_assessment_report &&
            this.user &&
            this.user.is_professor
          )
            this.fab_actions.push({
              name: "goToAssessmentReport",
              icon: "assessment",
              tooltip: "Reporte de Assessment",
              color: "var(--secondary-color)",
            });
          if (this.show_achievements && this.user && this.user.is_student)
            this.fab_actions.push({
              name: "goToAchievements",
              icon: "star",
              tooltip: `Progresión de ${this.$getVisibleNames(
                "mesh.competence",
                true,
                "Competencias"
              )}`,
              color: "var(--secondary-color)",
            });
          // this.$store
          //   .dispatch(
          //     names.FETCH_EGRESS_PROFILE_MATTER,
          //     response.egress_profile_matter
          //   )
          //   .then((matter) => {
          //     this.egress_profile_id = matter.egress_profile;
          //     this.fab_actions.push({
          //       name: "goToEgressProfile",
          //       icon: "school",
          //       tooltip: String(
          //         this.$getVisibleNames(
          //           "mesh.egressprofile",
          //           false,
          //           "Perfil de Egreso"
          //         )
          //       ),
          //       color: "var(--secondary-color)",
          //     });
          //     this.fab_actions.push({
          //       name: "goToMatrix1",
          //       icon: "view_list",
          //       tooltip: String(
          //         "M1: Progresión de " +
          //           this.$getVisibleNames(
          //             "mesh.competence",
          //             true,
          //             "Competencias"
          //           )
          //       ),
          //       color: "var(--secondary-color)",
          //     });
          //     this.fab_actions.push({
          //       name: "goToMatrix2",
          //       icon: "view_module",
          //       tooltip: "M2: Progresión de Asignaturas",
          //       color: "var(--secondary-color)",
          //     });
          //     this.fab_actions.push({
          //       name: "goToMatrix3",
          //       icon: "view_quilt",
          //       tooltip: "M3: Tributación al Perfil de Egreso",
          //       color: "var(--secondary-color)",
          //     });
          //     this.fab_actions.push({
          //       name: "goToStudyPlan",
          //       icon: "public",
          //       tooltip: "Plan de Estudio",
          //       color: "var(--secondary-color)",
          //     });
          //     this.fab_actions.push({
          //       name: "goToCurricularMesh",
          //       icon: "dashboard",
          //       tooltip: "Malla Curricular",
          //       color: "var(--secondary-color)",
          //     });
          //   });
        }
      });
  },
};
</script>

<style scoped>
.fab {
  background-color: var(--header-color);
}
</style>