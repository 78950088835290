<template>
  <div>
    <div style="display: flex" class="mt-4">
      <div style="text-align: left"></div>
      <h4 class="w-100 text-center">
        Evaluación
        <strong>{{ evaluation ? evaluation.name : "" }}</strong>
      </h4>
    </div>
    <template v-if="evaluation">
      <EvaluationHeader
        :Evaluation="evaluation"
        :time_to_start="time_elapsed_to_start"
        :time_to_end="time_elapsed_to_end"
        :is_running="is_running"
        :evaluation_type="getEvaluationType"
        :evaluation_category="getEvaluationCategory"
      >
      </EvaluationHeader>
    </template>

    <b-button
      class="toggle-evaluation-details mt-2 py-2 noprint"
      size="sm"
      v-b-toggle.collapse-a.collapse-test
      variant="none"
    >
      <span v-if="!collapse_visible">
        <b-icon icon="plus"></b-icon> Más Información
      </span>
      <span v-else> <b-icon icon="dash"></b-icon> Ocultar </span>
    </b-button>
    <div class="outer-header-container">
      <div class="w-100">
        <div>
          <b-collapse v-model="collapse_visible" id="collapse-test" class="m-0">
            <div class="container-div">
              <b-form-group
                label="Nombre de Evaluación"
                label-cols="0"
                label-cols-md="3"
                class="m-0 p-0 ml-2 text-left"
              >
                <b-form-input
                  id="input-name"
                  v-model="$v.evaluation_form.name.$model"
                  :state="validateState('name') || true"
                  aria_describedby="Este campo es obligatorio"
                  @blur="$v.evaluation_form.$touch('name')"
                  placeholder="Escribir nombre de aquí..."
                  :disabled="!allows_crud"
                  @input="debouncePatchEvaluation"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Descripción de la Situación de Evaluación"
                label-cols="0"
                label-cols-md="3"
                class="mb-3 p-0 ml-2 pb-2 text-left"
              >
                <div
                  v-b-tooltip.v-secondary="
                    allows_crud ? 'Editar Descripción' : ''
                  "
                  class="evaluation-form-description mt-2 rich-text-content"
                  :class="{ 'cursor-pointer': allows_crud }"
                  v-html="$v.evaluation_form.description.$model"
                  @click="
                    allows_crud ? $bvModal.show(`edit-instrument-modal`) : ''
                  "
                ></div>
                <b-modal
                  :id="`edit-instrument-modal`"
                  title="Editar Descripción de la Situación de Evaluación"
                  size="lg"
                  hide-footer
                >
                  <RichTextEditor
                    :Object="evaluation_form"
                    :Text="evaluation_form.description"
                    @update="patchEvaluation"
                    @close="$bvModal.hide(`edit-instrument-modal`)"
                  ></RichTextEditor>
                </b-modal>
              </b-form-group>

              <b-tabs>
                <b-tab
                  v-for="program in professor_evaluation"
                  :key="program.career[0].id"
                  :title="program.career[0].name"
                >
                  <div
                    class="inner-header my-2"
                    v-for="matter in program.matters"
                    :key="matter.id"
                  >
                    <b-button
                      variant="none"
                      class="professor-evaluation-matters"
                      v-b-toggle="String(matter.id)"
                    >
                      <div class="d-flex">
                        <span class="mr-2">{{
                          program.matters.length > 1
                            ? $getVisibleNames(
                                "mesh.egressprofilematter",
                                true,
                                "Asignaturas"
                              ) + ": "
                            : $getVisibleNames(
                                "mesh.egressprofilematter",
                                false,
                                "Asignatura"
                              ) + ": "
                        }}</span>
                        <div>
                          {{ matter.name }} - Nivel: {{ matter.plan_level }}
                        </div>
                      </div>
                    </b-button>
                    <b-collapse :id="String(matter.id)" visible>
                      <div class="d-flex inner-info-div">
                        <span class="mr-2">{{
                          matter.sections.length > 1
                            ? $getVisibleNames(
                                "teaching.section",
                                true,
                                "Secciones"
                              ) + ": "
                            : $getVisibleNames(
                                "teaching.section",
                                false,
                                "Sección"
                              ) + ": "
                        }}</span>
                        <div
                          v-for="section in matter.sections"
                          :key="section.id"
                          class="section-block"
                        >
                          {{ section.name }} - {{ section.campus__name }}
                          <b-icon
                            :id="`popover-matter-past-section-${section.id}`"
                            class="ml-1 card-header-icon"
                            icon="three-dots"
                          ></b-icon>
                          <b-popover
                            :target="`popover-matter-past-section-${section.id}`"
                            triggers="hover"
                          >
                            <b-button
                              variant="none"
                              @click="
                                redirectToMatterProgram(
                                  section.egress_profile_matter
                                )
                              "
                              class="profiles mb-1 text-white"
                            >
                              {{
                                $getVisibleNames(
                                  "manual.matter_program",
                                  false,
                                  "Programa de la Asignatura"
                                )
                              }}
                            </b-button>
                            <!-- institution.id != 12 Para ocultar la planificación de la institución Duoc -->
                            <b-button
                              v-if="institution && institution.id != 12"
                              variant="none"
                              @click="
                                redirectToSessionPlanning(
                                  section.egress_profile_matter,
                                  section.id
                                )
                              "
                              class="profiles mb-1 text-white"
                            >
                              {{
                                $getVisibleNames(
                                  "teaching.mattersessionplanning",
                                  false,
                                  "Planificación de la Asignatura"
                                )
                              }}</b-button
                            >
                            <b-button
                              v-if="user.is_professor"
                              variant="none"
                              @click="
                                redirectToEvaluationTable(
                                  section.egress_profile_matter
                                )
                              "
                              class="profiles mb-1 text-white"
                            >
                              Evaluaciones</b-button
                            >
                            <b-button
                              v-if="user.is_professor"
                              variant="none"
                              @click="redirectToNeedsGrading(section.id)"
                              class="profiles mb-1 text-white"
                            >
                              Centro de Calificaciones</b-button
                            >
                            <b-button
                              v-if="user.is_professor"
                              variant="none"
                              @click="redirectToFullGrading(section.id)"
                              class="profiles mb-1 text-white"
                            >
                              Notas</b-button
                            >
                            <b-button
                              variant="none"
                              @click="redirectToAssessment(section.id)"
                              class="profiles mb-1 text-white"
                            >
                              Assessment por Estudiante</b-button
                            >
                            <b-button
                              variant="none"
                              v-if="user.is_professor"
                              @click="redirectToAssessmentReport(section.id)"
                              class="profiles mb-1 text-white"
                            >
                              Reporte de Assessment</b-button
                            >
                            <b-button
                              variant="none"
                              @click="redirectToAchievementsPerCompetence()"
                              class="profiles mb-1 text-white"
                              v-if="user && user.is_student"
                            >
                              Progresión de
                              {{
                                $getVisibleNames(
                                  "mesh.competence",
                                  true,
                                  "Competencias"
                                )
                              }}</b-button
                            >
                          </b-popover>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </b-collapse>
        </div>
      </div>
      <div style="min-width: 35px" class="mt-2"></div>
    </div>
    <div class="mt-2"></div>
    <template v-if="evaluation">
      <EvaluationManager
        :evaluation_id="evaluation.id"
        :allows_crud="allows_crud"
        :is_running="is_running"
        :question_test_id="question_test_id"
        :observation_instrument_id="observation_instrument_id"
        :can_answer="can_answer"
        :can_edit_evaluation="canEditEvaluation"
      >
        <template #editevaluation>
          <b-button
            v-if="allows_crud"
            class="btn-edit-evaluation mb-2 mr-2"
            variant="primary"
            v-b-modal.edit-evaluation-modal
            size="sm"
          >
            Editar Evaluación
          </b-button></template
        ></EvaluationManager
      >
    </template>
    <div>
      <b-modal
        id="edit-evaluation-modal"
        title="Editar Evaluación"
        size="lg"
        hide-footer
      >
        <!-- :egressProfileMatte="egressProfileMatte" -->
        <EvaluationTableForm
          :Evaluation="evaluation"
          :SectionEvaluations="sectionEvaluationList"
          @updated="UpdateEvaluation"
        ></EvaluationTableForm>
        <!-- :SectionEvaluations="evaluation.sections" -->
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: "EvaluationView",
  mixins: [validationMixin],
  components: {
    EvaluationHeader: () => import("./EvaluationHeader"),
    EvaluationManager: () => import("./EvaluationManager"),
    EvaluationTableForm: () =>
      import("@/components/teaching/Evaluation/EvaluationTableForm"),
    RichTextEditor: () => import("@/components/reusable/RichTextEditor"),
  },
  data() {
    return {
      evaluation_id: Number(this.$route.params.evaluation_id),
      question_test_id: this.$route.params.question_test_id
        ? Number(this.$route.params.question_test_id)
        : null,
      observation_instrument_id: this.$route.params.observation_instrument_id
        ? Number(this.$route.params.observation_instrument_id)
        : null,
      evaluation_form: {
        name: "",
        description: "",
      },
      collapse_visible: true,
      collapse_professor_evaluation_info: false,
      is_running: false,
      can_edit_evaluation: false,
      time_to_start: null,
      time_to_end: null,
      time_elapsed_to_start: null,
      time_elapsed_to_end: null,
      can_answer: false,
      evaluation_types: [],
      evaluation_categories: [],
      professor_evaluation: null,
    };
  },
  validations: {
    evaluation_form: {
      name: { required },
      description: {},
    },
  },
  computed: {
    ...mapGetters({
      evaluations: names.EVALUATIONS,
      evaluationInstruments: names.EVALUATION_INSTRUMENTS,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      rubrics: names.OBSERVATION_INSTRUMENTS,
      sections: names.SECTIONS,
      sectionEvaluations: names.SECTION_EVALUATIONS,
      user: "getUser",
      institution: "getInstitution",
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("teaching.change_evaluation");
      if (has_permission) return has_permission;
      else return false;
    },
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    sectionEvaluationList() {
      if (!this.evaluation) return;
      return this.sectionEvaluations.filter(
        (x) =>
          this.evaluation.sections.includes(x.section) &&
          this.evaluation.id == x.evaluation
      );
    },
    getEvaluationType() {
      if (!this.evaluation_types || !this.evaluation) return;
      const type_name = this.evaluation_types.find(
        (x) => x.id == this.evaluation.type
      );
      return type_name ? type_name.value : "";
    },
    getEvaluationCategory() {
      if (!this.evaluation_categories || !this.evaluation) return;
      const category_name = this.evaluation_categories.find(
        (x) => x.id == this.evaluation.category
      );
      return category_name ? category_name.value : "";
    },
    selectedEvaluationInstruments() {
      let list = [];
      if (!this.evaluation) return [];
      this.evaluation.evaluation_instruments.forEach((element) => {
        let instance = this.evaluationInstruments.find((x) => x.id == element);
        if (instance) list.push(instance);
      });
      return list;
    },
    rubricList() {
      return this.rubrics.filter((x) => x.evaluation == this.evaluation_id);
    },
    canEditEvaluation() {
      let flag = false;
      if (!this.evaluation) return flag;
      if (
        this.user.is_professor &&
        (this.evaluation.editors.includes(this.user.id) ||
          this.evaluation.created_by == this.user.id)
      ) {
        flag = true;
      }
      return flag;
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.evaluation_form[key];
      return $dirty ? !$error : null;
    },
    redirectToMatterProgram(id) {
      // let route_data = this.$router.resolve({
      this.$router.push({
        name: "MatterProgramView",
        params: {
          matter_id: id,
        },
      });
      // window.open(route_data.href, "_blank");
    },
    redirectToNeedsGrading(id) {
      // let route_data = this.$router.resolve({
      this.$router.push({
        name: "NeedsGradingView",
        params: {
          section_id: id,
        },
      });
      // window.open(route_data.href, "_blank");
    },
    redirectToFullGrading(id) {
      this.$router.push({
        name: "FullGradingCenterView",
        params: {
          section_id: id,
        },
      });
    },
    redirectToAssessment(id) {
      this.$router.push({
        name: "AssessmentView",
        params: {
          section_id: id,
        },
      });
    },
    redirectToAssessmentReport(id) {
      this.$router.push({
        name: "AssessmentReportView",
        params: {
          section_id: id,
        },
      });
    },
    redirectToSessionPlanning(ep_matter_id, section_id) {
      this.$router.push({
        name: "SessionPlanningView",
        params: {
          egress_profile_matter_id: ep_matter_id,
          section_id: section_id,
        },
      });
    },
    redirectToAchievementsPerCompetence() {
      this.$router.push({
        name: "AchievementsPerCompetenceView",
      });
    },
    redirectToEvaluationTable(id) {
      // let route_data = this.$router.resolve({
      this.$router.push({
        name: "EvaluationTableView",
        params: {
          matter_id: id,
        },
      });
      // window.open(route_data.href, "_blank");
    },
    debouncePatchEvaluation() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.patchEvaluation();
      }, 1000);
    },
    patchEvaluation(evaluation_form, text) {
      let payload = {
        evaluation_id: this.evaluation.id,
        item: {
          name: evaluation_form.name,
          description: text,
        },
      };
      evaluation_form.name, (evaluation_form.description = text);
      this.$store.dispatch(names.PATCH_EVALUATION, payload).then((response) => {
        if (response) toast("Evaluación actualizada.");
      });
    },
    fetchProfessorEvaluations() {
      this.$restful
        .Get(
          `/teaching/get_professor_evaluation_info/?evaluation=${this.evaluation_id}`
        )
        .then((response) => {
          this.professor_evaluation = response;
        });
    },
    fetchEvaluationTypes() {
      this.$restful.Get("/teaching/evaluation-types/").then((response) => {
        this.evaluation_types = response;
      });
    },
    fetchEvaluationCategories() {
      this.$restful.Get("/teaching/evaluation-categories/").then((response) => {
        this.evaluation_categories = response;
      });
    },
    UpdateEvaluation(evaluation, section_evaluation, SectionEvaluations) {
      if (!isNaN(evaluation.id)) {
        this.$store.dispatch(names.UPDATE_EVALUATION, evaluation).then(() => {
          section_evaluation.forEach((x) => {
            const index = SectionEvaluations.findIndex(
              (section) => section.section == x
            );
            if (index == -1) {
              const payload = {
                section: x,
                evaluation: evaluation.id,
                evaluation_criterias_micro: [],
                evaluation_criterias_macro: [],
                weighing: evaluation.weighing,
                show_in_dashboard: false,
                show_to_students: false,
                show_stats: false,
              };
              this.$store.dispatch(names.POST_SECTION_EVALUATION, payload);
            }
          });
          SectionEvaluations.forEach((section) => {
            const index = section_evaluation.findIndex(
              (x) => x == section.section
            );
            if (index == -1) {
              this.$store.dispatch(names.DELETE_SECTION_EVALUATION, section.id);
            }
          });
          this.$bvModal.hide("edit-evaluation-modal");
        });
      }
    },
    checkTimes() {
      this.can_edit_evaluation = this.canEditEvaluation;
      if (this.time_to_start || this.time_to_end) return;
      clearTimeout();
      this.time_to_start = null;
      this.time_to_end = null;
      this.time_elapsed_to_start = null;
      this.time_elapsed_to_end = null;
      if (this.section && this.evaluation && !this.evaluation.finished) {
        let current_date = moment();
        if (!this.evaluation.start_date) {
          return;
        }
        let start_date = moment(this.evaluation.start_date);
        if (start_date > current_date) {
          this.can_edit_evaluation = this.canEditEvaluation;
          if (start_date.diff(current_date) < 24 * 60 * 60 * 1000)
            this.time_to_start = start_date.diff(current_date);
        } else {
          this.is_running = true;
          this.can_edit_evaluation = false;
        }
        if (!this.evaluation.end_date) {
          return;
        }
        let end_date = moment(this.evaluation.end_date);
        if (end_date > current_date && this.is_running) {
          this.time_to_end = end_date - current_date;
        } else {
          this.is_running = false;
        }
      } else {
        this.can_edit_evaluation = false;
      }
    },
    checkPermissions() {
      this.can_answer = false;
      if (this.is_running) {
        this.section.students.forEach((student) => {
          if (student == this.user.id) {
            this.can_answer = true;
            return;
          }
        });
      }
    },
  },
  watch: {
    section() {
      this.checkTimes();
      this.checkPermissions();
    },
    time_to_start() {
      if (this.time_to_start && this.time_to_start > 0) {
        this.time_elapsed_to_start = this.time_to_start;
        let interval = 1000;
        if (this.time_elapsed_to_start > 60 * 60 * 1000)
          interval = 60 * 60 * 1000;
        clearTimeout();
        setInterval(() => {
          this.time_elapsed_to_start -= interval;
          if (this.time_elapsed_to_start < 0) {
            clearInterval();
            this.time_elapsed_to_start = null;
            this.checkTimes();
            this.checkPermissions();
          }
        }, interval);
      } else {
        this.time_to_start = null;
      }
    },
    time_to_end() {
      if (this.time_to_end && this.time_to_end > 0) {
        this.time_elapsed_to_end = this.time_to_end;
        clearTimeout();
        setInterval(() => {
          this.time_elapsed_to_end -= 1000;
          if (this.time_elapsed_to_end < 0) {
            clearInterval();
            this.time_elapsed_to_end = null;
            this.is_running = false;
          }
        }, 1000);
      }
    },
  },
  created() {
    this.fetchProfessorEvaluations();
    this.fetchEvaluationTypes();
    this.fetchEvaluationCategories();
    this.$store.dispatch(names.FETCH_AUTOMATED_SCORE_TYPES);
    this.$store.dispatch(names.FETCH_EVALUATION_INSTRUMENTS);
    this.$store
      .dispatch(names.FETCH_EVALUATION, this.evaluation_id)
      .then((response) => {
        if (
          this.user.is_professor &&
          (response.editors.includes(this.user.id) ||
            response.created_by == this.user.id)
        ) {
          this.can_edit_evaluation = true;
        }
        this.evaluation_form.name = response.name;
        this.evaluation_form.description = response.description;
        this.$store.dispatch(names.FETCH_OBSERVATION_INSTRUMENTS, response.id);
        response.sections.forEach((section_id) => {
          this.$store
            .dispatch(names.FETCH_SECTION, section_id)
            .then((section) => {
              if (section) {
                this.$store
                  .dispatch(
                    names.FETCH_EGRESS_PROFILE_MATTER,
                    section.egress_profile_matter
                  )
                  .then((egress_profile_matter) => {
                    this.$store.dispatch(
                      names.FETCH_STUDY_UNITS,
                      egress_profile_matter.matter
                    );
                    // this.$store.dispatch(
                    //   names.FETCH_MATTER,
                    //   egress_profile_matter.matter
                    // );
                  });
              }
            });
        });
      });
    this.$store
      .dispatch(names.FETCH_SECTION_EVALUATIONS, this.evaluation_id)
      .then((response) => {
        response.forEach((section_evaluation) => {
          section_evaluation.evaluation_criterias_micro.forEach(
            (ra_micro_id) => {
              this.$store.dispatch(
                names.FETCH_EVALUATION_CRITERIA,
                ra_micro_id
              );
            }
          );
          section_evaluation.evaluation_criterias_macro.forEach(
            (ra_macro_id) => {
              this.$store.dispatch(
                names.FETCH_EVALUATION_CRITERIA_MACRO,
                ra_macro_id
              );
            }
          );
        });
      });
  },
  mounted() {
    // if (this.studyUnit)
    //   this.$store.dispatch(names.FETCH_CONTENTS, this.studyUnit.id);
    if (this.user && this.user.is_student) {
      this.collapse_visible = false;
    }
    this.checkTimes();
    this.checkPermissions();
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.outer-header-container {
  padding: 0 0.5em;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.container-div {
  margin-inline: 0.5em;
}
.header-div {
  background-color: white;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
label {
  width: 25%;
}
select {
  margin-top: 4px;
  width: 84% !important;
}
.list-group {
  margin: 0;
}
.list-group-item {
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
}
.toggle-evaluation-details {
  width: 100%;
  background-color: var(--primary-color);
  border: var(--primary-color);
}
.toggle-evaluation-details:focus {
  border: var(--primary-color) !important;
}
.evaluation-info-container {
  padding-top: 0.6em;
}
.btn.btn-primary:focus {
  border: var(--primary-color) !important;
}
.evaluation-form-description {
  padding: 0.5em;
  border: 1px solid var(--secondary-color);
  min-height: 38px;
  border-radius: 4px;
  resize: vertical;
  overflow: auto;
}
.outer-header-container >>> legend {
  padding-top: 1em !important;
}
.inner-info-div {
  /* background: var(--primary-color); */
  padding: 0.6em 2em;
  border: 1px solid #72a9ba;
}
.inner-info-div:first-of-type {
  border-top: none;
}
.inner-info-div:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.section-block {
  background: var(--secondary-color);
  margin-right: 0.5em;
  padding: 0 1em;
  border-radius: 2px;
  color: #fff;
}
.professor-evaluation-matters {
  background: var(--primary-color);
  width: 100%;
  padding: 0.6em 2em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-edit-evaluation {
  width: max-content;
}
.profiles {
  background-color: var(--secondary-color);
  border-radius: 4px;
  padding: 0 2em;
  width: 100%;
  font-size: 11pt;
  text-align: center;
}
</style>