import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    careers: [],
    careers_url: '/mesh/career'
};

const getters = {
    [names.CAREERS]: state => {
        if (!get.store.state.user) return [];
        return state.careers.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
};

const mutations = {
    [names.MUTATE_CAREERS]: (state, careers) => {
        careers.forEach(element => {
            let index = state.careers.findIndex(x => x.id == element.id);
            if (index != -1) state.careers.splice(index, 1, element);
            else state.careers.push(element);
        });
    },
    [names.MUTATE_CAREER]: (state, career) => {
        let index = state.careers.findIndex(x => x.id == career.id);
        if (index != -1) state.careers.splice(index, 1, career);
        else state.careers.push(career);
    },
    [names.MUTATE_DELETE_CAREER]: (state, career_id) => {
        let index = state.careers.findIndex(x => x.id == career_id);
        if (index != -1) state.careers.splice(index, 1);
    },
};

const actions = {
    [names.FETCH_CAREER]: ({
        commit,
        state
    }, career_id) => {
        return new Promise((resolve) => {
            const career = state.careers.find(x => x.id == career_id);
            if (career) {
                resolve(career);
                return;
            }
            restful.Get(`${state.careers_url}/${career_id}/`)
                .then(response => {
                    commit(names.MUTATE_CAREER, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_CAREERS]: ({
        commit,
        state,
    }, faculty_id) => {
        const url_filter = faculty_id ? `?faculty=${faculty_id}` : '';
        return new Promise((resolve) => {
            restful.Get(`${state.careers_url}/${url_filter}`)
                .then(response => {
                    commit(names.MUTATE_CAREERS, response);
                    resolve(response);
                });
        });
    },
    // [names.FETCH_CAREER_BY_EGRESS_PROFILE]: ({
    //     commit,
    //     state,
    // }, egress_profile_id) => {
    //     const url_filter = egress_profile_id ? `?egress_profile=${egress_profile_id}` : '';
    //     return new Promise((resolve) => {
    //         restful.Get(`${state.careers_url}/${url_filter}`)
    //             .then(response => {
    //                 commit(names.MUTATE_CAREERS, response);
    //                 resolve(response);
    //             });
    //     });
    // },
    [names.POST_CAREER]: ({
        commit,
        state
    }, career) => {
        return new Promise((resolve) => {
            restful.Post(`${state.careers_url}/`, career)
                .then(response => {
                    commit(names.MUTATE_CAREER, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_CAREER]: ({
        commit,
        state
    }, career) => {
        return new Promise((resolve) => {
            restful.Put(`${state.careers_url}/${career.id}/`, career)
                .then(response => {
                    commit(names.MUTATE_CAREER, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_CAREER]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.careers_url}/${payload.career_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_CAREER, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_CAREER]: ({
        commit,
        state
    }, career_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.careers_url}/${career_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_CAREER, career_id);
                    resolve(response);
                });
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};