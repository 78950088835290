var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"mt-4 mb-3"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso"))+" "),(
        (_vm.isClosedEgressProfile && _vm.allows_crud) ||
        (_vm.isClosedEgressProfile && _vm.egressProfile && _vm.egressProfile.is_closed)
      )?[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
          `Cambiar el estado de la ${_vm.$getVisibleNames(
            'mesh.egressprofile',
            false,
            'Perfil de Egreso'
          )}`
        ),expression:"\n          `Cambiar el estado de la ${$getVisibleNames(\n            'mesh.egressprofile',\n            false,\n            'Perfil de Egreso'\n          )}`\n        ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"noprint",attrs:{"size":"sm","variant":"none"},on:{"click":function($event){return _vm.changeStateEgressProfile()}}},[(_vm.egressProfile && _vm.egressProfile.is_closed)?[_c('b-icon',{staticClass:"btn-actions",attrs:{"icon":"lock","scale":"1"}})]:[_c('b-icon',{staticClass:"btn-actions",attrs:{"icon":"unlock","scale":"1"}})]],2)]:_vm._e(),(
        (_vm.institution && _vm.institution.id == 12) ||
        (_vm.institution && _vm.institution.id == 7) ||
        _vm.$debug_change_duoc ||
        _vm.$debug_change_unab
      )?[_c('div',{staticClass:"float-right noprint"},[_c('b-button',{staticClass:"mb-2 ml-3 noprint",attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){return _vm.DownloadDocument()}}},[_vm._v(" Formato de salida de "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofile", false, "Perfil de Egreso" ).toLowerCase())+" ")])],1)]:_vm._e()],2),_c('EgressProfile',{attrs:{"egress_profile_id":_vm.egress_profile_id,"allows_crud":_vm.allows_crud}}),(_vm.egress_profile_id)?_c('MeshCustomFab',{staticClass:"noprint",attrs:{"egress_profile_id":_vm.egress_profile_id,"show_egress_profile":false}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }