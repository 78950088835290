<template>
  <div v-if="oldMatter">
    <ul>
      <li
        v-for="strategy in methodologicalStrategyList"
        :key="`strategy-${strategy.id}`"
        :title="strategy.description"
      >
        {{ strategy.name }}
      </li>
    </ul>
    <div
      v-can="'teaching.change_matter'"
      v-if="allows_crud && !isNaN(ProfileMatter.id)"
    >
      <!-- class="button-div" -->
      <button-edit
        @click="
          $bvModal.show(`modal-methodological-strategy-${ProfileMatter.id}`)
        "
        v-b-tooltip.v-secondary.noninteractive="
          `Editar ${$getVisibleNames(
            'mesh.methodologicalstrategy',
            true,
            'Estrategias Metodológicas'
          )}`
        "
      ></button-edit>
      <b-modal
        :title="`Seleccionar ${$getVisibleNames(
          'mesh.methodologicalstrategy',
          true,
          'Estrategias Metodológicas'
        )}`"
        :id="`modal-methodological-strategy-${ProfileMatter.id}`"
        @hidden="closeMethodologicalStrategy(ProfileMatter.id)"
        size="lg"
      >
        <template v-slot:modal-header>
          <h5 class="custom-modal-title">
            Selecionar
            {{
              $getVisibleNames(
                "mesh.methodologicalstrategy",
                true,
                "Estrategias Metodológicas"
              )
            }}
          </h5>
          <InfoTooltip
            class="selection-info ml-0"
            tooltip_text="Para utilizar la selección múltiple, mantenga presionada la tecla Ctrl y a continuación, seleccione los elementos que desee agregar."
          >
          </InfoTooltip>
        </template>
        <b-input-group class="mb-1">
          <b-form-input
            v-model="input_search"
            type="search"
            size="sm"
            :placeholder="`Buscar ${$getVisibleNames(
              'mesh.methodologicalstrategy',
              true,
              'Estrategias Metodológicas'
            )}...`"
          >
          </b-form-input>
          <b-input-group-append>
            <b-button
              :disabled="!input_search"
              @click="input_search = ''"
              size="sm"
              >Limpiar</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <!-- v-model="methodological_strategies" -->
        <b-form-select
          :options="searchMethodologicalStrategies"
          text-field="name"
          value-field="id"
          v-model="methodological_strategies"
          size="sm"
          :select-size="methodologicalStrategies.length"
          multiple
        ></b-form-select>
        <template v-slot:modal-footer>
          <b-button
            class="btn-cancel"
            size="sm"
            @click="closeMethodologicalStrategy(ProfileMatter.id)"
            >Cancelar</b-button
          >
          <b-button
            size="sm"
            @click="updateMethodologicalStrategy(ProfileMatter.id)"
            >Guardar</b-button
          >
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "MethodologicalStrategiesCell",
  components: {
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    ProfileMatter: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      methodological_strategies: [],
      input_search: "",
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      methodologicalStrategies: names.METHODOLOGICAL_STRATEGIES,
    }),
    oldMatter() {
      if (!this.ProfileMatter) return null;
      return this.matters.find((x) => x.id == this.ProfileMatter.matter);
    },
    methodologicalStrategySort() {
      const list = this.methodologicalStrategies;
      return list.sort(function (a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    },
    methodologicalStrategyList() {
      let list = [];
      this.oldMatter.methodological_strategies.forEach((element) => {
        let instance = this.methodologicalStrategies.find(
          (x) => x.id == element
        );
        if (instance) list.push(instance);
      });
      return list;
    },
    searchMethodologicalStrategies: function () {
      return this.methodologicalStrategySort
        .filter((item) => {
          return (
            this.$filtered(item.name, this.input_search) ||
            this.methodological_strategies.includes(item.id)
          );
        })
        .sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
  },
  methods: {
    updateMethodologicalStrategy(profile_matter_id) {
      this.$store
        .dispatch(names.PATCH_MATTER, {
          matter_id: this.oldMatter.id,
          item: {
            methodological_strategies: this.methodological_strategies,
          },
        })
        .then((response) => {
          if (response)
            this.$bvModal.hide(
              `modal-methodological-strategy-${profile_matter_id}`
            );
        });
    },
    closeMethodologicalStrategy(profile_matter_id) {
      this.methodological_strategies = this.oldMatter.methodological_strategies;
      this.$bvModal.hide(`modal-methodological-strategy-${profile_matter_id}`);
    },
  },
  created() {
    if (this.oldMatter) {
      this.oldMatter.methodological_strategies.forEach((element) => {
        if (
          this.methodologicalStrategies.findIndex((x) => x.id == element) == -1
        )
          this.$store.dispatch(names.FETCH_METHODOLOGICAL_STRATEGY, element);
      });
    }
  },
  mounted() {
    if (this.oldMatter) {
      this.methodological_strategies = this.oldMatter.methodological_strategies;
      this.oldMatter.methodological_strategies.forEach((element) => {
        if (
          this.methodologicalStrategies.findIndex((x) => x.id == element) == -1
        )
          this.$store.dispatch(names.FETCH_METHODOLOGICAL_STRATEGY, element);
      });
    }
  },
};
</script>

<style scoped>
p,
li {
  font-size: var(--thirth-font-size);
}
ul {
  padding-left: 5px;
  margin-left: 10px;
  text-align: justify;
}
.button-div {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}
.custom-modal-title {
  display: block !important;
  width: 60%;
}
.selection-info {
  transform: translate(-86%, -20%);
  width: 40%;
  /* margin-right: auto;
  margin-left: 0 !important; */
}
.btn-cancel {
  margin-right: auto;
  margin-left: 0.3em;
}
@media (max-width: 991px) {
  .custom-modal-title {
    width: 80%;
  }
  .selection-info {
    transform: translate(-76%, -20%);
    width: 20%;
  }
}
</style>