<template>
  <tr>
    <template v-if="isFirtsMatterOfLevel">
      <td class="td-plan-level" :rowspan="cycleMattersAmount">
        {{ matter.plan_level }}
      </td>
    </template>
    <!-- <template v-if="getFirtsMatterPerFormationArea">
      <td :rowspan="getMaxFormationAreaPerNivel()">
        <div class="wrap-text">
          {{ getFormationArea(matter.formation_area) }}
        </div>
      </td>
    </template> -->
    <!-- <td v-if="false">{{ getCycles(matter.cycle) }}</td> -->
    <td v-if="false">{{ matter.plan_level }}</td>
    <td v-if="false">{{ getFormationArea(matter.formation_area) }}</td>
    <!-- <td>{{ getMatterName(matter.matter) }}</td> -->
    <td
      class="td-matter"
      v-b-tooltip.top.v-secondary.noninteractive="
        `${$getVisibleNames(
          'mesh.formationarea',
          false,
          'Área De Formación'
        )}: ${getFormationArea(matter.formation_area)}`
      "
    >
      <b-link
        :to="{
          name: 'MatterProgramView',
          params: { matter_id: matter.id },
        }"
      >
        {{ getMatterName(matter.matter).toUpperCase() }}
      </b-link>
    </td>
    <template v-for="competence in competence">
      <td
        v-if="getCheckCompetence(matter, competence.id)"
        :key="matter.id + 'c' + competence.id"
        :id="matter.id + 'c' + competence.id"
        class="check-competence"
      >
        <b-popover
          :target="matter.id + 'c' + competence.id"
          placement="left"
          triggers="hover focus"
        >
          <template #title
            >{{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}:
            {{ getCycles(competence.cycle) }}</template
          >
          <div class="competence-div">
            <!-- {{ competence.order + "." }} -->
            {{
              competence.profile_cycle
                ? competence.order + "." + competence.profile_cycle.order
                : competence.order + "."
            }}
            <SentenceContainer :Sentence="competence"></SentenceContainer>
          </div>
        </b-popover>
        <b-icon icon="check" variant="success" width="25" height="25"></b-icon>
      </td>
      <td v-else :key="matter.id + 'c' + competence.id"></td>
    </template>
  </tr>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "Matrix3Header",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    matter: {
      type: Object,
      required: true,
    },
    matters: {
      type: Array,
      required: true,
    },
    formationAreas: {
      type: Array,
    },
    cycles: {
      type: Array,
    },
    competence: {
      type: Array,
    },
    egress_profile_id: {
      type: Number,
      required: true,
    },
    // genericCompetence: {
    //   type: Array,
    // },
  },
  computed: {
    ...mapGetters({
      matters_list: names.MATTERS,
    }),
    cycleMattersAmount() {
      return this.matters.filter(
        (x) =>
          x.plan_level == this.matter.plan_level && x.cycle == this.matter.cycle
      ).length;
    },
    isFirtsMatterOfLevel() {
      if (!this.matter) return false;
      const matter = this.matters
        .filter(
          (x) =>
            x.egress_profile == this.egress_profile_id &&
            x.plan_level == this.matter.plan_level
        )
        .shift();
      if (this.matter.id == matter.id) return true;
      return false;
    },
    getFirtsMatterPerFormationArea() {
      if (!this.matter) return false;
      const matter = this.matters
        .filter(
          (x) =>
            x.formation_area == this.matter.formation_area &&
            x.cycle == this.matter.cycle &&
            x.egress_profile == this.egress_profile_id &&
            x.plan_level == this.matter.plan_level
        )
        .shift();
      if (this.matter.id === matter.id) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getMatterName(matter_id) {
      if (matter_id) {
        const matter = this.matters_list.find((x) => x.id == matter_id);
        return matter ? matter.name : "";
      } else return "";
    },
    getCheckCompetence(item, index) {
      const matter = item.competences.filter((x) => x == index).shift();
      if (index === matter) {
        return true;
      }
      return false;
    },
    getFormationArea(area_id) {
      let name = "N/A";
      this.formationAreas.forEach((element) => {
        if (element.id == area_id) {
          name = element.name;
        }
      });
      return name;
    },
    getCycles(cycle) {
      let name = "N/A";
      this.cycles.forEach((element) => {
        if (element.id == cycle) {
          name = element.name;
        }
      });
      return name;
    },
    getMaxFormationAreaPerNivel() {
      let max = 0;
      this.matters.forEach((element) => {
        if (
          element.formation_area == this.matter.formation_area &&
          element.plan_level == this.matter.plan_level &&
          element.cycle == this.matter.cycle
        ) {
          max += 1;
        }
      });
      return max;
    },
  },
};
</script>

<style scoped>
td {
  vertical-align: middle;
  border: 1px solid black;
  border-width: thin;
  text-align: center;
  height: 60px;
  min-width: 45px;
  font-size: 12px;
  padding: 10px 2px;
}
.check-competence {
  background-color: rgb(235, 235, 235);
  cursor: help;
}
.td-plan-level {
  width: 10.05% !important;
}
.td-matter {
  width: 29.95%;
}
.competence-div {
  display: flex;
}
</style>