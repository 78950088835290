<template>
  <div>
    <DescriptorsContainer
      :ref_component="'Competence'"
      :Title_text="'Programas'"
      :Title="competenceDescriptor"
      :Descriptors="cMiddleDescriptors"
      :allows_crud="allows_crud"
      :Order="1"
      :VisibleOrder="false"
      :ProgramView="true"
      :rich_text_editor="false"
      :show_is_default="false"
      :show_visible="false"
      @updateOrder="updateOrderCMiddleDescriptors"
      @createDescription="createCMiddleDescriptors"
      @updateDescription="updateCMiddleDescriptors"
      @createTitle="createCompetenceDescriptors"
      @updateTitle="updateCompetenceDescriptors"
      @deleteDescriptors="deleteCompetenceDescriptors"
      @deleteTitle="deleteTitle"
    >
      <!-- @updateState="updateVisibleCMiddleDescriptors" -->
    </DescriptorsContainer>
  </div>
</template>

<script>
export default {
  name: "CompetenceDescriptors",
  components: {
    DescriptorsContainer: () =>
      import("@/components/reusable/DescriptorsContainer"),
  },
  props: {
    competence_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      competenceDescriptor: [],
      cMiddleDescriptors: [],
    };
  },
  computed: {},
  methods: {
    mutateCMDescriptor(item) {
      let index = this.cMiddleDescriptors.findIndex((x) => x.id == item.id);
      if (index != -1) this.cMiddleDescriptors.splice(index, 1, item);
      else this.cMiddleDescriptors.push(item);
    },
    mutateDeleteCMDescriptor(cMDescriptor_id) {
      let index = this.cMiddleDescriptors.findIndex(
        (x) => x.id == cMDescriptor_id
      );
      if (index != -1) this.cMiddleDescriptors.splice(index, 1);
    },
    mutateCompetenceDescriptor(item) {
      let index = this.competenceDescriptor.findIndex((x) => x.id == item.id);
      if (index != -1) this.competenceDescriptor.splice(index, 1, item);
      else this.competenceDescriptor.push(item);
    },
    mutateDeleteCompetenceDescriptor(competenceDescriptor_id) {
      let index = this.competenceDescriptor.findIndex(
        (x) => x.id == competenceDescriptor_id
      );
      if (index != -1) this.competenceDescriptor.splice(index, 1);
    },
    updateOrderCMiddleDescriptors(item) {
      let order = 1;
      item.forEach((element) => {
        if (element.order != order) {
          element.order = order;
          if (
            !isNaN(element.id) &&
            this.cMiddleDescriptors.findIndex((x) => x.id == element.id) != -1
          ) {
            let payload = {
              id: element.id,
              item: {
                order: element.order,
              },
            };
            this.$restful
              .Patch(
                `/mesh/competence-middle-descriptor/${payload.id}/`,
                payload.item
              )
              .then((response) => {
                this.mutateCMDescriptor(response);
              });
          }
        }
        order += 1;
      });
    },
    // updateVisibleCMiddleDescriptors() {},
    createCMiddleDescriptors(text, object) {
      if (!isNaN(object.id) && this.competence_id) {
        let item = {
          competence: this.competence_id,
          descriptor: object.id,
          description: text,
          order: 1 + this.cMiddleDescriptors.length,
        };
        this.$restful
          .Post(`/mesh/competence-middle-descriptor/`, item)
          .then((response) => {
            this.mutateCMDescriptor(response);
            this.$emit("reset_competence");
          });
      }
    },
    updateCMiddleDescriptors(object, text) {
      if (!isNaN(object.id)) {
        let payload = {
          id: object.id,
          item: {
            description: text,
          },
        };
        this.$restful
          .Patch(
            `/mesh/competence-middle-descriptor/${payload.id}/`,
            payload.item
          )
          .then((response) => {
            this.mutateCMDescriptor(response);
            this.$emit("change_middle_descriptors", response);
          });
      }
    },
    createCompetenceDescriptors(title) {
      if (isNaN(title.id)) {
        this.$restful
          .Post(`/mesh/competence-descriptor/`, title)
          .then((response) => {
            this.mutateCompetenceDescriptor(response);
            this.$emit("reset_competence_table");
            this.createCMiddleDescriptors("", response);
          });
      }
    },
    updateCompetenceDescriptors(title) {
      if (!isNaN(title.id)) {
        let payload = {
          id: title.id,
          item: {
            title: title.title,
            is_default: title.is_default,
          },
        };
        this.$restful
          .Patch(`/mesh/competence-descriptor/${payload.id}/`, payload.item)
          .then((response) => {
            this.mutateCompetenceDescriptor(response);
            this.$emit("reset_competence_table");
          });
      }
    },
    deleteCompetenceDescriptors(cMiddleDescriptor) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el título descriptivo?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(
              `/mesh/competence-middle-descriptor/${cMiddleDescriptor.id}/`
            )
            .then(() => {
              this.mutateDeleteCMDescriptor(cMiddleDescriptor.id);
              this.$restful
                .Delete(
                  `/mesh/competence-descriptor/${cMiddleDescriptor.descriptor}/`
                )
                .then(() => {
                  this.mutateDeleteCompetenceDescriptor(
                    cMiddleDescriptor.descriptor
                  );
                  this.fetchCMiddleDescriptors();
                  this.$emit("reset_competence_table");
                });
            });
        }
      });
    },
    deleteTitle(id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el título descriptivo?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(`/mesh/competence-descriptor/${id}/`)
            .then(() => {
              this.mutateDeleteCompetenceDescriptor(id);
              this.fetchCMiddleDescriptors();
              this.$emit("reset_competence_table");
            });
        }
      });
    },
    fetchCompetenceDescriptor() {
      this.$restful.Get(`/mesh/competence-descriptor/`).then((response) => {
        this.competenceDescriptor = response;
      });
    },
    fetchCMiddleDescriptors() {
      this.$restful
        .Get(
          `/mesh/competence-middle-descriptor/?competence=${this.competence_id}`
        )
        .then((response) => {
          this.cMiddleDescriptors = response;
        });
    },
  },
  mounted() {},
  created() {
    this.fetchCompetenceDescriptor();
    this.fetchCMiddleDescriptors();
  },
};
</script>

<style scoped>
</style>