var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[(_vm.isFirtsMatterOfLevel)?[_c('td',{staticClass:"td-plan-level",attrs:{"rowspan":_vm.cycleMattersAmount}},[_vm._v(" "+_vm._s(_vm.matter.plan_level)+" ")])]:_vm._e(),(false)?_c('td',[_vm._v(_vm._s(_vm.matter.plan_level))]):_vm._e(),(false)?_c('td',[_vm._v(_vm._s(_vm.getFormationArea(_vm.matter.formation_area)))]):_vm._e(),_c('td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.v-secondary.noninteractive",value:(
      `${_vm.$getVisibleNames(
        'mesh.formationarea',
        false,
        'Área De Formación'
      )}: ${_vm.getFormationArea(_vm.matter.formation_area)}`
    ),expression:"\n      `${$getVisibleNames(\n        'mesh.formationarea',\n        false,\n        'Área De Formación'\n      )}: ${getFormationArea(matter.formation_area)}`\n    ",modifiers:{"top":true,"v-secondary":true,"noninteractive":true}}],staticClass:"td-matter"},[_c('b-link',{attrs:{"to":{
        name: 'MatterProgramView',
        params: { matter_id: _vm.matter.id },
      }}},[_vm._v(" "+_vm._s(_vm.getMatterName(_vm.matter.matter).toUpperCase())+" ")])],1),_vm._l((_vm.competence),function(competence){return [(_vm.getCheckCompetence(_vm.matter, competence.id))?_c('td',{key:_vm.matter.id + 'c' + competence.id,staticClass:"check-competence",attrs:{"id":_vm.matter.id + 'c' + competence.id}},[_c('b-popover',{attrs:{"target":_vm.matter.id + 'c' + competence.id,"placement":"left","triggers":"hover focus"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo"))+": "+_vm._s(_vm.getCycles(competence.cycle)))]},proxy:true}],null,true)},[_c('div',{staticClass:"competence-div"},[_vm._v(" "+_vm._s(competence.profile_cycle ? competence.order + "." + competence.profile_cycle.order : competence.order + ".")+" "),_c('SentenceContainer',{attrs:{"Sentence":competence}})],1)]),_c('b-icon',{attrs:{"icon":"check","variant":"success","width":"25","height":"25"}})],1):_c('td',{key:_vm.matter.id + 'c' + competence.id})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }