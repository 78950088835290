var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("manual.matriz_3", false, "Matriz 3"))+": Tributación de las "+_vm._s(_vm.$getVisibleNames("mesh.egressprofilematter", true, "Asignaturas"))+" al "+_vm._s(_vm.$getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso"))+" ")]),[_c('Matrix3',{attrs:{"egress_profile_id":_vm.egress_profile_id}}),_c('div',{staticClass:"matrix3-report-container"},[_vm._l((_vm.competenceTypes),function(competenceType){return _c('div',{key:competenceType.id},[(_vm.reportCompetences(competenceType.id).length)?_c('div',[_c('div',{staticClass:"competences"},[_c('strong',[_vm._v(_vm._s(competenceType.name.toUpperCase()))])]),_c('table',{staticClass:"competences-table"},[_c('thead',{staticClass:"competence-header"},[_c('th',{staticClass:"competence-caption-title"},[_vm._v("Definición:")]),(
                  _vm.egressProfile
                    ? _vm.egressProfileCycleId.length > 0
                      ? true
                      : false
                    : false
                )?_c('th',{staticClass:"competence-caption-title"},[_vm._v(" "+_vm._s(_vm.egressProfileCycleId.length > 1 ? _vm.$getVisibleNames( "mesh.cycle", true, "Ciclos Formativos" ) : _vm.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo"))+" ")]):_vm._e(),(_vm.profileCompetencesMention.length > 0 ? true : false)?_c('th',{staticClass:"competence-caption-title"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilecompetencemention", _vm.profileCompetencesMention.length > 1, "Mención" ))+" ")]):_vm._e(),_c('th',{staticClass:"amount-courses pr-3"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", true, "Asignaturas" ))+" ")])]),_c('tbody',_vm._l((_vm.reportCompetences(competenceType.id)),function(item){return _c('tr',{key:item.id,staticClass:"competences-row"},[_c('td',{staticClass:"competence-caption"},[_vm._v(" "+_vm._s(item.profile_cycle ? item.order + "." + item.profile_cycle.order : item.order + ".")+" "+_vm._s(item.name)+" ")]),(
                    _vm.egressProfile
                      ? _vm.egressProfileCycleId.length > 0
                        ? true
                        : false
                      : false
                  )?_c('td',{staticClass:"competence-caption"},[_vm._v(" "+_vm._s(_vm.cycleName(item.cycle))+" ")]):_vm._e(),(_vm.profileCompetencesMention.length > 0 ? true : false)?_c('td',{staticClass:"competence-caption"},[_vm._v(" "+_vm._s(_vm.mentionsName(item))+" ")]):_vm._e(),_c('td',{staticClass:"amount-courses"},[_c('div',{staticClass:"competence-matters-container"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.hover",value:(
                        item.courses.length > 0 ? 'Ver Detalles' : ''
                      ),expression:"\n                        item.courses.length > 0 ? 'Ver Detalles' : ''\n                      ",modifiers:{"v-secondary":true,"hover":true}}],staticClass:"popover-btn",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`competence-matters-modal-${item.id}`)}}},[_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(item.cantidad)),_c('span',{staticClass:"sr-only"})])],1),(item.courses.length > 0)?_c('b-modal',{attrs:{"id":`competence-matters-modal-${item.id}`,"size":"lg","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h6',[_c('span',{staticClass:"m-1 text-justify"},[_vm._v(" "+_vm._s(item.profile_cycle ? item.order + "." + item.profile_cycle.order : item.order + ".")+" "+_vm._s(item.name)+" ")])])]},proxy:true}],null,true)},[_c('b-table-simple',{attrs:{"responsive":"","small":"","bordered":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"th-class"},[_vm._v("Nivel del Plan")]),_c('b-th',{staticClass:"th-class"},[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", true, "Asignaturas" )))]),_c('b-th',{staticClass:"th-class"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.credits", true, "Créditos SCT Totales" ))+" ")])],1)],1),_c('b-tbody',_vm._l((_vm.egressProfileMatterList.filter(
                              (x) => item.courses.includes(x.id)
                            )),function(egress_profile_matter){return _c('b-tr',{key:egress_profile_matter.id + '-' + item.id},[_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(egress_profile_matter.plan_level))]),_c('b-td',[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
                                  `Ir al ${_vm.$getVisibleNames(
                                    'manual.matter_program',
                                    false,
                                    'Programa de Asignatura'
                                  )}`
                                ),expression:"\n                                  `Ir al ${$getVisibleNames(\n                                    'manual.matter_program',\n                                    false,\n                                    'Programa de Asignatura'\n                                  )}`\n                                ",modifiers:{"v-secondary":true}}],attrs:{"to":{
                                  name: 'MatterProgramView',
                                  params: {
                                    matter_id: egress_profile_matter.id,
                                  },
                                }}},[_vm._v(" "+_vm._s(_vm.getMatterName( egress_profile_matter.matter ).toUpperCase())+" ")])],1),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(egress_profile_matter.use_matter_values ? ( _vm.matters.find( (x) => x.id == egress_profile_matter.matter ) || {} ).credits || 0 : egress_profile_matter.credits)+" ")])],1)}),1)],1)],1):_vm._e()],1)])])}),0)])]):_vm._e()])}),_c('div',[(_vm.reportCompetences(null).length)?_c('div',[_c('div',{staticClass:"competences"},[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.competence", true, "Competencias" ).toUpperCase()))])]),_c('table',{staticClass:"competences-table"},[_c('thead',{staticClass:"competence-header"},[_c('th',{staticClass:"competence-caption-title"},[_vm._v("Definición:")]),(
                  _vm.egressProfile
                    ? _vm.egressProfileCycleId.length > 0
                      ? true
                      : false
                    : false
                )?_c('th',{staticClass:"competence-caption-title"},[_vm._v(" "+_vm._s(_vm.egressProfileCycleId.length > 1 ? _vm.$getVisibleNames( "mesh.cycle", true, "Ciclos Formativos" ) : _vm.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo"))+" ")]):_vm._e(),(_vm.profileCompetencesMention.length > 0 ? true : false)?_c('th',{staticClass:"competence-caption-title"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilecompetencemention", _vm.profileCompetencesMention.length > 1, "Mención" ))+" ")]):_vm._e(),_c('th',{staticClass:"amount-courses pr-3"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", true, "Asignaturas" ))+" ")])]),_c('tbody',_vm._l((_vm.reportCompetences(null)),function(item){return _c('tr',{key:item.id,staticClass:"competences-row"},[_c('td',{staticClass:"competence-caption"},[_vm._v(" "+_vm._s(item.profile_cycle ? item.order + "." + item.profile_cycle.order : item.order + ".")+" "+_vm._s(item.name)+" ")]),(
                    _vm.egressProfile
                      ? _vm.egressProfileCycleId.length > 0
                        ? true
                        : false
                      : false
                  )?_c('td',{staticClass:"competence-caption"},[_vm._v(" "+_vm._s(_vm.cycleName(item.cycle))+" ")]):_vm._e(),(_vm.profileCompetencesMention.length > 0 ? true : false)?_c('td',{staticClass:"competence-caption"},[_vm._v(" "+_vm._s(_vm.mentionsName(item))+" ")]):_vm._e(),_c('td',{staticClass:"amount-courses"},[_c('div',{staticClass:"competence-matters-container"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.hover",value:(
                        item.courses.length > 0 ? 'Ver Detalles' : ''
                      ),expression:"\n                        item.courses.length > 0 ? 'Ver Detalles' : ''\n                      ",modifiers:{"v-secondary":true,"hover":true}}],staticClass:"popover-btn",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`competence-matters-modal-2-${item.id}`)}}},[_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(item.cantidad)),_c('span',{staticClass:"sr-only"})])],1),(item.courses.length > 0)?_c('b-modal',{attrs:{"id":`competence-matters-modal-2-${item.id}`,"size":"lg","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h6',[_c('span',{staticClass:"m-1 text-justify"},[_vm._v(" "+_vm._s(item.profile_cycle ? item.order + "." + item.profile_cycle.order : item.order + ".")+" "+_vm._s(item.name)+" ")])])]},proxy:true}],null,true)},[_c('b-table-simple',{attrs:{"responsive":"","small":"","bordered":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"th-class"},[_vm._v("Nivel del Plan")]),_c('b-th',{staticClass:"th-class"},[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", true, "Asignaturas" )))]),_c('b-th',{staticClass:"th-class"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.credits", true, "Créditos SCT Totales" ))+" ")])],1)],1),_c('b-tbody',_vm._l((_vm.egressProfileMatterList.filter(
                              (x) => item.courses.includes(x.id)
                            )),function(egress_profile_matter){return _c('b-tr',{key:egress_profile_matter.id + '-' + item.id},[_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(egress_profile_matter.plan_level))]),_c('b-td',[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
                                  `Ir al ${_vm.$getVisibleNames(
                                    'manual.matter_program',
                                    false,
                                    'Programa de Asignatura'
                                  )}`
                                ),expression:"\n                                  `Ir al ${$getVisibleNames(\n                                    'manual.matter_program',\n                                    false,\n                                    'Programa de Asignatura'\n                                  )}`\n                                ",modifiers:{"v-secondary":true}}],attrs:{"to":{
                                  name: 'MatterProgramView',
                                  params: {
                                    matter_id: egress_profile_matter.id,
                                  },
                                }}},[_vm._v(" "+_vm._s(_vm.getMatterName( egress_profile_matter.matter ).toUpperCase())+" ")])],1),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(egress_profile_matter.use_matter_values ? ( _vm.matters.find( (x) => x.id == egress_profile_matter.matter ) || {} ).credits || 0 : egress_profile_matter.credits)+" ")])],1)}),1)],1)],1):_vm._e()],1)])])}),0)])]):_vm._e()])],2)],_c('MeshCustomFab',{staticClass:"noprint",attrs:{"egress_profile_id":_vm.egress_profile_id,"show_matrix3":false}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }