<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.act_type.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-description"
        name="input-description"
        v-model="$v.act_type.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>

    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.act_type.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDeleteObject"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
// import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
export default {
  name: "ActTypeForm",
  mixins: [validationMixin],
  props: {
    actType: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          school: "",
          active: true,
        };
      },
    },
    institution_id: {
      type: Number,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      act_type: this.actType
        ? {
            id: this.actType.id,
            name: this.actType.name,
            description: this.actType.description,
          }
        : { id: generateUniqueId(), name: "" },
    };
  },
  validations: {
    act_type: {
      name: {
        required,
        minLength: minLength(5),
      },
      description: {
        // required,
        // minLength: minLength(5),
      },
    },
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.act_type[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.act_type.$touch();
      if (this.$v.act_type.$anyError) {
        return;
      }
      if (isNaN(this.act_type.id)) {
        this.createActType();
      } else this.updateActType();
    },
    createActType() {
      this.act_type.school = this.institution_id;
      this.$restful.Post("/cideu/act-type/", this.act_type).then((response) => {
        toast(
          String(
            this.$getVisibleNames("cideu.acttype", false, "Tipo de Acta") +
              " creada."
          )
        );
        this.$emit("created", response);
      });
    },
    updateActType() {
      this.act_type.school = this.institution_id;
      this.$restful
        .Put(`/cideu/act-type/${this.act_type.id}/`, this.act_type)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames("cideu.acttype", false, "Tipo de Acta") +
                " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteActType() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "cideu.acttype",
          false,
          "Tipo de Acta"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(`/cideu/act-type/${this.act_type.id}/`)
            .then(() => {
              this.$emit("deleted", this.act_type);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>