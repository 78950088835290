<template>
  <div>
    <b-table-simple small border class="mb-0">
      <b-thead>
        <b-tr>
          <b-th
            colspan="5"
            class="header-top text-center font-weight-bold"
          ></b-th>
        </b-tr>
        <b-tr>
          <!-- <b-th class="secondary-color align-middle" style="width: 5%">1</b-th> -->
          <b-th
            colspan="1"
            class="secondary-color text-left font-weight-bold align-middle"
            style="width: 20%"
          >
            {{
              $getVisibleNames("mesh.career", false, "Programa").toUpperCase()
            }}
            <!-- PROGRAMA -->
          </b-th>
          <b-th colspan="1" class="secondary-color text-left pl-3 align-middle">
            {{ career ? career.name : "" }}
          </b-th>
          <b-th
            class="secondary-color text-left font-weight-bold align-middle"
            style="width: 5%"
          >
            <div
              v-if="
                !((institution && institution.id == 12) || $debug_change_duoc)
              "
            >
              {{
                $getVisibleNames(
                  "mesh.cycle",
                  true,
                  "Ciclos Formativos"
                ).toUpperCase()
              }}
              <button-edit
                v-b-tooltip.v-secondary.noninteractive="
                  `Editar los ${$getVisibleNames(
                    'mesh.cycle',
                    true,
                    'Ciclos Formativos'
                  )}.`
                "
                v-if="allows_crud"
                @click="launchCycleModal"
              ></button-edit>
            </div>
          </b-th>
          <b-th class="secondary-color align-middle" style="width: 5%">
            <div
              class="campus-container"
              v-if="
                !((institution && institution.id == 12) || $debug_change_duoc)
              "
            >
              <!-- v-bind:style="{
                cursor:
                  allows_crud &&
                  ((user_position &&
                    [1, 2, 3].includes(user_position.position) &&
                    [1, 2, 3].includes(user.groups[0])) ||
                    user.is_superuser ||
                    $hasObjectPermission(
                      'mesh.career',
                      'change',
                      egress_profile.career
                    ))
                    ? 'pointer'
                    : '',
              }"
              @click="launchCycleModal" -->
              <div
                v-for="cycle in egressProfileCycles"
                :key="cycle.id"
                class="campus-div"
              >
                {{ cycle.name }}
              </div>
              <div v-if="egressProfileCycles.length == 0">No Aplica.</div>
            </div>
          </b-th>
        </b-tr>
        <b-tr>
          <!-- <b-th class="secondary-color align-middle" style="width: 5%">2</b-th> -->
          <b-th
            colspan="1"
            class="secondary-color text-left font-weight-bold"
            >{{
              $getVisibleNames("mesh.faculty", false, "FACULTAD").toUpperCase()
            }}</b-th
          >
          <b-th colspan="1" class="secondary-color text-left pl-3">
            {{ faculty ? faculty.name : "" }}
          </b-th>
          <b-th
            colspan="1"
            class="secondary-color text-left font-weight-bold"
            style="width: 15%"
          >
            {{
              $getVisibleNames(
                "mesh.egressprofiletype",
                false,
                "Tipo de Perfil de Egreso"
              ).toUpperCase()
            }}
          </b-th>
          <b-th colspan="1" class="secondary-color" style="width: 25%">
            <!-- <b-form-select
              v-if="allows_crud"
              v-model="egress_profile.egress_profile_type"
              :options="egress_profile_types"
              value-field="id"
              text-field="name"
            /> -->
            <div class="ml-0 text-left">
              {{ egressProfileType ? egressProfileType : "No Aplica." }}
            </div>
          </b-th>
        </b-tr>
        <b-tr>
          <!-- <b-th class="secondary-color align-middle">3</b-th> -->
          <b-th
            colspan="1"
            class="secondary-color text-left font-weight-bold align-middle"
            >{{
              $getVisibleNames(
                "manual.mesh.titulo_profesional",
                false,
                "Título Profesional"
              ).toUpperCase()
            }}
            <!-- TÍTULO PROFESIONAL -->
          </b-th>
          <b-th
            colspan="1"
            class="secondary-color align-middle"
            style="width: 35%"
          >
            <b-input
              v-if="
                allows_crud &&
                ((user_position &&
                  [1, 2, 3].includes(user_position.position) &&
                  [1, 2, 3].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.career',
                    'change',
                    egress_profile.career
                  ))
              "
              type="text"
              v-model="egress_profile.professional_title"
              @update="saveProfile"
              :placeholder="`Introduzca el ${$getVisibleNames(
                'manual.mesh.titulo_profesional',
                false,
                'Título Profesional'
              )}`"
              size="sm"
            />
            <div v-else class="ml-2 text-left">
              {{ egress_profile.professional_title }}
            </div>
          </b-th>
          <b-th
            colspan="1"
            class="secondary-color text-left font-weight-bold align-middle"
            style="width: 15%"
          >
            DURACIÓN
          </b-th>
          <b-th
            colspan="1"
            class="secondary-color align-middle"
            style="width: 25%"
          >
            <div
              v-if="
                allows_crud &&
                ((user_position &&
                  [1, 2, 3].includes(user_position.position) &&
                  [1, 2, 3].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.career',
                    'change',
                    egress_profile.career
                  ))
              "
            >
              <div class="d-flex">
                <b-input
                  type="number"
                  min="1"
                  v-model="egress_profile.semester_amount"
                  @update="changeEPMatterAndCourseWrapper()"
                  :placeholder="`Duración del ${$getVisibleNames(
                    'mesh.career',
                    false,
                    'Programa'
                  ).toLowerCase()}`"
                  size="sm"
                />
                <!-- <b-button
                  v-if="
                    egressProfileMatters.filter(
                      (x) =>
                        x.plan_level > egress_profile.semester_amount &&
                        x.egress_profile == this.egress_profile_id
                    ).length > 0 ||
                    courses_wrapper.filter(
                      (x) =>
                        x.init_level > egress_profile.semester_amount &&
                        x.egress_profile == this.egress_profile_id
                    ).length > 0
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `
                  Desvincular las ${this.$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )} que están sobre el nivel asignado.
                  `
                  "
                  class="noprint"
                  size="sm"
                  variant="none"
                  @click="changeEPMatterAndCourseWrapper()"
                >
                  <b-icon-trash style="color: white"></b-icon-trash>
                </b-button> -->
              </div>
            </div>
            <div v-else class="ml-2 text-left">
              {{ egress_profile.semester_amount }}
            </div>
          </b-th>
        </b-tr>
        <b-tr v-if="!(institution.id == 12 || $debug_change_duoc)">
          <!-- <b-th class="secondary-color align-middle">4</b-th> -->
          <b-th
            v-if="institution.show_academic_degree"
            colspan="1"
            class="secondary-color text-left font-weight-bold align-middle"
            >{{
              $getVisibleNames(
                "manual.grado_academico",
                false,
                "Grado Académico"
              ).toUpperCase()
            }}
            <!-- GRADO ACADÉMICO -->
          </b-th>
          <b-th
            v-if="institution.show_academic_degree"
            colspan="1"
            class="secondary-color align-middle"
          >
            <b-input
              v-if="
                allows_crud &&
                ((user_position &&
                  [1, 2, 3].includes(user_position.position) &&
                  [1, 2, 3].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.career',
                    'change',
                    egress_profile.career
                  ))
              "
              type="text"
              v-model="egress_profile.academic_degree"
              @update="saveProfile"
              :placeholder="`Introduzca el ${$getVisibleNames(
                'manual.grado_academico',
                false,
                'Grado Académico'
              )}`"
              size="sm"
            />
            <div v-else class="ml-2 text-left">
              {{ egress_profile.academic_degree }}
            </div>
          </b-th>
          <b-th
            colspan="1"
            class="secondary-color text-left font-weight-bold align-middle"
            >{{
              selectedCampuses.length > 1
                ? $getVisibleNames("mesh.campus", true, "Sedes").toUpperCase()
                : $getVisibleNames("mesh.campus", false, "Sede").toUpperCase()
            }}</b-th
          >
          <b-th
            :colspan="institution.show_academic_degree ? 1 : 3"
            class="secondary-color"
          >
            <template
              v-if="
                allows_crud &&
                ((user_position &&
                  [1, 2, 3].includes(user_position.position) &&
                  [1, 2, 3].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.career',
                    'change',
                    egress_profile.career
                  ))
              "
            >
              <!-- <b-form-select
                v-model="selected_campuses"
                :options="campusListFiltered"
                @change="saveProfile"
                value-field="id"
                text-field="name"
                multiple
                :select-size="
                  7 > campusListFiltered.length ? campusListFiltered.length : 7
                "
              /> -->
              <v-select
                class="v-select-class"
                v-model="selected_campuses"
                :options="campusListFiltered"
                @input="saveProfile"
                :reduce="(campusListFiltered) => campusListFiltered.id"
                :selectable="(option) => !selected_campuses.includes(option.id)"
                multiple
                :placeholder="`Seleccione uno o varias ${$getVisibleNames(
                  'mesh.campus',
                  true,
                  'Sedes'
                )}`"
                label="name"
                track-by="id"
                size="sm"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No se encontró resultados para <em>{{ search }}</em
                    >.
                  </template>
                </template>
              </v-select>
            </template>
            <template v-else>
              <div class="campus-container campus-scroll">
                <div
                  v-for="campus in selectedCampuses"
                  :key="campus.id"
                  class="campus-div"
                >
                  {{ campus.name }}
                </div>
              </div>
            </template>
          </b-th>
        </b-tr>
        <b-tr>
          <!-- <b-th class="secondary-color align-middle">5</b-th> -->
          <b-th
            colspan="1"
            class="secondary-color text-left font-weight-bold align-middle"
          >
            {{
              $getVisibleNames(
                "manual.egress_profile_code",
                false,
                "Código de Perfil de Egreso"
              ).toUpperCase()
            }}
          </b-th>
          <b-th colspan="1" class="secondary-color align-middle">
            <template
              v-if="
                allows_crud &&
                ((user_position &&
                  [1, 2, 3].includes(user_position.position) &&
                  [1, 2, 3].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.career',
                    'change',
                    egress_profile.career
                  ))
              "
            >
              <b-input
                type="text"
                v-model="egress_profile.code"
                :state="
                  egress_profile_code_list.filter(
                    (x) =>
                      $equals(x.code, egress_profile.code) &&
                      x.id != egress_profile.id
                  ).length == 0
                "
                @update="
                  (value) => {
                    egress_profile.code = value.toUpperCase();
                    saveProfile();
                  }
                "
                aria-describedby="input-code-feedback"
                :placeholder="`Introduzca el ${$getVisibleNames(
                  'manual.egress_profile_code',
                  false,
                  'Código de Perfil de Egreso'
                )}`"
                size="sm"
              />
              <b-form-invalid-feedback id="input-code-feedback">
                <div
                  class="text-left"
                  v-if="
                    this.egress_profile_code_list.find(
                      (x) =>
                        this.$equals(x.code, this.egress_profile.code) &&
                        x.id != this.egress_profile.id
                    )
                  "
                >
                  El
                  {{
                    $getVisibleNames(
                      "manual.egress_profile_code",
                      false,
                      "Código de Perfil de Egreso"
                    )
                  }}
                  ya está siendo utilizado.
                </div>
              </b-form-invalid-feedback>
            </template>
            <!-- @update="saveProfile" -->
            <div v-else class="ml-2 text-left">
              <span
                v-if="
                  egress_profile.code == null ||
                  egress_profile.code.trim() == ''
                "
              >
                N/A
              </span>
              <span v-else>
                {{ egress_profile.code }}
              </span>
            </div>
          </b-th>
          <b-th
            colspan="1"
            class="secondary-color text-left font-weight-bold align-middle"
          >
            {{
              $getVisibleNames(
                "mesh.modalityegressprofile",
                false,
                "Modalidad del Perfil de Egreso"
              ).toUpperCase()
            }}
          </b-th>
          <b-th colspan="1" class="secondary-color align-middle">
            <template
              v-if="
                allows_crud &&
                ((user_position &&
                  [1, 2, 3].includes(user_position.position) &&
                  [1, 2, 3].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.career',
                    'change',
                    egress_profile.career
                  ))
              "
            >
              <b-form-select
                v-model="egress_profile.modality"
                :options="modalities_egress_profiles"
                value-field="id"
                @change="saveProfile"
                text-field="name"
                ><template #first>
                  <b-form-select-option :value="null"
                    >N/A
                  </b-form-select-option></template
                ></b-form-select
              >
            </template>
            <template v-else>
              <div class="text-justify">
                {{ modalityProfile }}
              </div>
            </template>
          </b-th>
        </b-tr>
        <b-tr>
          <!-- <b-th class="secondary-color align-middle">6</b-th> -->
          <b-th
            colspan="1"
            class="secondary-color text-left font-weight-bold align-middle"
          >
            <template v-if="institution && institution.show_ep_full_init_date">
              FECHA DE INICIO
            </template>
            <template v-else>AÑO DE ENTRADA EN VIGENCIA</template>
          </b-th>
          <b-th colspan="1" class="secondary-color">
            <template v-if="institution && institution.show_ep_full_init_date">
              <b-form-datepicker
                id="input-start-date"
                :disabled="
                  !(
                    allows_crud &&
                    ((user_position &&
                      [1, 2, 3].includes(user_position.position) &&
                      [1, 2, 3].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        egress_profile.career
                      ))
                  )
                "
                v-model="egress_profile.init_date"
                @input="saveProfile"
                placeholder="Definir fecha de inicio"
                aria-describedby="input-start-date-feedback"
                size="sm"
                dropright
              ></b-form-datepicker>
            </template>
            <template v-else>
              <b-form-input
                id="init_date-input"
                name="init_date-input"
                v-model="egress_profile.init_date"
                type="number"
                :disabled="
                  !(
                    allows_crud &&
                    ((user_position &&
                      [1, 2, 3].includes(user_position.position) &&
                      [1, 2, 3].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        egress_profile.career
                      ))
                  )
                "
                aria-describedby="input-init_date-live-feedback"
                :state="stateEPInitDate()"
                @update="
                  (value) => {
                    if (stateEPInitDate(value) && allows_crud) saveProfile();
                  }
                "
              ></b-form-input>
              <b-form-invalid-feedback id="input-init_date-live-feedback"
                >Ingrese un año válido</b-form-invalid-feedback
              >
            </template>
          </b-th>
          <b-th
            colspan="1"
            class="secondary-color text-left font-weight-bold"
          ></b-th>
          <b-th colspan="1" class="secondary-color"> </b-th>
        </b-tr>
      </b-thead>
    </b-table-simple>
    <p v-for="(error, index) in errors" :key="`error-${index}`" class="error-p">
      {{ error }}
    </p>
    <!-- Perfil de egreso -->
    <DescriptorsContainer
      :ref_component="'EgressProfile'"
      :Title_text="
        $getVisibleNames('mesh.egressprofile', true, 'Perfiles de Egreso')
      "
      :Text_info_tooltip="`Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros ${$getVisibleNames(
        'mesh.egressprofile',
        true,
        'Perfiles de Egreso'
      )}.`"
      :Title="egressProfileDescriptor.filter((x) => x.page_position == 1)"
      :Descriptors="
        epMiddleDescriptors.filter((a) =>
          egressProfileDescriptor
            .filter((b) => b.page_position == 1)
            .map((c) => c.id)
            .includes(a.descriptor)
        )
      "
      :allows_crud="
        allows_crud &&
        ((user_position &&
          [1, 2, 3].includes(user_position.position) &&
          [1, 2, 3].includes(user.groups[0])) ||
          user.is_superuser ||
          $hasObjectPermission('mesh.career', 'change', egress_profile.career))
      "
      :Order="7"
      :VisibleOrder="false"
      :PagePosition="1"
      @updateOrder="updateOrderEpMiddleDescriptors"
      @updateState="updateVisibleEpMiddleDescriptors"
      @updateDescription="updateEpMiddleDescriptors"
      @createDescription="createEpMiddleDescriptors"
      @createTitle="createProfileDescriptors"
      @updateTitle="updateProfileDescriptors"
      @deleteDescriptors="deleteProfileDescriptors"
      @deleteTitle="deleteTitle"
    >
    </DescriptorsContainer>
    <b-table-simple
      small
      responsive
      border
      class="competences-table page-break"
    >
      <b-thead class="page-break">
        <b-tr>
          <!-- <b-th>{{ lengthEgressProfileDescriptor }}</b-th> -->
          <b-th
            :colspan="5 + competenceDescriptor.length"
            class="text-center font-weight-bold"
            >{{
              $getVisibleNames(
                "mesh.competence",
                true,
                "Competencias"
              ).toUpperCase()
            }}
            <div
              class="float-right"
              v-if="
                allows_crud &&
                ((user_position &&
                  [1, 2, 3].includes(user_position.position) &&
                  [1, 2, 3].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.career',
                    'change',
                    egress_profile.career
                  ))
              "
            >
              <b-button
                v-can="'mesh.add_egressprofilecompetence'"
                size="sm"
                class="secondary-button"
                @click="$bvModal.show(`new-competence-modal`)"
                v-b-tooltip.v-secondary.noninteractive="
                  `Agregar ${$getVisibleNames(
                    'mesh.competence',
                    true,
                    'Competencias'
                  ).toLowerCase()}`
                "
                ><b-icon-plus></b-icon-plus>Agregar</b-button
              >
              <b-button
                v-can="'mesh.add_egressprofilecompetence'"
                size="sm"
                class="secondary-button ml-3"
                @click="$bvModal.show(`selected-competence-modal`)"
                v-b-tooltip.v-secondary.noninteractive="
                  `Seleccionar ${$getVisibleNames(
                    'mesh.competence',
                    true,
                    'Competencias'
                  ).toLowerCase()}`
                "
                >Seleccionar
                {{
                  $getVisibleNames("mesh.competence", true, "Competencias")
                }}</b-button
              >
              <b-modal
                :id="`selected-competence-modal`"
                :title="`Seleccionar ${$getVisibleNames(
                  'mesh.competence',
                  true,
                  'Competencias'
                )}`"
                size="lg"
                @hide="hideModal"
                hide-footer
              >
                <SelectedCompetence
                  :egress_profile_id="egress_profile_id"
                  :cycle_id="egressCycleId"
                  :display_id="`selected-competence-modal`"
                  @slotUpdate="slotUpdateProfileCompetence"
                ></SelectedCompetence>
                <div class="row">
                  <div class="col" style="text-align: right">
                    <b-button
                      class="mr-1"
                      @click="saveProfileCompetence()"
                      size="sm"
                      >Guardar</b-button
                    >
                  </div>
                </div>
              </b-modal>
              <b-modal
                :id="`new-competence-modal`"
                :title="`Redactar ${$getVisibleNames(
                  'mesh.competence',
                  false,
                  'Competencia'
                )}`"
                size="lg"
                hide-footer
              >
                <CompetenceForm
                  :egress_profile_id="egress_profile_id"
                  :cycle_id="egressCycleId"
                  :order="suggestedOrder"
                  :egress_profile_view="true"
                  :can_edit_cycle="false"
                  :can_edit_order="true"
                  @deleted="$bvModal.hide(`new-competence-modal`)"
                  @created="$bvModal.hide(`new-competence-modal`)"
                ></CompetenceForm>
                <!-- @create="slotCreateCompetence" -->
              </b-modal>
            </div>
          </b-th>
        </b-tr>
        <b-tr>
          <b-th v-if="competencesList.filter((x) => x.type != null).length > 0">
            <strong>
              {{
                $getVisibleNames(
                  "mesh.competencetype",
                  false,
                  "Tipo de Competencias"
                ).toUpperCase()
              }}
            </strong>
          </b-th>
          <b-th
            class="w-100"
            :colspan="
              allows_crud &&
              ((user_position &&
                [1, 2, 3].includes(user_position.position) &&
                [1, 2, 3].includes(user.groups[0])) ||
                user.is_superuser ||
                $hasObjectPermission(
                  'mesh.career',
                  'change',
                  egress_profile.career
                ))
                ? 2
                : 1
            "
            ><strong>REDACCIÓN</strong></b-th
          >
          <template v-for="descriptor in competenceDescriptor">
            <b-th :key="descriptor.key">
              <strong>{{ descriptor.label.toUpperCase() }}</strong>
            </b-th>
          </template>
          <template
            v-if="
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
          >
            <b-th
              v-if="
                (institution && institution.show_base_ra) || $debug_change_duoc
              "
            >
              <strong>
                {{
                  $getVisibleNames(
                    "teaching.basera",
                    false,
                    "RA Base"
                  ).toUpperCase()
                }}
              </strong>
            </b-th>
          </template>
          <b-th
            v-if="
              allows_crud &&
              ((user_position &&
                [1, 2, 3].includes(user_position.position) &&
                [1, 2, 3].includes(user.groups[0])) ||
                user.is_superuser ||
                $hasObjectPermission(
                  'mesh.career',
                  'change',
                  egress_profile.career
                ))
            "
          ></b-th>
        </b-tr>
      </b-thead>
      <draggable
        v-model="competencesList"
        :disabled="
          !(
            allows_crud &&
            ((user_position &&
              [1, 2, 3].includes(user_position.position) &&
              [1, 2, 3].includes(user.groups[0])) ||
              user.is_superuser ||
              $hasObjectPermission(
                'mesh.career',
                'change',
                egress_profile.career
              ))
          )
        "
        tag="b-tbody"
        handle=".handle"
      >
        <template v-if="competencesList.length > 0">
          <b-tr v-for="competence in competencesList" :key="competence.id">
            <b-td
              v-if="
                competencesList.filter((x) => x.type != null).length > 0 &&
                (firsCompetenceType(competence) ||
                  (allows_crud &&
                    ((user_position &&
                      [1, 2, 3].includes(user_position.position) &&
                      [1, 2, 3].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        egress_profile.career
                      ))))
              "
              class="row-secondary-color"
              :rowspan="
                allows_crud &&
                ((user_position &&
                  [1, 2, 3].includes(user_position.position) &&
                  [1, 2, 3].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.career',
                    'change',
                    egress_profile.career
                  ))
                  ? 1
                  : competencesList.filter((x) => x.type == competence.type)
                      .length
              "
            >
              <strong>
                {{
                  competenceTypes.find((x) => x.id == competence.type)
                    ? competenceTypes
                        .find((x) => x.id == competence.type)
                        .name.toUpperCase()
                    : ""
                }}
              </strong>
            </b-td>
            <b-td
              class="text-center handle"
              :class="{
                grabbable:
                  allows_crud &&
                  ((user_position &&
                    [1, 2, 3].includes(user_position.position) &&
                    [1, 2, 3].includes(user.groups[0])) ||
                    user.is_superuser ||
                    $hasObjectPermission(
                      'mesh.career',
                      'change',
                      egress_profile.career
                    )),
              }"
              v-if="
                allows_crud &&
                ((user_position &&
                  [1, 2, 3].includes(user_position.position) &&
                  [1, 2, 3].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.career',
                    'change',
                    egress_profile.career
                  ))
              "
              ><b-icon
                v-if="
                  allows_crud &&
                  ((user_position &&
                    [1, 2, 3].includes(user_position.position) &&
                    [1, 2, 3].includes(user.groups[0])) ||
                    user.is_superuser ||
                    $hasObjectPermission(
                      'mesh.career',
                      'change',
                      egress_profile.career
                    ))
                "
                icon="arrows-expand"
                scale="1.2"
              ></b-icon
            ></b-td>
            <b-td class="text-center">
              <div class="d-flex ml-3">
                <span
                  ><strong>{{ competence.order }}.</strong></span
                >
                <span
                  v-bind:class="orderState(competence) ? 'text-danger' : ''"
                >
                  <SentenceContainer
                    :Sentence="competence"
                    :order_hint="orderState(competence) ? true : false"
                  >
                  </SentenceContainer>
                </span>
              </div>
            </b-td>
            <b-td
              v-for="description in competenceDescriptor"
              :key="description.key"
            >
              {{
                competence.descriptions.find(
                  (x) =>
                    String("descriptor_" + x.descriptor__id) == description.key
                )
                  ? competence.descriptions.find(
                      (x) =>
                        String("descriptor_" + x.descriptor__id) ==
                        description.key
                    ).description
                  : ""
              }}
            </b-td>
            <template
              v-if="
                !((institution && institution.id == 12) || $debug_change_duoc)
              "
            >
              <b-td
                v-if="
                  (institution && institution.show_base_ra) ||
                  $debug_change_duoc
                "
              >
                <div class="ml-2">
                  <h5
                    :key="`ra-base-header-${competence.id}`"
                    :id="`ra-base-header-${competence.id}`"
                    v-bind:class="{
                      'pointer-class':
                        allows_crud &&
                        ((user_position &&
                          [1, 2, 3].includes(user_position.position) &&
                          [1, 2, 3].includes(user.groups[0])) ||
                          user.is_superuser ||
                          $hasObjectPermission(
                            'mesh.career',
                            'change',
                            egress_profile.career
                          )),
                    }"
                    @click="
                      () => {
                        if (
                          allows_crud &&
                          ((user_position &&
                            [1, 2, 3].includes(user_position.position) &&
                            [1, 2, 3].includes(user.groups[0])) ||
                            user.is_superuser ||
                            $hasObjectPermission(
                              'mesh.career',
                              'change',
                              egress_profile.career
                            ))
                        )
                          $bvModal.show(`modal-base-ra-${competence.id}`);
                      }
                    "
                  >
                    <b-badge variant="secondary">{{
                      raBasesFiltered(competence.competence).length
                    }}</b-badge>
                  </h5>
                  <b-modal
                    :id="`modal-base-ra-${competence.id}`"
                    hide-footer
                    :title="
                      $getVisibleNames('teaching.basera', false, 'RA Base')
                    "
                    size="lg"
                  >
                    <RaBase :competence_id="competence.competence"></RaBase>
                  </b-modal>
                  <b-popover
                    :target="`ra-base-header-${competence.id}`"
                    :key="`ra-base-tooltip-${competence.id}`"
                    triggers="hover"
                  >
                    <template #title>{{
                      $getVisibleNames("teaching.basera", false, "RA Base")
                    }}</template>
                    <template
                      v-if="raBasesFiltered(competence.competence).length > 0"
                    >
                      <div class="ra-competences-container">
                        <span
                          v-for="ra in raBasesFiltered(competence.competence)"
                          :key="ra.id"
                        >
                          <SentenceContainer :Sentence="ra">
                          </SentenceContainer>
                        </span>
                      </div>
                    </template>
                    <template v-else
                      >No cuenta con ningún
                      {{
                        $getVisibleNames("teaching.basera", false, "RA Base")
                      }}
                      creado
                    </template>
                  </b-popover>
                </div>
              </b-td>
            </template>
            <b-td
              v-if="
                allows_crud &&
                ((user_position &&
                  [1, 2, 3].includes(user_position.position) &&
                  [1, 2, 3].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.career',
                    'change',
                    egress_profile.career
                  ))
              "
            >
              <div class="noprint button-competence">
                <div
                  class="button-wrap"
                  v-if="
                    allows_crud &&
                    ((user_position &&
                      [1, 2, 3].includes(user_position.position) &&
                      [1, 2, 3].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        egress_profile.career
                      ))
                  "
                >
                  <div class="edit-button">
                    <button-edit
                      v-can="'mesh.change_egressprofilecompetence'"
                      @click="
                        $bvModal.show(`modal-edit-competence-${competence.id}`)
                      "
                    ></button-edit>
                  </div>
                  <div class="edit-button">
                    <b-button
                      class="p-1"
                      variant="none"
                      v-can="'mesh.delete_egressprofilecompetence'"
                      @click="askForDeleteCompetence(competence.id)"
                    >
                      <b-icon icon="trash-fill" scale="0.9"></b-icon>
                    </b-button>
                    <!-- <button-delete
                      v-can="'mesh.delete_egressprofilecompetence'"
                      @click="askForDeleteCompetence(competence.id)"
                    ></button-delete> -->
                  </div>
                  <b-modal
                    :id="`modal-edit-competence-${competence.id}`"
                    :title="`Modificar ${$getVisibleNames(
                      'mesh.competence',
                      true,
                      'Competencias'
                    )}`"
                    size="lg"
                    hide-footer
                  >
                    <CompetenceForm
                      :Competence="
                        competences.find((x) => x.id == competence.competence)
                      "
                      :egress_profile_id="egress_profile_id"
                      :type_id="competence.type"
                      :cycle_id="egressCycleId"
                      @updated="
                        $bvModal.hide(`modal-edit-competence-${competence.id}`)
                      "
                      @reset_competence="changeCompetenceDescriptor(competence)"
                      @reset_competence_table="resetCompetenceTable"
                      @change_middle_descriptors="
                        () => {
                          changeCompetenceDescriptor(competence);
                        }
                      "
                      :egress_profile_view="true"
                      :can_edit_cycle="false"
                      :can_edit_order="true"
                      :can_edit_type="true"
                      :allows_crud="true"
                      :show_cycle="false"
                    ></CompetenceForm>
                  </b-modal>
                </div>
              </div>
            </b-td>
          </b-tr>
        </template>
        <b-tr v-else>
          <b-td
            class="text-center"
            :colspan="
              allows_crud &&
              ((user_position &&
                [1, 2, 3].includes(user_position.position) &&
                [1, 2, 3].includes(user.groups[0])) ||
                user.is_superuser ||
                $hasObjectPermission(
                  'mesh.career',
                  'change',
                  egress_profile.career
                ))
                ? 6 + competenceDescriptor.length
                : 5 + competenceDescriptor.length
            "
          >
            No hay
            {{ $getVisibleNames("mesh.competence", true, "Competencias") }}
            para mostrar.
          </b-td>
        </b-tr>
      </draggable>
      <!-- <b-tbody>
        <b-tr
          v-for="competence_type in competenceTypes"
          :key="competence_type.id"
        >
          <template
            v-if="
              (competencesList.filter((x) => x.type == competence_type.id)
                .length > 0 &&
                !allows_crud) ||
              allows_crud
            "
          >
            <b-td
              colspan="2"
              class="secondary-color"
              style="background: var(--secondary-color) !important"
            >
              <strong>{{ competence_type.name.toUpperCase() }}</strong>
            </b-td>
            <b-td colspan="3">
              <div
                v-if="
                  competencesList.filter((x) => x.type == competence_type.id)
                    .length == 0
                "
                class="text-center mb-2"
                style="font-size: 11pt"
              >
                No existen
                {{ $getVisibleNames("mesh.competence", true, "Competencias") }}
                {{ competence_type.name.toLowerCase() }} que tributen a este
                perfil.
              </div>
              <draggable
                v-model="competencesList"
                tag="div"
                v-if="allows_crud && competencesList.length > 0"
              >
                <div
                  v-for="competence in competencesList.filter(
                    (x) => x.type == competence_type.id
                  )"
                  :key="competence.id"
                  style="cursor: move"
                >
                  <div class="d-flex ml-2">
                    <span
                      ><strong>{{ competence.order }}.</strong></span
                    >
                    <span
                      v-bind:class="orderState(competence) ? 'text-danger' : ''"
                    >
                      <SentenceContainer
                        :Sentence="competence"
                        :order_hint="orderState(competence) ? true : false"
                      >
                      </SentenceContainer>
                    </span>
                    <div
                      v-for="descriptor in competence.descriptions"
                      :key="descriptor.id"
                    >
                      <span
                        class="mr-3 ml-1"
                        v-b-tooltip.v-secondary.noninteractive="
                          descriptor.descriptor__title
                        "
                      >
                        {{ descriptor.description }}
                      </span>
                    </div>
                    <div class="noprint button-competence">
                      <div class="button-wrap" v-if="allows_crud">
                        <div class="mr-1">
                          <h5
                            :key="`ra-base-header-${competence.id}`"
                            :id="`ra-base-header-${competence.id}`"
                            v-bind:class="{
                              'pointer-class': allows_crud,
                            }"
                            @click="
                              () => {
                                if (allows_crud)
                                  $bvModal.show(
                                    `modal-base-ra-${competence.id}`
                                  );
                              }
                            "
                          >
                            <b-badge variant="secondary">{{
                              raBasesFiltered(competence.competence).length
                            }}</b-badge>
                          </h5>
                          <b-modal
                            :id="`modal-base-ra-${competence.id}`"
                            hide-footer
                            :title="
                              $getVisibleNames(
                                'teaching.basera',
                                false,
                                'RA Base'
                              )
                            "
                            size="lg"
                          >
                            <RaBase
                              :competence_id="competence.competence"
                            ></RaBase>
                          </b-modal>
                          <b-popover
                            :target="`ra-base-header-${competence.id}`"
                            :key="`ra-base-tooltip-${competence.id}`"
                            triggers="hover"
                          >
                            <template #title>{{
                              $getVisibleNames(
                                "teaching.basera",
                                false,
                                "RA Base"
                              )
                            }}</template>
                            <template
                              v-if="
                                raBasesFiltered(competence.competence).length >
                                0
                              "
                            >
                              <span
                                v-for="ra in raBasesFiltered(
                                  competence.competence
                                )"
                                :key="ra.id"
                              >
                                <SentenceContainer :Sentence="ra">
                                </SentenceContainer>
                              </span>
                            </template>
                            <template v-else
                              >No cuenta con ningún
                              {{
                                $getVisibleNames(
                                  "teaching.basera",
                                  false,
                                  "RA Base"
                                )
                              }}
                              creado
                            </template>
                          </b-popover>
                        </div>
                        <div class="edit-button">
                          <button-edit
                            v-can="'mesh.change_egressprofilecompetence'"
                            @click="
                              $bvModal.show(
                                `modal-edit-competence-${competence.id}`
                              )
                            "
                          ></button-edit>
                        </div>
                        <div class="edit-button">
                          <button-delete
                            v-can="'mesh.delete_egressprofilecompetence'"
                            @click="askForDeleteCompetence(competence.id)"
                          ></button-delete>
                        </div>
                        <b-modal
                          :id="`modal-edit-competence-${competence.id}`"
                          :title="`Modificar ${$getVisibleNames(
                            'mesh.competence',
                            true,
                            'Competencias'
                          )}`"
                          size="lg"
                          hide-footer
                        >
                          <CompetenceForm
                            :Competence="
                              competences.find(
                                (x) => x.id == competence.competence
                              )
                            "
                            :egress_profile_id="egress_profile_id"
                            :type_id="competence.type"
                            :cycle_id="egressCycleId"
                            @updated="
                              $bvModal.hide(
                                `modal-edit-competence-${competence.id}`
                              )
                            "
                            :egress_profile_view="true"
                            :can_edit_cycle="false"
                            :can_edit_order="true"
                            :can_edit_type="true"
                            :allows_crud="true"
                            :show_cycle="false"
                          ></CompetenceForm>
                        </b-modal>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
              <div v-else style="width: 100%; margin-left: 10px">
                <div
                  v-for="competence in competencesList.filter(
                    (x) => x.type == competence_type.id
                  )"
                  :key="competence.id"
                  class="d-flex"
                  style="padding-right: 10px"
                >
                  <span
                    ><strong>{{ competence.order }}.</strong></span
                  >
                  <span>
                    <SentenceContainer :Sentence="competence">
                    </SentenceContainer>
                  </span>
                  <div
                    v-for="descriptor in competence.descriptions"
                    :key="descriptor.id"
                  >
                    <span
                      class="mr-3 ml-1"
                      v-b-tooltip.v-secondary.noninteractive="
                        descriptor.descriptor__title
                      "
                    >
                      {{ descriptor.description }}
                    </span>
                  </div>
                  <div class="noprint button-competence">
                    <h5
                      :key="`ra-base-header-${competence.id}`"
                      :id="`ra-base-header-${competence.id}`"
                    >
                      <b-badge variant="secondary">{{
                        raBasesFiltered(competence.competence).length
                      }}</b-badge>
                    </h5>
                    <b-popover
                      :target="`ra-base-header-${competence.id}`"
                      :key="`ra-base-tooltip-${competence.id}`"
                      triggers="hover"
                    >
                      <template #title>{{
                        $getVisibleNames("teaching.basera", false, "RA Base")
                      }}</template>
                      <template
                        v-if="raBasesFiltered(competence.competence).length > 0"
                      >
                        <span
                          v-for="ra in raBasesFiltered(competence.competence)"
                          :key="ra.id"
                        >
                          <SentenceContainer :Sentence="ra">
                          </SentenceContainer>
                        </span>
                      </template>
                      <template v-else
                        >No cuenta con ningún
                        {{
                          $getVisibleNames("teaching.basera", false, "RA Base")
                        }}
                        creado
                      </template>
                    </b-popover>
                  </div>
                </div>
              </div>
              <div v-if="allows_crud">
                <b-button
                  v-can="'mesh.add_egressprofilecompetence'"
                  size="sm"
                  class="secondary-button"
                  @click="
                    $bvModal.show(`new-competence-modal-${competence_type.id}`)
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `Agregar ${$getVisibleNames(
                      'mesh.competence',
                      true,
                      'Competencias'
                    ).toLowerCase()} ${competence_type.name.toLowerCase()}`
                  "
                  ><b-icon-plus></b-icon-plus>Agregar</b-button
                >
                <b-button
                  v-can="'mesh.add_egressprofilecompetence'"
                  size="sm"
                  class="secondary-button ml-3"
                  @click="
                    $bvModal.show(
                      `selected-competence-modal-${competence_type.id}`
                    )
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `Seleccionar ${$getVisibleNames(
                      'mesh.competence',
                      true,
                      'Competencias'
                    ).toLowerCase()} ${competence_type.name.toLowerCase()}`
                  "
                  >Seleccionar Competencias</b-button
                >
                <b-modal
                  :id="`selected-competence-modal-${competence_type.id}`"
                  :title="`Seleccionar ${$getVisibleNames(
                    'mesh.competence',
                    true,
                    'Competencias'
                  )} ${competence_type.name}`"
                  size="lg"
                  @hide="hideModal"
                  hide-footer
                >
                  <SelectedCompetence
                    :type_id="competence_type.id"
                    :egress_profile_id="egress_profile_id"
                    :cycle_id="egressCycleId"
                    :display_id="`selected-competence-modal-${competence_type.id}`"
                    @slotUpdate="slotUpdateProfileCompetence"
                  ></SelectedCompetence>
                  <div class="row">
                    <div class="col" style="text-align: right">
                      <b-button
                        class="mr-1"
                        @click="saveProfileCompetence(competence_type.id)"
                        size="sm"
                        >Guardar</b-button
                      >
                    </div>
                  </div>
                </b-modal>
                <b-modal
                  :id="`new-competence-modal-${competence_type.id}`"
                  :title="`Redactar ${$getVisibleNames(
                    'mesh.competence',
                    false,
                    'Competencia'
                  )}`"
                  size="lg"
                  hide-footer
                >
                  <CompetenceForm
                    :egress_profile_id="egress_profile_id"
                    :type_id="competence_type.id"
                    :cycle_id="egressCycleId"
                    :order="suggestedOrder"
                    :egress_profile_view="true"
                    :can_edit_cycle="false"
                    :can_edit_order="true"
                    @create="slotCreateCompetence"
                    @created="
                      $bvModal.hide(
                        `new-competence-modal-${competence_type.id}`
                      )
                    "
                  ></CompetenceForm>
                </b-modal>
              </div>
            </b-td>
          </template>
        </b-tr>
        <template v-if="competencesList == 0 && !allows_crud">
          <b-td colspan="5" class="competences-empty">
            No existen
            {{ $getVisibleNames("mesh.competence", true, "Competencias") }} que
            tributen a este perfil.
          </b-td>
        </template>
      </b-tbody> -->
    </b-table-simple>
    <template
      v-if="(institution && institution.id == 12) || $debug_change_duoc"
    >
      <template v-if="egress_profile_id">
        <EgressProfileCompetenceMention
          v-if="
            profile_competences_mention.filter(
              (x) => x.egress_profile == egress_profile_id
            ).length > 0 || allows_crud
          "
          :allows_crud="allows_crud"
          :egress_profile_id="egress_profile_id"
        ></EgressProfileCompetenceMention>
      </template>
    </template>
    <DescriptorsContainer
      :ref_component="'EgressProfileDescriptor2'"
      :Title_text="
        $getVisibleNames('mesh.egressprofile', true, 'Perfiles de Egreso')
      "
      :Text_info_tooltip="`Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros ${$getVisibleNames(
        'mesh.egressprofile',
        true,
        'Perfiles de Egreso'
      )}.`"
      :Title="egressProfileDescriptor.filter((x) => x.page_position == 2)"
      :Descriptors="
        epMiddleDescriptors.filter((a) =>
          egressProfileDescriptor
            .filter((b) => b.page_position == 2)
            .map((c) => c.id)
            .includes(a.descriptor)
        )
      "
      :allows_crud="
        allows_crud &&
        ((user_position &&
          [1, 2, 3].includes(user_position.position) &&
          [1, 2, 3].includes(user.groups[0])) ||
          user.is_superuser ||
          $hasObjectPermission('mesh.career', 'change', egress_profile.career))
      "
      :Order="lengthEgressProfileDescriptor + 1"
      :VisibleOrder="false"
      :PagePosition="2"
      @updateOrder="updateOrderEpMiddleDescriptors"
      @updateState="updateVisibleEpMiddleDescriptors"
      @updateDescription="updateEpMiddleDescriptors"
      @createDescription="createEpMiddleDescriptors"
      @createTitle="createProfileDescriptors"
      @updateTitle="updateProfileDescriptors"
      @deleteDescriptors="deleteProfileDescriptors"
      @deleteTitle="deleteTitle"
    >
    </DescriptorsContainer>
    <!-- <div class="ml-2 mt-1 mb-5">
      <b-button
        variant="secondary"
        size="sm"
        class="float-left noprint"
        @click="$bvModal.show('egressprofile-file-modal')"
      >
        Anexos
      </b-button>
    </div> -->
    <!-- Modals -->
    <b-modal
      id="modal-change-ep-matter-coruse-wrapper"
      :title="`Duración del ${$getVisibleNames(
        'mesh.egressprofile',
        false,
        'Perfil de Egreso'
      )}: ${egress_profile.semester_amount}`"
      size="xl"
      @hide="returnSemesterAmount"
      hide-footer
    >
      <template
        v-if="
          courses_wrapper.filter(
            (x) =>
              x.init_level > egress_profile.semester_amount &&
              x.egress_profile == egress_profile_id
          ).length > 0
        "
      >
        <CoursesWrapperView
          :semester_amount="
            egress_profile.semester_amount == '' ||
            egress_profile.semester_amount == null
              ? 0
              : egress_profile.semester_amount
          "
          :egress_profile_id="egress_profile_id"
          :allows_crud="allows_crud"
          :matrix2_view="false"
          @list_courses_wrapper="updateCoursesWrapperView"
        ></CoursesWrapperView>
      </template>
      <template
        v-if="
          egressProfileMatters.filter(
            (x) =>
              x.plan_level > egress_profile.semester_amount &&
              x.egress_profile == egress_profile_id
          ).length > 0
        "
      >
        <RemoveEPMattersComponent
          v-if="user && user.is_superuser"
          :semester_amount="
            egress_profile.semester_amount == '' ||
            egress_profile.semester_amount == null
              ? 0
              : egress_profile.semester_amount
          "
          :egress_profile_id="egress_profile_id"
          :allows_crud="allows_crud"
          :matrix2_view="false"
          @list_matters="updateRemoveEPMattersComponent"
        >
        </RemoveEPMattersComponent>
      </template>
      <div class="d-flex">
        <div style="margin-left: 0; margin-right: auto">
          <b-button
            size="sm"
            class="ml-3"
            v-b-tooltip.v-danger.noninteractive="
              `Al cancelar, la duración del ${$getVisibleNames(
                'mesh.egressprofile',
                false,
                'Perfil de Egreso'
              )} volverá a su estado original.`
            "
            @click="$bvModal.hide(`modal-change-ep-matter-coruse-wrapper`)"
          >
            Cancelar
          </b-button>
        </div>
        <div style="margin-left: auto; margin-right: 0">
          <template
            v-if="
              list_courses_wrapper.filter(
                (x) =>
                  (x.init_level > egress_profile.semester_amount ||
                    x.end_level < x.init_level ||
                    x.end_level > egress_profile.semester_amount ||
                    x.init_level <= 0 ||
                    x.end_semester <= 0 ||
                    x.init_level == '' ||
                    x.end_level == '') &&
                  x.egress_profile == egress_profile_id
              ).length == 0 &&
              list_matters.filter(
                (x) =>
                  (x.plan_level > egress_profile.semester_amount ||
                    x.plan_level == '' ||
                    x.plan_level <= 0) &&
                  x.egress_profile == egress_profile_id
              ).length == 0
            "
          >
            <b-button
              class="mr-1"
              size="sm"
              @click="removeMattersAndCoursesWrapper()"
              >Aplicar cambios</b-button
            >
          </template>
          <template v-else>
            <b-button class="mr-1" size="sm" disabled>Aplicar cambios</b-button>
          </template>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="egressprofile-file-modal"
      :title="`Anexos`"
      size="xl"
      hide-footer
    >
      <EgressProfileFileView
        :egress_profile_id="egress_profile_id"
        :allows_crud="allows_crud"
        @closeModal="$bvModal.hide('egressprofile-file-modal')"
      ></EgressProfileFileView>
    </b-modal>
    <template
      v-if="
        allows_crud &&
        ((user_position &&
          [1, 2, 3].includes(user_position.position) &&
          [1, 2, 3].includes(user.groups[0])) ||
          user.is_superuser ||
          $hasObjectPermission('mesh.career', 'change', egress_profile.career))
      "
    >
      <CycleModal
        @ok="slotUpdateCycles"
        :selected_ids="egress_profile.cycles"
        :egress_profile="egress_profile"
      ></CycleModal>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "EgressProfile",
  components: {
    vSelect,
    // ResizeAuto: () => import("@/components/reusable/ResizeAuto"),
    EgressProfileCompetenceMention: () =>
      import("./EgressProfileCompetenceMention"),
    EgressProfileFileView: () => import("./EgressProfileFileView"),
    RaBase: () => import("@/components/reusable/RaBase"),
    SelectedCompetence: () =>
      import("@/components/mesh/EgressProfile/SelectedCompetence"),
    DescriptorsContainer: () =>
      import("@/components/reusable/DescriptorsContainer"),
    CompetenceForm: () =>
      import("../CurricularResources/Competence/CompetenceForm"),
    draggable: () => import("vuedraggable"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    CycleModal: () =>
      import("@/components/mesh/CurricularResources/Cycle/CycleModal"),
    CoursesWrapperView: () =>
      import("@/components/mesh/Matrix2/CoursesWrapper/CoursesWrapperView"),
    RemoveEPMattersComponent: () =>
      import("@/components/mesh/Matrix2/RemoveEPMattersComponent"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      egress_profile: {
        id: generateUniqueId(),
        description: "",
        academic_degree: "",
        professional_title: "",
        code: null,
        modality: null,
        init_date: null,
        end_date: null,
        semester_amount: 12,
        active: true,
        egress_profile_type: 1,
        career: null,
        campuses: [],
        cycles: [],
      },
      list_matters: [],
      remove_matters: [],
      list_courses_wrapper: [],
      remove_courses_wrapper: [],
      egress_profile_code_list: [],
      selected_competences: [],
      selected_campuses: [],
      // created_competences: [],
      egress_profile_types: [],
      errors: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      institution: "getInstitution",
      campuses: names.CAMPUSES,
      careers: names.CAREERS,
      faculties: names.FACULTIES,
      competenceTypes: names.COMPETENCE_TYPES,
      competences: names.COMPETENCES,
      profile_competences: names.PROFILE_COMPETENCES,
      profile_cycles: names.PROFILE_CYCLES,
      cycles: names.CYCLES,
      ra_bases: names.RA_BASES,
      modalities_egress_profiles: names.MODALITIES_EGRESS_PROFILE,
      egress_profile_descriptors: names.EGRESS_PROFILE_DESCRIPTORS,
      ep_middle_descriptors: names.EP_MIDDLE_DESCRIPTORS,
      matters: names.MATTERS,
      egress_profiles: names.EGRESS_PROFILES,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      courses_wrapper: names.COURSES_WRAPPER,
      profile_competences_mention: names.PROFILE_COMPETENCES_MENTION,
    }),
    career() {
      if (!this.egress_profile) return [];
      return this.careers.find((x) => x.id == this.egress_profile.career);
    },
    faculty() {
      if (!this.career) return null;
      return this.faculties.find((x) => x.id == this.career.faculty);
    },
    epMiddleDescriptors() {
      return this.ep_middle_descriptors
        .filter((x) => x.egress_profile == this.egress_profile_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    egressProfileDescriptor() {
      const ep_middle = this.epMiddleDescriptors.map((x) => x.descriptor);
      return this.egress_profile_descriptors.filter(
        (x) => x.is_default || ep_middle.includes(x.id)
      );
    },
    lengthEgressProfileDescriptor() {
      let count = 6;
      this.epMiddleDescriptors.forEach((x) => {
        if (x.egress_profile == this.egress_profile_id) {
          let profile_descriptors = this.egress_profile_descriptors.find(
            (a) => x.descriptor == a.id
          );
          if (
            profile_descriptors &&
            profile_descriptors.page_position == 1 &&
            x.is_visible == true
          ) {
            if (profile_descriptors.is_default || x.description.trim() != 0) {
              count = x.order;
            }
          }
        }
      });
      count += 1;
      return count;
    },
    lengthEgressProfileDescriptor2() {
      let count = this.lengthEgressProfileDescriptor;
      this.epMiddleDescriptors.forEach((x) => {
        if (x.egress_profile == this.egress_profile_id) {
          let profile_descriptors = this.egress_profile_descriptors.find(
            (a) => x.descriptor == a.id
          );
          if (
            profile_descriptors &&
            profile_descriptors.page_position == 2 &&
            x.is_visible == true
          )
            if (profile_descriptors.is_default || x.description.trim() != 0)
              count = x.order;
        }
      });
      count += 1;
      return count;
    },
    profileCiclesList() {
      if (!this.egress_profile_id) return null;
      let profileCycles = this.profile_cycles.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
      return profileCycles
        ? profileCycles.sort(function (a, b) {
            if (a.init_semester < b.init_semester) return -1;
            if (a.init_semester > b.init_semester) return 1;
            if (a.end_semester < b.end_semester) return -1;
            if (a.end_semester > b.end_semester) return 1;
            return 0;
          })
        : [];
    },
    competenceDescriptor() {
      const competence_list = this.profileCompetencesList.filter(
        (x) => x.descriptions.length > 0
      );
      let distinct = [];
      competence_list.forEach((competence) => {
        competence.descriptions.forEach((descriptor) => {
          if (
            distinct.filter(
              (x) => x.key == "descriptor_" + descriptor.descriptor__id
            ).length == 0
          )
            distinct.push({
              key: "descriptor_" + descriptor.descriptor__id,
              label: descriptor.descriptor__title,
              display_column: true,
              thStyle: { background: "var(--primary-color) !important" },
            });
        });
      });
      return distinct;
    },
    profileCompetencesList() {
      let list = [];
      let profileCompetences = this.profile_competences.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
      profileCompetences.forEach((profile_competence) => {
        if (profile_competence) {
          let get_competence = this.competences.find(
            (x) => x.id == profile_competence.competence
          );
          if (get_competence) {
            const orden_type = this.getOrderType(get_competence.type);
            list.push({
              id: profile_competence.id,
              competence: profile_competence.competence,
              order: profile_competence.order,
              egress_profile: profile_competence.egress_profile,
              cycle: profile_competence.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              full_sentence: get_competence.full_sentence,
              descriptions: get_competence.descriptions,
              // can_edit: get_competence.created_by,
              type: get_competence.type,
              orden_type: orden_type,
              verb: get_competence.verb,
              sentence: get_competence.sentence,
              name: get_competence.name,
            });
          }
        }
      });
      if (this.egressProfileCycleId.length > 0)
        return list.filter((x) => x.cycle == this.egressCycleId);
      return list.filter((x) => x.cycle == null);
    },
    suggestedOrder() {
      if (!this.competencesList) return 1;
      return this.competencesList.length + 1;
    },
    modalityProfile() {
      if (!this.egress_profile) return "N/A";
      if (this.egress_profile.modality == null) return "N/A";
      else {
        const modality = this.modalities_egress_profiles.find(
          (x) => x.id == this.egress_profile.modality
        );
        return modality ? modality.name : "N/A";
      }
    },
    selectedCampuses() {
      let list = [];
      if (!this.faculty) return list;
      this.selected_campuses.forEach((campus_id) => {
        if (this.faculty.campuses.includes(campus_id)) {
          const instance = this.campuses.find((x) => x.id == campus_id);
          if (instance) list.push(instance);
        }
      });
      return list;
    },
    campusListFiltered() {
      let list = [];
      if (!this.faculty) return list;
      this.faculty.campuses.forEach((campus_id) => {
        const instance = this.campuses.find((x) => x.id == campus_id);
        if (instance) list.push(instance);
      });
      return list;
    },
    egressProfileCycles() {
      if (!this.egress_profile && !this.profileCiclesList) return [];
      let cycles = [];
      this.profileCiclesList.forEach((cycle_id) => {
        let cycle = this.cycles.find((x) => x.id == cycle_id.cycle);
        if (cycle) cycles.push(cycle);
      });
      return cycles;
    },
    egressProfileCycleId() {
      if (!this.egress_profile_id) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egress_profile_id) list.push(x.cycle);
      });
      return list;
    },
    egressCycleId() {
      if (!this.egress_profile && !this.profileCiclesList) return 1;
      let id = null;
      let order = 1;
      this.profileCiclesList.forEach((element) => {
        if (
          element.order >= order &&
          this.egressProfileCycleId.includes(element.cycle)
        ) {
          order = element.order;
          id = element.cycle;
        }
      });
      return id;
    },
    egressProfileType() {
      if (this.career) {
        const type = this.egress_profile_types.find(
          (x) => x.id == this.career.egress_profile_type
        );
        return type ? type.name : "";
      } else return "";
    },
    competencesList: {
      // TODO: Revisar cuidadosamente.
      get() {
        let list = this.profileCompetencesList;
        // list.push(...this.created_competences);
        list.sort(function (a, b) {
          if (a.orden_type < b.orden_type) return -1;
          if (a.orden_type > b.orden_type) return 1;
          if (a.type < b.type) return -1;
          if (a.type > b.type) return 1;
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              let payload = {
                profile_competence_id: element.id,
                item: {
                  order: element.order,
                },
              };
              this.$store.dispatch(names.PATCH_PROFILE_COMPETENCE, payload);
            }
          }
          order += 1;
        });
      },
    },
  },
  methods: {
    getOrderType(id) {
      const type = this.competenceTypes.find((x) => x.id == id);
      return type ? type.order : 10000;
    },
    stateEPInitDate(init_date = this.egress_profile.init_date) {
      if (
        (init_date > 1900 &&
          init_date < parseInt(new Date().getFullYear()) + 30) ||
        init_date == "" ||
        init_date == null
      )
        return true;
      else return false;
    },
    slotUpdateProfileCompetence(profile_competence_ids) {
      this.selected_competences = profile_competence_ids;
    },
    changeCompetenceDescriptor(competence) {
      this.$store.dispatch(names.PATCH_COMPETENCE, {
        competence_id: competence.competence,
      });
    },
    resetCompetenceTable() {
      this.$store.dispatch(names.FETCH_COMPETENCES);
    },
    firsCompetenceType(competence) {
      const first = this.competencesList.find((x) => x.type == competence.type);
      return first.id == competence.id;
    },
    hideModal() {
      this.selected_competences = [];
    },
    raBasesFiltered(comptence_id) {
      if (!comptence_id) return [];
      return this.ra_bases.filter((x) => x.competences.includes(comptence_id));
    },
    saveProfileCompetence() {
      let order = this.suggestedOrder;
      this.selected_competences.forEach((x) => {
        this.$store.dispatch(names.POST_PROFILE_COMPETENCE, {
          egress_profile: this.egress_profile_id,
          competence: x,
          cycle: this.egressCycleId,
          order: order,
        });
        order += 1;
      });
      this.$bvModal.hide(`selected-competence-modal`);
    },
    orderState(competence) {
      if (!competence) return false;
      const comp_temp = this.profile_competences.find(
        (x) => x.id == competence.id
      );
      if (comp_temp) {
        const profile_competence = this.profile_competences.filter(
          (x) =>
            x.egress_profile == comp_temp.egress_profile &&
            x.order == comp_temp.order &&
            x.cycle == comp_temp.cycle
        );
        if (profile_competence.length > 1) return true;
      }
      return false;
    },
    deleteTitle(id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el título descriptivo?",
        text: `"Esta acción no se podrá revertir y podria afectar a otros ${this.$getVisibleNames(
          "mesh.egressprofile",
          true,
          "Perfiles de Egreso"
        )}."`,
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_PROFILE_DESCRIPTORS, id)
            .then(() =>
              this.$store.dispatch(names.FETCH_EP_MIDDLE_DESCRIPTORS)
            );
        }
      });
    },
    deleteProfileDescriptors(EpMiddleDescriptor) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el título descriptivo?",
        text: `"Esta acción no se podrá revertir y podria afectar a otros ${this.$getVisibleNames(
          "mesh.egressprofile",
          true,
          "Perfiles de Egreso"
        )}."`,
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_EP_MIDDLE_DESCRIPTORS, EpMiddleDescriptor.id)
            .then(() => {
              this.$store
                .dispatch(
                  names.DELETE_PROFILE_DESCRIPTORS,
                  EpMiddleDescriptor.descriptor
                )
                .then(() =>
                  this.$store.dispatch(names.FETCH_EP_MIDDLE_DESCRIPTORS)
                );
            });
        }
      });
    },
    createProfileDescriptors(title) {
      if (isNaN(title.id)) {
        this.$store
          .dispatch(names.POST_PROFILE_DESCRIPTORS, title)
          .then((response) => {
            this.createEpMiddleDescriptors("", response);
          });
      }
    },
    updateProfileDescriptors(title) {
      if (!isNaN(title.id)) {
        let payload = {
          id: title.id,
          item: {
            title: title.title,
            is_default: title.is_default,
          },
        };
        this.$store.dispatch(names.PATCH_PROFILE_DESCRIPTORS, payload);
      }
    },
    createEpMiddleDescriptors(text, object) {
      if (!isNaN(object.id) && this.egress_profile_id) {
        let item = {
          egress_profile: this.egress_profile_id,
          descriptor: object.id,
          description: text,
          order:
            object.page_position == 1
              ? this.lengthEgressProfileDescriptor
              : this.lengthEgressProfileDescriptor2,
        };
        this.$store.dispatch(names.POST_EP_MIDDLE_DESCRIPTORS, item);
      }
    },
    updateEpMiddleDescriptors(object, text) {
      if (!isNaN(object.id)) {
        let payload = {
          id: object.id,
          item: {
            description: text,
          },
        };
        this.$store.dispatch(names.PATCH_EP_MIDDLE_DESCRIPTORS, payload);
      }
    },
    updateVisibleEpMiddleDescriptors(item) {
      if (!isNaN(item.id)) {
        let payload = {
          id: item.id,
          item: {
            is_visible: !item.is_visible,
          },
        };
        this.$store.dispatch(names.PATCH_EP_MIDDLE_DESCRIPTORS, payload);
      }
    },
    updateOrderEpMiddleDescriptors(item) {
      item.forEach((element) => {
        if (
          !isNaN(element.id) &&
          this.ep_middle_descriptors.findIndex((x) => x.id == element.id) != -1
        ) {
          let payload = {
            id: element.id,
            item: {
              order: element.order,
            },
          };
          this.$store.dispatch(names.PATCH_EP_MIDDLE_DESCRIPTORS, payload);
        }
      });
    },
    slotUpdateCycles() {
      // let competences = this.profile_competences.filter(
      //   (x) => x.egress_profile == this.egress_profile_id
      // );
      // if (competences.length > 0) {
      //   this.$swal({
      //     title: "¿Está seguro de que desea cambiar los ciclos?",
      //     text: `"Al cambiar se eliminarán ${competences.length} competencias asignadas a este perfil. Esta acción no se podrá revertir!"`,
      //     type: "warning",
      //     showCancelButton: true,
      //   }).then((result) => {
      //     if (result.value) {
      //       competences.forEach((x) => {
      //         this.$store.dispatch(names.DELETE_PROFILE_COMPETENCE, x.id);
      //       });
      //       let payload = {
      //         egress_profile_id: this.egress_profile_id,
      //         item: {
      //           cycles: cycle_ids,
      //         },
      //       };
      //       this.patchEgressProfile(payload);
      //     }
      //   });
      // } else {
      //   let payload = {
      //     egress_profile_id: this.egress_profile_id,
      //     item: {
      //       cycles: cycle_ids,
      //     },
      //   };
      //   this.patchEgressProfile(payload);
      // }
    },
    launchCycleModal() {
      this.$bvModal.show("modal-select-cycle");
    },
    // slotCreateCompetence(competence) {
    //   this.created_competences.push(competence);
    //   this.$bvModal.hide(`new-competence-modal-${competence.type}`);
    // },
    changeEPMatterAndCourseWrapper() {
      if (
        this.egressProfileMatters.filter(
          (x) =>
            x.plan_level > this.egress_profile.semester_amount &&
            x.egress_profile == this.egress_profile_id
        ).length > 0 ||
        this.courses_wrapper.filter(
          (x) =>
            x.init_level > this.egress_profile.semester_amount &&
            x.egress_profile == this.egress_profile_id
        ).length > 0
      ) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.egress_profile.semester_amount >= 0)
            this.$bvModal.show("modal-change-ep-matter-coruse-wrapper");
        }, 3000);
      } else this.saveProfile();
    },
    returnSemesterAmount() {
      if (
        this.egressProfileMatters.filter(
          (x) =>
            x.plan_level > this.egress_profile.semester_amount &&
            x.egress_profile == this.egress_profile_id
        ).length > 0 ||
        this.courses_wrapper.filter(
          (x) =>
            x.init_level > this.egress_profile.semester_amount &&
            x.egress_profile == this.egress_profile_id
        ).length > 0
      ) {
        this.egress_profile.semester_amount =
          this.egress_profiles.find((x) => x.id == this.egress_profile.id)
            .semester_amount || 1;
      }
    },
    removeMattersAndCoursesWrapper() {
      this.$swal({
        title: `¿Está seguro de aplicar los cambios?`,
        width: "auto",
        text: `Esta acción no se podrá revertir.`,
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          // this.saveMatterAndCoursesWrapper();
          this.$restful
            .Post(`/mesh/edit_or_delete_course_or_epm/`, {
              egress_profile_matters_to_change: this.list_matters.map((x) => ({
                id: x.id,
                plan_level: x.plan_level,
              })),
              course_wrappers_to_change: this.list_courses_wrapper.map((x) => ({
                id: x.id,
                init_level: x.init_level,
                end_level: x.end_level,
              })),
              egress_profile_matters_to_delete: this.remove_matters.map(
                (x) => x.id
              ),
              course_wrappers_to_delete: this.remove_courses_wrapper.map(
                (x) => x.id
              ),
            })
            .then(() => {
              this.list_matters.forEach((x) => {
                this.$store.commit(names.MUTATE_EGRESS_PROFILE_MATTER, x);
              });
              this.remove_matters.forEach((x) => {
                this.$store.commit(
                  names.MUTATE_DELETE_EGRESS_PROFILE_MATTER,
                  x.id
                );
              });
              this.list_courses_wrapper.forEach((x) => {
                this.$store.commit(names.MUTATE_COURSE_WRAPPER, x);
              });
              this.remove_courses_wrapper.forEach((x) => {
                this.$store.commit(names.MUTATE_DELETE_COURSE_WRAPPER, x.id);
              });
              this.saveProfile();
              this.$bvModal.hide("modal-change-ep-matter-coruse-wrapper");
            });
        }
      });
    },
    // async saveMatterAndCoursesWrapper() {
    //   for await (let ep_matter of this.remove_matters) {
    //     await this.$store.dispatch(
    //       names.DELETE_EGRESS_PROFILE_MATTER,
    //       ep_matter.id
    //     );
    //   }
    //   for await (let course_wrapper of this.remove_courses_wrapper) {
    //     await this.$store.dispatch(
    //       names.DELETE_COURSE_WRAPPER,
    //       course_wrapper.id
    //     );
    //   }
    //   for await (let ep_matter of this.list_matters) {
    //     await this.$store.dispatch(names.PATCH_EGRESS_PROFILE_MATTER, {
    //       egress_profile_matter_id: ep_matter.id,
    //       item: { plan_level: ep_matter.plan_level },
    //     });
    //   }
    //   for await (let course_wrapper of this.list_courses_wrapper) {
    //     await this.$store.dispatch(names.PATCH_COURSE_WRAPPER, {
    //       course_wrapper_id: course_wrapper.id,
    //       item: {
    //         init_level: course_wrapper.init_level,
    //         end_level: course_wrapper.end_level,
    //       },
    //     });
    //   }
    //   this.saveProfile();
    //   this.$bvModal.hide("modal-change-ep-matter-coruse-wrapper");
    // },
    updateRemoveEPMattersComponent(list_matters, remove_matters) {
      this.list_matters = list_matters;
      this.remove_matters = remove_matters;
    },
    updateCoursesWrapperView(list_courses_wrapper, remove_courses_wrapper) {
      this.list_courses_wrapper = list_courses_wrapper;
      this.remove_courses_wrapper = remove_courses_wrapper;
    },
    saveProfile() {
      if (this.timeout) clearTimeout(this.timeout);
      if (this.institution && !this.institution.show_ep_full_init_date) {
        if (this.stateEPInitDate())
          this.timeout = setTimeout(() => {
            this.updateEgressProfile();
          }, 2000);
      } else
        this.timeout = setTimeout(() => {
          this.updateEgressProfile();
        }, 2000);
    },
    updateEgressProfile() {
      if (!this.validate()) return;
      if (
        this.egress_profile_code_list.find(
          (x) =>
            this.$equals(x.code, this.egress_profile.code) &&
            x.id != this.egress_profile.id
        )
      ) {
        return;
      } else {
        // if (this.selected_campuses.length < 1) {
        // this.$swal({
        //   title: `"Debe seleccionar al menos una ${this.$getVisibleNames(
        //     "mesh.campus",
        //     false,
        //     "Sede"
        //   )}."`,
        //   type: "warning",
        // });
        // } else if (this.selected_campuses) {
        this.egress_profile.campuses = this.selected_campuses;
        let payload = { ...this.egress_profile };
        if (this.institution && !this.institution.show_ep_full_init_date)
          if (
            this.egress_profile.init_date != "" &&
            this.egress_profile.init_date != null
          )
            payload.init_date =
              String(this.egress_profile.init_date) + "-01-01";
          else if (this.egress_profile.init_date == "")
            payload.init_date = null;
        this.$store
          .dispatch(names.UPDATE_EGRESS_PROFILE, payload)
          .then((response) => {
            response.campuses = this.selected_campuses;
            // if(response){
            //   this.patchEgressProfile(response);
            // }
            toast(
              String(
                this.$getVisibleNames(
                  "mesh.egressprofile",
                  false,
                  "Perfil de Egreso"
                ) + " actualizado."
              )
            );
          });
        // }
      }
    },
    // patchEgressProfile(payload) {
    //   this.$store
    //     .dispatch(names.PATCH_EGRESS_PROFILE, payload)
    //     .then((egress_profile) => {
    //       this.egress_profile = egress_profile;
    //       toast(
    //         String(
    //           this.$getVisibleNames(
    //             "mesh.egressprofile",
    //             false,
    //             "Perfil de Egreso"
    //           ) + " actualizado."
    //         )
    //       );
    //     });
    // },
    askForDeleteCompetence(egress_profile_competence_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar la asociación de esta instancia de "${this.$getVisibleNames(
          "mesh.competence",
          false,
          "Competencia"
        )} en el ${this.$getVisibleNames(
          "mesh.egressprofile",
          false,
          "Perfil de Egreso"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              names.DELETE_PROFILE_COMPETENCE,
              egress_profile_competence_id
            )
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.competence",
                    false,
                    "Competencia"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    validate() {
      this.errors = [];
      if (this.egress_profile.academic_degree.trim().length == 0)
        this.errors.push(
          String(
            "El " +
              this.$getVisibleNames(
                "manual.grado_academico",
                false,
                "Grado Académico"
              ) +
              " no puede estar vacío."
          )
        );
      if (this.egress_profile.professional_title.trim().length == 0)
        this.errors.push(
          String(
            "El " +
              this.$getVisibleNames(
                "manual.mesh.titulo_profesional",
                false,
                "Título Profesional"
              ) +
              " no puede estar vacío."
          )
        );
      if (this.egress_profile.semester_amount <= 0)
        this.errors.push(
          "Debe definir una cantidad de Duración con un valor numérico."
        );
      if (this.errors.length > 0) return false;
      return true;
    },
    fetchEgressProfileTypes() {
      this.$restful
        .Get(`/mesh/egress-profile-type/`)
        .then((egress_profile_types) => {
          this.egress_profile_types = egress_profile_types;
        });
    },
  },
  created() {
    this.$restful.Get(`/mesh/egress_profile_code_list/`).then((response) => {
      this.egress_profile_code_list = response;
    });
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(
      names.FETCH_PROFILE_DESCRIPTORS,
      this.egress_profile_id
    );
    this.$store.dispatch(
      names.FETCH_EP_MIDDLE_DESCRIPTORS,
      this.egress_profile_id
    );
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES_MENTION, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_CYCLES);
    this.$store.dispatch(names.FETCH_COMPETENCE_TYPES);
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_RA_BASES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_COMPETENCES);
    this.$store.dispatch(names.FETCH_FORMATION_AREAS);
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id)
      .then((egress_profile) => {
        // career=${egress_profile.career}&
        this.selected_campuses = egress_profile.campuses;
        this.$store.dispatch(names.FETCH_CAREER, egress_profile.career);
        this.egress_profile = { ...egress_profile };
        if (
          !isNaN(this.egress_profile.id) &&
          this.institution &&
          !this.institution.show_ep_full_init_date
        )
          if (this.egress_profile && this.egress_profile.init_date != null)
            this.egress_profile.init_date = parseInt(
              String(this.egress_profile.init_date).substr(0, 4)
            );
      });
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_MODALITIES_EGRESS_PROFILE);
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.fetchEgressProfileTypes();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
table thead >>> th,
.competences-table >>> .custom-footer {
  background-color: var(--secondary-color) !important;
}
.competences-table >>> th,
.header-top {
  background: var(--primary-color) !important;
}
.v-select-class >>> .vs__selected {
  background-color: #99c9d8 !important;
  color: #fff !important;
}
.row-secondary-color {
  background: var(--secondary-color) !important;
}
th {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
  font-weight: normal;
}
span {
  margin-left: -8px !important;
}
p {
  text-align: justify;
  margin-left: 2px;
  padding-left: 12px;
  margin-right: 2px;
  padding-right: 12px;
  font-size: var(--secondary-font-size);
}
textarea {
  font-size: var(--secondary-font-size);
  height: 7rem;
}
input {
  font-size: var(--secondary-font-size);
  padding-top: 1px;
  padding-bottom: 1px;
}
td[colspan="5"] {
  max-width: 35rem;
}
td {
  vertical-align: middle;
  text-align: center;
}
.table-responsive {
  overflow-x: hidden;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.error-p {
  color: red;
  text-align: center;
  font-size: var(--thirth-font-size);
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  width: auto;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
.campus-scroll {
  overflow-y: auto;
  max-height: 10rem;
}
.competences-empty {
  transition-delay: 500ms;
}
.secondary-color >>> .dropdown-menu.show {
  display: block;
  z-index: 1 !important;
}
.page-break {
  page-break-before: auto !important;
  page-break-after: auto !important;
  page-break-inside: avoid !important;
}
.button-competence {
  margin-right: 0;
  margin-left: auto;
}
.button-wrap {
  display: flex;
}
@media (max-width: 800px) {
  .button-wrap {
    display: block;
  }
  .edit-button {
    margin-right: 0.5rem;
  }
}
.pointer-class {
  cursor: pointer;
}
.ra-competences-container {
  max-height: 500px;
  overflow-y: auto;
}
.grabbing * {
  cursor: grabbing !important;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
@media print {
  .badge {
    border: none;
  }
  .campus-scroll {
    overflow-y: hidden;
    /* max-height: 100%; */
  }
}
</style>
