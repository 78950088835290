var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('b-breadcrumb',{staticClass:"breadcrumb pb-2 pt-1 noprint",attrs:{"items":_vm.breadcrumb_items}}),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud && _vm.user.is_superuser)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('teaching.add_basera'),expression:"'teaching.add_basera'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-ra_base-modal`)}}},[_vm._v(" Crear "+_vm._s(_vm.$getVisibleNames("teaching.basera", false, "RA Base"))+" ")]):_vm._e()])]),_c('h3',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("teaching.basera", false, "RA Base"))+" ")]),_c('GenericBTable',{attrs:{"items":_vm.ra_bases,"pagination":_vm.ra_bases.length,"fields":_vm.ra_bases_fields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"columns_display":true,"view_filter_by":true,"allows_crud":_vm.allows_crud,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"emitChangeFilterOn":_vm.changeFilterOn,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(egress_profile)",fn:function(row){return [_c('div',{staticClass:"profiles-container"},[_vm._l((_vm.getEgressProfileCompetence(
              row.item.competences
            )),function(profiles){return [_c('div',{key:profiles.id,staticClass:"profiles-content"},[_vm._v(" "+_vm._s(profiles.professional_title)+" "),_c('p',[_c('strong',[_vm._v(" "+_vm._s(profiles.order)+" "+_vm._s(profiles.cycle_order != "" ? "." + profiles.cycle_order : "")+" ")])])])]})],2)]}},{key:"cell(full_sentence)",fn:function(row){return [_c('SentenceContainer',{attrs:{"Sentence":row.item}})]}},{key:"cell(competences)",fn:function(row){return _vm._l((row.item.competences),function(competence){return _c('div',{key:'id:' + row.item.id + ',competence:' + competence},[_c('SentenceContainer',{attrs:{"Sentence":_vm.getCompetence(competence)}})],1)})}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Editar ${_vm.$getVisibleNames('teaching.basera', false, 'RA Base')}`
          ),expression:"\n            `Editar ${$getVisibleNames('teaching.basera', false, 'RA Base')}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.editModal(row.item)}}}),(_vm.allows_crud && _vm.user.is_superuser)?_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Eliminar ${_vm.$getVisibleNames(
              'teaching.basera',
              false,
              'RA Base'
            )}`
          ),expression:"\n            `Eliminar ${$getVisibleNames(\n              'teaching.basera',\n              false,\n              'RA Base'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteRABase(row.item.id)}}}):_vm._e(),_c('b-modal',{attrs:{"id":`edit-ra_base-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'teaching.basera',
            false,
            'RA Base'
          )}`,"size":"xl","hide-footer":""},on:{"hide":() => {
              _vm.selected_competences = [];
            }}},[_c('b-form-group',{staticClass:"mb-4",attrs:{"label":_vm.$getVisibleNames('mesh.competence', true, 'Competencia'),"label-for":"sort-by-select","label-cols-sm":"2","label-align-sm":"left","label-size":"sm"}},[_c('v-select',{attrs:{"id":"input-selected_competences","options":_vm.competences,"reduce":(competences) => competences.id,"placeholder":`${
                _vm.competences.length > 0
                  ? String('Seleccione una o varias')
                  : String(
                      'No cuenta con ' +
                        _vm.$getVisibleNames(
                          'mesh.competence',
                          true,
                          'Competencia'
                        ).toLowerCase()
                    )
              }`,"dropdown-should-open":_vm.dropdownShouldOpen,"multiple":"","label":"full_sentence","track-by":"id","selectable":(option) => !_vm.selected_competences.includes(option.id)},on:{"change":_vm.forceRerender},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(`${option.full_sentence}`),expression:"`${option.full_sentence}`",modifiers:{"v-secondary":true}}],staticClass:"text-break",on:{"click":(e) => e.target.classList.toggle('text-break')}},[_vm._v(" "+_vm._s(option.full_sentence)+" ")])]}},{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontraron resultados para: \""),_c('em',[_vm._v(_vm._s(search))]),_vm._v("\" ")]:_vm._e()]}}],null,true),model:{value:(_vm.selected_competences),callback:function ($$v) {_vm.selected_competences=$$v},expression:"selected_competences"}})],1),_c('RaBaseForm',{key:_vm.componentKey,attrs:{"RABase":row.item,"competences_ids":_vm.selected_competences},on:{"updated":function($event){return _vm.$bvModal.hide(`edit-ra_base-modal-${row.item.id}`)}}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-ra_base-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames('teaching.basera', false, 'RA Base')}`,"size":"xl"},on:{"hide":() => {
        _vm.selected_competences = [];
      }}},[_c('b-form-group',{staticClass:"mb-4",attrs:{"label":_vm.$getVisibleNames('mesh.competence', true, 'Competencia'),"label-for":"sort-by-select","label-cols-sm":"2","label-align-sm":"left","label-size":"sm"}},[_c('v-select',{attrs:{"id":"input-selected_competences2","options":_vm.competences,"reduce":(competences) => competences.id,"selectable":(option) => !_vm.selected_competences.includes(option.id),"placeholder":`${
          _vm.competences.length > 0
            ? String('Seleccione una o varias')
            : String(
                'No cuenta con ' +
                  _vm.$getVisibleNames(
                    'mesh.competence',
                    true,
                    'Competencia'
                  ).toLowerCase()
              )
        }`,"dropdown-should-open":_vm.dropdownShouldOpen,"multiple":"","label":"full_sentence","track-by":"id"},on:{"change":_vm.forceRerender},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(`${option.full_sentence}`),expression:"`${option.full_sentence}`",modifiers:{"v-secondary":true}}],staticClass:"text-break",on:{"click":(e) => e.target.classList.toggle('text-break')}},[_vm._v(" "+_vm._s(option.full_sentence)+" ")])]}},{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontraron resultados para: \""),_c('em',[_vm._v(_vm._s(search))]),_vm._v("\" ")]:_vm._e()]}}]),model:{value:(_vm.selected_competences),callback:function ($$v) {_vm.selected_competences=$$v},expression:"selected_competences"}})],1),_c('RaBaseForm',{key:_vm.componentKey,attrs:{"competences_ids":_vm.selected_competences},on:{"created":function($event){return _vm.$bvModal.hide(`new-ra_base-modal`)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }