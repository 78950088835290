var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":"Nombre:","label-for":"input-name","label-cols":"0","label-cols-sm":"4"}},[_c('b-form-input',{staticClass:"input-name",attrs:{"id":"input-name","name":"input-name","state":_vm.validateState('name'),"aria-describedby":"input-name-live-feedback","size":"sm"},model:{value:(_vm.$v.commune.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.commune.name, "$model", $$v)},expression:"$v.commune.name.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-live-feedback"}},[_vm._v("Este campo debe tener al menos 3 caracteres.")])],1),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":"Orden:","label-for":"input-order","label-cols":"0","label-cols-sm":"4"}},[_c('b-form-input',{staticClass:"input-order",attrs:{"id":"input-order","name":"input-order","type":"number","state":_vm.validateState('order'),"aria-describedby":"input-order-live-feedback","size":"sm"},model:{value:(_vm.$v.commune.order.$model),callback:function ($$v) {_vm.$set(_vm.$v.commune.order, "$model", $$v)},expression:"$v.commune.order.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-order-live-feedback"}},[_vm._v("Este campo debe ser numérico y mayor a 0")])],1),(_vm.countries.length > 1)?_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`${_vm.$getVisibleNames('common.country', false, 'País')}:`,"label-for":"select-country","label-cols":"0","label-cols-sm":"4"}},[_c('v-select',{attrs:{"id":"select-country","options":_vm.countries,"reduce":(countries) => countries.id,"state":_vm.validateCountry(),"placeholder":`${
        _vm.countries.length > 0
          ? String(
              'Seleccione una ' +
                _vm.$getVisibleNames(
                  'common.country',
                  false,
                  'País'
                ).toLowerCase()
            )
          : String(
              'No cuenta con ' +
                _vm.$getVisibleNames(
                  'common.country',
                  true,
                  'Países'
                ).toLowerCase() +
                ' creados'
            )
      }`,"label":"name","track-by":"id","size":"sm"},on:{"input":(value) => (_vm.commune.region = null)},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontró resultados para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_vm._e()]}}],null,false,738677531),model:{value:(_vm.$v.selected_country.$model),callback:function ($$v) {_vm.$set(_vm.$v.selected_country, "$model", $$v)},expression:"$v.selected_country.$model"}}),(_vm.$v.selected_country.$anyError == true)?_c('div',{staticClass:"div-invalid-feedback"},[_vm._v(" Debe seleccionar "+_vm._s(_vm.$getVisibleNames("common.country", false, "País"))+". ")]):_vm._e()],1):_vm._e(),(_vm.selected_country || _vm.countries.length == 1)?_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`${_vm.$getVisibleNames('common.region', false, 'Región')}:`,"label-for":"select-region","label-cols":"0","label-cols-sm":"4"}},[_c('v-select',{attrs:{"id":"select-region","options":_vm.filterRegions,"reduce":(filterRegions) => filterRegions.id,"state":_vm.validateState('region'),"placeholder":`${
        _vm.filterRegions.length > 0
          ? String(
              'Seleccione una ' +
                _vm.$getVisibleNames(
                  'common.region',
                  false,
                  'Región'
                ).toLowerCase()
            )
          : String(
              'No cuenta con ' +
                _vm.$getVisibleNames(
                  'common.region',
                  true,
                  'Regiones'
                ).toLowerCase() +
                ' creadas'
            )
      }`,"label":"name","track-by":"id","size":"sm"},on:{"input":(value) =>
          (_vm.commune.order =
            1 + _vm.communes.filter((x) => x.region == value).length)},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontró resultados para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_vm._e()]}}],null,false,738677531),model:{value:(_vm.$v.commune.region.$model),callback:function ($$v) {_vm.$set(_vm.$v.commune.region, "$model", $$v)},expression:"$v.commune.region.$model"}}),(_vm.$v.commune.region.$anyError == true && _vm.commune.region == null)?_c('div',{staticClass:"div-invalid-feedback"},[_vm._v(" Debe seleccionar "+_vm._s(_vm.$getVisibleNames("common.region", false, "Región"))+". ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"row mt-3"},[(_vm.show_delete_button && !isNaN(this.commune.id))?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.askForDeleteObject}},[_vm._v("Eliminar")])],1):_vm._e(),(_vm.show_save_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"m-0 mr-1",attrs:{"size":"sm"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }