<template>
  <tr v-if="matter && old_matter">
    <!-- Número de Asignatura -->
    <td>
      <slot
        ><template v-if="allows_crud && !matter.is_closed">
          <b-form-input
            :id="`order-${matter.id}`"
            type="number"
            min="1"
            max="100"
            v-bind:class="{
              'border-color':
                matter.order < 1 || matter.order > 100 || SameMatterOrder,
            }"
            v-model.number="matter.order"
            class="inputNumber"
          ></b-form-input>
          <b-tooltip
            :target="`order-${matter.id}`"
            variant="secondary"
            :noninteractive="true"
            v-if="matter.order < 1 || matter.order > 100 || SameMatterOrder"
          >
            <div v-if="matter.order < 1 || matter.order > 100">
              Los valores deben estar entre 1 ~ 100.
            </div>
            <div v-if="SameMatterOrder">
              Dos o más
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                )
              }}
              cuentan con el mismo número.
            </div>
          </b-tooltip>
        </template>
        <template v-else>{{ matter.order }}</template></slot
      >
      <!-- TODO: Cambiar índice por número de asignatura -->
    </td>
    <!-- Nombre de Asignatura -->
    <td
      class="matter-td"
      :style="{
        'background-color': matterFormationLine + '!important',
      }"
    >
      <div class="matter-name text-left ml-1">
        <div class="matter-link">
          <b-link
            :id="`matter-link-${matter.id}`"
            :to="{
              name: 'MatterProgramView',
              params: { matter_id: matter.id },
            }"
            class="d-flex"
            :style="{
              color: $lightOrDark(matterFormationLine) ? '#2b2a2a' : 'white',
            }"
          >
            <div class="w-100">
              {{ old_matter.name }}
              <!-- {{ getMatterName(matter.matter) }} -->
            </div>
            <!-- <template
              v-if="
                allows_crud &&
                user &&
                user.is_superuser &&
                matter.use_matter_values
              "
            >
              <template v-if="old_matter.is_closed">
                <b-icon
                  v-b-tooltip.v-secondary.noninteractive="
                    `La ${$getVisibleNames(
                      'teaching.matter',
                      false,
                      'Asignatura Base'
                    )} está cerrada.`
                  "
                  icon="lock-fill"
                  scale="1"
                ></b-icon>
              </template>
              <template v-else>
                <b-icon
                  icon="unlock-fill"
                  v-b-tooltip.v-secondary.noninteractive="
                    `La ${$getVisibleNames(
                      'teaching.matter',
                      false,
                      'Asignatura Base'
                    )} está abierta.`
                  "
                  scale="1"
                ></b-icon>
              </template>
            </template> -->
            <!-- <template v-if="allows_crud">
              <template v-if="matter.is_closed">
                <b-icon
                  v-b-tooltip.v-secondary.noninteractive="
                    `La ${$getVisibleNames(
                      'mesh.egressprofilematter',
                      false,
                      'Asignatura'
                    )} está cerrada.`
                  "
                  icon="lock"
                  scale="1"
                ></b-icon>
              </template>
              <template v-else>
                <b-icon
                  icon="unlock"
                  v-b-tooltip.v-secondary.noninteractive="
                    `La ${$getVisibleNames(
                      'mesh.egressprofilematter',
                      false,
                      'Asignatura'
                    )} está abierta.`
                  "
                  scale="1"
                ></b-icon>
              </template>
            </template> -->
          </b-link>
          <!-- <template v-if="matter.modalities.length">
            <b-badge
              :id="`modalities-badge-${matter.id}`"
              class="modalities-badge"
              variant="secondary"
            >
              {{ matter.modalities ? matter.modalities.length : "" }}
            </b-badge>
            <ModalitiesPopover
              :object="matter"
              :content="matterModalities(matter.modalities)"
              :triggers="'hover'"
            ></ModalitiesPopover>
          </template> -->
        </div>
        <b-tooltip
          :target="`matter-link-${matter.id}`"
          variant="secondary"
          placement="right"
          :boundary-padding="100"
          noninteractive
        >
          <b-icon icon="box-arrow-up-right"></b-icon> Ir al
          {{
            $getVisibleNames(
              "manual.matter_program",
              false,
              "Programa de Asignatura"
            )
          }}
        </b-tooltip>
      </div>
    </td>
    <!-- Prerrequisitos -->
    <td
      v-if="
        !allows_crud || matter.is_closed || prerequisitesOptions.length == 0
      "
      :id="`prerequisite-label-${matter.id}`"
    >
      <div class="campus-container">
        <div
          v-for="matter in prerequisites"
          :key="matter.id"
          class="campus-div"
          :style="{
            'background-color': matterFormationLine,
            color: $lightOrDark(matterFormationLine) ? '#2b2a2a' : 'white',
          }"
        >
          {{ matter.order }}
        </div>
      </div>
      <PrerequisitesPopover
        v-if="prerequisites.length > 0"
        :object="matter"
        :content="prerequisitesNames"
        :placement="'top'"
        :triggers="'hover'"
        :title="'Requisitos'"
      >
      </PrerequisitesPopover>
    </td>
    <td
      v-else
      class="prerequisites-div"
      @click="
        $bvModal.show(
          `modal-prerequisites-${matter.id}-${prerequisites_modal_id}`
        )
      "
    >
      <div class="campus-container">
        <div
          v-for="matter in prerequisites"
          :key="matter.id"
          class="campus-div"
          :style="{
            'background-color': matterFormationLine,
            color: $lightOrDark(matterFormationLine) ? '#2b2a2a' : 'white',
          }"
        >
          {{ matter.order }}
        </div>
      </div>

      <b-modal
        :id="`modal-prerequisites-${matter.id}-${prerequisites_modal_id}`"
        title="Seleccione los Prerrequisitos"
        hide-footer
        @hide="resetModalPrerequisites"
      >
        <b-form-select
          v-if="matters"
          v-model="prerequisites_in_process"
          :options="prerequisitesOptions"
          text-field="label"
          value-field="id"
          :state="!isValidPrerequisites"
          aria-describedby="select-prerequisites-feedback"
          :multiple="true"
          :select-size="
            prerequisitesOptions.length <= 5
              ? prerequisitesOptions.length + 1
              : 15
          "
        >
          <template v-slot:first>
            <b-form-select-option :value="null"
              >-- No seleccionar --</b-form-select-option
            >
          </template>
        </b-form-select>
        <b-form-invalid-feedback id="select-prerequisites-feedback"
          >Para marcar una o más
          {{
            $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas")
          }}
          quites de su selección (-- No seleccionar
          --).</b-form-invalid-feedback
        >
        <b-button class="btn-save mt-2" size="sm" @click="savePrerequisites"
          >Guardar</b-button
        >
      </b-modal>
    </td>
    <!-- Régimen Semestral o Anual -->
    <td>
      <template v-if="allows_crud && !matter.is_closed">
        <b-form-select
          v-model="matter.regime"
          :options="regimesList"
          text-field="name"
          value-field="id"
        ></b-form-select>
      </template>
      <template v-else-if="regime">{{ regime.name }}</template>
    </td>
    <!-- Semanas -->
    <td>
      <template
        v-if="
          allows_crud &&
          ((matter.use_matter_values && !old_matter.is_closed) ||
            (!matter.use_matter_values && !matter.is_closed))
        "
      >
        <template v-if="matter.use_matter_values">
          <b-form-input
            :id="`Weeks-old-matter-${old_matter.id}`"
            type="number"
            min="1"
            max="52"
            v-bind:class="{
              'border-color': old_matter.weeks < 1 || old_matter.weeks > 52,
            }"
            v-model.number="old_matter.weeks"
            class="inputNumber"
          ></b-form-input>
          <b-tooltip
            :target="`Weeks-old-matter-${old_matter.id}`"
            variant="secondary"
            v-if="old_matter.weeks < 1 || old_matter.weeks > 52"
          >
            Los valores deben estar entre 1 ~ 52
          </b-tooltip>
        </template>
        <template v-else>
          <b-form-input
            :id="`Weeks-${matter.id}`"
            type="number"
            min="1"
            max="52"
            v-bind:class="{
              'border-color': matter.weeks < 1 || matter.weeks > 52,
            }"
            v-model.number="matter.weeks"
            class="inputNumber"
          ></b-form-input>
          <b-tooltip
            :target="`Weeks-${matter.id}`"
            variant="secondary"
            v-if="matter.weeks < 1 || matter.weeks > 52"
          >
            Los valores deben estar entre 1 ~ 52
          </b-tooltip>
        </template>
      </template>
      <template v-else>
        <template v-if="matter.use_matter_values">
          <template v-if="isAnual && matter.regime == isAnual.id">
            {{ (old_matter.weeks / 2) | Round }}
          </template>
          <template v-else>
            {{ old_matter.weeks }}
          </template>
        </template>
        <template v-else>
          <template v-if="isAnual && matter.regime == isAnual.id">
            {{ (matter.weeks / 2) | Round }} </template
          ><template v-else>
            {{ matter.weeks }}
          </template>
        </template>
      </template>
    </td>
    <!-- Horas -->
    <td
      v-for="time in time_allocations.filter(
        (x) =>
          x.uses_module_minutes == true &&
          this.egress_profile_time_allocations.includes(x.id)
      )"
      :key="time.id"
    >
      <template
        v-if="
          allows_crud &&
          ((matter.use_matter_values && !old_matter.is_closed) ||
            (!matter.use_matter_values && !matter.is_closed))
        "
      >
        <template v-if="matter.use_matter_values">
          <InputMTimeAllocation
            :TimeAllocation="time"
            :OldMatter="old_matter"
            @changed_hour="slotUpdatedCredits"
          ></InputMTimeAllocation>
        </template>
        <template v-else>
          <InputEPMTimeAllocation
            :TimeAllocation="time"
            :Matter="matter"
            @changed_hour="slotUpdatedCredits"
          ></InputEPMTimeAllocation>
        </template>
      </template>
      <template v-else>
        <div class="d-flex" style="align-items: center">
          <template v-if="matter.use_matter_values">
            <div class="w-100">
              {{
                matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id && x.matter == old_matter.id
                )
                  ? matter.regime == isAnual.id
                    ? Math.round(
                        matter_time_allocations.find(
                          (x) =>
                            x.time_allocation == time.id &&
                            x.matter == old_matter.id
                        ).hours / 2
                      )
                    : matter_time_allocations.find(
                        (x) =>
                          x.time_allocation == time.id &&
                          x.matter == old_matter.id
                      ).hours
                  : 0
              }}
            </div>
            <template
              v-if="
                matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id && x.matter == old_matter.id
                ) &&
                !matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id && x.matter == old_matter.id
                ).counts_towards_credits
              "
            >
              <div
                v-b-tooltip.v-secondary.noninteractive="
                  'Estas horas no cuentan para el total'
                "
                class="m-0 p-0"
                style="font-size: 1.3rem"
              >
                *
              </div>
            </template>
          </template>
          <template v-else>
            <div class="w-100">
              {{
                ep_matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id &&
                    x.egress_profile_matter == matter.id
                )
                  ? matter.regime == isAnual.id
                    ? Math.round(
                        ep_matter_time_allocations.find(
                          (x) =>
                            x.time_allocation == time.id &&
                            x.egress_profile_matter == matter.id
                        ).hours / 2
                      )
                    : ep_matter_time_allocations.find(
                        (x) =>
                          x.time_allocation == time.id &&
                          x.egress_profile_matter == matter.id
                      ).hours
                  : 0
              }}
            </div>
            <template
              v-if="
                ep_matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id &&
                    x.egress_profile_matter == matter.id
                ) &&
                !ep_matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id &&
                    x.egress_profile_matter == matter.id
                ).counts_towards_credits
              "
            >
              <div
                v-b-tooltip.v-secondary.noninteractive="
                  'Estas horas no cuentan para el total'
                "
                class="m-0 p-0"
                style="font-size: 1.3rem"
              >
                *
              </div>
            </template>
          </template>
        </div>
      </template>
    </td>
    <td>
      {{ totalPedagogical }}
    </td>
    <td v-if="profileType">
      {{
        (totalPedagogical * (parseFloat(profileType.module_minutes) / 60))
          | Round
      }}
    </td>
    <td
      v-for="time in time_allocations.filter(
        (x) =>
          x.uses_module_minutes == false &&
          this.egress_profile_time_allocations.includes(x.id)
      )"
      :key="time.id"
    >
      <template
        v-if="
          allows_crud &&
          ((matter.use_matter_values && !old_matter.is_closed) ||
            (!matter.use_matter_values && !matter.is_closed))
        "
      >
        <template v-if="matter.use_matter_values">
          <InputMTimeAllocation
            :TimeAllocation="time"
            :OldMatter="old_matter"
            @changed_hour="slotUpdatedCredits"
          ></InputMTimeAllocation>
        </template>
        <template v-else>
          <InputEPMTimeAllocation
            :TimeAllocation="time"
            :Matter="matter"
            @changed_hour="slotUpdatedCredits"
          ></InputEPMTimeAllocation>
        </template>
      </template>
      <template v-else>
        <div class="d-flex" style="align-items: center">
          <template v-if="matter.use_matter_values">
            <div class="w-100">
              {{
                matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id && x.matter == old_matter.id
                )
                  ? matter.regime == isAnual.id
                    ? Math.round(
                        matter_time_allocations.find(
                          (x) =>
                            x.time_allocation == time.id &&
                            x.matter == old_matter.id
                        ).hours / 2
                      )
                    : matter_time_allocations.find(
                        (x) =>
                          x.time_allocation == time.id &&
                          x.matter == old_matter.id
                      ).hours
                  : 0
              }}
            </div>
            <template
              v-if="
                matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id && x.matter == old_matter.id
                ) &&
                !matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id && x.matter == old_matter.id
                ).counts_towards_credits
              "
            >
              <div
                v-b-tooltip.v-secondary.noninteractive="
                  'Estas horas no cuentan para el total'
                "
                class="m-0 p-0"
                style="font-size: 1.3rem"
              >
                *
              </div>
            </template>
          </template>
          <template v-else>
            <div class="w-100">
              {{
                ep_matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id &&
                    x.egress_profile_matter == matter.id
                )
                  ? matter.regime == isAnual.id
                    ? Math.round(
                        ep_matter_time_allocations.find(
                          (x) =>
                            x.time_allocation == time.id &&
                            x.egress_profile_matter == matter.id
                        ).hours / 2
                      )
                    : ep_matter_time_allocations.find(
                        (x) =>
                          x.time_allocation == time.id &&
                          x.egress_profile_matter == matter.id
                      ).hours
                  : 0
              }}
            </div>
            <template
              v-if="
                ep_matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id &&
                    x.egress_profile_matter == matter.id
                ) &&
                !ep_matter_time_allocations.find(
                  (x) =>
                    x.time_allocation == time.id &&
                    x.egress_profile_matter == matter.id
                ).counts_towards_credits
              "
            >
              <div
                v-b-tooltip.v-secondary.noninteractive="
                  'Estas horas no cuentan para el total'
                "
                class="m-0 p-0"
                style="font-size: 1.3rem"
              >
                *
              </div>
            </template>
          </template>
        </div>
      </template>
    </td>
    <td>
      {{ totalChronological | Round }}
    </td>
    <td>
      {{ totalHours | Round }}
    </td>
    <!-- credits -->
    <td>
      <div v-if="profileType">
        <div
          v-if="
            allows_crud &&
            ((matter.use_matter_values && !old_matter.is_closed) ||
              (!matter.use_matter_values && !matter.is_closed))
          "
          class="d-flex justify-content-center"
        >
          <div
            class="mr-2"
            style="margin-top: 0.82em"
            v-b-tooltip.v-secondary.top.noninteractive="'Créditos Automáticos'"
          >
            {{ creditSctChile }}
          </div>
          <template v-if="matter.use_matter_values">
            <b-form-input
              type="number"
              min="0"
              v-model.number="old_matter.credits"
              v-b-tooltip.v-secondary.top.noninteractive="
                'Créditos Planificados'
              "
              class="inputNumber mt-1"
            ></b-form-input>
          </template>
          <template v-else>
            <b-form-input
              type="number"
              min="0"
              v-model.number="matter.credits"
              v-b-tooltip.v-secondary.top.noninteractive="
                'Créditos Planificados'
              "
              class="inputNumber mt-1"
            ></b-form-input>
          </template>
          <template
            v-if="
              (matter.use_matter_values && !old_matter.use_automatic_credits) ||
              (!matter.use_matter_values && !matter.use_automatic_credits)
            "
          >
            <button
              class="btn-select-default ml-2"
              v-b-tooltip.v-secondary.noninteractive="
                'Copiar la información del crédito automático'
              "
              @click="copyCredits"
            >
              <b-icon font-scale="1.5" icon="clipboard-check"></b-icon>
            </button>
          </template>
          <template v-else>
            <div
              class="mt-1"
              v-b-tooltip.v-secondary.noninteractive="
                'Al cambiar las horas los créditos cambiaran de forma automática'
              "
            >
              <button class="btn-select-default ml-2" disabled>
                <b-icon font-scale="1.5" icon="clipboard-check"></b-icon>
              </button>
            </div>
          </template>
        </div>
        <div v-else>
          <span v-if="matter.use_matter_values">
            {{
              matter.regime == isAnual.id
                ? Math.round(old_matter.credits / 2)
                : old_matter.credits
            }}
          </span>
          <span v-else>
            {{
              matter.regime == isAnual.id
                ? Math.round(matter.credits / 2)
                : matter.credits
            }}
          </span>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "MatterRow",
  components: {
    PrerequisitesPopover: () =>
      import("@/components/reusable/PrerequisitesPopover"),
    InputMTimeAllocation: () => import("./InputMTimeAllocation.vue"),
    InputEPMTimeAllocation: () => import("./InputEPMTimeAllocation.vue"),
    // ModalitiesPopover: () => import("@/components/reusable/ModalitiesPopover"),
  },
  props: {
    Matter: {
      type: Object,
      requerid: true,
    },
    OldMatter: {
      type: Object,
      requerid: true,
    },
    egress_profile_time_allocations: {
      type: Array,
      default: () => [],
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    egress_profile_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      matter: this.Matter,
      old_matter: this.OldMatter,
      prerequisites_modal_id: generateUniqueId(),
      prerequisites_in_process: this.Matter.prerequisites,
      default_bg_color: "#fff",
    };
  },
  computed: {
    ...mapGetters({
      egressProfiles: names.EGRESS_PROFILES,
      profile_types: names.PROFILE_TYPES,
      matters: names.MATTERS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      regimes: names.REGIMES,
      user: "getUser",
      school: "getInstitution",
      formation_lines: names.FORMATION_LINES,
      modalities: names.MODALITIES,
      time_allocations: names.TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      careers: names.CAREERS,
    }),
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    profileType() {
      if (!this.egressProfile) return null;
      return this.profile_types.find(
        (x) => x.id == this.egressProfile.egress_profile_type
      );
    },
    isAnual() {
      const months_amount_egress_profile = this.regimes.find(
        (x) => x.id == this.egressProfile.regime
      );
      if (months_amount_egress_profile) {
        if (months_amount_egress_profile.months_amount == 6)
          return this.regimes.find(
            (x) =>
              x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
          );
        else if (months_amount_egress_profile.months_amount == 3)
          return this.regimes.find(
            (x) =>
              x.name.includes("Semestral") || parseInt(x.months_amount) / 3 == 2
          );
        else if (months_amount_egress_profile.months_amount == 2)
          return this.regimes.find(
            (x) =>
              x.name.includes("Trimestral") || parseInt(x.months_amount) == 3
          );
      }
      return this.regimes.find(
        (x) => x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
      );
    },
    matterFormationLine() {
      if (!this.Matter) return this.default_bg_color;
      const formation_line = this.formation_lines.find(
        (x) =>
          x.id == this.Matter.formation_line &&
          x.faculties.includes(this.career.faculty)
      );
      if (formation_line) return formation_line.color;
      else return this.default_bg_color;
    },
    totalPedagogical() {
      let hours = 0;
      const pedagogical_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == true)
        .map((x) => x.id);
      let matter_hours = [];
      if (this.matter.use_matter_values) {
        matter_hours = this.matter_time_allocations.filter(
          (x) =>
            x.matter == this.matter.matter &&
            pedagogical_hours_ids.includes(x.time_allocation) &&
            x.counts_towards_credits == true
        );
      } else {
        matter_hours = this.ep_matter_time_allocations.filter(
          (x) =>
            x.egress_profile_matter == this.matter.id &&
            pedagogical_hours_ids.includes(x.time_allocation) &&
            x.counts_towards_credits == true
        );
      }
      matter_hours.forEach((hour) => {
        if (
          this.isAnual &&
          this.matter.regime == this.isAnual.id &&
          !this.allows_crud
        )
          hours += parseFloat(hour.hours) / 2;
        else hours += parseFloat(hour.hours);
      });
      return hours;
    },
    totalChronological() {
      let hours = 0;
      const chronological_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == false)
        .map((x) => x.id);
      let matter_hours = [];
      if (this.matter.use_matter_values) {
        matter_hours = this.matter_time_allocations.filter(
          (x) =>
            x.matter == this.matter.matter &&
            chronological_hours_ids.includes(x.time_allocation) &&
            x.counts_towards_credits == true
        );
      } else {
        matter_hours = this.ep_matter_time_allocations.filter(
          (x) =>
            x.egress_profile_matter == this.matter.id &&
            chronological_hours_ids.includes(x.time_allocation) &&
            x.counts_towards_credits == true
        );
      }
      matter_hours.forEach((hour) => {
        if (this.matter.regime == this.isAnual.id && !this.allows_crud)
          hours += parseFloat(hour.hours) / 2;
        else hours += parseFloat(hour.hours);
      });
      return hours;
    },
    totalHours() {
      if (!this.profileType) return 0;
      const total_hours =
        this.totalPedagogical * (this.profileType.module_minutes / 60) +
        this.totalChronological;
      return total_hours ? total_hours : 0;
    },
    regimesList() {
      if (!this.egressProfile) return [];
      //Bimestral(2) - Trimestral(3) - Semestral(6)
      const months_amount_egress_profile = this.regimes.find(
        (x) => x.id == this.egressProfile.regime
      );
      if (months_amount_egress_profile) {
        if (months_amount_egress_profile.months_amount == 6)
          return this.regimes
            .filter((x) => [6, 12].includes(x.months_amount))
            .sort((a, b) => b.months_amount - a.months_amount);
        else if (months_amount_egress_profile.months_amount == 3)
          return this.regimes
            .filter((x) => [3, 6].includes(x.months_amount))
            .sort((a, b) => b.months_amount - a.months_amount);
        else if (months_amount_egress_profile.months_amount == 2)
          return this.regimes
            .filter((x) => [2, 3].includes(x.months_amount))
            .sort((a, b) => b.months_amount - a.months_amount);
      } else
        return this.regimes
          .filter((x) => [6, 12].includes(x.months_amount))
          .sort((a, b) => b.months_amount - a.months_amount);
      return [];
    },
    regime() {
      return this.regimes.find((x) => x.id == this.Matter.regime);
    },
    SameMatterOrder() {
      if (!this.matter) return false;
      const SameMatterOrder = this.egress_profiles_matters.filter(
        (matter) =>
          matter.order == this.matter.order &&
          matter.egress_profile == this.matter.egress_profile &&
          matter.career == this.matter.career
      );
      if (SameMatterOrder.length > 1) return true;
      return false;
    },
    prerequisites() {
      let names = [];
      if (!this.matter) return names;
      this.matter.prerequisites.forEach((element) => {
        let egress_profile_matter = this.egress_profiles_matters.find(
          (x) => x.id == element
        );
        // if (egress_profile_matter) {
        //   let instance = this.matters.find(
        //     (x) => x.id == egress_profile_matter.matter
        //   );
        if (egress_profile_matter) names.push(egress_profile_matter);
        // }
      });
      return names.sort((a, b) => a.order - b.order);
    },
    prerequisitesNames() {
      let list = [];
      this.matter.prerequisites.forEach((element) => {
        let egress_profile_matter = this.egress_profiles_matters.find(
          (x) => x.id == element
        );
        if (egress_profile_matter) {
          let instance = this.matters.find(
            (x) => x.id == egress_profile_matter.matter
          );

          if (instance) {
            instance["formation_line"] = egress_profile_matter.formation_line;
            instance["order"] = egress_profile_matter.order;
            list.push(instance);
          }
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;

        return 0;
      });
    },
    prerequisitesOptions() {
      let list = [];
      if (!this.matter) return list;
      list = this.egress_profiles_matters.filter(
        (x) =>
          x.egress_profile == this.matter.egress_profile &&
          x.plan_level <= this.matter.plan_level &&
          x.matter != this.matter.matter
      );
      list.forEach((x) => {
        const matter = this.matters.find((matter) => matter.id == x.matter);
        if (matter)
          x.label = String(x.order + " - " + x.code + " - " + matter.name);
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    creditSctChile() {
      if (!this.profileType) return 0;
      let hours = 0;
      hours = this.totalHours;
      return Math.round(hours / parseFloat(this.school.hours_per_credit));
    },
    isValidPrerequisites() {
      if (
        this.prerequisites_in_process.includes(null) &&
        this.prerequisites_in_process.length > 1
      )
        return true;
      return false;
    },
  },
  methods: {
    slotUpdatedCredits() {
      if (this.matter.use_matter_values) {
        if (this.old_matter.use_automatic_credits) {
          this.old_matter.credits = this.creditSctChile;
          this.patchOldMatter({
            credits: this.creditSctChile,
          });
        }
      } else {
        if (this.matter.use_automatic_credits) {
          this.matter.credits = this.creditSctChile;
          this.patchMatter({
            credits: this.creditSctChile,
          });
        }
      }
    },
    resetModalPrerequisites() {
      this.prerequisites_in_process = this.Matter.prerequisites;
    },
    savePrerequisites() {
      if (!this.isValidPrerequisites) {
        this.matter.prerequisites = this.prerequisites_in_process;
        this.$bvModal.hide(
          `modal-prerequisites-${this.matter.id}-${this.prerequisites_modal_id}`
        );
      }
    },
    copyCredits() {
      if (this.matter.use_matter_values) {
        this.old_matter.credits = this.creditSctChile;
        this.patchOldMatter({
          credits: this.creditSctChile,
        });
      } else {
        this.matter.credits = this.creditSctChile;
        this.patchMatter({
          credits: this.creditSctChile,
        });
      }
    },
    // getMatterName(matter_id) {
    //   const matter = this.matters.find((x) => x.id == matter_id);
    //   return matter ? matter.name : "";
    // },
    patchMatter(item) {
      if (!this.allows_crud) return;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$store
          .dispatch(names.PATCH_EGRESS_PROFILE_MATTER, {
            egress_profile_matter_id: this.matter.id,
            item: item,
          })
          .then((matter) => {
            this.matter = matter;
            if (item.order || item.regime) {
              this.$emit("forceUpdate");
            }
            toast(
              this.$getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              ) + " actualizada."
            );
          });
      }, 500);
    },
    patchOldMatter(item) {
      if (!this.allows_crud) return;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$store
          .dispatch(names.PATCH_MATTER, {
            matter_id: this.old_matter.id,
            item: item,
          })
          .then((old_matter) => {
            this.old_matter = old_matter;
            toast(
              this.$getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              ) + " actualizada."
            );
          });
      }, 500);
    },
    // matterModalities(modalities_ids) {
    //   return this.modalities.filter((x) => modalities_ids.includes(x.id));
    // },
  },
  watch: {
    "matter.order": function (n, o) {
      if (n == o) return;
      if (n > 100 || n < 1) return;
      this.patchMatter({
        order: this.matter.order,
      });
    },
    "matter.weeks": function (n, o) {
      if (n == o) return;
      if (n > 52 || n < 1) return;
      this.patchMatter({
        weeks: this.matter.weeks,
      });
    },
    "old_matter.weeks": function (n, o) {
      if (n == o) return;
      if (n > 52 || n < 1) return;
      if (this.matter.use_matter_values) {
        this.patchOldMatter({
          weeks: this.old_matter.weeks,
        });
      }
    },
    "matter.credits": function (n, o) {
      if (n == o) return;
      if (n < 0) return;
      this.patchMatter({
        credits: this.matter.credits,
      });
    },
    "old_matter.credits": function (n, o) {
      if (n == o) return;
      if (n < 0) return;
      if (this.matter.use_matter_values) {
        this.patchOldMatter({
          credits: this.old_matter.credits,
        });
      }
    },
    "matter.prerequisites": function (new_value, old_value) {
      let flag = true;
      for (let index = 0; index < new_value.length; index++) {
        if (index >= old_value.length || new_value[index] != old_value[index]) {
          flag = false;
          break;
        }
      }
      if (flag) return;
      let item = {
        prerequisites: this.matter.prerequisites,
      };
      if (
        this.matter.prerequisites.length == 1 &&
        this.matter.prerequisites[0] == null
      )
        item.prerequisites = [];
      this.patchMatter(item);
    },
    "matter.regime": function () {
      // if (this.matter.regime == 1 && !this.allows_crud) {
      //   console.log("No se debería mostrar.", this.matter.id);
      // }
      this.patchMatter({
        regime: this.matter.regime,
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.multiselect {
  min-width: 150px;
}
.border-color {
  border-color: red;
}
.inputNumber {
  width: 70px;
  justify-content: center;
}
td {
  width: 120px;
  border: solid black;
  border-width: thin;
  font-size: var(--secondary-font-size) !important;
  text-align: -webkit-center;
}
.table td {
  margin: 2px;
  padding: 2px;
  font-size: var(--thirth-font-size);
  vertical-align: middle;
  text-align: -moz-center;
}
input {
  padding: 1px 1px 1px 5px;
  height: 30px;
  max-width: 50px;
  font-size: var(--secondary-font-size);
}
select {
  /* padding: 1px 1px 1px 5px; */
  height: 30px;
  font-size: var(--secondary-font-size);
}
.prerequisites-div {
  cursor: pointer;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  width: auto;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  min-width: 23px;
  max-width: 23px;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.campus-container {
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.btn-select-default {
  border: none;
  background: transparent;
  font-size: 11pt;
  padding: 0;
  margin-left: 0.7rem;
}
/* .matter-name .matter-link {
  background-color: #fff;
  line-height: 1.2;
}
.matter-link > a {
  color: var(--primary-font-color) !important;
} */
.btn-save {
  display: flex;
  margin-left: auto;
  margin-right: 0;
}
.matter-link {
  min-width: 7rem;
}
.matter-link > a {
  color: #fff;
  font-size: 11pt;
}
.modalities-badge {
  font-size: 0.6rem;
  vertical-align: top;
  padding: 0.15rem 0.2rem;
  cursor: default !important;
  margin-left: 0.1rem;
}

@media print {
  .matter-td >>> a {
    text-decoration: none !important;
  }
  .modalities-badge {
    border: none !important;
  }
}
</style>