<template>
  <div>
    <br />
    <div class="search-cycle" v-if="!egress_profile_id">
      <b-input-group size="sm">
        <b-input-group-prepend is-text>
          <b-icon icon="search"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          id="search-form"
          type="search"
          v-model="input_search"
          placeholder="Buscar..."
        >
        </b-form-input>
      </b-input-group>
    </div>
    <b-table
      :items="profile_cycles_list"
      :fields="fields"
      :filter="input_search"
      :filter-function="filterCustom"
      thead-class="b-card-style text-center"
      td-class="align-middle"
      thClass="font-weight-bold"
      stacked="md"
      show-empty
      empty-text="No hay datos para mostrar."
      empty-filtered-text="No hay datos que coincidan con su búsqueda."
      small
      bordered
      borderless
      striped
      responsive
    >
      <template #cell(selected)="row">
        <b-form-checkbox
          v-model="row.item.selected"
          @input="slotProfilesSelectionChanged"
          :disabled="!isNaN(egress_profile_id)"
        >
        </b-form-checkbox>
      </template>
      <template #cell(career)="row">
        {{ getCareer(row.item.egress_profile) }}
      </template>
      <template #cell(professional_title)="row">
        <template v-if="getEgressProfile(row.item.egress_profile)">
          {{ getEgressProfile(row.item.egress_profile).professional_title }}
        </template>
      </template>
      <template #cell(academic_degree)="row">
        <template v-if="getEgressProfile(row.item.egress_profile)">
          {{ getEgressProfile(row.item.egress_profile).academic_degree }}
        </template>
      </template>
      <template #cell(init_semester)="row">
        <b-form-input
          v-if="row.item.selected"
          size="sm"
          class="m-0"
          type="number"
          v-model="row.item.init_semester"
        >
        </b-form-input>
        <b-form-input
          v-else
          size="sm"
          class="m-0"
          type="number"
          :disabled="true"
        >
        </b-form-input>
      </template>
      <template #cell(end_semester)="row">
        <b-form-input
          v-if="row.item.selected"
          size="sm"
          class="m-0"
          type="number"
          v-model="row.item.end_semester"
        >
        </b-form-input>
        <b-form-input
          v-else
          size="sm"
          class="m-0"
          type="number"
          :disabled="true"
        >
        </b-form-input>
      </template>
    </b-table>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "EgressProfileCycle",
  components: {},
  props: {
    Cycle: {
      type: Object,
    },
    egress_profile_id: {
      type: Number,
    },
    cycle_id: {
      type: Number,
    },
    init_semester: {
      default: 1,
    },
    end_semester: { default: 1 },
  },
  data() {
    return {
      profile_cycles_list: [],
      check_selected: [],
      selected_cycle: null,
      is_checked: false,
      input_search: "",
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
    }),
    fields() {
      return [
        { key: "selected", label: "", thStyle: { width: "min-content" } },
        {
          key: "career",
          label: String(
            this.$getVisibleNames("mesh.career", false, "Programa")
          ),
          thStyle: { font: "font-weight-bold" },
          sortable: true,
        },
        {
          key: "professional_title",
          label: String(
            this.$getVisibleNames(
              "manual.mesh.titulo_profesional",
              false,
              "Título Profesional"
            )
          ),
          sortable: true,
        },
        !(
          this.institution.id == 12 ||
          this.$debug_change_duoc ||
          !this.institution.show_academic_degree
        )
          ? {
              key: "academic_degree",
              label: String(
                this.$getVisibleNames(
                  "manual.grado_academico",
                  false,
                  "Grado Académico"
                )
              ),
              sortable: true,
            }
          : { key: "", label: "", display_column: false },

        {
          key: "init_semester",
          label: "Inicio",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "end_semester",
          label: "Término",
          sortable: true,
          thStyle: { width: "10%" },
        },
        // {
        //   key: "order",
        //   label: "Orden",
        //   thStyle: { width: "12%" },
        //   requires_check_selected: true,
        //   sortable: true,
        // },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(this.getCareer(row.egress_profile), this.input_search) ||
        this.$filtered(
          this.getEgressProfile(row.egress_profile).professional_title,
          this.input_search
        ) ||
        this.$filtered(
          this.getEgressProfile(row.egress_profile).academic_degree,
          this.input_search
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    fetchEgressprofileCycles() {
      this.profile_cycles_list = [];
      this.$store
        .dispatch(names.FETCH_PROFILE_CYCLES)
        .then((profile_cycles) => {
          if (!isNaN(this.egress_profile_id)) {
            const profile_cycle = profile_cycles.find(
              (x) =>
                x.egress_profile == this.egress_profile_id &&
                x.cycle == this.Cycle.id
            );
            if (profile_cycle) {
              this.profile_cycles_list.push({
                id: profile_cycle.id,
                egress_profile: profile_cycle.egress_profile,
                cycle: profile_cycle.cycle,
                init_semester: profile_cycle.init_semester,
                end_semester: profile_cycle.end_semester,
                selected: true,
              });
            } else {
              this.profile_cycles_list.push({
                id: generateUniqueId(),
                egress_profile: this.egress_profile_id,
                cycle: this.Cycle.id,
                init_semester: this.init_semester,
                end_semester: this.end_semester,
                selected: true,
              });
            }
          } else {
            this.$store
              .dispatch(names.FETCH_EGRESS_PROFILES)
              .then((egress_profiles) => {
                egress_profiles.forEach((egress_profile) => {
                  const profile_cycle = profile_cycles.find(
                    (x) =>
                      x.egress_profile == egress_profile.id &&
                      x.cycle == this.Cycle.id
                  );
                  if (profile_cycle) {
                    this.profile_cycles_list.push({
                      id: profile_cycle.id,
                      egress_profile: profile_cycle.egress_profile,
                      cycle: profile_cycle.cycle,
                      init_semester: profile_cycle.init_semester,
                      end_semester: profile_cycle.end_semester,
                      selected: true,
                    });
                  } else {
                    this.profile_cycles_list.push({
                      id: generateUniqueId(),
                      egress_profile: egress_profile.id,
                      cycle: this.Cycle.id,
                      init_semester: this.init_semester,
                      end_semester: this.end_semester,
                      selected: false,
                    });
                  }
                });
              });
          }
        });
    },
    getEgressProfile(egress_profile) {
      let get_egress_profile = this.egressProfiles.find(
        (x) => x.id == egress_profile
      );
      return get_egress_profile ? get_egress_profile : [];
    },
    getCareer(egress_profile) {
      let get_career = this.careers.find((x) =>
        x.egress_profiles.includes(egress_profile)
      );
      return get_career ? get_career.name : "";
    },
    slotProfilesSelectionChanged() {
      // Emite la señal updated junto a todas las instancias de Egressprofile_Cycle.
      // Las seleccionadas y las que no.
      this.$emit("updated", this.profile_cycles_list);
    },
    // slotCycleUpdated(cycle_id, egress_profile_competence_id) {
    //   this.profile_cycles_list.find(
    //     (x) => x.id == egress_profile_competence_id
    //   ).cycle = cycle_id;
    //   // Se llama el siguiente metodo para que el componente padre se entere que algo cambio.
    //   this.slotProfilesSelectionChanged();
    // },
  },
  created() {
    this.fetchEgressprofileCycles();
    this.$store.dispatch(names.FETCH_CYCLES);
  },
  mounted() {
    this.slotProfilesSelectionChanged();
  },
};
</script>

<style scoped>
.search-cycle {
  margin-bottom: 2em !important;
}
</style>