<template>
  <b-modal
    :id="modal_id"
    :title="`Seleccionar ${$getVisibleNames(
      'mesh.competence',
      true,
      'Competencias'
    )}`"
    @hide="hideModal"
    size="lg"
  >
    <!-- @ok="accept" -->
    <CompetenceTable
      :allows_crud="false"
      :selection_mode="true"
      :matrix2_competences="matrix2_competences"
      :selected_ids="selected_competence_ids"
      :egress_profile_id="egress_profile_id"
      @competence_changed="slotCompetenceChanged"
    ></CompetenceTable>
    <template v-slot:modal-footer>
      <b-button class="btn-cancel" size="sm" @click="$bvModal.hide(modal_id)"
        >Cancelar</b-button
      >
      <b-button size="sm" @click="accept" class="mr-3">Guardar</b-button>
    </template>
  </b-modal>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "EgressProfile",
  components: {
    CompetenceTable: () => import("./CompetenceTable.vue"),
  },
  props: {
    modal_id: {
      type: String,
      default: "modal-select-competence",
      required: false,
    },
    selected_ids: {
      type: Array,
    },
    egress_profile_id: {},
    matrix2_competences: {
      type: Array,
    },
    matter_id: {
      type: Number,
    },
  },
  data() {
    return {
      selected_competence_ids: this.selected_ids ? this.selected_ids : [],
      competences_list: [],
    };
  },
  computed: {
    ...mapGetters({
      profile_matters: names.EGRESS_PROFILES_MATTERS,
    }),
    matter() {
      return this.profile_matters.find((x) => x.id == this.matter_id);
    },
  },
  methods: {
    slotCompetenceChanged(competence_id, selected) {
      const index = this.competences_list.findIndex((x) => x == competence_id);
      if (index == -1) {
        if (selected) this.competences_list.push(competence_id);
      } else {
        if (!selected) this.competences_list.splice(index, 1);
      }
    },
    accept() {
      if (this.competences_list) {
        this.selected_competence_ids = this.competences_list;
        this.$emit("ok", this.selected_competence_ids);
        this.$bvModal.hide(this.modal_id);
      }
    },
    hideModal() {
      this.competences_list = [];
      this.selected_competence_ids.forEach((element) => {
        this.competences_list.push(element);
      });
    },
  },
  mounted() {
    this.selected_competence_ids.forEach((element) => {
      this.competences_list.push(element);
    });
  },
  created() {
    // this.$store
    //   .dispatch(names.FETCH_EGRESS_PROFILES_MATTERS)
    //   .then((this.competences_list = this.matter.competences));
  },
};
</script>

<style scoped>
.btn-cancel {
  margin-right: auto;
  margin-left: 1.2em;
}
</style>