<template>
  <div>
    <div class="mx-2">
      <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'teaching.add_basera'"
            v-if="allows_crud && user.is_superuser"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-ra_base-modal`)"
          >
            Crear
            {{ $getVisibleNames("teaching.basera", false, "RA Base") }}
          </button>
        </div>
      </div>
      <h3>
        {{ $getVisibleNames("teaching.basera", false, "RA Base") }}
      </h3>
      <GenericBTable
        :items="ra_bases"
        :pagination="ra_bases.length"
        :fields="ra_bases_fields"
        :filter="input_search"
        :filterCustom="filterCustom"
        @emitChangeSearchField="changeInputSearch"
        @emitChangeFilterOn="changeFilterOn"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :view_filter_by="true"
        :allows_crud="allows_crud"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(egress_profile)="row">
          <div class="profiles-container">
            <template
              v-for="profiles in getEgressProfileCompetence(
                row.item.competences
              )"
            >
              <div class="profiles-content" :key="profiles.id">
                {{ profiles.professional_title }}
                <p>
                  <strong>
                    {{ profiles.order }}
                    {{
                      profiles.cycle_order != ""
                        ? "." + profiles.cycle_order
                        : ""
                    }}
                  </strong>
                </p>
              </div>
            </template>
          </div>
        </template>
        <template #cell(full_sentence)="row">
          <SentenceContainer :Sentence="row.item"></SentenceContainer>
        </template>
        <template #cell(competences)="row">
          <div
            v-for="competence in row.item.competences"
            :key="'id:' + row.item.id + ',competence:' + competence"
          >
            <SentenceContainer
              :Sentence="getCompetence(competence)"
            ></SentenceContainer>
          </div>
        </template>
        <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            @click="editModal(row.item)"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames('teaching.basera', false, 'RA Base')}`
            "
          ></button-edit>
          <button-delete
            v-if="allows_crud && user.is_superuser"
            @click="askForDeleteRABase(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'teaching.basera',
                false,
                'RA Base'
              )}`
            "
          ></button-delete>
          <b-modal
            :id="`edit-ra_base-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'teaching.basera',
              false,
              'RA Base'
            )}`"
            size="xl"
            hide-footer
            @hide="
              () => {
                selected_competences = [];
              }
            "
          >
            <b-form-group
              :label="$getVisibleNames('mesh.competence', true, 'Competencia')"
              label-for="sort-by-select"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              class="mb-4"
            >
              <v-select
                id="input-selected_competences"
                v-model="selected_competences"
                :options="competences"
                @change="forceRerender"
                :reduce="(competences) => competences.id"
                :placeholder="`${
                  competences.length > 0
                    ? String('Seleccione una o varias')
                    : String(
                        'No cuenta con ' +
                          $getVisibleNames(
                            'mesh.competence',
                            true,
                            'Competencia'
                          ).toLowerCase()
                      )
                }`"
                :dropdown-should-open="dropdownShouldOpen"
                multiple
                label="full_sentence"
                track-by="id"
                :selectable="
                  (option) => !selected_competences.includes(option.id)
                "
              >
                <template slot="selected-option" slot-scope="option">
                  <div
                    class="text-break"
                    @click="(e) => e.target.classList.toggle('text-break')"
                    v-b-tooltip.v-secondary="`${option.full_sentence}`"
                  >
                    {{ option.full_sentence }}
                  </div>
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No se encontraron resultados para: "<em>{{ search }}</em
                    >"
                  </template>
                </template>
              </v-select>
            </b-form-group>
            <RaBaseForm
              :key="componentKey"
              :RABase="row.item"
              :competences_ids="selected_competences"
              @updated="$bvModal.hide(`edit-ra_base-modal-${row.item.id}`)"
            ></RaBaseForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-ra_base-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames('teaching.basera', false, 'RA Base')}`"
      size="xl"
      @hide="
        () => {
          selected_competences = [];
        }
      "
    >
      <b-form-group
        :label="$getVisibleNames('mesh.competence', true, 'Competencia')"
        label-for="sort-by-select"
        label-cols-sm="2"
        label-align-sm="left"
        label-size="sm"
        class="mb-4"
      >
        <v-select
          id="input-selected_competences2"
          v-model="selected_competences"
          :options="competences"
          @change="forceRerender"
          :reduce="(competences) => competences.id"
          :selectable="(option) => !selected_competences.includes(option.id)"
          :placeholder="`${
            competences.length > 0
              ? String('Seleccione una o varias')
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'mesh.competence',
                      true,
                      'Competencia'
                    ).toLowerCase()
                )
          }`"
          :dropdown-should-open="dropdownShouldOpen"
          multiple
          label="full_sentence"
          track-by="id"
        >
          <template slot="selected-option" slot-scope="option">
            <div
              class="text-break"
              @click="(e) => e.target.classList.toggle('text-break')"
              v-b-tooltip.v-secondary="`${option.full_sentence}`"
            >
              {{ option.full_sentence }}
            </div>
          </template>
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontraron resultados para: "<em>{{ search }}</em
              >"
            </template>
          </template>
        </v-select>
      </b-form-group>
      <RaBaseForm
        :key="componentKey"
        :competences_ids="selected_competences"
        @created="$bvModal.hide(`new-ra_base-modal`)"
      ></RaBaseForm>
    </b-modal>
  </div>
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "RaBaseView",
  components: {
    vSelect,
    RaBaseForm: () => import("@/components/reusable/RaBaseForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  data() {
    return {
      input_search: "",
      sortBy: "",
      sortDesc: "",
      selected_competences: [],
      componentKey: 0,
      filterOn: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      ra_bases: names.RA_BASES,
      competences: names.COMPETENCES,
      profileCompetences: names.PROFILE_COMPETENCES,
      profile_cycles: names.PROFILE_CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("teaching.add_basera");
      if (has_permission) return has_permission;
      else return false;
    },
    breadcrumb_items() {
      return [
        { text: "Productos Curriculares", active: true },
        {
          text: this.$getVisibleNames("teaching.basera", false, "RA Base"),
          active: true,
        },
      ];
    },
    ra_bases_fields() {
      return [
        {
          key: "full_sentence",
          label: String(
            this.$getVisibleNames("teaching.basera", true, "RA Bases")
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "egress_profile",
          label: String(
            this.$getVisibleNames("mesh.career", true, "Programas")
          ),
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "competences",
          label: String(
            this.$getVisibleNames("mesh.competence", true, "Competencias")
          ),
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    dropdownShouldOpen(VueSelect) {
      if (this.competences.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    editModal(item) {
      this.selected_competences = item.competences;
      this.$bvModal.show(`edit-ra_base-modal-${item.id}`);
    },
    changeFilterOn(filter_on) {
      this.filterOn = filter_on;
    },
    filterCustom(row) {
      if (
        (this.$filtered(row.full_sentence, this.input_search) ||
          row.competences.filter((x) =>
            this.$filtered(this.getCFullSentence(x), this.input_search)
          ).length > 0 ||
          this.profileCompetences.filter(
            (x) =>
              this.$filtered(
                this.getEgressProfile(x.egress_profile),
                this.input_search
              ) && row.competences.includes(x.competence)
          ).length > 0) &&
        this.filterOn.length == 0
      ) {
        return true;
      } else if (
        (this.$filtered(row.full_sentence, this.input_search) &&
          this.filterOn.includes("full_sentence")) ||
        (row.competences.filter((x) =>
          this.$filtered(this.getCFullSentence(x), this.input_search)
        ).length > 0 &&
          this.filterOn.includes("competences")) ||
        (this.profileCompetences.filter(
          (x) =>
            this.$filtered(
              this.getEgressProfile(x.egress_profile),
              this.input_search
            ) && row.competences.includes(x.competence)
        ).length > 0 &&
          this.filterOn.includes("egress_profile"))
      ) {
        return true;
      } else {
        return false;
      }
    },
    getCFullSentence(competence_id) {
      const competence = this.competences.find((x) => x.id == competence_id);
      return competence ? competence.full_sentence : "";
    },
    getCompetence(competence_id) {
      const competence = this.competences.find((x) => x.id == competence_id);
      return competence ? competence : null;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    askForDeleteRABase(ra_base_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "teaching.basera",
          false,
          "RA Base"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_RA_BASE, ra_base_id).then(() => {
            toast(
              String(
                this.$getVisibleNames("teaching.basera", false, "RA Base") +
                  " eliminado."
              )
            );
          });
        }
      });
    },
    getOrderCycle(cycle_id, egress_profile_id) {
      const cycle = this.profile_cycles.find(
        (x) => x.cycle == cycle_id && x.egress_profile == egress_profile_id
      );
      return cycle ? cycle.order : "";
    },
    getEgressProfile(id) {
      if (!id) return "";
      let egress_profile = this.egressProfiles.find((x) => x.id == id);
      return egress_profile ? egress_profile.professional_title : "";
    },
    getEgressProfileCompetence(competences_ids) {
      let list = [];
      const profile_competences = this.profileCompetences.filter((x) =>
        competences_ids.includes(x.competence)
      );
      profile_competences.forEach((profile_competence) => {
        profile_competence.professional_title = this.getEgressProfile(
          profile_competence.egress_profile
        );
        profile_competence.cycle_order = this.getOrderCycle(
          profile_competence.cycle,
          profile_competence.egress_profile
        );
        list.push(profile_competence);
      });
      return list;
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.$store.dispatch(names.FETCH_RA_BASES);
    this.$store.dispatch(names.FETCH_COMPETENCES);
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES);
  },
};
</script>

<style scoped>
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}

.title-header {
  padding: 0;
  margin-top: auto;
}
.search-ra_base {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
.profiles-container {
  display: flex !important;
  flex-direction: column;
}
.profiles-content {
  border-bottom: 0.1px solid var(--secondary-color);
  margin: 0 !important;
  margin-top: 0.4em !important;
  min-height: fit-content;
  min-width: fit-content;
}
.profiles-content:last-child {
  border-bottom: none;
}
.text-break {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
</style>