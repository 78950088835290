<template>
  <div>
    <div class="main-info">
      <div class="training-info">
        <h3 class="info-title">Capacitación</h3>
        <ul class="title-description mr-3">
          <li>
            <h4 class="info-title">Cursos Modalidad Sincrónica</h4>
            <p class="content-body ml-2">
              Ofrecemos a las Instituciones de Educación Superior cursos en
              temas metodológicos y didácticos para académicos de las
              Instituciones de Educación Superior, pertinentes para el contexto
              de procesos en enseñanza remota.
            </p>
          </li>
          <li>
            <h4 class="info-title mt-3">Relatores</h4>
            <p class="content-body ml-2">
              Los(as) relatores(as) son personas expertas del TEC de Monterrey,
              México, y de Chile.
            </p>
          </li>
        </ul>
      </div>
      <div class="training-courses">
        <img class="w-25" src="@/assets/curso_1_texto.png" alt="" />
        <img class="w-25" src="@/assets/curso_2_texto.png" alt="" />
        <img class="w-25" src="@/assets/curso_3_texto.png" alt="" />
        <img class="w-25" src="@/assets/curso_4_texto.png" alt="" />
        <img class="w-25" src="@/assets/curso_5_texto.png" alt="" />
        <img class="w-25" src="@/assets/curso_6_texto.png" alt="" />
        <img class="w-25" src="@/assets/curso_7_texto.png" alt="" />
      </div>
    </div>
    <h2 class="text-center info-title">
      Catálogo de cursos de capacitación, formación y desarrollo docente
    </h2>
    <div class="main-info">
      <div class="main-info-content">
        <h4>Curso: Diseño de Experiencias de Aprendizaje</h4>
        Duración: 16 horas
        <br />
        <br />

        Metodología: Mixta con sesiones sincrónicas y actividades asincrónicas.
        <br />
        <br />
        Resultado de aprendizaje: Construye itinerarios de aprendizaje a través
        del desarrollo de competencias en ambientes de aprendizaje presenciales
        y no presenciales.
        <br />
        <br />
        Criterios de evaluación:
        <ol>
          <li>
            Aplica los elementos y momentos clave de una buena clase acorde al
            contexto en donde se desarrolla el aprendizaje.
          </li>
          <li>
            Elabora el diseño de una unidad de formación basada en los elementos
            básicos de experiencia de aprendizaje para un ambiente híbrido.
          </li>
        </ol>
        <br />
        Contenido:
        <ol>
          <li>Tendencias en el diseño de una clase.</li>
          <li>Características de los alumnos.</li>
          <li>Elementos de diseño para el aprendizaje.</li>
          <li>Diseño de Experiencias de aprendizaje.</li>
        </ol>
      </div>
      <div class="main-info-picture">
        <img class="w-75 mt-1" src="@/assets/detallecurso1.png" alt="" />
      </div>
    </div>
    <div class="main-info">
      <div class="main-info-content">
        <h4>Curso: Tecnología Educativa para el aprendizaje</h4>
        Duración: 20 horas
        <br />
        <br />
        Metodología: Mixta con sesiones sincrónicas y actividades asincrónicas
        <br />
        <br />
        Resultado de aprendizaje: Aplica las tecnologías educativas para el
        diseño de sus clases que le permitan mejorar el aprendizaje de los(as)
        estudiantes.
        <br />
        <br />
        Criterios de evaluación:
        <ol>
          <li>
            Realiza una tabla comparativa de las herramientas para informar,
            crear, colaborar, interactuar y evaluar soportada con tecnología
            educativa.
          </li>
          <li>
            Diseña una unidad de formación soportada con tecnología educativa de
            alguna de sus materias para promover el aprendizaje activo.
          </li>
        </ol>
        <br />
        Contenido:
        <ol>
          <li>Tendencias en tecnologías.</li>
          <li>Tecnología educativa (TE) para el aprendizaje y la enseñanza.</li>
          <li>Evaluación de TE para el diseño de experiencias.</li>
          <li>Modelos de Integración de la TE.</li>
        </ol>
      </div>
      <div class="main-info-picture">
        <img class="w-75 mt-1" src="@/assets/detallecurso2.png" alt="" />
      </div>
    </div>
    <div class="main-info">
      <div class="main-info-content">
        <h4>Curso: Estrategias Integradoras (Pedagogía Colaborativa)</h4>
        Duración: 20 horas
        <br />
        <br />
        Metodología: Mixta con sesiones sincrónicas y actividades asincrónicas
        <br />
        <br />
        Resultado de aprendizaje: Diseña estrategias colaborativas globales, por
        nivel o por áreas disciplinarias, acorde a las características y
        particularidades de los estudiantes, que permitan contribuir al logro de
        los resultados de aprendizaje y de las competencias del perfil del
        egreso; además de construir nuestro propio conocimiento, conectar con
        pares y lograr mayor impacto en nuestro contexto del proceso formativo.
        <br />
        <br />
        Criterios de evaluación:
        <ol>
          <li>
            Presenta acciones de trabajo colaborativo, para mitigar los nudos
            críticos, considerando las lecturas previas sobre comunidades de
            aprendizaje.
          </li>
          <li>
            Diseña la estrategia de trabajo colaborativo, a partir del análisis
            del diagnóstico de entrada de los estudiantes, y las proyecciones de
            las asignaturas.
          </li>
          <li>
            Diseña la planificación integrada de actividades docentes que
            promueva un aprendizaje inclusivo, considerando el balance de carga
            de acuerdo a los lineamientos emanados de la innovación curricular
            en SCT- Chile.
          </li>
          <li>
            Proyecta vías de seguimiento y evaluación de las acciones
            colaborativas diseñadas, argumentando sus opiniones sobre la
            bibliografía del curso.
          </li>
        </ol>
        <br />
        Contenido:
        <ol>
          <li>Elementos de una red de aprendizaje profesional.</li>
          <li>Reglas de etiqueta y comunicación.</li>
          <li>Construcción e interacción en comunidades de aprendizaje.</li>
          <li>Proyectos colaborativos para la innovación.</li>
          <li>
            Proyecta vías de seguimiento y evaluación de las acciones
            colaborativas diseñadas, argumentando sus opiniones sobre la
            bibliografía del curso.
          </li>
        </ol>
      </div>
      <div class="main-info-picture">
        <img class="w-75 mt-1" src="@/assets/detallecurso3.png" alt="" />
      </div>
    </div>
    <div class="main-info">
      <div class="main-info-content">
        <h4>Curso: Retroalimentación efectiva para el Aprendizaje</h4>
        Duración: 20 horas
        <br />
        <br />
        Metodología: Mixta con sesiones sincrónicas y actividades asincrónicas
        <br />
        <br />
        Resultado de aprendizaje: Utiliza los elementos básicos de la
        retroalimentación en un plan de evaluación de Resultados de Aprendizaje
        en el contexto de la innovación curricular.
        <br />
        <br />
        Criterios de evaluación:
        <ol>
          <li>
            Identifica el modelo de feedback que utiliza normalmente al dar
            retroalimentación a sus alumnos.
          </li>
          <li>
            Diseña un formato de retroalimentación positiva basado en el Modelo
            de Hattie & Timperley.
          </li>
          <li>
            Implementa el modelo de retroalimentación diseñado y realiza una
            reflexión estructurada sobre los resultados en los dominios de
            percepción del estudiante, percepción del docente y aprendizaje.
          </li>
        </ol>
        <br />
        Contenido:
        <ol>
          <li>
            Relación contenido – estudiante – profesor durante el aprendizaje.
          </li>
          <li>Técnicas para retroalimentar el aprendizaje.</li>
          <li>Diseño del proceso de evaluación y retroalimentación.</li>
          <li>Plan de mejora para el aprendizaje del estudiante.</li>
        </ol>
      </div>
      <div class="main-info-picture">
        <img class="w-75 mt-1" src="@/assets/detallecurso4.png" alt="" />
      </div>
    </div>
    <div class="main-info">
      <div class="main-info-content">
        <h4>
          Curso: Evaluación del Aprendizaje en un Modelo basado en Competencias
        </h4>
        Duración: 15 horas
        <br />
        <br />
        Metodología: Mixta con sesiones sincrónicas y actividades asincrónicas
        <br />
        <br />
        Resultado de aprendizaje: Aplica un modelo de evaluación de desempeño,
        sus etapas, métodos y momentos clave para un adecuado seguimiento del
        desarrollo de competencias en el ambiente educativo.
        <br />
        <br />
        Criterios de evaluación:
        <ol>
          <li>Diseña un plan integral de evaluación de desempeño.</li>
          <li>Diseña los instrumentos de evaluación acorde al plan:</li>
          <ul>
            <li>Rúbrica</li>
            <li>Rubrica binaria</li>
            <li>Lista de cotejo</li>
            <li>Reflexión estructurada</li>
            <li>Escala de apreciación</li>
            <li>Registro anecdótico</li>
          </ul>
          <li>
            Implementa un proyecto de evaluación del aprendizaje en alguna de
            sus asignaturas.
          </li>
        </ol>
        <br />
        Contenido:
        <ol>
          <li>Planeación de la evaluación del aprendizaje.</li>
          <li>
            Diseño del proceso evaluativo. Momentos, técnicas, instrumentos.
          </li>
          <li>
            Implementación de la evaluación del aprendizaje. Proyecto
            integrador.
          </li>
          <li>
            Metaevaluación, proceso de mejora continua y nuevos aprendizajes.
          </li>
        </ol>
      </div>
      <div class="main-info-picture">
        <img class="w-75 mt-1" src="@/assets/detallecurso5.png" alt="" />
      </div>
    </div>
    <div class="main-info">
      <div class="main-info-content">
        <h4>
          Curso: ABP, ABPro y Aprendizaje Basado en Reto, Técnicas Didácticas
          enfocadas en Aprendizaje Activo
        </h4>
        Duración: 15 horas sincrónicas al inicio del curso. Entrega de
        evidencias de forma asíncrona 5 horas sincrónicas al finalizar el curso.
        <br />
        <br />
        Metodología: Mixta con sesiones sincrónicas y actividades asincrónicas
        <br />
        <br />
        Resultado de aprendizaje: Aplicar las tres técnicas didácticas (ABP,
        ABPro y ABR) e identificar momentos y experiencias para seleccionar la
        técnica adecuada, reconociendo las diferencias y sus similitudes.
        <br />
        <br />
        Criterios de evaluación:
        <ol>
          <li>
            Diseño de un plan de implementación de la técnica didáctica
            (ABP,ABPro o ABR) en su asignatura que incluya la experiencia
            detallada de aprendizaje usando la técnica adecuada.
          </li>
          <li>Utiliza una bitácora de observaciones y seguimiento.</li>
          <li>
            Utiliza una lista de cotejo de la implementación de la técnica.
          </li>
          <li>
            Construye una PPT de 2 filminas donde se expliquen los principales
            aprendizajes al implementar la técnica seleccionada en su curso.
          </li>
        </ol>
        <br />
        Contenido:
        <ol>
          <li>Concepto y características del ABP.</li>
          <li>Concepto y características de ABPro.</li>
          <li>Concepto y características de ABR.</li>
          <li>Selección de la mejor técnica.</li>
          <li>Diseño de la experiencia de aprendizaje.</li>
          <li>Diseño de la evaluación según la técnica.</li>
        </ol>
      </div>
      <div class="main-info-picture">
        <img class="w-75 mt-1" src="@/assets/detallecurso6.png" alt="" />
      </div>
    </div>
    <div class="main-info">
      <div class="main-info-content">
        <h4>Curso: Aprendizaje basado en Casos</h4>
        Duración: 25 horas
        <br />
        <br />
        Metodología: Mixta con sesiones sincrónicas y actividades asincrónicas
        <br />
        <br />
        Resultado de aprendizaje: Aplicar la metodología de aprendizaje basado
        en casos como técnica didáctica en sus asignaturas.
        <br />
        <br />
        Criterios de evaluación:
        <ol>
          <li>Selecciona y adopta de un caso para su asignatura.</li>
          <li>Realiza un mapa de argumentos basado en el análisis del caso.</li>
          <li>
            Utiliza un árbol de decisiones para plantear las posibilidades de
            solución.
          </li>
          <li>Diseña una presentación de solución del caso.</li>
          <li>
            Utiliza instrumentos de evaluación de competencias desarrolladas en
            el proceso de solución del caso.
          </li>
        </ol>
        <br />
        Contenido:
        <ol>
          <li>
            Fase 0. Actividades previas a la metodología. Sentando las bases.
          </li>
          <li>Fundamentos teóricos y metodológicos del Método del Caso.</li>
          <li>¿Qué es un caso? Tipos de casos.</li>
          <li>Fase 1. Preparación y análisis del caso.</li>
          <li>Discusiones iterativas y debates.</li>
          <li>Fase 2. Discusión grupal de alternativas. Pequeños grupos.</li>
          <li>Fase 3. Sesión plenaria.</li>
          <li>Fase 4. Evaluación del caso y aprendizajes esperados.</li>
        </ol>
      </div>
      <div class="main-info-picture">
        <img class="w-75 mt-1" src="@/assets/detallecurso7.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Training",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
h3 {
  text-align: left;
  font-weight: bold !important;
  font-size: 28pt;
}
h4 {
  margin-left: -1rem;
}
.title-description {
  list-style-type: none;
  text-align: justify;
}
.training-info {
  width: 60%;
}
.training-courses {
  width: 40%;
}
.content-body {
  font-size: var(--primary-font-size);
}
</style>