<template>
  <tr v-bind:class="{ 'even-row': isWeekEven(index) }">
    <td
      v-bind:style="{ cursor: allows_crud ? 'move' : '' }"
      :title="errors.length == 0 ? 'Completado' : errors.join(' ')"
    >
      {{ index + 1 }}
      <b-icon
        v-if="errors.length == 0"
        icon="check"
        variant="success"
        scale="1.5"
      ></b-icon>
      <b-icon v-else icon="exclamation-triangle" color="red" scale="1"></b-icon>
    </td>
    <!-- Orden Eliminar -->
    <!-- <td>{{ sessionDateFormated }}</td> -->
    <!-- Fecha -->
    <td
      v-bind:style="{ cursor: allows_crud ? 'pointer' : '' }"
      @click="
        allows_crud
          ? $bvModal.show(`modal-evaluation-criteria-${session_planning.id}`)
          : null
      "
    >
      <!-- <div v-if="evaluationCriteriasSelected.length > 0" class="resource-div">
        {{
          $getVisibleNames(
            "teaching.evaluationcriteriamicro",
            true,
            "Criterios de Evaluación Micro"
          )
        }}
      </div> -->
      <ul>
        <li
          v-for="criteria in evaluationCriteriasSelected"
          :key="`evaluation-criteria-p-${criteria.id}`"
        >
          {{ criteria.sentence }}
        </li>
      </ul>
      <!-- <div
        v-if="evaluationCriteriasMacroSelected.length > 0"
        class="resource-div"
      >
        {{
          $getVisibleNames(
            "teaching.evaluationcriteriamacro",
            true,
            "Criterios de Evaluación Macro"
          )
        }}
      </div> -->
      <ul v-if="institution && institution.show_evaluation_criteria_macro">
        <li
          v-for="criteria in evaluationCriteriasMacroSelected"
          :key="`evaluation-criteria-m-${criteria.id}`"
        >
          {{ criteria.full_sentence }}
        </li>
      </ul>
    </td>
    <!-- Criterio de Evaluación -->
    <td
      v-bind:style="{ cursor: allows_crud ? 'pointer' : '' }"
      @click="
        allows_crud
          ? $bvModal.show(`modal-contents-${session_planning.id}`)
          : null
      "
      class="text-left pl-2"
    >
      <div
        v-for="content in selectedContentList"
        :key="`content-p-${content.id}`"
        v-html="content.content"
      ></div>
      <template
        v-if="
          EvaluationCriteriaContents &&
          institution &&
          institution.show_evaluation_criteria_macro
        "
      >
        <div
          v-for="content in selectedEvaluationCriteriaContents"
          :key="`content-e-c-m-${content.id}`"
          v-html="content.content"
        ></div>
      </template>
    </td>
    <!-- Contenidos -->
    <td
      v-bind:style="{ cursor: allows_crud ? 'pointer' : '' }"
      @click="
        allows_crud
          ? $bvModal.show(
              `modal-methodological-strategies-${session_planning.id}`
            )
          : null
      "
    >
      <ul>
        <li
          v-for="strategy in methodologicalStrategyList"
          :key="`strategy-p-${strategy.id}`"
        >
          {{ strategy.name }}
        </li>
      </ul>
    </td>
    <!-- Estrategias Metodológicas -->
    <td
      v-bind:style="{ cursor: allows_crud ? 'pointer' : '' }"
      @click="
        allows_crud
          ? $bvModal.show(`modal-direct-activities-${session_planning.id}`)
          : null
      "
    >
      <ul>
        <li
          v-for="direct in directTeachingActivityList"
          :key="`direct-p-${direct.id}`"
        >
          {{ direct.name }}
        </li>
      </ul>
    </td>
    <!-- Actividades de Docencia Directa -->
    <td
      v-bind:style="{ cursor: allows_crud ? 'pointer' : '' }"
      @click="
        allows_crud
          ? $bvModal.show(`modal-autonomous-activities-${session_planning.id}`)
          : null
      "
    >
      <ul>
        <li
          v-for="autonomous in autonomousTeachingActivityList"
          :key="`autonomous-p-${autonomous.id}`"
        >
          {{ autonomous.name }}
        </li>
      </ul>
    </td>
    <!-- Actividades de Trabajo Autónomo -->
    <td
      v-bind:style="{ cursor: allows_crud ? 'pointer' : '' }"
      @click="
        allows_crud
          ? $bvModal.show(
              `modal-bibliographic-resources-${session_planning.id}`
            )
          : null
      "
      style="vertical-align: top; text-align: justify"
    >
      <template v-for="bibliography in bibliographicResourceList">
        <p
          :key="`bibliography-${bibliography.id}`"
          v-html="bibliography.description"
        ></p>
      </template>
      <div v-if="teachingSupportResourceList.length > 0" class="resource-div">
        Recursos de Apoyo
      </div>
      <template v-for="resource in teachingSupportResourceList">
        <p :key="`resource-${resource.id}`" v-html="resource.description"></p>
      </template>
    </td>
    <!-- Bibliografía Básica -->
    <!-- <td>
      <EvaluationCell
        v-if="!isNaN(session_planning.id)"
        :section_id="section_id"
        :SessionPlanning="session_planning"
        :allows_crud="allows_crud"
        :SessionDate="sessionDate"
      ></EvaluationCell>
    </td> -->
    <!-- Evaluaciones -->
    <!-- <td
      v-bind:style="{ cursor: allows_crud ? 'pointer' : '' }"
      @click="
        allows_crud
          ? $bvModal.show(`modal-feedbacks-${session_planning.id}`)
          : null
      "
    >
      <p
        v-for="feedback in feedbackList"
        :key="`feedback-p-${feedback.id}`"
        :title="feedback.description"
      >
        {{ feedback.name }}
      </p>
    </td> -->
    <!-- Retroalimentación -->

    <td v-if="user.is_professor && allows_crud">
      <b-button
        v-if="!isNaN(session_planning.id)"
        size="sm"
        variant="none"
        @click="$bvModal.show(`modal-observations-${session_planning.id}`)"
      >
        <b-icon icon="eye" variant="secondary"></b-icon>
      </b-button>
      <b-modal
        :title="`Observaciones de sesión ${session_planning.order}`"
        hide-footer
        :id="`modal-observations-${session_planning.id}`"
        size="xl"
      >
        <SessionObservations
          :session_id="session_planning.id"
          @saved="$bvModal.hide(`modal-observations-${session_planning.id}`)"
        ></SessionObservations>
      </b-modal>
    </td>
    <!-- Modals -->
    <!-- Modal Criterios de Evaluación e Indicadores de Desempeño -->
    <b-modal :id="`modal-evaluation-criteria-${session_planning.id}`" size="xl">
      <!-- ok-only
      @ok="
        saveSessionPlanning({
          evaluation_criterias_micro: evaluation_criterias_micro,
        })
      " -->
      <template v-slot:modal-title
        >{{
          $getVisibleNames(
            "teaching.evaluationcriteriamicro",
            true,
            "Criterios de Evaluación Micro"
          )
        }}
      </template>
      <template v-if="filterECMicro.length > 0">
        <b-form-group
          :description="`Los ${$getVisibleNames(
            'teaching.evaluationcriteriamicro',
            true,
            'Criterios de Evaluación Micro'
          )} se definen por ${$getVisibleNames(
            'teaching.ramicro',
            false,
            'RA Micro'
          )}`"
          :label="`Seleccionar ${$getVisibleNames(
            'teaching.evaluationcriteriamicro',
            true,
            'Criterios de Evaluación Micro'
          )}`"
          label-for="evaluation-criteria-select"
        >
          <b-form-select
            v-model="evaluation_criterias_micro"
            :select-size="Math.min(evaluationCriteriasToSelect.length + 2, 10)"
            size="sm"
            multiple
            class="select-form"
          >
            <b-form-select-option-group
              v-for="item in filterECMicro"
              :key="item.id"
              :label="item.name"
            >
              <b-select-option
                class="select-option-micro"
                v-for="ecm in item.options"
                :value="ecm.id"
                :key="ecm.id"
                :title="ecm.name"
              >
                {{ item.order }}.{{ ecm.order }} {{ ecm.full_sentence }}
              </b-select-option>
            </b-form-select-option-group>
          </b-form-select>
          <!-- <b-form-select
          id="evaluation-criteria-select"
          :options="evaluationCriteriasToSelect"
          value-field="id"
          text-field="full_sentence"
          v-model="evaluation_criterias_micro"
          multiple
          class="pl-1"
          :select-size="10"
        >
          <template v-slot:first>
            <b-form-select-option :value="null"
              >-- Eliminar Selección --</b-form-select-option
            >
          </template>
        </b-form-select> -->
        </b-form-group>
      </template>
      <template v-else>
        {{
          $getVisibleNames(
            "teaching.evaluationcriteriamicro",
            true,
            "Criterios de Evaluación Micro"
          )
        }}: <span>N/A</span>
      </template>
      <template
        v-if="institution && institution.show_evaluation_criteria_macro"
      >
        <template v-if="filterECMacro.length > 0">
          <b-form-group
            :label="`Seleccionar ${$getVisibleNames(
              'teaching.evaluationcriteriamacro',
              true,
              'Criterios de Evaluación Macro'
            )}:`"
            label-for="evaluation-criteria-macro-select"
          >
            <b-form-select
              id="evaluation-criteria-macro-select"
              :options="filterECMacro"
              value-field="id"
              text-field="full_sentence"
              v-model="evaluation_criterias_macro"
              multiple
              class="pl-1"
              :select-size="10"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >-- Eliminar Selección --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </template>
        <template v-else>
          {{
            $getVisibleNames(
              "teaching.evaluationcriteriamacro",
              true,
              "Criterios de Evaluación Macro"
            )
          }}: <span>N/A</span>
        </template>
      </template>
      <template v-slot:modal-footer>
        <b-button
          size="sm"
          @click="
            () => {
              saveSessionPlanning({
                evaluation_criterias_micro: evaluation_criterias_micro,
              });
              $bvModal.hide(`modal-evaluation-criteria-${session_planning.id}`);
            }
          "
          >Guardar</b-button
        >
      </template>
    </b-modal>
    <!-- Modal Contenidos -->
    <b-modal :id="`modal-contents-${session_planning.id}`" size="xl">
      <!-- ok-only
      @ok="
        saveSessionPlanning({
          contents: contents,
          evaluation_criteria_macro_contents:
            evaluation_criteria_macro_contents,
        })
      " -->
      <template v-slot:modal-title>Seleccionar Contenidos</template>
      <template v-if="studyUnitContentOptions.length > 0">
        <small>
          Contenidos en
          {{ $getVisibleNames("teaching.ramicro", false, "RA Micro") }}:
        </small>
        <b-form-select
          v-model="contents"
          multiple
          :select-size="Math.min(studyUnitContentList.length + 1, 10)"
        >
          <b-form-select-option-group
            v-for="item in studyUnitContentOptions"
            :key="item.id"
            :label="item.name"
          >
            <b-select-option
              class="select-option"
              v-for="content in item.options"
              :value="content.id"
              :key="content.id"
              :title="content.name"
              v-html="content.content"
            >
            </b-select-option>
          </b-form-select-option-group>
        </b-form-select>
      </template>
      <template v-else>
        <div>
          <small>
            Contenidos en
            {{
              $getVisibleNames("teaching.ramicro", false, "RA Micro")
            }}: </small
          >N/A
        </div>
      </template>

      <!-- <b-form-select
        value-field="id"
        text-field="content"
        v-model="contents"
        multiple
        :select-size="Math.min(studyUnitContentList.length, 10)"
      >
        <template v-for="content in studyUnitContentList">
          <b-form-select-option
            :value="content.id"
            :key="content.id"
            v-html="content.content"
          ></b-form-select-option>
        </template>
      </b-form-select> -->
      <template
        v-if="
          EvaluationCriteriaContents &&
          institution &&
          institution.show_evaluation_criteria_macro
        "
      >
        <template v-if="evaluationCriteriaContentsList.length > 0">
          <small>
            Contenidos de los
            {{
              $getVisibleNames(
                "teaching.evaluationcriteriamacro",
                true,
                "Criterios de Evaluación Macro"
              )
            }}:
          </small>
          <b-form-select
            value-field="id"
            text-field="content"
            v-model="evaluation_criteria_macro_contents"
            multiple
            :select-size="Math.min(evaluationCriteriaContentsList.length, 10)"
          >
            <template v-for="content in evaluationCriteriaContentsList">
              <b-form-select-option
                :value="content.id"
                :key="content.id"
                v-html="content.content"
              ></b-form-select-option>
            </template>
          </b-form-select>
        </template>
        <template v-else>
          <div>
            <small>
              Contenidos de los
              {{
                $getVisibleNames(
                  "teaching.evaluationcriteriamacro",
                  true,
                  "Criterios de Evaluación Macro"
                )
              }}: </small
            >N/A
          </div>
        </template>
      </template>
      <template v-slot:modal-footer>
        <b-button
          size="sm"
          @click="
            () => {
              saveSessionPlanning({
                contents: contents,
                evaluation_criteria_macro_contents:
                  evaluation_criteria_macro_contents,
              });
              $bvModal.hide(`modal-contents-${session_planning.id}`);
            }
          "
          >Guardar</b-button
        >
      </template>
    </b-modal>
    <!-- Modal Estrategias Metodológicas -->
    <b-modal
      :id="`modal-methodological-strategies-${session_planning.id}`"
      size="xl"
    >
      <!-- ok-only
      @ok="
        saveSessionPlanning({
          methodological_strategies: methodological_strategies,
        })
      " -->
      <template v-slot:modal-title
        >Seleccionar
        {{
          $getVisibleNames(
            "mesh.methodologicalstrategy",
            true,
            "Estrategias Metodológicas"
          )
        }}</template
      >
      <small>
        Se muestran
        <span style="color: var(--kl-thirth-hover-color)">coloreadas</span>
        las
        {{
          $getVisibleNames(
            "mesh.methodologicalstrategy",
            true,
            "Estrategias Metodológicas"
          )
        }}
        definidas como mínimas para la
        {{ $getVisibleNames("mesh.egressprofilematter", false, "Asignatura") }}.
      </small>
      <b-form-select
        v-model="methodological_strategies"
        multiple
        :select-size="methodologicalStrategies.length"
      >
        <b-form-select-option
          v-for="meth in matterMethodologicalStrategies"
          :value="meth.id"
          :key="meth.id"
          style="background-color: var(--kl-thirth-hover-color)"
          :title="`Estratégias Mínimas de la ${$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )}`"
          >{{ meth.name }}</b-form-select-option
        >
        <b-form-select-option
          v-for="meth in leftMethodologicalStrategies"
          :value="meth.id"
          :key="meth.id"
          >{{ meth.name }}</b-form-select-option
        >
      </b-form-select>
      <template v-slot:modal-footer>
        <b-button
          size="sm"
          @click="
            () => {
              saveSessionPlanning({
                methodological_strategies: methodological_strategies,
              });
              $bvModal.hide(
                `modal-methodological-strategies-${session_planning.id}`
              );
            }
          "
          >Guardar</b-button
        >
      </template>
    </b-modal>
    <!-- Modal Bibliografía Básica -->
    <b-modal
      :id="`modal-bibliographic-resources-${session_planning.id}`"
      size="xl"
    >
      <!-- ok-only
      @ok="
        saveSessionPlanning({
          bibliographic_resources: bibliographic_resources,
          teaching_support_resource: teaching_support_resource,
        })
      " -->
      <template v-slot:modal-title>Seleccionar Bibliografía Básica</template>
      <!-- :options="BibliographicResources" -->
      <template v-if="BibliographicResources.length > 0">
        <b-form-select
          value-field="id"
          text-field="description"
          v-model="bibliographic_resources"
          multiple
          :select-size="Math.min(BibliographicResources.length + 1, 10)"
        >
          <template v-for="bibliographic_resource in BibliographicResources">
            <b-form-select-option
              :value="bibliographic_resource.id"
              :key="bibliographic_resource.id"
              v-html="bibliographic_resource.description"
            ></b-form-select-option>
          </template>
        </b-form-select>
      </template>
      <template v-else>
        <div><small>Bibliografía Básica: </small>N/A</div>
      </template>
      <template v-if="TeachingSupportResource.length > 0">
        <b-form-group
          label="Seleccionar Recursos de Apoyo:"
          label-for="teaching-support-resource-select"
        >
          <!-- :options="TeachingSupportResource" -->
          <b-form-select
            id="teaching-support-resource-select"
            value-field="id"
            text-field="name"
            v-model="teaching_support_resource"
            multiple
            :select-size="Math.min(TeachingSupportResource.length + 2, 10)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null"
                >-- Eliminar Selección --</b-form-select-option
              >
            </template>
            <template
              v-for="teaching_support_resource in TeachingSupportResource"
            >
              <b-form-select-option
                :value="teaching_support_resource.id"
                :key="teaching_support_resource.id"
                v-html="teaching_support_resource.description"
              ></b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </template>
      <template v-else>
        <div><small>Recursos de Apoyo: </small>N/A</div>
      </template>
      <template v-slot:modal-footer>
        <b-button
          size="sm"
          @click="
            () => {
              saveSessionPlanning({
                bibliographic_resources: bibliographic_resources,
                teaching_support_resource: teaching_support_resource,
              });
              $bvModal.hide(
                `modal-bibliographic-resources-${session_planning.id}`
              );
            }
          "
          >Guardar</b-button
        >
      </template>
    </b-modal>
    <!-- Modal Retroalimentación -->
    <b-modal :id="`modal-feedbacks-${session_planning.id}`" size="xl">
      <!-- ok-only
      @ok="
        saveSessionPlanning({
          feedbacks: feedbacks,
        })
      " -->
      <template v-slot:modal-title>Seleccionar Retroalimentación</template>
      <b-form-select
        :options="evaluationFeedbacks"
        value-field="id"
        text-field="name"
        v-model="feedbacks"
        multiple
        :select-size="Math.min(evaluationFeedbacks.length, 15)"
      ></b-form-select>
      <template v-slot:modal-footer>
        <b-button
          size="sm"
          @click="
            () => {
              saveSessionPlanning({
                feedbacks: feedbacks,
              });
              $bvModal.hide(`modal-feedbacks-${session_planning.id}`);
            }
          "
          >Guardar</b-button
        >
      </template>
    </b-modal>
    <!-- Modal Actividades de Docencia Directa -->
    <b-modal :id="`modal-direct-activities-${session_planning.id}`" size="xl">
      <!-- ok-only
      @ok="
        saveSessionPlanning({
          direct_teaching_activities: direct_teaching_activities,
        })
      " -->
      <template v-slot:modal-title
        >Seleccionar Actividades de Docencia Directa</template
      >
      <b-form-select
        :options="DirectTeachingActivities"
        value-field="id"
        text-field="name"
        v-model="direct_teaching_activities"
        multiple
        :select-size="Math.min(DirectTeachingActivities.length, 15)"
      ></b-form-select>
      <template v-slot:modal-footer>
        <b-button
          size="sm"
          @click="
            () => {
              saveSessionPlanning({
                direct_teaching_activities: direct_teaching_activities,
              });
              $bvModal.hide(`modal-direct-activities-${session_planning.id}`);
            }
          "
          >Guardar</b-button
        >
      </template>
    </b-modal>
    <!-- Modal Actividades de Trabajo Autónomo -->
    <b-modal
      :id="`modal-autonomous-activities-${session_planning.id}`"
      size="xl"
    >
      <!-- ok-only
      @ok="
        saveSessionPlanning({
          autonomous_teaching_activities: autonomous_teaching_activities,
        })
      " -->
      <template v-slot:modal-title
        >Seleccionar
        {{
          $getVisibleNames(
            "mesh.autonomousteachingactivity",
            true,
            "Actividades de Trabajo Autónomo"
          )
        }}</template
      >
      <b-form-select
        :options="AutonomousTeachingActivities"
        value-field="id"
        text-field="name"
        v-model="autonomous_teaching_activities"
        multiple
        :select-size="Math.min(AutonomousTeachingActivities.length, 15)"
      ></b-form-select>
      <template v-slot:modal-footer>
        <b-button
          size="sm"
          @click="
            () => {
              saveSessionPlanning({
                autonomous_teaching_activities: autonomous_teaching_activities,
              });
              $bvModal.hide(
                `modal-autonomous-activities-${session_planning.id}`
              );
            }
          "
          >Guardar</b-button
        >
      </template>
    </b-modal>
  </tr>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import moment from "moment";

export default {
  name: "SessionRow",
  components: {
    SessionObservations: () => import("./SessionObservations"),
    // EvaluationCell: () => import("./EvaluationCell"),
  },
  props: {
    SessionPlanning: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    // study_unit_id: {
    //   type: Number,
    //   required: true,
    // },
    section_id: {
      type: Number,
      required: true,
    },
    key_label: {
      type: String,
      required: true,
    },
    new_matter_id: {
      type: Number,
      required: true,
    },
    matter_id: {
      type: Number,
      required: true,
    },
    BibliographicResources: {
      type: Array,
    },
    filter_list: Array,
    DirectTeachingActivities: Array,
    AutonomousTeachingActivities: Array,
    TeachingSupportResource: Array,
    EvaluationCriteriaContents: Array,
    // SumativeEvaluations: Array,
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      session_planning: this.SessionPlanning,
      errors: [],
      evaluation_criterias_micro:
        this.SessionPlanning.evaluation_criterias_micro,
      evaluation_criterias_macro: [],
      contents: this.SessionPlanning.contents,
      evaluation_criteria_macro_contents:
        this.SessionPlanning.evaluation_criteria_macro_contents,
      methodological_strategies: this.SessionPlanning.methodological_strategies,
      bibliographic_resources: this.SessionPlanning.bibliographic_resources,
      feedbacks: this.SessionPlanning.feedbacks,
      direct_teaching_activities:
        this.SessionPlanning.direct_teaching_activities,
      autonomous_teaching_activities:
        this.SessionPlanning.autonomous_teaching_activities,
      teaching_support_resource: this.SessionPlanning.teaching_support_resource,
      start_date: this.SessionPlanning.start_date,
      end_date: this.SessionPlanning.end_date,
      // section: this.section_id,
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      studyUnits: names.STUDY_UNITS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      sessionPlannings: names.SESSION_PLANNINGS,
      studyUnitcontents: names.CONTENTS,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
      methodologicalStrategies: names.METHODOLOGICAL_STRATEGIES,
      evaluationFeedbacks: names.EVALUATION_FEEDBACKS,
      user: "getUser",
      institution: "getInstitution",
      sessionDates: names.SESSION_DATES,
      time_allocations: names.TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
    }),
    newMatter() {
      return this.egress_profiles_matters.find(
        (x) => x.id == this.new_matter_id
      );
    },
    matter() {
      return this.matters.find((x) => x.id == this.matter_id);
    },
    filterECMacro() {
      if (!this.newMatter) return [];
      return this.evaluationCriteriasMacros
        .filter((x) => x.matter == this.newMatter.matter)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    studyUnitListMap() {
      if (!this.matter_id) return [];
      return this.studyUnits
        .filter((x) => x.matter == this.matter_id)
        .map((object) => object.id);
    },
    studyUnitsList() {
      if (!this.matter_id) return [];
      return this.studyUnits
        .filter((x) => x.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    filterECMicro() {
      let list = [];
      this.studyUnitsList.forEach((study_unit) => {
        const evaluation_criterias = this.evaluationCriterias.filter(
          (x) => x.study_unit == study_unit.id
        );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: String("N°" + study_unit.order + ": " + study_unit.name),
            order: study_unit.order,
            options: evaluation_criterias
              .filter((x) => x.study_unit == study_unit.id)
              .sort(function (a, b) {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return a.full_sentence
                  .toLowerCase()
                  .localeCompare(b.full_sentence.toLowerCase());
              }),
          });
      });
      return list;
    },
    evaluationCriteriasToSelect() {
      if (this.studyUnitListMap.length == 0) return [];
      return this.evaluationCriterias.filter((x) =>
        this.studyUnitListMap.includes(x.study_unit)
      );
    },
    evaluationCriteriasSelected() {
      let list = [];
      this.session_planning.evaluation_criterias_micro.forEach((element) => {
        let instance = this.evaluationCriterias.find((x) => x.id == element);
        if (instance) list.push(instance);
      });
      return list;
    },
    studyUnitContentList() {
      if (this.studyUnitListMap.length == 0) return [];
      return this.studyUnitcontents.filter((x) =>
        this.studyUnitListMap.includes(x.study_unit)
      );
    },
    studyUnitContentOptions() {
      let list = [];
      if (!this.matter_id) return list;
      this.studyUnitsList.forEach((study_unit) => {
        const contents = this.studyUnitcontents.filter(
          (x) => x.study_unit == study_unit.id
        );
        if (contents.length > 0)
          list.push({
            id: study_unit.id,
            name: String("N°" + study_unit.order + ": " + study_unit.name),
            order: study_unit.order,
            options: contents.sort(function (a, b) {
              return a.content
                .toLowerCase()
                .localeCompare(b.content.toLowerCase());
            }),
          });
      });
      return list;
    },
    selectedContentList() {
      let list = [];
      this.session_planning.contents.forEach((element) => {
        let instance = this.studyUnitContentList.find((x) => x.id == element);
        if (instance) list.push(instance);
      });
      return list;
    },
    evaluationCriteriaContentsList() {
      let list = [];
      if (!this.EvaluationCriteriaContents) return list;
      const macro_ids = this.filterECMacro.map((x) => x.id);
      list = this.EvaluationCriteriaContents.filter((x) =>
        macro_ids.includes(x.evaluation_criteria_macro)
      );
      return list;
    },
    selectedEvaluationCriteriaContents() {
      let list = [];
      if (!this.EvaluationCriteriaContents) return list;
      this.session_planning.evaluation_criteria_macro_contents.forEach(
        (element) => {
          let instance = this.evaluationCriteriaContentsList.find(
            (x) => x.id == element
          );
          if (instance) list.push(instance);
        }
      );
      return list;
    },
    evaluationCriteriasMacroSelected() {
      let list = [];
      let element = null;
      if (this.key_label == "matter") {
        element = this.filter_list.find(
          (x) =>
            x.session_planning == this.session_planning.id &&
            x.matter == this.matter_id
        );
      }
      if (this.key_label == "ep_matter")
        element = this.filter_list.find(
          (x) =>
            x.session_planning == this.session_planning.id &&
            x.egress_profile_matter == this.new_matter_id
        );
      if (this.key_label == "section")
        element = this.filter_list.find(
          (x) =>
            x.session_planning == this.session_planning.id &&
            x.section == this.section_id
        );
      if (element) {
        return this.filterECMacro.filter((x) =>
          element.evaluation_criteria_macro.includes(x.id)
        );
      }
      return list;
    },
    matterWeekHours() {
      if (!this.newMatter) return 0;
      let hours = 0;
      const pedagogical_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == true)
        .map((x) => x.id);
      const matter_hours = this.ep_matter_time_allocations.filter(
        (x) =>
          x.egress_profile_matter == this.newMatter.id &&
          pedagogical_hours_ids.includes(x.time_allocation) &&
          x.counts_towards_credits == true
      );
      matter_hours.forEach((hour) => {
        hours += parseFloat(hour.hours);
      });
      return Math.round(parseFloat(hours));
    },
    matterMethodologicalStrategies() {
      let list = [];
      if (!this.matter) return list;
      // let study_units = this.studyUnits.filter(
      //   (x) => x.matter == this.matter_id
      // );
      // let methodological_strategies = [];
      // study_units.forEach((x) => {
      //   methodological_strategies = methodological_strategies.concat(
      //     x.methodological_strategies
      //   );
      // });
      // methodological_strategies = [...new Set(methodological_strategies)];
      // methodological_strategies.forEach((element) => {
      //   let instance = this.methodologicalStrategies.find(
      //     (x) => x.id == element
      //   );
      //   if (instance) list.push(instance);
      // });
      this.matter.methodological_strategies.forEach((element) => {
        let instance = this.methodologicalStrategies.find(
          (x) => x.id == element
        );
        if (instance) list.push(instance);
      });
      return list.sort((a, b) => a.name.localeCompare(b.name));
    },
    leftMethodologicalStrategies() {
      let list = [];
      if (!this.matter) return list;
      // let study_units = this.studyUnits.filter(
      //   (x) => x.matter == this.matter_id
      // );
      // let methodological_strategies = [];
      // study_units.forEach((x) => {
      //   methodological_strategies = methodological_strategies.concat(
      //     x.methodological_strategies
      //   );
      // });
      // methodological_strategies = [...new Set(methodological_strategies)];
      // this.methodologicalStrategies.forEach((element) => {
      //   if (!methodological_strategies.includes(element.id)) {
      //     list.push(element);
      //   }
      // });
      this.methodologicalStrategies.forEach((element) => {
        if (
          !this.matter.methodological_strategies.find((x) => x == element.id)
        ) {
          list.push(element);
        }
      });
      return list.sort((a, b) => a.name.localeCompare(b.name));
    },
    methodologicalStrategyList() {
      let list = [];
      if (!this.session_planning.methodological_strategies) return list;
      this.session_planning.methodological_strategies.forEach((element) => {
        let instance = this.methodologicalStrategies.find(
          (x) => x.id == element
        );
        if (instance) list.push(instance);
      });
      return list.sort((a, b) => a.name.localeCompare(b.name));
    },
    bibliographicResourceList() {
      let list = [];
      if (!this.session_planning.bibliographic_resources) return list;
      this.session_planning.bibliographic_resources.forEach((element) => {
        let instance = this.BibliographicResources.find((x) => x.id == element);
        if (instance) list.push(instance);
      });
      return list;
    },
    feedbackList() {
      let list = [];
      this.session_planning.feedbacks.forEach((element) => {
        let instance = this.evaluationFeedbacks.find((x) => x.id == element);
        if (instance) list.push(instance);
      });
      return list;
    },
    directTeachingActivityList() {
      let list = [];
      this.session_planning.direct_teaching_activities.forEach((element) => {
        let instance = this.DirectTeachingActivities.find(
          (x) => x.id == element
        );
        if (instance) list.push(instance);
      });
      return list;
    },
    autonomousTeachingActivityList() {
      let list = [];
      this.session_planning.autonomous_teaching_activities.forEach(
        (element) => {
          let instance = this.AutonomousTeachingActivities.find(
            (x) => x.id == element
          );
          if (instance) list.push(instance);
        }
      );
      return list.sort((a, b) => a.name.localeCompare(b.name));
    },
    teachingSupportResourceList() {
      let list = [];
      this.session_planning.teaching_support_resource.forEach((element) => {
        let instance = this.TeachingSupportResource.find(
          (x) => x.id == element
        );
        if (instance) list.push(instance);
      });
      return list;
    },
    // sessionDateList() {
    //   let list = this.sessionDates.filter((x) => x.section == this.section_id);
    //   return list.sort(function (a, b) {
    //     if (moment(a.start_date) > moment(b.start_date)) return 1;
    //     if (moment(a.end_date) < moment(b.end_date)) return -1;
    //     return 0;
    //   });
    // },
    // matterStudyUnits() {
    //   if (!this.newMatter) return [];
    //   let list = this.studyUnits.filter((x) => x.matter == this.newMatter.matter);
    //   list = list.sort(function (a, b) {
    //     if (a.order > b.order) return 1;
    //     if (a.order < b.order) return -1;
    //     return 0;
    //   });
    //   return list;
    // },
    // sessionDate() {
    //   let index = 0;
    //   this.matterStudyUnits.forEach((study_unit) => {
    //     if (study_unit.id != this.study_unit_id) {
    //       index += study_unit.weeks;
    //     } else if (study_unit.id == this.study_unit_id) {
    //       index += this.session_planning.order;
    //     }
    //   });
    //   try {
    //     return this.sessionDateList[index - 1].start_date;
    //   } catch {
    //     return null;
    //   }
    // },
    // sessionDateFormated() {
    //   if (this.sessionDate)
    //     return moment(this.sessionDate).format("YYYY-MM-DD");
    //   return "";
    // },
  },
  methods: {
    isWeekEven(index) {
      if (Math.trunc(index / this.matterWeekHours) % 2 != 0) return true;
      return false;
    },
    validateForm() {
      this.errors = [];
      if (this.key_label == "matter") {
        if (this.evaluation_criterias_micro.length == 0) {
          this.errors.push(
            "Seleccione un " +
              String(
                this.$getVisibleNames(
                  "manual.indicador_de_logro",
                  false,
                  "Indicador de Logro"
                ) + "."
              )
          );
        }
      } else if (
        this.evaluation_criterias_micro.length == 0 &&
        this.evaluation_criterias_macro.length == 0
      )
        this.errors.push(
          "Seleccione un " +
            String(
              this.$getVisibleNames(
                "manual.indicador_de_logro",
                false,
                "Indicador de Logro"
              ) + "."
            )
        );
      if (this.key_label == "matter") {
        if (this.session_planning.contents.length == 0)
          this.errors.push("Seleccione al menos un Contenido.");
      } else if (
        this.session_planning.contents.length == 0 &&
        this.session_planning.evaluation_criteria_macro_contents.length == 0
      )
        this.errors.push("Seleccione al menos un Contenido.");
      if (this.session_planning.methodological_strategies.length == 0)
        this.errors.push(
          "Seleccione alguna " +
            this.$getVisibleNames(
              "mesh.methodologicalstrategy",
              false,
              "Estrategia Metodológica"
            ) +
            "."
        );
      if (this.session_planning.direct_teaching_activities.length == 0)
        this.errors.push("Seleccione alguna actividad de docencia directa.");
      // if (this.session_planning.autonomous_teaching_activities.length == 0)
      //   this.errors.push("Seleccione alguna actividad de trabajo autónomo.");
      // if (this.session_planning.bibliographic_resources.length == 0)
      //   this.errors.push("Seleccione alguna bibliografía.");
      // if (this.session_planning.feedbacks.length == 0)
      //   this.errors.push("Seleccione al menos un método de retroalimentación.");

      if (
        !this.session_planning.study_unit ||
        isNaN(this.session_planning.study_unit)
      )
        return false;
      return true;
    },
    saveSessionPlanning(item) {
      if (isNaN(this.session_planning.id)) this.createSessionPlanning(item);
      else this.updateSessionPlanning(item);
    },
    createSessionPlanning(item) {
      this.$store
        .dispatch(names.POST_SESSION_PLANNING, this.session_planning)
        .then((response) => {
          this.session_planning = response;
          if (this.key_label == "matter") {
            this.$restful
              .Post(`/teaching/matter-session-planning/`, {
                session_planning: response.id,
                matter: this.matter_id,
                evaluation_criteria_macro: this.evaluation_criterias_macro,
              })
              .then((session_planning) => {
                this.$emit("created_session_planning", session_planning);
              });
          } else if (this.key_label == "ep_matter") {
            this.$restful
              .Post(`/teaching/ep-matter-session-planning/`, {
                session_planning: response.id,
                egress_profile_matter: this.new_matter_id,
                evaluation_criteria_macro: this.evaluation_criterias_macro,
              })
              .then((session_planning) => {
                this.$emit("created_session_planning", session_planning);
              });
          } else if (this.key_label == "section") {
            this.$restful
              .Post(`/teaching/section-session-planning/`, {
                session_planning: response.id,
                section: this.section_id,
                evaluation_criteria_macro: this.evaluation_criterias_macro,
              })
              .then((session_planning) => {
                this.$emit("created_session_planning", session_planning);
              });
          }
          const payload = {
            session_planning_id: this.session_planning.id,
            item: item,
          };
          this.$store
            .dispatch(names.PATCH_SESSION_PLANNING, payload)
            .then((session_planning) => {
              this.session_planning = session_planning;
            });
        });
    },
    updateSessionPlanning(item) {
      if (!isNaN(this.session_planning.id)) {
        const payload = {
          session_planning_id: this.session_planning.id,
          item: item,
        };
        this.$store
          .dispatch(names.PATCH_SESSION_PLANNING, payload)
          .then((response) => {
            this.session_planning = response;
            this.validateForm();
            if (this.key_label == "ep_matter") {
              const element = this.filter_list.find(
                (x) =>
                  x.session_planning == this.session_planning.id &&
                  x.egress_profile_matter == this.new_matter_id
              );
              if (element)
                this.$restful
                  .Patch(
                    `/teaching/ep-matter-session-planning/${element.id}/`,
                    {
                      evaluation_criteria_macro:
                        this.evaluation_criterias_macro,
                    }
                  )
                  .then((session_planning) => {
                    this.$emit("created_session_planning", session_planning);
                  });
            } else if (this.key_label == "section") {
              const element = this.filter_list.find(
                (x) =>
                  x.session_planning == this.session_planning.id &&
                  x.section == this.section_id
              );
              if (element)
                this.$restful
                  .Patch(`/teaching/section-session-planning/${element.id}/`, {
                    evaluation_criteria_macro: this.evaluation_criterias_macro,
                  })
                  .then((session_planning) => {
                    this.$emit("created_session_planning", session_planning);
                  });
            } else if (this.key_label == "matter") {
              const element = this.filter_list.find(
                (x) =>
                  x.session_planning == this.session_planning.id &&
                  x.matter == this.matter_id
              );
              if (element)
                this.$restful
                  .Patch(`/teaching/matter-session-planning/${element.id}/`, {
                    evaluation_criteria_macro: this.evaluation_criterias_macro,
                  })
                  .then((session_planning) => {
                    this.$emit("created_session_planning", session_planning);
                  });
            }
          });
      }
    },
  },
  watch: {
    session_planning: function () {
      this.evaluation_criterias_micro =
        this.session_planning.evaluation_criterias_micro;
      this.contents = this.session_planning.contents;
      this.methodological_strategies =
        this.session_planning.methodological_strategies;
      this.bibliographic_resources =
        this.session_planning.bibliographic_resources;
      this.feedbacks = this.session_planning.feedbacks;
      this.direct_teaching_activities =
        this.session_planning.direct_teaching_activities;
      this.autonomous_teaching_activities =
        this.session_planning.autonomous_teaching_activities;
      this.teaching_support_resource =
        this.session_planning.teaching_support_resource;
    },
    evaluation_criterias_micro: function () {
      if (
        this.evaluation_criterias_micro.length > 0 &&
        this.evaluation_criterias_micro[0] == null
      ) {
        this.evaluation_criterias_micro = [];
      }
    },
    evaluation_criterias_macro: function () {
      if (
        this.evaluation_criterias_macro.length > 0 &&
        this.evaluation_criterias_macro[0] == null
      ) {
        this.evaluation_criterias_macro = [];
      }
    },
    teaching_support_resource: function () {
      if (
        this.teaching_support_resource.length > 0 &&
        this.teaching_support_resource[0] == null
      ) {
        this.teaching_support_resource = [];
      }
    },
    // sessionDate() {
    //   if (this.sessionDate) {
    //     this.session_planning.start_date = this.sessionDate;
    //   }
    // },
  },
  created() {
    if (!isNaN(this.session_planning.id)) {
      let element = null;
      if (this.key_label == "matter")
        element = this.filter_list.find(
          (x) =>
            x.session_planning == this.session_planning.id &&
            x.matter == this.matter_id
        );
      if (this.key_label == "ep_matter")
        element = this.filter_list.find(
          (x) =>
            x.session_planning == this.session_planning.id &&
            x.egress_profile_matter == this.new_matter_id
        );
      if (this.key_label == "section")
        element = this.filter_list.find(
          (x) =>
            x.session_planning == this.session_planning.id &&
            x.section == this.section_id
        );
      if (element) {
        this.evaluation_criterias_macro = element.evaluation_criteria_macro;
      }
    }

    this.SessionPlanning.contents.forEach((content_id) => {
      if (!this.studyUnitcontents.find((x) => x.id == content_id))
        this.$store.dispatch(names.FETCH_CONTENT, content_id);
    });
    this.validateForm();
  },
};
</script>

<style scoped>
.select-option {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
  text-indent: -16px;
}
tr td {
  border: 1px black solid;
  font-size: var(--thirth-font-size);
}
select option:checked {
  font-weight: bold !important;
  /* background-color: #cecece !important; */
}
.even-row {
  background-color: #e9e9e9;
}
textarea {
  min-height: 50px;
  font-size: var(--thirth-font-size);
  /* height: -webkit-fill-available; */
  width: 100%;
}
p {
  text-align: justify;
  margin: 0px 5px;
  font-size: var(--thirth-font-size);
}
select {
  font-size: var(--thirth-font-size);
  padding-left: 0px;
}
.resource-div {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
}
li,
ul {
  text-align: left;
  margin-bottom: 0px;
}
ul {
  margin-left: 18px;
}
</style>