<template>
  <div class="mb-3 page-break">
    <h4 v-if="cycle">
      {{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}:
      {{ cycle.name }}
    </h4>
    <div class="legend" v-if="semester == 1">
      El ' * ' representa que ese valor no se contabilizará en el cálculo Total
      de Horas.
    </div>
    <table class="table">
      <thead style="background-color: var(--primary-color)">
        <th :colspan="10 + time_allocations.length">
          {{ numberToWord(semester) }} NIVEL
          <span
            v-can="'mesh.change_egressprofile'"
            v-if="!egressProfile.is_closed"
          >
            <span v-if="limitLevel && limitLevel.max_hours != 0">
              -
              {{
                $getVisibleNames(
                  "manual.total_pedagogical_hours",
                  true,
                  "Total de Horas Pedagógicas"
                )
              }}:
              <span
                :class="
                  limitLevel && limitLevel.max_hours < totalPedagogical
                    ? 'limit-class'
                    : ''
                "
                v-b-tooltip.v-secondary.top.noninteractive="
                  `${$getVisibleNames(
                    'manual.total_pedagogical_hours',
                    true,
                    'Total de Horas Pedagógicas'
                  )} en el Nivel.`
                "
              >
                {{ totalPedagogical }}
              </span>
              <span
                v-if="limitLevel && limitLevel.max_hours != 0"
                v-b-tooltip.v-secondary.top.noninteractive="'Máximo sugerido.'"
                >/ {{ limitLevel.max_hours }}</span
              >
            </span>
            <span v-if="limitLevel && limitLevel.max_credits != 0">
              - Total Créditos:
              <span
                :class="
                  limitLevel && limitLevel.max_credits < totalSCT
                    ? 'limit-class'
                    : ''
                "
                v-b-tooltip.v-secondary.top.noninteractive="
                  'Total de Créditos en el Nivel.'
                "
              >
                {{ totalSCT }}
              </span>
              <span
                v-if="limitLevel && limitLevel.max_credits != 0"
                v-b-tooltip.v-secondary.top.noninteractive="
                  'Máximo de Créditos sugerido.'
                "
                >/ {{ limitLevel.max_credits }}</span
              >
            </span>
          </span>
        </th>
        <tr>
          <th scope="col">N°</th>
          <th scope="col">
            {{
              $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas")
            }}
          </th>
          <th
            scope="col"
            :title="`${$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            )} que debe vencer para tomar esta.`"
          >
            Requisitos
          </th>
          <th
            scope="col"
            class="header-row-rotated"
            :title="`${$getVisibleNames(
              'mesh.matterregime',
              true,
              'Regímenes'
            )}`"
          >
            {{ $getVisibleNames("mesh.matterregime", true, "Regímenes") }}
            <!-- Períodos -->
          </th>
          <th scope="col" class="header-row-rotated" title="Semanas">
            Semanas
          </th>
          <th
            scope="col"
            class="header-row-rotated"
            v-for="hour in time_allocations.filter(
              (x) =>
                x.uses_module_minutes == true &&
                this.egress_profile_time_allocations.includes(x.id)
            )"
            :key="hour.id"
          >
            {{ hour.name }}
          </th>
          <th scope="col" class="header-row-rotated">
            {{
              $getVisibleNames(
                "manual.total_pedagogical_hours",
                true,
                "Total de Horas Pedagógicas"
              )
            }}
          </th>
          <th scope="col" class="header-row-rotated">
            {{
              $getVisibleNames(
                "manual.total_pedagogical_hours_to_chronological",
                true,
                "Total de Horas Pedagógicas (En Cronológicas)"
              )
            }}
          </th>
          <th
            scope="col"
            v-for="hour in time_allocations.filter(
              (x) =>
                x.uses_module_minutes == false &&
                this.egress_profile_time_allocations.includes(x.id)
            )"
            :key="hour.id"
          >
            {{ hour.name }}
          </th>
          <th scope="col" class="header-row-rotated">
            {{
              $getVisibleNames(
                "manual.total_chronological_hours",
                true,
                "Total de Horas Cronológicas"
              )
            }}
          </th>
          <th scope="col" class="header-row-rotated">
            {{
              $getVisibleNames(
                "manual.total_hours_sum_to_chronologica",
                true,
                "Total de Horas (Pedagógicas + Cronológicas)"
              )
            }}
          </th>
          <!-- <th scope="col" class="header-row-rotated" title="Módulos Teóricas">
            Módulos Teóricos
          </th>
          <th
            scope="col"
            class="header-row-rotated"
            title="Módulos Lab/Talleres"
          >
            Módulos Lab / Talleres
          </th>
          <th
            scope="col"
            class="header-row-rotated"
            title="Módulos Terreno/Campo Clínico"
          >
            Módulos Terreno / Campo Clínico
          </th>
          <th scope="col" class="header-row-rotated" title="Módulos Ayudantía">
            Módulos Ayudantía
          </th>
          <th
            scope="col"
            class="header-row-rotated"
            title="Módulos Presenciales Semanales"
          >
            Módulos Presenciales Semanales
          </th>
          <th
            scope="col"
            class="header-row-rotated"
            title="Total de Horas Presenciales Cronológicas"
          >
            Total de Horas Presenciales Cronológicas
          </th>
          <th
            scope="col"
            class="header-row-rotated"
            title="Horas Autónomas Totales"
          >
            Horas Autónomas Semanales
          </th>
          <th
            scope="col"
            class="header-row-rotated"
            :title="
              $getVisibleNames(
                'manual.total_autonomous_hours',
                false,
                'Total de Horas Autónomas'
              )
            "
          >
            {{
              $getVisibleNames(
                "manual.total_autonomous_hours",
                false,
                "Total de Horas Autónomas"
              )
            }}
          </th> -->
          <th
            scope="col"
            class="header-row-rotated"
            :title="
              $getVisibleNames('manual.credits', true, 'Créditos SCT Totales')
            "
          >
            {{
              $getVisibleNames("manual.credits", true, "Créditos SCT Totales")
            }}
          </th>
        </tr>
      </thead>
      <StudyPlanFormationArea
        v-for="area in formationAreasList"
        :key="area.id"
        :egress_profile_id="egress_profile_id"
        :semester="semester"
        :limit_study_plan="limit_study_plan"
        :egress_profile_time_allocations="egress_profile_time_allocations"
        :formation_area_id="area.id"
        :allows_crud="
          allows_crud &&
          ((user_position &&
            [1, 2, 3].includes(user_position.position) &&
            [1, 2, 3].includes(user.groups[0])) ||
            user.is_superuser ||
            $hasObjectPermission('mesh.career', 'change', egressProfile.career))
        "
      ></StudyPlanFormationArea>
      <tr>
        <td colspan="5" style="text-align: end">Total</td>
        <td
          v-for="hour in time_allocations.filter(
            (x) =>
              x.uses_module_minutes == true &&
              this.egress_profile_time_allocations.includes(x.id)
          )"
          :key="hour.id"
        >
          {{ countTimeAllocationHours(hour.id) }}
        </td>
        <td>{{ totalPedagogical }}</td>
        <td v-if="profileType">
          {{
            (totalPedagogical * (parseFloat(profileType.module_minutes) / 60))
              | Round
          }}
        </td>
        <td
          v-for="hour in time_allocations.filter(
            (x) =>
              x.uses_module_minutes == false &&
              this.egress_profile_time_allocations.includes(x.id)
          )"
          :key="hour.id"
        >
          {{ countTimeAllocationHours(hour.id) }}
        </td>
        <td>{{ totalChronological }}</td>
        <td>{{ totalHours }}</td>
        <!-- <td>{{ totalWeekTheoricHours | Round }}</td>
        <td>{{ totalWeekLabHours | Round }}</td>
        <td>{{ totalWeekGroundHours | Round }}</td>
        <td>{{ totalWeekAssistantshipHours | Round }}</td>
        <td>
          <span
            :class="
              limitLevel &&
              limitLevel.max_hours != 0 &&
              limitLevel.max_hours < totalWeekClassroomHours
                ? 'limit-class'
                : ''
            "
            >{{ totalWeekClassroomHours | Round }}</span
          >
        </td>
        <td>{{ Math.round(semesterClassroomHours) }}</td>
        <td>{{ totalWeekAutonomousHours | Round }}</td>
        <td>{{ Math.round(totalSemesterAutonomousHours) }}</td> -->
        <td>
          <div v-if="profileType">
            <span
              :class="
                limitLevel &&
                limitLevel.max_credits != 0 &&
                limitLevel.max_credits < totalSCT
                  ? 'limit-class'
                  : ''
              "
              >{{ totalSCT }}</span
            >
          </div>
        </td>
        <!-- <td>{{ semesterCredits }}</td> -->
      </tr>
    </table>
    <div v-if="!((institution && institution.id == 12) || $debug_change_duoc)">
      <div
        v-if="
          !(
            allows_crud &&
            ((user_position &&
              [1, 2, 3].includes(user_position.position) &&
              [1, 2, 3].includes(user.groups[0])) ||
              user.is_superuser ||
              $hasObjectPermission(
                'mesh.career',
                'change',
                egressProfile.career
              ))
          )
        "
      >
        <p v-for="(p, index) in comment.comments.split('\n')" :key="index">
          {{ p }}
        </p>
      </div>
      <div v-else>
        <h6 class="comments-header">Comentarios del Nivel {{ semester }}</h6>
        <ResizeAuto>
          <template v-slot:default="{ resize }">
            <textarea
              placeholder="Ingrese su comentario aquí..."
              @input="resize"
              @focus="resize"
              v-model="comment.comments"
              @blur="saveComment"
            ></textarea>
          </template>
        </ResizeAuto>
      </div>
    </div>
  </div>
</template>
<script>
import * as names from "@/store/names";
import StudyPlanFormationArea from "@/components/mesh/StudyPlan/StudyPlanFormationArea";
import { mapGetters } from "vuex";
import ResizeAuto from "@/components/reusable/ResizeAuto";

export default {
  name: "BiannualLevel",
  components: {
    StudyPlanFormationArea: StudyPlanFormationArea,
    ResizeAuto,
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    semester: {
      type: Number,
      required: true,
    },
    limit_study_plan: {
      type: Object,
      default: null,
    },
    egress_profile_time_allocations: {
      type: Array,
      default: () => [],
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      comment: {
        id: -1,
        semester: this.semester,
        comments: "",
        egress_profile: this.egress_profile_id,
      },
    };
  },
  methods: {
    numberToWord(number) {
      return this.numbersToWord[number - 1]
        ? this.numbersToWord[number - 1]
        : number;
    },
    saveComment() {
      if (!this.comment) return;
      this.comment.egress_profile = this.egressProfile.id;
      if (this.comment.comments.trim().length == 0 && this.comment.id != -1) {
        this.$store.dispatch(names.DELETE_STUDY_PLAN_COMMENT, this.comment.id);
        return;
      }
      if (this.comment.comments.trim().length == 0) return;
      if (this.comment.id == -1) {
        this.$store.dispatch(names.POST_STUDY_PLAN_COMMENT, this.comment);
      } else {
        let payload = {
          study_plan_comment_id: this.comment.id,
          item: {
            comments: this.comment.comments,
          },
        };
        this.$store.dispatch(names.PATCH_STUDY_PLAN_COMMENT, payload);
      }
    },
    countTimeAllocationHours(time_allocation_id) {
      let hours = 0;
      if (!this.isAnual) return hours;
      this.mattersBySemester.forEach((matter) => {
        let matter_hour = [];
        if (matter.use_matter_values) {
          matter_hour = this.matter_time_allocations.find(
            (x) =>
              x.matter == matter.matter &&
              x.time_allocation == time_allocation_id
          );
        } else {
          matter_hour = this.ep_matter_time_allocations.find(
            (x) =>
              x.egress_profile_matter == matter.id &&
              x.time_allocation == time_allocation_id
          );
        }
        if (matter_hour && matter_hour.counts_towards_credits) {
          if (matter.regime == this.isAnual.id)
            hours += parseFloat(matter_hour.hours) / 2;
          else hours += parseFloat(matter_hour.hours);
        }
      });
      return Math.round(hours);
    },
  },
  computed: {
    ...mapGetters({
      egressProfiles: names.EGRESS_PROFILES,
      matters: names.MATTERS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      numbersToWord: names.NUMBERS_TO_WORDS,
      regimes: names.REGIMES,
      profile_cycles: names.PROFILE_CYCLES,
      profile_types: names.PROFILE_TYPES,
      comments: names.STUDY_PLAN_COMMENTS,
      cycles: names.CYCLES,
      formationAreas: names.FORMATION_AREAS,
      user: "getUser",
      user_position: names.USER_POSITION,
      institution: "getInstitution",
      time_allocations: names.TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      courses_wrapper: names.COURSES_WRAPPER,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
    }),
    isAnual() {
      const months_amount_egress_profile = this.regimes.find(
        (x) => x.id == this.egressProfile.regime
      );
      if (months_amount_egress_profile) {
        if (months_amount_egress_profile.months_amount == 6)
          return this.regimes.find(
            (x) =>
              x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
          );
        else if (months_amount_egress_profile.months_amount == 3)
          return this.regimes.find(
            (x) =>
              x.name.includes("Semestral") || parseInt(x.months_amount) / 3 == 2
          );
        else if (months_amount_egress_profile.months_amount == 2)
          return this.regimes.find(
            (x) =>
              x.name.includes("Trimestral") || parseInt(x.months_amount) == 3
          );
      }
      return this.regimes.find(
        (x) => x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
      );
    },
    formationAreasList() {
      let list = [];
      this.formationAreas.forEach((item) => {
        list.push(item);
      });
      list.push({
        id: 0,
        name: "N/A",
        description: "No Aplica",
        order: 0,
      });
      return list;
    },
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    profileType() {
      if (!this.egressProfile) return [];
      return this.profile_types.find(
        (x) => x.id == this.egressProfile.egress_profile_type
      );
    },
    limitLevel() {
      if (this.limit_study_plan == null) return null;
      else {
        const index = this.limit_study_plan.levels.findIndex(
          (x) => x.level == this.semester
        );
        if (index != -1) return this.limit_study_plan.levels[index];
        else return null;
      }
    },
    coursesWrapperList() {
      return this.courses_wrapper.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
    },
    wrapperMatterIds() {
      let list = [];
      this.coursesWrapperList.forEach((x) => {
        list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    matterList() {
      return this.egress_profiles_matters.filter(
        (x) =>
          x.egress_profile == this.egress_profile_id &&
          !this.wrapperMatterIds.includes(x.id)
      );
    },
    getComment() {
      return this.comments.find(
        (x) =>
          x.egress_profile == this.egress_profile_id &&
          x.semester == this.semester
      );
    },
    cycle() {
      let cycle = this.profile_cycles.find(
        (x) =>
          x.init_semester == this.semester &&
          x.egress_profile == this.egress_profile_id
      );
      if (cycle) {
        return this.cycles.find((x) => x.id == cycle.cycle);
      }
      return null;
    },
    mattersBySemester() {
      if (!this.isAnual) return [];
      return this.matterList.filter(
        (x) =>
          x.plan_level == this.semester ||
          (x.regime == this.isAnual.id && x.plan_level == this.semester - 1)
      );
    },
    // totalWeekTheoricHours() {
    //   let hours = 0;
    //   this.mattersBySemester.forEach((matter) => {
    //     hours += matter.week_theoric_hours;
    //   });
    //   return hours;
    // },
    // totalWeekLabHours() {
    //   let hours = 0;
    //   this.mattersBySemester.forEach((matter) => {
    //     hours += matter.week_lab_hours;
    //   });
    //   return hours;
    // },
    // totalWeekGroundHours() {
    //   let hours = 0;
    //   this.mattersBySemester.forEach((matter) => {
    //     hours += matter.week_ground_hours;
    //   });
    //   return hours;
    // },
    // totalWeekAssistantshipHours() {
    //   let hours = 0;
    //   this.mattersBySemester.forEach((matter) => {
    //     hours += matter.week_assistantship_hours;
    //   });
    //   return hours;
    // },
    // totalWeekClassroomHours() {
    //   let hours = 0;
    //   this.mattersBySemester.forEach((matter) => {
    //     hours +=
    //       matter.week_theoric_hours +
    //       matter.week_lab_hours +
    //       matter.week_ground_hours +
    //       matter.week_assistantship_hours;
    //   });
    //   return hours;
    // },
    totalPedagogical() {
      let hours = 0;
      if (!this.isAnual) return hours;
      const pedagogical_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == true)
        .map((x) => x.id);
      this.mattersBySemester.forEach((matter) => {
        let matter_hours = [];
        if (matter.use_matter_values) {
          matter_hours = this.matter_time_allocations.filter(
            (x) =>
              x.matter == matter.matter &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        } else {
          matter_hours = this.ep_matter_time_allocations.filter(
            (x) =>
              x.egress_profile_matter == matter.id &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        }
        matter_hours.forEach((hour) => {
          if (matter.regime == this.isAnual.id)
            hours += parseFloat(hour.hours) / 2;
          else hours += parseFloat(hour.hours);
        });
      });
      return Math.round(hours);
    },
    totalChronological() {
      let hours = 0;
      if (!this.isAnual) return hours;
      const chronological_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == false)
        .map((x) => x.id);
      this.mattersBySemester.forEach((matter) => {
        let matter_hours = [];
        if (matter.use_matter_values) {
          matter_hours = this.matter_time_allocations.filter(
            (x) =>
              x.matter == matter.matter &&
              chronological_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        } else {
          matter_hours = this.ep_matter_time_allocations.filter(
            (x) =>
              x.egress_profile_matter == matter.id &&
              chronological_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        }
        matter_hours.forEach((hour) => {
          if (matter.regime == this.isAnual.id)
            hours += parseFloat(hour.hours) / 2;
          else hours += parseFloat(hour.hours);
        });
      });
      return hours;
    },
    totalHours() {
      if (!this.profileType) return 0;
      const total_hours =
        this.totalPedagogical * (this.profileType.module_minutes / 60) +
        this.totalChronological;
      return total_hours ? total_hours : 0;
    },
    totalSCT() {
      // let totalClassroomHours = 0;
      let totalSCT = 0;
      if (!this.isAnual) return totalSCT;
      this.mattersBySemester.forEach((matter) => {
        if (matter.use_matter_values) {
          const old_matter = this.matters.find((x) => x.id == matter.matter);
          if (old_matter) {
            if (matter.regime == this.isAnual.id)
              totalSCT += old_matter.credits / 2;
            else totalSCT += old_matter.credits;
          }
        } else {
          if (matter.regime == this.isAnual.id) totalSCT += matter.credits / 2;
          else totalSCT += matter.credits;
        }
        // if (
        //   element.egress_profile == this.egressProfile.id &&
        //   element.regime == this.isAnual.id &&
        //   this.semester % 2 == 0 &&
        //   element.plan_level == this.semester - 1
        // ) {
        //   totalSCT += element.credits;
        // }
        // if (
        //   element.plan_level == this.semester &&
        //   element.egress_profile == this.egressProfile.id
        // ) {
        //   totalSCT += element.credits;
        // }
      });
      return Math.round(totalSCT);
    },
  },
  watch: {
    getComment() {
      if (this.getComment) this.comment = this.getComment;
    },
  },
};
</script>

<style scoped>
.table {
  position: relative;
  font-size: 10px;
  border: solid black;
  border-width: thin;
}
.legend {
  text-align: right;
}
.table thead th {
  font-size: var(--secondary-font-size) !important;
  padding: 0.2rem;
  vertical-align: middle;
  color: white !important;
  border: solid black;
  border-width: thin;
}
th {
  height: 0px;
  background-color: var(--kl-menu-color) !important;
}
.table tr td {
  font-size: var(--secondary-font-size);
  font-weight: bold;
  padding: 2px 2px;
  border: solid black;
  border-width: thin;
}
.header-row-rotated {
  /* transform: rotate(270deg);
  text-align: left; */
  padding: 0px;
  margin: 0px;
}
p {
  text-align: left;
  font-family: "Helvetica Neue";
  line-height: 1em;
  font-weight: bold;
  font-style: italic;
  margin: auto;
  padding: 0 1em;
  overflow: hidden;
  display: block;
}
.comments-header {
  text-align: center;
  margin-bottom: -0.5px;
  font-weight: bold;
  font-style: italic;
}
textarea {
  width: 100%;
  margin-top: 5px;
  padding: 4px 8px;
  font-family: "Helvetica Neue";
  line-height: 1em;
  font-weight: bold;
  font-style: italic;
  border: 1px solid black;
  border-radius: 5px;
}
.limit-class {
  color: red;
}
.page-break {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
</style>