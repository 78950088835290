<template>
  <div>
    <h4 v-if="show_title">
      {{ $getVisibleNames("mesh.egressprofilematter", false, "Asignatura") }}
    </h4>
    <div>
      <b-form-group
        label-for="name-input"
        label-cols="1"
        label-cols-sm="3"
        class="label m-0"
        label-class="label-matter"
      >
        <template #label>
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()} vinculadas`
            "
            >⚠️</span
          >Nombre
        </template>
        <div class="d-flex">
          <div class="d-block w-100">
            <!-- && isNaN(old_matter.id) -->
            <b-form-input
              id="name-input"
              v-model="$v.old_matter.name.$model"
              :disabled="old_matter.is_closed"
              v-bind:class="{
                'matter-name': !SameMatterName,
              }"
              :state="validateOldMatter('name')"
              aria-describedby="input-name-feedback"
              size="sm"
            >
            </b-form-input>
            <!-- :disabled="!isNaN(old_matter.id)" -->
            <b-form-invalid-feedback id="input-name-feedback" class="mt-0 mb-1"
              >Este campo no debe estar vacío y no debe ser menor a 1
              caracteres.</b-form-invalid-feedback
            >
            <!-- && isNaN(old_matter.id) -->
            <span v-if="!SameMatterName" class="text-info">
              Existe una
              {{
                $getVisibleNames("teaching.matter", false, "Asignatura Base")
              }}
              con ese nombre.
            </span>
          </div>
          <button
            class="btn-select-default ml-2"
            v-if="!old_matter.is_closed"
            v-b-tooltip.v-secondary.noninteractive="
              `Seleccionar ${$getVisibleNames(
                'teaching.matter',
                false,
                'Asignatura Base'
              )}.`
            "
            @click="$bvModal.show('bv-modal-matter-select')"
          >
            <b-icon font-scale="1.5" icon="clipboard-check"></b-icon>
          </button>
          <button
            class="btn-clear-inputs ml-2"
            v-if="!old_matter.is_closed"
            v-b-tooltip.v-secondary.noninteractive="
              `Limpiar nombre y ${$getVisibleNames(
                'manual.matter_code',
                false,
                'Código'
              )}.`
            "
            @click="resetOldMatter"
          >
            <b-icon font-scale="1.5" icon="x-square"></b-icon>
          </button>
        </div>
      </b-form-group>
      <b-modal size="lg" id="bv-modal-matter-select" hide-footer>
        <template #modal-title
          >Seleccione la
          {{
            $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
          }}
          a utilizar.</template
        >
        <div class="d-block text-center">
          <GenericBTable
            :items="matter_codes"
            :fields="fieldsMatterCode"
            :filterCustom="filterCustom"
            :show_pagination="true"
            :search_filter="true"
            :selection_mode="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @emitChangeSearchField="changeInputSearch"
          >
            <template #cell(selected)="row">
              <b-checkbox
                :checked="old_matter.id == row.item.id"
                @change="ChangeOldMatter(row.item.id)"
              ></b-checkbox>
            </template>
            <template #cell(modalities)="row">
              <div class="text-left">
                <span
                  v-for="(modality, index) in row.item.modalities"
                  :key="row.item.id + ',' + index"
                >
                  {{ getModality(modality) }}
                  <span v-if="index + 1 != row.item.modalities.length">-</span>
                </span>
              </div>
            </template>
            <template #cell(formation_area)="row">
              {{ getFormationArea(row.item.formation_area) }}
            </template>
          </GenericBTable>
        </div>
      </b-modal>
      <div class="row-code">
        <div class="w-25">
          <label for="input-code" class="label-code">
            <span
              v-if="
                !old_matter.is_closed &&
                !((institution && institution.id == 12) || $debug_change_duoc)
              "
              v-b-tooltip.v-secondary.noninteractive="
                `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                  'mesh.egressprofilematter',
                  true,
                  'Asignaturas'
                ).toLowerCase()} vinculadas`
              "
              >⚠️</span
            >
            {{ $getVisibleNames("manual.matter_code", false, "Código") }}</label
          >
        </div>
        <div class="w-75">
          <b-form-group class="label m-0 custom-input">
            <!-- :disabled="!isNaN(old_matter.id)" -->
            <b-form-input
              id="input-code"
              v-model="$v.old_matter.code.$model"
              :state="validateOldMatter('code')"
              aria-describedby="input-code-live-feedback"
              :disabled="old_matter.is_closed"
              size="sm"
              class="input-code"
              @update="
                (value) => {
                  old_matter.code = value.toUpperCase();
                }
              "
            ></b-form-input>
            <b-form-invalid-feedback id="input-code-live-feedback">
              <div v-if="!SameMatterCode">
                El código ya está siendo utilizado.
              </div>
              <div v-else>Este campo debe tener al menos 3 caracteres.</div>
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <b-form-group label-cols="0" label-cols-sm="5" class="label m-0">
        <template #label>
          <div class="weighing-label">
            <div class="label-content">
              <span
                v-if="
                  !old_matter.is_closed &&
                  !((institution && institution.id == 12) || $debug_change_duoc)
                "
                v-b-tooltip.v-secondary.noninteractive="
                  `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    true,
                    'Asignaturas'
                  ).toLowerCase()} vinculadas`
                "
                >⚠️</span
              >Ponderación Equivalente
              <span>
                <InfoTooltip
                  class="info-icon"
                  :tooltip_text="`Utilizar una ponderación equivalente para los ${$getVisibleNames(
                    'teaching.ramicro',
                    false,
                    'RA Micro'
                  )} ${
                    institution && !institution.show_ra_macro
                      ? ''
                      : String(
                          ' y ' +
                            $getVisibleNames(
                              'teaching.evaluationcriteriamacro',
                              true,
                              'Criterios de Evaluación Macro'
                            )
                        )
                  }`"
                >
                </InfoTooltip
              ></span>
            </div>
          </div>
        </template>
        <div id="credit">
          <div class="d-flex">
            <div class="toggle-types noprint">
              <span
                :class="{
                  'font-weight-bold': old_matter.automatic_achievement_weighing,
                }"
                >Sí</span
              >
              <!-- :disabled="!isNaN(old_matter.id)" -->
              <b-button
                class="check-type"
                :class="{ 'check-type-disabled': old_matter.is_closed }"
                @click="
                  () => {
                    if (!old_matter.is_closed)
                      old_matter.automatic_achievement_weighing =
                        !old_matter.automatic_achievement_weighing;
                  }
                "
                variant="primary"
              >
                <b-icon
                  icon="circle-fill"
                  class="icon-check"
                  :class="{
                    'icon-check-active':
                      !old_matter.automatic_achievement_weighing,
                  }"
                  scale="0.7"
                ></b-icon>
              </b-button>
              <span
                :class="{
                  'font-weight-bold':
                    !old_matter.automatic_achievement_weighing,
                }"
                >No</span
              >
            </div>
          </div>
        </div></b-form-group
      >
      <b-form-group label-cols="0" label-cols-sm="5" class="label m-0">
        <template #label>
          <div class="weighing-label">
            <div class="label-content">
              <span
                v-if="
                  !old_matter.is_closed &&
                  !((institution && institution.id == 12) || $debug_change_duoc)
                "
                v-b-tooltip.v-secondary.noninteractive="
                  `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    true,
                    'Asignaturas'
                  ).toLowerCase()} vinculadas`
                "
                >⚠️</span
              >Es seleccionable en
              {{
                $getVisibleNames(
                  "mesh.basematterwrapper",
                  false,
                  "Módulo Electivo Genérico"
                )
              }}
              <span
                ><InfoTooltip
                  class="info-icon"
                  :tooltip_text="
                    'Necesario para su registro en ' +
                    $getVisibleNames(
                      'mesh.basematterwrapper',
                      false,
                      'Módulo Electivo Genérico'
                    )
                  "
                >
                </InfoTooltip
              ></span>
            </div>
          </div>
        </template>
        <div id="credit">
          <div class="d-flex">
            <div class="toggle-types noprint">
              <span
                :class="{
                  'font-weight-bold': old_matter.is_elective,
                }"
                >Sí</span
              >
              <b-button
                class="check-type"
                :class="{ 'check-type-disabled': old_matter.is_closed }"
                @click="
                  () => {
                    if (!old_matter.is_closed)
                      old_matter.is_elective = !old_matter.is_elective;
                  }
                "
                variant="primary"
              >
                <b-icon
                  icon="circle-fill"
                  class="icon-check"
                  :class="{
                    'icon-check-active': !old_matter.is_elective,
                  }"
                  scale="0.7"
                ></b-icon>
              </b-button>
              <span
                :class="{
                  'font-weight-bold': !old_matter.is_elective,
                }"
                >No</span
              >
            </div>
          </div>
        </div></b-form-group
      >
      <b-form-group
        label-cols="0"
        label-cols-sm="5"
        class="label m-0"
        v-if="!((institution && institution.id == 12) || $debug_change_duoc)"
      >
        <template #label>
          <div class="weighing-label">
            <div class="label-content">
              Vincular
              {{
                $getVisibleNames("teaching.matter", false, "Asignatura Base")
              }}
              <span
                ><InfoTooltip
                  class="info-icon"
                  :tooltip_text="`Los cambios en los campos marcados (⚠️) se verán reflejados en todas las ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    true,
                    'Asignaturas'
                  ).toLowerCase()} vinculadas a esta ${$getVisibleNames(
                    'teaching.matter',
                    false,
                    'Asignaturas'
                  ).toLowerCase()}.`"
                >
                </InfoTooltip
              ></span>
            </div>
          </div>
        </template>
        <div id="credit">
          <div class="d-flex">
            <div class="toggle-types noprint">
              <span
                :class="{
                  'font-weight-bold': matter.use_matter_values,
                }"
                >Sí</span
              >
              <b-button
                class="check-type"
                :class="{ 'check-type-disabled': matter.is_closed }"
                @click="
                  () => {
                    if (!matter.is_closed) {
                      matter.use_matter_values = !matter.use_matter_values;
                      if (!matter.use_matter_values && !isNaN(old_matter.id))
                        copyMatterToEgressProfileMatter(old_matter.id);
                    }
                  }
                "
                variant="primary"
              >
                <b-icon
                  icon="circle-fill"
                  class="icon-check"
                  :class="{
                    'icon-check-active': !matter.use_matter_values,
                  }"
                  scale="0.7"
                ></b-icon>
              </b-button>
              <span
                :class="{
                  'font-weight-bold': !matter.use_matter_values,
                }"
                >No</span
              >
            </div>
          </div>
        </div></b-form-group
      >
      <!-- matter.use_matter_values -->
      <b-form-group
        v-if="matter.use_matter_values"
        label-cols="0"
        label-cols-sm="3"
        label-for="input-modality"
        class="label my-2 mx-0"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          {{
            $getVisibleNames("mesh.modality", false, "Modalidad de Asignatura")
          }}
        </template>
        <v-select
          id="input-modality"
          class="input-modality"
          :options="modalities"
          v-model="$v.old_matter.modalities.$model"
          :reduce="(modalities) => modalities.id"
          :state="validateState('modalities')"
          :disabled="old_matter.is_closed"
          :placeholder="`${
            modalities.length > 0
              ? String(
                  'Seleccione una o varias ' +
                    $getVisibleNames(
                      'mesh.modality',
                      true,
                      'Modalidades de Asignatura'
                    ).toLowerCase()
                )
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'mesh.modality',
                      true,
                      'Modalidades de Asignatura'
                    ).toLowerCase() +
                    ' creadas'
                )
          }`"
          multiple
          :selectable="(option) => !old_matter.modalities.includes(option.id)"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
          <template v-slot:option="option">
            {{ option.name }}
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
        v-if="matter.use_matter_values && studyEnvironments.length > 0"
        label-cols="0"
        label-cols-sm="3"
        label-for="input-study_environments"
        class="label my-2 mx-0"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          {{
            $getVisibleNames(
              "mesh.studyenvironment",
              false,
              "Ambiente de Aprendizaje"
            )
          }}
        </template>
        <v-select
          id="input-study_environments"
          v-model="$v.old_matter.study_environments.$model"
          :options="studyEnvironments"
          :reduce="(studyEnvironments) => studyEnvironments.id"
          :disabled="old_matter.is_closed"
          :placeholder="`${
            studyEnvironments.length > 0
              ? String(
                  'Seleccione uno o varios ' +
                    $getVisibleNames(
                      'mesh.studyenvironment',
                      false,
                      'Ambiente de Aprendizaje'
                    ).toLowerCase()
                )
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'mesh.studyenvironment',
                      false,
                      'Ambiente de Aprendizaje'
                    ).toLowerCase() +
                    ' creadas'
                )
          }`"
          multiple
          :selectable="
            (option) => !old_matter.study_environments.includes(option.id)
          "
          :dropdown-should-open="dropdownShouldOpen2"
          :state="validateState('study_environments')"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontraron resultados para: "<em>{{ search }}</em
              >"
            </template>
          </template>
        </v-select>
        <b-tooltip
          target="input-study_environments"
          variant="secondary"
          :noninteractive="true"
          triggers="hover"
        >
          <div v-if="studyEnvironments.length == 0">
            Diríjase a Definiciones Curriculares para poder crear
            {{
              $getVisibleNames(
                "mesh.studyenvironment",
                false,
                "Ambiente de Aprendizaje"
              ).toLowerCase()
            }}.
          </div>
        </b-tooltip>
      </b-form-group>
      <b-form-group
        v-if="matter.use_matter_values"
        label-cols="0"
        label-cols-sm="3"
        label-for="input-weeks"
        class="label my-2 mx-0"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          Semanas
        </template>
        <b-form-input
          id="input-weeks"
          v-model="$v.old_matter.weeks.$model"
          :disabled="old_matter.is_closed"
          type="number"
          min="0"
          :state="validateState('weeks')"
          v-b-tooltip.v-secondary.top.noninteractive="
            `Al cambiar la cantidad de semana, modificara la cantidad Total de ${$getVisibleNames(
              'manual.total_pedagogical_hours',
              true,
              'Total de Horas Pedagógicas'
            )} y ${$getVisibleNames(
              'manual.total_chronological_hours',
              true,
              'Total de Horas Cronológicas'
            )}.`
          "
          aria-describedby="input-weeks-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-weeks-feedback"
          >Este campo debe ser un entero mayor a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <MatterTimeAllocationComponent
        v-if="matter.use_matter_values && step_matter"
        :key="reload_matter_time_allocation"
        :oldMatter="old_matter"
        :profileType="profileType"
        @updated="slotUpdateOldMatterHours"
      >
      </MatterTimeAllocationComponent>
      <b-form-group
        v-if="matter.use_matter_values"
        label-cols="0"
        label-cols-sm="4"
        label-for="total_hours"
        class="label mb-0 mx-0"
      >
        <template #label>
          <div>
            {{
              $getVisibleNames(
                "manual.total_hours_sum_to_chronologica",
                true,
                "Total de Horas (Pedagógicas + Cronológicas)"
              )
            }}
            <span>
              <InfoTooltip
                class="info-icon"
                :tooltip_text="`${$getVisibleNames(
                  'manual.total_pedagogical_hours_to_chronological',
                  true,
                  'Total de Horas Pedagógicas (En Cronológicas)'
                )} + ${$getVisibleNames(
                  'manual.total_chronological_hours',
                  true,
                  'Total de Horas Cronológicas'
                )}`"
              >
              </InfoTooltip>
            </span>
          </div>
        </template>
        <div>
          <b-badge
            id="total_hours"
            class="total-hours-value mt-1"
            variant="light"
          >
            {{ totalHours | Round }}
          </b-badge>
        </div>
      </b-form-group>
      <b-form-group
        v-if="matter.use_matter_values"
        label-cols="0"
        label-cols-sm="4"
        label-for="credit"
        class="label mx-0"
      >
        <template #label>
          <div>
            <!-- <b-icon
              v-b-tooltip.v-secondary.noninteractive="
                `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                  'mesh.egressprofilematter',
                  true,
                  'Asignaturas'
                ).toLowerCase()}`
              "
              icon="exclamation-triangle"
              style="margin-bottom: 0.1rem"
            ></b-icon> -->
            <span
              v-if="
                !old_matter.is_closed &&
                !((institution && institution.id == 12) || $debug_change_duoc)
              "
              v-b-tooltip.v-secondary.noninteractive="
                `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                  'mesh.egressprofilematter',
                  true,
                  'Asignaturas'
                ).toLowerCase()}`
              "
              >⚠️</span
            >
            {{
              $getVisibleNames("manual.credits", true, "Créditos SCT Totales")
            }}
            <span>
              <InfoTooltip
                class="info-icon"
                :tooltip_text="`${$getVisibleNames(
                  'manual.total_hours_sum_to_chronologica',
                  true,
                  'Total de Horas (Pedagógicas + Cronológicas)'
                )}(${totalHours}) / Horas por ${$getVisibleNames(
                  'manual.credits',
                  true,
                  'Créditos SCT Totales'
                )}(${institution.hours_per_credit})`"
              >
              </InfoTooltip>
            </span>
          </div>
        </template>
        <div id="credit">
          <div class="d-flex">
            <b-badge class="credits-value mb-0" variant="light">
              {{ creditSctChile }}
            </b-badge>
            <div
              class="toggle-types noprint"
              v-b-tooltip.v-secondary.hover="'Crédito'"
            >
              <span
                :class="{
                  'font-weight-bold': old_matter.use_automatic_credits,
                }"
                >Automático</span
              >
              <b-button
                class="check-type"
                :class="{ 'check-type-disabled': old_matter.is_closed }"
                @click="
                  () => {
                    if (!old_matter.is_closed) slotCheckCredits();
                  }
                "
                variant="primary"
              >
                <b-icon
                  icon="circle-fill"
                  class="icon-check"
                  :class="{
                    'icon-check-active': !old_matter.use_automatic_credits,
                  }"
                  scale="0.7"
                ></b-icon>
              </b-button>
              <span
                :class="{
                  'font-weight-bold': !old_matter.use_automatic_credits,
                }"
                >Planificado</span
              >
            </div>
            <b-form-input
              id="input-credits"
              type="number"
              min="0"
              class="ml-2"
              :disabled="
                old_matter.use_automatic_credits || old_matter.is_closed
              "
              v-model="$v.old_matter.credits.$model"
              :state="validateState('credits')"
              aria-describedby="input-credits-feedback"
              size="sm"
            ></b-form-input>
            <b-tooltip
              :target="`input-credits`"
              variant="secondary"
              :noninteractive="true"
            >
              <div v-if="old_matter.credits < 0">
                Este campo debe ser mayor o igual a cero.
              </div>
            </b-tooltip>
          </div>
        </div>
      </b-form-group>
      <b-form-group
        v-if="
          matter.use_matter_values &&
          ((institution && institution.id == 7) || $debug_change_unab)
        "
        label-cols="0"
        label-cols-sm="3"
        label-for="input-institutional_credits"
        class="label mt-2"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          {{
            $getVisibleNames(
              "manual.institutional_credits",
              false,
              "Créditos Institucionales"
            )
          }}
        </template>
        <b-form-input
          id="input-institutional_credits"
          type="number"
          min="0"
          v-model="$v.old_matter.institutional_credits.$model"
          :disabled="old_matter.is_closed"
          :state="validateState('institutional_credits')"
          aria-describedby="input-institutional_credits-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-institutional_credits-feedback"
          >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="matter.use_matter_values"
        label-cols="0"
        label-cols-sm="3"
        label-for="input-suggested_students"
        class="label mt-2"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          N° Estudiantes
        </template>
        <b-form-input
          id="input-suggested_students"
          type="number"
          min="0"
          v-model="$v.old_matter.suggested_students.$model"
          :disabled="old_matter.is_closed"
          :state="validateState('suggested_students')"
          aria-describedby="input-suggested_students-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-suggested_students-feedback"
          >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="matter.use_matter_values"
        label-cols="0"
        label-cols-sm="3"
        label-for="input-suggested_teachers"
        class="label mt-2"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          N° Docentes
        </template>
        <b-form-input
          id="input-suggested_teachers"
          type="number"
          min="0"
          v-model="$v.old_matter.suggested_teachers.$model"
          :disabled="old_matter.is_closed"
          :state="validateState('suggested_teachers')"
          aria-describedby="input-suggested_teachers-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-suggested_teachers-feedback"
          >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <template v-if="isClosedOldMatter && !isNaN(old_matter.id)">
        <div class="text-right mr-1">
          <b-button
            size="sm"
            v-b-tooltip.v-secondary.noninteractive="
              `Cambiar el estado del ${$getVisibleNames(
                'teaching.matter',
                false,
                'Asignatura Base'
              )}`
            "
            @click="changeStateOldMatter()"
          >
            <template v-if="old_matter && old_matter.is_closed">
              Abrir
              {{
                $getVisibleNames("teaching.matter", false, "Asignatura Base")
              }}
              <b-icon icon="lock-fill" scale="1"></b-icon>
            </template>
            <template v-else>
              Cerrar
              {{
                $getVisibleNames("teaching.matter", false, "Asignatura Base")
              }}
              <b-icon icon="unlock-fill" scale="1"></b-icon>
            </template>
          </b-button>
        </div>
      </template>
      <hr />
      <!-- !matter.use_matter_values -->
      <b-form-group
        label-cols="1"
        label-cols-sm="3"
        :label="`Número de ${$getVisibleNames(
          'mesh.egressprofilematter',
          false,
          'Asignatura'
        )}`"
        label-for="input-order"
        class="label m-0 mb-2"
        label-class="mt-2"
      >
        <b-form-input
          id="input-order"
          v-model="$v.matter.order.$model"
          :state="validateState('order')"
          type="number"
          :disabled="!allow_crud || matter.is_closed"
          aria-describedby="input-order-feedback"
          :description="`Define el número de la ${$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )} en el Plan de Estudio.`"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-order-feedback"
          >Este campo debe ser un entero mayor a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        :label="$getVisibleNames('mesh.matterregime', false, 'Régimen')"
        label-for="input-regime"
        class="label mt-1 mb-2 mx-0"
      >
        <b-form-select
          id="input-regime"
          :options="regimesList"
          v-model="$v.matter.regime.$model"
          value-field="id"
          text-field="name"
          :state="validateState('regime')"
          type="number"
          :disabled="!allow_crud || matter.is_closed"
          aria-describedby="input-regime-feedback"
          size="sm"
        ></b-form-select>
        <b-form-invalid-feedback id="input-regime-feedback"
          >Debe seleccionar un
          {{ $getVisibleNames("mesh.matterregime", false, "Régimen") }} para la
          {{
            $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
          }}.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        :label="
          $getVisibleNames('mesh.formationarea', false, 'Área De Formación')
        "
        label-for="input-formation-area"
        class="label my-2 mx-0"
      >
        <b-form-select
          id="input-formation-area"
          :options="formationAreas"
          v-model="$v.matter.formation_area.$model"
          value-field="id"
          text-field="name"
          :state="validateState('formation_area')"
          type="number"
          :disabled="!allow_crud || matter.is_closed"
          aria-describedby="input-formation-area-feedback"
          size="sm"
        >
          <template #first>
            <b-form-select-option :value="null"
              >N/A
            </b-form-select-option></template
          ></b-form-select
        >
        <b-form-invalid-feedback id="input-formation-area-feedback"
          >Este campo no puede ser vacío.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        :label="
          $getVisibleNames('mesh.formationline', false, 'Línea De Formación')
        "
        label-for="input-formation-line"
        class="label my-2 mx-0"
      >
        <div class="d-flex">
          <div class="d-block w-100">
            <v-select
              id="input-formation-line"
              v-model="$v.matter.formation_line.$model"
              :options="formationLinesList"
              :reduce="(formationLinesList) => formationLinesList.id"
              :placeholder="`${
                formationLinesList.length > 0
                  ? String(
                      'Seleccione una ' +
                        $getVisibleNames(
                          'mesh.formationline',
                          false,
                          'Línea de Formación'
                        ).toLowerCase()
                    )
                  : String(
                      'Este ' +
                        $getVisibleNames(
                          'mesh.egressprofile',
                          false,
                          'Perfil de Egreso'
                        ).toLowerCase() +
                        ' no cuenta con ' +
                        $getVisibleNames(
                          'mesh.formationline',
                          true,
                          'Líneas de Formación'
                        ).toLowerCase() +
                        ' creadas'
                    )
              }`"
              :dropdown-should-open="dropdownShouldOpen"
              :state="validateState('formation_line')"
              label="name"
              track-by="id"
              :disabled="!allow_crud || matter.is_closed"
              size="sm"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  No se encontraron resultados para: "<em>{{ search }}</em
                  >"
                </template>
              </template>
            </v-select>
          </div>
          <button
            v-if="career && !matter.is_closed"
            v-can="'mesh.add_formationline'"
            @click="openModalFormationLine(true)"
            class="btn-add"
            v-b-tooltip.v-secondary.noninteractive="
              `Agregar ${$getVisibleNames(
                'mesh.formationline',
                false,
                'Línea De Formación'
              )}
            `
            "
          >
            <b-icon font-scale="1" icon="plus-square"></b-icon>
          </button>
          <button-edit
            v-if="career && matter.formation_line && !matter.is_closed"
            v-can="'mesh.change_formationline'"
            @click="openModalFormationLine(false)"
            class="btn-add"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.formationline',
                false,
                'Línea De Formación'
              )}
            `
            "
          >
          </button-edit>
        </div>
        <b-tooltip
          target="input-formation-line"
          variant="secondary"
          :noninteractive="true"
          triggers="hover"
        >
          <div v-if="formationLinesList.length == 0">
            Diríjase a Definiciones Curriculares para poder crear
            {{
              $getVisibleNames(
                "mesh.formationline",
                true,
                "Líneas De Formación"
              ).toLowerCase()
            }}.
          </div>
        </b-tooltip>
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        :label="
          $getVisibleNames('mesh.mattertype', false, 'Tipo de Asignatura')
        "
        label-for="input-matter-type"
        class="label my-2 mx-0"
      >
        <v-select
          id="input-matter-type"
          v-model="$v.matter.matter_types.$model"
          :options="matterTypes"
          :reduce="(matterTypes) => matterTypes.id"
          multiple
          :selectable="(option) => !matter.matter_types.includes(option.id)"
          :placeholder="`Seleccione uno o varios ${$getVisibleNames(
            'mesh.mattertype',
            true,
            'Tipos de Asignatura'
          )}`"
          :disabled="!allow_crud || matter.is_closed"
          :state="validateState('matter_types')"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
        v-if="institution && institution.show_matter_contribution"
        label-cols="0"
        label-cols-sm="3"
        :label="`${$getVisibleNames(
          'mesh.mattercontribution',
          false,
          'Estrategia Curricular'
        )}`"
        label-for="input-matter-contribution"
        class="label my-2 mx-0"
      >
        <v-select
          id="input-matter-contribution"
          :options="matterContributions"
          v-model="$v.matter.matter_contributions.$model"
          :placeholder="`Seleccione una o varias ${$getVisibleNames(
            'mesh.mattercontribution',
            true,
            'Estrategias Curriculares'
          )}`"
          :reduce="(matterContributions) => matterContributions.id"
          multiple
          :selectable="
            (option) => !matter.matter_contributions.includes(option.id)
          "
          :disabled="!allow_crud || matter.is_closed"
          :state="validateState('matter_contributions')"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
          <template v-slot:option="option">
            <strong>{{ option.name }}: </strong>
            {{ option.description }}
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        label="Nivel del Plan"
        label-for="input-plan-level"
        class="label my-2 mx-0"
      >
        <b-form-input
          id="input-plan-level"
          v-model="$v.matter.plan_level.$model"
          :state="validateState('plan_level')"
          type="number"
          :disabled="!allow_crud || matter.is_closed"
          aria-describedby="input-plan-level-live-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-plan-level-live-feedback"
          >Este campo debe ser mayor que 0 y menor o igual de
          {{
            egressProfile ? egressProfile.semester_amount : 0
          }}</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="!matter.use_matter_values"
        label-cols="0"
        label-cols-sm="3"
        :label="
          $getVisibleNames('mesh.modality', false, 'Modalidad de Asignatura')
        "
        label-for="input-modality"
        class="label my-2 mx-0"
      >
        <v-select
          id="input-modality"
          class="input-modality"
          :options="modalities"
          v-model="$v.matter.modalities.$model"
          :reduce="(modalities) => modalities.id"
          :state="validateState('modalities')"
          :disabled="!allow_crud || matter.is_closed"
          :placeholder="`${
            modalities.length > 0
              ? String(
                  'Seleccione una o varias ' +
                    $getVisibleNames(
                      'mesh.modality',
                      true,
                      'Modalidades de Asignatura'
                    ).toLowerCase()
                )
              : String(
                  'Este ' +
                    $getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    ).toLowerCase() +
                    ' no cuenta con ' +
                    $getVisibleNames(
                      'mesh.modality',
                      true,
                      'Modalidades de Asignatura'
                    ).toLowerCase() +
                    ' creadas'
                )
          }`"
          :selectable="(option) => !matter.modalities.includes(option.id)"
          multiple
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
          <template v-slot:option="option">
            {{ option.name }}
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
        v-if="!matter.use_matter_values && studyEnvironments.length > 0"
        label-cols="0"
        label-cols-sm="3"
        :label="
          $getVisibleNames(
            'mesh.studyenvironment',
            false,
            'Ambiente de Aprendizaje'
          )
        "
        label-for="input-study_environments"
        class="label my-2 mx-0"
      >
        <v-select
          id="input-study_environments"
          v-model="$v.matter.study_environments.$model"
          :options="studyEnvironments"
          :reduce="(studyEnvironments) => studyEnvironments.id"
          :disabled="!allow_crud || matter.is_closed"
          :placeholder="`${
            studyEnvironments.length > 0
              ? String(
                  'Seleccione uno o varios ' +
                    $getVisibleNames(
                      'mesh.studyenvironment',
                      false,
                      'Ambiente de Aprendizaje'
                    ).toLowerCase()
                )
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'mesh.studyenvironment',
                      false,
                      'Ambiente de Aprendizaje'
                    ).toLowerCase() +
                    ' creadas'
                )
          }`"
          :selectable="
            (option) => !matter.study_environments.includes(option.id)
          "
          multiple
          :dropdown-should-open="dropdownShouldOpen2"
          :state="validateState('study_environments')"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontraron resultados para: "<em>{{ search }}</em
              >"
            </template>
          </template>
        </v-select>
        <b-tooltip
          target="input-study_environments"
          variant="secondary"
          :noninteractive="true"
          triggers="hover"
        >
          <div v-if="studyEnvironments.length == 0">
            Diríjase a Definiciones Curriculares para poder crear
            {{
              $getVisibleNames(
                "mesh.studyenvironment",
                false,
                "Ambiente de Aprendizaje"
              ).toLowerCase()
            }}.
          </div>
        </b-tooltip>
      </b-form-group>
      <b-form-group
        v-if="!matter.use_matter_values"
        label-cols="0"
        label-cols-sm="3"
        label="Semanas"
        label-for="input-weeks"
        class="label my-2 mx-0"
      >
        <b-form-input
          id="input-weeks"
          v-model="$v.matter.weeks.$model"
          :disabled="!allow_crud || matter.is_closed"
          type="number"
          min="0"
          :state="validateState('weeks')"
          v-b-tooltip.v-secondary.top.noninteractive="
            `Al cambiar la cantidad de semana, modificara la cantidad Total de ${$getVisibleNames(
              'manual.total_pedagogical_hours',
              true,
              'Total de Horas Pedagógicas'
            )} y ${$getVisibleNames(
              'manual.total_chronological_hours',
              true,
              'Total de Horas Cronológicas'
            )}.`
          "
          aria-describedby="input-weeks-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-weeks-feedback"
          >Este campo debe ser un entero mayor a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <TimeAllocationComponent
        v-if="!matter.use_matter_values"
        :egressProfileMatter="matter"
        :profileType="profileType"
        @updated="slotUpdateMatterHours"
      >
      </TimeAllocationComponent>
      <b-form-group
        v-if="!matter.use_matter_values"
        label-cols="0"
        label-cols-sm="4"
        label-for="total_hours"
        class="label mb-0 mx-0"
      >
        <template #label>
          <div>
            {{
              $getVisibleNames(
                "manual.total_hours_sum_to_chronologica",
                true,
                "Total de Horas (Pedagógicas + Cronológicas)"
              )
            }}
            <span>
              <InfoTooltip
                class="info-icon"
                :tooltip_text="`${$getVisibleNames(
                  'manual.total_pedagogical_hours_to_chronological',
                  true,
                  'Total de Horas Pedagógicas (En Cronológicas)'
                )} + ${$getVisibleNames(
                  'manual.total_chronological_hours',
                  true,
                  'Total de Horas Cronológicas'
                )}`"
              >
              </InfoTooltip>
            </span>
          </div>
        </template>
        <div>
          <b-badge
            id="total_hours"
            class="total-hours-value mt-1"
            variant="light"
          >
            {{ totalHours | Round }}
          </b-badge>
        </div>
      </b-form-group>
      <b-form-group
        v-if="!matter.use_matter_values"
        label-cols="0"
        label-cols-sm="4"
        label-for="credit"
        class="label mx-0"
      >
        <template #label>
          <div>
            {{
              $getVisibleNames("manual.credits", true, "Créditos SCT Totales")
            }}
            <span>
              <InfoTooltip
                class="info-icon"
                :tooltip_text="`${$getVisibleNames(
                  'manual.total_hours_sum_to_chronologica',
                  true,
                  'Total de Horas (Pedagógicas + Cronológicas)'
                )}(${totalHours}) / Horas por ${$getVisibleNames(
                  'manual.credits',
                  true,
                  'Créditos SCT Totales'
                )}(${institution.hours_per_credit})`"
              >
              </InfoTooltip>
            </span>
          </div>
        </template>
        <div id="credit">
          <div class="d-flex">
            <b-badge class="credits-value mb-0" variant="light">
              {{ creditSctChile }}
            </b-badge>
            <div
              class="toggle-types noprint"
              v-b-tooltip.v-secondary.hover="'Crédito'"
            >
              <span
                :class="{ 'font-weight-bold': matter.use_automatic_credits }"
                >Automático</span
              >

              <b-button
                class="check-type"
                :class="{ 'check-type-disabled': matter.is_closed }"
                @click="
                  () => {
                    if (!matter.is_closed) slotCheckCredits();
                  }
                "
                variant="primary"
              >
                <b-icon
                  icon="circle-fill"
                  class="icon-check"
                  :class="{
                    'icon-check-active': !matter.use_automatic_credits,
                  }"
                  scale="0.7"
                ></b-icon>
              </b-button>
              <span
                :class="{ 'font-weight-bold': !matter.use_automatic_credits }"
                >Planificado</span
              >
            </div>
            <b-form-input
              id="input-credits"
              type="number"
              min="0"
              class="ml-2"
              v-model="$v.matter.credits.$model"
              :disabled="matter.use_automatic_credits || matter.is_closed"
              :state="validateState('credits')"
              aria-describedby="input-credits-feedback"
              size="sm"
            ></b-form-input>
            <b-tooltip
              :target="`input-credits`"
              variant="secondary"
              :noninteractive="true"
            >
              <div v-if="matter.credits < 0">
                Este campo debe ser mayor o igual a cero.
              </div>
            </b-tooltip>
          </div>
        </div>
      </b-form-group>
      <b-form-group
        v-if="
          !matter.use_matter_values &&
          ((institution && institution.id == 7) || $debug_change_unab)
        "
        label-cols="0"
        label-cols-sm="3"
        :label="
          $getVisibleNames(
            'manual.institutional_credits',
            true,
            'Créditos Institucionales'
          )
        "
        label-for="input-institutional_credits"
        class="label my-2 mx-0"
      >
        <b-form-input
          id="input-institutional_credits"
          type="number"
          min="0"
          v-model="$v.matter.institutional_credits.$model"
          :state="validateState('institutional_credits')"
          aria-describedby="input-institutional_credits-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-institutional_credits-feedback"
          >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="!matter.use_matter_values"
        label-cols="0"
        label-cols-sm="3"
        label="N° Estudiantes"
        label-for="input-suggested-suggested_students"
        class="label my-2 mx-0"
      >
        <b-form-input
          id="input-suggested-suggested_students"
          type="number"
          min="0"
          v-model="$v.matter.suggested_students.$model"
          :disabled="!allow_crud || matter.is_closed"
          :state="validateState('suggested_students')"
          aria-describedby="input-suggested-suggested_students-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback
          id="input-suggested-suggested_students-feedback"
          >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="!matter.use_matter_values"
        label-cols="0"
        label-cols-sm="3"
        label="N° Docentes"
        label-for="input-suggested-suggested_teachers"
        class="label my-2 mx-0"
      >
        <b-form-input
          id="input-suggested-suggested_teachers"
          type="number"
          min="0"
          :disabled="!allow_crud || matter.is_closed"
          v-model="$v.matter.suggested_teachers.$model"
          :state="validateState('suggested_teachers')"
          aria-describedby="input-suggested-suggested_teachers-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback
          id="input-suggested-suggested_teachers-feedback"
          >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        label-for="is_integrative"
        v-if="(institution && institution.show_base_ra) || $debug_change_duoc"
        class="label m-0 align-middle mb-2"
      >
        <template #label>
          <div class="weighing-label">
            <div class="label-content">
              Comparte
              {{ $getVisibleNames("teaching.basera", false, "RA Base") }}
              <span>
                <InfoTooltip
                  class="info-icon"
                  :tooltip_text="`Permite la asignación de un ${$getVisibleNames(
                    'teaching.basera',
                    false,
                    'RA Base'
                  )} ya relacionado a otra ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    false,
                    'Asignatura'
                  )}.
                  `"
                >
                </InfoTooltip>
              </span>
            </div>
          </div>
        </template>
        <div id="is_integrative" class="align-middle">
          <div class="d-flex">
            <div class="toggle-types noprint">
              <span
                :class="{
                  'font-weight-bold': matter.is_integrative,
                }"
                >Sí</span
              >
              <b-button
                class="check-type"
                :class="{ 'check-type-disabled': matter.is_closed }"
                @click="
                  () => {
                    if (!matter.is_closed)
                      matter.is_integrative = !matter.is_integrative;
                  }
                "
                variant="primary"
              >
                <b-icon
                  icon="circle-fill"
                  class="icon-check"
                  :class="{
                    'icon-check-active': !matter.is_integrative,
                  }"
                  scale="0.7"
                ></b-icon>
              </b-button>
              <span
                :class="{
                  'font-weight-bold': !matter.is_integrative,
                }"
                >No</span
              >
            </div>
          </div>
        </div>
      </b-form-group>
      <template v-if="isClosedMatter && !isNaN(matter.id)">
        <div class="text-right mb-3 mr-1">
          <b-button
            class="noprint"
            size="sm"
            v-b-tooltip.v-secondary.noninteractive="
              `Cambiar el estado de la ${$getVisibleNames(
                'mesh.egressprofilematter',
                false,
                'Asignatura'
              )}`
            "
            @click="changeStateMatter()"
          >
            <template v-if="matter && matter.is_closed">
              Abrir
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  false,
                  "Asignatura"
                )
              }}
              <b-icon class="btn-actions" icon="lock" scale="1"></b-icon>
            </template>
            <template v-else>
              Cerrar
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  false,
                  "Asignatura"
                )
              }}
              <b-icon class="btn-actions" icon="unlock" scale="1"></b-icon>
            </template>
          </b-button>
        </div>
      </template>
    </div>
    <!-- Comienza la sección de los botones -->
    <div class="row">
      <div
        v-if="show_delete_button && !matter.is_closed"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDeleteMatter"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
    <!-- <b-modal
      :id="`modal-old-matter-${matter.id}`"
      hide-footer
      :title="
        matter.matter == null
          ? 'Crear Asignatura Base'
          : 'Editar Asignatura Base'
      "
      size="md"
    >
      <OldMatterForm
        :old_matter="this.matters.find((x) => x.id == this.matter.matter)"
        @updated="SaveOldMatter"
      >
      </OldMatterForm>
    </b-modal> -->
    <b-modal
      :id="`new-formation-line-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.formationline',
        false,
        'Línea De Formación'
      )}`"
      size="md"
    >
      <FormationLineForm
        v-if="career"
        :faculty_id="career.faculty"
        @created="slotCreatedFormationLine"
      ></FormationLineForm>
    </b-modal>
    <b-modal
      :id="`edit-formation-line-modal-${matter.formation_line}`"
      :hide-footer="true"
      :title="`Editar ${$getVisibleNames(
        'mesh.formationline',
        false,
        'Línea De Formación'
      )}`"
      size="md"
    >
      <FormationLineForm
        v-if="
          matter.formation_line != null &&
          formationLines.find((x) => x.id == matter.formation_line) &&
          career
        "
        :FormationLine="
          formationLines.find((x) => x.id == matter.formation_line)
        "
        :faculty_id="career.faculty"
        @updated="
          $bvModal.hide(`edit-formation-line-modal-${matter.formation_line}`)
        "
      ></FormationLineForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  minValue,
  numeric,
} from "vuelidate/lib/validators";
import { generateUniqueId, toast } from "@/utils/utils";
import "vue-multiselect/dist/vue-multiselect.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "MatterForm",
  mixins: [validationMixin],
  components: {
    vSelect,
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    TimeAllocationComponent: () => import("./TimeAllocationComponent.vue"),
    // OldMatterForm: () => import("./OldMatterForm.vue"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    FormationLineForm: () =>
      import(
        "@/components/mesh/CurricularResources/FormationLine/FormationLineForm"
      ),
    MatterTimeAllocationComponent: () =>
      import("./MatterTimeAllocationComponent.vue"),
    // ResizeAuto: () => import("@/components/reusable/ResizeAuto"),
  },
  props: {
    Matter: {
      type: Object,
    },
    egress_profile_id: {
      type: Number,
      required: true,
    },
    courses_wrapper_id: {
      default: null,
    },
    show_title: {
      type: Boolean,
      default: false,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    tabs_mode: {
      type: Boolean,
      default: true,
    },
    allow_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      matter: {
        id: this.Matter ? this.Matter.id : generateUniqueId(),
        // code: this.Matter ? this.Matter.code : "",
        order: this.Matter ? this.Matter.order : 100,
        plan_level: this.Matter ? this.Matter.plan_level : 1,
        strategy: this.Matter ? this.Matter.strategy : "",
        toggle_in_weeks: this.Matter ? this.Matter.toggle_in_weeks : false,
        study_environments: this.Matter ? this.Matter.study_environments : [],
        suggested_students: this.Matter ? this.Matter.suggested_students : 0,
        suggested_teachers: this.Matter ? this.Matter.suggested_teachers : 0,
        regime: this.Matter ? this.Matter.regime : null,
        is_integrative: this.Matter ? this.Matter.is_integrative : false,
        formation_area: this.Matter ? this.Matter.formation_area : null,
        matter: this.Matter ? this.Matter.matter : null,
        formation_line: this.Matter ? this.Matter.formation_line : null,
        matter_types: this.Matter ? this.Matter.matter_types : [],
        matter_contributions: this.Matter
          ? this.Matter.matter_contributions
          : [],
        modalities: this.Matter ? this.Matter.modalities : [],
        egress_profile: this.Matter
          ? this.Matter.egress_profile
          : this.egress_profile_id,
        prerequisites: this.Matter ? this.Matter.prerequisites : [],
        competences: this.Matter ? this.Matter.competences : [],
        campuses: this.Matter ? this.Matter.campuses : [],
        weeks: this.Matter ? this.Matter.weeks : 0,
        credits: this.Matter ? this.Matter.credits : 0,
        institutional_credits: this.Matter
          ? this.Matter.institutional_credits
          : 0,
        use_matter_values: this.Matter ? this.Matter.use_matter_values : true,
        use_automatic_credits: this.Matter
          ? this.Matter.use_automatic_credits
          : true,
        is_closed: this.Matter ? this.Matter.is_closed : false,
      },
      old_matter: {
        id: this.Matter ? this.Matter.matter : generateUniqueId(),
        name: "",
        code: "",
        automatic_achievement_weighing: true,
        modalities: [],
        study_environments: [],
        weeks: 0,
        credits: 0,
        institutional_credits: 0,
        is_elective: false,
        toggle_in_weeks: false,
        suggested_students: 0,
        suggested_teachers: 0,
        is_closed: false,
        use_automatic_credits: true,
      },
      matter_hours: [],
      old_matter_hours: [],
      input_search: "",
      step_matter: false,
      reload_matter_time_allocation: 0,
      tabs_index: 0,
      matter_codes: [],
      sortBy: "",
      sortDesc: false,
    };
  },
  validations: {
    matter: {
      matter: {},
      order: {
        required,
        numeric,
        minValue: minValue(1),
      },
      regime: {
        required,
      },
      study_environments: {},
      suggested_students: {},
      suggested_teachers: {},
      formation_area: {},
      formation_line: {},
      matter_types: {},
      matter_contributions: {},
      modalities: {},
      is_integrative: {},
      plan_level: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue() {
          if (
            parseFloat(this.matter.plan_level) <=
            parseFloat(this.egressProfile.semester_amount)
          )
            return true;
          else return false;
        },
      },
      weeks: {
        required,
        minValue: minValue(1),
      },
      credits: {
        required,
        minValue: minValue(0),
      },
      institutional_credits: {
        minValue: minValue(0),
      },
    },
    old_matter: {
      name: {
        required,
        minLength: minLength(1),
      },
      code: {
        isUnique() {
          if (this.old_matter.code == "") return true;
          else return this.SameMatterCode;
        },
      },
      modalities: {},
      study_environments: {},
      weeks: { minValue: minValue(1) },
      credits: { minValue: minValue(0) },
      institutional_credits: {
        minValue: minValue(0),
      },
      suggested_students: {},
      suggested_teachers: {},
    },
    validationGroup: ["matter", "old_matter"],
  },
  computed: {
    ...mapGetters({
      cycles: names.CYCLES,
      regimes: names.REGIMES,
      formationAreas: names.FORMATION_AREAS,
      formationLines: names.FORMATION_LINES,
      matterTypes: names.MATTER_TYPES,
      profile_types: names.PROFILE_TYPES,
      matterContributions: names.MATTER_CONTRIBUTIONS,
      modalities: names.MODALITIES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      competences: names.COMPETENCES,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      institution: "getInstitution",
      user: "getUser",
      matters: names.MATTERS,
      campuses: names.CAMPUSES,
      careers: names.CAREERS,
      egress_profiles: names.EGRESS_PROFILES,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      time_allocations: names.TIME_ALLOCATIONS,
      courses_wrapper: names.COURSES_WRAPPER,
      faculties: names.FACULTIES,
    }),
    isClosedMatter() {
      if (
        this.user.is_superuser ||
        this.$hasObjectPermission(
          "special.close_base_matter",
          "special",
          this.old_matter.id
        )
        // ||(this.user_access && this.user_access.position == 1)
      ) {
        return true;
      } else return false;
    },
    isClosedOldMatter() {
      if (
        this.user.is_superuser ||
        this.$hasObjectPermission(
          "special.close_base_matter",
          "special",
          this.old_matter.id
        )
        // ||
        // (this.user_access && this.user_access.position == 1)
      ) {
        return true;
      } else return false;
    },
    egressProfile() {
      if (!this.egress_profile_id) return [];
      return this.egress_profiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    coursesWrapper() {
      if (!this.courses_wrapper_id) return null;
      return this.courses_wrapper.find((x) => x.id == this.courses_wrapper_id);
    },
    regimesList() {
      if (!this.egressProfile) return [];
      //Bimestral(2) - Trimestral(3) - Semestral(6)
      const months_amount_egress_profile = this.regimes.find(
        (x) => x.id == this.egressProfile.regime
      );
      if (months_amount_egress_profile) {
        if (months_amount_egress_profile.months_amount == 6)
          return this.regimes
            .filter((x) => [6, 12].includes(x.months_amount))
            .sort((a, b) => b.months_amount - a.months_amount);
        else if (months_amount_egress_profile.months_amount == 3)
          return this.regimes
            .filter((x) => [3, 6].includes(x.months_amount))
            .sort((a, b) => b.months_amount - a.months_amount);
        else if (months_amount_egress_profile.months_amount == 2)
          return this.regimes
            .filter((x) => [2, 3].includes(x.months_amount))
            .sort((a, b) => b.months_amount - a.months_amount);
      } else
        return this.regimes
          .filter((x) => [6, 12].includes(x.months_amount))
          .sort((a, b) => b.months_amount - a.months_amount);
      return [];
    },
    fieldsMatterCode() {
      return [
        { key: "selected", label: "", display_column: true },
        {
          key: "name",
          label: "Nombre",
          display_column: true,
        },
        {
          key: "code",
          label: String(
            this.$getVisibleNames("manual.matter_code", false, "Código")
          ),
          display_column: true,
        },
        {
          key: "modalities",
          label: String(
            this.$getVisibleNames(
              "mesh.modality",
              false,
              "Modalidad de Asignatura"
            )
          ),
          display_column: true,
        },
        {
          key: "formation_area",
          label: String(
            this.$getVisibleNames(
              "mesh.formationarea",
              false,
              "Área De Formación"
            )
          ),
          display_column: true,
        },
      ];
    },
    SameMatterName() {
      if (!this.old_matter) return false;
      const SameMatterName = this.matter_codes.find(
        (matter) =>
          this.$equals(this.old_matter.name, matter.name) &&
          this.old_matter.id != matter.id
      );
      if (SameMatterName) return false;
      return true;
    },
    SameMatterCode() {
      if (!this.old_matter) return false;
      const SameMatterCode = this.matter_codes.find(
        (matter) =>
          this.$equals(this.old_matter.code, matter.code) &&
          this.old_matter.id != matter.id
      );
      if (SameMatterCode) return false;
      return true;
    },
    profileType() {
      if (!this.egressProfile) return [];
      return this.profile_types.find(
        (x) => x.id == this.egressProfile.egress_profile_type
      );
    },
    formationLinesList() {
      if (!this.career) return [];
      return this.formationLines.filter(
        (x) =>
          x.faculties.includes(this.career.faculty) ||
          this.matter.formation_line == x.id
      );
    },
    orderSugested() {
      let list = this.egress_profiles_matters.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
      if (list.length == 0) {
        list = this.egress_profiles_matters.filter(
          (x) => x.egress_profile == this.egress_profile_id
        );
      }
      let order = 0;
      list.forEach((x) => {
        if (x.order > order) {
          order = x.order;
        }
      });
      order += 1;
      return order;
    },
    totalPedagogical() {
      let count = 0;
      if (!this.matter.use_matter_values)
        this.matter_hours
          .filter((x) => x.uses_module_minutes == true)
          .forEach((element) => {
            if (
              element.counts_towards_credits &&
              element.hours &&
              element.hours >= 0
            ) {
              count += parseFloat(element.hours);
            }
          });
      else
        this.old_matter_hours
          .filter((x) => x.uses_module_minutes == true)
          .forEach((element) => {
            if (
              element.counts_towards_credits &&
              element.hours &&
              element.hours >= 0
            ) {
              count += parseFloat(element.hours);
            }
          });
      return count;
    },
    totalChronological() {
      let count = 0;
      if (!this.matter.use_matter_values)
        this.matter_hours
          .filter((x) => x.uses_module_minutes == false)
          .forEach((element) => {
            if (
              element.counts_towards_credits &&
              element.hours &&
              element.hours >= 0
            ) {
              count += parseFloat(element.hours);
            }
          });
      else
        this.old_matter_hours
          .filter((x) => x.uses_module_minutes == false)
          .forEach((element) => {
            if (
              element.counts_towards_credits &&
              element.hours &&
              element.hours >= 0
            ) {
              count += parseFloat(element.hours);
            }
          });
      return count;
    },
    totalHours() {
      if (!this.profileType) return 0;
      const total_hours =
        this.totalPedagogical * (this.profileType.module_minutes / 60) +
        this.totalChronological;
      return total_hours ? total_hours : 0;
    },
    creditSctChile() {
      if (!this.profileType) return 0;
      const credits = Math.round(
        this.totalHours / parseFloat(this.institution.hours_per_credit)
      );
      return credits ? credits : 0;
    },
  },
  methods: {
    changeStateMatter() {
      if (!this.matter.is_closed) {
        const payload = {
          egress_profile_matter_id: this.matter.id,
          item: { is_closed: !this.matter.is_closed },
        };
        this.$store
          .dispatch(names.PATCH_EGRESS_PROFILE_MATTER, payload)
          .then((response) => {
            this.matter.is_closed = response.is_closed;
          });
      } else {
        this.$restful
          .Get(
            `/mesh/reopen_egress_profile_matter/?egress_profile_matter=${this.matter.id}`
          )
          .then(() => {
            this.matter.is_closed = !this.matter.is_closed;
          });
      }
    },
    changeStateOldMatter() {
      const payload = {
        matter_id: this.old_matter.id,
        item: {
          is_closed: !this.old_matter.is_closed,
        },
      };
      this.$store.dispatch(names.PATCH_MATTER, payload).then((response) => {
        this.old_matter.is_closed = response.is_closed;
      });
    },
    ChangeOldMatter(id) {
      this.copyMatterToEgressProfileMatter(id);
      this.$bvModal.hide("bv-modal-matter-select");
    },
    copyMatterToEgressProfileMatter(matter_id) {
      this.matter.matter = matter_id;
      this.$store.dispatch(names.FETCH_MATTER, matter_id).then((response) => {
        this.old_matter = { ...response };
        this.matter.formation_area = response.formation_area;
        this.matter.matter_types = response.matter_types;
        this.matter.modalities = response.modalities;
        this.matter.matter_contributions = response.matter_contributions;
        this.matter.study_environments = response.study_environments;
        this.matter.credits = response.credits;
        this.matter.institutional_credits = response.institutional_credits;
        this.matter.weeks = response.weeks;
        this.matter.toggle_in_weeks = response.toggle_in_weeks;
        this.matter.suggested_students = response.suggested_students;
        this.matter.suggested_teachers = response.suggested_teachers;
        this.matter.use_automatic_credits = response.use_automatic_credits;
        this.$store
          .dispatch(names.FETCH_MATTER_TIME_ALLOCATIONS, {
            matter_id: matter_id,
          })
          .then((old_matter_hours_list) => {
            old_matter_hours_list.forEach((old_matter_hour) => {
              const index = this.matter_hours.findIndex(
                (matter_hour) =>
                  matter_hour.time_allocation == old_matter_hour.time_allocation
              );
              if (index != -1) {
                this.matter_hours[index].hours = old_matter_hour.hours;
                this.matter_hours[index].counts_towards_credits =
                  old_matter_hour.counts_towards_credits;
                this.matter_hours[index].attendance_requirement =
                  old_matter_hour.attendance_requirement;
                this.matter_hours[index].tem_hours = Math.round(
                  parseFloat(
                    old_matter_hour.hours >= 0 ? old_matter_hour.hours : 0
                  ) / parseFloat(response.weeks)
                );
              }
              if (this.matter.use_matter_values) {
                this.reload_matter_time_allocation += 1;
              }
            });
          });
      });
    },
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(row.code, this.input_search) ||
        this.$filtered(
          this.getFormationArea(row.formation_area),
          this.input_search
        ) ||
        row.modalities.filter((x) =>
          this.$filtered(this.getModality(x), this.input_search)
        ).length > 0 ||
        this.$filtered(this.getRegime(row.regime), this.input_search)
      ) {
        return true;
      } else {
        return false;
      }
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    resetOldMatter() {
      this.old_matter.id = generateUniqueId();
      this.matter.matter = null;
      this.old_matter.code = "";
      this.old_matter.name = "";
      this.old_matter.automatic_achievement_weighing = false;
    },
    slotCheckCredits() {
      if (!this.matter.use_matter_values) {
        this.matter.use_automatic_credits = !this.matter.use_automatic_credits;
        if (this.matter.use_automatic_credits == true) {
          this.matter.credits = this.creditSctChile;
        }
      } else {
        this.old_matter.use_automatic_credits =
          !this.old_matter.use_automatic_credits;
        if (
          this.old_matter.use_automatic_credits == true &&
          !this.old_matter.is_closed
        ) {
          this.old_matter.credits = this.creditSctChile;
        }
      }
    },
    // MatterNameModal() {
    //   this.$root.$emit("bv::show::modal", `modal-old-matter-${this.matter.id}`);
    // },
    dropdownShouldOpen(VueSelect) {
      if (this.formationLinesList.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpen2(VueSelect) {
      if (this.studyEnvironments.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.matter[name];
      return $dirty ? !$error : null;
    },
    validateOldMatter(name) {
      const { $dirty, $error } = this.$v.old_matter[name];
      return $dirty ? !$error : null;
    },
    // SaveOldMatter(old_matter) {
    //   if (this.matter.matter == null) {
    //     this.$store.dispatch(names.POST_MATTER, old_matter).then((response) => {
    //       this.matter.matter = response.id;
    //       this.$bvModal.hide(`modal-old-matter-${this.matter.id}`);
    //     });
    //   } else {
    //     this.$store
    //       .dispatch(names.PATCH_MATTER, {
    //         matter_id: old_matter.id,
    //         item: {
    //           name: old_matter.name,
    //           description: old_matter.description,
    //           order: old_matter.order,
    //           plan_level: old_matter.plan_level,
    //           week_theoric_hours: old_matter.week_theoric_hours,
    //           week_lab_hours: old_matter.week_lab_hours,
    //           week_ground_hours: old_matter.week_ground_hours,
    //           week_assistantship_hours: old_matter.week_assistantship_hours,
    //           week_autonomous_hours: old_matter.week_autonomous_hours,
    //           total_autonomous_hours: old_matter.total_autonomous_hours,
    //           formation_area: old_matter.formation_area,
    //           matter_types: old_matter.matter_types,
    //           matter_contributions: old_matter.matter_contributions,
    //           modality: old_matter.modality,
    //           weeks: old_matter.weeks,
    //         },
    //       })
    //       .then(() => {
    //         this.$bvModal.hide(`modal-old-matter-${this.matter.id}`);
    //       });
    //   }
    //   this.$bvModal.hide(`modal-old-matter-${this.matter.id}`);
    // },
    getRegime(regime_id) {
      if (!this.regimes) return "N/A";
      const regime = this.regimes.find((x) => x.id == regime_id);
      return regime ? regime.name : "N/A";
    },
    getModality(modality_id) {
      if (!this.modalities) return "N/A";
      const modality = this.modalities.find((x) => x.id == modality_id);
      return modality ? modality.name : "N/A";
    },
    getFormationArea(formation_area_id) {
      if (!this.formationAreas) return "N/A";
      const formation_area = this.formationAreas.find(
        (x) => x.id == formation_area_id
      );
      return formation_area ? formation_area.name : "N/A";
    },
    save() {
      if (this.matter.use_matter_values) {
        this.matter.weeks = this.old_matter.weeks;
      } else {
        if (this.old_matter.weeks <= 0)
          this.old_matter.weeks = this.matter.weeks;
      }
      const profile_matters = this.egress_profiles_matters.filter(
        (x) =>
          x.matter == this.matter.matter &&
          x.egress_profile == this.matter.egress_profile
      );
      if (
        (profile_matters.length > 0 && isNaN(this.matter.id)) ||
        (profile_matters.length > 0 &&
          !profile_matters.find((matter) => matter.id == this.matter.id))
      ) {
        return this.$swal({
          title:
            "En este " +
            this.$getVisibleNames(
              "mesh.egressprofile",
              false,
              "Perfil de Egreso"
            ) +
            " ya cuenta con la " +
            this.$getVisibleNames(
              "mesh.egressprofilematter",
              false,
              "Asignatura"
            ) +
            " seleccionada.",
        });
      }

      this.$v.validationGroup.$touch();
      if (this.$v.validationGroup.$anyError) {
        return;
      }
      if (this.matter.use_matter_values) {
        if (isNaN(this.old_matter.id)) {
          const payload = {
            name: this.old_matter.name,
            code: this.old_matter.code,
            is_elective: this.old_matter.is_elective,
            formation_area: this.matter.formation_area,
            modalities: this.old_matter.modalities,
            matter_types: this.matter.matter_types,
            matter_contributions: this.matter.matter_contributions,
            evaluation_instruments: this.matter.evaluation_instruments,
            study_environments: this.old_matter.study_environments,
            methodological_strategies: [],
            weeks: this.old_matter.weeks,
            credits: this.old_matter.credits,
            institutional_credits: this.old_matter.institutional_credits,
            suggested_students: this.old_matter.suggested_students,
            suggested_teachers: this.old_matter.suggested_teachers,
            automatic_achievement_weighing:
              this.old_matter.automatic_achievement_weighing,
            toggle_in_weeks: this.old_matter.toggle_in_weeks,
            use_automatic_credits: this.old_matter.use_automatic_credits,
          };
          this.$store.dispatch(names.POST_MATTER, payload).then((response) => {
            this.matter.matter = response.id;
            this.old_matter.id = response.id;
            if (isNaN(this.matter.id)) this.create();
            else this.update();
          });
        } else {
          const payload = {
            matter_id: this.old_matter.id,
            item: {
              name: this.old_matter.name,
              code: this.old_matter.code,
              is_elective: this.old_matter.is_elective,
              // formation_area: this.matter.formation_area,
              modalities: this.old_matter.modalities,
              // matter_types: this.matter.matter_types,
              // matter_contributions: this.matter.matter_contributions,
              study_environments: this.old_matter.study_environments,
              weeks: this.old_matter.weeks,
              credits: this.old_matter.credits,
              institutional_credits: this.old_matter.institutional_credits,
              suggested_students: this.old_matter.suggested_students,
              suggested_teachers: this.old_matter.suggested_teachers,
              automatic_achievement_weighing:
                this.old_matter.automatic_achievement_weighing,
              toggle_in_weeks: this.old_matter.toggle_in_weeks,
              use_automatic_credits: this.old_matter.use_automatic_credits,
            },
          };
          this.$store.dispatch(names.PATCH_MATTER, payload).then((response) => {
            this.old_matter = { ...response };
            this.matter.matter = response.id;
            if (!isNaN(this.matter.matter) && this.matter.matter != null) {
              if (isNaN(this.matter.id)) this.create();
              else this.update();
            }
          });
        }
      } else {
        // !this.matter.use_matter_values
        if (isNaN(this.old_matter.id)) {
          const payload = {
            name: this.old_matter.name,
            code: this.old_matter.code,
            is_elective: this.old_matter.is_elective,
            formation_area: this.matter.formation_area,
            modalities: this.matter.modalities,
            matter_types: this.matter.matter_types,
            matter_contributions: this.matter.matter_contributions,
            evaluation_instruments: this.matter.evaluation_instruments,
            study_environments: this.matter.study_environments,
            methodological_strategies: [],
            weeks: this.matter.weeks,
            credits: this.matter.credits,
            institutional_credits: this.matter.institutional_credits,
            suggested_students: this.matter.suggested_students,
            suggested_teachers: this.matter.suggested_teachers,
            automatic_achievement_weighing:
              this.old_matter.automatic_achievement_weighing,
            toggle_in_weeks: this.matter.toggle_in_weeks,
            use_automatic_credits: this.matter.use_automatic_credits,
          };
          this.$store.dispatch(names.POST_MATTER, payload).then((response) => {
            this.matter.matter = response.id;
            this.old_matter.id = response.id;
            if (isNaN(this.matter.id)) this.create();
            else this.update();
          });
        } else {
          const payload = {
            matter_id: this.old_matter.id,
            item: {
              name: this.old_matter.name,
              code: this.old_matter.code,
              is_elective: this.old_matter.is_elective,
              automatic_achievement_weighing:
                this.old_matter.automatic_achievement_weighing,
            },
          };
          this.$store.dispatch(names.PATCH_MATTER, payload).then((response) => {
            this.old_matter = { ...response };
            this.matter.matter = response.id;
            if (!isNaN(this.matter.matter) && this.matter.matter != null) {
              if (isNaN(this.matter.id)) this.create();
              else this.update();
            }
          });
        }
      }
    },
    askForDeleteMatter() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.egressprofilematter",
          false,
          "Asignatura"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) this.deleteMatter();
      });
    },
    create() {
      this.matter.campuses = this.egress_profiles.find(
        (x) => x.id == this.egress_profile_id
      ).campuses;
      if (!this.allow_crud) {
        this.$emit("create", this.matter);
        return;
      }
      this.$store
        .dispatch(names.POST_EGRESS_PROFILE_MATTER, this.matter)
        .then((response) => {
          if (this.courses_wrapper_id && this.courses_wrapper_id != null) {
            let payload = this.coursesWrapper;
            payload.egress_profile_matters.push(response.id);
            this.$store.dispatch(names.UPDATE_COURSE_WRAPPER, payload);
          }
          this.$restful.Get(
            `mesh/copy_egress_profile_matter_descriptors/?original_matter=${response.matter}&target_epm=${response.id}`
          );
          this.$restful
            .Get(
              `teaching/update_ra_micro_ec_macro_weighing/?matter=${response.matter}`
            )
            .then(() => {
              this.$store.dispatch(
                names.FETCH_EVALUATION_CRITERIAS_MACROS,
                response.matter
              );
              this.$store.dispatch(names.FETCH_STUDY_UNITS, response.matter);
            });
          if (!this.matters.find((x) => x.id == response.matter))
            this.$store.dispatch(names.FETCH_MATTER, response.matter);
          this.$emit("created", response);
          if (this.matter.use_matter_values)
            this.saveOldMatterHours(response.matter);
          else this.saveMatterHours(response.id);
          toast(
            this.$getVisibleNames(
              "mesh.egressprofilematter",
              false,
              "Asignatura"
            ) + " creada."
          );
        });
    },
    update() {
      this.matter.campuses = this.egress_profiles.find(
        (x) => x.id == this.egress_profile_id
      ).campuses;
      if (!this.allow_crud) {
        this.$emit("update", this.matter);
        return;
      }
      if (!this.matter.is_closed) {
        const payload = {
          egress_profile_matter_id: this.matter.id,
          item: {
            id: this.matter.id,
            order: this.matter.order,
            plan_level: this.matter.plan_level,
            toggle_in_weeks: this.matter.toggle_in_weeks,
            study_environments: this.matter.study_environments,
            suggested_students: this.matter.suggested_students,
            suggested_teachers: this.matter.suggested_teachers,
            regime: this.matter.regime,
            is_integrative: this.matter.is_integrative,
            formation_area: this.matter.formation_area,
            matter: this.matter.matter,
            formation_line: this.matter.formation_line,
            matter_types: this.matter.matter_types,
            matter_contributions: this.matter.matter_contributions,
            modalities: this.matter.modalities,
            egress_profile: this.matter.egress_profile,
            campuses: this.matter.campuses,
            weeks: this.matter.weeks,
            credits: this.matter.credits,
            institutional_credits: this.matter.institutional_credits,
            use_matter_values: this.matter.use_matter_values,
          },
        };
        this.$store
          .dispatch(names.PATCH_EGRESS_PROFILE_MATTER, payload)
          .then((response) => {
            this.$restful
              .Get(
                `teaching/update_ra_micro_ec_macro_weighing/?matter=${response.matter}`
              )
              .then(() => {
                this.$store.dispatch(
                  names.FETCH_EVALUATION_CRITERIAS_MACROS,
                  response.matter
                );
                this.$store.dispatch(names.FETCH_STUDY_UNITS, response.matter);
              });
            this.$emit("updated", response);
            if (this.matter.use_matter_values)
              this.saveOldMatterHours(response.matter);
            else this.saveMatterHours(response.id);
            if (!this.matters.find((x) => x.id == response.matter))
              this.$store.dispatch(names.FETCH_MATTER, response.matter);
            toast(
              this.$getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              ) + " actualizada."
            );
          });
      } else {
        this.$restful
          .Get(
            `teaching/update_ra_micro_ec_macro_weighing/?matter=${this.matter.matter}`
          )
          .then(() => {
            this.$store.dispatch(
              names.FETCH_EVALUATION_CRITERIAS_MACROS,
              this.matter.matter
            );
            this.$store.dispatch(names.FETCH_STUDY_UNITS, this.matter.matter);
          });
        this.$emit("updated", this.matter);
        if (this.matter.use_matter_values)
          this.saveOldMatterHours(this.matter.matter);
        else this.saveMatterHours(this.matter.id);
        if (!this.matters.find((x) => x.id == this.matter.matter))
          this.$store.dispatch(names.FETCH_MATTER, this.matter.matter);
        toast(
          this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ) + " actualizada."
        );
      }
    },
    slotUpdateMatterHours(matter_hours) {
      this.matter_hours = matter_hours;
    },
    slotUpdateOldMatterHours(old_matter_hours) {
      this.old_matter_hours = old_matter_hours;
    },
    async saveMatterHours(egress_profile_matter_id) {
      for await (let hour of this.matter_hours) {
        if (isNaN(hour.id)) {
          hour.egress_profile_matter = egress_profile_matter_id;
          await this.$store
            .dispatch(names.POST_EP_MATTER_TIME_ALLOCATION, hour)
            .then((response) => {
              hour.id = response.id;
            });
        } else {
          hour.egress_profile_matter = egress_profile_matter_id;
          await this.$store.dispatch(
            names.UPDATE_EP_MATTER_TIME_ALLOCATION,
            hour
          );
        }
      }
    },
    async saveOldMatterHours(matter_id) {
      for await (let hour of this.old_matter_hours) {
        if (isNaN(hour.id)) {
          hour.matter = matter_id;
          await this.$store
            .dispatch(names.POST_MATTER_TIME_ALLOCATION, hour)
            .then((response) => {
              hour.id = response.id;
            });
        } else {
          hour.matter = matter_id;
          await this.$store.dispatch(names.UPDATE_MATTER_TIME_ALLOCATION, hour);
        }
      }
    },
    deleteMatter() {
      if (!this.allow_crud) {
        this.$emit("delete", this.matter);
        return;
      }
      this.$store
        .dispatch(names.DELETE_EGRESS_PROFILE_MATTER, this.matter.id)
        .then(() => {
          this.$emit("deleted", this.matter);
          toast(
            this.$getVisibleNames(
              "mesh.egressprofilematter",
              false,
              "Asignatura"
            ) + " eliminada."
          );
        });
    },
    openModalFormationLine(is_created = true) {
      if (this.faculties.length == 0)
        this.$store.dispatch(names.FETCH_FACULTIES);
      if (is_created) this.$bvModal.show(`new-formation-line-modal`);
      else if (this.matter.formation_line != null)
        this.$bvModal.show(
          `edit-formation-line-modal-${this.matter.formation_line}`
        );
    },
    slotCreatedFormationLine(respone) {
      this.matter.formation_line = respone.id;
      this.$bvModal.hide("new-formation-line-modal");
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id)
      .then((response) => {
        this.$store.dispatch(names.FETCH_CAREER, response.career);
      });
    if (
      !(
        (this.institution && this.institution.id == 12) ||
        this.$debug_change_duoc
      ) &&
      isNaN(this.matter.id)
    ) {
      this.matter.use_matter_values = false;
    }
    this.$store.dispatch(names.FETCH_PROFILE_TYPES);
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_REGIMES);
    this.$store.dispatch(names.FETCH_FORMATION_AREAS);
    this.$store.dispatch(names.FETCH_MODALITIES);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS_TIME_ALLOCATION);
    this.$store.dispatch(names.FETCH_MATTER_CODES).then((response) => {
      this.matter_codes = response;
    });
    if (!isNaN(this.old_matter.id)) {
      this.$store
        .dispatch(names.FETCH_MATTER, this.old_matter.id)
        .then((response) => {
          this.old_matter = { ...response };
          this.step_matter = true;
        });
    } else this.step_matter = true;
  },
  watch: {
    creditSctChile() {
      if (!this.matter.use_matter_values) {
        if (
          this.profileType &&
          this.profileType.module_minutes != 0 &&
          this.matter.weeks != 0
        )
          this.matter.institutional_credits = Math.round(
            this.totalPedagogical / this.matter.weeks +
              ((this.totalChronological / this.matter.weeks) * 60) /
                this.profileType.module_minutes
          );
        if (this.matter.use_automatic_credits == true)
          this.matter.credits = this.creditSctChile;
      } else {
        if (
          this.old_matter.use_automatic_credits == true &&
          !this.old_matter.is_closed
        )
          this.old_matter.credits = this.creditSctChile;
        if (
          this.profileType &&
          this.profileType.module_minutes != 0 &&
          this.old_matter.weeks != 0 &&
          !this.old_matter.is_closed
        )
          this.old_matter.institutional_credits = Math.round(
            this.totalPedagogical / this.old_matter.weeks +
              ((this.totalChronological / this.old_matter.weeks) * 60) /
                this.profileType.module_minutes
          );
      }
    },
  },
  mounted() {
    if (isNaN(this.matter.id)) this.matter.order = this.orderSugested;
    if (this.matter.weeks == 0) {
      if (this.profileType) {
        if (
          this.$equals(this.profileType.name, "Pregrado") &&
          this.institution &&
          this.institution.actual_period &&
          this.institution.actual_period.weeks > 0
        ) {
          this.matter.weeks = this.institution.actual_period.weeks;
        }
      }
    }
  },
};
</script>

<style scoped>
.btn-add {
  border: none;
  background: transparent;
  font-size: 13pt;
  padding: 0;
  margin-left: 0.7rem;
}
.toggle-types {
  display: flex;
  float: right;
  /* margin-right: 1em; */
  /* background: rgb(230, 230, 230); */
  padding: 0.3em 1.8em;
  border-radius: 3px;
}
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.check-type-disabled {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ) !important;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.matter-name {
  border: 1px solid #17a2b8;
}
.icon-check {
  color: white;
  transform: translate(-69%, -50%);
  transition: 0.2s;
}
.icon-check-active {
  transform: translate(15%, -50%) !important;
}
.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}
#input-order {
  margin-top: 0.8rem;
  margin-bottom: 0.2rem;
}
#input-total-class-hours,
#input-total-autonomous-hours {
  border: 1px solid #ced4da;
  transition: border-width 0.3s linear;
  -webkit-transition: border-color 0.3s ease-out;
  -moz-transition: border-color 0.3s ease-out;
  -o-transition: border-color 0.3s ease-out;
  -ms-transition: border-color 0.3s ease-out;
  transition: border-color 0.3s ease-out;
}
#input-module-class-hours {
  font-size: var(--primary-font-size);
}
.highlight-input {
  border: solid var(--primary-color) !important;
  border-width: 3px;
  transition: border 0.4s ease-out;
}
.btn-select-default,
.btn-clear-inputs {
  border: none;
  background: transparent;
  font-size: 11pt;
  padding: 0;
  margin-left: 0.7rem;
}
.btn-clear-inputs {
  font-size: 10pt;
}

#total_hours {
  margin-left: 0.6rem;
}
#credit {
  /* margin-left: 0.2rem; */
  max-height: 2.3rem;
}

#credit,
#total_hours {
  font-size: var(--primary-font-size);
}
textarea {
  width: 100%;
}

.form-group >>> .col-form-label {
  font-size: var(--secondary-font-size) !important;
}
.label {
  /* font-size: var(--thirth-font-size); */
  font-size: var(--secondary-font-size);
  /* margin-bottom: -1% !important; */
}
.form-group >>> .label-matter {
  margin-top: 0.2rem;
  padding-right: 10px !important;
  font-size: var(--secondary-font-size);
}
.custom-input {
  margin-bottom: -3.4% !important;
}
.custom-row {
  display: flex;
  margin-top: -2.4% !important;
  margin-bottom: -2.4% !important;
  width: 100%;
}
.row-code {
  display: flex;
  min-height: 3rem;
  width: 100% !important;
  /* margin-bottom: -1.6% !important; */
  margin-top: 0;
}
.label-code {
  font-size: var(--secondary-font-size);
  margin-top: 3.5% !important;
  margin-left: 0.4em;
}
.input-code {
  width: 98%;
  margin-left: 1%;
  transform: translateY(-10%);
}

#input-code-live-feedback {
  margin-top: -1.4%;
  margin-left: 1%;
}

.total-modules {
  margin-top: 0 !important;
}

/* .input-modality {
  font-size: 0.875rem !important;
} */
.v-select >>> .vs__search,
.v-select >>> .vs__search:focus,
.v-select >>> .vs__selected {
  font-size: 0.875rem !important;
  color: #495057 !important;
}

.v-select >>> .vs__clear {
  margin-bottom: 0.2rem !important;
}
.total-modules-value {
  font-weight: normal;
}

.total-hours-value {
  margin-left: 0.1em !important;
  font-weight: normal;
}
.credits-value {
  margin-left: 0.1em;
  font-size: var(--primary-font-size) !important;
  font-weight: normal;
  line-height: 1;
  max-height: 1.5rem;
  margin-top: 0.2rem;
}

.weighing-label,
.total-modules-label {
  display: flex;
}
.weighing-info::after,
.total-modules-label::after {
  vertical-align: top;
}
.label-content {
  width: max-content;
}
.info-icon {
  position: relative;
  vertical-align: top;
  height: 12px;
  width: 12px;
}
.total-modules-label > .label-content {
  max-width: 7rem;
}
@media (max-width: 991px) {
  .info-icon {
    margin-left: 0;
  }
}
</style>