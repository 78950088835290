var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-div"},[_c('div',[_c('label',{staticClass:"study-unit-label"},[_vm._v(_vm._s(_vm.study_unit.name))]),_c('p',[_c('SentenceContainer',{attrs:{"Sentence":_vm.study_unit}})],1),_vm._m(0),(_vm.allows_crud)?_c('div',{directives:[{name:"can",rawName:"v-can",value:('teaching.change_studyunit'),expression:"'teaching.change_studyunit'"}],staticClass:"d-flex justify-content-center"},[((_vm.institution && _vm.institution.show_base_ra) || _vm.$debug_change_duoc)?_c('LinkingCompetenceToRa',{staticClass:"noprint",attrs:{"study_unit_id":_vm.study_unit_id,"egress_profile_matter_id":_vm.egress_profile_matter_id,"allows_crud":_vm.allows_crud}}):_vm._e(),_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
          `Editar ${_vm.$getVisibleNames('teaching.ramicro', false, 'RA Micro')}`
        ),expression:"\n          `Editar ${$getVisibleNames('teaching.ramicro', false, 'RA Micro')}`\n        ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`modal-study-unit-${_vm.study_unit.id}`)}}}),_c('b-modal',{attrs:{"id":`modal-study-unit-${_vm.study_unit.id}`,"title":`Editar ${_vm.$getVisibleNames(
          'teaching.ramicro',
          false,
          'RA Micro'
        )}`,"size":"lg","hide-footer":""}},[_c('StudyUnitForm',{attrs:{"egress_profile_matter_id":_vm.egress_profile_matter_id,"matter_id":_vm.matter_id,"StudyUnit":_vm.study_unit,"show_delete_button":true},on:{"updated":function($event){return _vm.$bvModal.hide(`modal-study-unit-${_vm.study_unit.id}`)},"deleted":function($event){return _vm.$bvModal.hide(`modal-study-unit-${_vm.study_unit.id}`)}}})],1)],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section')])
}]

export { render, staticRenderFns }