<template>
  <div class="text-center">
    <h4 class="mt-4">Plan de Estudio</h4>
    <b-table-simple class="header-table" bordered responsive outlined>
      <b-thead>
        <b-tr>
          <b-th>{{
            $getVisibleNames("mesh.career", false, "Programa").toUpperCase()
          }}</b-th>
          <b-th class="program-section">
            <div v-if="career">{{ career.name.toUpperCase() }}</div>
            <div v-else>Cargando...</div>
          </b-th>
        </b-tr>
        <b-tr>
          <b-th>
            {{
              $getVisibleNames(
                "manual.mesh.titulo_profesional",
                false,
                "Título Profesional"
              ).toUpperCase()
            }}
            <!-- TÍTULO PROFESIONAL -->
          </b-th>
          <b-th class="program-section">
            <div v-if="egressProfile">
              {{ egressProfile.professional_title.toUpperCase() }}
            </div>
            <div v-else>Cargando...</div>
          </b-th>
        </b-tr>
        <b-tr
          v-if="
            !(
              this.institution.id == 12 ||
              this.$debug_change_duoc ||
              !this.institution.show_academic_degree
            )
          "
        >
          <b-th>
            {{
              $getVisibleNames(
                "manual.grado_academico",
                false,
                "Grado Académico"
              ).toUpperCase()
            }}
            <!-- GRADO ACADÉMICO -->
          </b-th>
          <b-th class="program-section">
            <div v-if="egressProfile">
              {{ egressProfile.academic_degree.toUpperCase() }}
            </div>
            <div v-else>Cargando...</div>
          </b-th>
        </b-tr>
      </b-thead>
    </b-table-simple>
    <div class="header-p" v-if="egressProfile && egressProfile.comments">
      <div v-if="!allows_crud">
        <p
          v-for="(p, index) in egressProfile.comments.split('\n')"
          :key="index"
        >
          {{ p }}
        </p>
      </div>
      <div v-else style="text-align: center">
        <ResizeAuto>
          <template v-slot:default="{ resize }">
            <textarea
              @input="resize"
              @focus="resize"
              v-model="egressProfile.comments"
              @blur="updateComment"
            ></textarea>
          </template>
        </ResizeAuto>
      </div>
    </div>
    <div v-if="egressProfile">
      <BiannualLevel
        v-for="semester in egressProfile.semester_amount"
        :key="semester.id"
        :egress_profile_id="egress_profile_id"
        :limit_study_plan="limit_study_plan"
        :egress_profile_time_allocations="egress_profile_time_allocations"
        :semester="semester"
        :allows_crud="allows_crud"
      ></BiannualLevel>
    </div>
    <div class="ml-2" v-if="coursesWrapperList.length > 0">
      <b-table
        class="mt-3"
        :items="coursesWrapperList"
        :fields="courses_wrapper_fields"
        small
        bordered
        noCollapse
        fixed
      >
        <template #cell(egress_profile_matters)="row">
          <div class="campus-container">
            <div
              v-for="item in getEgressProfileMatterName(
                row.item.egress_profile_matters
              )"
              :key="item.id"
              class="campus-div"
            >
              {{ item.name }}
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <Summary
      :egress_profile_id="egress_profile_id"
      :limit_study_plan="limit_study_plan"
      :egress_profile_time_allocations="egress_profile_time_allocations"
    ></Summary>

    <MeshCustomFab
      class="noprint"
      :egress_profile_id="egress_profile_id"
      :show_study_plan="false"
    ></MeshCustomFab>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "StudyPlanView",
  components: {
    BiannualLevel: () => import("@/components/mesh/StudyPlan/BiannualLevel"),
    Summary: () => import("@/components/mesh/StudyPlan/Summary"),
    ResizeAuto: () => import("@/components/reusable/ResizeAuto"),
    MeshCustomFab: () => import("../MeshCustomFab"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      egress_profile_time_allocations: [],
      limit_study_plan: null,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      courses_wrapper: names.COURSES_WRAPPER,
      matters: names.MATTERS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
    }),
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    coursesWrapperList() {
      return this.courses_wrapper
        .filter((x) => x.egress_profile == this.egress_profile_id)
        .sort(function (a, b) {
          if (a.init_level < b.init_level) return -1;
          if (a.init_level > b.init_level) return 1;
          if (a.end_level < b.end_level) return -1;
          if (a.end_level > b.end_level) return 1;
          return 0;
        });
    },
    courses_wrapper_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.coursewrapper",
              true,
              "Envoltorios de Asignaturas"
            )
          ),
          sortable: true,
          thStyle: {
            background: "var(--kl-menu-color) !important",
            color: "white !important",
            border: "1px solid black",
          },
          tdStyle: { border: "1px solid black !important" },
          thClass: "align-middle",
          tdClass: "text-left",
        },
        {
          key: "init_level",

          label: "Nivel Inicial",
          sortable: true,
          thStyle: {
            background: "var(--kl-menu-color) !important",
            color: "white !important",
            border: "1px solid black",
          },
          tdStyle: { border: "1px solid black !important" },
          thClass: "text-center align-middle",
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "end_level",

          label: "Nivel Final",
          sortable: true,
          thStyle: {
            background: "var(--kl-menu-color) !important",
            color: "white !important",
            border: "1px solid black",
          },
          tdStyle: { border: "1px solid black !important" },
          thClass: "text-center align-middle",
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "credits",

          label: this.$getVisibleNames(
            "manual.credits",
            true,
            "Créditos SCT Totales"
          ),
          sortable: true,
          thStyle: {
            background: "var(--kl-menu-color) !important",
            color: "white !important",
            border: "1px solid black",
          },
          tdStyle: { border: "1px solid black !important" },
          thClass: "text-center align-middle",
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "egress_profile_matters",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            true,
            "Asignaturas"
          ),
          sortable: true,
          thStyle: {
            background: "var(--kl-menu-color) !important",
            color: "white !important",
            border: "1px solid black",
          },
          tdStyle: { border: "1px solid black !important" },
          thClass: "align-middle",
          tdClass: "text-left",
        },
      ];
    },
  },
  methods: {
    updateComment() {
      let payload = {
        egress_profile_id: this.egressProfile.id,
        item: {
          comments: this.egressProfile.comments,
        },
      };
      this.$store.dispatch(names.PATCH_EGRESS_PROFILE, payload);
    },
    getEgressProfileMatterName(egress_profile_matters) {
      let matters = this.egress_profiles_matters
        .filter((x) => egress_profile_matters.includes(x.id))
        .map((x) => x.matter);
      matters = [...new Set(matters)];
      if (matters.length > 0) {
        const list = this.matters.filter((element) =>
          matters.includes(element.id)
        );
        return list.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
      } else return [];
    },
  },
  watch: {
    egressProfile: function () {
      if (this.egressProfile && !this.career) {
        this.$store.dispatch(names.FETCH_CAREER, this.egressProfile.career);
      }
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_MODALITIES);
    this.$store.dispatch(names.FETCH_REGIMES);
    this.$store.dispatch(names.FETCH_FORMATION_AREAS);
    this.$store.dispatch(names.FETCH_PROFILE_TYPES);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id);
    this.$store.dispatch(names.FETCH_COURSES_WRAPPER, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$restful
      .Get(`/mesh/limit_study_plan/?egress_profile=${this.egress_profile_id}`)
      .then((response) => {
        this.limit_study_plan = response;
      });
    this.$restful
      .Get(
        `/mesh/egress_profile_time_allocations/?egress_profile=${this.egress_profile_id}`
      )
      .then((response) => {
        this.egress_profile_time_allocations = response;
      });
    this.$store.dispatch(
      names.FETCH_STUDY_PLAN_COMMENTS,
      this.egress_profile_id
    );
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_EP_MATTER_TIME_ALLOCATIONS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_MATTER_TIME_ALLOCATIONS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_MATTERS, {
      egress_profile_id: this.egress_profile_id,
    });
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.header-table {
  background-color: var(--primary-color) !important;
}
.header-table th {
  border: 1px solid black;
  color: white !important;
  padding: 0.25rem;
  background-color: var(--kl-menu-color) !important;
  text-align: left;
  padding-left: 10px;
}
.table .program-section {
  background-color: var(--kl-fourth-color) !important;
}
.header-p {
  text-align: left;
  font-family: "Helvetica Neue";
  line-height: 1em;
  font-weight: bold;
  font-style: italic;
  margin: auto;
  padding: 0 1em;
  overflow: hidden;
  display: block;
}
textarea {
  width: 100%;
  font-family: "Helvetica Neue";
  line-height: 0.8em;
  font-weight: bold;
  font-style: italic;
  height: 4.5rem;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
</style>