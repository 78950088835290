<template>
  <div>
    <h4 v-if="isNaN(observation_achievement.id) && show_title">
      Crear
      {{
        $getVisibleNames(
          "evaluations.observationachievement",
          false,
          "Nivel de Logro"
        )
      }}
      de la Rúbrica
    </h4>
    <h4 v-else-if="show_title">
      Editar
      {{
        $getVisibleNames(
          "evaluations.observationachievement",
          false,
          "Nivel de Logro"
        )
      }}
      de la Rúbrica
    </h4>
    <div>
      <b-form>
        <b-form-group
          label="Texto"
          label-for="input-text"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-text"
            v-model="$v.observation_achievement.text.$model"
            :state="validateState('text')"
            aria-describedby="input-text-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-text-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label="Puntaje"
          label-for="input-score"
          label-cols="4"
          label-cols-sm="3"
        >
          <b-form-input
            id="input-score"
            v-model="$v.observation_achievement.score.$model"
            :state="validateState('score')"
            aria-describedby="input-score-feedback"
            size="sm"
            type="number"
          ></b-form-input>
          <b-form-invalid-feedback id="input-score-feedback"
            >Este campo debe ser mayor o igual a 0.</b-form-invalid-feedback
          >
        </b-form-group>

        <div class="row">
          <div
            v-if="show_delete_button && !isNaN(observation_achievement.id)"
            class="col"
            style="text-align: left"
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="danger"
              @click="deleteRubricAchievement"
              >Eliminar</b-button
            >
          </div>
          <div v-if="show_save_button" class="col" style="text-align: right">
            <b-button class="mr-1" size="sm" @click="saveRubricAchievement"
              >Guardar</b-button
            >
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minValue } from "vuelidate/lib/validators";

export default {
  name: "RubricAchievementForm",
  mixins: [validationMixin],
  props: {
    observation_instrument_id: {
      type: Number,
      required: true,
    },
    RubricAchievement: {
      type: Object,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      observation_achievement: {
        id: this.RubricAchievement
          ? this.RubricAchievement.id
          : generateUniqueId(),
        text: this.RubricAchievement ? this.RubricAchievement.text : "",
        score: this.RubricAchievement ? this.RubricAchievement.score : 1,
        observation_instrument: this.RubricAchievement
          ? this.RubricAchievement.observation_instrument
          : this.observation_instrument_id,
      },
    };
  },
  validations: {
    observation_achievement: {
      text: {
        required,
      },
      score: {
        required,
        minValue: minValue(0),
      },
      observation_instrument: {
        required,
      },
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.observation_achievement[key];
      return $dirty ? !$error : null;
    },
    saveRubricAchievement() {
      this.$v.observation_achievement.$touch();
      if (this.$v.observation_achievement.$anyError) {
        return;
      }
      if (isNaN(this.observation_achievement.id))
        this.createRubricAchievement();
      else this.updateRubricAchievement();
    },
    createRubricAchievement() {
      this.$store
        .dispatch(
          names.POST_OBSERVATION_ACHIEVEMENT,
          this.observation_achievement
        )
        .then((response) => {
          this.observation_achievement = response;
          this.$emit("created", this.observation_achievement);
        });
    },
    updateRubricAchievement() {
      this.$store
        .dispatch(
          names.UPDATE_OBSERVATION_ACHIEVEMENT,
          this.observation_achievement
        )
        .then((response) => {
          this.observation_achievement = response;
          this.$emit("updated", this.observation_achievement);
        });
    },
    deleteRubricAchievement() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "evaluations.observationachievement",
          false,
          "Nivel de Logro"
        )}" de la Rúbrica?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              names.DELETE_OBSERVATION_ACHIEVEMENT,
              this.observation_achievement.id
            )
            .then(() => {
              this.$emit("deleted", this.observation_achievement);
            });
        }
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
</style>