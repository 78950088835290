<template>
  <div>
    <b-breadcrumb
      class="breadcrumb pb-2 pt-1 noprint"
      :items="breadcrumb_items"
    ></b-breadcrumb>
    <div style="display: flex">
      <div class="col" style="text-align: left">
        <button
          v-can="'mesh.add_cycle'"
          v-if="allows_crud"
          class="btn btn-secondary btn-sm"
          v-b-modal.new-cycle-modal
        >
          Agregar {{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}
        </button>
      </div>
    </div>
    <h3>{{ $getVisibleNames("mesh.cycle", true, "Ciclos Formativos") }}</h3>
    <GenericBTable
      v-if="!isBusy"
      :items="cycles"
      :pagination="cycles.length"
      :fields="fields"
      :filter="input_search"
      stacked="md"
      :show_pagination="true"
      :search_filter="true"
      :columns_display="true"
      :allows_crud="allows_crud"
      :display_id="display_id"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <!-- <template #cell(init_semester)="row">
        <span v-b-tooltip.v-secondary="'Semestre inicial'">{{
          row.item.init_semester
        }}</span>
      </template>
      <template #cell(end_semester)="row">
        <span v-b-tooltip.v-secondary="'Semestre final'">{{
          row.item.end_semester
        }}</span>
      </template> -->
      <template #cell(institution)="row">
        <div v-if="row.item.institution">SI</div>
        <div v-else>NO</div>
      </template>
      <template #cell(egress_profiles)="row">
        <b-button
          variant="none"
          :id="`popover-button-variant-${row.item.id}`"
          v-b-tooltip.v-secondary.hover="'Ver Detalles'"
        >
          <b-icon icon="eye" scale="1"></b-icon>
        </b-button>
        <b-popover
          :target="`popover-button-variant-${row.item.id}`"
          triggers="focus"
        >
          <template #title>
            {{
              $getVisibleNames("mesh.egressprofile", true, "Perfiles De Egreso")
            }}
            <!-- Perfiles de Egreso -->
          </template>
          <div
            v-for="(egress_profiles, index) in [getEgressProfiles(row.item.id)]"
            :key="index"
          >
            <!-- v-for="egress_profile in egressProfiles.filter(
                (x) => x.id == egress_profile_id
              )" -->

            <template v-for="profile in egress_profiles">
              <div :key="profile.id" class="profiles-container">
                <b-button
                  variant="none"
                  @click="redirectToEgressProfile(profile.id)"
                  class="profiles mb-1"
                  v-b-tooltip.v-secondary="'Ir al perfil'"
                >
                  {{ profile.professional_title }}
                </b-button>
              </div>
            </template>
          </div>
        </b-popover>
      </template>
      <template v-if="allows_crud" #cell(actions)="row">
        <button-edit
          @click="$bvModal.show(`edit-cycle-modal-${row.item.id}`)"
          v-b-tooltip.v-secondary.auto.noninteractive="
            `Editar ${$getVisibleNames('mesh.cycle', false, 'Ciclo Formativo')}`
          "
        ></button-edit>
        <button-delete
          @click="askForDeleteCycle(row.item.id)"
          v-b-tooltip.v-secondary.auto.noninteractive="
            `Eliminar ${$getVisibleNames(
              'mesh.cycle',
              false,
              'Ciclo Formativo'
            )}`
          "
        ></button-delete>
        <b-modal
          :id="`edit-cycle-modal-${row.item.id}`"
          :title="`Editar ${$getVisibleNames(
            'mesh.cycle',
            false,
            'Ciclo Formativo'
          )}`"
          size="xl"
          hide-footer
        >
          <CyclesForm
            :Cycle="row.item"
            @updated="slotUpdatedCycle"
            :show_title="false"
          ></CyclesForm>
        </b-modal>
      </template>
    </GenericBTable>
    <div>
      <b-modal
        id="new-cycle-modal"
        :title="`Crear ${$getVisibleNames(
          'mesh.cycle',
          false,
          'Ciclo Formativo'
        )}`"
        size="xl"
        hide-footer
      >
        <CyclesForm
          @created="slotCreatedCycle"
          :show_title="false"
        ></CyclesForm>
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "CyclesListView",
  components: {
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    CyclesForm: () => import("./CyclesForm.vue"),
  },
  props: {
    input_search: {
      type: String,
      default: "",
    },
    display_id: {},
  },
  data() {
    return {
      loading: 0,
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      egressProfiles: names.EGRESS_PROFILES,
      profileCycles: names.PROFILE_CYCLES,
      careers: names.CAREERS,
      faculties: names.FACULTIES,
      cycles: names.CYCLES,
    }),
    allows_crud() {
      if (
        (this.user_position &&
          [1].includes(this.user_position.position) &&
          [1].includes(this.user.groups[0])) ||
        this.user.is_superuser
      ) {
        const has_permission = this.$hasPermissions("mesh.add_cycle");
        if (has_permission) return has_permission;
        else return false;
      } else return false;
    },
    isBusy() {
      if (this.loading != 0) return true;
      return false;
    },
    breadcrumb_items() {
      return [
        { text: "Definiciones Curriculares", active: true },
        {
          text: this.$getVisibleNames("mesh.cycle", true, "Ciclos Formativos"),
          active: true,
        },
      ];
    },
    fields() {
      return [
        {
          key: "name",
          label: this.$getVisibleNames("mesh.cycle", true, "Ciclos Formativos"),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        // {
        //   key: "init_semester",
        //   label: "Inicio",
        //   sortable: true,
        // },
        // {
        //   key: "end_semester",
        //   label: "Fin",
        //   sortable: true,
        // },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "certification",
          label: "Certificación",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "institution",
          label: "Institucional",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "egress_profiles",
          label: "Perfiles",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    getEgressProfiles(cycle_id) {
      let list = [];
      const profile_cycles = this.profileCycles.filter(
        (x) => x.cycle == cycle_id
      );
      profile_cycles.forEach((profile_cycle) => {
        const egress_profile = this.egressProfiles.find(
          (x) => x.id == profile_cycle.egress_profile
        );
        const index = list.findIndex(
          (x) => x.id == profile_cycle.egress_profile
        );
        if (egress_profile && index == -1) list.push(egress_profile);
      });
      return list;
    },
    slotCreatedCycle() {
      this.$bvModal.hide("new-cycle-modal");
    },
    slotUpdatedCycle(cycle) {
      console.log(cycle);
      this.$bvModal.hide(`edit-cycle-modal-${cycle.id}`);
    },
    askForDeleteCycle(cycle_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.cycle",
          false,
          "Ciclo Formativo"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_CYCLE, cycle_id).then(() => {
            toast(
              this.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo") +
                " eliminado."
            );
          });
        }
      });
    },
    redirectToEgressProfile(id) {
      let route_data = this.$router.resolve({
        name: "EgressProfileView",
        params: { egress_profile_id: id },
      });
      window.open(route_data.href, "_blank");
    },
  },
  created() {
    this.loading = 1;
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES).then((response) => {
      response.forEach((element) => {
        this.$store.dispatch(names.FETCH_CAREER, element.career);
      });
    });
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_CYCLES);
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES).then(() => {
      this.loading -= 1;
    });
  },
};
</script>

<style scoped>
.add-icon {
  margin-right: -2%;
  font-size: bold;
}
.profiles-container {
  width: 100%;
}
.profiles {
  background-color: var(--secondary-color);
  border-radius: 4px;
  padding: 0 2em;
  width: 100%;
  font-size: 11pt;
  text-align: center;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.75em;
}
</style>