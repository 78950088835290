
export default [
    {
        path: '/taxonomy/form/',
        name: 'TaxonomyView',
        component: () =>
            import("@/components/mesh/MethodologicalResources/Taxonomy/TaxonomyView"),
    },
    {
        path: '/campuses',
        name: 'CampusListView',
        component: () =>
            import("@/components/mesh/CurricularResources/Campus/CampusListView"),
    },
    {
        path: '/faculties',
        name: 'FacultyListView',
        component: () =>
            import("@/components/mesh/CurricularResources/Faculty/FacultyListView"),
    },
    {
        path: '/periods',
        name: 'PeriodView',
        component: () =>
            import("@/components/mesh/CurricularResources/Period/PeriodView"),
    },
    {
        path: '/egress-profile-type',
        name: 'EgressProfileTypeView',
        component: () =>
            import("@/components/mesh/EgressProfile/EgressProfileType/EgressProfileTypeView"),
    },
    {
        path: '/cycles',
        name: 'CyclesListView',
        component: () =>
            import("@/components/mesh/CurricularResources/Cycle/CyclesListView"),
    },
    {
        path: '/competence-types',
        name: 'CompetenceTypeView',
        component: () =>
            import("@/components/mesh/CurricularResources/CompetenceType/CompetenceTypeView"),
    },
    {
        path: '/formation-areas',
        name: 'FormationAreaView',
        component: () =>
            import("@/components/mesh/CurricularResources/FormationArea/FormationAreaView"),
    },
    {
        path: '/matter-type',
        name: 'MatterTypeView',
        component: () =>
            import("@/components/mesh/CurricularResources/MatterType/MatterTypeView"),
    },
    {
        path: '/modality',
        name: 'ModalityView',
        component: () =>
            import("@/components/mesh/CurricularResources/Modality/ModalityView"),
    },
    {
        path: '/modality-egress-profile',
        name: 'ModalityEgressProfileView',
        component: () =>
            import("@/components/mesh/CurricularResources/ModalityEgressProfile/ModalityEgressProfileView"),
    },
    {
        path: '/curricular-strategies',
        name: 'MatterContributionView',
        component: () =>
            import("@/components/mesh/CurricularResources/MatterContribution/MatterContributionView"),
    },
    {
        path: '/formation-lines',
        name: 'FormationLineView',
        component: () =>
            import("@/components/mesh/CurricularResources/FormationLine/FormationLineView"),
    },
    {
        path: '/competence',
        name: 'CompetenceTable',
        component: () =>
            import("@/components/mesh/CurricularResources/Competence/CompetenceTable"),
    },
    {
        path: '/matter-list',
        name: 'MatterListView',
        component: () =>
            import("@/components/mesh/CurricularResources/Matter/MatterListView"),
    },
    {
        path: '/egress-profiles-list',
        name: 'EgressProfilesList',
        component: () =>
            import("@/components/mesh/EgressProfile/EgressProfilesList"),
    },
    {
        path: '/careers',
        name: 'CareerListView',
        component: () =>
            import("@/components/mesh/CurricularResources/CareerListView"),
    },
    {
        path: '/egress-profile/form/:egress_profile_id/',
        name: 'EgressProfileView',
        component: () =>
            import("@/components/mesh/EgressProfile/EgressProfileView"),
    },
    {
        path: '/matrix1/form/:egress_profile_id/',
        name: 'Matrix1View',
        component: () =>
            import("@/components/mesh/Matrix1/Matrix1View"),
    },
    {
        path: '/matrix1-per-cycle/form/:egress_profile_id/',
        name: 'Matrix1PerCycleView',
        component: () =>
            import("@/components/mesh/Matrix1/Matrix1PerCycleView"),
    },
    {
        path: '/matrix2/form/:egress_profile_id/:courses_wrapper_id?',
        name: 'Matrix2View',
        component: () =>
            import("@/components/mesh/Matrix2/Matrix2View"),
    },
    {
        path: '/matrix3/form/:egress_profile_id/',
        name: 'Matrix3View',
        component: () =>
            import("@/components/mesh/Matrix3/Matrix3View"),
    },
    {
        path: '/study-plan/form/:egress_profile_id/',
        name: 'StudyPlanView',
        component: () =>
            import("@/components/mesh/StudyPlan/StudyPlanView"),
    },
    {
        path: "/curricular-mesh/form/:egress_profile_id/",
        name: "CurricularMeshView",
        component: () =>
            import("@/components/mesh/CurricularMesh/CurricularMeshView"),
    },
    {
        path: "/institution-competences/form/",
        name: "InstitutionCompetencesView",
        component: () =>
            import("@/components/mesh/InstitutionCompetences/InstitutionCompetencesView"),
    },
    {
        path: "/methodological-strategies/form/",
        name: "MethodologicalStrategyView",
        component: () =>
            import("@/components/mesh/MethodologicalResources/MethodologicalStrategy/MethodologicalStrategyView"),
    },
    {
        path: "/direct-teaching-activities/form/",
        name: "DirectTeachingActivityView",
        component: () =>
            import("@/components/mesh/MethodologicalResources/DirectTeachingActivity/DirectTeachingActivityView"),
    },
    {
        path: "/autonomous-teaching-activities/",
        name: "AutonomousTeachingActivityView",
        component: () =>
            import("@/components/mesh/MethodologicalResources/AutonomousTeachingActivity/AutonomousTeachingActivityView"),
    },
    {
        path: "/procedure-evidences/",
        name: "ProcedureEvidenceView",
        component: () =>
            import("@/components/mesh/MethodologicalResources/ProcedureEvidence/ProcedureEvidenceView"),
    },
    {
        path: "/alternative-names/",
        name: "AlternativeNamesView",
        component: () =>
            import("@/components/mesh/MethodologicalResources/AlternativeNames/AlternativeNamesView"),
    },
    {
        path: '/act-type',
        name: 'ActTypeView',
        component: () =>
            import("@/components/mesh/CurricularResources/ActType/ActTypeView"),
    },
    {
        path: '/study-environment',
        name: 'StudyEnvironmentView',
        component: () =>
            import("@/components/mesh/CurricularResources/StudyEnvironment/StudyEnvironmentView"),
    },
    {
        path: '/certification',
        name: 'CertificationView',
        component: () =>
            import("@/components/mesh/CurricularResources/Certification/CertificationView"),
    },
    {
        path: '/evaluation-situation',
        name: 'EvaluationSituationView',
        component: () =>
            import("@/components/mesh/CurricularResources/EvaluationSituation/EvaluationSituationView"),
    },
    {
        path: '/base-ra/',
        name: 'RaBaseView',
        component: () =>
            import("@/components/mesh/CurricularResources/RaBase/RaBaseView"),
    },
    {
        path: '/region/',
        name: 'RegionView',
        component: () =>
            import("@/components/mesh/CurricularResources/Region/RegionView"),
    },
    {
        path: '/commune/',
        name: 'CommuneView',
        component: () =>
            import("@/components/mesh/CurricularResources/Commune/CommuneView"),
    },
    {
        path: '/time-allocation/',
        name: 'TimeAllocationView',
        component: () =>
            import("@/components/mesh/CurricularResources/TimeAllocation/TimeAllocationView"),
    },
    {
        path: '/base-matter-wrapper',
        name: 'BaseMattersWrapperView',
        component: () =>
            import("@/components/mesh/Matrix2/BaseMattersWrapper/BaseMattersWrapperView"),
    },
];
