<template>
  <div>
    <div class="mx-2">
      <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'teaching.add_evaluationsituation'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-evaluation_situation-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "teaching.evaluationsituation",
                false,
                "Situación Evaluativa"
              )
            }}
          </button>
        </div>
      </div>
      <h3>
        {{
          $getVisibleNames(
            "teaching.evaluationsituation",
            false,
            "Situación Evaluativa"
          )
        }}
      </h3>
      <GenericBTable
        :items="evaluation_situations"
        :pagination="evaluation_situations.length"
        :fields="evaluation_situationsFields"
        :filter="input_search"
        :filterCustom="filterCustom"
        @emitChangeSearchField="changeInputSearch"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template> </template>
        <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            @click="
              $bvModal.show(`edit-evaluation_situation-modal-${row.item.id}`)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'teaching.evaluationsituation',
                false,
                'Situación Evaluativa'
              )}`
            "
          ></button-edit>
          <button-delete
            @click="askForDeleteEvaluationSituation(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'teaching.evaluationsituation',
                false,
                'Situación Evaluativa'
              )}`
            "
          ></button-delete>
          <b-modal
            :id="`edit-evaluation_situation-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'teaching.evaluationsituation',
              false,
              'Situación Evaluativa'
            )}`"
            size="md"
            hide-footer
          >
            <EvaluationSituationForm
              :EvaluationSituation="row.item"
              @updated="slotUpdatedEvaluationSituation"
            ></EvaluationSituationForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-evaluation_situation-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'teaching.evaluationsituation',
        false,
        'Situación Evaluativa'
      )}`"
      size="md"
    >
      <EvaluationSituationForm
        @created="slotCreatedEvaluationSituation"
      ></EvaluationSituationForm>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "EvaluationSituationView",
  components: {
    EvaluationSituationForm: () =>
      import(
        "@/components/mesh/CurricularResources/EvaluationSituation/EvaluationSituationForm"
      ),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {},
  data() {
    return {
      sortBy: "",
      evaluation_situations: [],
      sortDesc: false,
      input_search: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "teaching.add_evaluationsituation"
      );
      if (has_permission) return has_permission;
      else return false;
    },
    evaluation_situationsFields() {
      if (!this.allows_crud)
        return this.evaluation_situations_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.evaluation_situations_fields;
    },
    breadcrumb_items() {
      return [
        { text: "Definiciones Curriculares", active: true },
        {
          text: this.$getVisibleNames(
            "teaching.evaluationsituation",
            false,
            "Situación Evaluativa"
          ),
          active: true,
        },
      ];
    },
    evaluation_situations_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "teaching.evaluationsituation",
              true,
              "Situación Evaluativas"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(row.description, this.input_search)
      ) {
        return true;
      } else {
        return false;
      }
    },
    askForDeleteEvaluationSituation(evaluation_situation_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "teaching.evaluationsituation",
          false,
          "Situación Evaluativa"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(
              `/teaching/evaluation-situation/${evaluation_situation_id}/`
            )
            .then(() => {
              const index = this.evaluation_situations.findIndex(
                (x) => x.id == evaluation_situation_id
              );
              if (index != -1) {
                this.evaluation_situations.splice(index, 1);
                toast(
                  String(
                    this.$getVisibleNames(
                      "teaching.evaluationsituation",
                      false,
                      "Situación Evaluativa"
                    ) + " eliminada."
                  )
                );
              }
            });
        }
      });
    },
    fetchEvaluationSituations() {
      this.$restful.Get(`/teaching/evaluation-situation/`).then((response) => {
        this.evaluation_situations = response;
      });
    },
    slotCreatedEvaluationSituation(evaluation_situation) {
      this.evaluation_situations.push(evaluation_situation);
      this.$bvModal.hide("new-evaluation_situation-modal");
    },
    slotUpdatedEvaluationSituation(evaluation_situation) {
      const index = this.evaluation_situations.findIndex(
        (x) => x.id == evaluation_situation.id
      );
      if (index != -1) {
        this.evaluation_situations[index].description =
          evaluation_situation.description;
        this.evaluation_situations[index].name = evaluation_situation.name;
        this.evaluation_situations[index].update_date =
          evaluation_situation.update_date;
        this.evaluation_situations[index].updated_by =
          evaluation_situation.updated_by;
        this.$bvModal.hide(
          `edit-evaluation_situation-modal-${evaluation_situation.id}`
        );
      }
    },
  },
  created() {
    if (this.user) {
      this.fetchEvaluationSituations();
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped>
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}

.title-header {
  padding: 0;
  margin-top: auto;
}
.search-evaluation_situation {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
</style>