import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    evaluation_criterias: [],
    evaluation_criterias_url: '/teaching/evaluation-criteria-micro',
    evaluation_criterias_macros: [],
    evaluation_criterias_macros_url: '/teaching/evaluation-criteria-macro'
}

const getters = {
    [names.EVALUATION_CRITERIAS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluation_criterias.filter((x) => x.school == get.store.state.user.school).sort(function(a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
    [names.EVALUATION_CRITERIAS_MACROS]: state => {
        if (!get.store.state.user) return [];
        return state.evaluation_criterias_macros.filter((x) => x.school == get.store.state.user.school).sort(function(a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
}

const mutations = {
    [names.MUTATE_EVALUATION_CRITERIAS]: (state, evaluation_criterias) => {
        evaluation_criterias.forEach(element => {
            let index = state.evaluation_criterias.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluation_criterias.splice(index, 1, element);
            else state.evaluation_criterias.push(element);
        });
    },
    [names.MUTATE_EVALUATION_CRITERIA]: (state, evaluation_criteria) => {
        let index = state.evaluation_criterias.findIndex(x => x.id == evaluation_criteria.id);
        if (index != -1) state.evaluation_criterias.splice(index, 1, evaluation_criteria);
        else state.evaluation_criterias.push(evaluation_criteria);
    },
    [names.MUTATE_DELETE_EVALUATION_CRITERIA]: (state, evaluation_criterio_id) => {
        let index = state.evaluation_criterias.findIndex(x => x.id == evaluation_criterio_id);
        if (index != -1) state.evaluation_criterias.splice(index, 1);
    },
    [names.MUTATE_EVALUATION_CRITERIAS_MACROS]: (state, evaluation_criterias_macros) => {
        evaluation_criterias_macros.forEach(element => {
            let index = state.evaluation_criterias_macros.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluation_criterias_macros.splice(index, 1, element);
            else state.evaluation_criterias_macros.push(element);
        });
    },
    [names.MUTATE_EVALUATION_CRITERIA_MACRO]: (state, evaluation_criteria_macro) => {
        let index = state.evaluation_criterias_macros.findIndex(x => x.id == evaluation_criteria_macro.id);
        if (index != -1) state.evaluation_criterias_macros.splice(index, 1, evaluation_criteria_macro);
        else state.evaluation_criterias_macros.push(evaluation_criteria_macro);
    },
    [names.MUTATE_DELETE_EVALUATION_CRITERIA_MACRO]: (state, evaluation_criterio_macro_id) => {
        let index = state.evaluation_criterias_macros.findIndex(x => x.id == evaluation_criterio_macro_id);
        if (index != -1) state.evaluation_criterias_macros.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_EVALUATION_CRITERIA]: ({
        commit,
        state
    }, evaluation_criterio_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.evaluation_criterias_url}/${evaluation_criterio_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_CRITERIA, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATION_CRITERIAS]: ({
        commit,
        state
    }, study_unit_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.evaluation_criterias_url}/?study_unit=${study_unit_id}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_CRITERIAS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EVALUATION_CRITERIA]: ({
        commit,
        state
    }, evaluation_criteria) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluation_criterias_url}/`, evaluation_criteria)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_CRITERIA, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATION_CRITERIA]: ({
        commit,
        state
    }, evaluation_criteria) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluation_criterias_url}/${evaluation_criteria.id}/`, evaluation_criteria)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_CRITERIA, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATION_CRITERIA]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluation_criterias_url}/${payload.evaluation_criterio_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_CRITERIA, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATION_CRITERIA]: ({
        commit,
        state
    }, evaluation_criterio_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluation_criterias_url}/${evaluation_criterio_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATION_CRITERIA, evaluation_criterio_id);
                    resolve(response);
                });
        });
    },





    [names.FETCH_EVALUATION_CRITERIA_MACRO]: ({
        commit,
        state
    }, evaluation_criterio_macro_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.evaluation_criterias_macros_url}/${evaluation_criterio_macro_id}/`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_CRITERIA_MACRO, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EVALUATION_CRITERIAS_MACROS]: ({
        commit,
        state
    }, matter_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.evaluation_criterias_macros_url}/?matter=${matter_id}`)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_CRITERIAS_MACROS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EVALUATION_CRITERIA_MACRO]: ({
        commit,
        state
    }, evaluation_criteria_macro) => {
        return new Promise((resolve) => {
            restful.Post(`${state.evaluation_criterias_macros_url}/`, evaluation_criteria_macro)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_CRITERIA_MACRO, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EVALUATION_CRITERIA_MACRO]: ({
        commit,
        state
    }, evaluation_criteria_macro) => {
        return new Promise((resolve) => {
            restful.Put(`${state.evaluation_criterias_macros_url}/${evaluation_criteria_macro.id}/`, evaluation_criteria_macro)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_CRITERIA_MACRO, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EVALUATION_CRITERIA_MACRO]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.evaluation_criterias_macros_url}/${payload.id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EVALUATION_CRITERIA_MACRO, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EVALUATION_CRITERIA_MACRO]: ({
        commit,
        state
    }, evaluation_criterio_macro_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.evaluation_criterias_macros_url}/${evaluation_criterio_macro_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EVALUATION_CRITERIA_MACRO, evaluation_criterio_macro_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};