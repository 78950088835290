var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_directteachingactivity'),expression:"'mesh.add_directteachingactivity'"}],staticClass:"btn btn-secondary btn-sm btn-add",on:{"click":function($event){return _vm.$bvModal.show(`new-direct-teaching-activity-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames( "manual.actividad_presencial", true, "Actividad Presencial" ))+" ")]):_vm._e()])]),_c('h3',{staticClass:"title-header noprint"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.actividad_presencial", true, "Actividades Presenciales" ))+" ")]),_c('GenericBTable',{attrs:{"items":_vm.direct_teaching_activities,"fields":_vm.directTeachingActivityFields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":'direct_teaching_activities',"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_directteachingactivity'),expression:"'mesh.change_directteachingactivity'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            'Editar Actividad Presencial'
          ),expression:"\n            'Editar Actividad Presencial'\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(
              `edit-direct-teaching-activity-modal-${row.item.id}`
            )}}}),_c('button-delete',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_directteachingactivity'),expression:"'mesh.delete_directteachingactivity'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            'Eliminar Actividad Presencial'
          ),expression:"\n            'Eliminar Actividad Presencial'\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteDirectTeachingActivity(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-direct-teaching-activity-modal-${row.item.id}`,"title":"Editar Actividad Presencial","size":"md","hide-footer":""}},[_c('DirectTeachingActivityForm',{attrs:{"DirectTeachingActivity":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedDirectTeachingActivity}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-direct-teaching-activity-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'manual.actividad_presencial',
      false,
      'Actividad Presencial'
    )}`,"size":"lg"}},[_c('DirectTeachingActivityForm',{attrs:{"institution_id":this.user.school},on:{"created":_vm.slotCreatedDirectTeachingActivity}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }