<template>
  <div>
    <div class="mx-2">
      <h3 class="title-header">
        Información de la
        {{ $getVisibleNames("mesh.egressprofilematter", false, "Asignatura") }}
        <!-- <b-icon
          class="ml-2 pt-1"
          icon="eye"
          v-b-tooltip.v-secondary.right.noninteractive="
            `ir al Programa de la Asignatura.`
          "
          @click="redirectToMatter(egress_profile_matter_id)"
          scale="1"
        ></b-icon> -->
      </h3>
      <MatterPresentation
        v-if="matter"
        :Matter="matter"
        :egress_profile_id="matter.egress_profile"
        :allows_crud="false"
      ></MatterPresentation>
      <EvaluationTable
        v-if="matter"
        :egressProfileMatte="matter"
        :allows_crud="allows_crud"
      >
      </EvaluationTable>
      <template v-if="!user.is_student">
        <AppliedEvaluationCriterias
          v-if="matter"
          :matter="matter"
        ></AppliedEvaluationCriterias>
      </template>
    </div>
    <EvaluationCustomFab
      class="noprint"
      :matter_id="egress_profile_matter_id"
      :show_matter_program="true"
    ></EvaluationCustomFab>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { toast } from "@/utils/utils";

export default {
  name: "EvaluationTableView",
  components: {
    MatterPresentation: () =>
      import("@/components/teaching/MatterProgram/MatterPresentation"),
    EvaluationTable: () =>
      import("@/components/teaching/Evaluation/EvaluationTable"),
    AppliedEvaluationCriterias: () =>
      import("@/components/teaching/Evaluation/AppliedEvaluationCriterias"),
    EvaluationCustomFab: () =>
      import("@/components/evaluations/EvaluationCustomFab"),
  },
  data() {
    return {
      egress_profile_matter_id: Number(this.$route.params.matter_id),
      input_search: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("teaching.change_evaluation");
      if (has_permission) return has_permission;
      else return false;
    },
    matter() {
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
  },
  methods: {
    redirectToMatter(matter_id) {
      this.$router.push({
        name: "MatterProgramView",
        params: { matter_id: matter_id },
      });
    },
  },
  created() {
    this.$store
      .dispatch(
        names.FETCH_EGRESS_PROFILE_MATTER,
        this.egress_profile_matter_id
      )
      .then((response) => {
        this.$store.dispatch(names.FETCH_MATTER, response.matter);
      });
  },
};
</script>
<style scoped>
.title-header {
  padding: 0 2rem;
}
.btn-add {
  margin-left: 0.4rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  width: 255px;
}
</style>