var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question-container",style:(_vm.questionContainerStyle),on:{"click":_vm.slotCardSelected}},[_c('div',{staticClass:"question-body py-3"},[_c('div',{staticClass:"row-div"},[_c('div',{staticClass:"row-info-div"},[_c('div',{staticClass:"title-div"},[_c('div',{staticClass:"row py-2"},[_c('div',{staticClass:"pr-1 pt-1 ml-2"},[_c('strong',[_vm._v(_vm._s(_vm.Question.order))]),_vm._v(". ")]),_c('div',{staticClass:"text-left pt-1",domProps:{"innerHTML":_vm._s(_vm.Question.title)}})])])]),_c('div',{staticClass:"vl"}),_c('div',{staticClass:"footer-buttons-div"},[_c('div',{staticClass:"type-div pb-2"},[_c('strong',[_vm._v(_vm._s(_vm.questionType ? _vm.questionType.value : "-"))])])])]),_c('hr'),_c('ImagesContainer',{attrs:{"Images":_vm.topQuestionImages,"allows_crud":false}}),(_vm.show_description)?_c('div',{staticClass:"content-div"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.Question.description)}})]):_vm._e(),(_vm.Question.question_type == 2)?[_c('div',{staticClass:"mb-2"},[(
            _vm.select_question_answer &&
            _vm.select_question_answer.selected.length == 1
          )?_vm._l((_vm.options),function(option){return _c('b-form-checkbox',{key:option.id,staticClass:"text-justify mb-1",attrs:{"checked":_vm.select_question_answer.selected == option.id,"disabled":""}},[_vm._v(_vm._s(option.text)+" ")])}):_vm._l((_vm.options),function(option){return _c('b-form-checkbox',{key:option.id,staticClass:"text-justify mb-1",attrs:{"checked":_vm.getCheckedOption(option.id),"disabled":""}},[_vm._v(_vm._s(option.text)+" ")])})],2)]:_vm._e(),_c('ImagesContainer',{attrs:{"Images":_vm.bottomQuestionImages,"allows_crud":false}}),_c('hr'),_c('div',{staticClass:"row-div"},[_c('div',{staticClass:"row-info-div pt-1"},[_c('div',{staticClass:"px-2"},[_vm._v("Puntaje Máximo: "+_vm._s(_vm.Question.score))]),(_vm.student_id)?_c('div',{staticClass:"automated-score mb-1"},[_vm._v(" Puntaje Automático: "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.select_question_answer.automated_score))])]):_vm._e()]),_c('div',{staticClass:"vl"}),_c('div',{staticClass:"footer-buttons-div"},[(_vm.student_id && _vm.institution)?_c('div',{staticClass:"mb-1"},[_vm._v(" Puntaje Obtenido: "),(
              _vm.institution.actual_period != null &&
              _vm.institution.actual_period.id == _vm.period_id
            )?[_c('strong',[_c('StyledInputNumber',{staticStyle:{"width":"80px"},on:{"input":_vm.patchAnswerScore},model:{value:(_vm.manual_score.score),callback:function ($$v) {_vm.$set(_vm.manual_score, "score", $$v)},expression:"manual_score.score"}})],1)]:[_vm._v(" "+_vm._s(_vm.manual_score.score)+" ")]],2):_vm._e()])]),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-feedback-${_vm.Question.id}`),expression:"`collapse-feedback-${Question.id}`"}],staticClass:"btn-feedback",attrs:{"variant":"none","size":"sm"}},[_vm._v(" Retroalimentar ")]),(_vm.institution)?_c('b-collapse',{attrs:{"id":`collapse-feedback-${_vm.Question.id}`}},[_c('b-form-textarea',{attrs:{"size":"sm","rows":"3","disabled":!_vm.student_id ||
          (_vm.institution.actual_period != null &&
            _vm.institution.actual_period.id != _vm.period_id),"max-rows":"6"},on:{"change":_vm.patchAnswerScore},model:{value:(_vm.manual_score.feedback),callback:function ($$v) {_vm.$set(_vm.manual_score, "feedback", $$v)},expression:"manual_score.feedback"}})],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }