<template>
  <div>
    <div class="mx-2">
      <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_mattercontribution'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-matter-contribution-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "mesh.mattercontribution",
                false,
                "Estrategia Curricular"
              )
            }}
          </button>
        </div>
      </div>
      <h3>
        {{
          $getVisibleNames(
            "mesh.mattercontribution",
            true,
            "Estrategias Curriculares"
          )
        }}
      </h3>
      <GenericBTable
        :items="matter_contributions"
        :fields="matterContributionsFields"
        :filter="input_search"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="display_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template> </template>
        <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            @click="
              $bvModal.show(`edit-matter-contribution-modal-${row.item.id}`)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.mattercontribution',
                false,
                'Estrategia Curricular'
              )}`
            "
          ></button-edit>
          <button-delete
            @click="askForDeleteMatterContribution(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'mesh.mattercontribution',
                false,
                'Estrategia Curricular'
              )}`
            "
          ></button-delete>
          <b-modal
            :id="`edit-matter-contribution-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.mattercontribution',
              false,
              'Estrategia Curricular'
            )}`"
            size="md"
            hide-footer
          >
            <MatterContributionForm
              :MatterContribution="row.item"
              @updated="slotUpdatedMatterContribution"
              :institution_id="row.item.school"
              :show_title="false"
            ></MatterContributionForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-matter-contribution-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.mattercontribution',
        false,
        'Estrategia Curricular'
      )}`"
      size="md"
    >
      <MatterContributionForm
        @created="slotCreatedMatterContribution"
        :institution_id="this.user.school"
      ></MatterContributionForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "MatterContributionView",
  components: {
    MatterContributionForm: () =>
      import(
        "@/components/mesh/CurricularResources/MatterContribution/MatterContributionForm"
      ),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    input_search: {
      type: String,
      default: "",
    },
    // allows_crud: {
    //   type: Boolean,
    //   default: false,
    // },
    display_id: {},
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      matter_contributions: names.MATTER_CONTRIBUTIONS,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "mesh.add_mattercontribution"
      );
      if (has_permission) return has_permission;
      else return false;
    },
    matter_contributions_fields() {
      return [
        {
          key: "name",
          label: this.$getVisibleNames(
            "mesh.mattercontribution",
            true,
            "Estrategias Curriculares"
          ),
          sortable: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          // requires_allows_crud: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
    breadcrumb_items() {
      return [
        { text: "Definiciones Curriculares", active: true },
        {
          text: this.$getVisibleNames(
            "mesh.mattercontribution",
            true,
            "Estrategias Curriculares"
          ),
          active: true,
        },
      ];
    },
    matterContributionsFields() {
      if (!this.allows_crud)
        return this.matter_contributions_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.matter_contributions_fields;
    },
  },
  methods: {
    askForDeleteMatterContribution(matter_contribution_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar la ${this.$getVisibleNames(
          "mesh.mattercontribution",
          false,
          "Estrategia Curricular"
        )}?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_MATTER_CONTRIBUTION, matter_contribution_id)
            .then(() => {
              toast(
                this.$getVisibleNames(
                  "mesh.mattercontribution",
                  false,
                  "Estrategia Curricular"
                ) + " eliminada."
              );
            });
        }
      });
    },
    slotCreatedMatterContribution() {
      this.$bvModal.hide("new-matter-contribution-modal");
    },
    slotUpdatedMatterContribution(matter_contribution) {
      this.$bvModal.hide(
        `edit-matter-contribution-modal-${matter_contribution.id}`
      );
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch(names.FETCH_MATTER_CONTRIBUTIONS, this.user.school);
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped>
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}

.title-header {
  padding: 0;
  margin-top: auto;
}
.search-matter-contribution {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
</style>