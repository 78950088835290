var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{staticClass:"label",attrs:{"label":"Nombre:","label-for":"input-name","label-cols":"3","label-cols-sm":"2"}},[_c('b-form-input',{staticClass:"input-name",attrs:{"id":"input-name","name":"input-name","state":_vm.validateState('name'),"aria-describedby":"input-name-live-feedback","size":"sm"},model:{value:(_vm.$v.profile_competence_mention.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.profile_competence_mention.name, "$model", $$v)},expression:"$v.profile_competence_mention.name.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-live-feedback"}},[_vm._v("Este campo debe tener al menos 3 caracteres.")])],1),_c('b-form-group',{staticClass:"label",attrs:{"label":"Descripción:","label-for":"input-description","label-cols":"3","label-cols-sm":"2"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:('Editar Descripción'),expression:"'Editar Descripción'",modifiers:{"v-secondary":true}}],staticClass:"profile_competence_mention-form-description rich-text-content mr-0 mt-1",domProps:{"innerHTML":_vm._s(_vm.$v.profile_competence_mention.description.$model)},on:{"click":function($event){return _vm.$bvModal.show(
          `edit-profile_competence_mention-description-modal-${_vm.profile_competence_mention.id}`
        )}}}),_c('b-modal',{attrs:{"id":`edit-profile_competence_mention-description-modal-${_vm.profile_competence_mention.id}`,"title":"Editar Descripción","size":"lg","hide-footer":""}},[_c('RichTextEditor',{attrs:{"Object":_vm.profile_competence_mention,"Text":_vm.profile_competence_mention.description},on:{"update":_vm.patchProfileCompetenceMention,"close":function($event){return _vm.$bvModal.hide(
            `edit-profile_competence_mention-description-modal-${_vm.profile_competence_mention.id}`
          )}}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"input-description-feedback"}},[_vm._v("Este campo es opcional.")])],1),_c('b-form-group',{staticClass:"label",attrs:{"label":_vm.$getVisibleNames('mesh.competence', true, 'Competencias') + ':',"label-for":"input-egress_profile_competences","label-cols":"3","label-cols-sm":"2"}},[(_vm.competenceOptions.length > 0)?_c('b-form-select',{staticClass:"select-form",attrs:{"id":"input-egress_profile_competences","state":_vm.validateState('egress_profile_competences'),"aria-describedby":"input-egress_profile_competences-feedback","size":"sm","multiple":"","select-size":_vm.competenceOptions.length <= 5 ? _vm.competenceOptions.length : 9},model:{value:(
        _vm.$v.profile_competence_mention.egress_profile_competences.$model
      ),callback:function ($$v) {_vm.$set(_vm.$v.profile_competence_mention.egress_profile_competences, "$model", $$v)},expression:"\n        $v.profile_competence_mention.egress_profile_competences.$model\n      "}},_vm._l((_vm.competenceOptions),function(item){return _c('b-select-option',{key:item.id,staticClass:"select-option",attrs:{"value":item.id,"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_c('div',{staticClass:"pt-2"},[_vm._v(" El "+_vm._s(_vm.$getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso"))+" no cuenta con "+_vm._s(_vm.$getVisibleNames("mesh.competence", true, "Competencias"))+". ")]),_c('b-form-invalid-feedback',{attrs:{"id":"input-egress_profile_competences-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),_c('div',{staticClass:"row mt-3"},[(_vm.show_delete_button && !isNaN(this.profile_competence_mention.id))?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.askForDeleteObject}},[_vm._v("Eliminar")])],1):_vm._e(),(_vm.show_save_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"m-0 mr-1",attrs:{"size":"sm"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }