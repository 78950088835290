import { render, staticRenderFns } from "./MatterList.vue?vue&type=template&id=3753d170&scoped=true&"
import script from "./MatterList.vue?vue&type=script&lang=js&"
export * from "./MatterList.vue?vue&type=script&lang=js&"
import style0 from "./MatterList.vue?vue&type=style&index=0&id=3753d170&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3753d170",
  null
  
)

export default component.exports