var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.matrix2_competences)?_c('div',{staticClass:"edit-section mb-3 mt-2"}):_vm._e(),(!_vm.matrix2_competences)?_c('b-breadcrumb',{staticClass:"breadcrumb pb-2 pt-1 noprint",attrs:{"items":_vm.breadcrumb_items}}):_vm._e(),_c('div',{staticStyle:{"display":"flex"}},[(_vm.allows_cruds && _vm.user && _vm.user.is_superuser)?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_competence'),expression:"'mesh.add_competence'"},{name:"b-modal",rawName:"v-b-modal.new-competence-modal",modifiers:{"new-competence-modal":true}}],staticClass:"btn btn-secondary btn-sm"},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames("mesh.competence", false, "Competencia"))+" ")])]):_vm._e()]),(!_vm.matrix2_competences)?_c('h3',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.competence", true, "Competencias"))+" ")]):_vm._e(),_c('div',{staticClass:"table-container"},[(!_vm.isBusy)?_c('GenericBTable',{key:_vm.keyGenericTable,attrs:{"items":_vm.filteredCompetences,"fields":_vm.fields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"selection_mode":true,"columns_display":true,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"emitChangeSearchField":_vm.changeInputSearch},scopedSlots:_vm._u([{key:"head(selected)",fn:function(){return [_c('b-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
            _vm.all_selected ? 'Deseleccionar Todos' : 'Seleccionar Todos'
          ),expression:"\n            all_selected ? 'Deseleccionar Todos' : 'Seleccionar Todos'\n          ",modifiers:{"v-secondary":true}}],on:{"input":_vm.selectAll},model:{value:(_vm.all_selected),callback:function ($$v) {_vm.all_selected=$$v},expression:"all_selected"}})]},proxy:true},{key:"cell(selected)",fn:function(row){return [_c('b-checkbox',{on:{"input":function($event){return _vm.selectCompetence(row.item.id, $event)}},model:{value:(row.item.selected),callback:function ($$v) {_vm.$set(row.item, "selected", $$v)},expression:"row.item.selected"}})]}},{key:"cell(cycle)",fn:function(row){return [_vm._l(([_vm.competenceCycle(row.item.cycle)]),function(cycle){return [(cycle)?_c('span',{key:cycle.id},[_vm._v(_vm._s(cycle.name))]):_c('span',{key:cycle},[_vm._v("N/A")])]})]}},{key:"cell(profile_competence)",fn:function(row){return [_c('p',[_vm._v(" "+_vm._s(_vm.profileCompetenceMention(row.item))+" ")])]}},{key:"cell(full_sentence)",fn:function(row){return [_c('SentenceContainer',{attrs:{"Sentence":row.item}})]}},{key:"cell(type)",fn:function(row){return [(row.item.type && _vm.competenceType(row.item.type))?[_vm._l(([_vm.competenceType(row.item.type)]),function(type){return [(type)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(type.description),expression:"type.description",modifiers:{"v-secondary":true}}],key:type.id},[_vm._v(_vm._s(type.name))]):_vm._e()]})]:_vm._e()]}},{key:"cell(egress_profile)",fn:function(row){return [_c('div',{staticClass:"profiles-container"},[_vm._l((_vm.getEgressProfileCompetence(row.item.id)),function(profiles){return [_c('div',{key:profiles.id,staticClass:"profiles-content"},[_vm._v(" "+_vm._s(profiles.professional_title)+" "),(
                  !(
                    (_vm.institution && _vm.institution.id == 12) ||
                    _vm.$debug_change_duoc
                  )
                )?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo"))+": ")]),_vm._v(_vm._s(profiles.cycle_name == "" ? "N/A" : profiles.cycle_name)+" ")]):_vm._e(),(profiles.mentions.length > 0)?_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "mesh.egressprofilecompetencemention", profiles.mentions.length > 1, "Mención" ))+": ")]),_vm._v(_vm._s(profiles.mentions.length > 1 ? "(" + profiles.mentions.join(", ") + ")" : profiles.mentions[0])+" ")]):_vm._e()])]})],2)]}},{key:"cell(ra_base)",fn:function(row){return [_c('h5',{key:`ra-base-header-${row.item.id}`,class:{ 'pointer-class': _vm.allows_cruds && !_vm.selection_mode },attrs:{"id":`ra-base-header-${row.item.id}`},on:{"click":() => {
              if (_vm.allows_cruds && !_vm.selection_mode)
                _vm.$bvModal.show(`modal-base-ra-${row.item.id}`);
            }}},[(_vm.raBasesFiltered)?_c('b-badge',{staticClass:"badge",attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.raBasesFiltered(row.item.id).length))]):_vm._e()],1),_c('b-modal',{attrs:{"id":`modal-base-ra-${row.item.id}`,"hide-footer":"","title":_vm.$getVisibleNames('teaching.basera', false, 'RA Base'),"size":"lg"}},[_c('RaBase',{attrs:{"competence_id":row.item.id}})],1),_c('b-popover',{key:`ra-base-tooltip-${row.item.id}`,attrs:{"target":`ra-base-header-${row.item.id}`,"triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$getVisibleNames("teaching.basera", false, "RA Base")))]},proxy:true}],null,true)},[(_vm.raBasesFiltered(row.item.id).length > 0)?[_c('div',{staticClass:"ra-competences-container"},_vm._l((_vm.raBasesFiltered(row.item.id)),function(ra){return _c('span',{key:ra.id},[_c('SentenceContainer',{attrs:{"Sentence":ra}})],1)}),0)]:[_vm._v("No cuenta con ningún "+_vm._s(_vm.$getVisibleNames("teaching.basera", false, "RA Base"))+" creado ")]],2)]}},(_vm.allows_cruds)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_competence'),expression:"'mesh.change_competence'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:({
            customClass: 'custom-tooltip',
          }),expression:"{\n            customClass: 'custom-tooltip',\n          }",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}],attrs:{"title":`Editar ${_vm.$getVisibleNames(
            'mesh.competence',
            false,
            'Competencia'
          )}`},on:{"click":function($event){return _vm.$bvModal.show(`edit-competence-modal-${row.item.id}`)}}}),(_vm.allows_cruds && _vm.user && _vm.user.is_superuser)?_c('button-delete',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_competence'),expression:"'mesh.delete_competence'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:({
            customClass: 'custom-tooltip',
          }),expression:"{\n            customClass: 'custom-tooltip',\n          }",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}],attrs:{"title":`Eliminar ${_vm.$getVisibleNames(
            'mesh.competence',
            false,
            'Competencia'
          )}`},on:{"click":function($event){return _vm.askForDeleteCompetence(row.item.id)}}}):_vm._e(),_c('b-modal',{attrs:{"id":`edit-competence-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.competence',
            false,
            'Competencia'
          )}`,"size":"lg","hide-footer":""},on:{"hide":_vm.resetCompetenceTable}},[_c('CompetenceForm',{attrs:{"Competence":row.item,"selected_ids":_vm.getEgressProfiles(row.item.id),"show_title":false},on:{"updated":_vm.slotUpdatedCompetence,"close":_vm.slotCloseModal,"reset_competence":function($event){return _vm.changeCompetenceDescriptor(row.item.id)},"change_middle_descriptors":() => {
                _vm.changeCompetenceDescriptor(row.item.id);
              },"reset_competence_table":_vm.countCompetenceTable}})],1)]}}:null],null,true)}):_vm._e()],1),_c('div',[_c('b-modal',{attrs:{"id":"new-competence-modal","title":`Crear ${_vm.$getVisibleNames(
        'mesh.competence',
        false,
        'Competencia'
      )}`,"size":"lg","hide-footer":""},on:{"hide":_vm.resetCompetenceTable}},[_c('CompetenceForm',{attrs:{"show_title":false},on:{"created":_vm.slotCreatedCompetence,"close":_vm.slotCloseModal}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }