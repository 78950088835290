<template>
  <div>
    <ResizeAuto>
      <template v-slot:default="{ resize }">
        <b-form-textarea
          @input="resize"
          @focus="resize"
          v-model.trim="text"
          @keydown.enter.prevent="update"
        ></b-form-textarea>
      </template>
    </ResizeAuto>
    <div v-if="showSaveBtn" class="row">
      <div class="col mt-1" style="text-align: right">
        <b-button class="mr-0" size="sm" @click="update">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ResizeAuto from "@/components/reusable/ResizeAuto";

export default {
  name: "TextArea",
  components: {
    ResizeAuto,
  },
  props: {
    Text: {
      type: String,
      default: "",
    },
    showSaveBtn: {
      type: Boolean,
      default: false,
    },
    clearTextArea: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      text: this.Text,
    };
  },
  methods: {
    update() {
      if (this.text != this.Text) this.$emit("update", this.text.trim());
      if (this.clearTextArea) this.text = "";
    },
  },
  // watch: {
  //   text: function () {
  //     this.text = this.text.replace(
  //       /\d\.\s+|•\s+|[A-Z]\.\s+|[IVX]+\.\s+/g,
  //       ""
  //     );
  //   },
  // },
};
</script>

<style scoped>
label {
  cursor: pointer;
}
textarea {
  width: 100%;
  border: 1px solid var(--secondary-color);
}
</style>