import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    egress_profiles_matters: [],
    egress_profiles_matters_url: '/mesh/egress-profile-matter',
    matter_codes: [],
    matter_code_url: '/teaching/get_trimmed_matters',
    egress_profile_matter_descriptors: [],
    egress_profile_matter_descriptor_url: '/mesh/egress-profile-matter-descriptor',
    epm_middle_descriptors: [],
    epm_middle_descriptor_url: '/mesh/epm-middle-descriptor',
}

const getters = {
    [names.EGRESS_PROFILES_MATTERS]: state => {
        if (!get.store.state.user) return [];
        return state.egress_profiles_matters.filter((x) => x.school == get.store.state.user.school).sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });
    },
    [names.MATTER_CODES]: state => {
        return state.matter_codes;
    },
    [names.EGRESS_PROFILE_MATTER_DESCRIPTORS]: state => {
        if (!get.store.state.user) return [];
        return state.egress_profile_matter_descriptors.filter((x) => x.school == get.store.state.user.school);
    },
    [names.EPM_MIDDLE_DESCRIPTORS]: state => {
        if (!get.store.state.user) return [];
        return state.epm_middle_descriptors.filter((x) => x.school == get.store.state.user.school);
    },
}

const mutations = {
    [names.MUTATE_EGRESS_PROFILES_MATTERS]: (state, egress_profiles_matters) => {
        egress_profiles_matters.forEach(element => {
            let index = state.egress_profiles_matters.findIndex(x => x.id == element.id);
            if (index != -1) state.egress_profiles_matters.splice(index, 1, element);
            else state.egress_profiles_matters.push(element);
        });
    },
    [names.MUTATE_EGRESS_PROFILE_MATTER]: (state, egress_profile_matter) => {
        let index = state.egress_profiles_matters.findIndex(x => x.id == egress_profile_matter.id);
        if (index != -1) state.egress_profiles_matters.splice(index, 1, egress_profile_matter);
        else state.egress_profiles_matters.push(egress_profile_matter);
    },
    [names.MUTATE_DELETE_EGRESS_PROFILE_MATTER]: (state, egress_profile_matter_id) => {
        let index = state.egress_profiles_matters.findIndex(x => x.id == egress_profile_matter_id);
        if (index != -1) state.egress_profiles_matters.splice(index, 1);
    },
    ["MUTATE_DELETE_EGRESS_PROFILE_MATTER_BY_BASE_MATTER"]: (state, matter_id) => {
        let index = state.egress_profiles_matters.findIndex(x => x.matter == matter_id);
        while (index != -1) {
            state.egress_profiles_matters.splice(index, 1);
            index = state.egress_profiles_matters.findIndex(x => x.matter == matter_id);
        }
    },
    [names.MUTATE_MATTER_CODES]: (state, matter_codes) => {
        matter_codes.forEach(element => {
            let index = state.matter_codes.findIndex(x => x.id == element.id);
            if (index != -1) state.matter_codes.splice(index, 1, element);
            else state.matter_codes.push(element);
        });
    },
    [names.MUTATE_EGRESS_PROFILE_MATTER_DESCRIPTOR]: (state, egress_profile_matter_descriptor) => {
        let index = state.egress_profile_matter_descriptors.findIndex(x => x.id == egress_profile_matter_descriptor.id);
        if (index != -1) state.egress_profile_matter_descriptors.splice(index, 1, egress_profile_matter_descriptor);
        else state.egress_profile_matter_descriptors.push(egress_profile_matter_descriptor);
    },
    [names.MUTATE_EGRESS_PROFILE_MATTER_DESCRIPTORS]: (state, egress_profile_matter_descriptors) => {
        egress_profile_matter_descriptors.forEach(element => {
            let index = state.egress_profile_matter_descriptors.findIndex(x => x.id == element.id);
            if (index != -1) state.egress_profile_matter_descriptors.splice(index, 1, element);
            else state.egress_profile_matter_descriptors.push(element);
        });
    },
    [names.MUTATE_DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS]: (state, egress_profile_matter_descriptor_id) => {
        let index = state.egress_profile_matter_descriptors.findIndex(x => x.id == egress_profile_matter_descriptor_id);
        if (index != -1) state.egress_profile_matter_descriptors.splice(index, 1);
    },
    [names.MUTATE_EPM_MIDDLE_DESCRIPTOR]: (state, epm_middle_descriptor) => {
        let index = state.epm_middle_descriptors.findIndex(x => x.id == epm_middle_descriptor.id);
        if (index != -1) state.epm_middle_descriptors.splice(index, 1, epm_middle_descriptor);
        else state.epm_middle_descriptors.push(epm_middle_descriptor);
    },
    [names.MUTATE_EPM_MIDDLE_DESCRIPTORS]: (state, epm_middle_descriptors) => {
        epm_middle_descriptors.forEach(element => {
            let index = state.epm_middle_descriptors.findIndex(x => x.id == element.id);
            if (index != -1) state.epm_middle_descriptors.splice(index, 1, element);
            else state.epm_middle_descriptors.push(element);
        });
    },
    [names.MUTATE_DELETE_EPM_MIDDLE_DESCRIPTORS]: (state, epm_middle_descriptor_id) => {
        let index = state.epm_middle_descriptors.findIndex(x => x.id == epm_middle_descriptor_id);
        if (index != -1) state.epm_middle_descriptors.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_EGRESS_PROFILE_MATTER]: ({
        commit,
        state
    }, egress_profile_matter_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.egress_profiles_matters_url}/${egress_profile_matter_id}/`)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EGRESS_PROFILES_MATTERS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '';
            if (payload) {
                if (payload.egress_profile_id && payload.cycle_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id + '&cycle=' + payload.cycle_id;
                else if (payload.egress_profile_id)
                    url_append += '?egress_profile=' + payload.egress_profile_id;
                else if (payload.cycle_id)
                    url_append += '?cycle=' + payload.cycle_id;
            }
            restful.Get(`${state.egress_profiles_matters_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILES_MATTERS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EGRESS_PROFILE_MATTER]: ({
        commit,
        state
    }, egress_profile_matter) => {
        return new Promise((resolve) => {
            restful.Post(`${state.egress_profiles_matters_url}/`, egress_profile_matter)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EGRESS_PROFILE_MATTER]: ({
        commit,
        state
    }, egress_profile_matter) => {
        return new Promise((resolve) => {
            restful.Put(`${state.egress_profiles_matters_url}/${egress_profile_matter.id}/`, egress_profile_matter)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EGRESS_PROFILE_MATTER]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.egress_profiles_matters_url}/${payload.egress_profile_matter_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILE_MATTER, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EGRESS_PROFILE_MATTER]: ({
        commit,
        state,
        dispatch,
    }, egress_profile_matter_id) => {
        return new Promise((resolve) => {
            const ep_matter = { ...state.egress_profiles_matters.find(x => x.id == egress_profile_matter_id) }
            if (ep_matter) {
                dispatch(names.FETCH_STUDY_UNITS, ep_matter.matter).then(study_units => {
                    if (study_units.length > 0)
                        commit("MUTATE_DELETE_EGR_PRO_RA_BASE_BY_EGR_PRO_AND_STU_UNI", { egress_profile: ep_matter.egress_profile, study_units: study_units.map(x => x.id) });
                })
                restful.Delete(`${state.egress_profiles_matters_url}/${egress_profile_matter_id}/`)
                    .then(response => {
                        commit(names.MUTATE_DELETE_EGRESS_PROFILE_MATTER, egress_profile_matter_id);
                        resolve(response);
                    });
            }
        });
    },
    [names.FETCH_MATTER_CODES]: ({
        commit,
        state
    }) => {
        return new Promise((resolve) => {
            restful.Get(`${state.matter_code_url}/`)
                .then(response => {
                    commit(names.MUTATE_MATTER_CODES, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EGRESS_PROFILE_MATTER_DESCRIPTORS]: ({
        commit,
        state,
        matter_id
    }) => {
        return new Promise((resolve) => {
            let arr = [];
            if (matter_id) arr.push('egress_profile_matter=' + matter_id);
            let partial_url = '';
            if (arr.length > 0) partial_url = '?' + arr.join('&&');
            restful.Get(`${state.egress_profile_matter_descriptor_url}/${partial_url}`)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILE_MATTER_DESCRIPTORS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EGRESS_PROFILE_MATTER_DESCRIPTORS]: ({
        commit,
        state
    }, egress_profile_matter_descriptor) => {
        return new Promise((resolve) => {
            restful.Post(`${state.egress_profile_matter_descriptor_url}/`, egress_profile_matter_descriptor)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILE_MATTER_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EGRESS_PROFILE_MATTER_DESCRIPTORS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.egress_profile_matter_descriptor_url}/${payload.id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILE_MATTER_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS]: ({
        commit,
        state
    }, id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.egress_profile_matter_descriptor_url}/${id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS, id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EPM_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, search_id) => {
        return new Promise((resolve) => {
            let arr = [];
            if (search_id) arr.push('egress_profile_matter=' + search_id);
            let partial_url = '';
            if (arr.length > 0) partial_url = '?' + arr.join('&&');
            restful.Get(`${state.epm_middle_descriptor_url}/${partial_url}`)
                .then(response => {
                    commit(names.MUTATE_EPM_MIDDLE_DESCRIPTORS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EPM_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, epm_middle_descriptor) => {
        return new Promise((resolve) => {
            restful.Post(`${state.epm_middle_descriptor_url}/`, epm_middle_descriptor)
                .then(response => {
                    commit(names.MUTATE_EPM_MIDDLE_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EPM_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.epm_middle_descriptor_url}/${payload.id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EPM_MIDDLE_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EPM_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.epm_middle_descriptor_url}/${id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EPM_MIDDLE_DESCRIPTORS, id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};