<template>
  <div>
    <b-table-simple small responsive>
      <b-thead>
        <b-tr>
          <b-th
            :colspan="allows_crud ? 5 : 4"
            style="text-align: center"
            class="pl-3 evaluation-header"
          >
            EXPERIENCIAS DE APRENDIZAJE
            <div class="evaluation-button-div">
              <b-button
                v-if="allows_crud"
                class="mx-1"
                size="sm"
                @click="
                  $bvModal.show(`add-learning-experience-modal-${matter_id}`)
                "
                >Agregar Experiencia de Aprendizaje</b-button
              >
            </div>
          </b-th>
        </b-tr>
        <b-tr>
          <b-td
            style="width: 3%"
            class="text-center"
            v-if="allows_crud && draggableList.length > 0"
          >
          </b-td>
          <b-td style="width: 30%" class="text-center">
            <strong> NOMBRE </strong>
          </b-td>
          <b-td style="width: 30%" class="text-center">
            <strong> DESCRIPCIÓN </strong>
          </b-td>
          <b-td style="width: 35%" class="text-center">
            <strong>
              {{
                $getVisibleNames(
                  "teaching.ramicro",
                  true,
                  "RA Micro"
                ).toUpperCase()
              }}
            </strong>
          </b-td>
          <b-td
            style="width: 2%"
            v-if="allows_crud && draggableList.length > 0"
          ></b-td>
        </b-tr>
      </b-thead>
      <draggable
        v-model="draggableList"
        :disabled="!allows_crud"
        tag="b-tbody"
        handle=".handle"
      >
        <template v-if="draggableList.length > 0">
          <b-tr
            v-for="learning_experience in draggableList"
            :key="learning_experience.id"
          >
            <b-td
              class="text-center handle"
              :class="{ grabbable: allows_crud }"
              v-if="allows_crud"
              ><b-icon
                v-if="allows_crud"
                icon="arrows-expand"
                scale="1.2"
              ></b-icon
            ></b-td>
            <b-td class="text-center"> {{ learning_experience.name }}</b-td>
            <b-td
              class="rich-text-content px-2"
              v-html="learning_experience.description"
            ></b-td>
            <!-- study_units -->
            <b-td>
              <div class="d-flex align-center" style="justify-content: center">
                <div
                  v-for="study_unit in studyUnits.filter((x) =>
                    learning_experience.study_units.includes(x.id)
                  )"
                  :key="'study_units:' + study_unit.id"
                >
                  <div class="text-center">
                    <b-badge
                      class="ec-micro-badge"
                      v-b-tooltip.v-secondary.noninteractive="
                        `${study_unit.full_sentence}`
                      "
                    >
                      N°{{ study_unit.order }}
                    </b-badge>
                  </div>
                </div>
              </div>
            </b-td>
            <!-- actions -->
            <b-td class="text-center" v-if="allows_crud">
              <button-edit
                v-if="allows_crud"
                v-b-tooltip.v-secondary.noninteractive.top="
                  'Editar Experiencia de Aprendizaje'
                "
                fill="white"
                @click="
                  $bvModal.show(
                    `edit-learning-experience-modal-${learning_experience.id}`
                  )
                "
              ></button-edit>
              <button-delete
                v-b-tooltip.v-secondary.noninteractive.top="
                  'Eliminar Experiencia de Aprendizaje'
                "
                @click="deleteLearningExperience(learning_experience.id)"
              ></button-delete>
              <b-modal
                :id="`edit-learning-experience-modal-${learning_experience.id}`"
                title="Editar Experiencia de Aprendizaje"
                size="lg"
                hide-footer
              >
                <LearningExperienceForm
                  :matter_id="matter_id"
                  :max_order="maxOrder"
                  :LearningExperience="learning_experience"
                  @updated="updatedLearningExperience"
                >
                </LearningExperienceForm>
              </b-modal>
            </b-td>
          </b-tr>
        </template>
        <b-tr v-else>
          <b-td class="text-center" colspan="5"
            >No hay Experiencia de Aprendizaje para mostrar.</b-td
          >
        </b-tr>
      </draggable>
    </b-table-simple>
    <b-modal
      :id="`add-learning-experience-modal-${matter_id}`"
      title="Crear Experiencia de Aprendizaje"
      size="lg"
      hide-footer
    >
      <LearningExperienceForm
        :matter_id="matter_id"
        :max_order="maxOrder"
        @created="createdLearningExperience"
      >
      </LearningExperienceForm>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { generateUniqueId } from "@/utils/utils";
export default {
  name: "LearningExperience",
  components: {
    draggable,
    LearningExperienceForm: () => import("./LearningExperienceForm.vue"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
    },
  },
  data() {
    return {
      learning_experiences: [],
    };
  },
  computed: {
    ...mapGetters({
      studyUnits: names.STUDY_UNITS,
    }),
    studyUnitsList() {
      if (!this.matter) return [];
      return this.studyUnits
        .filter((x) => x.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    maxOrder() {
      let order = 0;
      this.learning_experiences.forEach((x) => (order = x.order));
      order += 1;
      return order;
    },
    draggableList: {
      get() {
        let list = this.learning_experiences;
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              this.updatedLearningExperience(element);
            }
          }
          order += 1;
        });
      },
    },
  },
  methods: {
    fetchLearningExperiences() {
      this.$restful
        .Get(`/teaching/learning-experience/?matter=${this.matter_id}`)
        .then((response) => {
          this.learning_experiences = response.sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          });
        });
    },
    createdLearningExperience(learning_experience) {
      this.$restful
        .Post(`/teaching/learning-experience/`, learning_experience)
        .then((response) => {
          this.learning_experiences.push(response);
          this.$bvModal.hide(`add-learning-experience-modal-${this.matter_id}`);
        });
    },
    updatedLearningExperience(learning_experience) {
      this.$restful
        .Put(
          `/teaching/learning-experience/${learning_experience.id}/`,
          learning_experience
        )
        .then((response) => {
          const index = this.learning_experiences.findIndex(
            (x) => x.id == response.id
          );
          if (index != -1) {
            this.learning_experiences.splice(index, 1, response);
            this.$bvModal.hide(`edit-learning-experience-modal-${response.id}`);
          }
        });
    },
    deleteLearningExperience(id) {
      this.$swal({
        title:
          "¿Está seguro de que desea eliminar la Experiencia de Aprendizaje?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(`/teaching/learning-experience/${id}/`)
            .then(() => {
              const index = this.learning_experiences.findIndex(
                (x) => x.id == id
              );
              if (index != -1) {
                this.learning_experiences.splice(index, 1);
              }
            });
        }
      });
    },
  },
  mounted() {},
  created() {
    this.fetchLearningExperiences();
  },
};
</script>

<style scoped>
table th {
  vertical-align: middle;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.evaluation-header {
  background: var(--primary-color) !important;
  border: 1px solid #000 !important;
}
.evaluation-button-div {
  float: right;
  /* padding-right: 1rem; */
}
table td {
  border: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.ec-badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.ec-micro-badge {
  font-size: var(--thirth-font-size);
  text-align: center;
  width: fit-content !important;
  margin-right: 0.3em;
}
@media print {
  .ec-micro-badge {
    border: none;
  }
}
</style>