
<template>
  <div id="app" :class="{ 'not-logged-in': !isLoged }">
    <!-- :class="{
        'custom-margin-bottom': app_name == 'KEY LEARNING' && !isLoged,
      }" 
      mt-4-->
    <div class="section1" id="mainsection">
      <go-top
        :right="0"
        title="Ir al tope de la página"
        :boundary="gotop_boundary"
        :radius="5"
        class="noprint"
      ></go-top>

      <new-nav-bar />
      <!-- <nav-bar /> -->

      <Menu />
      <main id="page-wrap" :class="{ 'router-container': isLoged }">
        <div v-if="isLoged" class="div-header">
          <b-img
            v-if="isLoged && institution_logo"
            v-bind:src="institution_logo"
            width="60px"
            height="58px"
          ></b-img>
        </div>
        <b-container
          class="app-container pt-0"
          style="padding-left: 0 !important; padding-right: 0 !important"
        >
          <router-view></router-view>
        </b-container>
      </main>
      <toolbar-component></toolbar-component>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  expirationTime,
  EventBus,
  isValidJwt,
  base64UrlToBytes,
} from "@/utils/utils";
import { APP_NAME } from "@/utils/globals";
import NewNavBar from "@/components/new-page/NavBar";
// import NavBar from "@/components/public/NavBar";
// import Footer from "@/components/public/Footer";
import toolbar from "@/components/public/Toolbar";
import * as names from "@/store/names";
import GoTop from "@inotom/vue-go-top";

export default {
  components: {
    NewNavBar,
    // NavBar,
    // Footer,
    "toolbar-component": toolbar,
    "go-top": GoTop,
  },
  data() {
    return {
      loading: false,
      loading_value: 0,
      loading_max: 0,
      gotop_boundary: 100,
      app_name: APP_NAME,
    };
  },
  computed: {
    ...mapGetters({
      isLoged: "isLoged",
      institution: "getInstitution",
      institution_logo: "getInstitutionLogo",
    }),
  },
  methods: {
    launchAlertTimeout() {
      if (localStorage.jwt_token) {
        // Computamos el tiempo restante del token y le restamos 15 segundos,
        // el formulario estara activo por 10 segundos,
        // los restantes 5 segundos son el margen para que la peticion se ejecute.
        // Si el token expira, en getters.isLoged eliminara toda la data del storage
        // aunque se refresque el token y eso lo queremos evitar.
        // Por lo tanto el token debera ser valido todo el tiempo.
        const time_to_launch_modal =
          expirationTime(localStorage.jwt_token) - 15000;
        const time_modal_alive = 10000;
        setTimeout(() => {
          if (!isValidJwt(localStorage.jwt_token)) {
            return;
          }
          this.$swal
            .fire({
              title: "Su sesión va a expirar pronto",
              text: "¿Desea seguir navegando?",
              type: "question",
              timer: time_modal_alive,
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: "Si",
              cancelButtonText: "No",
              preConfirm: () => {
                this.$store.dispatch("updateToken");
              },
            })
            .then((result) => {
              if (result.dismiss === "timer") {
                // El usuario no seleciona nada.
                // Volvemos a establecer el timeOut en caso de que haya renovado en otra pagina.
                if (
                  expirationTime(localStorage.jwt_token) - 15000 >
                  time_modal_alive
                ) {
                  // console.log("Reestableciendo tiempo de expiracion.");
                  this.launchAlertTimeout();
                }
              } else if (result.dismiss === "cancel") {
                // El usuario no revova el token.
                this.$logOut();
              }
            });
        }, time_to_launch_modal);
      }
    },
    launchExpirationModal() {
      this.$swal.fire({
        title: "Su sesión ha expirado",
        text: "Se ha agotado el tiempo de sección.",
        type: "info",
        allowEscapeKey: true,
        allowOutsideClick: true,
        showConfirmButton: true,
        showCancelButton: false,
      });
    },
  },
  watch: {
    $route(to) {
      if (this.isLoged && to.name == "ResetPassword") {
        this.$router.push({ name: "Welcome" });
      }
    },
    isLoged() {
      if (this.isLoged) {
        this.$store.dispatch(names.FETCH_TAXONOMIES);
        this.$store.dispatch(names.FETCH_CYCLES);
        this.$store.dispatch(names.FETCH_CAMPUSES);
        this.$store.dispatch(names.FETCH_COMPETENCE_TYPES);
        this.$store.dispatch(names.FETCH_FACULTIES);
        this.$store.dispatch(names.FETCH_INSTRUCTIVES).then((response) => {
          response.forEach((instructive) => {
            this.$store.dispatch(names.FETCH_FILE, instructive.file);
          });
        });
        this.$store.dispatch(names.FETCH_FORMATION_AREAS);
        this.$store.dispatch(names.FETCH_MODALITIES);
        this.$store.dispatch(names.FETCH_SHIFTS);
        this.$store.dispatch(names.FETCH_PERIODS);
        this.$store.dispatch(names.FETCH_REGIMES);
      }
    },
  },
  created() {
    if (APP_NAME == "GERA") {
      import("./utils/styles_gera.css");
    } else {
      import("./utils/styles_kl.css");
    }
    if (APP_NAME) document.title = APP_NAME;
    const logo = this.$logo;
    if (logo) document.icon = logo;

    EventBus.$on("loadStarted", () => {
      this.loading = true;
    });
    EventBus.$on("loadFinished", () => {
      this.loading = false;
    });
    EventBus.$on("increment", (max) => {
      this.loading_max = max;
    });
    EventBus.$on("decrement", (value) => {
      this.loading_value = value;
    });
  },
  mounted() {
    clearInterval();
    // Set up a timer to check the token's expiration time periodically
    setInterval(() => {
      const token = localStorage.getItem("jwt_token");
      console.log(token ? "Have token" : "No token");
      try {
        const [, payload] = token.split(".");
        const decodedPayload = JSON.parse(
          new TextDecoder().decode(base64UrlToBytes(payload))
        );
        const expirationDate = new Date(decodedPayload.exp * 1000);
        if (expirationDate < new Date()) {
          this.launchExpirationModal();
          this.$logOut();
        }
      } catch (error) {
        this.$logOut();
      }
    }, 60000); // Check every x minutes (60000 * x)
    // this.launchAlertTimeout();

    // Para que cuando actualicen la página se guarden los alternative_names del local_storage al store
    if (this.isLoged) this.$store.dispatch(names.FETCH_ALTERNATIVE_NAMES);
  },
};
</script>

<style>
:root {
  -webkit-print-color-adjust: exact !important;
}
.primary-color {
  background-color: var(--primary-color) !important;
  color: var(--primary-font-color) !important;
}
.secondary-color {
  background-color: var(--secondary-color) !important;
  color: var(--primary-font-color) !important;
}
.primary-font-size {
  font-size: var(--primary-font-size);
}
.secondary-font-size {
  font-size: var(--secondary-font-size);
}
.thirth-font-size {
  font-size: var(--thirth-font-size);
}
.primary-button {
  background-color: var(--primary-color) !important;
  color: var(--primary-font-color) !important;
}
.primary-button:hover {
  background-color: var(--primary-hover-color) !important;
  color: var(--primary-font-color) !important;
}
.secondary-button {
  background-color: var(--secondary-color) !important;
  color: var(--secondary-font-color) !important;
}
.secondary-button:hover {
  background-color: var(--secondary-hover-color) !important;
  color: var(--secondary-font-color) !important;
}
.btn-primary,
.btn-secondary,
.btn-danger {
  color: var(--secondary-font-color) !important;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 78px;
  overflow: hidden;
}
.not-logged-in {
  padding-top: 49px !important;
}
.bm-menu {
  top: 78px !important;
}
.bm-burger-button {
  top: 84px;
  left: 15px;
}
.navbar-nav .nav-link {
  color: white;
  text-align: left;
}
.section1 {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  right: 0;
  box-sizing: border-box;
  background: #fff;
  /* background: -moz-linear-gradient(
    0deg,
    rgba(193, 193, 193, 1) 0%,
    rgba(227, 227, 227, 1) 10%,
    rgba(255, 255, 255, 1) 33%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 1) 92%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(193, 193, 193, 1) 0%,
    rgba(227, 227, 227, 1) 10%,
    rgba(255, 255, 255, 1) 33%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 1) 92%
  );
  background: linear-gradient(
    0deg,
    rgba(193, 193, 193, 1) 0%,
    rgba(227, 227, 227, 1) 10%,
    rgba(255, 255, 255, 1) 33%,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 1) 92%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c1c1c1",endColorstr="#ffffff",GradientType=1); */
  /* padding-bottom: 2em; */
  /* margin-bottom: 300px; */
  /* margin-bottom: 60px; */
  box-shadow: 0 2px 3px -2px rgb(0, 0, 0);
  transition: all 0.3s;
}
.section1.active {
  margin-left: 250px;
  padding-right: 250px;
}
/* .custom-margin-bottom { */
/* margin-bottom: 380px !important; */
/* } */
/* .sidebar-btn {
  position: fixed;
  top: 67px !important;
  left: 0;
  z-index: 1000;
  transition: all 0.3s;
}
.sidebar-btn-active {
  margin-left: 250px;
}
.sidebar-btn .btn-collapse {
  width: 25px;
  height: 25px;
  background: var(--header-color) !important;
  border: var(--header-color) !important;
}
.sidebar-btn .btn-collapse:hover {
  background: #14abd8 !important;
}
.sidebar-btn-icon {
  transform: translate(-25%, -10%);
} */
@media only screen and (max-width: 600px) {
  .section1 {
    position: relative;
    width: 100%;
    min-height: 100vh;
    z-index: 10;
    padding: 5px;
    box-sizing: border-box;
    background: #fff;
    /* margin-bottom: 100px; */
    line-height: 30px;
    box-shadow: 0 2px 3px -2px rgb(0, 0, 0);
    transition: all 0.3s;
  }
}
.app-container {
  width: 100%;
  max-width: 100% !important;
  min-height: 100vh;
}
.router-container {
  width: 100% !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}
/* Clases above y below para mostrar los botones en el fondo de las celdas de las tablas. */
.above {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 5px;
  vertical-align: middle;
}
.below {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  -webkit-box-align: center;
}
.big-table-content {
  font-size: 8pt;
  text-align: justify;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.dirty {
  border-color: #5a5;
  background: #efe;
}
.dirty:focus {
  outline-color: #8e8;
}
.error {
  border-color: red;
  background: #fdd;
}
.input--error {
  border-color: red;
}
.input--error:focus {
  border-color: red;
}
.error-message {
  font-size: 8pt;
}
.error:focus {
  outline-color: #f99;
}
div.vue-go-top {
  width: 50px !important;
  height: 50px !important;
  background-color: var(--header-color) !important;
  bottom: calc(30px) !important;
}
ul,
li {
  margin-left: 0.5em;
}
.tooltip-inner {
  max-width: 230px !important;
}
.div-header {
  display: none;
}
@media print {
  .div-header {
    display: flex !important;
    margin-right: auto !important;
    margin-left: 3% !important;
    margin-top: 0 !important;
  }
  #app {
    padding-top: 0 !important;
  }
  .noprint {
    display: none !important;
  }
  .section1 {
    min-height: 0 !important;
  }
  .app-container {
    min-height: 0 !important;
  }
}
</style>
