<template>
  <tbody v-if="mattersBySemesterAndArea.length > 0">
    <th :colspan="10 + time_allocations.length">
      {{ formationArea.name }}
      <span v-can="'mesh.change_egressprofile'">
        <span v-if="limitFormationAreas && limitFormationAreas.max_hours != 0">
          -
          {{
            $getVisibleNames(
              "manual.total_pedagogical_hours",
              true,
              "Total de Horas Pedagógicas"
            )
          }}:
          <span
            :class="
              limitFormationAreas &&
              limitFormationAreas.max_hours < maxHoursAndCredits.max_hours
                ? 'limit-class'
                : ''
            "
            v-b-tooltip.v-secondary.top.noninteractive="
              `${$getVisibleNames(
                'manual.total_pedagogical_hours',
                true,
                'Total de Horas Pedagógicas'
              )} en el Área.`
            "
          >
            {{ maxHoursAndCredits.max_hours }}
          </span>
          <span
            v-if="limitFormationAreas && limitFormationAreas.max_hours != 0"
            v-b-tooltip.v-secondary.top.noninteractive="'Máximo sugerido.'"
            >/ {{ limitFormationAreas.max_hours }}</span
          >
        </span>

        <span
          v-if="limitFormationAreas && limitFormationAreas.max_credits != 0"
        >
          - Total Créditos:
          <span
            :class="
              limitFormationAreas &&
              limitFormationAreas.max_credits < maxHoursAndCredits.max_credits
                ? 'limit-class'
                : ''
            "
            v-b-tooltip.v-secondary.top.noninteractive="
              'Total de Créditos en el Área.'
            "
          >
            {{ maxHoursAndCredits.max_credits }}
          </span>
          <span
            v-if="limitFormationAreas && limitFormationAreas.max_credits != 0"
            v-b-tooltip.v-secondary.top.noninteractive="
              'Máximo de Créditos sugerido.'
            "
            >/ {{ limitFormationAreas.max_credits }}</span
          >
        </span>
      </span>
    </th>
    <template v-for="matter in mattersBySemesterAndArea">
      <matter-row
        v-if="!(matter.plan_level != semester && matter.regime == 1)"
        :key="matter.id + '-' + componentKey"
        :Matter="matter"
        :egress_profile_time_allocations="egress_profile_time_allocations"
        :OldMatter="matters.find((x) => x.id == matter.matter)"
        :egress_profile_id="egress_profile_id"
        :allows_crud="matter.plan_level === semester && allows_crud"
        @forceUpdate="forceUpdate"
      >
        <!-- {{ mattersOfPreviousArea + index + 1 }} -->
      </matter-row>
    </template>
  </tbody>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "StudyPlanForm",
  components: {
    "matter-row": () => import("@/components/mesh/StudyPlan/MatterRow"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    semester: {
      type: Number,
      required: true,
    },
    limit_study_plan: {
      type: Object,
      default: null,
    },
    egress_profile_time_allocations: {
      type: Array,
      default: () => [],
    },
    formation_area_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return { componentKey: 0 };
  },
  methods: {
    forceUpdate() {
      this.componentKey += 1;
    },
  },
  computed: {
    ...mapGetters({
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      courses_wrapper: names.COURSES_WRAPPER,
      egressProfiles: names.EGRESS_PROFILES,
      profile_types: names.PROFILE_TYPES,
      regimes: names.REGIMES,
      formationAreas: names.FORMATION_AREAS,
      time_allocations: names.TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
    }),
    isAnual() {
      const months_amount_egress_profile = this.regimes.find(
        (x) => x.id == this.egressProfile.regime
      );
      if (months_amount_egress_profile) {
        if (months_amount_egress_profile.months_amount == 6)
          return this.regimes.find(
            (x) =>
              x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
          );
        else if (months_amount_egress_profile.months_amount == 3)
          return this.regimes.find(
            (x) =>
              x.name.includes("Semestral") || parseInt(x.months_amount) / 3 == 2
          );
        else if (months_amount_egress_profile.months_amount == 2)
          return this.regimes.find(
            (x) =>
              x.name.includes("Trimestral") || parseInt(x.months_amount) == 3
          );
      }
      return this.regimes.find(
        (x) => x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
      );
    },
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    coursesWrapperList() {
      return this.courses_wrapper.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
    },
    wrapperMatterIds() {
      let list = [];
      this.coursesWrapperList.forEach((x) => {
        list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    matterList() {
      return this.egress_profiles_matters.filter(
        (x) =>
          x.egress_profile == this.egress_profile_id &&
          !this.wrapperMatterIds.includes(x.id)
      );
    },
    profileType() {
      if (!this.egressProfile) return null;
      return this.profile_types.find(
        (x) => x.id == this.egressProfile.egress_profile_type
      );
    },
    limitFormationAreas() {
      if (this.limit_study_plan == null) return null;
      else {
        const index = this.limit_study_plan.formation_areas.findIndex(
          (x) => x.id == this.formation_area_id
        );
        if (index != -1) return this.limit_study_plan.formation_areas[index];
        else return null;
      }
    },
    maxHoursAndCredits() {
      let max_hours = 0;
      let totalSCT = 0;
      const pedagogical_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == true)
        .map((x) => x.id);
      this.mattersBySemesterAndArea.forEach((matter) => {
        let matter_hours = [];
        if (matter.use_matter_values) {
          matter_hours = this.matter_time_allocations.filter(
            (x) =>
              x.matter == matter.matter &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
          matter_hours.forEach((hour) => {
            if (matter.regime == this.isAnual.id)
              max_hours += parseFloat(hour.hours) / 2;
            else max_hours += parseFloat(hour.hours);
          });
          const old_matter = this.matters.find((x) => x.id == matter.matter);
          if (old_matter) {
            if (matter.regime == this.isAnual.id)
              totalSCT += old_matter.credits / 2;
            else totalSCT += old_matter.credits;
          }
        } else {
          matter_hours = this.ep_matter_time_allocations.filter(
            (x) =>
              x.egress_profile_matter == matter.id &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
          matter_hours.forEach((hour) => {
            if (matter.regime == this.isAnual.id)
              max_hours += parseFloat(hour.hours) / 2;
            else max_hours += parseFloat(hour.hours);
          });
          if (matter.regime == this.isAnual.id) totalSCT += matter.credits / 2;
          else totalSCT += matter.credits;
        }
      });
      return {
        max_hours: Math.round(max_hours),
        max_credits: Math.round(totalSCT),
      };
    },
    mattersBySemesterAndArea() {
      if (this.formation_area_id != 0) {
        return this.matterList
          .filter(
            (x) =>
              (x.egress_profile == this.egress_profile_id &&
                x.plan_level == this.semester &&
                x.formation_area == this.formation_area_id) ||
              (x.egress_profile == this.egress_profile_id &&
                x.plan_level == this.semester - 1 &&
                this.isAnual &&
                x.regime == this.isAnual.id &&
                x.formation_area == this.formation_area_id)
          )
          .sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          });
      } else {
        return this.matterList
          .filter(
            (x) =>
              (x.egress_profile == this.egress_profile_id &&
                x.plan_level == this.semester &&
                x.formation_area == null) ||
              (x.egress_profile == this.egress_profile_id &&
                x.plan_level == this.semester - 1 &&
                this.isAnual &&
                x.regime == this.isAnual.id &&
                x.formation_area == null)
          )
          .sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          });
      }
    },
    mattersOfPreviousArea() {
      let count = 0;
      for (let i = 1; i < this.formation_area_id; i++) {
        count += this.matterList.filter(
          (x) => x.plan_level == this.semester && x.formation_area == i
        ).length;
      }
      if (this.formation_area_id == 0) {
        count =
          this.matterList.filter(
            (x) =>
              x.plan_level == this.semester &&
              x.egress_profile == this.egress_profile_id
          ).length - 1;
      }
      return count;
    },
    formationArea() {
      if (this.formation_area_id == 0) {
        return {
          name: String(
            "Sin " +
              this.$getVisibleNames(
                "mesh.formationarea",
                false,
                "Área De Formación"
              )
          ),
        };
      }
      return this.formationAreas.find((x) => x.id == this.formation_area_id);
    },
  },
  created() {},
};
</script>

<style scoped>
td {
  border: solid black !important;
  border-width: thin !important;
}
th {
  background-color: var(--kl-menu-color) !important;
  vertical-align: middle;
  text-align: left;
  padding: 0.2rem;
}
.table th {
  color: white !important;
  border: solid black !important;
  border-width: thin !important;
  font-size: var(--secondary-font-size) !important;
}
.table td {
  margin: 0;
  padding: 0;
}
.limit-class {
  color: red;
}
</style>