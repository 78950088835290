<template>
  <div>
    <div class="main-info">
      <div class="consultancy-content">
        <h3 class="info-title"><strong>Asesorías</strong></h3>
        <ul>
          <li>
            <strong>Innovación Curricular</strong>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;Asesoría y apoyo para la realización de
            ajustes o rediseños en innovación curricular.
          </li>
          <br />
          <li>
            <strong
              >Desarrollo de Competencias Genéricas o Transversales</strong
            >
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;Asesoría y apoyo para estructurar un
            proyecto de diseño e implementación de Competencia Genéricas o
            Transversales.
          </li>
          <br />
          <li>
            <strong
              >Desarrollo Mecanismos de Aseguramiento de la Calidad</strong
            >
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;Asesoría y apoyo para definir, construir e
            implementar Mecanismos que permitan ejecutar y aplicar las
            innovaciones curriculares.
          </li>
        </ul>
      </div>
      <div class="consultancy-picture">
        <img
          class="mt-1 img-coaching"
          src="@/assets/business-coach-017-1024x682.jpg"
          alt=""
        />
      </div>
    </div>
    <h3 class="text-center my-5">
      TODO LO ANTERIOR EN BASE A LOS PROPIOS MODELOS EDUCATIVOS DE LAS IES
    </h3>
  </div>
</template>

<script>
export default {
  name: "Consultancy",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.consultancy-content {
  width: 60%;
  font-size: 17px;
  line-height: 26px;
  text-align: justify;
  color: #666;
  margin-right: 2%;
}
.consultancy-picture {
  width: 40%;
}
.img-coaching {
  max-width: 500px;
}
@media (max-width: 1025px) {
  .consultancy-content {
    width: 100%;
  }
}
</style>