<template>
  <div>
    <div class="mx-2">
      <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_studyenvironment'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-study-environment-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "mesh.studyenvironment",
                false,
                "Ambiente de Aprendizaje"
              )
            }}
          </button>
        </div>
      </div>
      <h3>
        {{
          $getVisibleNames(
            "mesh.studyenvironment",
            true,
            "Ambientes de Aprendizajes"
          )
        }}
      </h3>
      <GenericBTable
        class="study-environment-table"
        :items="study_environments"
        :pagination="10"
        :fields="studyEnvironmentsFields"
        :filterCustom="filterCustom"
        @emitChangeSearchField="changeInputSearch"
        :filter="input_search"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(time_allocation)="row">
          <div class="time-allocation-container">
            <div
              v-for="item in getTimeAllocation(row.item.id)"
              :key="item.id"
              class="time-allocation-div"
            >
              {{ item.name }}
            </div>
          </div>
        </template>
        <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            @click="
              $bvModal.show(`edit-study-environment-modal-${row.item.id}`)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.studyenvironment',
                false,
                'Ambiente de Aprendizaje'
              )}`
            "
          ></button-edit>
          <button-delete
            @click="askForDeleteStudyEnvironment(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'mesh.studyenvironment',
                false,
                'Ambiente de Aprendizaje'
              )}`
            "
          ></button-delete>
          <b-modal
            :id="`edit-study-environment-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.studyenvironment',
              false,
              'Ambiente de Aprendizaje'
            )}`"
            size="md"
            hide-footer
          >
            <StudyEnvironmentForm
              :StudyEnvironment="row.item"
              @updated="slotUpdatedStudyEnvironment"
              :show_title="false"
            ></StudyEnvironmentForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-study-environment-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.studyenvironment',
        false,
        'Ambiente de Aprendizaje'
      )}`"
      size="md"
    >
      <StudyEnvironmentForm
        @created="slotCreatedStudyEnvironment"
        :show_title="false"
      ></StudyEnvironmentForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "StudyEnvironmentView",
  components: {
    StudyEnvironmentForm: () =>
      import(
        "@/components/mesh/CurricularResources/StudyEnvironment/StudyEnvironmentForm"
      ),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {},
  data() {
    return {
      input_search: "",
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      studyEnvironmentsTimeAllocation: names.STUDY_ENVIRONMENTS_TIME_ALLOCATION,
      time_allocations: names.TIME_ALLOCATIONS,
      study_environments: names.STUDY_ENVIRONMENTS,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_studyenvironment");
      if (has_permission) return has_permission;
      else return false;
    },
    breadcrumb_items() {
      return [
        { text: "Definiciones Curriculares", active: true },
        {
          text: this.$getVisibleNames(
            "mesh.studyenvironment",
            true,
            "Ambientes de Aprendizajes"
          ),
          active: true,
        },
      ];
    },
    study_environments_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.studyenvironment",
              true,
              "Ambientes de Aprendizajes"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "time_allocation",
          label: this.$getVisibleNames(
            "mesh.timeallocation",
            true,
            "División de tiempo"
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
    studyEnvironmentsFields() {
      if (!this.allows_crud)
        return this.study_environments_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.study_environments_fields;
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(row.description, this.input_search) ||
        this.time_allocations.filter(
          (x) =>
            this.$filtered(x.name, this.input_search) &&
            this.studyEnvironmentsTimeAllocation.find(
              (sETA) =>
                sETA.time_allocation == x.id && sETA.study_environment == row.id
            )
        ).length > 0
      ) {
        return true;
      } else return false;
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    getTimeAllocation(study_environment_id) {
      const time_allocation_ids = [
        ...new Set(
          this.studyEnvironmentsTimeAllocation
            .filter((x) => x.study_environment == study_environment_id)
            .map((x) => x.time_allocation)
        ),
      ];
      return this.time_allocations.filter((x) =>
        time_allocation_ids.includes(x.id)
      );
    },
    askForDeleteStudyEnvironment(study_environment_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.studyenvironment",
          false,
          "Ambiente de Aprendizaje"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_STUDY_ENVIRONMENT, study_environment_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.studyenvironment",
                    false,
                    "Ambiente de Aprendizaje"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    slotCreatedStudyEnvironment() {
      this.$bvModal.hide("new-study-environment-modal");
    },
    slotUpdatedStudyEnvironment(study_environment) {
      this.$bvModal.hide(
        `edit-study-environment-modal-${study_environment.id}`
      );
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS_TIME_ALLOCATION);
      this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS);
      this.$store.dispatch(names.FETCH_TIME_ALLOCATIONS);
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped>
.td-actions {
  max-width: 100px !important;
}
.title-header {
  padding: 0 1rem;
  margin-top: auto;
}
.search-study-environment {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
.time-allocation-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.time-allocation-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
</style>