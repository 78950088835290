<template>
  <div>
    <div class="mx-2">
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_coursewrapper'"
            v-if="allows_crud && matrix2_view"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-course-wrapper-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "mesh.coursewrapper",
                false,
                "Envoltorio de Asignaturas"
              )
            }}
          </button>
        </div>
      </div>
      <div class="d-flex" v-if="!matrix2_view">
        <h3 class="ml-4">
          {{
            $getVisibleNames(
              "mesh.coursewrapper",
              true,
              "Envoltorios de Asignaturas"
            )
          }}
        </h3>
        <div class="ml-3">
          <template v-if="selected.length > 0">
            <b-button
              class="mr-1"
              size="sm"
              @click="TemporalRemoveCoursesWrapper()"
              >Remover
              {{
                $getVisibleNames(
                  "mesh.coursewrapper",
                  true,
                  "Envoltorios de Asignaturas"
                )
              }}
              Seleccionadas
              <template v-if="copy_egress_profile_view"> de la copia </template>
            </b-button>
          </template>
          <template v-else>
            <b-button class="mr-1" size="sm" disabled
              >Remover
              {{
                $getVisibleNames(
                  "mesh.coursewrapper",
                  true,
                  "Envoltorios de Asignaturas"
                )
              }}
              Seleccionadas
              <template v-if="copy_egress_profile_view">
                de la copia
              </template></b-button
            >
          </template>
        </div>
        <div class="ml-3" v-if="!copy_egress_profile_view">
          <template v-if="selected.length > 0">
            <b-button
              class="mr-1"
              size="sm"
              @click="TemporalChangedCoursesWrapper()"
              >Reasignar el nivel "{{ semester_amount }}" a
              {{
                $getVisibleNames(
                  "mesh.coursewrapper",
                  true,
                  "Envoltorios de Asignaturas"
                )
              }}
              Seleccionadas</b-button
            >
          </template>
          <template v-else>
            <b-button class="mr-1" size="sm" disabled
              >Reasignar el nivel "{{ semester_amount }}" a
              {{
                $getVisibleNames(
                  "mesh.coursewrapper",
                  true,
                  "Envoltorios de Asignaturas"
                )
              }}
              Seleccionadas</b-button
            >
          </template>
        </div>
      </div>
      <GenericBTable
        :items="coursesWrapperList"
        :pagination="courses_wrapper.length"
        :fields="courses_wrapper_fields"
        :filter="input_search"
        :filterCustom="filterCustom"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="this.matrix2_view ? true : false"
        :allows_crud="allows_crud"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @emitChangeSearchField="changeInputSearch"
      >
        <template #head(selected)>
          <b-form-checkbox
            v-model="select_all"
            @change="selectAllCourseWrapper()"
          ></b-form-checkbox>
        </template>
        <template #cell(selected)="row">
          <b-checkbox
            v-model="row.item.selected"
            @change="selectCourseWrapper(row.item.id, $event)"
          ></b-checkbox>
        </template>
        <template v-if="!matrix2_view" #cell(init_level)="row">
          <b-form-input
            style="width: auto"
            type="number"
            min="1"
            v-model="row.item.init_level"
            @update="updateInput()"
            :state="
              row.item.init_level <= semester_amount &&
              row.item.init_level >= 1 &&
              row.item.end_level >= row.item.init_level
            "
            v-b-tooltip.v-danger.noninteractive="
              `${
                row.item.init_level <= semester_amount &&
                row.item.init_level >= 1
                  ? ''
                  : 'El nivel inicial no debe sobrepasa al del ' +
                    $getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    ) +
                    '.'
              }${
                row.item.end_level < row.item.init_level
                  ? 'Nivel inicial no debe ser superior al nivel final'
                  : ''
              }`
            "
            size="sm"
          ></b-form-input>
        </template>
        <template v-if="!matrix2_view" #cell(end_level)="row">
          <b-form-input
            style="width: auto"
            type="number"
            min="1"
            v-model="row.item.end_level"
            @update="updateInput()"
            :state="
              row.item.end_level <= semester_amount &&
              row.item.end_level >= 1 &&
              row.item.end_level >= row.item.init_level
            "
            v-b-tooltip.v-danger.noninteractive="
              `${
                row.item.end_level <= semester_amount && row.item.end_level >= 1
                  ? ''
                  : 'El nivel inicial no debe sobrepasa al del ' +
                    $getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    ) +
                    '.'
              }${
                row.item.end_level < row.item.init_level
                  ? 'Nivel inicial no debe ser superior al nivel final'
                  : ''
              }`
            "
            size="sm"
          ></b-form-input>
        </template>
        <template #cell(color)="row">
          <div
            class="course-wrapper-color"
            :style="{
              'background-color': row.item.color,
              color: row.item.color,
            }"
          ></div>
        </template>
        <template #cell(egress_profile_matters)="row">
          <div class="campus-container">
            <div
              v-for="item in getMatterName(row.item.egress_profile_matters)"
              :key="item.id"
              class="campus-div"
            >
              {{ item.name }}
            </div>
          </div>
        </template>
        <template #cell(actions)="row">
          <div class="d-flex">
            <b-button
              size="sm"
              variant="none"
              class="p-1"
              @click="routerPush(row.item.id)"
              ><b-icon icon="eye" scale="1"></b-icon
            ></b-button>
            <template v-if="allows_crud">
              <button-edit
                v-can="'mesh.change_coursewrapper'"
                @click="
                  $bvModal.show(`edit-course-wrapper-modal-${row.item.id}`)
                "
                v-b-tooltip.v-secondary.noninteractive="
                  `Editar ${$getVisibleNames(
                    'mesh.coursewrapper',
                    false,
                    'Envoltorio de Asignaturas'
                  )}`
                "
              ></button-edit>
              <button-delete
                v-can="'mesh.delete_coursewrapper'"
                @click="askForDeleteCourseWrapper(row.item.id)"
                v-b-tooltip.v-secondary.noninteractive="
                  `Eliminar ${$getVisibleNames(
                    'mesh.coursewrapper',
                    false,
                    'Envoltorio de Asignaturas'
                  )}`
                "
              ></button-delete>
            </template>
          </div>
          <b-modal
            :id="`edit-course-wrapper-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.coursewrapper',
              false,
              'Envoltorio de Asignaturas'
            )}`"
            size="lg"
            hide-footer
          >
            <CoursesWrapperForm
              :CourseWrapper="row.item"
              :egress_profile_id="egress_profile_id"
              @updated="slotUpdatedCourseWrapper"
            ></CoursesWrapperForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <template v-if="!matrix2_view">
      <div class="d-block">
        <template v-if="selected.length > 0">
          <div class="d-block ml-2">
            <h4>
              {{
                $getVisibleNames(
                  "mesh.coursewrapper",
                  true,
                  "Envoltorios de Asignaturas"
                )
              }}
              Seleccionadas
            </h4>
            <div class="matters-container">
              <div
                v-for="item in coursesWrapperList.filter((x) =>
                  selected.includes(x.id)
                )"
                :key="item.id"
                class="matters-div"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </template>
        <template v-if="remove_courses_wrapper.length > 0">
          <div class="d-block ml-2 mb-3">
            <h5>
              <template v-if="copy_egress_profile_view">Listado de </template>
              {{
                $getVisibleNames(
                  "mesh.coursewrapper",
                  true,
                  "Envoltorios de Asignaturas"
                )
              }}
              <template v-if="!copy_egress_profile_view"> a eliminar </template>
              <template v-else> que no se copiaran:</template>
            </h5>
            <div class="matters-container">
              <div
                v-for="item in remove_courses_wrapper"
                :key="item.id"
                class="matters-div deleted-matter"
              >
                {{ item.name }}
                <b-button
                  variant="none"
                  size="sm"
                  class="m-0 p-0"
                  @click="reintegrateCoursesWrapper(item)"
                >
                  <b-icon
                    icon="x-square"
                    scale="0.9"
                    variant="white"
                    v-b-tooltip.v-secondary.noninteractive="
                      `Integrar ${$getVisibleNames(
                        'mesh.coursewrapper',
                        false,
                        'Envoltorio de Asignaturas'
                      )}`
                    "
                  ></b-icon>
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- <div class="mb-4">
        <h4 v-if="selected.length > 0">
          {{
            $getVisibleNames(
              "mesh.coursewrapper",
              true,
              "Envoltorios de Asignaturas"
            )
          }}
          Seleccionadas
        </h4>
        <div class="matters-container">
          <div
            v-for="item in coursesWrapperList.filter((x) =>
              selected.includes(x.id)
            )"
            :key="item.id"
            class="matters-div"
          >
            {{ item.name }}
          </div>
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col" style="text-align: right">
          <template v-if="selected.length > 0">
            <b-button class="mr-1" size="sm" @click="removeCourseWrapper()"
              >Remover
              {{
                $getVisibleNames(
                  "mesh.coursewrapper",
                  true,
                  "Envoltorios de Asignaturas"
                )
              }}</b-button
            >
          </template>
          <template v-else>
            <b-button class="mr-1" size="sm" disabled
              >Remover
              {{
                $getVisibleNames(
                  "mesh.coursewrapper",
                  true,
                  "Envoltorios de Asignaturas"
                )
              }}</b-button
            >
          </template>
        </div>
      </div> -->
    </template>
    <b-modal
      :id="`new-course-wrapper-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.coursewrapper',
        false,
        'Envoltorio de Asignaturas'
      )}`"
      size="lg"
    >
      <CoursesWrapperForm
        @created="slotCreatedCourseWrapper"
        :egress_profile_id="egress_profile_id"
      ></CoursesWrapperForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "CourseWrapperView",
  components: {
    CoursesWrapperForm: () =>
      import("@/components/mesh/Matrix2/CoursesWrapper/CoursesWrapperForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    matrix2_view: {
      type: Boolean,
      default: true,
    },
    copy_egress_profile_view: {
      type: Boolean,
      default: false,
    },
    semester_amount: {
      default: 1,
    },
  },
  data() {
    return {
      input_search: "",
      sortBy: "",
      sortDesc: false,
      select_all: null,
      selected: [],
      list_courses_wrapper: [],
      remove_courses_wrapper: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      courses_wrapper: names.COURSES_WRAPPER,
      matters: names.MATTERS,
      formationAreas: names.FORMATION_AREAS,
      formationLines: names.FORMATION_LINES,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
    }),
    coursesWrapperList() {
      if (this.matrix2_view) {
        let list = [
          ...this.courses_wrapper.filter(
            (x) => x.egress_profile == this.egress_profile_id
          ),
        ];
        return list;
      } else {
        let list = this.list_courses_wrapper;
        return list.sort(function (a, b) {
          if (a.init_level > b.init_level) return 1;
          if (a.init_level < b.init_level) return -1;
          if (a.end_level > b.end_level) return 1;
          if (a.end_level < b.end_level) return -1;
          return 0;
        });
      }
    },
    // allows_crud() {
    //   const has_permission = this.$hasPermissions("mesh.add_coursewrapper");
    //   if (has_permission) return has_permission;
    //   else return false;
    // },
    courses_wrapper_fields() {
      return [
        !this.matrix2_view
          ? {
              key: "selected",
              label: "Selecionar",
              display_column: !this.matrix2_view ? true : false,
              thStyle: { width: "1%" },
            }
          : { key: "", label: "", display_column: false },
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.coursewrapper",
              true,
              "Envoltorios de Asignaturas"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-left",
          display_column: this.matrix2_view ? true : false,
        },
        {
          key: "init_level",
          label: "Nivel Inicial",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "end_level",
          label: "Nivel Final",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-center",
          display_column: true,
        },
        {
          key: "formation_area",
          label: this.$getVisibleNames(
            "mesh.formationarea",
            false,
            "Área De Formación"
          ),
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          display_column: this.matrix2_view ? true : false,
          tdClass: "text-center",
          formatter: (value) => {
            return this.getFormationArea(value);
          },
        },
        {
          key: "formation_line",
          label: this.$getVisibleNames(
            "mesh.formationline",
            false,
            "Línea De Formación"
          ),
          sortable: false,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
          display_column: this.matrix2_view ? true : false,
          formatter: (value) => {
            return this.getFormationLine(value);
          },
        },
        {
          key: "credits",

          label: this.$getVisibleNames(
            "manual.credits",
            true,
            "Créditos SCT Totales"
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-center",
          display_column: this.matrix2_view ? true : false,
        },
        // {
        //   key: "color",
        //   label: "Color",
        //   sortable: false,
        //   thStyle: { background: "var(--primary-color) !important" },
        //   display_column: true,
        // },
        {
          key: "egress_profile_matters",
          label: String(
            this.$getVisibleNames(
              "mesh.egressprofilematter",
              true,
              "Asignaturas"
            )
          ),
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "text-center align-middle",
          tdClass: "text-left",
          display_column: this.matrix2_view ? true : false,
        },
        {
          key: "actions",
          label: "",
          display_column: this.matrix2_view ? true : false,
          thStyle: { background: "var(--primary-color) !important" },
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    updateInput() {
      this.$emit(
        "list_courses_wrapper",
        this.list_courses_wrapper,
        this.remove_courses_wrapper
      );
    },
    TemporalRemoveCoursesWrapper() {
      this.list_courses_wrapper
        .filter((x) => this.selected.includes(x.id))
        .forEach((x) => {
          x.selected = false;
          this.remove_courses_wrapper.push(x);
          const index = this.list_courses_wrapper.findIndex(
            (a) => a.id == x.id
          );
          if (index != -1) this.list_courses_wrapper.splice(index, 1);
        });
      this.selected = [];
      this.select_all = false;
      this.$emit(
        "list_courses_wrapper",
        this.list_courses_wrapper,
        this.remove_courses_wrapper
      );
    },
    TemporalChangedCoursesWrapper() {
      this.list_courses_wrapper
        .filter((x) => this.selected.includes(x.id))
        .forEach((x) => {
          x.init_level = this.semester_amount;
          x.end_level = this.semester_amount;
          x.selected = false;
        });
      this.selected = [];
      this.select_all = false;
      this.$emit(
        "list_courses_wrapper",
        this.list_courses_wrapper,
        this.remove_courses_wrapper
      );
    },
    reintegrateCoursesWrapper(matter) {
      const index = this.remove_courses_wrapper.findIndex(
        (x) => x.id == matter.id
      );
      if (index != -1) {
        this.remove_courses_wrapper.splice(index, 1);
        this.list_courses_wrapper.push(matter);
      }
      this.$emit(
        "list_courses_wrapper",
        this.list_courses_wrapper,
        this.remove_courses_wrapper
      );
      this.select_all = false;
    },
    selectCourseWrapper(matter_id) {
      const index = this.selected.findIndex((x) => x == matter_id);
      if (index != -1) this.selected.splice(index, 1);
      else this.selected.push(matter_id);
    },
    selectAllCourseWrapper() {
      let list = [];
      if (!this.select_all) {
        this.coursesWrapperList.forEach((x) => {
          x.selected = false;
        });
        this.selected = [];
      } else {
        this.coursesWrapperList.forEach((x) => {
          list.push(x.id);
          x.selected = true;
        });
        this.selected = [...new Set(list)];
      }
    },
    // removeCourseWrapper() {
    //   this.$swal({
    //     title: `¿Desea remover las ${this.$getVisibleNames(
    //       "mesh.coursewrapper",
    //       true,
    //       "Envoltorios de Asignaturas"
    //     )} seleccionadas?`,
    //     width: "auto",
    //     text: `Esta acción no se podrá revertir y se desvinculará los objetos seleccionados de este ${this.$getVisibleNames(
    //       "mesh.egressprofile",
    //       false,
    //       "Perfil de Egreso"
    //     )}. `,
    //     type: "warning",
    //     showCancelButton: true,
    //   }).then((result) => {
    //     if (result.value) {
    //       this.removeCoursesWrapper(this.selected);
    //     }
    //   });
    // },
    // async removeCoursesWrapper(course_wrapper) {
    //   for await (let wrapper_id of course_wrapper) {
    //     await this.$store.dispatch(names.DELETE_COURSE_WRAPPER, wrapper_id);
    //   }
    //   this.selected = [];
    //   this.selected_all = false;
    // },
    routerPush(courses_wrapper_id) {
      this.$router.push({
        name: "Matrix2View",
        params: {
          egress_profile_id: this.egress_profile_id,
          courses_wrapper_id: courses_wrapper_id,
        },
      });
      this.$emit("closeModal");
    },
    filterCustom(row) {
      if (
        this.$filtered(String(row.name), this.input_search) ||
        this.$filtered(String(row.description), this.input_search) ||
        this.$filtered(String(row.init_level), this.input_search) ||
        this.$filtered(String(row.end_level), this.input_search) ||
        this.$filtered(String(row.credits), this.input_search) ||
        this.$filtered(
          String(this.getFormationArea(row.formation_area)),
          this.input_search
        ) ||
        this.$filtered(
          String(this.getFormationLine(row.formation_line)),
          this.input_search
        ) ||
        this.egress_profile_matters.filter(
          (x) =>
            this.matters.find(
              (matter) =>
                this.$filtered(matter.name, this.input_search) &&
                x.matter == matter.id
            ) && row.egress_profile_matters.includes(x.id)
        ).length > 0
      ) {
        return true;
      } else {
        false;
      }
    },
    getFormationArea(formation_area_id) {
      if (!this.formationAreas) return "N/A";
      const formation_area = this.formationAreas.find(
        (x) => x.id == formation_area_id
      );
      return formation_area ? formation_area.name : "N/A";
    },
    getFormationLine(formation_line_id) {
      if (!this.formationLines) return "N/A";
      const formation_line = this.formationLines.find(
        (x) => x.id == formation_line_id
      );
      return formation_line ? formation_line.name : "N/A";
    },
    getMatterName(egress_profile_matters) {
      let matters = this.egress_profile_matters
        .filter(
          (x) =>
            egress_profile_matters.includes(x.id) &&
            x.egress_profile == this.egress_profile_id
        )
        .map((x) => x.matter);
      matters = [...new Set(matters)];
      if (matters.length > 0) {
        const list = this.matters.filter((element) =>
          matters.includes(element.id)
        );
        return list.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
      } else return [];
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    askForDeleteCourseWrapper(course_wrapper_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.coursewrapper",
          false,
          "Envoltorio de Asignaturas"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_COURSE_WRAPPER, course_wrapper_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.coursewrapper",
                    false,
                    "Envoltorio de Asignaturas"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    slotCreatedCourseWrapper() {
      this.$bvModal.hide("new-course-wrapper-modal");
    },
    slotUpdatedCourseWrapper(course_wrapper) {
      this.$bvModal.hide(`edit-course-wrapper-modal-${course_wrapper.id}`);
    },
  },
  watch: {
    selected() {
      if (
        this.selected.length == this.coursesWrapperList.length &&
        this.coursesWrapperList.length > 0
      ) {
        this.select_all = true;
      } else if (
        this.selected.length != this.coursesWrapperList.length &&
        this.coursesWrapperList.length > 0
      )
        this.select_all = false;
    },
  },
  mounted() {
    this.select_all = false;
    this.selected = [];
    this.list_courses_wrapper = [];
    if (!this.matrix2_view) {
      if (!this.copy_egress_profile_view) {
        this.list_courses_wrapper = this.courses_wrapper
          .filter(
            (x) =>
              x.egress_profile == this.egress_profile_id &&
              x.init_level > this.semester_amount
          )
          .map((objeto) => ({ ...objeto }));
      } else {
        this.list_courses_wrapper = this.courses_wrapper
          .filter((x) => x.egress_profile == this.egress_profile_id)
          .map((objeto) => ({ ...objeto }));
      }
      this.$emit(
        "list_courses_wrapper",
        this.list_courses_wrapper,
        this.remove_courses_wrapper
      );
    }
  },
  created() {
    if (this.formationAreas.length == 0) {
      this.$store.dispatch(names.FETCH_FORMATION_AREAS);
    }
    // this.$store.dispatch(names.FETCH_COURSES_WRAPPER, {
    //   egress_profile_id: this.egress_profile_id,
    // });
  },
};
</script>
<style scoped>
.campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}
.course-wrapper-color::before {
  min-width: 100px !important;
  height: 20px !important;
  opacity: 1;
  content: " #";
}
.matters-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.matters-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.deleted-matter {
  background-color: red !important;
}
</style>