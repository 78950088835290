import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    regimes: [],
    regimes_url: '/mesh/matter-regime'
}

const getters = {
    [names.REGIMES]: state => {
        if (!get.store.state.user) return [];
        return state.regimes.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_REGIMES]: (state, regimes) => {
        regimes.forEach(element => {
            let index = state.regimes.findIndex(x => x.id == element.id);
            if (index != -1) state.regimes.splice(index, 1, element);
            else state.regimes.push(element);
        });
    },
    [names.MUTATE_REGIME]: (state, regime) => {
        let index = state.regimes.findIndex(x => x.id == regime.id);
        if (index != -1) state.regimes.splice(index, 1, regime);
        else state.regimes.push(regime);
    },
    [names.MUTATE_DELETE_REGIME]: (state, regime_id) => {
        let index = state.regimes.findIndex(x => x.id == regime_id);
        if (index != -1) state.regimes.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_REGIME]: ({
        commit,
        state
    }, regime_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.regimes_url}/${regime_id}/`)
                .then(response => {
                    commit(names.MUTATE_REGIME, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_REGIMES]: ({
        commit,
        state,
    }, force) => {
        return new Promise((resolve) => {
            if (!force && state.regimes.length > 0) {
                resolve(state.regimes);
                return;
            }
            restful.Get(`${state.regimes_url}/`)
                .then(response => {
                    commit(names.MUTATE_REGIMES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_REGIME]: ({
        commit,
        state
    }, regime) => {
        return new Promise((resolve) => {
            restful.Post(`${state.regimes_url}/`, regime)
                .then(response => {
                    commit(names.MUTATE_REGIME, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_REGIME]: ({
        commit,
        state
    }, regime) => {
        return new Promise((resolve) => {
            restful.Put(`${state.regimes_url}/${regime.id}/`, regime)
                .then(response => {
                    commit(names.MUTATE_REGIME, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_REGIME]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.regimes_url}/${payload.regime_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_REGIME, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_REGIME]: ({
        commit,
        state
    }, regime_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.regimes_url}/${regime_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_REGIME, regime_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};