var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('b-breadcrumb',{staticClass:"breadcrumb pb-2 pt-1 noprint",attrs:{"items":_vm.breadcrumb_items}}),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_modality'),expression:"'mesh.add_modality'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-modality-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames( "mesh.modality", false, "Modalidad de Asignaturas" ))+" ")]):_vm._e()])]),_c('h3',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.modality", false, "Modalidad de Asignaturas"))+" ")]),(_vm.searchModality.length > 0)?_c('GenericBTable',{attrs:{"items":_vm.modalities,"pagination":_vm.modalities.length,"fields":_vm.modalitiesFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Editar ${_vm.$getVisibleNames(
              'mesh.modality',
              false,
              'Modalidad de Asignaturas'
            )}`
          ),expression:"\n            `Editar ${$getVisibleNames(\n              'mesh.modality',\n              false,\n              'Modalidad de Asignaturas'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-modality-modal-${row.item.id}`)}}}),(
            _vm.institution &&
            _vm.institution.id == 12 &&
            (row.item.id == 33 || row.item.id == 42)
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
            `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`
          ),expression:"\n            `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`\n          ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}]},[_c('button-delete',{attrs:{"disabled":""}})],1):_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Eliminar ${_vm.$getVisibleNames(
              'mesh.modality',
              false,
              'Modalidad de Asignaturas'
            )}`
          ),expression:"\n            `Eliminar ${$getVisibleNames(\n              'mesh.modality',\n              false,\n              'Modalidad de Asignaturas'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteModality(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-modality-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.modality',
            false,
            'Modalidad de Asignaturas'
          )}`,"size":"md","hide-footer":""}},[_c('ModalityForm',{attrs:{"Modality":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedModality}})],1)]}}:null],null,true)},[void 0],2):_c('div',[_c('br'),_c('strong',[_vm._v("No se a encontrado ninguna "+_vm._s(_vm.$getVisibleNames( "mesh.modality", false, "Modalidad de Asignaturas" ))+".")])])],1),_c('b-modal',{attrs:{"id":`new-modality-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.modality',
      false,
      'Modalidad de Asignaturas'
    )}`,"size":"md"}},[_c('ModalityForm',{attrs:{"institution_id":this.user.school},on:{"created":_vm.slotCreatedModality}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }