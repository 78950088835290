<template>
  <div>
    <!-- ************************************** Paso 1 ******************************************* -->
    <section v-if="step == 1">
      <div class="step-grid mb-5">
        <div class="step-icon-border mr-5">
          <b-icon-list-ul id="step1" class="step-icon"></b-icon-list-ul>
          <p class="step-caption">
            Paso 1: Detalles de la
            {{ $getVisibleNames("teaching.section", false, "Sección") }}
          </p>
        </div>
        <div class="step-icon-border mx-5">
          <b-icon-people-fill class="step-icon"></b-icon-people-fill>
          <p class="step-caption">Paso 2: Asignar Estudiante(s)</p>
        </div>
        <div class="step-icon-border ml-5">
          <b-icon-person-check-fill
            class="step-icon"
          ></b-icon-person-check-fill>
          <p class="step-caption">Paso 3: Asignar Profesor(es)</p>
        </div>
      </div>
      <span class="progress first-load"></span>
      <div class="container-group">
        <div class="group">
          <b-form-group
            label="Nombre"
            label-for="input-name"
            label-cols="4"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-input
              id="input-name"
              v-model="$v.section.name.$model"
              :state="validateState('name')"
              :disabled="!allows_crud"
              aria-describedby="input-name-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <!-- ******************************** SE COMENTA SELECTOR COLOR EN SECCIONES ************************************** -->
        <!-- <div class="group">
        <b-form-group
          label="Color"
          label-for="input-color"
          label-cols="4"
          label-cols-sm="3"
          class="m-0"
        >
          <b-form-input
            id="input-color"
            v-model="$v.section.color.$model"
            :state="validateState('color')"
            :disabled="!allows_crud"
            aria-describedby="input-color-feedback"
            size="sm"
            type="color"
          ></b-form-input>
          <b-form-invalid-feedback id="input-color-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
      </div> -->
        <!-- <div class="group">
          <b-form-group
            v-if="egressProfile"
            label="Nivel"
            label-for="select-level"
            label-cols="4"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-select
              id="select-level"
              :options="levels"
              v-model="$v.section.plan_level.$model"
              :state="validateState('plan_level')"
              :disabled="!allows_crud || !edit_level"
              aria-describedby="select-level-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-level-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div> -->
        <div v-if="egressProfile" class="group">
          <b-form-group
            :label="
              $getVisibleNames('mesh.egressprofilematter', false, 'Asignatura')
            "
            label-for="select-level"
            label-cols="4"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-select
              id="select-level"
              :options="matterList"
              text-field="name"
              value-field="id"
              v-model="$v.section.egress_profile_matter.$model"
              :state="validateState('egress_profile_matter')"
              :disabled="!allows_crud || !edit_matter"
              aria-describedby="select-level-feedback"
              size="sm"
              class="ml-1"
            ></b-form-select>
            <b-form-invalid-feedback id="select-level-feedback">
              <span v-if="section.egress_profile_matter == null">
                Este campo es obligatorio.
              </span>
              <span v-else>
                No se puede {{ isNaN(section.id) ? "crear" : "editar" }} una
                {{ $getVisibleNames("teaching.section", false, "Sección") }} en
                una
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    false,
                    "Asignatura"
                  )
                }}
                que no se encuentre cerrada.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="group">
          <b-form-group
            :label="$getVisibleNames('mesh.period', false, 'Período')"
            label-for="select-period"
            label-cols="4"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-select
              id="select-period"
              :options="periodList"
              value-field="id"
              text-field="label"
              v-model="$v.section.period.$model"
              :state="validateState('period')"
              :disabled="!allows_crud || !edit_period"
              aria-describedby="select-period-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-period-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="group">
          <b-form-group
            :label="$getVisibleNames('mesh.shift', false, 'Jornada')"
            label-for="select-shift"
            label-cols="4"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-select
              id="select-shift"
              :options="shifts"
              value-field="id"
              text-field="name"
              v-model="$v.section.shift.$model"
              :state="validateState('shift')"
              :disabled="!allows_crud || !edit_shift"
              aria-describedby="select-shift-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-shift-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="group">
          <b-form-group
            :label="$getVisibleNames('mesh.campus', false, 'Sede')"
            label-for="select-campus"
            label-cols="4"
            label-cols-sm="3"
            class="m-0"
          >
            <b-form-select
              id="select-campus"
              :options="filteredCampuses"
              value-field="id"
              text-field="name"
              v-model="$v.section.campus.$model"
              :state="validateState('campus')"
              :disabled="!allows_crud || !edit_campus"
              aria-describedby="select-campus-feedback"
              size="sm"
              class="ml-1"
            ></b-form-select>
            <b-form-invalid-feedback id="select-campus-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
      </div>
    </section>
    <!-- ************************************** Paso 2 ******************************************* -->
    <div class="hidden-step" :class="{ 'is-shown': this.step == 2 }">
      <div class="step-grid mb-5">
        <div class="step-icon-border mr-5 done">
          <b-icon-list-ul
            id="step1"
            class="step-icon"
            variant="success"
          ></b-icon-list-ul>
          <p class="step-caption text-success">
            Paso 1: Detalles de la
            {{ $getVisibleNames("teaching.section", false, "Sección") }}
            <b-icon-check2-circle variant="success"></b-icon-check2-circle>
          </p>
        </div>
        <div class="step-icon-border mx-5">
          <b-icon-people-fill class="step-icon"></b-icon-people-fill>
          <p class="step-caption">Paso 2: Asignar Estudiante(s)</p>
        </div>
        <div class="step-icon-border ml-5">
          <b-icon-person-check-fill
            class="step-icon"
          ></b-icon-person-check-fill>
          <p class="step-caption">Paso 3: Asignar Profesor(es)</p>
        </div>
      </div>
      <div class="progress second-load"></div>
      <h5>Estudiantes</h5>
      <UserList
        :section_id="section.id"
        :filter_by_professor="false"
        :filter_by_student="true"
        :allows_crud="allows_crud"
        @changed="slotStudentsChanged"
      ></UserList>
    </div>
    <!-- ************************************** Paso 3 ******************************************* -->
    <div class="hidden-step" :class="{ 'is-shown': this.step == 3 }">
      <div class="step-grid mb-5">
        <div class="step-icon-border mr-5 done">
          <b-icon-list-ul
            id="step1"
            class="step-icon"
            variant="success"
          ></b-icon-list-ul>
          <p class="step-caption text-success">
            Paso 1: Detalles de la
            {{ $getVisibleNames("teaching.section", false, "Sección") }}
            <b-icon-check2-circle variant="success"></b-icon-check2-circle>
          </p>
        </div>
        <div class="step-icon-border mx-5 done">
          <b-icon-people-fill
            class="step-icon"
            variant="success"
          ></b-icon-people-fill>
          <p class="step-caption text-success">
            Paso 2: Asignar Estudiante(s)
            <b-icon-check2-circle variant="success"></b-icon-check2-circle>
          </p>
        </div>
        <div class="step-icon-border ml-5" :class="[{ done: saved }]">
          <b-icon-person-check-fill
            class="step-icon"
            :style="[
              saved
                ? { color: '#28a745' }
                : { color: 'var(--secondary-color)' },
            ]"
          ></b-icon-person-check-fill>
          <p class="step-caption" :class="[{ 'text-success': saved }]">
            Paso 3: Asignar Profesor(es)<b-icon-check2-circle
              :style="[saved ? { display: 'inline' } : { display: 'none' }]"
              variant="success"
            ></b-icon-check2-circle>
          </p>
        </div>
      </div>
      <span class="progress third-load" :class="[{ fill: saved }]"></span>
      <h5>Profesores</h5>
      <UserList
        :section_id="section.id"
        :filter_by_professor="true"
        :filter_by_student="false"
        :allows_crud="allows_crud"
        @changed="slotProfessorsChanged"
      ></UserList>
    </div>
    <div class="mb-4" style="text-align: right">
      <b-button v-if="step != 1" class="mr-1" @click.prevent="prevStep">
        Paso anterior
      </b-button>
      <b-button v-if="step != total_steps" @click.prevent="nextStep">
        Siguiente paso
      </b-button>
      <b-button
        class="mr-1"
        @click="save"
        v-can="'teaching.add_section'"
        v-if="step == 3 && allows_crud"
        >Guardar</b-button
      >
    </div>
    <div class="row" v-if="allows_crud">
      <div
        v-if="show_delete_button && !isNaN(section.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          style="margin-top: -9%"
          size="sm"
          variant="danger"
          v-can="'teaching.delete_section'"
          @click="askForDelete"
          >Eliminar</b-button
        >
      </div>
    </div>
    <div style="text-align: right" v-if="step == 3"></div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import * as names from "@/store/names";
import { mapGetters, mapMutations } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  // minValue,
  // maxValue,
  // numeric,
} from "vuelidate/lib/validators";

export default {
  name: "SectionForm",
  mixins: [validationMixin],
  components: {
    UserList: () => import("@/components/teaching/Scheduler/UserList"),
  },
  props: {
    Section: {
      type: Object,
    },
    egress_profile_id: {
      type: Number,
      required: true,
    },
    matter_id: {
      type: Number,
    },
    period_id: {
      type: Number,
    },
    shift_id: {
      type: Number,
    },
    campus_id: {
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    show_delete_button: {
      type: Boolean,
      default: true,
    },
    edit_matter: {
      type: Boolean,
      default: true,
    },
    edit_level: {
      type: Boolean,
      default: true,
    },
    edit_period: {
      type: Boolean,
      default: true,
    },
    edit_shift: {
      type: Boolean,
      default: true,
    },
    edit_campus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      step: 1,
      total_steps: 3,
      saved: false,
      section: {
        id: this.Section ? this.Section.id : generateUniqueId(),
        name: this.Section ? this.Section.name : "",
        color: this.Section ? this.Section.color : "#000000",
        attention_hours: this.Section ? this.Section.attention_hours : "",
        coexistence_ruls: this.Section ? this.Section.coexistence_ruls : "",
        // plan_level: this.Section ? this.Section.plan_level : 1,
        egress_profile_matter: this.Section
          ? this.Section.egress_profile_matter
          : this.matter_id,
        period: this.Section ? this.Section.period : this.period_id,
        parent: this.Section ? this.Section.parent : null,
        shift: this.Section ? this.Section.shift : this.shift_id,
        campus: this.Section ? this.Section.campus : this.campus_id,
        students: this.Section ? this.Section.students : [],
        professors: this.Section ? this.Section.professors : [],
        sessions: this.Section ? this.Section.sessions : [],
        session_dates: this.Section ? this.Section.session_dates : [],
      },
    };
  },
  validations() {
    return {
      section: {
        name: { required },
        color: {},
        // plan_level: {
        //   required,
        //   numeric,
        //   minValue: minValue(1),
        //   maxValue: maxValue(18),
        // },
        egress_profile_matter: {
          required,
          validMatter(value) {
            const matter = this.egress_profiles_matters.find(
              (x) => x.id == value
            );

            if (matter) {
              if (matter.is_closed) return true;
              else return false;
            } else return false;
          },
        },
        period: { required },
        shift: { required },
        campus: { required },
        students: {},
        professors: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
      periods: names.PERIODS,
      shifts: names.SHIFTS,
      campuses: names.CAMPUSES,
      user: "getUser",
    }),
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    levels() {
      let list = [];
      if (!this.egressProfile) return list;
      for (
        let index = 1;
        index <= this.egressProfile.semester_amount;
        index++
      ) {
        list.push(index);
      }
      return list;
    },
    matterList() {
      let matterList = [];
      const list = this.egress_profiles_matters.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
      // && x.plan_level == this.section.plan_level
      if (list.length > 0) {
        list.forEach((x) => {
          const matter = this.matters.find((matter) => matter.id == x.matter);
          if (matter) {
            matterList.push({
              id: x.id,
              name: matter.name,
            });
          }
        });
      }
      return matterList;
    },
    periodList() {
      let list = [];
      this.periods.forEach((period) => {
        let tmp = period;
        tmp.label =
          period.name + " De: " + period.start_date + " al " + period.end_date;
        list.push(tmp);
      });
      return list;
    },
    filteredCampuses() {
      if (!this.egressProfile) return this.campuses;
      let list = [];
      this.egressProfile.campuses.forEach((campus_id) => {
        const instance = this.campuses.find((x) => x.id == campus_id);
        if (instance) list.push(instance);
      });
      return list;
    },
  },
  methods: {
    ...mapMutations([
      "setUserSectionProfessor",
      "UpdateUserSectionProfessor",
      "DeleteUserSectionProfessor",
    ]),
    validateState(name) {
      const { $dirty, $error } = this.$v.section[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.section.$touch();
      if (this.$v.section.$anyError) {
        return;
      }
      // if (this.step == 3 && this.section.professors.length == 0) {
      //   return this.$swal(
      //     "No se han ingresado profesores",
      //     "Por favor, asegúrese de asignar profesores a la sección antes de guardar.",
      //     "warning"
      //   );
      // }
      if (isNaN(this.section.id)) this.create();
      else this.update();
      this.saved = true;
    },
    create() {
      this.$store
        .dispatch(names.POST_SECTION, this.section)
        .then((response) => {
          if (this.section.professors.find((x) => x == this.user.id)) {
            let payload = { user_id: this.user.id, section: response };
            this.setUserSectionProfessor(payload);
          }
          this.$emit("created", response);
          toast(
            this.$getVisibleNames("teaching.section", false, "Sección") +
              " creada."
          );
        });
    },
    update() {
      this.$store
        .dispatch(names.UPDATE_SECTION, this.section)
        .then((response) => {
          let payload = { user_id: this.user.id, section: response };
          this.UpdateUserSectionProfessor(payload);
          this.$emit("updated", response);
          toast(
            this.$getVisibleNames("teaching.section", false, "Sección") +
              " modificada."
          );
        });
    },
    askForDelete() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Sección?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) this.delete();
      });
    },
    delete() {
      this.$store.dispatch(names.DELETE_SECTION, this.section.id).then(() => {
        this.$emit("deleted", this.section);
        let payload = { user_id: this.user.id, section: this.section };
        this.DeleteUserSectionProfessor(payload);
        toast(
          this.$getVisibleNames("teaching.section", false, "Sección") +
            " eliminada."
        );
      });
    },

    slotStudentsChanged(students) {
      this.section.students = [];
      students.forEach((user) => {
        const index = this.section.students.findIndex((x) => x == user.id);
        if (index == -1) this.section.students.push(user.id);
      });
    },
    slotProfessorsChanged(professors) {
      this.section.professors = [];
      professors.forEach((user) => {
        const index = this.section.professors.findIndex((x) => x == user.id);
        if (index == -1) this.section.professors.push(user.id);
      });
    },
    prevStep() {
      return this.step--;
    },
    nextStep() {
      if (this.allows_crud) {
        this.$v.section.$touch();
        if (this.$v.section.$anyError) {
          return;
        }
      }
      // if (this.step == 2 && this.section.students.length == 0) {
      //   return this.$swal(
      //     "No se han ingresado estudiantes",
      //     "Por favor, asegúrese de asignar estudiantes a la sección antes de continuar.",
      //     "warning"
      //   );
      // }
      return this.step++;
    },
  },
  created() {
    this.saved = false;
    this.$store.dispatch(names.FETCH_SHIFTS);
    this.$store.dispatch(names.FETCH_MATTERS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
      egress_profile_id: this.egress_profile_id,
    });
  },
};
</script>

<style scoped>
.container-group {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  flex-wrap: wrap;
  margin-top: 10%;
}
.group {
  width: 320px;
}
.hidden-step {
  display: none;
}
.is-shown {
  display: block;
}
.progress {
  border: 1px solid var(--secondary-color);
  margin-top: -14%;
  margin-bottom: 5%;
}
.step-grid {
  display: flex;
  justify-content: center;
  margin-bottom: 10% !important;
}
.step-icon-border {
  width: 80px;
  height: 80px;
  border: 3px solid var(--secondary-color);
  border-radius: 50%;
  padding: 16px;
  z-index: 30;
  background: #fff;
}
.step-icon {
  font-size: 40px;
  color: var(--secondary-color);
}
.step-caption {
  width: 170px;
  text-align: center;
  margin-top: 1.3em;
  transform: translateX(-38%);
}
.first-load {
  width: 33%;
}
.second-load {
  width: 50%;
}
.third-load {
  width: 66%;
}
.fill {
  width: 100%;
}
.done {
  border: 3px solid #28a745 !important;
  transition: 0.4s;
}
@media (max-width: 1200px) {
  .progress {
    margin-top: -16%;
    margin-bottom: 7%;
  }
  .first-load {
    width: 30%;
  }
  .third-load {
    width: 70%;
  }
}
@media (max-width: 991px) {
  .progress {
    margin-top: -20%;
    margin-bottom: 19%;
  }
  .step-icon-border {
    margin-right: 1% !important;
  }
  .step-caption {
    width: 190px;
    padding: 0 10px;
  }
  .first-load {
    width: 25%;
  }
}
</style>