<template>
  <div>
    <b-form-group
      label="Nombres:"
      label-for="first_name-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="first_name-input"
        name="first_name-input"
        type="text"
        v-model="User.first_name"
        :disabled="true"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label="Apellidos:"
      label-for="last_name-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="last_name-input"
        name="last_name-input"
        type="text"
        :disabled="true"
        v-model="User.last_name"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label="Correo:"
      label-for="email-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <div class="d-flex">
        <div class="d-blok w-100">
          <b-form-input
            id="email-input"
            name="email-input"
            type="text"
            v-model="User.email"
            :disabled="true"
          ></b-form-input>
        </div>
      </div>
    </b-form-group>
    <b-form-group
      label="Rol:"
      label-for="role-input"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="select-role"
        name="select-role"
        :options="Roles"
        value-field="id"
        text-field="position_name"
        :disabled="true"
        v-model="access.role"
      >
      </b-form-select>
    </b-form-group>
    <template v-if="!isNaN(access.id)">
      <div
        class="accordion"
        role="tablist"
        v-for="table in tablesListFiltered"
        :key="table.id"
      >
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle="String('key_id:' + table.id)"
              variant="info"
              >{{
                $getVisibleNames(table.name, true, table.viewable_name)
              }}</b-button
            >
          </b-card-header>
          <b-collapse
            :id="String('key_id:' + table.id)"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <template v-if="table.name == 'special.close_egress_profile'">
                <SpecialAccessCard
                  :Table="table"
                  :Items="egressProfiles"
                  :Access="access"
                  :allows_crud="allows_crud"
                >
                </SpecialAccessCard>
              </template>
              <template
                v-else-if="table.name == 'special.close_egress_profile_matter'"
              >
                <EPMSpecialAccessCard
                  :Table="table"
                  :Items="filterEPMatters"
                  :Access="access"
                  :allows_crud="allows_crud"
                >
                </EPMSpecialAccessCard>
              </template>
              <template v-else-if="table.name == 'special.close_base_matter'">
                <SpecialAccessCard
                  :Table="table"
                  :Items="matters"
                  :Access="access"
                  :allows_crud="allows_crud"
                >
                </SpecialAccessCard>
              </template>
              <template v-else-if="table.name == 'mesh.faculty'">
                <AccessCard
                  :Table="table"
                  :Items="faculties"
                  :Access="access"
                  :allows_crud="allows_crud"
                >
                </AccessCard>
              </template>
              <template v-else-if="table.name == 'mesh.career'">
                <AccessCard
                  :Table="table"
                  :Items="careers"
                  :Access="access"
                  :allows_crud="allows_crud"
                >
                </AccessCard>
              </template>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>
    <template v-else>
      <div class="text-center">
        Para crear un Accesos es necesario que el Usuario cuenta con un Rol
      </div>
    </template>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AccessForm",
  components: {
    AccessCard: () => import("./AccessCard/AccessCard.vue"),
    SpecialAccessCard: () => import("./AccessCard/SpecialAccessCard.vue"),
    EPMSpecialAccessCard: () => import("./AccessCard/EPMSpecialAccessCard.vue"),
  },

  props: {
    User: {
      type: Object,
      required: true,
    },
    Access: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          user: null,
          role: null,
          school: null,
        };
      },
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    Roles: {
      type: Array,
    },
  },
  data() {
    return {
      tables_list: [],
      access: { ...this.Access },
    };
  },
  computed: {
    ...mapGetters({
      careers: names.CAREERS,
      faculties: names.FACULTIES,
      egressProfiles: names.EGRESS_PROFILES,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
    }),
    tablesListFiltered() {
      let tables_list_filtered = [];
      if (this.access && this.access.position == 1)
        return this.tables_list
          .filter(
            (x) =>
              // this.$equals(x.name, "special.close_egress_profile_matter") ||
              this.$equals(x.name, "special.close_base_matter") ||
              this.$equals(x.name, "special.close_egress_profile")
          )
          .sort((a, b) => a.name.localeCompare(b.name));
      if (this.access && this.access.position == 3)
        return this.tables_list.filter((x) =>
          this.$equals(x.name, "mesh.faculty")
        );
      if (this.access && this.access.position == 4)
        return this.tables_list.filter((x) =>
          this.$equals(x.name, "mesh.career")
        );
      return tables_list_filtered;
    },
    filterEPMatters() {
      let list = [];
      this.egressProfiles.forEach((egress_profile) => {
        const egress_profile_matters = this.egress_profile_matters.filter(
          (x) => x.egress_profile == egress_profile.id
        );
        if (egress_profile_matters.length > 0)
          list.push({
            id: egress_profile.id,
            name: String(egress_profile.name),
            options: egress_profile_matters.sort(function (a, b) {
              if (a.order > b.order) return 1;
              if (a.order < b.order) return -1;
            }),
          });
      });
      return list;
    },
  },
  methods: {},
  mounted() {},
  created() {
    this.$restful.Get(`/teaching/table/`).then((response) => {
      this.tables_list = response;
    });
    this.$restful
      .Get(`/teaching/access/?user=${this.User.id}`)
      .then((response) => {
        if (response.length > 0) this.access = response[0];
      });
  },
};
</script>

<style scoped>
</style>