<template>
  <div>
    <div>
      <b-form-group label-cols-sm="3" class="label m-0 ml-1">
        <template #label>
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            class="mr-1"
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()} vinculadas`
            "
            >⚠️</span
          >Nombre
        </template>
        <b-form-input
          :id="`name-input-${old_matter.id}`"
          name="name-input"
          class="name-input"
          v-bind:class="{ 'matter-name': SameMatter }"
          v-model="$v.old_matter.name.$model"
          :disabled="old_matter.is_closed"
          :state="validateState('name') && !SameMatter"
          aria-describedby="input-name-live-feedback"
          size="sm"
        ></b-form-input>
        <!-- && !SameMatter -->
        <b-form-invalid-feedback id="input-name-live-feedback">
          <div v-if="old_matter.name.length < 5">
            Este campo debe tener al menos 5 caracteres.
          </div>
          <div v-if="SameMatter" class="text-alert">
            Ya se cuenta con una
            {{ $getVisibleNames("teaching.matter", false, "Asignatura Base") }}
            con este nombre.
          </div>
        </b-form-invalid-feedback>
        <b-tooltip
          :target="`name-input-${old_matter.id}`"
          variant="secondary"
          placement="right"
          :noninteractive="true"
          v-if="SameMatter"
        >
          <div v-if="SameMatter">
            {{
              filterSameMatter.length > 0
                ? $getVisibleNames(
                    "mesh.egressprofilematter",
                    true,
                    "Asignaturas"
                  )
                : $getVisibleNames(
                    "mesh.egressprofilematter",
                    false,
                    "Asignatura"
                  )
            }}
            Existente{{ filterSameMatter.length > 0 ? "s" : "" }}:
            <div v-for="matter in filterSameMatter" :key="matter.id">
              <p>
                {{ matter.name }}
              </p>
            </div>
          </div>
        </b-tooltip>
      </b-form-group>
      <div class="row-code pb-3">
        <div class="w-25">
          <label for="input-code" class="label-code">
            <span
              v-if="
                !old_matter.is_closed &&
                !((institution && institution.id == 12) || $debug_change_duoc)
              "
              v-b-tooltip.v-secondary.noninteractive="
                `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                  'mesh.egressprofilematter',
                  true,
                  'Asignaturas'
                ).toLowerCase()} vinculadas`
              "
              >⚠️</span
            >
            {{ $getVisibleNames("manual.matter_code", false, "Código") }}</label
          >
        </div>
        <div class="w-75">
          <b-form-group class="label m-0 custom-input">
            <b-form-input
              id="input-code"
              v-model="$v.old_matter.code.$model"
              :disabled="old_matter.is_closed"
              :state="validateState('code')"
              aria-describedby="input-code-live-feedback"
              size="sm"
              class="input-code"
              @update="
                (value) => {
                  old_matter.code = value.toUpperCase();
                }
              "
            ></b-form-input>
            <b-form-invalid-feedback id="input-code-live-feedback">
              <div v-if="!SameMatterCode">
                Ya Existe una
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    false,
                    "Asignatura"
                  )
                }}
                con el mismo Código.
              </div>
              <div v-else>Este campo debe tener al menos 3 caracteres.</div>
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <b-form-group label-cols="0" label-cols-sm="5" class="label m-0">
        <template #label>
          <div class="weighing-label">
            <div class="label-content">
              <span
                v-if="
                  !old_matter.is_closed &&
                  !((institution && institution.id == 12) || $debug_change_duoc)
                "
                class="mr-1"
                v-b-tooltip.v-secondary.noninteractive="
                  `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    true,
                    'Asignaturas'
                  ).toLowerCase()} vinculadas`
                "
                >⚠️</span
              >Ponderación Equivalente
              <span>
                <InfoTooltip
                  class="info-icon"
                  :tooltip_text="
                    'Utilizar una ponderación equivalente para los ' +
                    $getVisibleNames('teaching.ramicro', false, 'RA Micro') +
                    ' y ' +
                    $getVisibleNames(
                      'teaching.evaluationcriteriamacro',
                      true,
                      'Criterios de Evaluación Macro'
                    )
                  "
                >
                </InfoTooltip
              ></span>
            </div>
          </div>
        </template>
        <div id="credit">
          <div class="d-flex">
            <div class="toggle-types noprint">
              <span
                :class="{
                  'font-weight-bold': old_matter.automatic_achievement_weighing,
                }"
                >Sí</span
              >
              <b-button
                class="check-type"
                :class="{ 'check-type-disabled': old_matter.is_closed }"
                @click="
                  () => {
                    if (!old_matter.is_closed)
                      old_matter.automatic_achievement_weighing =
                        !old_matter.automatic_achievement_weighing;
                  }
                "
                variant="primary"
              >
                <b-icon
                  icon="circle-fill"
                  class="icon-check"
                  :class="{
                    'icon-check-active':
                      !old_matter.automatic_achievement_weighing,
                  }"
                  scale="0.7"
                ></b-icon>
              </b-button>
              <span
                :class="{
                  'font-weight-bold':
                    !old_matter.automatic_achievement_weighing,
                }"
                >No</span
              >
            </div>
          </div>
        </div></b-form-group
      >
      <b-form-group label-cols="0" label-cols-sm="5" class="label m-0">
        <template #label>
          <div class="weighing-label">
            <div class="label-content">
              <span
                v-if="
                  !old_matter.is_closed &&
                  !((institution && institution.id == 12) || $debug_change_duoc)
                "
                class="mr-1"
                v-b-tooltip.v-secondary.noninteractive="
                  `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                    'mesh.egressprofilematter',
                    true,
                    'Asignaturas'
                  ).toLowerCase()} vinculadas`
                "
                >⚠️</span
              >
              Es seleccionable en
              {{
                $getVisibleNames(
                  "mesh.basematterwrapper",
                  false,
                  "Módulo Electivo Genérico"
                )
              }}
              <span
                ><InfoTooltip
                  class="info-icon"
                  :tooltip_text="
                    'Necesario para que pueda aparecer para seleccionar en ' +
                    $getVisibleNames(
                      'mesh.basematterwrapper',
                      false,
                      'Módulo Electivo Genérico'
                    )
                  "
                >
                </InfoTooltip
              ></span>
            </div>
          </div>
        </template>
        <div id="credit">
          <div class="d-flex">
            <div class="toggle-types noprint">
              <span
                :class="{
                  'font-weight-bold': old_matter.is_elective,
                }"
                >Sí</span
              >
              <b-button
                class="check-type"
                :class="{
                  'check-type-disabled':
                    old_matter.is_closed || BaseMatterWrapperForm,
                }"
                @click="
                  () => {
                    if (!old_matter.is_closed && !BaseMatterWrapperForm)
                      old_matter.is_elective = !old_matter.is_elective;
                  }
                "
                variant="primary"
              >
                <b-icon
                  icon="circle-fill"
                  class="icon-check"
                  :class="{
                    'icon-check-active': !old_matter.is_elective,
                  }"
                  scale="0.7"
                ></b-icon>
              </b-button>
              <span
                :class="{
                  'font-weight-bold': !old_matter.is_elective,
                }"
                >No</span
              >
            </div>
          </div>
        </div></b-form-group
      >
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        :label="
          $getVisibleNames('mesh.formationarea', false, 'Área De Formación')
        "
        label-for="input-formation-area"
        class="label m-0 my-3"
      >
        <b-form-select
          id="input-formation-area"
          :options="formationAreas"
          v-model="$v.old_matter.formation_area.$model"
          value-field="id"
          text-field="name"
          :state="validateState('formation_area')"
          type="number"
          :disabled="!allow_crud"
          aria-describedby="input-formation-area-feedback"
          size="sm"
          ><template #first>
            <b-form-select-option :value="null"
              >N/A
            </b-form-select-option></template
          ></b-form-select
        >
        <b-form-invalid-feedback id="input-formation-area-feedback"
          >Este campo no puede ser vacío.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        label-for="input-modality"
        class="label m-0 mt-3"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          {{
            $getVisibleNames("mesh.modality", false, "Modalidad de Asignatura")
          }}
        </template>
        <v-select
          id="input-modalities"
          :options="modalities"
          v-model="$v.old_matter.modalities.$model"
          :reduce="(modalities) => modalities.id"
          :state="validateState('modalities')"
          :disabled="old_matter.is_closed"
          label="name"
          track-by="id"
          multiple
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
          <template v-slot:option="option">
            {{ option.name }}
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        :label="
          $getVisibleNames('mesh.mattertype', false, 'Tipo De Asignatura')
        "
        label-for="input-old_matter-type"
        class="label m-0 mt-3"
      >
        <v-select
          id="input-old_matter-type"
          v-model="$v.old_matter.matter_types.$model"
          :options="matterTypes"
          :reduce="(matterTypes) => matterTypes.id"
          multiple
          :placeholder="`Seleccione uno o varios ${$getVisibleNames(
            'mesh.mattertype',
            true,
            'Tipo De Asignatura'
          )}`"
          :state="validateState('matter_types')"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
        v-if="institution && institution.show_matter_contribution"
        label-cols="0"
        label-cols-sm="3"
        :label="
          $getVisibleNames(
            'mesh.mattercontribution',
            false,
            'Estrategia Curricular'
          )
        "
        label-for="input-old_matter-contribution"
        class="label m-0 mt-3"
      >
        <v-select
          id="input-old_matter-contribution"
          :options="matterContributions"
          v-model="$v.old_matter.matter_contributions.$model"
          :reduce="(matterContributions) => matterContributions.id"
          multiple
          :placeholder="`Seleccione una o varias ${$getVisibleNames(
            'mesh.mattercontribution',
            true,
            'Estrategias Curriculares'
          )}`"
          :state="validateState('matter_contributions')"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
          <template v-slot:option="option">
            <strong>{{ option.name }}: </strong>
            {{ option.description }}
          </template>
        </v-select>
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        label-for="input-study_environments"
        class="label m-0 mt-3"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          {{
            $getVisibleNames(
              "mesh.studyenvironment",
              false,
              "Ambiente de Aprendizaje"
            )
          }}
        </template>
        <v-select
          id="input-study_environments"
          v-model="$v.old_matter.study_environments.$model"
          :options="studyEnvironments"
          :disabled="old_matter.is_closed"
          :reduce="(studyEnvironments) => studyEnvironments.id"
          :placeholder="`${
            studyEnvironments.length > 0
              ? String(
                  'Seleccione uno o varios ' +
                    $getVisibleNames(
                      'mesh.studyenvironment',
                      false,
                      'Ambiente de Aprendizaje'
                    ).toLowerCase()
                )
              : String(
                  'Este ' +
                    $getVisibleNames(
                      'mesh.studyenvironment',
                      false,
                      'Ambiente de Aprendizaje'
                    ).toLowerCase() +
                    ' no cuenta con ' +
                    $getVisibleNames(
                      'mesh.studyenvironment',
                      false,
                      'Ambiente de Aprendizaje'
                    ).toLowerCase() +
                    ' creadas'
                )
          }`"
          multiple
          :dropdown-should-open="dropdownShouldOpen2"
          :state="validateState('study_environments')"
          label="name"
          track-by="id"
          size="sm"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontraron resultados para: "<em>{{ search }}</em
              >"
            </template>
          </template>
        </v-select>
        <b-tooltip
          target="input-study_environments"
          variant="secondary"
          :noninteractive="true"
          triggers="hover"
        >
          <div v-if="studyEnvironments.length == 0">
            Diríjase a Definiciones Curriculares para poder crear
            {{
              $getVisibleNames(
                "mesh.studyenvironment",
                false,
                "Ambiente de Aprendizaje"
              ).toLowerCase()
            }}.
          </div>
        </b-tooltip>
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        label-for="input-weeks"
        class="label m-0 mt-3"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          Semanas
        </template>
        <b-form-input
          id="input-weeks"
          v-model="$v.old_matter.weeks.$model"
          type="number"
          min="0"
          :disabled="old_matter.is_closed"
          :state="validateState('weeks')"
          aria-describedby="input-weeks-feedback"
          @update="slotWeekUpdated"
          size="sm"
        ></b-form-input>

        <b-form-invalid-feedback id="input-weeks-feedback"
          >Este campo debe ser entero mayor a mayor 0 y menor o igual a
          52.</b-form-invalid-feedback
        >
      </b-form-group>
      <MatterTimeAllocationComponent
        class="mt-2"
        :oldMatter="old_matter"
        @updated="slotUpdateMatterHours"
      ></MatterTimeAllocationComponent>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        label-for="input-credits"
        class="label mt-2"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          {{ $getVisibleNames("manual.credits", true, "Créditos SCT Totales") }}
        </template>
        <b-form-input
          id="input-credits"
          type="number"
          min="0"
          v-model="$v.old_matter.credits.$model"
          :disabled="old_matter.is_closed"
          :state="validateState('credits')"
          aria-describedby="input-credits-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-credits-feedback"
          >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="(institution && institution.id == 7) || $debug_change_unab"
        label-cols="0"
        label-cols-sm="3"
        label-for="input-institutional_credits"
        class="label mt-2"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="!old_matter.is_closed"
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          {{
            $getVisibleNames(
              "manual.institutional_credits",
              true,
              "Créditos Institucionales"
            )
          }}
        </template>
        <b-form-input
          id="input-institutional_credits"
          type="number"
          min="0"
          v-model="$v.old_matter.institutional_credits.$model"
          :disabled="old_matter.is_closed"
          :state="validateState('institutional_credits')"
          aria-describedby="input-institutional_credits-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-institutional_credits-feedback"
          >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        label-for="input-suggested_students"
        class="label mt-2"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          N° Estudiantes
        </template>
        <b-form-input
          id="input-suggested_students"
          type="number"
          min="0"
          v-model="$v.old_matter.suggested_students.$model"
          :disabled="old_matter.is_closed"
          :state="validateState('suggested_students')"
          aria-describedby="input-suggested_students-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-suggested_students-feedback"
          >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label-cols="0"
        label-cols-sm="3"
        label-for="input-suggested_teachers"
        class="label mt-2"
      >
        <template v-slot:label>
          <!-- <b-icon
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            icon="exclamation-triangle"
            style="margin-bottom: 0.1rem"
          ></b-icon> -->
          <span
            v-if="
              !old_matter.is_closed &&
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()}`
            "
            >⚠️</span
          >
          N° Docentes
        </template>
        <b-form-input
          id="input-suggested_teachers"
          type="number"
          min="0"
          v-model="$v.old_matter.suggested_teachers.$model"
          :disabled="old_matter.is_closed"
          :state="validateState('suggested_teachers')"
          aria-describedby="input-suggested_teachers-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-suggested_teachers-feedback"
          >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
        >
      </b-form-group>
    </div>
    <!-- Comienza la sección de los botones -->
    <div
      class="row mt-4"
      v-if="
        (user_position &&
          [1, 2].includes(user_position.position) &&
          [1, 2].includes(user.groups[0])) ||
        user.is_superuser
      "
    >
      <div v-if="show_delete_button" class="col" style="text-align: left">
        <b-button
          v-if="user.is_superuser"
          v-can="'teaching.delete_matter'"
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteMatter(old_matter.id)"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "OldMatterForm",
  mixins: [validationMixin],
  components: {
    vSelect,
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    MatterTimeAllocationComponent: () =>
      import("./MatterTimeAllocationComponent.vue"),
  },
  props: {
    OldMatter: {
      type: Object,
    },
    BaseMatterWrapperForm: {
      type: Boolean,
      default: false,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    allow_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      old_matter: {
        id: this.OldMatter ? this.OldMatter.id : generateUniqueId(),
        name: this.OldMatter ? this.OldMatter.name : "",
        code: this.OldMatter ? this.OldMatter.code : "",
        is_elective: this.OldMatter
          ? this.OldMatter.is_elective
          : this.BaseMatterWrapperForm
          ? true
          : false,
        is_closed: this.OldMatter ? this.OldMatter.is_closed : false,
        toggle_in_weeks: this.OldMatter
          ? this.OldMatter.toggle_in_weeks
          : false,
        study_environments: this.OldMatter
          ? this.OldMatter.study_environments
          : [],
        formation_area: this.OldMatter ? this.OldMatter.formation_area : null,
        formation_lines: this.OldMatter ? this.OldMatter.formation_lines : [],
        matter_types: this.OldMatter ? this.OldMatter.matter_types : [],
        methodological_strategies: this.OldMatter
          ? this.OldMatter.methodological_strategies
          : [],
        matter_contributions: this.OldMatter
          ? this.OldMatter.matter_contributions
          : [],
        modalities: this.OldMatter ? this.OldMatter.modalities : [],
        egress_profile: this.OldMatter
          ? this.OldMatter.egress_profile
          : this.egress_profile_id,
        prerequisites: this.OldMatter ? this.OldMatter.prerequisites : [],
        competences: this.OldMatter ? this.OldMatter.competences : [],
        campuses: this.OldMatter ? this.OldMatter.campuses : [],
        weeks: this.OldMatter ? this.OldMatter.weeks : 18,
        automatic_achievement_weighing: this.OldMatter
          ? this.OldMatter.automatic_achievement_weighing
          : false,
        credits: this.OldMatter ? this.OldMatter.credits : 0,
        institutional_credits: this.OldMatter
          ? this.OldMatter.institutional_credits
          : 0,
        suggested_students: this.OldMatter
          ? this.OldMatter.suggested_students
          : 0,
        suggested_teachers: this.OldMatter
          ? this.OldMatter.suggested_teachers
          : 0,
      },
      matter_hours: [],
    };
  },
  validations() {
    return {
      old_matter: {
        name: { required, minLength: minLength(5) },
        code: {
          // required,
          // minLength: minLength(3),
          isUnique() {
            if (this.old_matter.code == "") return true;
            else return this.SameMatterCode;
          },
        },
        formation_area: {},
        matter_types: {},
        matter_contributions: {},
        modalities: {},
        study_environments: {},
        weeks: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(52),
        },
        credits: {
          minValue: minValue(0),
        },
        institutional_credits: {
          minValue: minValue(0),
        },
        suggested_students: {},
        suggested_teachers: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      matters: names.MATTERS,
      matterTypes: names.MATTER_TYPES,
      formationAreas: names.FORMATION_AREAS,
      institution: "getInstitution",
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matterContributions: names.MATTER_CONTRIBUTIONS,
      modalities: names.MODALITIES,
      matter_codes: names.MATTER_CODES,
    }),
    SameMatter() {
      if (!this.old_matter) return false;
      if (!this.old_matter.name) return false;
      const SameMatter = this.matters.filter((matter) =>
        this.$equals(matter.name, this.old_matter.name)
      );
      if (
        (SameMatter.length > 0 && isNaN(this.old_matter.id)) ||
        (SameMatter.length > 0 &&
          !SameMatter.find((matter) => matter.id == this.old_matter.id))
      )
        return true;
      return false;
    },
    SameMatterCode() {
      if (!this.old_matter) return false;
      const SameMatterCode = this.matter_codes.find(
        (matter) =>
          this.$equals(this.old_matter.code, matter.code) &&
          this.old_matter.id != matter.id
      );
      if (SameMatterCode) return false;
      return true;
    },
    filterSameMatter() {
      if (!this.old_matter) return [];
      if (!this.old_matter.name) return [];
      const SameMatter = this.matters.filter((matter) =>
        this.$equals(matter.name, this.old_matter.name)
      );
      if (
        (SameMatter.length > 0 && isNaN(this.old_matter.id)) ||
        (SameMatter.length > 0 &&
          !SameMatter.find((matter) => matter.id == this.old_matter.id))
      )
        return SameMatter;
      return [];
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.old_matter[key];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.old_matter.$touch();
      if (this.$v.old_matter.$anyError) {
        return;
      }
      if (isNaN(this.old_matter.id)) this.createdMatter();
      else this.updateMatter();
    },
    createdMatter() {
      this.$store
        .dispatch(names.POST_MATTER, this.old_matter)
        .then((response) => {
          this.saveMatterHours(response.id);
          toast(
            this.$getVisibleNames("teaching.matter", false, "Asignatura Base") +
              " creada correctamente."
          );
          this.$emit("created", response);
        });
    },
    updateMatter() {
      this.$store
        .dispatch(names.PATCH_MATTER, {
          matter_id: this.old_matter.id,
          item: {
            name: this.old_matter.name,
            description: this.old_matter.description,
            code: this.old_matter.code,
            is_elective: this.old_matter.is_elective,
            toggle_in_weeks: this.old_matter.toggle_in_weeks,
            plan_level: this.old_matter.plan_level,
            formation_area: this.old_matter.formation_area,
            matter_types: this.old_matter.matter_types,
            matter_contributions: this.old_matter.matter_contributions,
            modalities: this.old_matter.modalities,
            weeks: this.old_matter.weeks,
            automatic_achievement_weighing:
              this.old_matter.automatic_achievement_weighing,
            study_environments: this.old_matter.study_environments,
            credits: this.old_matter.credits,
          },
        })
        .then((response) => {
          this.saveMatterHours(response.id);
          this.$restful
            .Get(
              `teaching/update_ra_micro_ec_macro_weighing/?matter=${response.id}`
            )
            .then(() => {
              this.$store.dispatch(
                names.FETCH_EVALUATION_CRITERIAS_MACROS,
                response.id
              );
              this.$store.dispatch(names.FETCH_STUDY_UNITS, response.id);
            });
          toast("Cambios guardados correctamente.");
          this.$emit("updated");
        });
    },
    deleteMatter(id) {
      this.$restful
        .Get(
          `/teaching/cascade_delete_objects/?app_label=teaching&model_name=matter&id=${id}`
        )
        .then((response) => {
          this.$swal({
            title: `¿Está seguro de que desea eliminar esta instancia de ${this.$getVisibleNames(
              "teaching.matter",
              false,
              "Asignatura Base"
            )}?`,
            width: "auto",
            text: `Esta acción no se podrá revertir y se eliminarán los objetos referenciados en la parte inferior.`,
            type: "warning",
            footer: `<ul>${response
              .map((objeto) => `<li>${objeto.model}: ${objeto.str}</li>`)
              .join("")}</ul>`,
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              this.$store.dispatch(names.DELETE_MATTER, id).then(() => {
                this.$store.dispatch(names.FETCH_MATTER_CODES);
                this.$emit("delete", this.old_matter);
                toast(
                  this.$getVisibleNames(
                    "mesh.egressprofilematter",
                    false,
                    "Asignatura"
                  ) + " eliminada."
                );
              });
            }
          });
        });
      // const matters = this.egress_profiles_matters.filter(
      //   (x) => x.matter == this.old_matter.id
      // );
      // this.$swal({
      //   title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
      //     "teaching.matter",
      //     false,
      //     "Asignatura Base"
      //   )}"?`,
      //   text: `"Esta acción no se podrá revertir${
      //     matters.length > 0
      //       ? " y se eliminarán " +
      //         matters.length +
      //         this.$getVisibleNames(
      //           "mesh.egressprofilematter",
      //           true,
      //           "Asignaturas"
      //         ) +
      //         " asociadas a esta " +
      //         this.$getVisibleNames("teaching.matter", false, "Asignatura Base")
      //       : "."
      //   }"`,
      //   type: "warning",
      //   showCancelButton: true,
      // }).then((result) => {
      //   if (result.value) {
      //     this.$store.dispatch(names.DELETE_MATTER, id).then(() => {
      //       this.$store.dispatch(names.FETCH_MATTER_CODES);
      //       this.$emit("delete", this.old_matter);
      //       toast(
      //         this.$getVisibleNames(
      //           "mesh.egressprofilematter",
      //           false,
      //           "Asignatura"
      //         ) + " eliminada."
      //       );
      //     });
      //   }
      // });
    },
    dropdownShouldOpen2(VueSelect) {
      if (this.studyEnvironments.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    slotUpdateMatterHours(matter_hours) {
      this.matter_hours = matter_hours;
    },
    async saveMatterHours(matter_id) {
      for await (let hour of this.matter_hours) {
        if (isNaN(hour.id)) {
          hour.matter = matter_id;
          await this.$store
            .dispatch(names.POST_MATTER_TIME_ALLOCATION, hour)
            .then((response) => {
              hour.id = response.id;
            });
        } else {
          hour.matter = matter_id;
          await this.$store.dispatch(names.UPDATE_MATTER_TIME_ALLOCATION, hour);
        }
      }
    },
    slotWeekUpdated(week) {
      if (week < 0) return;
      this.old_matter.weks = week;
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS_TIME_ALLOCATION);
    this.$store.dispatch(names.FETCH_MATTER_CODES);
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
}
.label {
  font-size: var(--thirth-font-size);
  margin-bottom: -1% !important;
}
.label-matter {
  padding-right: 10px !important;
  font-size: 20px;
}
.custom-input {
  margin-bottom: -3.4% !important;
}
.row-code {
  display: flex;
  min-height: 55px;
  width: 100% !important;
  margin-bottom: -1.6% !important;
  margin-top: 2%;
}
.label-code {
  font-size: var(--thirth-font-size);
  margin-top: 1.3em !important;
  margin-left: 0.4em;
}
.input-code {
  width: 98%;
  margin-left: 1%;
}
.matter-name {
  border: 1px solid #17a2b8;
}
.form-group >>> .text-alert {
  color: #17a2b8 !important;
}
.toggle-types {
  display: flex;
  float: right;
  /* margin-right: 1em; */
  /* background: rgb(230, 230, 230); */
  padding: 0.3em 1.8em;
  border-radius: 3px;
}
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.1em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.check-type-disabled {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ) !important;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.icon-check {
  color: white;
  transform: translate(-70%, -50%);
  transition: 0.2s;
}
.icon-check-active {
  transform: translate(15%, -50%) !important;
}
.weighing-info {
  transform: translate(-380%, -210%);
  height: 12px;
  width: 12px;
}
.name-input {
  margin-left: -0.25em;
}
</style>