<template>
  <tr>
    <td>{{ numberToWord(semester) }}</td>
    <td
      v-for="hour in time_allocations.filter(
        (x) =>
          x.uses_module_minutes == true &&
          egress_profile_time_allocations.includes(x.id)
      )"
      :key="hour.id"
    >
      {{ countTimeAllocationHours(hour.id) }}
    </td>
    <td>{{ totalPedagogical }}</td>
    <td v-if="profileType">
      {{
        (totalPedagogical * (parseFloat(profileType.module_minutes) / 60))
          | Round
      }}
    </td>
    <td
      v-for="hour in time_allocations.filter(
        (x) =>
          x.uses_module_minutes == false &&
          egress_profile_time_allocations.includes(x.id)
      )"
      :key="hour.id"
    >
      {{ countTimeAllocationHours(hour.id) }}
    </td>
    <td>{{ totalChronological }}</td>
    <td>{{ totalHours }}</td>
    <td>
      <div v-if="profileType">
        <!-- && isAnual -->
        {{ totalSCT }}
      </div>
    </td>
  </tr>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "SummerySemesterRow",
  props: {
    egress_profile_id: {
      type: Number,
      required: true,
    },
    egress_profile_time_allocations: {
      type: Array,
      default: () => [],
    },
    semester: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    numberToWord(number) {
      return this.numbersToWord[number - 1]
        ? this.numbersToWord[number - 1]
        : number;
    },
    countTimeAllocationHours(time_allocation_id) {
      let hours = 0;
      this.mattersBySemester.forEach((matter) => {
        let matter_hour = [];
        if (matter.use_matter_values) {
          matter_hour = this.matter_time_allocations.find(
            (x) =>
              x.matter == matter.matter &&
              x.time_allocation == time_allocation_id
          );
        } else {
          matter_hour = this.ep_matter_time_allocations.find(
            (x) =>
              x.egress_profile_matter == matter.id &&
              x.time_allocation == time_allocation_id
          );
        }
        if (matter_hour && matter_hour.counts_towards_credits) {
          if (matter.regime == this.isAnual.id)
            hours += parseFloat(matter_hour.hours) / 2;
          else hours += parseFloat(matter_hour.hours);
        }
      });
      return Math.round(hours);
    },
  },
  computed: {
    ...mapGetters({
      profile_types: names.PROFILE_TYPES,
      egressProfiles: names.EGRESS_PROFILES,
      matters: names.MATTERS,
      regimes: names.REGIMES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      courses_wrapper: names.COURSES_WRAPPER,
      school: "getInstitution",
      numbersToWord: names.NUMBERS_TO_WORDS,
      time_allocations: names.TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
    }),
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    isAnual() {
      const months_amount_egress_profile = this.regimes.find(
        (x) => x.id == this.egressProfile.regime
      );
      if (months_amount_egress_profile) {
        if (months_amount_egress_profile.months_amount == 6)
          return this.regimes.find(
            (x) =>
              x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
          );
        else if (months_amount_egress_profile.months_amount == 3)
          return this.regimes.find(
            (x) =>
              x.name.includes("Semestral") || parseInt(x.months_amount) / 3 == 2
          );
        else if (months_amount_egress_profile.months_amount == 2)
          return this.regimes.find(
            (x) =>
              x.name.includes("Trimestral") || parseInt(x.months_amount) == 3
          );
      }
      return this.regimes.find(
        (x) => x.name.includes("Anual") || parseInt(x.months_amount) / 6 == 2
      );
    },
    profileType() {
      if (!this.egressProfile) return null;
      return this.profile_types.find(
        (x) => x.id == this.egressProfile.egress_profile_type
      );
    },
    coursesWrapperList() {
      return this.courses_wrapper.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
    },
    wrapperMatterIds() {
      let list = [];
      this.coursesWrapperList.forEach((x) => {
        list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    mattersBySemester() {
      if (!this.isAnual) return [];
      return this.egress_profiles_matters.filter(
        (x) =>
          (x.egress_profile == this.egress_profile_id &&
            !this.wrapperMatterIds.includes(x.id) &&
            x.plan_level == this.semester) ||
          (x.egress_profile == this.egress_profile_id &&
            !this.wrapperMatterIds.includes(x.id) &&
            x.regime == this.isAnual.id &&
            x.plan_level == this.semester - 1)
      );
    },
    totalPedagogical() {
      let hours = 0;
      const pedagogical_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == true)
        .map((x) => x.id);
      this.mattersBySemester.forEach((matter) => {
        let matter_hours = [];
        if (matter.use_matter_values) {
          matter_hours = this.matter_time_allocations.filter(
            (x) =>
              x.matter == matter.matter &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        } else {
          matter_hours = this.ep_matter_time_allocations.filter(
            (x) =>
              x.egress_profile_matter == matter.id &&
              pedagogical_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        }
        matter_hours.forEach((hour) => {
          if (matter.regime == this.isAnual.id)
            hours += parseFloat(hour.hours) / 2;
          else hours += parseFloat(hour.hours);
        });
      });
      return hours;
    },
    totalChronological() {
      let hours = 0;
      const chronological_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == false)
        .map((x) => x.id);
      this.mattersBySemester.forEach((matter) => {
        let matter_hours = [];
        if (matter.use_matter_values) {
          matter_hours = this.matter_time_allocations.filter(
            (x) =>
              x.matter == matter.matter &&
              chronological_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        } else {
          matter_hours = this.ep_matter_time_allocations.filter(
            (x) =>
              x.egress_profile_matter == matter.id &&
              chronological_hours_ids.includes(x.time_allocation) &&
              x.counts_towards_credits == true
          );
        }
        matter_hours.forEach((hour) => {
          if (matter.regime == this.isAnual.id)
            hours += parseFloat(hour.hours) / 2;
          else hours += parseFloat(hour.hours);
        });
      });
      return hours;
    },
    totalHours() {
      if (!this.profileType) return 0;
      const total_hours =
        this.totalPedagogical * (this.profileType.module_minutes / 60) +
        this.totalChronological;
      return total_hours ? total_hours : 0;
    },
    totalSCT() {
      let totalSCT = 0;
      if (!this.profileType || !this.isAnual) return totalSCT;
      this.mattersBySemester.forEach((matter) => {
        if (matter.use_matter_values) {
          const old_matter = this.matters.find((x) => x.id == matter.matter);
          if (old_matter) {
            if (matter.regime == this.isAnual.id)
              totalSCT += old_matter.credits / 2;
            else totalSCT += old_matter.credits;
          }
        } else {
          if (matter.regime == this.isAnual.id) totalSCT += matter.credits / 2;
          else totalSCT += matter.credits;
        }
        // if (
        //   element.egress_profile == this.egressProfile.id &&
        //   element.regime == this.isAnual.id &&
        //   this.semester % 2 == 0 &&
        //   element.plan_level == this.semester - 1
        // ) {
        //   totalSCT += element.credits;
        // }
        // if (
        //   element.plan_level == this.semester &&
        //   element.egress_profile == this.egressProfile.id
        // ) {
        //   totalSCT += element.credits;
        // }
      });
      return Math.round(totalSCT);
    },
  },
};
</script>

<style scoped>
td {
  width: 120px;
  border: solid black;
  border-width: thin;
  text-align: -webkit-center;
}
.table td {
  margin: 2px;
  padding: 2px;
  font-size: var(--secondary-font-size);
  vertical-align: middle;
}
</style>