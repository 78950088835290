<template>
  <div>
    <StudyPlan
      :egress_profile_id="egress_profile_id"
      :allows_crud="allows_crud"
    ></StudyPlan>

    <MeshCustomFab
      class="noprint"
      :egress_profile_id="egress_profile_id"
      :show_study_plan="false"
    ></MeshCustomFab>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
export default {
  name: "StudyPlanView",
  components: {
    StudyPlan: () => import("./StudyPlan"),
    MeshCustomFab: () => import("../MeshCustomFab"),
  },
  data() {
    return {
      egress_profile_id: Number(this.$route.params.egress_profile_id),
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      careers: names.CAREERS,
      egressProfiles: names.EGRESS_PROFILES,
    }),
    egressProfile() {
      if (!this.egress_profile_id) return [];
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    allows_crud() {
      if (
        this.career &&
        this.egressProfile &&
        !this.egressProfile.is_closed &&
        ((this.user_position &&
          [1, 2].includes(this.user_position.position) &&
          [1, 2].includes(this.user.groups[0])) ||
          this.user.is_superuser ||
          this.$hasObjectPermission(
            "mesh.faculty",
            "change",
            this.career.faculty
          ) ||
          this.$hasObjectPermission(
            "mesh.career",
            "change",
            this.egressProfile.career
          ))
      ) {
        const has_permission = this.$hasPermissions(
          "mesh.change_egressprofile"
        );
        if (has_permission) {
          this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
          return has_permission;
        } else {
          return false;
        }
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id)
      .then((egress_profile) => {
        this.$store.dispatch(names.FETCH_CAREER, egress_profile.career);
      });
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_TIME_ALLOCATIONS);
    this.$store.dispatch(names.FETCH_FORMATION_LINES);
  },
};
</script>

<style scoped>
</style>
