<template>
  <b-container fluid class="pt-2">
    <b-breadcrumb
      class="breadcrumb pb-2 pt-0 noprint"
      :items="breadcrumb_items"
    ></b-breadcrumb>
    <div
      v-if="
        allows_crud &&
        ((user_position &&
          [1, 2].includes(user_position.position) &&
          [1, 2].includes(user.groups[0])) ||
          user.is_superuser ||
          $hasObjectPermission('mesh.faculty', 'add') ||
          $hasObjectPermission('mesh.career', 'add'))
      "
      style="display: flex"
    >
      <div class="col" style="text-align: left">
        <button
          v-can="'mesh.add_egressprofile'"
          v-if="allows_crud && user.is_superuser"
          class="btn btn-secondary btn-sm"
          @click="$bvModal.show(`modal-new-egress-profile`)"
        >
          Agregar
          {{
            $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso")
          }}
          <!-- Perfil de Egreso -->
        </button>
      </div>
    </div>
    <b-row>
      <b-col lg="6" class="my-1">
        <!-- <b-form-group
          label="Ordenar por"
          label-for="sort-by-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sort-by-select"
              v-model="sortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- Vacío --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="sortDesc"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              size="sm"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group> -->
      </b-col>
      <b-col lg="6" class="my-1">
        <!-- <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group> -->
      </b-col>
      <!-- <b-col sm="5" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-form-group
          label="Paginación por"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col> -->
    </b-row>
    <h3>
      {{ $getVisibleNames("mesh.egressprofile", true, "Perfiles de Egreso") }}
    </h3>
    <div class="table-responsive">
      <GenericBTable
        :key="generic_table_key"
        :items="items_list"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="input_search"
        :filter-included-fields="filterOn"
        :filterCustom="filterCustom"
        :busy="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="'profiles-list'"
        :item_created_id="item_created_id"
        @emitChangeSearchField="changeInputSearch"
      >
        <!-- :sort-by.sync="sortBy" -->
        <template #cell(professional_title)="row">
          <div
            class="td-custom"
            :id="`div-item-${row.item.id}`"
            :class="
              row.item.id == first_profile || row.item.id == last_ep
                ? 'new-profile-container'
                : ''
            "
          >
            {{ row.item.professional_title }}
          </div>
        </template>
        <template #cell(academic_degree)="row">
          <div
            class="td-custom"
            :class="
              row.item.id == first_profile || row.item.id == last_ep
                ? 'new-profile-container'
                : ''
            "
          >
            {{ row.item.academic_degree }}
          </div>
        </template>
        <template #cell(career)="row">
          <div
            class="td-custom"
            :class="
              row.item.id == first_profile || row.item.id == last_ep
                ? 'new-profile-container'
                : ''
            "
          >
            {{ getCareerName(row.item.career) }}
          </div>
        </template>
        <template #cell(egress_profile_type)="row">
          <div
            class="td-custom"
            :class="
              row.item.id == first_profile || row.item.id == last_ep
                ? 'new-profile-container'
                : ''
            "
          >
            {{ getEgressProfileTypeName(row.item.egress_profile_type) }}
          </div>
        </template>
        <template #cell(creation_date)="row">
          <div
            class="td-custom"
            :class="
              row.item.id == first_profile || row.item.id == last_ep
                ? 'new-profile-container'
                : ''
            "
          >
            {{ row.item.creation_date | FormatToDateTime }}
          </div>
        </template>
        <template #cell(created_by)="row">
          <div
            class="td-custom"
            :class="
              row.item.id == first_profile || row.item.id == last_ep
                ? 'new-profile-container'
                : ''
            "
          >
            {{ getUserName(row.item.created_by) }}
          </div>
        </template>
        <template #cell(semester_amount)="row">
          <div
            class="td-custom"
            :class="{
              'new-profile-container': row.item.id == first_profile,
              'copy-profile-container': row.item.id == last_ep,
            }"
          >
            {{ row.item.semester_amount }}
          </div>
        </template>
        <template #cell(actions)="row">
          <div
            v-if="
              !(
                allows_crud &&
                ((user_position &&
                  [1, 2].includes(user_position.position) &&
                  [1, 2].includes(user.groups[0])) ||
                  user.is_superuser ||
                  $hasObjectPermission(
                    'mesh.faculty',
                    'change',
                    getFacultyId(row.item.career)
                  ) ||
                  $hasObjectPermission(
                    'mesh.career',
                    'change',
                    row.item.career
                  ))
              )
            "
          >
            <b-button
              size="sm"
              variant="none"
              class="p-1"
              v-b-tooltip.v-secondary="
                `Ir a ${$getVisibleNames(
                  'mesh.egressprofile',
                  false,
                  'Perfil de Egreso'
                )}`
              "
              @click="redirectToEgressProfile(row.item.id)"
              ><b-icon icon="eye" scale="1"></b-icon
            ></b-button>
          </div>
          <div v-else class="mb-0">
            <ButtonWrapper :item_id="row.item.id">
              <template v-slot:btn-slot>
                <b-button
                  v-if="
                    allows_crud &&
                    !row.item.is_closed &&
                    ((user_position &&
                      [1, 2].includes(user_position.position) &&
                      [1, 2].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.faculty',
                        'change',
                        getFacultyId(row.item.career)
                      ) ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        row.item.career
                      ))
                  "
                  size="sm"
                  variant="none"
                  @click="fetch_history(row)"
                  v-b-tooltip.v-secondary.noninteractive="'Versiones'"
                >
                  <h5 class="p-0 m-0">
                    <b-badge variant="secondary">{{
                      row.item.versions_count
                    }}</b-badge>
                  </h5>
                </b-button>
                <b-button
                  size="sm"
                  variant="none"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Ir a ${$getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    )}`
                  "
                  @click="redirectToEgressProfile(row.item.id)"
                  ><b-icon icon="eye" scale="1"></b-icon
                ></b-button>
                <b-button
                  v-can="'mesh.change_egressprofile'"
                  v-if="
                    allows_crud &&
                    !row.item.is_closed &&
                    ((user_position &&
                      [1, 2].includes(user_position.position) &&
                      [1, 2].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.faculty',
                        'change',
                        getFacultyId(row.item.career)
                      ) ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        row.item.career
                      ))
                  "
                  size="sm"
                  variant="none"
                  @click="
                    $bvModal.show(`modal-edit-egressProfile-${row.item.id}`)
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `Editar ${$getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    )}`
                  "
                >
                  <b-icon icon="pencil-square" scale="1"></b-icon
                ></b-button>
                <b-button
                  v-if="allows_crud && !row.item.is_closed && user.is_superuser"
                  size="sm"
                  variant="none"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Eliminar ${$getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    )}`
                  "
                  @click="deleteEgressProfile(row.item.id)"
                  ><b-icon icon="trash" scale="1"></b-icon
                ></b-button>
                <b-button
                  v-if="
                    allows_crud &&
                    ((user_position &&
                      [1, 2].includes(user_position.position) &&
                      [1, 2].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.faculty',
                        'change',
                        getFacultyId(row.item.career)
                      ) ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        row.item.career
                      ))
                  "
                  size="sm"
                  variant="none"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Copiar ${$getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    )}`
                  "
                  @click="copyEgressProfile(row.item.id)"
                  ><b-icon class="btn-actions" icon="files"></b-icon
                ></b-button>
                <b-button
                  v-if="
                    allows_crud &&
                    user &&
                    (user.is_superuser ||
                      $hasObjectPermission(
                        'special.close_egress_profile',
                        'special',
                        row.item.id
                      ))
                  "
                  size="sm"
                  variant="none"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Cambiar el estado del ${$getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    )}`
                  "
                  @click="changeStateEgressProfile(row.item)"
                >
                  <template v-if="row.item.is_closed">
                    <b-icon icon="lock" scale="1"></b-icon>
                  </template>
                  <template v-else>
                    <b-icon icon="unlock" scale="1"></b-icon>
                  </template>
                </b-button>
              </template>
            </ButtonWrapper>
          </div>
          <b-modal
            :id="`modal-edit-egressProfile-${row.item.id}`"
            hide-footer
            :title="`Editar ${$getVisibleNames(
              'mesh.egressprofile',
              false,
              'Perfil de Egreso'
            )}`"
            size="lg"
          >
            <EgressProfileForm
              :EgressProfile="row.item"
              :career_id="row.item.career"
              :show_delete_button="false"
              @updated="updateEgressProfile"
            ></EgressProfileForm>
          </b-modal>
          <b-modal
            :id="`modal-copy-egress-profile-${row.item.id}`"
            :title="`Duración del ${$getVisibleNames(
              'mesh.egressprofile',
              false,
              'Perfil de Egreso'
            )}: ${row.item.semester_amount}`"
            size="xl"
            hide-footer
          >
            <template
              v-if="
                courses_wrapper.filter((x) => x.egress_profile == row.item.id)
                  .length > 0
              "
            >
              <CoursesWrapperView
                :semester_amount="
                  row.item.semester_amount == '' ||
                  row.item.semester_amount == null
                    ? 0
                    : row.item.semester_amount
                "
                :egress_profile_id="row.item.id"
                :allows_crud="true"
                :matrix2_view="false"
                :copy_egress_profile_view="true"
                @list_courses_wrapper="updateCoursesWrapperView"
              ></CoursesWrapperView>
            </template>
            <template
              v-if="
                egressProfileMatters.filter(
                  (x) => x.egress_profile == row.item.id
                ).length > 0
              "
            >
              <RemoveEPMattersComponent
                :semester_amount="
                  row.item.semester_amount == '' ||
                  row.item.semester_amount == null
                    ? 0
                    : row.item.semester_amount
                "
                :egress_profile_id="row.item.id"
                :allows_crud="true"
                :matrix2_view="false"
                :copy_egress_profile_view="true"
                @list_matters="updateRemoveEPMattersComponent"
              >
              </RemoveEPMattersComponent>
            </template>
            <div class="d-flex">
              <div style="margin-left: 0; margin-right: auto">
                <b-button
                  size="sm"
                  class="ml-3"
                  @click="
                    $bvModal.hide(`modal-copy-egress-profile-${row.item.id}`)
                  "
                >
                  Cancelar
                </b-button>
              </div>
              <div style="margin-left: auto; margin-right: 0">
                <template
                  v-if="
                    list_courses_wrapper.filter(
                      (x) =>
                        (x.init_level > row.item.semester_amount ||
                          x.end_level < x.init_level ||
                          x.end_level > row.item.semester_amount ||
                          x.init_level == '' ||
                          x.init_level <= 0 ||
                          x.end_level == '' ||
                          x.end_level <= 0) &&
                        x.egress_profile == row.item.id
                    ).length == 0 &&
                    list_matters.filter(
                      (x) =>
                        (x.plan_level > row.item.semester_amount ||
                          x.plan_level == '' ||
                          x.plan_level <= 0) &&
                        x.egress_profile == row.item.id
                    ).length == 0
                  "
                >
                  <b-button
                    class="mr-1"
                    size="sm"
                    @click="removeMattersAndCoursesWrapper(row.item.id)"
                    >Copiar
                    {{
                      $getVisibleNames(
                        "mesh.egressprofile",
                        false,
                        "Perfil de Egreso"
                      )
                    }}</b-button
                  >
                </template>
                <template v-else>
                  <b-button class="mr-1" size="sm" disabled
                    >Copiar
                    {{
                      $getVisibleNames(
                        "mesh.egressprofile",
                        false,
                        "Perfil de Egreso"
                      )
                    }}</b-button
                  >
                </template>
              </div>
            </div>
          </b-modal>
        </template>
        <template v-if="allows_crud" #row-details="row">
          <b-card>
            <b-table
              thead-class="th-style"
              borderless
              bordered
              :busy="isBusy"
              :items="filterHistory(row.item.id)"
              :fields="fields_history"
              style="background-color: #efefef"
            >
              <template #cell(date_time)="cell">
                {{ cell.item.date_time | FormatToDateTime }}
              </template>
              <template #cell(comment)="cell">
                <div class="row">
                  <div class="col">
                    <b-form-textarea
                      id="input-comments"
                      v-model="cell.item.comment"
                      size="sm"
                    ></b-form-textarea>
                  </div>
                  <b-button
                    v-can="'reversion.change_revision'"
                    size="sm"
                    variant="none"
                    @click="
                      saveComment(cell.item.revision_id, cell.item.comment)
                    "
                  >
                    <b-icon-box-arrow-in-down
                      v-b-tooltip.v-secondary="'Guardar comentario'"
                    ></b-icon-box-arrow-in-down>
                  </b-button>
                </div>
              </template>
              <template #cell(active)="cell">
                <div
                  v-if="ActiveEgressProfile(cell.item.revision_id, row.item)"
                >
                  <b-icon
                    icon="check-circle-fill"
                    scale="1"
                    variant="primary"
                  ></b-icon>
                </div>
                <div v-else>
                  <b-icon icon="x-circle-fill" scale="1"></b-icon>
                </div>
              </template>
              <template #cell(actions)="cell">
                <b-button
                  size="sm"
                  @click="
                    info(
                      filterHistoryEgressProfile(cell.item.id),
                      cell.item,
                      $event.target
                    )
                  "
                  class="mr-1"
                >
                  Ver modificación
                </b-button>
              </template>
            </b-table>
          </b-card>
        </template>
      </GenericBTable>
    </div>
    <b-modal :id="infoModal.id" size="xl" hide-footer @hide="resetInfoModal">
      <template v-if="infoModal.title" #modal-title>
        Fecha de modificación:
        {{ infoModal.title.date_time | FormatToDateTime }}</template
      >
      <div v-if="infoModal.content">
        <div>
          <EgressProfile
            :egress_profile_id="infoModal.content.id"
            :allows_crud="false"
          ></EgressProfile>
        </div>
      </div>
      <div>
        <b-button
          size="sm"
          variant="danger"
          v-can="'reversion.delete_revision'"
          @click="deleteChanges(infoModal.title, infoModal.content)"
        >
          Eliminar modificación
        </b-button>
        <b-button
          size="sm"
          class="float-right"
          @click="$bvModal.hide('info-modal-egress-profile')"
        >
          Cancelar
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          class="float-right mr-5"
          v-can="'reversion.change_revision'"
          @click="applyChanges(infoModal.title, infoModal.content)"
        >
          Aplicar modificación
        </b-button>
      </div>
    </b-modal>
    <div>
      <b-modal
        id="modal-new-egress-profile"
        :title="`Crear ${$getVisibleNames(
          'mesh.egressprofile',
          false,
          'Perfil de Egreso'
        )}`"
        size="lg"
        hide-footer
      >
        <b-form-group
          :label="`${$getVisibleNames('mesh.career', false, 'Programa')}`"
          label-for="sort-by-select"
          label-cols-sm="3"
          label-align-sm="left"
          label-size="sm"
          class="m-0"
          v-slot="{ ariaDescribedby }"
        >
          <div class="d-flex">
            <div class="d-block w-100">
              <b-input-group size="sm">
                <b-form-select
                  id="sort-by-select"
                  v-model="career_id"
                  :options="careers"
                  value-field="id"
                  text-field="name"
                  @change="forceRerender"
                  :aria-describedby="ariaDescribedby"
                  class="w-75"
                >
                </b-form-select>
              </b-input-group>
            </div>
            <button
              class="btn-add"
              v-b-tooltip.v-secondary.noninteractive="
                `Crear ${$getVisibleNames('mesh.career', false, 'Programa')}.`
              "
              @click="$bvModal.show('new-career-modal')"
            >
              <b-icon font-scale="1" icon="plus-square"></b-icon>
            </button>
          </div>
        </b-form-group>
        <EgressProfileForm
          v-if="career_id"
          :key="componentKey"
          :career_id="career_id"
          @created="slotEgressProfileCreated"
        ></EgressProfileForm>
      </b-modal>
      <b-modal
        id="new-career-modal"
        :title="`Crear ${$getVisibleNames('mesh.career', false, 'Programa')}`"
        size="md"
        hide-footer
      >
        <CareerForm
          @created="slotCreatedCareer"
          :show_title="false"
        ></CareerForm>
      </b-modal>
    </div>
  </b-container>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "../../../utils/utils";

export default {
  name: "EgressProfilesList",
  components: {
    EgressProfileForm: () =>
      import("@/components/mesh/EgressProfile/EgressProfileForm"),
    EgressProfile: () =>
      import("@/components/mesh/EgressProfile/EgressProfile"),
    ButtonWrapper: () => import("@/components/reusable/ButtonWrapper"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    CareerForm: () =>
      import("@/components/mesh/CurricularResources/Career/CareerForm"),
    CoursesWrapperView: () =>
      import("@/components/mesh/Matrix2/CoursesWrapper/CoursesWrapperView"),
    RemoveEPMattersComponent: () =>
      import("@/components/mesh/Matrix2/RemoveEPMattersComponent"),
  },
  props: {
    // input_search: {
    //   type: String,
    //   default: "",
    // },
  },
  data() {
    return {
      input_search: "",
      items_list: [],
      fields_history: [
        {
          key: "date_time",
          label: "Fecha de modificación",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "user_id",
          label: "Usuario",
          sortable: true,
          formatter: (value) => {
            return this.getUserName(value);
          },
        },
        {
          key: "comment",
          label: "Comentario",
          sortable: true,
        },
        {
          key: "active",
          label: "Aplicado",
          sortable: true,
          sortDirection: "desc",
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "actions",
          label: "Vista",
          thClass: "noprint",
          tdClass: "noprint",
        },
      ],
      first_profile: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 1000,
      pageOptions: [5, 10, 15, { value: 1000, text: "Mostrar Todos" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      isBusy: false,
      componentKey: 0,
      infoModal: {
        id: "info-modal-egress-profile",
        title: null,
        content: null,
      },
      list_matters: [],
      remove_matters: [],
      list_courses_wrapper: [],
      remove_courses_wrapper: [],
      egress_profiles_history: [],
      history_data: [],
      career_id: null,
      last_ep: null,
      item_created_id: null,
      generic_table_key: 0,
      faculties_areas: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      user_position: names.USER_POSITION,
      users: names.USERS,
      careers: names.CAREERS,
      competences: names.COMPETENCES,
      egressProfiles: names.EGRESS_PROFILES,
      profileCompetences: names.PROFILE_COMPETENCES,
      regimes: names.REGIMES,
      profile_types: names.PROFILE_TYPES,
      courses_wrapper: names.COURSES_WRAPPER,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_egressprofile");
      if (has_permission) return has_permission;
      else return false;
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    breadcrumb_items() {
      return [
        { text: "Productos Curriculares", active: true },
        {
          text: this.$getVisibleNames(
            "mesh.egressprofile",
            true,
            "Perfiles de Egreso"
          ),
          active: true,
        },
      ];
    },
    fields() {
      return [
        {
          key: "career",
          label: String(
            this.$getVisibleNames("mesh.career", false, "Programa")
          ),
          sortable: true,
          formatter: (value) => {
            return this.getCareerName(value);
          },
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        {
          key: "faculty_area",
          label: String(
            this.$getVisibleNames("mesh.facultyarea", false, "Área de Facultad")
          ),
          sortable: true,
          formatter: (value) => {
            const area = this.faculties_areas.find((x) => x.id == value);
            return area ? area.name : "";
          },
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        {
          key: "professional_title",
          label: String(
            this.$getVisibleNames(
              "manual.mesh.titulo_profesional",
              false,
              "Título Profesional"
            )
          ),
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        !(
          this.institution.id == 12 ||
          this.$debug_change_duoc ||
          !this.institution.show_academic_degree
        )
          ? {
              key: "academic_degree",
              label: String(
                this.$getVisibleNames(
                  "manual.grado_academico",
                  false,
                  "Grado Académico"
                )
              ),
              sortable: true,
              display_column: true,
              thStyle: { background: "var(--primary-color) !important" },
              tdClass: "text-left",
            }
          : { key: "academic_degree", label: "", display_column: false },
        {
          key: "egress_profile_type",
          label: "Tipo",
          sortable: true,
          formatter: (value) => {
            return this.getEgressProfileTypeName(value);
          },
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        {
          key: "creation_date",
          label: "Creación",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "created_by",
          label: "Usuario",
          sortable: true,
          formatter: (value) => {
            return this.getUserName(value);
          },
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        // {
        //   key: "is_closed",
        //   label: "Estado",
        //   sortable: true,
        //   formatter: (value) => {
        //     return value ? "Cerrado" : "En proceso";
        //   },
        // },
        {
          key: "regime",
          label: this.$getVisibleNames("mesh.matterregime", false, "Régimen"),
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          formatter: (value) => {
            return this.getRegimenName(value);
          },
        },
        {
          key: "semester_amount",
          label: "Duración",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        // {
        //   key: "active",
        //   label: "Aplicado",
        //   sortable: true,
        //   sortDirection: "desc",
        //   sortByFormatted: true,
        //   filterByFormatted: true,
        // },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(row.professional_title, this.input_search) ||
        this.$filtered(row.academic_degree, this.input_search) ||
        this.$filtered(
          this.getFacultyAreaName(row.faculty_area),
          this.input_search
        ) ||
        this.$filtered(this.getCareerName(row.career), this.input_search) ||
        this.$filtered(this.getRegimenName(row.regime), this.input_search) ||
        this.$filtered(row.semester_amount, this.input_search) ||
        this.$filtered(
          row.creation_date
            ? this.$moment(row.creation_date).format("DD/MM/YYYY HH:mm")
            : "",
          this.input_search
        ) ||
        this.$filtered(this.getUserName(row.created_by), this.input_search) ||
        this.$filtered(
          this.getEgressProfileTypeName(row.egress_profile_type),
          this.input_search
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    toggleDetails(row) {
      if (row.detailsShowing) {
        row.toggleDetails();
      } else {
        this.items_list.forEach((item) => {
          this.$set(item, "_showDetails", false);
        });
        this.$nextTick(() => {
          row.toggleDetails();
        });
      }
    },
    fetch_history(row) {
      this.toggleDetails(row);
      if (
        this.egress_profiles_history.filter(
          (x) => x.egress_profile == row.item.id
        ).length == 0
      )
        this.$restful
          .Get(
            `/mesh/egress_profile_history_metadata_single/?id=${row.item.id}`
          )
          .then((history) => {
            this.egress_profiles_history.push({
              data: history.data,
              egress_profile: row.item.id,
            });
            history.data.forEach((data) => {
              this.$restful
                .Get(`/common/history_data_single/?id=${data.id}`)
                .then((data_egress_profile) => {
                  this.history_data.push({
                    data: data_egress_profile.data,
                    history_id: data.id,
                  });
                });
            });
          });
    },
    slotCreatedCareer(career) {
      this.career_id = career.id;
      this.forceRerender();
      this.$bvModal.hide("new-career-modal");
    },
    changeStateEgressProfile(egress_profile) {
      const payload = {
        egress_profile_id: egress_profile.id,
        item: {
          is_closed: !egress_profile.is_closed,
        },
      };
      this.$store
        .dispatch(names.PATCH_EGRESS_PROFILE, payload)
        .then((response) => {
          this.resetTable(response, false);
        });
    },
    ActiveEgressProfile(history_data_single_id, egress_profile) {
      let history_data = this.filterHistoryEgressProfile(
        history_data_single_id
      );
      if (history_data != null) {
        if (
          history_data.academic_degree == egress_profile.academic_degree &&
          history_data.professional_title ==
            egress_profile.professional_title &&
          history_data.init_date == egress_profile.init_date &&
          history_data.end_date == egress_profile.end_date &&
          history_data.semester_amount == egress_profile.semester_amount &&
          history_data.comments == egress_profile.comments &&
          history_data.active == egress_profile.active &&
          history_data.is_closed == egress_profile.is_closed &&
          history_data.egress_profile_type ==
            egress_profile.egress_profile_type &&
          history_data.career_id == egress_profile.career &&
          JSON.stringify(history_data.campuses) ===
            JSON.stringify(egress_profile.campuses) &&
          JSON.stringify(history_data.cycles) ===
            JSON.stringify(egress_profile.cycles)
        )
          return true;
      }
      return false;
    },
    info(egress_profile, history, button) {
      this.infoModal.title = history;
      this.infoModal.content = egress_profile;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    filterHistoryEgressProfile(id) {
      const egress_profile = this.history_data.find(
        (item) => item.history_id == id
      );
      return egress_profile ? egress_profile.data : null;
    },
    filterHistory(id) {
      const egress_profile = this.egress_profiles_history.find(
        (item) => item.egress_profile == id
      );
      return egress_profile ? egress_profile.data : null;
    },
    resetInfoModal() {
      this.infoModal.title = null;
      this.infoModal.content = null;
    },
    getUserName(id) {
      const user = this.users.find((user) => user.id == id);
      return user ? user.first_name + " " + user.last_name : "-";
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getFacultyId(career_id) {
      const career = this.careers.find((x) => x.id == career_id);
      return career ? career.faculty : null;
    },
    getCareerName(career_id) {
      const career = this.careers.find((x) => x.id == career_id);
      return career ? career.name : "-";
    },
    getFacultyAreaName(area_id) {
      const area = this.faculties_areas.find((x) => x.id == area_id);
      return area ? area.name : "-";
    },
    getRegimenName(regime_id) {
      const regime = this.regimes.find((x) => x.id == regime_id);
      return regime ? regime.name : "-";
    },
    getEgressProfileTypeName(egress_profile_type) {
      const type = this.profile_types.find((x) => x.id == egress_profile_type);
      return type ? type.name : "-";
    },
    saveComment(revision_id, comment) {
      this.$restful
        .Get(
          `/common/revision_set_comment/?id=${revision_id}&comment=${comment}`
        )
        .then(() => {
          toast("Comentario guardado.");
        });
    },
    resetTable(object, created) {
      this.isBusy = true;
      const index = this.items_list.findIndex((x) => x.id == object.id);
      if (created) {
        this.items_list.push(object);
        this.$restful
          .Get(`/mesh/egress_profile_history_metadata_single/?id=${object.id}`)
          .then((history_list) => {
            this.egress_profiles_history.push({
              data: history_list.data,
              egress_profile: object.id,
            });
            this.$restful
              .Get(`/common/history_data_single/?id=${history_list.data[0].id}`)
              .then((data_egress_profile) => {
                this.history_data.push({
                  data: data_egress_profile.data,
                  history_id: history_list.data[0].id,
                });
              });
          });
      } else if (index != -1) {
        this.items_list.splice(index, 1);
        this.items_list.push(object);
        const index_history_list = this.egress_profiles_history.findIndex(
          (x) => x.egress_profile == object.id
        );
        if (index_history_list != -1) {
          this.egress_profiles_history.splice(index_history_list, 1);
          this.$restful
            .Get(
              `/mesh/egress_profile_history_metadata_single/?id=${object.id}`
            )
            .then((history_list) => {
              this.egress_profiles_history.push({
                data: history_list.data,
                egress_profile: object.id,
              });
              this.$restful
                .Get(
                  `/common/history_data_single/?id=${history_list.data[0].id}`
                )
                .then((data_egress_profile) => {
                  this.history_data.push({
                    data: data_egress_profile.data,
                    history_id: history_list.data[0].id,
                  });
                });
            });
        }
      }
      this.items_list.sort(function (a, b) {
        if (a.id < b.id) return 1;
        if (a.id > b.id) return -1;
        return 0;
      });
      this.generic_table_key += 1;
      this.isBusy = false;
    },
    updateEgressProfile(egress_profile) {
      this.$bvModal.hide(`modal-edit-egressProfile-${egress_profile.id}`);
      this.resetTable(egress_profile, false);
    },
    slotEgressProfileCreated(egress_profile) {
      this.$bvModal.hide(`modal-new-egress-profile`);
      this.resetTable(egress_profile, true);
      // this.last_ep = egress_profile.id;
      setTimeout(() => {
        this.item_created_id = egress_profile.id;
      }, 500);
    },
    copyEgressProfile(id) {
      this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
        egress_profile_id: id,
      });
      this.$store.dispatch(names.FETCH_MATTERS, {
        egress_profile_id: id,
      });
      this.$store.dispatch(names.FETCH_COURSES_WRAPPER, {
        egress_profile_id: id,
      });
      this.$bvModal.show(`modal-copy-egress-profile-${id}`);
    },
    updateRemoveEPMattersComponent(list_matters, remove_matters) {
      this.list_matters = list_matters;
      this.remove_matters = remove_matters;
    },
    updateCoursesWrapperView(list_courses_wrapper, remove_courses_wrapper) {
      this.list_courses_wrapper = list_courses_wrapper;
      this.remove_courses_wrapper = remove_courses_wrapper;
    },
    async removeMattersAndCoursesWrapper(egress_profile_id) {
      this.$swal.fire({
        title: "Cargando...",
        onBeforeOpen: () => {
          this.$bvModal.hide(`modal-copy-egress-profile-${egress_profile_id}`);
          this.$swal.showLoading();
        },
      });
      try {
        await this.$restful
          .Post(`/mesh/copy_egress_profile/`, {
            egress_profile_matters_to_add: this.list_matters.map((x) => ({
              id: x.id,
              plan_level: x.plan_level,
            })),
            course_wrappers_to_add: this.list_courses_wrapper.map((x) => ({
              id: x.id,
              init_level: x.init_level,
              end_level: x.end_level,
            })),
            egress_profile: egress_profile_id,
          })
          .then((response) => {
            this.$swal
              .fire({
                title: "Éxito",
                text: `La petición fue exitosa.`,
                type: "success",
                showConfirmButton: false,
              })
              .then(() => {
                this.$store
                  .dispatch(names.FETCH_EGRESS_PROFILE, response.id)
                  .then((egress_profile) => {
                    this.items_list.push(egress_profile);
                    this.first_profile = egress_profile.id;
                    setTimeout(() => {
                      this.first_profile = null;
                    }, 2500);
                    this.sortBy = "professional_title";
                    this.item_created_id = egress_profile.id;
                  });
              });
          });
      } catch (error) {
        this.$swal.fire(
          "Error",
          "Ocurrió un error al realizar la petición",
          "warning"
        );
      }
    },
    deleteEgressProfile(id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.egressprofile",
          false,
          "Perfil de Egreso"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          const index = this.items_list.findIndex((x) => x.id == id);
          if (index != -1) {
            this.$store.dispatch(names.DELETE_EGRESS_PROFILE, id).then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.egressprofile",
                    false,
                    "Perfil de Egreso"
                  ) + " eliminado."
                )
              );
              this.items_list.splice(index, 1);
            });
          }
        }
      });
    },
    applyChanges(history_data, history_details) {
      this.$swal
        .fire({
          title: "¿Seguro que quiere aplicar esta modificación? ",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Guardar",
        })
        .then((result) => {
          if (result.value) {
            this.isBusy = true;
            this.$bvModal.hide(this.infoModal.id);
            const payload = {
              egress_profile_id: history_details.id,
              item: {
                academic_degree: history_details.academic_degree,
                professional_title: history_details.professional_title,
                init_date: history_details.init_date,
                end_date: history_details.end_date,
                semester_amount: history_details.semester_amount,
                comments: history_details.comments,
                active: history_details.active,
                egress_profile_type: history_details.egress_profile_type,
                career: history_details.career_id,
                campuses: history_details.campuses,
                cycles: history_details.cycles,
              },
            };
            this.$store
              .dispatch(names.PATCH_EGRESS_PROFILE, payload)
              .then((response) => {
                this.$swal.fire("Modificación actualizada!", "", "success");
                const index = this.items_list.findIndex(
                  (x) => x.id == history_details.id
                );
                if (index != -1) {
                  this.items_list.splice(index, 1);
                  this.items_list.push(response);
                  const index_history_list =
                    this.egress_profiles_history.findIndex(
                      (x) => x.egress_profile == response.id
                    );
                  if (index_history_list != -1) {
                    this.egress_profiles_history.splice(index_history_list, 1);
                    this.$restful
                      .Get(
                        `/mesh/egress_profile_history_metadata_single/?id=${response.id}`
                      )
                      .then((history_list) => {
                        history_list.data[0].comment = `Revertido a la modificación anterior, guardada el: ${this.$moment(
                          history_data.date_time
                        ).format("DD/MM/YYYY HH:mm")}`;
                        this.saveComment(
                          history_list.data[0].revision_id,
                          history_list.data[0].comment
                        );
                        this.egress_profiles_history.push({
                          data: history_list.data,
                          egress_profile: response.id,
                        });
                        this.$restful
                          .Get(
                            `/common/history_data_single/?id=${history_list.data[0].id}`
                          )
                          .then((data_egress_profile) => {
                            this.history_data.push({
                              data: data_egress_profile.data,
                              history_id: history_list.data[0].id,
                            });
                          });
                      });
                  }
                }
              });
            this.isBusy = false;
          }
        });
    },
    deleteChanges(history_data_modal, history_details) {
      this.$swal
        .fire({
          title: "¿Seguro que quiere eliminar esta modificación? ",
          text: "Este cambio es irreversible.",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Eliminar",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire("Modificación eliminada!", "", "success");
            this.isBusy = true;
            this.$bvModal.hide(this.infoModal.id);
            const index = this.egress_profiles_history.findIndex(
              (x) => x.egress_profile == history_details.id
            );
            if (index != -1) {
              const list_index = this.egress_profiles_history[
                index
              ].data.findIndex((x) => x.id == history_data_modal.id);
              if (list_index != -1) {
                const list_history_index = this.history_data.findIndex(
                  (x) => x.history_id == history_data_modal.id
                );
                this.egress_profiles_history[index].data.splice(list_index, 1);
                if (list_history_index != -1) {
                  this.history_data.splice(list_history_index, 1);
                  this.$restful.Get(
                    `/common/single_history_delete/?id=${history_data_modal.id}`
                  );
                }
              }
            }
            this.isBusy = false;
          }
        });
    },
    fetchEgressProfiles() {
      this.items_list.splice(0, this.items_list.length);
      this.egress_profiles_history.splice(
        0,
        this.egress_profiles_history.length
      );
      this.history_data.splice(0, this.history_data.length);
      this.$store.dispatch(names.FETCH_EGRESS_PROFILES).then((response) => {
        response.forEach((element) => {
          this.$store.dispatch(names.FETCH_CAREER, element.career);
          this.career_id = element.career;
          this.items_list.push(element);
        });
      });
      this.isBusy = false;
    },
    redirectToEgressProfile(id) {
      let route_data = this.$router.resolve({
        name: "EgressProfileView",
        params: { egress_profile_id: id },
      });
      window.open(route_data.href, "_blank");
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    fetchFacultiesAreas() {
      this.$restful.Get(`/mesh/faculty-area/`).then((response) => {
        this.faculties_areas = response;
      });
    },
  },
  watch: {
    item_created_id() {
      this.last_ep = this.item_created_id;
      setTimeout(() => {
        this.item_created_id = null;
      }, 2500);
    },
  },
  created() {
    this.isBusy = true;
    this.sortBy = "professional_title";
    this.$store.dispatch(names.FETCH_REGIMES);
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_USERS); // TODO: Esta petición hay que optimizarla.
    this.$store.dispatch(names.FETCH_MODALITIES_EGRESS_PROFILE);
    this.$store.dispatch(names.FETCH_PROFILE_TYPES);
    this.fetchEgressProfiles();
    this.fetchFacultiesAreas();
  },
  mounted() {
    this.totalRows = this.items_list.length;
  },
};
</script>

<style scoped>
.edit-button {
  margin-right: 10px;
}
.modal th {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
  font-weight: normal;
}
p {
  text-align: justify;
  margin-left: 2px;
  padding-left: 12px;
  margin-right: 2px;
  padding-right: 12px;
  font-size: var(--secondary-font-size);
}
td[colspan="5"] {
  max-width: 35rem;
}
td {
  vertical-align: middle;
  text-align: center;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  width: auto;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.table-responsive >>> .generic-table {
  overflow: inherit;
}
.edit-section {
  margin-top: 1.8em;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
  margin-top: -9px !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1 !important;
  content: "❯ " !important;
  padding: 0;
  padding-right: 0.65em;
}
.new-profile-container {
  animation: blinker 1s linear 7;
  /* background: var(--primary-color); */
}
.td-custom {
  padding: 0.7rem;
}
/* .td-custom.new-profile-container {
  background: var(--primary-color);
} */
.table-responsive >>> .table td {
  vertical-align: middle;
}
.btn-add {
  border: none;
  background: transparent;
  font-size: 13pt;
  padding: 0;
  margin-left: 0.7rem;
}
@keyframes blinker {
  50% {
    opacity: 0;
    background: var(--primary-color);
  }
}
</style>