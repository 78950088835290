<template>
  <div class="text-center">
    <h4 class="mt-4">
      {{
        $getVisibleNames(
          "manual.matter_program",
          false,
          "Programa de la Asignatura"
        ).toUpperCase()
      }}
      <template v-if="matter">
        <template v-if="allows_crud">
          <!-- (allows_crud && matter.use_matter_values) ||
            (allows_crud && !matter.use_matter_values && !matter.is_closed) -->
          <button-edit
            v-can="'teaching.change_matter'"
            @click="$bvModal.show(`modal-edit-matter-${matter.id}`)"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.egressprofilematter',
                false,
                'Asignatura'
              )}`
            "
          ></button-edit>
        </template>
        <b-modal
          :id="`modal-edit-matter-${matter.id}`"
          hide-footer
          :title="`Editar ${$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )}`"
          size="lg"
        >
          <MatterForm
            :Matter="matter"
            :egress_profile_id="matter.egress_profile"
            :show_delete_button="false"
            @update="forceRerenderMatterPresentation"
            @updated="forceRerenderMatterPresentation"
          ></MatterForm>
        </b-modal>
      </template>
      <!-- <template v-if="isClosedOldMatter">
        <b-button
          size="sm"
          variant="none"
          v-b-tooltip.v-secondary.noninteractive="
            `Cambiar el estado del ${$getVisibleNames(
              'teaching.matter',
              false,
              'Asignatura Base'
            )}`
          "
          @click="changeStateOldMatter()"
        >
          <template v-if="oldMatter && oldMatter.is_closed">
            <b-icon icon="lock-fill" scale="1"></b-icon>
          </template>
          <template v-else>
            <b-icon icon="unlock-fill" scale="1"></b-icon>
          </template>
        </b-button>
      </template> -->

      <!-- <template v-if="isClosedMatter">
        <b-button
          class="noprint"
          size="sm"
          variant="none"
          v-b-tooltip.v-secondary.noninteractive="
            `Cambiar el estado de la ${$getVisibleNames(
              'mesh.egressprofilematter',
              false,
              'Asignatura'
            )}`
          "
          @click="changeStateMatter()"
        >
          <template v-if="matter && matter.is_closed">
            <b-icon class="btn-actions" icon="lock" scale="1"></b-icon>
          </template>
          <template v-else>
            <b-icon class="btn-actions" icon="unlock" scale="1"></b-icon>
          </template>
        </b-button>
      </template> -->
    </h4>
    <MatterPresentation
      :key="componentKey"
      v-if="matter"
      :Matter="matter"
      :allows_crud="allows_crud"
    ></MatterPresentation>
    <template
      v-if="(institution && institution.id == 12) || $debug_change_duoc"
    >
      <div class="learning-suitcase-buttons noprint">
        <b-button
          class="learning-suitcase-btn"
          size="sm"
          @click="redirectToLearningSuitcase(matter.id)"
          ><b-icon icon="box-arrow-up-right"></b-icon> PIA SEDE</b-button
        >
        <b-button
          class="learning-suitcase-btn ml-1"
          size="sm"
          @click="redirectToLearningSuitcaseStudent(matter.id)"
          ><b-icon icon="box-arrow-up-right"></b-icon> PA ESTUDIANTE</b-button
        >
        <b-button
          class="learning-suitcase-btn ml-1"
          size="sm"
          @click="redirectToLearningSuitcaseTeacher(matter.id)"
          ><b-icon icon="box-arrow-up-right"></b-icon> PDA DOCENTE</b-button
        >
        <b-button
          class="learning-suitcase-btn ml-1"
          size="sm"
          @click="redirectToLearningExperience(matter.id)"
          ><b-icon icon="box-arrow-up-right"></b-icon> EXPERIENCIAS DE
          APRENDIZAJE</b-button
        >
      </div>
      <!-- <div class="learning-suitcase-buttons noprint">
        <div
          v-b-tooltip.v-secondary.noninteractive="
            'Deshabilitado temporalmente, en proceso de actualización.'
          "
        >
          <b-button class="learning-suitcase-btn" size="sm" disabled
            ><b-icon icon="box-arrow-up-right"></b-icon> PIA SEDE</b-button
          >
        </div>
        <div
          v-b-tooltip.v-secondary.noninteractive="
            'Deshabilitado temporalmente, en proceso de actualización.'
          "
        >
          <b-button class="learning-suitcase-btn ml-1" size="sm" disabled
            ><b-icon icon="box-arrow-up-right"></b-icon> PA ESTUDIANTE</b-button
          >
        </div>
        <div
          v-b-tooltip.v-secondary.noninteractive="
            'Deshabilitado temporalmente, en proceso de actualización.'
          "
        >
          <b-button class="learning-suitcase-btn ml-1" size="sm" disabled
            ><b-icon icon="box-arrow-up-right"></b-icon> PDA DOCENTE</b-button
          >
        </div>
        <div>
          <b-button
            class="learning-suitcase-btn ml-1"
            size="sm"
            @click="redirectToLearningExperience(matter.id)"
            ><b-icon icon="box-arrow-up-right"></b-icon> EXPERIENCIAS DE
            APRENDIZAJE</b-button
          >
        </div>
      </div> -->
    </template>
    <!-- <b-modal
      id="learning-experiences-modal"
      title="Experiencias de Aprendizaje"
      size="xl"
      hide-footer
    >
      <LearningExperiences
        :egress_profile_matter_id="egress_profile_matter_id"
        @close="$bvModal.hide('learning-experiences-modal')"
      ></LearningExperiences>
    </b-modal> -->
    <template v-if="matter && !matter.use_matter_values">
      <DescriptorsContainer
        :ref_component="'EPMatter'"
        :Title_text="'Programas'"
        :Text_info_tooltip="'Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros Programas.'"
        :Title="egressProfileMatterDescriptor"
        :Descriptors="epmMiddleDescriptors"
        :allows_crud="allows_crud && matter && !matter.is_closed"
        :Order="1"
        :VisibleOrder="false"
        :ProgramView="true"
        @updateOrder="updateOrderEpmMiddleDescriptors"
        @updateState="updateVisibleEpmMiddleDescriptors"
        @createDescription="createEpmMiddleDescriptors"
        @updateDescription="updateEpmMiddleDescriptors"
        @createTitle="createProfileMatterDescriptors"
        @updateTitle="updateProfileMatterDescriptors"
        @deleteDescriptors="deleteProfileMatterDescriptors"
        @deleteTitle="deleteTitle"
      >
      </DescriptorsContainer>
    </template>
    <template v-else>
      <DescriptorsContainer
        :ref_component="'Matter'"
        :Title_text="'Programas'"
        :Text_info_tooltip="'Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros Programas.'"
        :Title="egressProfileMatterDescriptor"
        :Descriptors="matterMiddleDescriptors"
        :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
        :Order="1"
        :VisibleOrder="false"
        :ProgramView="true"
        @updateOrder="updateOrderMatterMiddleDescriptors"
        @updateState="updateVisibleMatterMiddleDescriptors"
        @createDescription="createMatterMiddleDescriptors"
        @updateDescription="updateMatterMiddleDescriptors"
        @createTitle="createProfileMatterDescriptors"
        @updateTitle="updateProfileMatterDescriptors"
        @deleteDescriptors="deleteMatterMiddleDescriptors"
        @deleteTitle="deleteTitle"
      >
      </DescriptorsContainer>
    </template>
    <table>
      <tbody>
        <!-- <tr>
          <th colspan="10" rowspan="1">DESCRIPCIÓN</th>
        </tr>
        <tr>
          <td colspan="10" rowspan="1">
            <div class="wrapper" v-if="oldMatter && matter">
              <div v-html="oldMatter.description"></div>
              <div v-if="allows_crud">
                <button-edit
                  tabindex="-1"
                  class="w-30"
                  @click="
                    $bvModal.show(`edit-description-modal-${oldMatter.id}`)
                  "
                  v-b-tooltip.v-secondary.noninteractive="'Editar Descripción'"
                >
                </button-edit>
                <b-modal
                  :id="`edit-description-modal-${oldMatter.id}`"
                  title="Editar Descripción de la Asignatura"
                  size="lg"
                  hide-footer
                >
                  <RichTextEditor
                    :Object="oldMatter"
                    :Text="oldMatter.description"
                    @update="updateDescription"
                    @close="
                      $bvModal.hide(`edit-description-modal-${oldMatter.id}`)
                    "
                  ></RichTextEditor>
                </b-modal>
              </div>
            </div>
          </td>
        </tr> -->
        <tr class="tr-competences">
          <th colspan="10" rowspan="1">
            <div class="d-flex">
              <div
                class="mx-auto"
                :class="{
                  'competences-header':
                    allows_crud && matter && !matter.is_closed,
                }"
              >
                <span>
                  {{
                    $getVisibleNames(
                      "mesh.competence",
                      true,
                      "Competencias"
                    ).toUpperCase()
                  }}
                  A LAS QUE TRIBUTA LA
                  {{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      false,
                      "Asignatura"
                    ).toUpperCase()
                  }}
                </span>
              </div>
              <b-button
                v-if="allows_crud && matter && !matter.is_closed"
                size="sm"
                class="btn-edit-competences"
                @click="$bvModal.show(`modal-select-competence-${matter.id}`)"
                >Editar
                {{
                  $getVisibleNames("mesh.competence", true, "Competencias")
                }}</b-button
              >
            </div>
          </th>
        </tr>
        <tr
          v-for="competence_type in competenceTypes"
          :key="competence_type.id"
        >
          <td
            colspan="10"
            rowspan="1"
            v-if="
              matterCompetences.length > 0 &&
              competenceList.filter(
                (x) =>
                  x.type == competence_type.id &&
                  matterCompetences.includes(x.id)
              ).length > 0
            "
          >
            <div class="wrapper">
              <div>
                <strong :title="competence_type.description">{{
                  competence_type.name.toUpperCase()
                }}</strong>
              </div>
              <div
                v-for="competence in competenceList.filter(
                  (x) =>
                    x.type == competence_type.id &&
                    matterCompetences.includes(x.id)
                )"
                :key="competence.id"
              >
                <div class="d-flex" style="text-align: justify">
                  <span style="position: absolute"
                    >{{ competence.order }}.{{
                      cycleOrder(competence.cycle)
                    }}</span
                  >
                  <SentenceContainer
                    class="w-100"
                    style="padding-left: 12px"
                    :Sentence="competence"
                  >
                  </SentenceContainer>
                  <div
                    style="width: 10%"
                    v-if="
                      profileMentions(competence.profile_competence) !=
                        null &&
                      profileMentions(competence.profile_competence).length >
                        0
                    "
                  >
                    <strong
                      >{{
                        $getVisibleNames(
                          "mesh.egressprofilecompetencemention",
                          profileMentions(competence.profile_competence)
                            .length > 1,
                          "Mención"
                        )
                      }}: </strong
                    >{{
                      profileMentions(competence.profile_competence).length >
                      1
                        ? "(" +
                          profileMentions(
                            competence.profile_competence
                          ).join(", ") +
                          ")"
                        : profileMentions(competence.profile_competence)[0]
                    }}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            colspan="10"
            rowspan="1"
            v-if="
              matterCompetences.length > 0 &&
              competenceList.filter(
                (x) => x.type == null && matterCompetences.includes(x.id)
              ).length > 0
            "
          >
            <div class="wrapper">
              <div>
                <strong>
                  {{
                    $getVisibleNames(
                      "mesh.competence",
                      true,
                      "Competencias"
                    ).toUpperCase()
                  }}
                </strong>
              </div>
              <div
                v-for="competence in competenceList.filter(
                  (x) => x.type == null && matterCompetences.includes(x.id)
                )"
                :key="competence.id"
              >
                <p style="text-align: justify">
                  <span style="position: absolute"
                    >{{ competence.order }}.{{
                      cycleOrder(competence.cycle)
                    }}</span
                  >
                  <SentenceContainer
                    style="padding-left: 12px"
                    :Sentence="competence"
                  >
                  </SentenceContainer>
                </p>
              </div>
            </div>
          </td>
        </tr>
        <!-- <button-edit
          v-if="allows_crud && matterCompetences.length > 0"
          class="btn-edit-competence"
          v-b-tooltip.v-secondary.noninteractive="
            `Editar ${$getVisibleNames(
              'mesh.competence',
              true,
              'Competencias'
            )}`
          "
          @click="$bvModal.show(`modal-select-competence-${matter.id}`)"
        >
        </button-edit> -->
        <template v-if="allows_crud">
          <CompetenceModal
            @ok="updateCompetences"
            :selected_ids="matterCompetences"
            :modal_id="`modal-select-competence-${matter.id}`"
            :matrix2_competences="competenceList"
            :matrix2="true"
            :egress_profile_id="egressProfile ? egressProfile.id : null"
            :matter_id="matter.id"
          ></CompetenceModal>
        </template>
        <tr>
          <td v-if="matterCompetences.length == 0">
            <section class="py-1">
              Esta
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  false,
                  "Asignatura"
                )
              }}
              no tributa a ninguna
              {{ $getVisibleNames("mesh.competence", false, "Competencia") }}.
            </section>
            <!-- <section class="py-1">
              <button-edit
                v-b-tooltip.v-secondary.noninteractive="
                  `Editar ${$getVisibleNames(
                    'mesh.competence',
                    true,
                    'Competencias'
                  )}`
                "
                @click="
                  $bvModal.show(`empty-modal-select-competence-${matter.id}`)
                "
              >
              </button-edit>
              <template v-if="allows_crud && matterCompetences.length == 0">
                <CompetenceModal
                  @ok="updateCompetences"
                  :selected_ids="matterCompetences"
                  :modal_id="`empty-modal-select-competence-${matter.id}`"
                  :matrix2_competences="competenceList"
                  :matrix2="true"
                  :matter_id="matter.id"
                ></CompetenceModal>
              </template>
            </section> -->
          </td>
        </tr>
        <tr v-if="institution && institution.show_ra_macro">
          <th colspan="10" rowspan="1">
            {{
              $getVisibleNames(
                "teaching.ramacro",
                false,
                "RA Macro"
              ).toUpperCase()
            }}
          </th>
        </tr>
        <tr v-if="institution && institution.show_ra_macro">
          <td v-if="matter">
            <div class="wrapper">
              <section v-if="matterRAMacros">
                <SentenceContainer
                  style="padding-left: 12px"
                  :Sentence="matterRAMacros"
                >
                </SentenceContainer>
                <button-edit
                  v-if="
                    matterRAMacros
                      ? allows_crud && oldMatter && !oldMatter.is_closed
                      : false
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `Editar ${$getVisibleNames(
                      'teaching.ramacro',
                      false,
                      'RA Macro'
                    )}`
                  "
                  v-can="'teaching.change_ramacro'"
                  @click="$bvModal.show(`modal-edit-ra-macro-${matter.id}`)"
                ></button-edit>
              </section>
              <div>
                <section v-if="!matterRAMacros && !allows_crud">
                  Esta
                  {{
                    $getVisibleNames(
                      "mesh.egressprofilematter",
                      false,
                      "Asignatura"
                    )
                  }}
                  no cuenta con un
                  {{ $getVisibleNames("teaching.ramacro", false, "RA Macro") }}.
                </section>
                <button-add
                  v-if="
                    !matterRAMacros
                      ? allows_crud && oldMatter && !oldMatter.is_closed
                      : false
                  "
                  v-b-tooltip.v-secondary="
                    `Crear ${$getVisibleNames(
                      'teaching.ramacro',
                      false,
                      'RA Macro'
                    )}`
                  "
                  v-can="'teaching.change_ramacro'"
                  @click="$bvModal.show(`modal-new-ra-macro`)"
                ></button-add>
                <b-modal
                  :id="`modal-new-ra-macro`"
                  :title="`Crear ${$getVisibleNames(
                    'teaching.ramacro',
                    false,
                    'RA Macro'
                  )}.`"
                  size="lg"
                  hide-footer
                >
                  <RAMacroForm
                    :matter_id="matter.matter"
                    @created="$bvModal.hide(`modal-new-ra-macro`)"
                    @create="$bvModal.hide(`modal-new-ra-macro`)"
                  ></RAMacroForm>
                </b-modal>
                <b-modal
                  :id="`modal-edit-ra-macro-${matter.id}`"
                  :title="`Editar ${$getVisibleNames(
                    'teaching.ramacro',
                    false,
                    'RA Macro'
                  )}.`"
                  size="lg"
                  hide-footer
                >
                  <RAMacroForm
                    :matter_id="matter.matter"
                    :RAMacro="matterRAMacros"
                    @update="$bvModal.hide(`modal-edit-ra-macro-${matter.id}`)"
                    @updated="$bvModal.hide(`modal-edit-ra-macro-${matter.id}`)"
                  ></RAMacroForm>
                </b-modal>
              </div>
            </div>
          </td>
        </tr>
        <tr
          v-if="
            institution &&
            institution.show_evaluation_criteria_macro &&
            (evaluationCriteriaMacroList.length > 0 || allows_crud)
          "
        >
          <th colspan="10" rowspan="1" class="th-ecmacro">
            {{
              $getVisibleNames(
                "teaching.evaluationcriteriamacro",
                true,
                "Criterios de Evaluación Macro"
              ).toUpperCase()
            }}
            <div class="study-unit-button-div">
              <span
                class="secondary-color p-1 rounded"
                v-if="oldMatter && !oldMatter.automatic_achievement_weighing"
              >
                Ponderación: {{ WeighingAvailable }}%
              </span>
              <template
                v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                class="text-right"
              >
                <button-edit
                  class="ml-2"
                  @click="$bvModal.show(`modal-evaluation-criteria-macro`)"
                  v-b-tooltip.v-secondary.noninteractive="
                    `Editar ${$getVisibleNames(
                      'teaching.evaluationcriteriamacro',
                      true,
                      'Criterios de Evaluación Macro'
                    )}`
                  "
                ></button-edit>

                <b-modal
                  :title="
                    $getVisibleNames(
                      'teaching.evaluationcriteriamacro',
                      true,
                      'Criterios de Evaluación Macro'
                    )
                  "
                  :id="`modal-evaluation-criteria-macro`"
                  hide-footer
                  size="lg"
                >
                  <EvaluationCriteriaMacro
                    :egress_profile_matter_id="egress_profile_matter_id"
                  ></EvaluationCriteriaMacro>
                </b-modal>
              </template>
            </div>
          </th>
        </tr>
        <!-- Criterios de Evaluación Macro -->
        <tr
          v-if="
            institution &&
            institution.show_evaluation_criteria_macro &&
            (evaluationCriteriaMacroList.length > 0 || allows_crud)
          "
          class="tr-ecmacro"
        >
          <td colspan="10" rowspan="1" class="td-ecmacro">
            <template v-if="evaluationCriteriaMacroList.length > 0">
              <b-table-simple small responsive table-class="table-ecmacro">
                <b-thead>
                  <b-tr>
                    <b-th class="th-header m-0 p-0 w-25">
                      {{
                        $getVisibleNames(
                          "teaching.evaluationcriteriamacro",
                          false,
                          "Criterio de Evaluación Macro"
                        ).toUpperCase()
                      }}</b-th
                    >
                    <!-- <b-th class="th-header m-0 p-0">PONDERACIÓN</b-th> -->
                    <b-th class="th-header m-0 p-0 w-25">CONTENIDOS</b-th>
                    <b-th class="th-header m-0 p-0 w-25">{{
                      $getVisibleNames(
                        "mesh.methodologicalstrategy",
                        true,
                        "Estrategias Metodológicas"
                      ).toUpperCase()
                    }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <tr
                    v-for="criteria in evaluationCriteriaMacroList"
                    :key="criteria.id"
                  >
                    <td class="w-25">
                      <div
                        class="criteria py-1 d-flex"
                        style="padding-left: 20px"
                      >
                        <div class="w-75">
                          <span style="position: absolute">{{
                            criteria.order
                          }}</span>
                          <SentenceContainer :Sentence="criteria">
                          </SentenceContainer>
                        </div>
                        <div class="w-25">
                          <span
                            v-if="
                              oldMatter &&
                              !oldMatter.automatic_achievement_weighing
                            "
                          >
                            {{ criteria.weighing }}%
                          </span>
                        </div>
                      </div>
                    </td>
                    <!-- <td>
                      </td> -->
                    <td>
                      <ECMacroContent
                        :eCMacro_id="criteria.id"
                        :allows_crud="
                          allows_crud && oldMatter && !oldMatter.is_closed
                        "
                      ></ECMacroContent>
                    </td>
                    <td>
                      <MethodologicalStrategiesRow
                        v-if="matter"
                        :eCMacro_id="criteria.id"
                        :allows_crud="
                          allows_crud && oldMatter && !oldMatter.is_closed
                        "
                        :matter_id="matter.matter"
                      >
                      </MethodologicalStrategiesRow>
                    </td>
                  </tr>
                </b-tbody>
              </b-table-simple>
            </template>
            <!-- <template v-for="criteria in evaluationCriteriaMacroList">
                <div
                  :key="`criteria-${criteria.id}`"
                  class="criteria-container"
                >
                  <div class="criteria py-1" style="padding-left: 20px">
                    <span style="position: absolute">{{ criteria.order }}</span>
                    <SentenceContainer :Sentence="criteria">
                    </SentenceContainer>
                  </div>
                  <div
                    class="criteria-weighing text-right"
                    style="margin-right: 1.5%"
                  >
                    <span
                      v-b-tooltip.v-secondary.top.noninteractive="'Ponderación'"
                      >{{ criteria.weighing }}%
                    </span>
                  </div>
                </div>
              </template> -->
            <section v-if="evaluationCriteriaMacroList.length == 0">
              Esta
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  false,
                  "Asignatura"
                )
              }}
              no cuenta con
              {{
                $getVisibleNames(
                  "teaching.evaluationcriteriamacro",
                  true,
                  "Criterios de Evaluación Macro"
                )
              }}.
            </section>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <div v-if="studyUnitsList.length == 0">
      <p colspan="10">
        <span class="mr-4"
          >Esta asignatura no tiene Unidades de Estudio definidas.</span
        >
        <b-button @click="redirectToMatrix2" size="sm">Ir a Matriz 2</b-button>
      </p>
    </div> -->
    <div v-if="studyUnitsList.length > 0">
      <UnitSection
        v-for="study_unit in studyUnitsList"
        :study_unit_id="study_unit.id"
        :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
        :egress_profile_matter_id="egress_profile_matter_id"
        :key="`unit-section-${study_unit.id}`"
        class="my-2"
      ></UnitSection>
    </div>
    <div v-if="(institution && institution.id == 12) || $debug_change_duoc">
      <LearningExperience
        v-if="oldMatter"
        :matter_id="oldMatter.id"
        :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
      >
      </LearningExperience>
    </div>
    <template v-if="matter && !matter.use_matter_values">
      <EPMatterProgramEvaluation
        :egress_profile_matter_id="egress_profile_matter_id"
        :allows_crud="allows_crud && matter && !matter.is_closed"
        @created_support_resource="createdSupportResource"
      ></EPMatterProgramEvaluation>
    </template>
    <template v-else-if="oldMatter">
      <MatterProgramEvaluation
        :egress_profile_matter_id="egress_profile_matter_id"
        :matter_id="oldMatter.id"
        :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
        @created_support_resource="createdSupportResource"
      ></MatterProgramEvaluation>
    </template>
    <div class="mx-auto">
      <b-table-simple responsive>
        <b-tbody>
          <b-tr>
            <b-th colspan="2">RECURSOS DE APOYO A LA DOCENCIA</b-th>
          </b-tr>
          <b-tr
            v-for="resource_type in supportResourceTypes"
            :key="`resource_type-${resource_type.id}`"
          >
            <b-td style="width: 25%">
              <strong>{{ resource_type.name.toUpperCase() }}:</strong>
            </b-td>
            <b-td>
              <div class="wrapper">
                <div
                  v-for="resource in supportResources.filter(
                    (r) => r.type == resource_type.id
                  )"
                  :key="`resource-${resource.id}`"
                >
                  <!-- <span v-html="resource.description">{{
                  resource.description
                }}</span> -->
                  <div style="display: flex">
                    <div
                      class="my-1 w-100 rich-text-content support-resource-content"
                      v-html="resource.description"
                    >
                      <!-- <b-form-input
                      v-model="resource.description"
                      v-b-tooltip.v-secondary="
                        'Para actualizar presione enter o el boton de editar.'
                      "
                      @change="updateSupportResource(resource.id, arguments)"
                    ></b-form-input> -->
                      <!-- {{ resource.description }} -->
                    </div>
                    <div
                      v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                    >
                      <!-- <button-edit
                        tabindex="-1"
                        class="w-30"
                        @click="
                          updateSupportResource(
                            resource.id,
                            resource.description
                          )
                        "
                      >
                      </button-edit>
                      <button-delete
                        tabindex="-1"
                        class="w-30"
                        @click="deleteSupportResource(resource.id)"
                      ></button-delete> -->
                      <button-edit
                        tabindex="-1"
                        class="w-30"
                        @click="
                          $bvModal.show(`edit-resource-modal-${resource.id}`)
                        "
                      >
                      </button-edit>
                      <button-delete
                        tabindex="-1"
                        class="w-30"
                        @click="deleteSupportResource(resource.id)"
                      ></button-delete>
                      <b-modal
                        :id="`edit-resource-modal-${resource.id}`"
                        title="Editar Recurso de Apoyo a la Docencia"
                        size="lg"
                        hide-footer
                      >
                        Copie y edite los Recursos por separado
                        <RichTextEditor
                          :Object="resource"
                          :Text="resource.description"
                          @update="updateSupportResource"
                          @close="closeModal(resource.id)"
                        ></RichTextEditor>
                        <!-- @create="createSupportResource(resource_type.id, arguments)" -->
                      </b-modal>
                    </div>
                  </div>
                </div>
                <div v-if="allows_crud && oldMatter && !oldMatter.is_closed">
                  <b-button
                    @click="
                      $bvModal.show(`create-resource-modal-${resource_type.id}`)
                    "
                    v-b-tooltip.v-secondary.noninteractive="
                      'Agregue los Recursos por separado'
                    "
                    variant="secondary"
                    size="sm"
                  >
                    Agregar Recurso
                  </b-button>
                  <b-modal
                    :id="`create-resource-modal-${resource_type.id}`"
                    title="Crear Recurso"
                    size="lg"
                    hide-footer
                  >
                    <RichTextEditor
                      :Type="resource_type"
                      @create="createSupportResource"
                      @close="closeModal(resource_type.id)"
                    ></RichTextEditor>
                  </b-modal>
                </div>
                <!-- <div
                class="new-content-div"
                v-b-tooltip.v-secondary="'Presione enter para insertar.'"
                v-if="allows_crud"
              >
                <TextArea
                  @update="createSupportResource(resource_type.id, arguments)"
                ></TextArea>
              </div> -->
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-table-simple responsive>
        <b-tbody>
          <b-tr>
            <b-th colspan="2">RECURSOS BIBLIOGRÁFICOS</b-th>
          </b-tr>
          <b-tr
            v-for="bibliography_type in bibliographicResourceTypes"
            :key="`bibliography-${bibliography_type.id}`"
          >
            <b-td style="width: 25%">
              <strong class="mr-1">{{ bibliography_type.name }} </strong>
              <b-icon-exclamation-circle
                v-b-tooltip.v-secondary="
                  'Puede añadir hasta ' +
                  bibliography_type.max_amount +
                  ' recursos bibliográficos'
                "
              ></b-icon-exclamation-circle>
            </b-td>
            <b-td>
              <div class="wrapper">
                <div
                  v-for="biblio in bibliography.filter(
                    (b) => b.type == bibliography_type.id
                  )"
                  :key="`bibliography-${biblio.id}`"
                >
                  <div style="display: flex">
                    <div
                      class="my-1 w-100 rich-text-content support-resource-content"
                      v-html="biblio.description"
                    ></div>
                    <div
                      v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                    >
                      <button-edit
                        tabindex="-1"
                        class="w-30"
                        @click="
                          $bvModal.show(
                            `edit-bibliographic-resource-modal-${biblio.id}`
                          )
                        "
                      >
                      </button-edit>
                      <button-delete
                        tabindex="-1"
                        class="w-30"
                        @click="deleteBibliography(biblio.id)"
                      ></button-delete>
                      <b-modal
                        :id="`edit-bibliographic-resource-modal-${biblio.id}`"
                        title="Editar Recurso Bibliográfico"
                        size="lg"
                        hide-footer
                      >
                        Copie y edite los contenidos por separado
                        <RichTextEditor
                          :Object="biblio"
                          :Text="biblio.description"
                          @update="updateBibliography"
                          @close="closeModal(biblio.id)"
                        ></RichTextEditor>
                      </b-modal>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    allows_crud &&
                    bibliography.filter((b) => b.type == bibliography_type.id)
                      .length < bibliography_type.max_amount &&
                    oldMatter &&
                    !oldMatter.is_closed
                  "
                >
                  <b-button
                    @click="
                      $bvModal.show(
                        `create-bibliographic-resource-modal-${bibliography_type.id}`
                      )
                    "
                    v-b-tooltip.v-secondary.noninteractive="
                      'Agregue los Recursos por separado'
                    "
                    variant="secondary"
                    size="sm"
                  >
                    Agregar Recurso
                  </b-button>
                  <b-modal
                    :id="`create-bibliographic-resource-modal-${bibliography_type.id}`"
                    title="Crear Recurso Bibliográfico"
                    size="lg"
                    hide-footer
                  >
                    <RichTextEditor
                      :Type="bibliography_type"
                      @create="createBibliography"
                      @close="closeModal(bibliography_type.id)"
                    ></RichTextEditor>
                  </b-modal>
                </div>
                <div
                  v-else-if="allows_crud && oldMatter && !oldMatter.is_closed"
                  v-b-tooltip.v-secondary.noninteractive="
                    'Supero el máximo de recursos bibliográficos'
                  "
                >
                  <b-button disabled variant="secondary" size="sm">
                    Agregar Recurso
                  </b-button>
                </div>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-table-simple
        bordered
        responsive
        class="mt-2 page-break"
        v-if="(institution && institution.id == 12) || $debug_change_duoc"
      >
        <b-thead>
          <b-tr>
            <b-th class="biblio-header"
              >Recursos por Ambiente de Aprendizaje (referencia Ficha FER)</b-th
            >
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td class="text-center align-middle">
              <div v-if="oldMatter">
                <div
                  class="m-2 rich-text-content"
                  v-html="oldMatter.fer_record"
                ></div>
                <div v-if="allows_crud">
                  <button-edit
                    tabindex="-1"
                    class="w-30"
                    @click="
                      $bvModal.show(`edit-fer_record-modal-${oldMatter.id}`)
                    "
                    v-b-tooltip.v-secondary.noninteractive="
                      'Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)'
                    "
                  >
                  </button-edit>
                  <b-modal
                    :id="`edit-fer_record-modal-${oldMatter.id}`"
                    title="Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)"
                    size="lg"
                    hide-footer
                  >
                    <RichTextEditor
                      :Object="oldMatter"
                      :Text="oldMatter.fer_record"
                      @update="updateFerRecord"
                      @close="
                        $bvModal.hide(`edit-fer_record-modal-${oldMatter.id}`)
                      "
                    ></RichTextEditor>
                  </b-modal>
                </div>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-table-simple responsive>
        <b-tbody>
          <template v-for="(teaching_profile, index) in teaching_profiles">
            <b-tr :key="'profile_header_' + teaching_profile.id">
              <b-th colspan="10" rowspan="1">
                <div class="d-flex">
                  <div
                    style="
                      margin-left: auto;
                      margin-right: auto;
                      margin-top: auto;
                      margin-bottom: auto;
                    "
                  >
                    PERFIL DOCENTE {{ index + 1 }}
                    <button-add
                      v-if="
                        index == 0 &&
                        allows_crud &&
                        oldMatter &&
                        !oldMatter.is_closed
                      "
                      class="ml-1"
                      @click="createTeachingProfileBase()"
                      v-b-tooltip.v-secondary.noninteractive="
                        `Agregar un nuevo Perfil Docente`
                      "
                    >
                    </button-add>
                    <button-delete
                      v-if="
                        index != 0 &&
                        allows_crud &&
                        oldMatter &&
                        !oldMatter.is_closed
                      "
                      class="ml-1"
                      @click="deleteTeachingProfile(teaching_profile)"
                      v-b-tooltip.v-secondary.noninteractive="
                        `Eliminar el Perfil Docente`
                      "
                    >
                    </button-delete>
                    <div class="teacher-input">
                      <div class="d-flex">
                        <div
                          style="color: white !important; padding: 0.1em"
                          class="mr-2 secondary-color rounded"
                        >
                          {{
                            $getVisibleNames(
                              "manual.total_pedagogical_hours",
                              true,
                              "Total de Horas Pedagógicas"
                            )
                          }}
                          <span
                            v-if="
                              !allows_crud || (oldMatter && oldMatter.is_closed)
                            "
                            >:
                            {{
                              teaching_profile.total_hours
                                ? teaching_profile.total_hours
                                : "N/A"
                            }}
                          </span>
                          <b-form-input
                            v-else
                            :id="`input-teaching-profile-${teaching_profile.id}`"
                            type="number"
                            min="0"
                            :state="
                              totalPedagogical -
                                maxHoursTeachingProfile(teaching_profile.id) >=
                              teaching_profile.total_hours
                            "
                            @input="patchHourTeachingProfile(teaching_profile)"
                            v-model.number="teaching_profile.total_hours"
                            class="inputNumber"
                          ></b-form-input>
                          <b-popover
                            v-if="
                              allows_crud &&
                              totalPedagogical -
                                maxHoursTeachingProfile(teaching_profile.id) <
                                teaching_profile.total_hours
                            "
                            variant="warning"
                            placement="bottom"
                            :target="`input-teaching-profile-${teaching_profile.id}`"
                            show
                          >
                            <template>
                              La suma de las horas de los docentes superó el
                              máximo: {{ totalPedagogical }}
                            </template>
                          </b-popover>
                        </div>
                        <div
                          style="color: white !important; padding: 0.1em"
                          class="mr-2 secondary-color rounded"
                        >
                          Años de experiencia laboral en el área
                          <span
                            v-if="
                              !allows_crud || (oldMatter && oldMatter.is_closed)
                            "
                            >:
                            {{
                              teaching_profile.experience_years
                                ? teaching_profile.experience_years
                                : "N/A"
                            }}
                          </span>
                          <b-form-input
                            v-else
                            type="number"
                            min="0"
                            v-model.number="teaching_profile.experience_years"
                            @input="
                              (value) => {
                                if (
                                  value >= 0 &&
                                  teaching_profile.total_hours >= 0
                                )
                                  patchTeachingProfile(teaching_profile);
                                else if (value < 0)
                                  teaching_profile.experience_years = 0;
                              }
                            "
                            class="inputNumber"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-th>
            </b-tr>
            <b-tr :key="'professional_title_' + teaching_profile.id">
              <b-td colspan="1" style="width: 25%">
                <strong>
                  {{
                    $getVisibleNames(
                      "manual.titulo_profesional",
                      false,
                      "Título Profesional"
                    )
                  }}:</strong
                >
              </b-td>
              <b-td colspan="9">
                <div
                  class="wrapper2 rich-text-content"
                  v-html="teaching_profile.professional_title"
                ></div>
                <template
                  v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                >
                  <button-edit
                    tabindex="-1"
                    class="w-30"
                    @click="
                      $bvModal.show(
                        `edit-professional_title-modal-${teaching_profile.id}`
                      )
                    "
                  >
                  </button-edit>
                  <b-modal
                    :id="`edit-professional_title-modal-${teaching_profile.id}`"
                    :title="`Editar ${$getVisibleNames(
                      'manual.titulo_profesional',
                      false,
                      'Título Profesional'
                    )}`"
                    size="lg"
                    hide-footer
                  >
                    <RichTextEditor
                      :Object="teaching_profile"
                      :Text="teaching_profile.professional_title"
                      @update="updateProfessionalTitle"
                      @close="
                        $bvModal.hide(
                          `edit-professional_title-modal-${teaching_profile.id}`
                        )
                      "
                    ></RichTextEditor>
                  </b-modal>
                </template>
              </b-td>
            </b-tr>
            <b-tr :key="'academic_degree_' + teaching_profile.id">
              <b-td colspan="1" style="width: 25%">
                <strong>
                  {{
                    $getVisibleNames(
                      "manual.grado_academico",
                      false,
                      "Grado Académico"
                    )
                  }}:</strong
                >
              </b-td>
              <b-td colspan="9">
                <div
                  class="wrapper2 rich-text-content"
                  v-html="teaching_profile.academic_degree"
                ></div>
                <template
                  v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                >
                  <button-edit
                    tabindex="-1"
                    class="w-30"
                    @click="
                      $bvModal.show(
                        `edit-academic_degree-modal-${teaching_profile.id}`
                      )
                    "
                  >
                  </button-edit>
                  <b-modal
                    :id="`edit-academic_degree-modal-${teaching_profile.id}`"
                    :title="`Editar ${$getVisibleNames(
                      'manual.grado_academico',
                      false,
                      'Grado Académico'
                    )}`"
                    size="lg"
                    hide-footer
                  >
                    <RichTextEditor
                      :Object="teaching_profile"
                      :Text="teaching_profile.academic_degree"
                      @update="updateAcademicDegree"
                      @close="
                        $bvModal.hide(
                          `edit-academic_degree-modal-${teaching_profile.id}`
                        )
                      "
                    ></RichTextEditor>
                  </b-modal>
                </template>
              </b-td>
            </b-tr>
            <b-tr :key="'specialization_' + teaching_profile.id">
              <b-td colspan="1" style="width: 25%">
                <strong>
                  {{
                    $getVisibleNames(
                      "manual.especializacion",
                      false,
                      "Especialización"
                    )
                  }}:</strong
                >
              </b-td>
              <b-td colspan="9">
                <div
                  class="wrapper2 rich-text-content"
                  v-html="teaching_profile.specialization"
                ></div>
                <template
                  v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                >
                  <button-edit
                    tabindex="-1"
                    class="w-30"
                    @click="
                      $bvModal.show(
                        `edit-specialization-modal-${teaching_profile.id}`
                      )
                    "
                  >
                  </button-edit>
                  <b-modal
                    :id="`edit-specialization-modal-${teaching_profile.id}`"
                    :title="`Editar ${$getVisibleNames(
                      'manual.especializacion',
                      false,
                      'Especialización'
                    )}`"
                    size="lg"
                    hide-footer
                  >
                    <RichTextEditor
                      :Object="teaching_profile"
                      :Text="teaching_profile.specialization"
                      @update="updateSpecialization"
                      @close="
                        $bvModal.hide(
                          `edit-specialization-modal-${teaching_profile.id}`
                        )
                      "
                    ></RichTextEditor>
                  </b-modal>
                </template>
              </b-td>
            </b-tr>
            <b-tr :key="'required_skills_' + teaching_profile.id">
              <b-td colspan="1" style="width: 25%">
                <strong>
                  {{
                    $getVisibleNames(
                      "manual.habilidades_requeridas",
                      true,
                      "Habilidades Requeridas"
                    )
                  }}:</strong
                >
              </b-td>
              <b-td colspan="9">
                <div
                  class="wrapper2 rich-text-content"
                  v-html="teaching_profile.required_skills"
                ></div>
                <template
                  v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                >
                  <button-edit
                    tabindex="-1"
                    class="w-30"
                    @click="
                      $bvModal.show(
                        `edit-required_skills-modal-${teaching_profile.id}`
                      )
                    "
                  >
                  </button-edit>
                  <b-modal
                    :id="`edit-required_skills-modal-${teaching_profile.id}`"
                    :title="`Editar ${$getVisibleNames(
                      'manual.habilidades_requeridas',
                      true,
                      'Habilidades Requeridas'
                    )}`"
                    size="lg"
                    hide-footer
                  >
                    <RichTextEditor
                      :Object="teaching_profile"
                      :Text="teaching_profile.required_skills"
                      @update="updateRequiredSkills"
                      @close="
                        $bvModal.hide(
                          `edit-required_skills-modal-${teaching_profile.id}`
                        )
                      "
                    ></RichTextEditor>
                  </b-modal>
                </template>
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
      <b-table-simple responsive>
        <b-tbody>
          <b-tr>
            <b-th colspan="10" rowspan="1"
              >PERFIL AYUDANTE PARA LA
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  false,
                  "Asignatura"
                ).toUpperCase()
              }}</b-th
            >
          </b-tr>
          <b-tr>
            <b-td colspan="1" style="width: 25%">
              <strong>Perfil Ayudante:</strong>
            </b-td>
            <b-td colspan="9">
              <template v-if="oldMatter">
                <div
                  class="wrapper2 rich-text-content"
                  v-html="oldMatter.assistant_profile"
                ></div>
                <template
                  v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                >
                  <button-edit
                    tabindex="-1"
                    class="w-30"
                    @click="
                      $bvModal.show(
                        `edit-assistant_profile-modal-${oldMatter.id}`
                      )
                    "
                  >
                  </button-edit>
                  <b-modal
                    :id="`edit-assistant_profile-modal-${oldMatter.id}`"
                    title="Editar Perfil Ayudante:"
                    size="lg"
                    hide-footer
                  >
                    <RichTextEditor
                      :Object="oldMatter"
                      :Text="oldMatter.assistant_profile"
                      @update="
                        (old_matter, text) =>
                          patchMatter({ assistant_profile: text })
                      "
                      @close="
                        $bvModal.hide(
                          `edit-assistant_profile-modal-${oldMatter.id}`
                        )
                      "
                    ></RichTextEditor>
                  </b-modal>
                </template>
              </template>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td colspan="1" style="width: 25%">
              <strong>Rol del Ayudante:</strong>
            </b-td>
            <b-td colspan="9">
              <template v-if="oldMatter">
                <div
                  class="wrapper2 rich-text-content"
                  v-html="oldMatter.assistant_role"
                ></div>
                <template
                  v-if="allows_crud && oldMatter && !oldMatter.is_closed"
                >
                  <button-edit
                    tabindex="-1"
                    class="w-30"
                    @click="
                      $bvModal.show(`edit-assistant_role-modal-${oldMatter.id}`)
                    "
                  >
                  </button-edit>
                  <b-modal
                    :id="`edit-assistant_role-modal-${oldMatter.id}`"
                    title="Editar Perfil Ayudante:"
                    size="lg"
                    hide-footer
                  >
                    <RichTextEditor
                      :Object="oldMatter"
                      :Text="oldMatter.assistant_role"
                      @update="
                        (old_matter, text) =>
                          patchMatter({ assistant_role: text })
                      "
                      @close="
                        $bvModal.hide(
                          `edit-assistant_role-modal-${oldMatter.id}`
                        )
                      "
                    ></RichTextEditor>
                  </b-modal>
                </template>
              </template>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <EvaluationCustomFab
      class="noprint"
      :matter_id="egress_profile_matter_id"
      :show_matter_program="false"
    ></EvaluationCustomFab>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "MatterProgram",
  components: {
    // ResizeAuto: () => import("@/components/reusable/ResizeAuto"),
    // TextArea: () => import("@/components/reusable/TextArea"),
    ECMacroContent: () => import("./ECMacroContent.vue"),
    MethodologicalStrategiesRow: () =>
      import("./MethodologicalStrategiesRow.vue"),
    LearningExperience: () => import("./LearningExperience.vue"),
    EPMatterProgramEvaluation: () => import("./EPMatterProgramEvaluation.vue"),
    MatterProgramEvaluation: () => import("./MatterProgramEvaluation.vue"),
    MatterForm: () => import("@/components/mesh/Matrix2/MatterForm"),
    DescriptorsContainer: () =>
      import("@/components/reusable/DescriptorsContainer"),
    RichTextEditor: () => import("@/components/reusable/RichTextEditor"),
    MatterPresentation: () =>
      import("@/components/teaching/MatterProgram/MatterPresentation"),
    EvaluationCriteriaMacro: () => import("./EvaluationCriteriaMacro"),
    UnitSection: () => import("./UnitSection"),
    EvaluationCustomFab: () =>
      import("@/components/evaluations/EvaluationCustomFab"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    RAMacroForm: () => import("@/components/mesh/Matrix2/RAMacroForm"),
    CompetenceModal: () =>
      import(
        "@/components/mesh/CurricularResources/Competence/CompetenceModal"
      ),
    // LearningExperiences: () =>
    //   import("@/components/teaching/MatterProgram/LearningExperiences"),
  },
  props: {
    egress_profile_matter_id: {
      type: Number,
      required: true,
    },
    // matter_id: {
    //   type: Number,
    //   required: true,
    // },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      description: "",
      bibliographies: [],
      support_resources: [],
      teaching_profiles: [],
      componentKey: 0,
      resource_content_key: 0,
      biblio_content_key: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      user_access: names.USER_POSITION,
      matters: names.MATTERS,
      studyUnits: names.STUDY_UNITS,
      methodologicalStretegies: names.METHODOLOGICAL_STRATEGIES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      supportResourceTypes: names.SUPPORT_RESOURCE_TYPES,
      bibliographicResourceTypes: names.BIBLIOGRAPHIC_RESOURCE_TYPES,
      raMacros: names.RA_MACROS,
      profile_competences: names.PROFILE_COMPETENCES,
      competenceTypes: names.COMPETENCE_TYPES,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      competences: names.COMPETENCES,
      cycles: names.CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
      profile_cycles: names.PROFILE_CYCLES,
      egress_profile_matter_descriptors:
        names.EGRESS_PROFILE_MATTER_DESCRIPTORS,
      epm_middle_descriptors: names.EPM_MIDDLE_DESCRIPTORS,
      m_middle_descriptors: names.M_MIDDLE_DESCRIPTORS,
      time_allocations: names.TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      profile_competences_mention: names.PROFILE_COMPETENCES_MENTION,
    }),
    isClosedMatter() {
      if (
        this.user.is_superuser ||
        (this.oldMatter &&
          this.$hasObjectPermission(
            "special.close_base_matter",
            "special",
            this.oldMatter.id
          ))
        // ||(this.user_access && this.user_access.position == 1)
      ) {
        return true;
      } else return false;
    },
    isClosedOldMatter() {
      if (
        this.user.is_superuser ||
        (this.oldMatter &&
          this.$hasObjectPermission(
            "special.close_base_matter",
            "special",
            this.oldMatter.id
          ))
        // ||
        // (this.user_access && this.user_access.position == 1)
      ) {
        return true;
      } else return false;
    },
    matter() {
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    oldMatter() {
      if (!this.matter) return null;
      return this.matters.find((x) => x.id == this.matter.matter);
    },
    egressProfile() {
      return this.egressProfiles.find(
        (x) => x.id == this.matter.egress_profile
      );
    },
    evaluationCriteriaMacroList() {
      if (!this.matter) return [];
      let list = this.evaluationCriteriasMacros
        .filter((x) => x.matter == this.matter.matter)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
      return list;
    },
    WeighingAvailable() {
      let weighing = 0;
      this.evaluationCriteriaMacroList.forEach((element) => {
        weighing += element.weighing;
      });
      return weighing;
    },
    epmMiddleDescriptors() {
      return this.epm_middle_descriptors.filter(
        (x) => x.egress_profile_matter == this.egress_profile_matter_id
      );
    },
    matterMiddleDescriptors() {
      if (!this.matter) return [];
      return this.m_middle_descriptors.filter(
        (x) => x.matter == this.matter.matter
      );
    },
    egressProfileMatterDescriptor() {
      if (this.matter && !this.matter.use_matter_values) {
        const epm_middle = this.epmMiddleDescriptors.map((x) => x.descriptor);
        return this.egress_profile_matter_descriptors.filter(
          (x) => x.is_default || epm_middle.includes(x.id)
        );
      } else {
        const matter_middle = this.matterMiddleDescriptors.map(
          (x) => x.descriptor
        );
        return this.egress_profile_matter_descriptors.filter(
          (x) => x.is_default || matter_middle.includes(x.id)
        );
      }
    },
    egressProfileCycleId() {
      if (!this.egressProfile) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egressProfile.id) list.push(x.cycle);
      });
      return list;
    },
    matterRAMacros() {
      if (!this.matter) return {};
      return this.raMacros.find((x) => x.matter == this.matter.matter);
    },
    matterCompetences() {
      if (!this.matter) return [];
      return this.matter.competences;
    },
    competenceList() {
      if (!this.egressProfile) return [];
      if (!this.matter) return [];
      let list = [];
      let competences = [];
      let cycles = [];
      this.profile_cycles.forEach((x) => {
        if (this.matter.cycles.includes(x.id)) {
          cycles.push(x.cycle);
        }
      });
      if (this.egressProfileCycleId.length > 0 && cycles.length > 0) {
        competences = this.profile_competences.filter(
          (x) =>
            (x.egress_profile == this.egressProfile.id &&
              (cycles.includes(x.cycle) || x.cycle == null)) ||
            (x.egress_profile == this.egressProfile.id &&
              this.matter.competences.includes(x.competence))
        );
      } else {
        competences = this.profile_competences.filter(
          (x) =>
            (x.egress_profile == this.egressProfile.id && x.cycle == null) ||
            (x.egress_profile == this.egressProfile.id &&
              this.matter.competences.includes(x.competence))
        );
      }
      competences.forEach((competence) => {
        if (competence) {
          let get_competence = this.competences.find(
            (x) => x.id == competence.competence
          );
          if (get_competence) {
            list.push({
              id: competence.competence,
              order: competence.order,
              egress_profile: competence.egress_profile,
              profile_competence: competence.id,
              cycle: competence.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              full_sentence: get_competence.full_sentence,
              type: get_competence.type,
              verb: get_competence.verb,
              sentence: get_competence.sentence,
              name: get_competence.name,
              selected: get_competence.selected,
            });
          }
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.cycle > b.cycle) return 1;
        if (a.cycle < b.cycle) return -1;
        return 0;
      });
    },
    supportResources() {
      let list = this.support_resources;
      return list.sort(function (a, b) {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });
    },
    bibliography() {
      return this.bibliographies;
    },
    // methodologicalStretegyList() {
    //   let arr = [];
    //   if (!this.matter) return arr;
    //   this.matter.methodological_strategies.forEach((ms_id) => {
    //     if (!arr.find((x) => x.id == ms_id)) {
    //       let strategy = this.methodologicalStretegies.find(
    //         (x) => x.id == ms_id
    //       );
    //       if (strategy) arr.push(strategy);
    //     }
    //   });
    //   return arr;
    // },
    studyUnitsList() {
      if (!this.matter) return [];
      return this.studyUnits.filter((x) => x.matter == this.matter.matter);
    },
    matterHours() {
      let list = [];
      this.time_allocations.forEach((element) => {
        if (this.matter.use_matter_values) {
          const matter_hour = this.matter_time_allocations.find(
            (x) =>
              x.matter == this.matter.matter && x.time_allocation == element.id
          );
          if (matter_hour && this.oldMatter)
            list.push({
              id: matter_hour.id,
              time_allocation: matter_hour.time_allocation,
              name: element.name,
              order: element.order,
              uses_module_minutes: element.uses_module_minutes,
              matter: matter_hour.matter,
              counts_towards_credits: matter_hour.counts_towards_credits,
              attendance_requirement: matter_hour.attendance_requirement,
              hours: matter_hour.hours,
              tem_hours:
                parseFloat(this.oldMatter.weeks) > 0
                  ? Math.round(
                      parseFloat(matter_hour.hours) /
                        parseFloat(this.oldMatter.weeks)
                    )
                  : 0,
            });
        } else {
          const matter_hour = this.ep_matter_time_allocations.find(
            (x) =>
              x.egress_profile_matter == this.matter.id &&
              x.time_allocation == element.id
          );
          if (matter_hour)
            list.push({
              id: matter_hour.id,
              time_allocation: matter_hour.time_allocation,
              name: element.name,
              order: element.order,
              uses_module_minutes: element.uses_module_minutes,
              egress_profile_matter: matter_hour.egress_profile_matter,
              counts_towards_credits: matter_hour.counts_towards_credits,
              attendance_requirement: matter_hour.attendance_requirement,
              hours: matter_hour.hours,
              tem_hours:
                parseFloat(this.matter.weeks) > 0
                  ? Math.round(
                      parseFloat(matter_hour.hours) /
                        parseFloat(this.matter.weeks)
                    )
                  : 0,
            });
        }
      });
      return list;
    },
    totalPedagogical() {
      let count = 0;
      this.matterHours
        .filter((x) => x.uses_module_minutes == true)
        .forEach((element) => {
          if (
            element.counts_towards_credits &&
            element.hours &&
            element.hours >= 0
          ) {
            count += parseFloat(element.hours);
          }
        });
      return count;
    },
  },
  methods: {
    profileMentions(profile_competence) {
      if (
        profile_competence &&
        this.matter &&
        this.profile_competences_mention.filter(
          (x) => x.egress_profile == this.matter.egress_profile
        ).length > 0
      ) {
        const mentions = this.profile_competences_mention
          .filter(
            (x) =>
              x.egress_profile == this.matter.egress_profile &&
              x.egress_profile_competences.includes(profile_competence)
          )
          .map((x) => x.name);
        if (mentions.length > 0) return mentions;
        else return ["Común"];
      } else return null;
    },
    updateFerRecord(old_matter, text) {
      let payload = {
        matter_id: old_matter.id,
        item: {
          fer_record: text,
        },
      };
      this.$store.dispatch(names.PATCH_MATTER, payload).then(() => {
        toast(
          "Recursos por Ambiente de Aprendizaje (referencia Ficha FER) actualizado."
        );
      });
    },
    changeStateOldMatter() {
      const payload = {
        matter_id: this.oldMatter.id,
        item: {
          is_closed: !this.oldMatter.is_closed,
        },
      };
      this.$store.dispatch(names.PATCH_MATTER, payload);
    },
    createdSupportResource(response) {
      this.support_resources.push(response);
    },
    changeStateMatter() {
      if (!this.matter.is_closed) {
        const payload = {
          egress_profile_matter_id: this.matter.id,
          item: { is_closed: !this.matter.is_closed },
        };
        this.$store.dispatch(names.PATCH_EGRESS_PROFILE_MATTER, payload);
      } else {
        this.$restful
          .Get(
            `/mesh/reopen_egress_profile_matter/?egress_profile_matter=${this.matter.id}`
          )
          .then(() => {
            this.matter.is_closed = !this.matter.is_closed;
          });
      }
    },
    forceRerenderMatterPresentation() {
      this.componentKey += 1;
      this.$bvModal.hide(`modal-edit-matter-${this.matter.id}`);
    },
    getMatterDescription(matter_id) {
      const matter = this.matters.find((x) => x.id == matter_id);
      if (matter) this.description = matter.description;
      return matter ? matter.description : "";
    },
    updateDescription(old_matter, text) {
      let payload = {
        matter_id: old_matter.id,
        item: {
          description: text,
        },
      };
      old_matter.description = text;
      this.$store.dispatch(names.PATCH_MATTER, payload).then(() => {
        toast(
          "Descripción de " +
            this.$getVisibleNames(
              "mesh.egressprofilematter",
              false,
              "Asignatura"
            ) +
            " actualizada."
        );
      });
    },
    createProfileMatterDescriptors(title) {
      if (isNaN(title.id)) {
        this.$store
          .dispatch(names.POST_EGRESS_PROFILE_MATTER_DESCRIPTORS, title)
          .then((response) => {
            this.createEpmMiddleDescriptors("", response);
          });
      }
    },
    deleteTitle(id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el título descriptivo?",
        text: `"Esta acción no se podrá revertir y podría afectar en otras ${this.$getVisibleNames(
          "mesh.egressprofilematter",
          true,
          "Asignaturas"
        )}."`,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS, id)
            .then(() =>
              this.$store.dispatch(names.FETCH_EPM_MIDDLE_DESCRIPTORS)
            );
        }
      });
    },
    deleteProfileMatterDescriptors(EpmMiddleDescriptor) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el título descriptivo?",
        text: `"Esta acción no se podrá revertir y podría afectar en otras ${this.$getVisibleNames(
          "mesh.egressprofilematter",
          true,
          "Asignaturas"
        )}."`,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              names.DELETE_EPM_MIDDLE_DESCRIPTORS,
              EpmMiddleDescriptor.id
            )
            .then(() => {
              this.$store
                .dispatch(
                  names.DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS,
                  EpmMiddleDescriptor.descriptor
                )
                .then(() =>
                  this.$store.dispatch(names.FETCH_EPM_MIDDLE_DESCRIPTORS)
                );
            });
        }
      });
    },
    deleteMatterMiddleDescriptors(matterMiddleDescriptor) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el título descriptivo?",
        text: `"Esta acción no se podrá revertir y podría afectar en otras ${this.$getVisibleNames(
          "mesh.egressprofilematter",
          true,
          "Asignaturas"
        )}."`,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              names.DELETE_M_MIDDLE_DESCRIPTORS,
              matterMiddleDescriptor.id
            )
            .then(() => {
              this.$store
                .dispatch(
                  names.DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS,
                  matterMiddleDescriptor.descriptor
                )
                .then(() =>
                  this.$store.dispatch(names.FETCH_M_MIDDLE_DESCRIPTORS)
                );
            });
        }
      });
    },
    updateProfileMatterDescriptors(title) {
      if (!isNaN(title.id)) {
        let payload = {
          id: title.id,
          item: {
            title: title.title,
            is_default: title.is_default,
          },
        };
        this.$store.dispatch(
          names.PATCH_EGRESS_PROFILE_MATTER_DESCRIPTORS,
          payload
        );
      }
    },
    createEpmMiddleDescriptors(text, object) {
      if (!isNaN(object.id) && this.egress_profile_matter_id) {
        let item = {
          egress_profile_matter: this.egress_profile_matter_id,
          descriptor: object.id,
          description: text,
          order: 1 + this.epmMiddleDescriptors.length,
        };
        this.$store.dispatch(names.POST_EPM_MIDDLE_DESCRIPTORS, item);
      }
    },
    updateEpmMiddleDescriptors(object, text) {
      if (!isNaN(object.id)) {
        let payload = {
          id: object.id,
          item: {
            description: text,
          },
        };
        this.$store.dispatch(names.PATCH_EPM_MIDDLE_DESCRIPTORS, payload);
      }
    },
    updateVisibleEpmMiddleDescriptors(item) {
      if (!isNaN(item.id)) {
        let payload = {
          id: item.id,
          item: {
            is_visible: !item.is_visible,
          },
        };
        this.$store.dispatch(names.PATCH_EPM_MIDDLE_DESCRIPTORS, payload);
      }
    },
    updateOrderEpmMiddleDescriptors(item) {
      let order = 1;
      item.forEach((element) => {
        if (element.order != order) {
          element.order = order;
          if (
            !isNaN(element.id) &&
            this.epm_middle_descriptors.findIndex((x) => x.id == element.id) !=
              -1
          ) {
            let payload = {
              id: element.id,
              item: {
                order: element.order,
              },
            };
            this.$store.dispatch(names.PATCH_EPM_MIDDLE_DESCRIPTORS, payload);
          }
        }
        order += 1;
      });
    },
    updateOrderMatterMiddleDescriptors(item) {
      let order = 1;
      item.forEach((element) => {
        if (element.order != order) {
          element.order = order;
          if (
            !isNaN(element.id) &&
            this.m_middle_descriptors.findIndex((x) => x.id == element.id) != -1
          ) {
            let payload = {
              id: element.id,
              item: {
                order: element.order,
              },
            };
            this.$store.dispatch(names.PATCH_M_MIDDLE_DESCRIPTORS, payload);
          }
        }
        order += 1;
      });
    },
    updateVisibleMatterMiddleDescriptors(item) {
      if (!isNaN(item.id)) {
        let payload = {
          id: item.id,
          item: {
            is_visible: !item.is_visible,
          },
        };
        this.$store.dispatch(names.PATCH_EPM_MIDDLE_DESCRIPTORS, payload);
      }
    },
    createMatterMiddleDescriptors(text, object) {
      if (!isNaN(object.id) && this.matter) {
        let item = {
          matter: this.matter.matter,
          descriptor: object.id,
          description: text,
          order: 1 + this.matterMiddleDescriptors.length,
        };
        this.$store.dispatch(names.POST_M_MIDDLE_DESCRIPTORS, item);
      }
    },
    updateMatterMiddleDescriptors(object, text) {
      if (!isNaN(object.id)) {
        let payload = {
          id: object.id,
          item: {
            description: text,
          },
        };
        this.$store.dispatch(names.PATCH_M_MIDDLE_DESCRIPTORS, payload);
      }
    },
    cycleOrder(cycle_id) {
      if (!cycle_id && !this.egressProfile) return "-";
      let cycleOrder = this.profile_cycles.find(
        (x) => x.cycle == cycle_id && x.egress_profile == this.egressProfile.id
      );
      return cycleOrder ? cycleOrder.order : "-";
    },
    updateCompetences(competence_ids) {
      let get_matter = this.matter;
      this.$store
        .dispatch(names.UPDATE_EGRESS_PROFILE_MATTER, get_matter)
        .then((response) => {
          if (response) {
            let payload = {
              egress_profile_matter_id: get_matter.id,
              item: {
                competences: competence_ids,
              },
            };
            this.patchCompetences(payload);
          }
        });
    },
    patchCompetences(payload) {
      this.$store
        .dispatch(names.PATCH_EGRESS_PROFILE_MATTER, payload)
        .then((egress_profile_matter_id) => {
          let egress_profile_matter = this.matter;
          if (egress_profile_matter) {
            egress_profile_matter = egress_profile_matter_id;
            toast(
              String(
                this.$getVisibleNames("mesh.competence", true, "Competencias") +
                  " actualizadas."
              )
            );
          }
        });
    },
    fetchTeachingProfile() {
      if (this.matter) {
        this.$restful
          .Get(`/teaching/teaching-profile/?matter=${this.matter.matter}`)
          .then((response) => {
            this.teaching_profiles = response;
            if (response.length == 0) this.createTeachingProfileBase();
          });
      }
    },
    updateProfessionalTitle(teaching_profile, text) {
      teaching_profile.professional_title = text;
      this.saveTeachingProfile(teaching_profile);
    },
    updateAcademicDegree(teaching_profile, text) {
      teaching_profile.academic_degree = text;
      this.saveTeachingProfile(teaching_profile);
    },
    updateSpecialization(teaching_profile, text) {
      teaching_profile.specialization = text;
      this.saveTeachingProfile(teaching_profile);
    },
    updateRequiredSkills(teaching_profile, text) {
      teaching_profile.required_skills = text;
      this.saveTeachingProfile(teaching_profile);
    },
    saveTeachingProfile(teaching_profile) {
      if (teaching_profile.id == -1)
        this.createTeachingProfile(teaching_profile);
      else this.updateTeachingProfile(teaching_profile);
    },
    maxHoursTeachingProfile(teaching_profile_id) {
      return this.teaching_profiles
        .filter((x) => x.id != teaching_profile_id)
        .map((x) => x.total_hours)
        .reduce((a, b) => a + b, 0);
    },
    patchHourTeachingProfile(teaching_profile) {
      if (
        teaching_profile.total_hours >= 0 &&
        teaching_profile.experience_years >= 0 &&
        this.totalPedagogical -
          this.maxHoursTeachingProfile(teaching_profile.id) >=
          teaching_profile.total_hours
      )
        this.patchTeachingProfile(teaching_profile);
      else if (teaching_profile.total_hours && teaching_profile.total_hours < 0)
        teaching_profile.total_hours = 0;
    },
    patchTeachingProfile(teaching_profile) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.saveTeachingProfile(teaching_profile);
      }, 1000);
    },
    mutateTeachingProfile(response) {
      const index = this.teaching_profiles.findIndex(
        (x) => x.id == response.id
      );
      if (index != -1) {
        this.teaching_profiles[index].professional_title =
          response.professional_title;
        this.teaching_profiles[index].academic_degree =
          response.academic_degree;
        this.teaching_profiles[index].specialization = response.specialization;
        this.teaching_profiles[index].required_skills =
          response.required_skills;
        this.teaching_profiles[index].total_hours = response.total_hours;
        this.teaching_profiles[index].experience_years =
          response.experience_years;
      }
    },
    createTeachingProfileBase() {
      this.createTeachingProfile({
        professional_title: "",
        academic_degree: "",
        specialization: "",
        required_skills: "",
        total_hours: 0,
        experience_years: 0,
      });
    },
    createTeachingProfile(teaching_profile) {
      if (this.matter.matter) {
        teaching_profile.matter = this.matter.matter;
        this.$restful
          .Post(`/teaching/teaching-profile/`, teaching_profile)
          .then((response) => {
            this.teaching_profiles.push(response);
            toast("Perfil Docente creado.");
          });
      }
    },
    updateTeachingProfile(teaching_profile) {
      this.$restful
        .Put(
          `/teaching/teaching-profile/${teaching_profile.id}/`,
          teaching_profile
        )
        .then((response) => {
          if (response) {
            this.mutateTeachingProfile(response);
            toast("Perfil Docente actualizado.");
          }
        });
    },
    deleteTeachingProfile(teaching_profile) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Perfil Docente?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(`/teaching/teaching-profile/${teaching_profile.id}/`)
            .then(() => {
              const index = this.teaching_profiles.findIndex(
                (x) => x.id == teaching_profile.id
              );
              if (index != -1) {
                this.teaching_profiles.splice(index, 1);
                toast("Se elimino el Perfil Docente.");
              }
            });
        }
      });
    },
    fetchSupportResource() {
      if (this.matter) {
        this.$restful
          .Get(`/teaching/support-resource/?matter=${this.matter.matter}`)
          .then((response) => {
            this.support_resources = response;
          });
      }
    },
    createSupportResource(text, resource_type) {
      if (this.matter.matter) {
        let item = {
          name: text,
          description: text,
          type: resource_type.id,
          matter: this.matter.matter,
        };
        this.$restful
          .Post(`/teaching/support-resource/`, item)
          .then((response) => {
            this.support_resources.push(response);
            this.$bvModal.hide(`create-resource-modal-${resource_type.id}`);
          });
      }
    },
    updateSupportResource(resource, text) {
      // if (!args[0] || args[0] == "") {
      //   this.deleteSupportResource(resource_id);
      //   return;
      // }
      let item = {
        name: text,
        description: text,
      };
      // resource.name, (resource.description = text);
      this.$restful
        .Patch(`/teaching/support-resource/${resource.id}/`, item)
        .then((response) => {
          let index = this.support_resources.findIndex(
            (b) => b.id == resource.id
          );
          if (this.support_resources[index]) {
            this.support_resources[index] = response;
            this.resource_content_key += 1;
            this.$bvModal.hide(`edit-resource-modal-${resource.id}`);
          }
        });
    },
    deleteSupportResource(resource_id) {
      this.$restful
        .Delete(`/teaching/support-resource/${resource_id}/`)
        .then(() => {
          let index = this.support_resources.findIndex(
            (r) => r.id == resource_id
          );
          if (index != -1) this.support_resources.splice(index, 1);
        });
    },
    fetchBibliography() {
      if (this.matter) {
        this.$restful
          .Get(`/teaching/bibliographic-resource/?matter=${this.matter.matter}`)
          .then((response) => {
            this.bibliographies = response.sort((a, b) => a.id - b.id);
          });
      }
    },
    createBibliography(text, bibliography_type) {
      if (this.matter.matter) {
        // if (!args[0] || args[0] == "") {
        //   return;
        // }
        let item = {
          name: text,
          description: text,
          type: bibliography_type.id,
          matter: this.matter.matter,
        };
        this.$restful
          .Post(`/teaching/bibliographic-resource/`, item)
          .then((response) => {
            this.bibliography.push(response);
            this.$bvModal.hide(
              `create-bibliographic-resource-modal-${bibliography_type.id}`
            );
          });
      }
    },
    updateBibliography(biblio, text) {
      // if (!args[0] || args[0] == "") {
      //   this.deleteBibliography(bibliography_id);
      //   return;
      // }
      let item = {
        name: text,
        description: text,
      };
      this.$restful
        .Patch(`/teaching/bibliographic-resource/${biblio.id}/`, item)
        .then((response) => {
          let index = this.bibliography.findIndex((b) => b.id == biblio.id);
          this.bibliography[index] = response;
          this.biblio_content_key += 1;
          this.$bvModal.hide(`edit-bibliographic-resource-modal-${biblio.id}`);
        });
    },
    deleteBibliography(bibliography_id) {
      this.$restful
        .Delete(`/teaching/bibliographic-resource/${bibliography_id}/`)
        .then(() => {
          let index = this.bibliography.findIndex(
            (b) => b.id == bibliography_id
          );
          if (index != -1) this.bibliography.splice(index, 1);
        });
    },
    // updateMatter() {
    //   if (!this.allows_crud) return;
    //   this.$store.dispatch(names.UPDATE_MATTER, this.matter);
    //   //this.saveTeachingProfile();
    //   toast("Asignatura actualizada.");
    // },
    redirectToMatrix2() {
      this.$router.push({
        path: `/matrix2/form/${this.matter.egress_profile}`,
      });
    },
    patchMatter(item) {
      if (!this.allows_crud) return;
      if (!this.matter) return;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const payload = {
          matter_id: this.matter.matter,
          item: item,
        };
        this.$store.dispatch(names.PATCH_MATTER, payload).then(() => {
          toast(
            this.$getVisibleNames(
              "mesh.egressprofilematter",
              false,
              "Asignatura"
            ) + " actualizada."
          );
        });
      }, 1000);
    },
    closeModal(resource_id) {
      this.$bvModal.hide(`edit-resource-modal-${resource_id}`);
      this.$bvModal.hide(`create-resource-modal-${resource_id}`);
    },
    redirectToLearningSuitcase(matter_id) {
      // this.$router.push({
      let route_data = this.$router.resolve({
        name: "LearningSuitcaseView",
        params: { egress_profile_matter_id: matter_id },
      });
      window.open(route_data.href, "_blank");
    },
    redirectToLearningSuitcaseStudent(matter_id) {
      // this.$router.push({
      let route_data = this.$router.resolve({
        name: "LearningSuitcaseStudentView",
        params: { egress_profile_matter_id: matter_id },
      });
      window.open(route_data.href, "_blank");
    },
    redirectToLearningSuitcaseTeacher(matter_id) {
      // this.$router.push({
      let route_data = this.$router.resolve({
        name: "LearningSuitcaseTeacherView",
        params: { egress_profile_matter_id: matter_id },
      });
      window.open(route_data.href, "_blank");
    },
    redirectToLearningExperience(matter_id) {
      // this.$router.push({
      let route_data = this.$router.resolve({
        name: "LearningExperiencesView",
        params: { egress_profile_matter_id: matter_id },
      });
      window.open(route_data.href, "_blank");
    },
  },
  watch: {
    description() {
      this.patchMatter({ description: this.description });
    },
    // teaching_profile: {
    //   handler(item) {
    //     if (!this.allows_crud) return;
    //     if (this.timeout) clearTimeout(this.timeout);
    //     this.timeout = setTimeout(() => {
    //       this.saveTeachingProfile(item);
    //     }, 2000);
    //   },
    //   deep: true,
    // },
    matter() {
      this.fetchSupportResource();
      this.fetchBibliography();
      this.fetchTeachingProfile();
    },
    resource_content_key() {
      this.fetchSupportResource();
    },
    biblio_content_key() {
      this.fetchBibliography();
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS_TIME_ALLOCATION);
    this.$store.dispatch(names.FETCH_TIME_ALLOCATIONS);
    this.$store.dispatch(names.FETCH_CURRENT_POSITION, this.user.id);
    this.$store.dispatch(names.FETCH_REGIMES);
    this.$store.dispatch(names.FETCH_COMPETENCE_TYPES);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS);
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES);
    this.$store.dispatch(
      names.FETCH_EGRESS_PROFILE_MATTER_DESCRIPTORS,
      this.egress_profile_matter_id
    );
    this.$store.dispatch(
      names.FETCH_EPM_MIDDLE_DESCRIPTORS,
      this.egress_profile_matter_id
    );
    this.$store.dispatch(names.FETCH_SUPPORT_RESOURCE_TYPES);
    this.$store.dispatch(names.FETCH_BIBLIOGRAPHIC_RESOURCE_TYPES);
    this.fetchSupportResource();
    this.fetchBibliography();
    this.fetchTeachingProfile();
    this.$store
      .dispatch(
        names.FETCH_EGRESS_PROFILE_MATTER,
        this.egress_profile_matter_id
      )
      .then((response) => {
        this.$store.dispatch(
          names.FETCH_EVALUATION_CRITERIAS_MACROS,
          response.matter
        );
        this.$store.dispatch(names.FETCH_M_MIDDLE_DESCRIPTORS, response.matter);
        this.$store.dispatch(names.FETCH_RA_MACROS, {
          matter_id: response.matter,
        });
        this.$store.dispatch(
          names.FETCH_EGRESS_PROFILE,
          response.egress_profile
        );
        this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES_MENTION, {
          egress_profile_id: response.egress_profile,
        });
        this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES, {
          egress_profile_id: response.egress_profile,
        });
        this.$store.dispatch(names.FETCH_COMPETENCES, response.egress_profile);
        this.$store.dispatch(names.FETCH_MATTER, response.matter);
        this.$store.dispatch(names.FETCH_STUDY_UNITS, response.matter);
      });
    this.$store.dispatch(names.FETCH_TAXONOMIES);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.learning-suitcase-btn {
  margin-left: auto;
  margin-right: 0 !important;
}
table {
  width: 100%;
  table-layout: auto;
}
.study-unit-button-div {
  float: right;
  padding-right: 1rem;
}
.criteria-container {
  display: flex;
  flex-direction: row;
}
.criteria {
  width: 80%;
  text-align: left;
  padding-left: 0.4em;
}
.criteria-weighing {
  width: 20%;
}
table td {
  border: 1px solid black;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
table th {
  vertical-align: middle;
  border: 1px solid black;
  background-color: var(--primary-color) !important;
  color: white;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.th-header {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-size: var(--primary-font-size);
}
.w-20 {
  width: 20%;
}
.w-40 {
  width: 40%;
}
table td input[type="text"] {
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  min-height: 100%;
}
.table-ecmacro th,
.table-ecmacro td {
  border-left: 1px !important;
  border-bottom: 1px !important;
}
.table-ecmacro th {
  border-top: 1px !important;
}
.table-ecmacro th:last-of-type,
.table-ecmacro td:last-of-type {
  border-right: none;
}
.tr-ecmacro {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  border: 1px solid black;
}
.th-ecmacro {
  border: none;
  border-inline: 1px solid black;
}
.td-ecmacro .table-responsive {
  margin-bottom: 0 !important;
}
.td-ecmacro {
  padding: 0 !important;
}
p {
  /* text-align: justify; */
  padding-left: 3px;
}
.wrapper {
  margin: 15px;
  padding: 15px;
}
.wrapper2 {
  text-align: justify;
  margin: 15px;
  padding: 15px;
}
li {
  text-align: justify;
}
.indicator-div {
  margin: 1px 4px;
  padding: 1px 2px;
}
.indicator-p {
  margin: 1px 1px;
  padding: 5px 3px;
  text-align: justify;
  background: rgb(200, 198, 196);
}
.textarea {
  height: fit-content;
  min-height: 30px;
  width: 100%;
  border: 1px solid var(--secondary-color);
}
.input-group {
  min-width: max-content;
}
.tr-competences {
  overflow-x: auto;
}
.competences-header {
  margin-left: auto !important;
  margin-right: 0 !important;
}
.competences-header span {
  display: inline-flex;
  margin-left: 6em;
}
.btn-edit-competences {
  margin-left: auto;
  margin-right: 0;
}
.teacher-input {
  margin-left: auto;
  margin-right: 0;
}
.learning-suitcase-buttons {
  margin-left: 0;
  margin-right: auto;
  display: -webkit-box;
  margin-bottom: 0.5em;
  transform: translateY(-10%);
}
/* .bibliography-content {
  max-width: calc(100% - 100px);
  word-wrap: break-word;
  text-overflow: ellipsis;
} */
@media print {
  .bibliography-content,
  .support-resource-content {
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 800px !important;
  }
}
/* .biblio-header {
  vertical-align: middle;
  background-color: #d9d9d9 !important;
} */
</style>