<template>
  <div>
    <div class="mx-2">
      <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_modality'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-modality-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "mesh.modality",
                false,
                "Modalidad de Asignaturas"
              )
            }}
            <!-- Modalidad -->
          </button>
        </div>
      </div>
      <h3>
        {{
          $getVisibleNames("mesh.modality", false, "Modalidad de Asignaturas")
        }}
      </h3>
      <GenericBTable
        v-if="searchModality.length > 0"
        :items="modalities"
        :pagination="modalities.length"
        :fields="modalitiesFields"
        :filter="input_search"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="display_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template> </template>
        <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            @click="$bvModal.show(`edit-modality-modal-${row.item.id}`)"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.modality',
                false,
                'Modalidad de Asignaturas'
              )}`
            "
          ></button-edit>
          <span
            v-if="
              institution &&
              institution.id == 12 &&
              (row.item.id == 33 || row.item.id == 42)
            "
            v-b-tooltip.v-secondary.auto.noninteractive="
              `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`
            "
          >
            <button-delete disabled></button-delete>
          </span>
          <button-delete
            v-else
            @click="askForDeleteModality(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'mesh.modality',
                false,
                'Modalidad de Asignaturas'
              )}`
            "
          ></button-delete>
          <b-modal
            :id="`edit-modality-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.modality',
              false,
              'Modalidad de Asignaturas'
            )}`"
            size="md"
            hide-footer
          >
            <ModalityForm
              :Modality="row.item"
              @updated="slotUpdatedModality"
              :institution_id="row.item.school"
              :show_title="false"
            ></ModalityForm>
          </b-modal>
        </template>
      </GenericBTable>
      <div v-else>
        <br />
        <strong
          >No se a encontrado ninguna
          {{
            $getVisibleNames(
              "mesh.modality",
              false,
              "Modalidad de Asignaturas"
            )
          }}.</strong
        >
      </div>
    </div>
    <b-modal
      :id="`new-modality-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.modality',
        false,
        'Modalidad de Asignaturas'
      )}`"
      size="md"
    >
      <ModalityForm
        @created="slotCreatedModality"
        :institution_id="this.user.school"
      ></ModalityForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "ModalityView",
  components: {
    ModalityForm: () =>
      import("@/components/mesh/CurricularResources/Modality/ModalityForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    input_search: {
      type: String,
      default: "",
    },

    display_id: {},
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      modalities: names.MODALITIES,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_modality");
      if (has_permission) return has_permission;
      else return false;
    },
    searchModality: function () {
      return this.modalities
        .filter((item) => {
          return (
            item.name.toLowerCase().includes(this.input_search.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(this.input_search.toLowerCase())
          );
        })
        .sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
    modalitiesFields() {
      if (!this.allows_crud)
        return this.modalities_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.modalities_fields;
    },
    breadcrumb_items() {
      return [
        { text: "Definiciones Curriculares", active: true },
        {
          text: this.$getVisibleNames(
            "mesh.modality",
            false,
            "Modalidad de Asignaturas"
          ),
          active: true,
        },
      ];
    },
    modalities_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.modality",
              true,
              "Modalidades de Asignaturas"
            )
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "order",
          label: "Orden",
          sortable: true,
          tdClass: "text-center",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    askForDeleteModality(modality_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.modality",
          false,
          "Modalidad de Asignaturas"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_MODALITY, modality_id).then(() => {
            toast(
              String(
                this.$getVisibleNames(
                  "mesh.modality",
                  false,
                  "Modalidad de Asignaturas"
                ) + " eliminada."
              )
            );
          });
        }
      });
    },
    slotCreatedModality() {
      this.$bvModal.hide("new-modality-modal");
    },
    slotUpdatedModality(modality) {
      this.$bvModal.hide(`edit-modality-modal-${modality.id}`);
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch(names.FETCH_MODALITIES, this.user.school);
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped>
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}

.title-header {
  padding: 0;
  margin-top: auto;
}
.search-modality {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
</style>