<template>
  <div
    class="question-container"
    v-bind:style="questionContainerStyle"
    @click="slotCardSelected"
  >
    <div class="question-body py-3">
      <div class="row-div">
        <div class="row-info-div">
          <div class="title-div">
            <div class="row py-2">
              <div class="pr-1 pt-1 ml-2">
                <strong>{{ Question.order }}</strong
                >.
              </div>
              <div class="text-left pt-1" v-html="Question.title"></div>
            </div>
          </div>
        </div>
        <div class="vl"></div>
        <div class="footer-buttons-div">
          <div class="type-div pb-2">
            <strong>{{ questionType ? questionType.value : "-" }}</strong>
          </div>
        </div>
      </div>
      <hr />
      <ImagesContainer
        :Images="topQuestionImages"
        :allows_crud="false"
      ></ImagesContainer>
      <div v-if="show_description" class="content-div">
        <p v-html="Question.description"></p>
      </div>

      <template v-if="Question.question_type == 2">
        <div class="mb-2">
          <template
            v-if="
              select_question_answer &&
              select_question_answer.selected.length == 1
            "
          >
            <b-form-checkbox
              v-for="option in options"
              :key="option.id"
              :checked="select_question_answer.selected == option.id"
              class="text-justify mb-1"
              disabled
              >{{ option.text }}
            </b-form-checkbox>
          </template>
          <template v-else>
            <b-form-checkbox
              v-for="option in options"
              :key="option.id"
              :checked="getCheckedOption(option.id)"
              class="text-justify mb-1"
              disabled
              >{{ option.text }}
            </b-form-checkbox>
          </template>
        </div>
      </template>
      <ImagesContainer
        :Images="bottomQuestionImages"
        :allows_crud="false"
      ></ImagesContainer>
      <hr />
      <div class="row-div">
        <div class="row-info-div pt-1">
          <div class="px-2">Puntaje Máximo: {{ Question.score }}</div>
          <!-- <div class="px-2">Ponderación {{ Question.weighing }}%</div> -->
          <div class="automated-score mb-1" v-if="student_id">
            Puntaje Automático:
            <strong class="ml-1">{{
              select_question_answer.automated_score
            }}</strong>
          </div>
        </div>
        <div class="vl"></div>
        <div class="footer-buttons-div">
          <div class="mb-1" v-if="student_id && institution">
            Puntaje Obtenido:
            <template
              v-if="
                institution.actual_period != null &&
                institution.actual_period.id == period_id
              "
            >
              <strong
                ><StyledInputNumber
                  v-model="manual_score.score"
                  style="width: 80px"
                  @input="patchAnswerScore"
                ></StyledInputNumber
              ></strong>
            </template>
            <template v-else>
              {{ manual_score.score }}
            </template>
          </div>
        </div>
        <!-- <div class="mb-1" v-if="student_id">
            Puntaje Obtenido
            <strong
              ><StyledInputNumber
                v-model="manual_score.score"
                style="width: 80px"
                @input="patchAnswerScore"
              ></StyledInputNumber
            ></strong>
          </div> -->
      </div>
      <b-button
        variant="none"
        class="btn-feedback"
        size="sm"
        v-b-toggle="`collapse-feedback-${Question.id}`"
      >
        Retroalimentar
      </b-button>
      <b-collapse :id="`collapse-feedback-${Question.id}`" v-if="institution">
        <b-form-textarea
          size="sm"
          v-model="manual_score.feedback"
          rows="3"
          :disabled="
            !student_id ||
            (institution.actual_period != null &&
              institution.actual_period.id != period_id)
          "
          max-rows="6"
          @change="patchAnswerScore"
        ></b-form-textarea>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast, generateUniqueId } from "@/utils/utils";

export default {
  name: "EvaluateSelectQuestion",
  components: {
    StyledInputNumber: () => import("@/components/reusable/StyledInputNumber"),
    ImagesContainer: () => import("@/components/reusable/ImagesContainer"),
  },
  props: {
    Question: {
      type: Object,
      required: true,
    },
    is_selected: {
      type: Boolean,
      default: false,
    },
    student_id: {
      type: Number,
    },
    period_id: {
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    question_type: {
      type: Number,
    },
  },
  data() {
    return {
      temp_options: [],
      show_feedback: false,
      show_description: true,
      imageAlignClass: {
        justifyContent: "center",
      },
      select_question_answer: {
        id: generateUniqueId(),
        question: this.Question.id,
        student: this.student_id,
        selected: [],
        automated_score: null,
      },
      manual_score: {
        id: generateUniqueId(),
        score: null,
        feedback: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      questionTypes: names.QUESTION_TYPES,
      scoreTypes: names.SCORE_TYPES,
      questions: names.QUESTIONS,
      evaluationInstruments: names.EVALUATION_INSTRUMENTS,
      images: names.IMAGES,
      users: names.USERS,
      questionOptions: names.MULTIPLE_CHOICE_QUESTION_OPTIONS,
      institution: "getInstitution",
    }),
    options() {
      return this.questionOptions.filter((x) => x.question == this.Question.id);
    },
    user() {
      if (!this.student_id) return null;
      const user = this.users.find((x) => x.id == this.student_id);
      if (user) return user;
      return null;
    },
    questionType() {
      return this.questionTypes.find(
        (x) => x.id == this.Question.question_type
      );
    },
    questionContainerStyle() {
      return {
        backgroundColor: this.is_selected ? "var(--kl-menu-color)" : "white",
      };
    },
    questionImages() {
      let list = [];
      this.Question.images.forEach((element) => {
        const instance = this.images.find((x) => x.id == element);
        if (instance) list.push(instance);
      });
      return list;
    },
    topQuestionImages() {
      return this.questionImages.filter((x) => x.vertical_align == 1);
    },
    bottomQuestionImages() {
      return this.questionImages.filter((x) => x.vertical_align == 2);
    },
  },
  methods: {
    slotCardSelected() {
      this.$emit("selected", this.Question.id);
    },
    fetchAnswer() {
      if (!this.student_id) return;
      this.select_question_answer.student = this.student_id;
      this.fetchSelectQuestionAnswer();
    },
    patchSelectQuestionAnswer(item) {
      this.$restful
        .Patch(`/evaluation/manual-score/${this.manual_score.id}/`, item)
        .then((response) => {
          this.manual_score = response;
          this.$emit("updateAutomatedScore");
          toast("Puntaje actualizado.");
        });
    },
    patchAnswerScore() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.Question.question_type == 1) {
          // Desarrollo Breve y Extenso
        } else if (this.Question.question_type == 2) {
          // Selección Múltiple
          if (isNaN(this.select_question_answer.id))
            this.createSelectQuestionAnswer();
          else if (this.manual_score.score == "") {
            this.patchSelectQuestionAnswer({
              score: 0,
              feedback: this.manual_score.feedback,
            });
          } else
            this.patchSelectQuestionAnswer({
              score: this.manual_score.score,
              feedback: this.manual_score.feedback,
            });
        }
      }, 2000);
    },
    createSelectQuestionAnswer() {
      this.$restful
        .Post(
          `/evaluation/multiple-choice-question-answer/`,
          this.select_question_answer
        )
        .then((response) => {
          this.select_question_answer = response;
          this.manual_score.id = response.score_and_feedback.id;
          this.patchSelectQuestionAnswer({
            score: this.manual_score.score,
            feedback: this.manual_score.feedback,
          });
          this.$emit("updateAutomatedScore");
          toast("Puntaje actualizado.");
        });
    },
    // patchSelectAnswerScore() {
    //   if (this.timeout) clearTimeout(this.timeout);
    //   this.timeout = setTimeout(() => {
    //     let item = {
    //       score: this.manual_score.score,
    //       feedback: this.manual_score.feedback,
    //     };
    //     this.$restful
    //       .Patch(`/evaluation/manual-score/${this.manual_score.id}/`, item)
    //       .then((response) => {
    //         this.manual_score = response;
    //         this.$emit("updateAutomatedScore");
    //         toast("Puntaje actualizado.");
    //       });
    //   }, 2000);
    // },
    fetchSelectQuestionAnswer() {
      this.$restful
        .Get(
          `/evaluation/multiple-choice-question-answer/?question=${this.Question.id}&student=${this.student_id}`
        )
        .then((response) => {
          if (response.length > 0) {
            this.select_question_answer = response[0];
            this.$restful
              .Get(
                `/evaluation/manual-score/${this.select_question_answer.score_and_feedback.id}/`
              )
              .then((manual_score_response) => {
                this.manual_score = manual_score_response;
              });
          }
          if (response.length > 1)
            console.log("SelectQuestionAnswer = ", response.length);
        });
    },
    getCheckedOption(option_id) {
      if (
        this.select_question_answer &&
        this.select_question_answer.selected.includes(option_id)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    student_id() {
      this.select_question_answer = {
        id: generateUniqueId(),
        question: this.Question.id,
        student: this.student_id,
        score: null,
        selected: [],
        returned: false,
        feedback: null,
      };
      this.manual_score = {
        id: generateUniqueId(),
        score: null,
        feedback: "",
      };
      this.fetchAnswer();
    },
  },
  created() {
    this.$store.dispatch(
      names.FETCH_MULTIPLE_CHOICE_QUESTION_OPTIONS,
      this.Question.id
    );
    this.Question.images.forEach((element) => {
      this.$store.dispatch(names.FETCH_IMAGE, element);
    });
  },
};
</script>

<style scoped>
.question-container {
  border-radius: 7px;
  margin-top: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 2px 8px -3px var(--secondary-color);
  border: 1px solid rgb(237, 237, 237);
}
.question-body {
  background-color: white;
  margin-left: 5px;
  padding: 0px 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.title-div {
  width: 75%;
  text-align: left;
}
.content-div {
  margin: 0.1em 0.5em;
  display: flex;
  flex-direction: column;
  align-items: initial;
}
.row-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.row-info-div {
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.automated-score {
  margin-right: 0;
  margin-left: auto;
  padding-right: 1em;
}
.footer-buttons-div {
  width: 29%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.text-left p {
  margin-bottom: 0 !important;
}
.type-div {
  width: 100%;
  align-self: center;
  text-align: center;
  /* text-align: left;
  margin-left: .5em; */
}
a {
  text-align: left;
  width: min-content;
  min-width: 25%;
  vertical-align: middle;
}
hr {
  background-color: var(--secondary-color);
  height: 1px;
  margin: 0px 0px;
}
.vl {
  border-left: 1px solid var(--secondary-color);
  padding: 0px;
  margin: 0px;
}
.btn-feedback {
  background-color: var(--primary-color);
}
.btn-feedback:hover {
  background-color: #add3e0;
}
</style>