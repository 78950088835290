<template>
  <div>
    <b-table-simple small responsive>
      <b-thead>
        <b-tr>
          <b-th
            :colspan="allows_crud ? 8 : 7"
            style="text-align: center"
            class="pl-3 evaluation-header"
          >
            ACTIVIDADES Y EVALUACIONES
            <div class="evaluation-button-div">
              <b-button
                v-if="allows_crud"
                class="mx-1"
                size="sm"
                @click="
                  $bvModal.show(
                    `add-evaluation-modal-${egress_profile_matter_id}`
                  )
                "
                >Crear Actividad o Evaluación</b-button
              >
            </div>
          </b-th>
        </b-tr>
        <b-tr>
          <b-td
            style="width: 3%"
            class="text-center"
            v-if="allows_crud && profile_matters_evaluations.length > 0"
          >
          </b-td>
          <b-td style="width: 15%" class="text-center">
            <strong> NOMBRE </strong>
          </b-td>
          <b-td style="width: 15%" class="text-center">
            <strong> DESCRIPCIÓN </strong>
          </b-td>
          <b-td style="width: 15%" class="text-center">
            <strong> TIPO </strong>
          </b-td>
          <b-td style="width: 15%" class="text-center">
            <strong>PONDERACIÓN</strong>
          </b-td>
          <b-td
            colspan="1"
            style="width: 15%"
            class="text-center"
            v-if="institution && institution.show_evaluation_criteria_macro"
          >
            <strong>
              {{
                $getVisibleNames(
                  "teaching.evaluationcriteriamacro",
                  true,
                  "Criterios de Evaluación Macro"
                ).toUpperCase()
              }}</strong
            >
          </b-td>
          <b-td colspan="1" style="width: 15%" class="text-center">
            <strong>
              {{
                $getVisibleNames(
                  "teaching.evaluationcriteriamicro",
                  true,
                  "Criterios de Evaluación Micro"
                ).toUpperCase()
              }}</strong
            >
          </b-td>
          <b-td
            style="width: 2%"
            v-if="allows_crud && profile_matters_evaluations.length > 0"
          ></b-td>
        </b-tr>
      </b-thead>
      <template v-for="item in learning_experiences">
        <template
          v-if="
            profile_matters_evaluations.filter(
              (x) => x.learning_experience == item.id
            ).length > 0
          "
        >
          <b-thead :key="'learning-experiences-1' + item.id">
            <b-tr>
              <b-th
                style="text-align: left"
                class="pl-3 evaluation-header"
                :colspan="allows_crud ? 8 : 7"
                >{{ item.name }}</b-th
              >
            </b-tr>
          </b-thead>
          <EPMatterProgramEvaluationDraggable
            :key="'learning-experiences-2' + item.id + tmp_key"
            :allows_crud="allows_crud"
            :mattersEvaluations="
              profile_matters_evaluations.filter(
                (x) => x.learning_experience == item.id
              )
            "
            :egress_profile_matter_id="egress_profile_matter_id"
            :matter_id="matter.matter"
            :EvaluationType="evaluation_types"
            :EvaluationSituations="evaluation_situations"
            :ExaminerTypes="examiner_types"
            :EvaluativeAgentTypes="evaluative_agent_types"
            :WorkTypes="work_types"
            :EPMatEvaStuEnvTimAll="ep_mat_eva_stu_env_tim_all"
            @created_support_resource="createdSupportResource"
          ></EPMatterProgramEvaluationDraggable>
        </template>
      </template>
      <template
        v-if="
          profile_matters_evaluations.filter(
            (x) => x.learning_experience == null
          ).length > 0
        "
      >
        <b-thead
          :key="'learning-experiences-1' + null"
          v-if="learning_experiences.length > 0"
        >
          <b-tr>
            <b-th
              style="text-align: left"
              class="p-3 evaluation-header"
              :colspan="allows_crud ? 8 : 7"
            ></b-th>
          </b-tr>
        </b-thead>
        <EPMatterProgramEvaluationDraggable
          :key="'learning-experiences-2' + null + tmp_key"
          :allows_crud="allows_crud"
          :mattersEvaluations="
            profile_matters_evaluations.filter(
              (x) => x.learning_experience == null
            )
          "
          :egress_profile_matter_id="egress_profile_matter_id"
          :matter_id="matter.matter"
          :EvaluationType="evaluation_types"
          :EvaluationSituations="evaluation_situations"
          :ExaminerTypes="examiner_types"
          :EvaluativeAgentTypes="evaluative_agent_types"
          :WorkTypes="work_types"
          :EPMatEvaStuEnvTimAll="ep_mat_eva_stu_env_tim_all"
          @created_support_resource="createdSupportResource"
        ></EPMatterProgramEvaluationDraggable>
      </template>
      <!-- <draggable
        v-model="draggableList"
        :disabled="!allows_crud"
        tag="b-tbody"
        handle=".handle"
      >
        <template v-if="draggableList.length > 0">
          <b-tr v-for="evaluation in draggableList" :key="evaluation.id">
            <b-td
              class="text-center handle"
              :class="{ grabbable: allows_crud }"
              v-if="allows_crud"
              ><b-icon
                v-if="allows_crud"
                icon="arrows-expand"
                scale="1.2"
              ></b-icon
            ></b-td>
            <b-td class="text-center"> {{ evaluation.name }}</b-td>
            <b-td
              class="rich-text-content px-2"
              v-html="evaluation.description"
            ></b-td>
            <b-td class="text-center">
              {{
                evaluation_types.find((x) => x.id == evaluation.type)
                  ? evaluation_types.find((x) => x.id == evaluation.type).value
                  : "N/A"
              }}</b-td
            >
            <b-td class="text-center">{{ evaluation.weighing }}%</b-td>
            <b-td
              v-if="institution && institution.show_evaluation_criteria_macro"
              :class="
                evaluation.evaluation_criterias_macro.length > 0
                  ? 'text-justify'
                  : 'text-center'
              "
            >
              <template v-if="evaluation.evaluation_criterias_macro.length > 0">
                <template
                  v-for="evaluation_criteria in evaluationCriteriasMacrosFilter"
                >
                  <span
                    :key="evaluation_criteria.id"
                    v-if="
                      evaluation.evaluation_criterias_macro.includes(
                        evaluation_criteria.id
                      )
                    "
                  >
                    <span style="position: absolute">
                      {{ evaluation_criteria.order }}
                    </span>
                    <SentenceContainer
                      style="padding-left: 5px; padding-top: 2px"
                      :Sentence="evaluation_criteria"
                      :boundedTextContainer="false"
                    ></SentenceContainer>
                  </span>
                </template>
              </template>
              <template v-else> N/A </template>
            </b-td>
            <b-td
              :class="
                evaluation.evaluation_criterias_micro.length > 0
                  ? 'text-justify'
                  : 'text-center'
              "
            >
              <template v-if="evaluation.evaluation_criterias_micro.length > 0">
                <template v-for="study_unit in filterECMicro">
                  <strong
                    :key="study_unit.id"
                    v-if="
                      study_unit.options.filter((x) =>
                        evaluation.evaluation_criterias_micro.includes(x.id)
                      ).length > 0
                    "
                    >{{ study_unit.name }}</strong
                  >
                  <div
                    class="ec-badge-container"
                    :key="'ec-container' + study_unit.id"
                  >
                    <div
                      v-for="evaluation_criteria in study_unit.options"
                      :key="study_unit.id + ':' + evaluation_criteria.id"
                    >
                      <div
                        v-if="
                          evaluation.evaluation_criterias_micro.includes(
                            evaluation_criteria.id
                          )
                        "
                        :key="
                          'div' + study_unit.id + ':' + evaluation_criteria.id
                        "
                        class="text-center"
                      >
                        <b-badge
                          :id="`ec-badge-${evaluation.id}-${study_unit.id}-${evaluation_criteria.id}`"
                          class="ec-micro-badge"
                          v-b-tooltip.v-secondary.noninteractive="
                            `${evaluation_criteria.full_sentence}`
                          "
                        >
                          {{ study_unit.order }}.{{ evaluation_criteria.order }}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else><span> N/A </span> </template>
            </b-td>
            <b-td class="text-center" v-if="allows_crud">
              <ButtonWrapper :item_id="evaluation.id">
                <template v-slot:btn-slot>
                  <b-button
                    v-if="allows_crud"
                    size="sm"
                    fill="white"
                    variant="none"
                    v-b-tooltip.v-secondary.noninteractive.top="
                      'Editar Recursos Bibliográficos'
                    "
                    @click="
                      $bvModal.show(
                        `epm-bibliographic-resource-modal-${evaluation.id}`
                      )
                    "
                  >
                    <b-icon icon="book"></b-icon>
                  </b-button>
                  <button-edit
                    v-if="allows_crud"
                    fill="white"
                    v-b-tooltip.v-secondary.noninteractive.top="
                      'Editar Actividad/Evaluación'
                    "
                    @click="
                      $bvModal.show(`edit-evaluation-modal-${evaluation.id}`)
                    "
                  ></button-edit>
                  <button-delete
                    v-b-tooltip.v-secondary.noninteractive.top="
                      'Eliminar Actividad/Evaluación'
                    "
                    @click="deleteEvaluation(evaluation.id, evaluation.type)"
                  ></button-delete> </template
              ></ButtonWrapper>
              <b-modal
                :id="`epm-bibliographic-resource-modal-${evaluation.id}`"
                title="Editar Recursos Bibliográficos"
                size="xl"
                hide-footer
              >
                <EPMEvaluationBibliographicResource
                  :epm_evaluation_id="evaluation.id"
                  :ep_matter="matter"
                  :allows_crud="allows_crud"
                ></EPMEvaluationBibliographicResource>
              </b-modal>
              <b-modal
                :id="`edit-evaluation-modal-${evaluation.id}`"
                title="Editar Actividad/Evaluación"
                size="xl"
                hide-footer
              >
                <EPMatterProgramEvaluationForm
                  :egress_profile_matter_id="egress_profile_matter_id"
                  :Evaluation="evaluation"
                  :EvaluationType="evaluation_types"
                  :EvaluationSituations="evaluation_situations"
                  :evaluation_list="profile_matters_evaluations"
                  :ExaminerTypes="examiner_types"
                  :EvaluativeAgentTypes="evaluative_agent_types"
                  :WorkTypes="work_types"
                  :EPMatEvaStuEnvTimAll="ep_mat_eva_stu_env_tim_all"
                  @created="createdEvaluation"
                  @updated="updatedEvaluation"
                  @created_support_resource="createdSupportResource"
                >
                </EPMatterProgramEvaluationForm>
              </b-modal>
            </b-td>
          </b-tr>
        </template>
        <b-tr v-else>
          <b-td class="text-center" colspan="8"
            >No hay actividades o evaluaciones para mostrar.</b-td
          >
        </b-tr>
      </draggable> -->
    </b-table-simple>
    <b-modal
      :id="`add-evaluation-modal-${egress_profile_matter_id}`"
      title="Crear Actividad o Evaluación"
      size="xl"
      hide-footer
    >
      <EPMatterProgramEvaluationForm
        :egress_profile_matter_id="egress_profile_matter_id"
        :evaluation_list="profile_matters_evaluations"
        :EvaluationType="evaluation_types"
        :EvaluationSituations="evaluation_situations"
        :ExaminerTypes="examiner_types"
        :EvaluativeAgentTypes="evaluative_agent_types"
        :WorkTypes="work_types"
        :EPMatEvaStuEnvTimAll="ep_mat_eva_stu_env_tim_all"
        @created="createdEvaluation"
        @updated="updatedEvaluation"
        @created_support_resource="createdSupportResource"
      >
      </EPMatterProgramEvaluationForm>
    </b-modal>
  </div>
</template>

<script>
// import draggable from "vuedraggable";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "EPMatterProgramEvaluation",
  props: {
    egress_profile_matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // draggable,
    EPMatterProgramEvaluationForm: () =>
      import("./EPMatterProgramEvaluationForm.vue"),
    EPMatterProgramEvaluationDraggable: () =>
      import("./EPMatterProgramEvaluationDraggable.vue"),
    // EPMEvaluationBibliographicResource: () =>
    //   import("./EPMEvaluationBibliographicResource.vue"),
    // SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    // ButtonWrapper: () => import("@/components/reusable/ButtonWrapper"),
  },
  data() {
    return {
      profile_matters_evaluations: [],
      evaluation_types: [],
      evaluation_situations: [],
      examiner_types: [],
      evaluative_agent_types: [],
      work_types: [],
      ep_mat_eva_stu_env_tim_all: [],
      learning_experiences: [],
      tmp_key: 0,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      studyUnits: names.STUDY_UNITS,
    }),
    // draggableList: {
    //   get() {
    //     let list = this.profile_matters_evaluations;
    //     list.sort(function (a, b) {
    //       if (a.order < b.order) return -1;
    //       if (a.order > b.order) return 1;
    //       return 0;
    //     });
    //     let order = 1;
    //     list.forEach((element) => {
    //       if (element.order != order) element.order = order;
    //       order += 1;
    //     });
    //     return list;
    //   },
    //   set(list) {
    //     let order = 1;
    //     list.forEach((element) => {
    //       if (element.order != order) {
    //         element.order = order;
    //         if (!isNaN(element.id)) {
    //           this.updatedEvaluation(element);
    //         }
    //       }
    //       order += 1;
    //     });
    //   },
    // },
    matter() {
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    // evaluationCriteriasMacrosFilter() {
    //   return this.evaluationCriteriasMacros
    //     .filter((x) => x.matter == this.matter.matter)
    //     .sort(function (a, b) {
    //       if (a.matter > b.matter) return 1;
    //       if (a.matter < b.matter) return -1;
    //       if (a.order > b.order) return 1;
    //       if (a.order < b.order) return -1;
    //       return 0;
    //     });
    // },
    // studyUnitsList() {
    //   if (!this.matter) return [];
    //   return this.studyUnits
    //     .filter((x) => x.matter == this.matter.matter)
    //     .sort(function (a, b) {
    //       if (a.order > b.order) return 1;
    //       if (a.order < b.order) return -1;
    //       return 0;
    //     });
    // },
    // evaluationCriteriasMicrosFilter() {
    //   let study_units_ids = this.studyUnitsList.map((x) => x.id);
    //   return this.evaluationCriteriasMicros.filter((x) =>
    //     study_units_ids.includes(x.study_unit)
    //   );
    // },
    // filterECMicro() {
    //   let list = [];
    //   this.studyUnitsList.forEach((study_unit) => {
    //     const evaluation_criterias = this.evaluationCriteriasMicros.filter(
    //       (x) => x.study_unit == study_unit.id
    //     );
    //     if (evaluation_criterias.length > 0)
    //       list.push({
    //         id: study_unit.id,
    //         name: String("N°" + study_unit.order + ": " + study_unit.name),
    //         order: study_unit.order,
    //         options: this.evaluationCriteriasMicros
    //           .filter((x) => x.study_unit == study_unit.id)
    //           .sort(function (a, b) {
    //             if (a.order > b.order) return 1;
    //             if (a.order < b.order) return -1;
    //             return a.full_sentence
    //               .toLowerCase()
    //               .localeCompare(b.full_sentence.toLowerCase());
    //           }),
    //       });
    //   });
    //   return list;
    // },
  },
  methods: {
    createdSupportResource(response) {
      this.$emit("created_support_resource", response);
    },
    createdEvaluation(evaluation, tmp_ep_mat_eva_stu_env_tim_alls) {
      this.$restful
        .Post(`/teaching/egress-profile-matter-evaluation/`, evaluation)
        .then((response) => {
          this.profile_matters_evaluations.push(response);
          this.createdMatStuEnvTimAlls(
            response,
            tmp_ep_mat_eva_stu_env_tim_alls
          );
          this.$bvModal.hide(
            `add-evaluation-modal-${this.egress_profile_matter_id}`
          );
          this.tmp_key += 1;
        });
    },
    async createdMatStuEnvTimAlls(evaluation, tmp_ep_mat_eva_stu_env_tim_alls) {
      for await (let tmp_map_eva of tmp_ep_mat_eva_stu_env_tim_alls) {
        this.$restful
          .Post(`/mesh/egr-pro-mat-eva-stu-env-tim-all/`, {
            egress_profile_matter_evaluation: evaluation.id,
            study_environment: tmp_map_eva.study_environment,
            time_allocation: tmp_map_eva.time_allocation,
            hours: tmp_map_eva.hours,
          })
          .then((response) => {
            this.ep_mat_eva_stu_env_tim_all.push(response);
          });
      }
    },
    // deleteEvaluation(id, type) {
    //   const evaluation_type = this.evaluation_types.find((x) => x.id == type);
    //   let msg = "";
    //   if (evaluation_type) {
    //     if (type == 4) msg = "la " + evaluation_type.value.toLowerCase();
    //     else msg = "la evaluación " + evaluation_type.value.toLowerCase();
    //   }
    //   this.$swal({
    //     title: `¿Está seguro de que desea eliminar ${msg}?`,
    //     text: "¡Esta acción no se podrá revertir!",
    //     type: "warning",
    //     showCancelButton: true,
    //     cancelButtonText: "Cancelar",
    //   }).then((result) => {
    //     if (result.value) {
    //       this.$restful
    //         .Delete(`/teaching/egress-profile-matter-evaluation/${id}/`)
    //         .then(() => {
    //           const index = this.profile_matters_evaluations.findIndex(
    //             (x) => x.id == id
    //           );
    //           if (index != -1) {
    //             this.profile_matters_evaluations.splice(index, 1);
    //           }
    //         });
    //     }
    //   });
    // },
    updatedEvaluation(evaluation) {
      this.$restful
        .Put(
          `/teaching/egress-profile-matter-evaluation/${evaluation.id}/`,
          evaluation
        )
        .then((response) => {
          const index = this.profile_matters_evaluations.findIndex(
            (x) => x.id == response.id
          );
          if (index != -1) {
            this.profile_matters_evaluations.splice(index, 1, response);
            this.$bvModal.hide(`edit-evaluation-modal-${response.id}`);
          }
        });
    },
    fetchTypeProfileMatterEvaluation() {
      this.$restful.Get(`/teaching/epm-evaluation-types/`).then((response) => {
        this.evaluation_types = response;
      });
    },
    fetchEvaluationSituations() {
      this.$restful.Get(`/teaching/evaluation-situation/`).then((response) => {
        this.evaluation_situations = response;
      });
    },
    fetchExaminerTypes() {
      this.$restful.Get(`/teaching/examiner-types/`).then((response) => {
        this.examiner_types = response;
      });
    },
    fetchEvaluativeAgentTypes() {
      this.$restful
        .Get(`/teaching/evaluative-agent-types/`)
        .then((response) => {
          this.evaluative_agent_types = response;
        });
    },
    fetchWorkTypes() {
      this.$restful.Get(`/teaching/work-types/`).then((response) => {
        this.work_types = response;
      });
    },
    fetchProfileMattersEvaluation() {
      if (this.egress_profile_matter_id)
        this.$restful
          .Get(
            `/teaching/egress-profile-matter-evaluation/?egress_profile_matter=${this.egress_profile_matter_id}`
          )
          .then((response) => {
            this.profile_matters_evaluations = response.sort(function (a, b) {
              if (a.order > b.order) return 1;
              if (a.order < b.order) return -1;
              return 0;
            });
          });
    },
    fetchEpMatEvaStuEnvTimAll() {
      this.$restful
        .Get(
          `/mesh/egr-pro-mat-eva-stu-env-tim-all/?egress_profile_matter=${this.egress_profile_matter_id}`
        )
        .then((response) => {
          this.ep_mat_eva_stu_env_tim_all = response;
        });
    },
    fetchLearningExperiences() {
      this.$restful
        .Get(`/teaching/learning-experience/?matter=${this.matter.matter}`)
        .then((response) => {
          this.learning_experiences = response.sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          });
        });
    },
  },
  mounted() {
    if (this.matter) {
      this.fetchLearningExperiences();
    }
  },
  created() {
    this.$store.dispatch(names.FETCH_AUTONOMOUS_TEACHING_ACTIVITIES);
    this.fetchEpMatEvaStuEnvTimAll();
    this.fetchExaminerTypes();
    this.fetchEvaluativeAgentTypes();
    this.fetchWorkTypes();
    this.fetchEvaluationSituations();
    this.fetchTypeProfileMatterEvaluation();
    this.fetchProfileMattersEvaluation();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
table th {
  vertical-align: middle;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.evaluation-header {
  background: var(--primary-color) !important;
  border: 1px solid #000 !important;
}
.evaluation-button-div {
  float: right;
  /* padding-right: 1rem; */
}
table td {
  border: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.ec-badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.ec-micro-badge {
  font-size: var(--thirth-font-size);
  text-align: center;
  width: fit-content !important;
  margin-right: 0.3em;
}
@media print {
  .ec-micro-badge {
    border: none;
  }
}
</style>