<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.cognitive_level.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback
        id="input-name-live-feedback"
      >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-description"
        name="input-description"
        v-model="$v.cognitive_level.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback
        id="input-description-live-feedback"
      >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label="Nivel"
      label-for="input-level"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-level"
        name="input-level"
        v-model="$v.cognitive_level.level.$model"
        :state="validateState('level')"
        aria-describedby="input-level-feedback"
        size="sm"
        type="number"
        :min="1"
      ></b-form-input>
      <b-form-invalid-feedback
        id="input-level-feedback"
      >Este campo debe ser mayor a 0.</b-form-invalid-feedback>
    </b-form-group>

    <div class="row">
      <div v-if="show_delete_button" class="col" style="text-align: left;">
        <!-- <b-button class="mr-1" size="sm" variant="danger" @click="remove">Eliminar</b-button> -->
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right;">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength, minValue } from "vuelidate/lib/validators";

export default {
  name: "CognitiveLevelForm",
  mixins: [validationMixin],
  props: {
    taxonomy_id: {
      type: Number,
      required: true
    },
    order: {
      type: Number,
      required: true
    },
    CognitiveLevel: {
      type: Object,
      default: function() {
        return {
          id: generateUniqueId(),
          level: this.order,
          name: "",
          description: "",
          active: true,
          taxonomy: this.taxonomy_id,
          verbs: []
        };
      }
    },
    show_save_button: {
      type: Boolean,
      default: true
    },
    show_delete_button: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cognitive_level: this.CognitiveLevel
    };
  },
  validations: {
    cognitive_level: {
      name: {
        required,
        minLength: minLength(5)
      },
      description: {
        required,
        minLength: minLength(5)
      },
      level: {
        required,
        minValue: minValue(1)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.cognitive_level[name];
      return $dirty ? !$error : null;
    },
    save() {
      if (isNaN(this.cognitive_level.id)) this.createCognitiveLevel();
      else this.updateCognitiveLevel();
    },
    createCognitiveLevel() {
      this.$v.cognitive_level.$touch();
      if (this.$v.cognitive_level.$anyError) {
        return;
      }
      this.$restful
        .Post(`/mesh/cognitive-level/`, this.cognitive_level)
        .then(response => {
          this.cognitive_level = response;
          this.$emit("created", this.cognitive_level);
        });
    },
    updateCognitiveLevel() {
      this.$v.cognitive_level.$touch();
      if (this.$v.cognitive_level.$anyError) {
        return;
      }
      this.$restful
        .Put(
          `/mesh/cognitive-level/${this.cognitive_level.id}/`,
          this.cognitive_level
        )
        .then(response => {
          this.cognitive_level = response;
          this.$emit("updated", this.cognitive_level);
        });
    }
  }
};
</script>

<style>
</style>