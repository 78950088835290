import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    egress_profiles: [],
    egress_profiles_url: '/mesh/egress-profile',
    egress_profile_descriptors: [],
    egress_profile_descriptor_url: '/mesh/egress-profile-descriptor',
    ep_middle_descriptors: [],
    ep_middle_descriptor_url: '/mesh/ep-middle-descriptor',
}

const getters = {
    [names.EGRESS_PROFILES]: state => {
        if (!get.store.state.user) return [];
        return state.egress_profiles.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EGRESS_PROFILE_DESCRIPTORS]: state => {
        if (!get.store.state.user) return [];
        return state.egress_profile_descriptors.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.EP_MIDDLE_DESCRIPTORS]: state => {
        if (!get.store.state.user) return [];
        return state.ep_middle_descriptors.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
}

const mutations = {
    [names.MUTATE_EGRESS_PROFILES]: (state, egress_profiles) => {
        egress_profiles.forEach(element => {
            let index = state.egress_profiles.findIndex(x => x.id == element.id);
            if (index != -1) state.egress_profiles.splice(index, 1, element);
            else state.egress_profiles.push(element);
        });
    },
    [names.MUTATE_EGRESS_PROFILE]: (state, egress_profile) => {
        let index = state.egress_profiles.findIndex(x => x.id == egress_profile.id);
        if (index != -1) state.egress_profiles.splice(index, 1, egress_profile);
        else state.egress_profiles.push(egress_profile);
    },
    [names.MUTATE_DELETE_EGRESS_PROFILE]: (state, egress_profile_id) => {
        let index = state.egress_profiles.findIndex(x => x.id == egress_profile_id);
        if (index != -1) state.egress_profiles.splice(index, 1);
    },
    [names.MUTATE_PROFILE_DESCRIPTORS]: (state, egress_profile_descriptors) => {
        egress_profile_descriptors.forEach(element => {
            let index = state.egress_profile_descriptors.findIndex(x => x.id == element.id);
            if (index != -1) state.egress_profile_descriptors.splice(index, 1, element);
            else state.egress_profile_descriptors.push(element);
        });
    },
    [names.MUTATE_PROFILE_DESCRIPTOR]: (state, egress_profile_descriptor) => {
        let index = state.egress_profile_descriptors.findIndex(x => x.id == egress_profile_descriptor.id);
        if (index != -1) state.egress_profile_descriptors.splice(index, 1, egress_profile_descriptor);
        else state.egress_profile_descriptors.push(egress_profile_descriptor);
    },
    [names.MUTATE_DELETE_PROFILE_DESCRIPTORS]: (state, egress_profile_descriptor_id) => {
        let index = state.egress_profile_descriptors.findIndex(x => x.id == egress_profile_descriptor_id);
        if (index != -1) state.egress_profile_descriptors.splice(index, 1);
    },
    [names.MUTATE_EP_MIDDLE_DESCRIPTOR]: (state, ep_middle_descriptor) => {
        let index = state.ep_middle_descriptors.findIndex(x => x.id == ep_middle_descriptor.id);
        if (index != -1) state.ep_middle_descriptors.splice(index, 1, ep_middle_descriptor);
        else state.ep_middle_descriptors.push(ep_middle_descriptor);
    },
    [names.MUTATE_EP_MIDDLE_DESCRIPTORS]: (state, ep_middle_descriptors) => {
        ep_middle_descriptors.forEach(element => {
            let index = state.ep_middle_descriptors.findIndex(x => x.id == element.id);
            if (index != -1) state.ep_middle_descriptors.splice(index, 1, element);
            else state.ep_middle_descriptors.push(element);
        });
    },
    [names.MUTATE_DELETE_EP_MIDDLE_DESCRIPTORS]: (state, ep_middle_descriptor_id) => {
        let index = state.ep_middle_descriptors.findIndex(x => x.id == ep_middle_descriptor_id);
        if (index != -1) state.ep_middle_descriptors.splice(index, 1);
    },
}

const actions = {
    [names.FETCH_EGRESS_PROFILE]: ({
        commit,
        state
    }, egress_profile_id) => {
        return new Promise((resolve) => {
            const egress_profile = state.egress_profiles.find(x => x.id == egress_profile_id);
            if (egress_profile) {
                resolve(egress_profile);
                return;
            }
            restful.Get(`${state.egress_profiles_url}/${egress_profile_id}/`)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILE, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EGRESS_PROFILE_BY_CAREER]: ({
        commit,
        state
    }, career_id) => {
        return new Promise((resolve) => {
            restful.Get(`${state.egress_profiles_url}/?career=${career_id}`)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILES, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EGRESS_PROFILES]: ({
        commit,
        state,
    }, ) => {
        return new Promise((resolve) => {
            restful.Get(`${state.egress_profiles_url}/`)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILES, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EGRESS_PROFILE]: ({
        commit,
        state
    }, egress_profile) => {
        return new Promise((resolve) => {
            restful.Post(`${state.egress_profiles_url}/`, egress_profile)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILE, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_EGRESS_PROFILE]: ({
        commit,
        state
    }, egress_profile) => {
        return new Promise((resolve) => {
            restful.Put(`${state.egress_profiles_url}/${egress_profile.id}/`, egress_profile)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILE, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EGRESS_PROFILE]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.egress_profiles_url}/${payload.egress_profile_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EGRESS_PROFILE, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EGRESS_PROFILE]: ({
        commit,
        state
    }, egress_profile_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.egress_profiles_url}/${egress_profile_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EGRESS_PROFILE, egress_profile_id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_PROFILE_DESCRIPTORS]: ({
        commit,
        state
    }, search_id) => {
        return new Promise((resolve) => {
            let arr = [];
            if (search_id) arr.push('egress_profile=' + search_id);
            let partial_url = '';
            if (arr.length > 0) partial_url = '?' + arr.join('&&');
            restful.Get(`${state.egress_profile_descriptor_url}/${partial_url}`)
                .then(response => {
                    commit(names.MUTATE_PROFILE_DESCRIPTORS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_PROFILE_DESCRIPTORS]: ({
        commit,
        state
    }, egress_profile_descriptor) => {
        return new Promise((resolve) => {
            restful.Post(`${state.egress_profile_descriptor_url}/`, egress_profile_descriptor)
                .then(response => {
                    commit(names.MUTATE_PROFILE_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_PROFILE_DESCRIPTORS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.egress_profile_descriptor_url}/${payload.id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_PROFILE_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_PROFILE_DESCRIPTORS]: ({
        commit,
        state
    }, id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.egress_profile_descriptor_url}/${id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_PROFILE_DESCRIPTORS, id);
                    resolve(response);
                });
        });
    },
    [names.FETCH_EP_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, search_id) => {
        return new Promise((resolve) => {
            let arr = [];
            if (search_id) arr.push('egress_profile=' + search_id);
            let partial_url = '';
            if (arr.length > 0) partial_url = '?' + arr.join('&&');
            restful.Get(`${state.ep_middle_descriptor_url}/${partial_url}`)
                .then(response => {
                    commit(names.MUTATE_EP_MIDDLE_DESCRIPTORS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_EP_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, ep_middle_descriptor) => {
        return new Promise((resolve) => {
            restful.Post(`${state.ep_middle_descriptor_url}/`, ep_middle_descriptor)
                .then(response => {
                    commit(names.MUTATE_EP_MIDDLE_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_EP_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.ep_middle_descriptor_url}/${payload.id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_EP_MIDDLE_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_EP_MIDDLE_DESCRIPTORS]: ({
        commit,
        state
    }, id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.ep_middle_descriptor_url}/${id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_EP_MIDDLE_DESCRIPTORS, id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};