<template>
  <div>
    <div class="float-center mt-2 mb-2 table-title">
      <strong>
        {{
          $getVisibleNames(
            "teaching.matter",
            true,
            "Asignaturas Base"
          ).toUpperCase()
        }}
      </strong>
      <InfoTooltip
        :tooltip_text="`Estas son las ${$getVisibleNames(
          'teaching.matter',
          true,
          'Asignaturas Base'
        )} que pueden ser utilizadas en varios Perfiles de Egreso, replicando sus resultados de aprendizaje.`"
      >
      </InfoTooltip>
    </div>

    <div
      v-if="
        allows_crud &&
        ((user_position &&
          [1, 2].includes(user_position.position) &&
          [1, 2].includes(user.groups[0])) ||
          user.is_superuser)
      "
      class="float-left"
    >
      <b-button
        size="sm"
        class="mt-2 mr-1"
        variant="secondary"
        @click="$bvModal.show(`new-old-matter-modal-1`)"
        ><b-icon-plus></b-icon-plus>Agregar
        {{
          $getVisibleNames("teaching.matter", false, "Asignatura Base")
        }}</b-button
      >
    </div>
    <div>
      <GenericBTable
        :items="matters"
        :fields="fields_old_matter"
        :filterCustom="filterCustom2"
        :filter="input_search"
        :sort-by.sync="sortBy2"
        :sort-desc.sync="sortDesc"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="'base-matter'"
        @emitChangeSearchField="changeInputSearch"
      >
        <template #cell(regime)="row">
          <span>{{ getRegimen(row.item.regime) }}</span>
        </template>
        <template #cell(formation_area)="row">
          <span>{{ getFormationArea(row.item.formation_area) }}</span>
        </template>
        <template #cell(study_units)="row">
          <b-button
            :id="`popover-button-matter-${row.item.id}`"
            size="sm"
            style="max-height: 30px"
          >
            <b-badge variant="light">{{ row.item.study_units.length }}</b-badge>
          </b-button>
          <b-popover
            :target="`popover-button-matter-${row.item.id}`"
            placement="lefttop"
            triggers="focus"
          >
            <template #title>{{
              $getVisibleNames("teaching.ramicro", false, "RA Micro")
            }}</template>
            <div
              class="campus-container"
              v-if="row.item.study_units.length > 0"
            >
              <div
                v-for="item in getStudyUnitsName(row.item.study_units)"
                :key="item.id"
                class="campus-div"
              >
                {{ item.name || item.full_sentence }}
              </div>
            </div>
            <div v-else>
              No cuenta con
              {{ $getVisibleNames("teaching.ramicro", false, "RA Micro") }}
            </div>
          </b-popover>
        </template>
        <template #cell(actions)="row">
          <b-button
            v-if="allows_crud && isClosedMatter(row.item.id)"
            size="sm"
            variant="none"
            v-b-tooltip.v-secondary.noninteractive="
              `Cambiar el estado del ${$getVisibleNames(
                'teaching.matter',
                false,
                'Asignatura Base'
              )}`
            "
            @click="changeStateMatter(row.item)"
          >
            <template v-if="row.item.is_closed">
              <b-icon icon="lock-fill" scale="1"></b-icon>
            </template>
            <template v-else>
              <b-icon icon="unlock-fill" scale="1"></b-icon>
            </template>
          </b-button>
          <button-edit
            v-can="'teaching.change_matter'"
            v-if="allows_crud && !row.item.is_closed"
            @click="MatterNameModal(row.item)"
            v-b-tooltip.v-secondary.auto.noninteractive="{
              customClass: 'custom-tooltip',
            }"
            :title="`Editar ${$getVisibleNames(
              'teaching.matter',
              false,
              'Asignatura Base'
            )}`"
          ></button-edit>
          <b-modal
            :id="`modal-matter-name-${row.item.id}`"
            hide-footer
            :title="`Editar ${$getVisibleNames(
              'teaching.matter',
              false,
              'Asignatura Base'
            )}`"
            size="lg"
          >
            <OldMatterForm
              :OldMatter="row.item"
              @updated="$bvModal.hide(`modal-matter-name-${row.item.id}`)"
              :show_delete_button="true"
              @delete="removeOldMatter"
            >
            </OldMatterForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <b-modal
      :id="`new-old-matter-modal-1`"
      hide-footer
      :title="`Crear ${$getVisibleNames(
        'teaching.matter',
        false,
        'Asignatura Base'
      )}`"
      size="lg"
    >
      <OldMatterForm @created="$bvModal.hide(`new-old-matter-modal-1`)">
      </OldMatterForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { toast } from "@/utils/utils";
export default {
  name: "OldMatterTableView",
  components: {
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    OldMatterForm: () => import("@/components/mesh/Matrix2/OldMatterForm"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortBy2: "name",
      input_search: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      matters: names.MATTERS,
      studyUnits: names.STUDY_UNITS,
      regimes: names.REGIMES,
      formationAreas: names.FORMATION_AREAS,
    }),
    fields_old_matter() {
      return [
        {
          key: "name",
          label: this.$getVisibleNames(
            "teaching.matter",
            true,
            "Asignaturas Base"
          ),
          sortable: true,
          class: "text-left",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "code",
          label: "Código",
          sortable: true,
          class: "text-center",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "weeks",
          label: "Semanas",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "regime",
          label: this.$getVisibleNames("mesh.matterregime", false, "Régimen"),
          sortable: true,
          display_column: false,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "formation_area",
          label: "Área de Formación",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "study_units",
          label: this.$getVisibleNames("teaching.ramicro", false, "RA Micro"),
          sortable: true,
          class: "text-center",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },

          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    isClosedMatter(matter_id) {
      if (
        this.user.is_superuser ||
        this.$hasObjectPermission(
          "special.close_base_matter",
          "special",
          matter_id
        )
        // ||(this.user_access && this.user_access.position == 1)
      ) {
        return true;
      } else return false;
    },
    getRegimen(id) {
      const regimen = this.regimes.find((x) => x.id == id);
      return regimen ? regimen.name : "";
    },
    getFormationArea(id) {
      const formation_area = this.formationAreas.find((x) => x.id == id);
      return formation_area ? formation_area.name : "N/A";
    },
    filterCustom2(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(row.code, this.input_search) ||
        this.$filtered(row.weeks, this.input_search) ||
        this.$filtered(this.getRegimen(row.regime), this.input_search) ||
        this.$filtered(
          this.getFormationArea(row.formation_area),
          this.input_search
        ) ||
        this.$filtered(row.description, this.input_search)
      ) {
        return true;
      } else {
        false;
      }
    },
    MatterNameModal(row_item) {
      this.$root.$emit("bv::show::modal", `modal-matter-name-${row_item.id}`);
    },
    // SaveOldMatter(old_matter) {
    //   if (isNaN(old_matter.id)) {
    //     this.$store.dispatch(names.POST_MATTER, old_matter).then(() => {
    //       toast(
    //         this.$getVisibleNames("teaching.matter", false, "Asignatura Base") +
    //           " creada correctamente."
    //       );
    //       this.$bvModal.hide(`new-old-matter-modal-1`);
    //     });
    //   } else {
    //     this.$store
    //       .dispatch(names.PATCH_MATTER, {
    //         matter_id: old_matter.id,
    //         item: {
    //           name: old_matter.name,
    //           description: old_matter.description,
    //           code: old_matter.code,
    //           plan_level: old_matter.plan_level,
    //           formation_area: old_matter.formation_area,
    //           matter_types: old_matter.matter_types,
    //           matter_contributions: old_matter.matter_contributions,
    //           modality: old_matter.modality,
    //           weeks: old_matter.weeks,
    //           automatic_achievement_weighing:
    //             old_matter.automatic_achievement_weighing,
    //         },
    //       })
    //       .then((response) => {
    //         this.$restful
    //           .Get(
    //             `teaching/update_ra_micro_ec_macro_weighing/?matter=${response.id}`
    //           )
    //           .then(() => {
    //             this.$store.dispatch(
    //               names.FETCH_EVALUATION_CRITERIAS_MACROS,
    //               response.id
    //             );
    //             this.$store.dispatch(names.FETCH_STUDY_UNITS, response.id);
    //           });
    //         toast("Cambios guardados correctamente.");
    //         this.$bvModal.hide(`modal-matter-name-${old_matter.id}`);
    //       });
    //   }
    // },
    changeStateMatter(old_matter) {
      const payload = {
        matter_id: old_matter.id,
        item: {
          is_closed: !old_matter.is_closed,
        },
      };
      this.$store.dispatch(names.PATCH_MATTER, payload);
    },
    removeOldMatter(old_matter) {
      this.$bvModal.hide(`modal-matter-name-${old_matter.id}`);
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    getStudyUnitsName(study_units) {
      let list = [];
      study_units.forEach((x) => {
        const instance = this.studyUnits.find((element) => element.id == x);
        if (instance) list.push(instance);
      });
      return list;
    },
  },
  mounted() {
    this.$store.dispatch(names.FETCH_MATTERS);
    this.$store.dispatch(names.FETCH_STUDY_UNITS_BY_CICLE_AND_PROFILE);
  },
  created() {},
};
</script>

<style>
</style>