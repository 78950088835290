var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mattersBySemesterAndArea.length > 0)?_c('tbody',[_c('th',{attrs:{"colspan":10 + _vm.time_allocations.length}},[_vm._v(" "+_vm._s(_vm.formationArea.name)+" "),_c('span',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_egressprofile'),expression:"'mesh.change_egressprofile'"}]},[(_vm.limitFormationAreas && _vm.limitFormationAreas.max_hours != 0)?_c('span',[_vm._v(" - "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours", true, "Total de Horas Pedagógicas" ))+": "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
            `${_vm.$getVisibleNames(
              'manual.total_pedagogical_hours',
              true,
              'Total de Horas Pedagógicas'
            )} en el Área.`
          ),expression:"\n            `${$getVisibleNames(\n              'manual.total_pedagogical_hours',\n              true,\n              'Total de Horas Pedagógicas'\n            )} en el Área.`\n          ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],class:_vm.limitFormationAreas &&
            _vm.limitFormationAreas.max_hours < _vm.maxHoursAndCredits.max_hours
              ? 'limit-class'
              : ''},[_vm._v(" "+_vm._s(_vm.maxHoursAndCredits.max_hours)+" ")]),(_vm.limitFormationAreas && _vm.limitFormationAreas.max_hours != 0)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:('Máximo sugerido.'),expression:"'Máximo sugerido.'",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}]},[_vm._v("/ "+_vm._s(_vm.limitFormationAreas.max_hours))]):_vm._e()]):_vm._e(),(_vm.limitFormationAreas && _vm.limitFormationAreas.max_credits != 0)?_c('span',[_vm._v(" - Total Créditos: "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
            'Total de Créditos en el Área.'
          ),expression:"\n            'Total de Créditos en el Área.'\n          ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],class:_vm.limitFormationAreas &&
            _vm.limitFormationAreas.max_credits < _vm.maxHoursAndCredits.max_credits
              ? 'limit-class'
              : ''},[_vm._v(" "+_vm._s(_vm.maxHoursAndCredits.max_credits)+" ")]),(_vm.limitFormationAreas && _vm.limitFormationAreas.max_credits != 0)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
            'Máximo de Créditos sugerido.'
          ),expression:"\n            'Máximo de Créditos sugerido.'\n          ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}]},[_vm._v("/ "+_vm._s(_vm.limitFormationAreas.max_credits))]):_vm._e()]):_vm._e()])]),_vm._l((_vm.mattersBySemesterAndArea),function(matter){return [(!(matter.plan_level != _vm.semester && matter.regime == 1))?_c('matter-row',{key:matter.id + '-' + _vm.componentKey,attrs:{"Matter":matter,"egress_profile_time_allocations":_vm.egress_profile_time_allocations,"OldMatter":_vm.matters.find((x) => x.id == matter.matter),"egress_profile_id":_vm.egress_profile_id,"allows_crud":matter.plan_level === _vm.semester && _vm.allows_crud},on:{"forceUpdate":_vm.forceUpdate}}):_vm._e()]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }