<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        v-if="
          institution &&
          institution.id == 12 &&
          (modality.id == 33 || modality.id == 42)
        "
        size="sm"
        :value="modality.name"
        disabled
      >
      </b-form-input>
      <b-form-input
        v-else
        id="input-name"
        name="input-name"
        v-model="$v.modality.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Orden"
      label-for="input-order"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-order"
        name="input-order"
        v-model="$v.modality.order.$model"
        :state="validateState('order')"
        aria-describedby="input-order-live-feedback"
        size="sm"
        type="number"
      ></b-form-input>
      <b-form-invalid-feedback id="input-order-live-feedback"
        >Este campo debe ser mayor a 0.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-description"
        name="input-description"
        v-model="$v.modality.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>

    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.modality.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDeleteObject"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength, minValue } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "ModalityForm",
  mixins: [validationMixin],
  props: {
    Modality: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          order: 1,
          school: "",
          active: true,
        };
      },
    },
    institution_id: {
      type: Number,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modality: this.Modality
        ? {
            id: this.Modality.id,
            name: this.Modality.name,
            order: this.Modality.order,
            description: this.Modality.description,
          }
        : { id: generateUniqueId(), name: "", order: 0 },
    };
  },
  validations: {
    modality: {
      name: {
        required,
        minLength: minLength(2),
      },
      order: {
        required,
        minValue: minValue(1),
      },
      description: {
        minLength: minLength(0),
      },
    },
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.modality[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.modality.$touch();
      if (this.$v.modality.$anyError) {
        return;
      }
      if (isNaN(this.modality.id)) {
        this.createModality();
      } else this.updateModality();
    },
    createModality() {
      this.modality.school = this.institution_id;
      this.$store
        .dispatch(names.POST_MODALITY, this.modality, this.modality.school)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.modality",
                false,
                "Modalidad de Asignaturas"
              ) + " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateModality() {
      this.modality.school = this.institution_id;
      this.$store
        .dispatch(names.UPDATE_MODALITY, this.modality, this.modality.school)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames(
                "mesh.modality",
                false,
                "Modalidad de Asignaturas"
              ) + " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteModality() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.modality",
          false,
          "Modalidad de Asignaturas"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_MODALITY, this.modality.id);
          this.$emit("deleted", this.modality);
        }
      });
    },
  },
};
</script>

<style scoped>
</style>