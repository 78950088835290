var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.oldMatter)?_c('div',[_c('ul',_vm._l((_vm.methodologicalStrategyList),function(strategy){return _c('li',{key:`strategy-${strategy.id}`,attrs:{"title":strategy.description}},[_vm._v(" "+_vm._s(strategy.name)+" ")])}),0),(_vm.allows_crud && !isNaN(_vm.ProfileMatter.id))?_c('div',{directives:[{name:"can",rawName:"v-can",value:('teaching.change_matter'),expression:"'teaching.change_matter'"}]},[_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
        `Editar ${_vm.$getVisibleNames(
          'mesh.methodologicalstrategy',
          true,
          'Estrategias Metodológicas'
        )}`
      ),expression:"\n        `Editar ${$getVisibleNames(\n          'mesh.methodologicalstrategy',\n          true,\n          'Estrategias Metodológicas'\n        )}`\n      ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`modal-methodological-strategy-${_vm.ProfileMatter.id}`)}}}),_c('b-modal',{attrs:{"title":`Seleccionar ${_vm.$getVisibleNames(
        'mesh.methodologicalstrategy',
        true,
        'Estrategias Metodológicas'
      )}`,"id":`modal-methodological-strategy-${_vm.ProfileMatter.id}`,"size":"lg"},on:{"hidden":function($event){return _vm.closeMethodologicalStrategy(_vm.ProfileMatter.id)}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"custom-modal-title"},[_vm._v(" Selecionar "+_vm._s(_vm.$getVisibleNames( "mesh.methodologicalstrategy", true, "Estrategias Metodológicas" ))+" ")]),_c('InfoTooltip',{staticClass:"selection-info ml-0",attrs:{"tooltip_text":"Para utilizar la selección múltiple, mantenga presionada la tecla Ctrl y a continuación, seleccione los elementos que desee agregar."}})]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"btn-cancel",attrs:{"size":"sm"},on:{"click":function($event){return _vm.closeMethodologicalStrategy(_vm.ProfileMatter.id)}}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.updateMethodologicalStrategy(_vm.ProfileMatter.id)}}},[_vm._v("Guardar")])]},proxy:true}],null,false,4275777291)},[_c('b-input-group',{staticClass:"mb-1"},[_c('b-form-input',{attrs:{"type":"search","size":"sm","placeholder":`Buscar ${_vm.$getVisibleNames(
            'mesh.methodologicalstrategy',
            true,
            'Estrategias Metodológicas'
          )}...`},model:{value:(_vm.input_search),callback:function ($$v) {_vm.input_search=$$v},expression:"input_search"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.input_search,"size":"sm"},on:{"click":function($event){_vm.input_search = ''}}},[_vm._v("Limpiar")])],1)],1),_c('b-form-select',{attrs:{"options":_vm.searchMethodologicalStrategies,"text-field":"name","value-field":"id","size":"sm","select-size":_vm.methodologicalStrategies.length,"multiple":""},model:{value:(_vm.methodological_strategies),callback:function ($$v) {_vm.methodological_strategies=$$v},expression:"methodological_strategies"}})],1)],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }