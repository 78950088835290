var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.matter && _vm.old_matter)?_c('tr',[_c('td',[_vm._t("default",function(){return [(_vm.allows_crud && !_vm.matter.is_closed)?[_c('b-form-input',{staticClass:"inputNumber",class:{
            'border-color':
              _vm.matter.order < 1 || _vm.matter.order > 100 || _vm.SameMatterOrder,
          },attrs:{"id":`order-${_vm.matter.id}`,"type":"number","min":"1","max":"100"},model:{value:(_vm.matter.order),callback:function ($$v) {_vm.$set(_vm.matter, "order", _vm._n($$v))},expression:"matter.order"}}),(_vm.matter.order < 1 || _vm.matter.order > 100 || _vm.SameMatterOrder)?_c('b-tooltip',{attrs:{"target":`order-${_vm.matter.id}`,"variant":"secondary","noninteractive":true}},[(_vm.matter.order < 1 || _vm.matter.order > 100)?_c('div',[_vm._v(" Los valores deben estar entre 1 ~ 100. ")]):_vm._e(),(_vm.SameMatterOrder)?_c('div',[_vm._v(" Dos o más "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", true, "Asignaturas" ))+" cuentan con el mismo número. ")]):_vm._e()]):_vm._e()]:[_vm._v(_vm._s(_vm.matter.order))]]})],2),_c('td',{staticClass:"matter-td",style:({
      'background-color': _vm.matterFormationLine + '!important',
    })},[_c('div',{staticClass:"matter-name text-left ml-1"},[_c('div',{staticClass:"matter-link"},[_c('b-link',{staticClass:"d-flex",style:({
            color: _vm.$lightOrDark(_vm.matterFormationLine) ? '#2b2a2a' : 'white',
          }),attrs:{"id":`matter-link-${_vm.matter.id}`,"to":{
            name: 'MatterProgramView',
            params: { matter_id: _vm.matter.id },
          }}},[_c('div',{staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.old_matter.name)+" ")])])],1),_c('b-tooltip',{attrs:{"target":`matter-link-${_vm.matter.id}`,"variant":"secondary","placement":"right","boundary-padding":100,"noninteractive":""}},[_c('b-icon',{attrs:{"icon":"box-arrow-up-right"}}),_vm._v(" Ir al "+_vm._s(_vm.$getVisibleNames( "manual.matter_program", false, "Programa de Asignatura" ))+" ")],1)],1)]),(
      !_vm.allows_crud || _vm.matter.is_closed || _vm.prerequisitesOptions.length == 0
    )?_c('td',{attrs:{"id":`prerequisite-label-${_vm.matter.id}`}},[_c('div',{staticClass:"campus-container"},_vm._l((_vm.prerequisites),function(matter){return _c('div',{key:matter.id,staticClass:"campus-div",style:({
          'background-color': _vm.matterFormationLine,
          color: _vm.$lightOrDark(_vm.matterFormationLine) ? '#2b2a2a' : 'white',
        })},[_vm._v(" "+_vm._s(matter.order)+" ")])}),0),(_vm.prerequisites.length > 0)?_c('PrerequisitesPopover',{attrs:{"object":_vm.matter,"content":_vm.prerequisitesNames,"placement":'top',"triggers":'hover',"title":'Requisitos'}}):_vm._e()],1):_c('td',{staticClass:"prerequisites-div",on:{"click":function($event){return _vm.$bvModal.show(
        `modal-prerequisites-${_vm.matter.id}-${_vm.prerequisites_modal_id}`
      )}}},[_c('div',{staticClass:"campus-container"},_vm._l((_vm.prerequisites),function(matter){return _c('div',{key:matter.id,staticClass:"campus-div",style:({
          'background-color': _vm.matterFormationLine,
          color: _vm.$lightOrDark(_vm.matterFormationLine) ? '#2b2a2a' : 'white',
        })},[_vm._v(" "+_vm._s(matter.order)+" ")])}),0),_c('b-modal',{attrs:{"id":`modal-prerequisites-${_vm.matter.id}-${_vm.prerequisites_modal_id}`,"title":"Seleccione los Prerrequisitos","hide-footer":""},on:{"hide":_vm.resetModalPrerequisites}},[(_vm.matters)?_c('b-form-select',{attrs:{"options":_vm.prerequisitesOptions,"text-field":"label","value-field":"id","state":!_vm.isValidPrerequisites,"aria-describedby":"select-prerequisites-feedback","multiple":true,"select-size":_vm.prerequisitesOptions.length <= 5
            ? _vm.prerequisitesOptions.length + 1
            : 15},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- No seleccionar --")])]},proxy:true}],null,false,2263904411),model:{value:(_vm.prerequisites_in_process),callback:function ($$v) {_vm.prerequisites_in_process=$$v},expression:"prerequisites_in_process"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"id":"select-prerequisites-feedback"}},[_vm._v("Para marcar una o más "+_vm._s(_vm.$getVisibleNames("mesh.egressprofilematter", true, "Asignaturas"))+" quites de su selección (-- No seleccionar --).")]),_c('b-button',{staticClass:"btn-save mt-2",attrs:{"size":"sm"},on:{"click":_vm.savePrerequisites}},[_vm._v("Guardar")])],1)],1),_c('td',[(_vm.allows_crud && !_vm.matter.is_closed)?[_c('b-form-select',{attrs:{"options":_vm.regimesList,"text-field":"name","value-field":"id"},model:{value:(_vm.matter.regime),callback:function ($$v) {_vm.$set(_vm.matter, "regime", $$v)},expression:"matter.regime"}})]:(_vm.regime)?[_vm._v(_vm._s(_vm.regime.name))]:_vm._e()],2),_c('td',[(
        _vm.allows_crud &&
        ((_vm.matter.use_matter_values && !_vm.old_matter.is_closed) ||
          (!_vm.matter.use_matter_values && !_vm.matter.is_closed))
      )?[(_vm.matter.use_matter_values)?[_c('b-form-input',{staticClass:"inputNumber",class:{
            'border-color': _vm.old_matter.weeks < 1 || _vm.old_matter.weeks > 52,
          },attrs:{"id":`Weeks-old-matter-${_vm.old_matter.id}`,"type":"number","min":"1","max":"52"},model:{value:(_vm.old_matter.weeks),callback:function ($$v) {_vm.$set(_vm.old_matter, "weeks", _vm._n($$v))},expression:"old_matter.weeks"}}),(_vm.old_matter.weeks < 1 || _vm.old_matter.weeks > 52)?_c('b-tooltip',{attrs:{"target":`Weeks-old-matter-${_vm.old_matter.id}`,"variant":"secondary"}},[_vm._v(" Los valores deben estar entre 1 ~ 52 ")]):_vm._e()]:[_c('b-form-input',{staticClass:"inputNumber",class:{
            'border-color': _vm.matter.weeks < 1 || _vm.matter.weeks > 52,
          },attrs:{"id":`Weeks-${_vm.matter.id}`,"type":"number","min":"1","max":"52"},model:{value:(_vm.matter.weeks),callback:function ($$v) {_vm.$set(_vm.matter, "weeks", _vm._n($$v))},expression:"matter.weeks"}}),(_vm.matter.weeks < 1 || _vm.matter.weeks > 52)?_c('b-tooltip',{attrs:{"target":`Weeks-${_vm.matter.id}`,"variant":"secondary"}},[_vm._v(" Los valores deben estar entre 1 ~ 52 ")]):_vm._e()]]:[(_vm.matter.use_matter_values)?[(_vm.isAnual && _vm.matter.regime == _vm.isAnual.id)?[_vm._v(" "+_vm._s(_vm._f("Round")((_vm.old_matter.weeks / 2)))+" ")]:[_vm._v(" "+_vm._s(_vm.old_matter.weeks)+" ")]]:[(_vm.isAnual && _vm.matter.regime == _vm.isAnual.id)?[_vm._v(" "+_vm._s(_vm._f("Round")((_vm.matter.weeks / 2)))+" ")]:[_vm._v(" "+_vm._s(_vm.matter.weeks)+" ")]]]],2),_vm._l((_vm.time_allocations.filter(
      (x) =>
        x.uses_module_minutes == true &&
        this.egress_profile_time_allocations.includes(x.id)
    )),function(time){return _c('td',{key:time.id},[(
        _vm.allows_crud &&
        ((_vm.matter.use_matter_values && !_vm.old_matter.is_closed) ||
          (!_vm.matter.use_matter_values && !_vm.matter.is_closed))
      )?[(_vm.matter.use_matter_values)?[_c('InputMTimeAllocation',{attrs:{"TimeAllocation":time,"OldMatter":_vm.old_matter},on:{"changed_hour":_vm.slotUpdatedCredits}})]:[_c('InputEPMTimeAllocation',{attrs:{"TimeAllocation":time,"Matter":_vm.matter},on:{"changed_hour":_vm.slotUpdatedCredits}})]]:[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[(_vm.matter.use_matter_values)?[_c('div',{staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.matter_time_allocations.find( (x) => x.time_allocation == time.id && x.matter == _vm.old_matter.id ) ? _vm.matter.regime == _vm.isAnual.id ? Math.round( _vm.matter_time_allocations.find( (x) => x.time_allocation == time.id && x.matter == _vm.old_matter.id ).hours / 2 ) : _vm.matter_time_allocations.find( (x) => x.time_allocation == time.id && x.matter == _vm.old_matter.id ).hours : 0)+" ")]),(
              _vm.matter_time_allocations.find(
                (x) =>
                  x.time_allocation == time.id && x.matter == _vm.old_matter.id
              ) &&
              !_vm.matter_time_allocations.find(
                (x) =>
                  x.time_allocation == time.id && x.matter == _vm.old_matter.id
              ).counts_towards_credits
            )?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                'Estas horas no cuentan para el total'
              ),expression:"\n                'Estas horas no cuentan para el total'\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"m-0 p-0",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" * ")])]:_vm._e()]:[_c('div',{staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.ep_matter_time_allocations.find( (x) => x.time_allocation == time.id && x.egress_profile_matter == _vm.matter.id ) ? _vm.matter.regime == _vm.isAnual.id ? Math.round( _vm.ep_matter_time_allocations.find( (x) => x.time_allocation == time.id && x.egress_profile_matter == _vm.matter.id ).hours / 2 ) : _vm.ep_matter_time_allocations.find( (x) => x.time_allocation == time.id && x.egress_profile_matter == _vm.matter.id ).hours : 0)+" ")]),(
              _vm.ep_matter_time_allocations.find(
                (x) =>
                  x.time_allocation == time.id &&
                  x.egress_profile_matter == _vm.matter.id
              ) &&
              !_vm.ep_matter_time_allocations.find(
                (x) =>
                  x.time_allocation == time.id &&
                  x.egress_profile_matter == _vm.matter.id
              ).counts_towards_credits
            )?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                'Estas horas no cuentan para el total'
              ),expression:"\n                'Estas horas no cuentan para el total'\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"m-0 p-0",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" * ")])]:_vm._e()]],2)]],2)}),_c('td',[_vm._v(" "+_vm._s(_vm.totalPedagogical)+" ")]),(_vm.profileType)?_c('td',[_vm._v(" "+_vm._s(_vm._f("Round")((_vm.totalPedagogical * (parseFloat(_vm.profileType.module_minutes) / 60))))+" ")]):_vm._e(),_vm._l((_vm.time_allocations.filter(
      (x) =>
        x.uses_module_minutes == false &&
        this.egress_profile_time_allocations.includes(x.id)
    )),function(time){return _c('td',{key:time.id},[(
        _vm.allows_crud &&
        ((_vm.matter.use_matter_values && !_vm.old_matter.is_closed) ||
          (!_vm.matter.use_matter_values && !_vm.matter.is_closed))
      )?[(_vm.matter.use_matter_values)?[_c('InputMTimeAllocation',{attrs:{"TimeAllocation":time,"OldMatter":_vm.old_matter},on:{"changed_hour":_vm.slotUpdatedCredits}})]:[_c('InputEPMTimeAllocation',{attrs:{"TimeAllocation":time,"Matter":_vm.matter},on:{"changed_hour":_vm.slotUpdatedCredits}})]]:[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[(_vm.matter.use_matter_values)?[_c('div',{staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.matter_time_allocations.find( (x) => x.time_allocation == time.id && x.matter == _vm.old_matter.id ) ? _vm.matter.regime == _vm.isAnual.id ? Math.round( _vm.matter_time_allocations.find( (x) => x.time_allocation == time.id && x.matter == _vm.old_matter.id ).hours / 2 ) : _vm.matter_time_allocations.find( (x) => x.time_allocation == time.id && x.matter == _vm.old_matter.id ).hours : 0)+" ")]),(
              _vm.matter_time_allocations.find(
                (x) =>
                  x.time_allocation == time.id && x.matter == _vm.old_matter.id
              ) &&
              !_vm.matter_time_allocations.find(
                (x) =>
                  x.time_allocation == time.id && x.matter == _vm.old_matter.id
              ).counts_towards_credits
            )?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                'Estas horas no cuentan para el total'
              ),expression:"\n                'Estas horas no cuentan para el total'\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"m-0 p-0",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" * ")])]:_vm._e()]:[_c('div',{staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.ep_matter_time_allocations.find( (x) => x.time_allocation == time.id && x.egress_profile_matter == _vm.matter.id ) ? _vm.matter.regime == _vm.isAnual.id ? Math.round( _vm.ep_matter_time_allocations.find( (x) => x.time_allocation == time.id && x.egress_profile_matter == _vm.matter.id ).hours / 2 ) : _vm.ep_matter_time_allocations.find( (x) => x.time_allocation == time.id && x.egress_profile_matter == _vm.matter.id ).hours : 0)+" ")]),(
              _vm.ep_matter_time_allocations.find(
                (x) =>
                  x.time_allocation == time.id &&
                  x.egress_profile_matter == _vm.matter.id
              ) &&
              !_vm.ep_matter_time_allocations.find(
                (x) =>
                  x.time_allocation == time.id &&
                  x.egress_profile_matter == _vm.matter.id
              ).counts_towards_credits
            )?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                'Estas horas no cuentan para el total'
              ),expression:"\n                'Estas horas no cuentan para el total'\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"m-0 p-0",staticStyle:{"font-size":"1.3rem"}},[_vm._v(" * ")])]:_vm._e()]],2)]],2)}),_c('td',[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.totalChronological))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.totalHours))+" ")]),_c('td',[(_vm.profileType)?_c('div',[(
          _vm.allows_crud &&
          ((_vm.matter.use_matter_values && !_vm.old_matter.is_closed) ||
            (!_vm.matter.use_matter_values && !_vm.matter.is_closed))
        )?_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:('Créditos Automáticos'),expression:"'Créditos Automáticos'",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"mr-2",staticStyle:{"margin-top":"0.82em"}},[_vm._v(" "+_vm._s(_vm.creditSctChile)+" ")]),(_vm.matter.use_matter_values)?[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
              'Créditos Planificados'
            ),expression:"\n              'Créditos Planificados'\n            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"inputNumber mt-1",attrs:{"type":"number","min":"0"},model:{value:(_vm.old_matter.credits),callback:function ($$v) {_vm.$set(_vm.old_matter, "credits", _vm._n($$v))},expression:"old_matter.credits"}})]:[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
              'Créditos Planificados'
            ),expression:"\n              'Créditos Planificados'\n            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"inputNumber mt-1",attrs:{"type":"number","min":"0"},model:{value:(_vm.matter.credits),callback:function ($$v) {_vm.$set(_vm.matter, "credits", _vm._n($$v))},expression:"matter.credits"}})],(
            (_vm.matter.use_matter_values && !_vm.old_matter.use_automatic_credits) ||
            (!_vm.matter.use_matter_values && !_vm.matter.use_automatic_credits)
          )?[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              'Copiar la información del crédito automático'
            ),expression:"\n              'Copiar la información del crédito automático'\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-select-default ml-2",on:{"click":_vm.copyCredits}},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":"clipboard-check"}})],1)]:[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              'Al cambiar las horas los créditos cambiaran de forma automática'
            ),expression:"\n              'Al cambiar las horas los créditos cambiaran de forma automática'\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"mt-1"},[_c('button',{staticClass:"btn-select-default ml-2",attrs:{"disabled":""}},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":"clipboard-check"}})],1)])]],2):_c('div',[(_vm.matter.use_matter_values)?_c('span',[_vm._v(" "+_vm._s(_vm.matter.regime == _vm.isAnual.id ? Math.round(_vm.old_matter.credits / 2) : _vm.old_matter.credits)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.matter.regime == _vm.isAnual.id ? Math.round(_vm.matter.credits / 2) : _vm.matter.credits)+" ")])])]):_vm._e()])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }