<template>
  <div>
    <template v-for="study_environment in studyEnvironmentsFilter">
      <div
        :key="study_environment.id"
        v-if="StudyEnvironmentTimeAllocation(study_environment.id).length > 0"
      >
        <div class="header">
          <strong>{{ study_environment.name }}:</strong>
        </div>
        <template
          v-for="time_allocation in StudyEnvironmentTimeAllocation(
            study_environment.id
          )"
        >
          <div :key="study_environment.id + '-' + time_allocation.id">
            <template v-if="isOlMatter">
              <MatEvaStuEnvTimAllInput
                class="ml-5"
                :matter_id="matter_id"
                :matter_evaluation_id="matter_evaluation_id"
                :MatEvaStuEnvTimAll="
                  MatEvaStuEnvTimAll.find(
                    (x) =>
                      x.study_environment == study_environment.id &&
                      x.time_allocation == time_allocation.id &&
                      x.matter_evaluation == matter_evaluation_id
                  )
                "
                :MatEvaStuEnvTimAllList="MatEvaStuEnvTimAll"
                :tmpMatEvaStuEnvTimAll="tmpMatEvaStuEnvTimAll"
                :StudyEnvironmentIds="StudyEnvironmentIds"
                :isPedagogical="isPedagogical"
                :time_allocation_id="time_allocation.id"
                :study_environment_id="study_environment.id"
                :isOlMatter="isOlMatter"
                @created="createdMatStuEnvTimAll"
                @updated="UpdateMatStuEnvTimAll"
              >
              </MatEvaStuEnvTimAllInput>
            </template>
            <template v-else>
              <MatEvaStuEnvTimAllInput
                class="ml-5"
                :matter_id="matter_id"
                :matter_evaluation_id="matter_evaluation_id"
                :MatEvaStuEnvTimAll="
                  MatEvaStuEnvTimAll.find(
                    (x) =>
                      x.study_environment == study_environment.id &&
                      x.time_allocation == time_allocation.id &&
                      x.egress_profile_matter_evaluation == matter_evaluation_id
                  )
                "
                :MatEvaStuEnvTimAllList="MatEvaStuEnvTimAll"
                :tmpMatEvaStuEnvTimAll="tmpMatEvaStuEnvTimAll"
                :StudyEnvironmentIds="StudyEnvironmentIds"
                :isPedagogical="isPedagogical"
                :time_allocation_id="time_allocation.id"
                :study_environment_id="study_environment.id"
                :isOlMatter="isOlMatter"
                @created="createdMatStuEnvTimAll"
                @updated="UpdateMatStuEnvTimAll"
              >
              </MatEvaStuEnvTimAllInput>
            </template>
          </div>
        </template>
      </div>
    </template>
    <template v-if="timeAllocationWithNoStudyEnvironment.length > 0">
      <div
        class="header"
        v-if="studyEnvironmentsFilter.length > 0 && containHours.length > 0"
      >
        <strong
          >Sin
          {{
            $getVisibleNames(
              "mesh.studyenvironment",
              false,
              "Ambiente de Aprendizaje"
            )
          }}:</strong
        >
      </div>
      <template v-for="time_allocation in timeAllocationWithNoStudyEnvironment">
        <div :key="'time_allocation:' + time_allocation.id">
          <template v-if="isOlMatter">
            <MatEvaStuEnvTimAllInput
              :class="{ 'ml-5': studyEnvironmentsFilter.length > 0 }"
              :matter_id="matter_id"
              :matter_evaluation_id="matter_evaluation_id"
              :MatEvaStuEnvTimAll="
                MatEvaStuEnvTimAll.find(
                  (x) =>
                    x.study_environment == null &&
                    x.time_allocation == time_allocation.id &&
                    x.matter_evaluation == matter_evaluation_id
                )
              "
              :MatEvaStuEnvTimAllList="MatEvaStuEnvTimAll"
              :tmpMatEvaStuEnvTimAll="tmpMatEvaStuEnvTimAll"
              :StudyEnvironmentIds="StudyEnvironmentIds"
              :isPedagogical="isPedagogical"
              :time_allocation_id="time_allocation.id"
              :study_environment_id="null"
              :isOlMatter="isOlMatter"
              @created="createdMatStuEnvTimAll"
              @updated="UpdateMatStuEnvTimAll"
            >
            </MatEvaStuEnvTimAllInput>
          </template>
          <template v-else>
            <MatEvaStuEnvTimAllInput
              :matter_id="matter_id"
              :matter_evaluation_id="matter_evaluation_id"
              :MatEvaStuEnvTimAll="
                MatEvaStuEnvTimAll.find(
                  (x) =>
                    x.study_environment == null &&
                    x.time_allocation == time_allocation.id &&
                    x.egress_profile_matter_evaluation == matter_evaluation_id
                )
              "
              :MatEvaStuEnvTimAllList="MatEvaStuEnvTimAll"
              :tmpMatEvaStuEnvTimAll="tmpMatEvaStuEnvTimAll"
              :StudyEnvironmentIds="StudyEnvironmentIds"
              :isPedagogical="isPedagogical"
              :time_allocation_id="time_allocation.id"
              :study_environment_id="null"
              :isOlMatter="isOlMatter"
              @created="createdMatStuEnvTimAll"
              @updated="UpdateMatStuEnvTimAll"
            >
            </MatEvaStuEnvTimAllInput>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { generateUniqueId } from "@/utils/utils";
export default {
  name: "MatEvaStuEnvTimAllComponent",
  components: {
    MatEvaStuEnvTimAllInput: () =>
      import("@/components/teaching/MatterProgram/MatEvaStuEnvTimAllInput.vue"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    matter_evaluation_id: {
      required: true,
    },
    MatEvaStuEnvTimAll: {
      type: Array,
    },
    tmpMatEvaStuEnvTimAll: {
      type: Array,
    },
    StudyEnvironmentIds: {
      type: Array,
    },
    isPedagogical: {
      type: Boolean,
      required: true,
    },
    isOlMatter: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      studyEnvironmentsTimeAllocation: names.STUDY_ENVIRONMENTS_TIME_ALLOCATION,
      time_allocations: names.TIME_ALLOCATIONS,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
    }),
    studyEnvironmentExist() {
      const time_allocation_ids = this.timeAllocationsIsPedagogical.map(
        (x) => x.id
      );
      if (this.isOlMatter)
        return [
          ...new Set(
            this.MatEvaStuEnvTimAll.filter(
              (x) =>
                x.study_environment != null &&
                x.matter_evaluation == this.matter_evaluation_id &&
                time_allocation_ids.includes(x.time_allocation) &&
                x.hours > 0
            ).map((x) => x.study_environment)
          ),
        ];
      else
        return [
          ...new Set(
            this.MatEvaStuEnvTimAll.filter(
              (x) =>
                x.study_environment != null &&
                x.egress_profile_matter_evaluation ==
                  this.matter_evaluation_id &&
                time_allocation_ids.includes(x.time_allocation) &&
                x.hours > 0
            ).map((x) => x.study_environment)
          ),
        ];
    },
    containHours() {
      if (this.isOlMatter)
        return this.matter_time_allocations.filter(
          (x) =>
            this.timeAllocationWithNoStudyEnvironment
              .map((a) => a.id)
              .includes(x.time_allocation) &&
            x.matter == this.matter_id &&
            x.hours > 0
        );
      else
        return this.ep_matter_time_allocations.filter(
          (x) =>
            this.timeAllocationWithNoStudyEnvironment
              .map((a) => a.id)
              .includes(x.time_allocation) &&
            x.egress_profile_matter == this.matter_id &&
            x.hours > 0
        );
    },
    timeAllocationWithNoStudyEnvironment() {
      let time_allocations_ids = [];
      if (this.isOlMatter)
        time_allocations_ids = [
          ...new Set(
            this.MatEvaStuEnvTimAll.filter(
              (x) =>
                x.study_environment == null &&
                x.matter_evaluation == this.matter_evaluation_id &&
                x.hours > 0
            ).map((x) => x.time_allocation)
          ),
        ];
      else
        time_allocations_ids = [
          ...new Set(
            this.MatEvaStuEnvTimAll.filter(
              (x) =>
                x.study_environment == null &&
                x.egress_profile_matter_evaluation ==
                  this.matter_evaluation_id &&
                x.hours > 0
            ).map((x) => x.time_allocation)
          ),
        ];
      if (this.StudyEnvironmentIds.length > 0)
        return this.timeAllocationsIsPedagogical.filter(
          (x) =>
            time_allocations_ids.includes(x.id) || !x.has_study_environments
        );
      else return this.timeAllocationsIsPedagogical;
    },
    studyEnvironmentsFilter() {
      return this.studyEnvironments.filter(
        (x) =>
          this.StudyEnvironmentIds.includes(x.id) ||
          this.studyEnvironmentExist.includes(x.id)
      );
    },
    timeAllocationsIsPedagogical() {
      return this.time_allocations.filter(
        (x) => x.uses_module_minutes == this.isPedagogical
      );
    },
  },
  methods: {
    StudyEnvironmentTimeAllocation(study_environment_id) {
      const time_allocation_ids = [
        ...new Set(
          this.studyEnvironmentsTimeAllocation
            .filter((x) => x.study_environment == study_environment_id)
            .map((x) => x.time_allocation)
        ),
      ];
      if (this.isOlMatter)
        return this.timeAllocationsIsPedagogical.filter(
          (x) =>
            time_allocation_ids.includes(x.id) ||
            this.MatEvaStuEnvTimAll.find(
              (x) =>
                x.matter_evaluation == this.matter_evaluation_id &&
                x.study_environment == study_environment_id &&
                x.time_allocation == x.id &&
                x.hous > 0
            )
        );
      else
        return this.timeAllocationsIsPedagogical.filter(
          (x) =>
            time_allocation_ids.includes(x.id) ||
            this.MatEvaStuEnvTimAll.find(
              (x) =>
                x.egress_profile_matter_evaluation ==
                  this.matter_evaluation_id &&
                x.study_environment == study_environment_id &&
                x.time_allocation == x.id &&
                x.hous > 0
            )
        );
    },
    createdMatStuEnvTimAll(tmp_mat_eva_stu_env_tim_all) {
      this.$emit("created", tmp_mat_eva_stu_env_tim_all);
    },
    UpdateMatStuEnvTimAll(tmp_mat_eva_stu_env_tim_all) {
      this.$emit("updated", tmp_mat_eva_stu_env_tim_all);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
</style>