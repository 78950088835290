
export default [
    {
        path: '/instrument/',
        name: 'InstrumentView',
        component: () =>
            import ("@/components/evaluations/tmp/InstrumentView"),
    },
    {
        path: '/evaluation/form/:evaluation_id/',
        name: 'EvaluationView',
        component: () =>
            import ("@/components/evaluations/EvaluationView"),
    },
    {
        path: '/evaluation/form/:evaluation_id/:question_test_id/',
        name: 'SpecificationTableView',
        component: () =>
            import ("@/components/evaluations/SpecificationTable/SpecificationTableView"),
    },
    {
        path: '/evaluation/needs-grading/:section_id/',
        name: 'NeedsGradingView',
        component: () =>
            import ("@/components/evaluations/NeedsGrading/NeedsGradingView"),
    },
    {
        path: '/evaluation/full-grading/:section_id/',
        name: 'FullGradingCenterView',
        component: () =>
            import ("@/components/evaluations/NeedsGrading/FullGradingCenterView"),
    },
    {
        path: '/answer/:evaluation_id/',
        name: 'AnswerView',
        component: () =>
            import("@/components/evaluations/AnswerView"),
    },
];
