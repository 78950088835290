<template>
  <div class="row page-break" ref="row">
    <div class="col level-col">
      <span>Nivel {{ cognitive_level.level }}</span>
      <br />
      <ResizeAuto v-if="allows_crud">
        <template v-slot:default="{ resize }">
          <textarea
            @input="resize"
            v-model="cognitive_level.name"
            placeholder="Inserte el nombre aquí..."
            class="input-level"
          ></textarea>
        </template>
      </ResizeAuto>
      <p v-else>{{ cognitive_level.name }}</p>
    </div>
    <div
      class="col verbs-col"
      v-bind:style="{ minHeight: row_height + 'px' }"
      ref="second_col"
      v-resize="onSecondColResize"
    >
      <ResizeAuto>
        <template v-slot:default="{ resize }">
          <textarea
            class="textarea"
            @input="resize"
            v-model="cognitive_level.description"
            placeholder="Inserte la descripción aquí..."
            :disabled="!allows_crud"
          ></textarea>
        </template>
      </ResizeAuto>
    </div>
    <div
      class="col verbs-col"
      style="padding-left: 5px; padding-right: 15px"
      v-bind:style="{ minHeight: row_height + 'px' }"
      ref="third_col"
    >
      <tag-list
        :words="cognitive_level.verbs"
        :cognitive_level_id="cognitive_level.id"
        :allows_crud="allows_crud"
      ></tag-list>
    </div>
    <div
      v-if="allows_crud"
      v-can="'mesh.change_cognitivelevel mesh.delete_cognitivelevel'"
    >
      <div class="col">
        <div class="row">
          <b-button
            size="sm"
            variant="none"
            :title="`Guardar ${$getVisibleNames(
              'mesh.cognitivelevel',
              false,
              'Nivel Cognitivo'
            )}`"
            @click="saveCognitiveLevel"
          >
            <b-icon-box-arrow-in-down
              class="h4 mb-0"
            ></b-icon-box-arrow-in-down>
          </b-button>
        </div>
        <div class="row">
          <b-button
            size="sm"
            variant="none"
            :title="`Eliminar ${$getVisibleNames(
              'mesh.cognitivelevel',
              false,
              'Nivel Cognitivo'
            )}`"
            @click="askForDelete"
          >
            <b-icon-trash class="h4 mb-0"></b-icon-trash>
          </b-button>
        </div>
        <div
          class="row"
          style="cursor: move; text-align: center; margin-left: -3px"
          :title="`Arrastrar para ordenar el ${$getVisibleNames(
            'mesh.cognitivelevel',
            false,
            'Nivel Cognitivo'
          )}.`"
        >
          <b-icon-arrows-expand></b-icon-arrows-expand>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TagList from "@/components/reusable/TagList.vue";
import ResizeAuto from "@/components/reusable/ResizeAuto";
import resize from "vue-resize-directive";
import { toast } from "@/utils/utils";

export default {
  name: "CognitiveLevelRow",
  components: {
    TagList,
    ResizeAuto,
  },
  directives: {
    resize,
  },
  props: {
    taxonomy_id: {
      type: Number,
      required: true,
    },
    CognitiveLevel: {
      type: Object,
      default: function () {
        return {
          id: -1,
          level: 1,
          name: "",
          description: "",
          active: true,
          taxonomy: this.taxonomy_id,
          verbs: [],
        };
      },
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cognitive_level: this.CognitiveLevel,
      row_height: 100,
    };
  },
  methods: {
    onSecondColResize(item) {
      this.row_height = item.clientHeight;
    },
    saveCognitiveLevel() {
      if (this.cognitive_level.id === -1) {
        this.createCognitiveLevel();
      } else {
        this.updateCognitiveLevel();
      }
    },
    createCognitiveLevel() {
      this.$restful
        .Post(`/mesh/cognitive-level/`, this.cognitive_level)
        .then((response) => {
          this.cognitive_level = response;
          toast(
            String(
              this.$getVisibleNames(
                "mesh.cognitivelevel",
                false,
                "Nivel Cognitivo"
              ) + " creado."
            )
          );
        });
    },
    updateCognitiveLevel() {
      this.$restful
        .Put(
          `/mesh/cognitive-level/${this.cognitive_level.id}/`,
          this.cognitive_level
        )
        .then((response) => {
          this.cognitive_level = response;
          toast(
            String(
              this.$getVisibleNames(
                "mesh.cognitivelevel",
                false,
                "Nivel Cognitivo"
              ) + " actualizado."
            )
          );
        });
    },
    patchCognitiveLevel(item) {
      this.$restful
        .Patch(`/mesh/cognitive-level/${this.cognitive_level.id}/`, item)
        .then((response) => {
          this.cognitive_level = response;
        });
    },
    askForDelete() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.cognitivelevel",
          false,
          "Nivel Cognitivo"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteCognitiveLevel();
        }
      });
    },
    deleteCognitiveLevel() {
      this.$restful
        .Delete(`/mesh/cognitive-level/${this.cognitive_level.id}/`)
        .then(() => {
          this.$emit("deleted", this.cognitive_level.id);
        });
    },
  },
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}
.row {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.level-col * {
  background-color: var(--primary-color);
  font-size: 10pt !important;
  border: solid var(--primary-color);
  color: white;
}
input[class="level"] {
  max-width: 50px;
}
input[class="header"] {
  text-align: center;
}
textarea:focus,
input:focus {
  outline: none;
}
.level-col {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  background-color: var(--primary-color);
  margin-left: 5px;
  margin-right: 5px;
  width: 50% !important;
  font-size: 10pt !important;
  outline: none;
}
.verbs-col {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  background-color: var(--secondary-color);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 5px;
  margin-right: 5px;
  font-size: var(--secondary-font-size);
  line-height: 1 !important;
  width: 95%;
  outline: none;
  overflow: hidden;
}
.textarea {
  border: none;
  background-color: var(--secondary-color);
  font-size: var(--secondary-font-size);
  line-height: 1 !important;
  padding-top: 1rem;
  min-height: 11.5rem;
  min-width: fit-content;
  resize: none;
}
.input-level {
  min-height: 10rem;
  min-width: 100%;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(128, 128, 128);
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(124, 124, 124);
}
.page-break {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
</style>