var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 page-break"},[(_vm.cycle)?_c('h4',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo"))+": "+_vm._s(_vm.cycle.name)+" ")]):_vm._e(),(_vm.semester == 1)?_c('div',{staticClass:"legend"},[_vm._v(" El ' * ' representa que ese valor no se contabilizará en el cálculo Total de Horas. ")]):_vm._e(),_c('table',{staticClass:"table"},[_c('thead',{staticStyle:{"background-color":"var(--primary-color)"}},[_c('th',{attrs:{"colspan":10 + _vm.time_allocations.length}},[_vm._v(" "+_vm._s(_vm.numberToWord(_vm.semester))+" NIVEL "),(!_vm.egressProfile.is_closed)?_c('span',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_egressprofile'),expression:"'mesh.change_egressprofile'"}]},[(_vm.limitLevel && _vm.limitLevel.max_hours != 0)?_c('span',[_vm._v(" - "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours", true, "Total de Horas Pedagógicas" ))+": "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                `${_vm.$getVisibleNames(
                  'manual.total_pedagogical_hours',
                  true,
                  'Total de Horas Pedagógicas'
                )} en el Nivel.`
              ),expression:"\n                `${$getVisibleNames(\n                  'manual.total_pedagogical_hours',\n                  true,\n                  'Total de Horas Pedagógicas'\n                )} en el Nivel.`\n              ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],class:_vm.limitLevel && _vm.limitLevel.max_hours < _vm.totalPedagogical
                  ? 'limit-class'
                  : ''},[_vm._v(" "+_vm._s(_vm.totalPedagogical)+" ")]),(_vm.limitLevel && _vm.limitLevel.max_hours != 0)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:('Máximo sugerido.'),expression:"'Máximo sugerido.'",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}]},[_vm._v("/ "+_vm._s(_vm.limitLevel.max_hours))]):_vm._e()]):_vm._e(),(_vm.limitLevel && _vm.limitLevel.max_credits != 0)?_c('span',[_vm._v(" - Total Créditos: "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                'Total de Créditos en el Nivel.'
              ),expression:"\n                'Total de Créditos en el Nivel.'\n              ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],class:_vm.limitLevel && _vm.limitLevel.max_credits < _vm.totalSCT
                  ? 'limit-class'
                  : ''},[_vm._v(" "+_vm._s(_vm.totalSCT)+" ")]),(_vm.limitLevel && _vm.limitLevel.max_credits != 0)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                'Máximo de Créditos sugerido.'
              ),expression:"\n                'Máximo de Créditos sugerido.'\n              ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}]},[_vm._v("/ "+_vm._s(_vm.limitLevel.max_credits))]):_vm._e()]):_vm._e()]):_vm._e()]),_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("N°")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.egressprofilematter", true, "Asignaturas"))+" ")]),_c('th',{attrs:{"scope":"col","title":`${_vm.$getVisibleNames(
            'mesh.egressprofilematter',
            true,
            'Asignaturas'
          )} que debe vencer para tomar esta.`}},[_vm._v(" Requisitos ")]),_c('th',{staticClass:"header-row-rotated",attrs:{"scope":"col","title":`${_vm.$getVisibleNames(
            'mesh.matterregime',
            true,
            'Regímenes'
          )}`}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.matterregime", true, "Regímenes"))+" ")]),_c('th',{staticClass:"header-row-rotated",attrs:{"scope":"col","title":"Semanas"}},[_vm._v(" Semanas ")]),_vm._l((_vm.time_allocations.filter(
            (x) =>
              x.uses_module_minutes == true &&
              this.egress_profile_time_allocations.includes(x.id)
          )),function(hour){return _c('th',{key:hour.id,staticClass:"header-row-rotated",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(hour.name)+" ")])}),_c('th',{staticClass:"header-row-rotated",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours", true, "Total de Horas Pedagógicas" ))+" ")]),_c('th',{staticClass:"header-row-rotated",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours_to_chronological", true, "Total de Horas Pedagógicas (En Cronológicas)" ))+" ")]),_vm._l((_vm.time_allocations.filter(
            (x) =>
              x.uses_module_minutes == false &&
              this.egress_profile_time_allocations.includes(x.id)
          )),function(hour){return _c('th',{key:hour.id,attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(hour.name)+" ")])}),_c('th',{staticClass:"header-row-rotated",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_chronological_hours", true, "Total de Horas Cronológicas" ))+" ")]),_c('th',{staticClass:"header-row-rotated",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_hours_sum_to_chronologica", true, "Total de Horas (Pedagógicas + Cronológicas)" ))+" ")]),_c('th',{staticClass:"header-row-rotated",attrs:{"scope":"col","title":_vm.$getVisibleNames('manual.credits', true, 'Créditos SCT Totales')}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("manual.credits", true, "Créditos SCT Totales"))+" ")])],2)]),_vm._l((_vm.formationAreasList),function(area){return _c('StudyPlanFormationArea',{key:area.id,attrs:{"egress_profile_id":_vm.egress_profile_id,"semester":_vm.semester,"limit_study_plan":_vm.limit_study_plan,"egress_profile_time_allocations":_vm.egress_profile_time_allocations,"formation_area_id":area.id,"allows_crud":_vm.allows_crud &&
        ((_vm.user_position &&
          [1, 2, 3].includes(_vm.user_position.position) &&
          [1, 2, 3].includes(_vm.user.groups[0])) ||
          _vm.user.is_superuser ||
          _vm.$hasObjectPermission('mesh.career', 'change', _vm.egressProfile.career))}})}),_c('tr',[_c('td',{staticStyle:{"text-align":"end"},attrs:{"colspan":"5"}},[_vm._v("Total")]),_vm._l((_vm.time_allocations.filter(
          (x) =>
            x.uses_module_minutes == true &&
            this.egress_profile_time_allocations.includes(x.id)
        )),function(hour){return _c('td',{key:hour.id},[_vm._v(" "+_vm._s(_vm.countTimeAllocationHours(hour.id))+" ")])}),_c('td',[_vm._v(_vm._s(_vm.totalPedagogical))]),(_vm.profileType)?_c('td',[_vm._v(" "+_vm._s(_vm._f("Round")((_vm.totalPedagogical * (parseFloat(_vm.profileType.module_minutes) / 60))))+" ")]):_vm._e(),_vm._l((_vm.time_allocations.filter(
          (x) =>
            x.uses_module_minutes == false &&
            this.egress_profile_time_allocations.includes(x.id)
        )),function(hour){return _c('td',{key:hour.id},[_vm._v(" "+_vm._s(_vm.countTimeAllocationHours(hour.id))+" ")])}),_c('td',[_vm._v(_vm._s(_vm.totalChronological))]),_c('td',[_vm._v(_vm._s(_vm.totalHours))]),_c('td',[(_vm.profileType)?_c('div',[_c('span',{class:_vm.limitLevel &&
              _vm.limitLevel.max_credits != 0 &&
              _vm.limitLevel.max_credits < _vm.totalSCT
                ? 'limit-class'
                : ''},[_vm._v(_vm._s(_vm.totalSCT))])]):_vm._e()])],2)],2),(!((_vm.institution && _vm.institution.id == 12) || _vm.$debug_change_duoc))?_c('div',[(
        !(
          _vm.allows_crud &&
          ((_vm.user_position &&
            [1, 2, 3].includes(_vm.user_position.position) &&
            [1, 2, 3].includes(_vm.user.groups[0])) ||
            _vm.user.is_superuser ||
            _vm.$hasObjectPermission(
              'mesh.career',
              'change',
              _vm.egressProfile.career
            ))
        )
      )?_c('div',_vm._l((_vm.comment.comments.split('\n')),function(p,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(p)+" ")])}),0):_c('div',[_c('h6',{staticClass:"comments-header"},[_vm._v("Comentarios del Nivel "+_vm._s(_vm.semester))]),_c('ResizeAuto',{scopedSlots:_vm._u([{key:"default",fn:function({ resize }){return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment.comments),expression:"comment.comments"}],attrs:{"placeholder":"Ingrese su comentario aquí..."},domProps:{"value":(_vm.comment.comments)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.comment, "comments", $event.target.value)},resize],"focus":resize,"blur":_vm.saveComment}})]}}],null,false,1237915224)})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }