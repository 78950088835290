<template>
  <div>
    <table>
      <thead>
        <tr>
          <th style="width: 25%">
            {{
              $getVisibleNames("mesh.career", false, "Programa").toUpperCase()
            }}
          </th>
          <td colspan="3" style="padding: 0 16px">
            <template v-if="career">{{ career.name }}</template>
          </td>
          <!-- <th rowspan="2" style="width: 15%">
            {{
              $getVisibleNames(
                "mesh.campus",
                false,
                "SEDE/CAMPUS"
              ).toUpperCase()
            }}
          </th> -->
          <!-- <template v-if="campus">
              <template v-for="item in campus" >
                <div v-bind:key="item.id" class="campus-div">
                  {{item.name}}
                </div>
              </template>
            </template> -->
          <!-- <td rowspan="2" colspan="2" class="campus-container">
            <div class="campus-div">
              {{ campus ? campus.name : "-" }}
            </div>
          </td> -->
          <th rowspan="2" style="width: 15%">
            N°
            {{
              $getVisibleNames(
                "teaching.section",
                false,
                "Sección"
              ).toUpperCase()
            }}
          </th>
          <td rowspan="2" colspan="2" style="width: 5%">
            {{ section ? section.name : "-" }}
          </td>
        </tr>
        <tr>
          <th>
            {{
              $getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              ).toUpperCase()
            }}
          </th>
          <td colspan="3" v-if="oldMatter">
            <b-link
              :to="{
                name: 'MatterProgramView',
                params: { matter_id: Matter.id },
              }"
              >{{ oldMatter.name }}</b-link
            >
          </td>
        </tr>
      </thead>
    </table>
    <table class="mt-2">
      <tbody>
        <tr v-for="professor in professors" :key="professor.id">
          <th style="width: 25%">Nombre del Docente</th>
          <td style="width: 25%">
            {{ professor.first_name + " " + professor.last_name }}
          </td>
          <th style="width: 25%">Correo Electrónico</th>
          <td style="width: 25%">{{ professor.email }}</td>
        </tr>
        <tr>
          <th style="width: 25%">Horario de Atención al Estudiante</th>
          <td colspan="3" class="td-header text-left px-3">
            <template v-if="allows_crud">
              <StyledTextArea
                v-model="attention_hours"
                @input="debouncePatchSection"
              ></StyledTextArea>
            </template>
            <template v-else-if="section">{{
              section.attention_hours
            }}</template>
          </td>
        </tr>
        <tr>
          <th style="width: 25%">
            Normas de Convivencia para la
            {{
              $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
            }}
          </th>
          <td colspan="3" class="td-header text-left px-3">
            <template v-if="allows_crud">
              <StyledTextArea
                v-model="coexistence_ruls"
                @input="debouncePatchSection"
              ></StyledTextArea>
            </template>
            <template v-else-if="section">{{
              section.coexistence_ruls
            }}</template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "Header",
  components: {
    StyledTextArea: () => import("@/components/reusable/StyledTextArea"),
  },
  props: {
    Matter: {
      type: Object,
      required: true,
    },
    section_id: {
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attention_hours: "",
      coexistence_ruls: "",
    };
  },
  computed: {
    ...mapGetters({
      campuses: names.CAMPUSES,
      matters: names.MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      sections: names.SECTIONS,
      users: names.USERS,
    }),
    egressProfile() {
      return this.egressProfiles.find(
        (x) => x.id == this.Matter.egress_profile
      );
    },
    oldMatter() {
      if (!this.Matter) return null;
      return this.matters.find((x) => x.id == this.Matter.matter);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) =>
        x.egress_profiles.includes(this.egressProfile.id)
      );
    },
    section() {
      return this.sections.find((x) => x.id == this.section_id);
    },
    professors() {
      let list = [];
      if (!this.section) return list;
      this.section.professors.forEach((element) => {
        const instance = this.users.find((x) => x.id == element);
        if (instance) return list.push(instance);
      });
      return list;
    },
    campus() {
      if (!this.section) return null;
      return this.campuses.find((x) => x.id == this.section.campus);
    },
  },
  methods: {
    fetchProfessors() {
      if (!this.section) return;
      this.section.professors.forEach((element) => {
        this.$store.dispatch(names.FETCH_USER, element);
      });
    },
    debouncePatchSection() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const payload = {
          section_id: this.section.id,
          item: {
            attention_hours: this.attention_hours,
            coexistence_ruls: this.coexistence_ruls,
          },
        };
        this.$store.dispatch(names.PATCH_SECTION, payload).then(() => {
          toast(
            this.$getVisibleNames("teaching.section", false, "Sección") +
              " actualizada."
          );
        });
      }, 2000);
    },
  },
  watch: {
    section() {
      this.fetchProfessors();
      if (this.section) {
        this.attention_hours = this.section.attention_hours;
        this.coexistence_ruls = this.section.coexistence_ruls;
      }
    },
  },
  created() {
    if (
      this.egressProfiles.findIndex(
        (x) => x.id == this.Matter.egress_profile
      ) == -1
    ) {
      this.$store
        .dispatch(names.FETCH_EGRESS_PROFILE, this.Matter.egress_profile)
        .then((response) => {
          if (this.careers.findIndex((x) => x.id == response.career) == -1) {
            this.$store.dispatch(names.FETCH_CAREER, response.career);
          }
          if (
            response.campus &&
            this.campuses.findIndex((x) => x.id == response.campus) == -1
          ) {
            this.$store.dispatch(names.FETCH_CAMPUS, response.campus);
          }
        });
    }
    this.fetchProfessors();
    if (this.section) {
      this.attention_hours = this.section.attention_hours;
      this.coexistence_ruls = this.section.coexistence_ruls;
    }
  },
};
</script>

<style scoped>
table {
  /* position: relative; */
  width: 100%;
  border: 2px solid black;
  font-size: var(--primary-font-size);
}
th {
  vertical-align: middle;
  border: 1px solid black;
  background-color: var(--primary-color);
  color: white;
  /* padding: 0px 5px; */
  position: sticky;
  font-size: var(--secondary-font-size);
}
td {
  border: 1px solid black;
}
.td-header {
  background-color: var(--secondary-color);
}
p {
  text-align: justify;
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: fit-content;
  padding: 4px 6px;
  border: none;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  border-radius: 3px;
  margin: 0 4px;
  margin-top: 2%;
  margin-bottom: -2.8em;
  padding: 1px 4px;
  padding-top: 2.5%;
  flex-grow: 1;
  font-size: var(--secondary-font-size);
  text-align: center;
}
</style>