<template>
  <div>
    <div class="row-div mt-3">
      <slot name="editevaluation"></slot>
      <b-button
        v-if="allows_crud"
        @click="$bvModal.show('modal-create-instrument')"
        size="sm"
        variant="primary"
        class="mb-2 mr-2"
        >Crear Instrumento</b-button
      >
      <b-button
        @click="$bvModal.show('modal-instruments-evaluation-criterias')"
        size="sm"
        variant="primary"
        class="mb-2 mr-2"
      >
        {{
          $getVisibleNames(
            "manual.criterio_de_evaluacion",
            true,
            "Criterios de Evaluación"
          )
        }}
      </b-button>
    </div>
    <template v-if="evaluation">
      <!-- Desarrollo Breve, Extenso y Selección Simple  -->
      <template
        v-if="
          evaluation.evaluation_instrument >= 1 &&
          evaluation.evaluation_instrument <= 4
        "
      >
        <b-tabs content-class="mt-3" align="center">
          <b-tab :title="`Tabla de Especificaciones`" active>
            <SpecificationTable
              v-if="questionTest"
              class="mt-2"
              :question_test_id="questionTest.id"
              :allows_crud="allows_crud"
            ></SpecificationTable>
          </b-tab>
          <!-- v-if="$store.getters.hasPermission('teaching.change_evaluation')" -->
          <b-tab :title="`Test`" style="text-align: center">
            <div class="evaluation-container">
              <TestMaker
                v-if="questionTest"
                :question_test_id="questionTest.id"
                :allows_crud="allows_crud"
                :is_running="is_running"
                :section_evaluation_stats="section_evaluation_stats"
                :can_answer="can_answer"
              ></TestMaker>
            </div>
          </b-tab>
        </b-tabs>
      </template>
      <!-- Rúbricas -->
      <template v-if="evaluation.evaluation_instrument >= 5">
        <template v-if="!rubric && !allows_crud">
          <small>Para crear el instrumento debe activar el modo edición.</small>
        </template>
        <template v-else-if="rubric">
          <b-tabs content-class="mt-3" align="center">
            <b-tab :title="rubric.name" active>
              <ObservationInstrument
                v-if="!evaluate_mode"
                :rubric_id="rubric.id"
                :is_running="is_running"
                :section_evaluation_stats="section_evaluation_stats"
                :allows_crud="allows_crud"
              ></ObservationInstrument>
              <EvaluateRubric v-else :rubric_id="rubric.id"></EvaluateRubric>
              <b-button
                v-if="evaluation.finished && can_edit_evaluation"
                size="sm"
                class="mt-1 noprint"
                variant="primary"
                @click="evaluate_mode = !evaluate_mode"
                >{{ evaluate_mode ? "Editar" : "Evaluar" }}</b-button
              >
            </b-tab>
          </b-tabs>
        </template>
      </template>
    </template>
    <!-- Modals -->
    <template>
      <b-modal
        id="modal-create-instrument"
        title="Crear Instrumento"
        hide-footer
        size="lg"
      >
        <b-form-select
          :options="instrument_types"
          v-model="selected_instrument_type"
          class="mb-2"
          text-field="name"
        >
          <template #first>
            <b-form-select-option value="null" disabled>
              Seleccione un tipo de Instrumento...
            </b-form-select-option>
          </template>
        </b-form-select>
        <template v-if="selected_instrument_type == 1">
          <QuestionTestForm
            :TestForm="true"
            :evaluation_id="evaluation_id"
            @created="$bvModal.hide('modal-create-instrument')"
          ></QuestionTestForm>
        </template>
        <template v-if="selected_instrument_type == 2">
          <ObservationInstrumentForm
            :TestForm="false"
            :evaluation_id="evaluation_id"
            @created="$bvModal.hide('modal-create-instrument')"
          ></ObservationInstrumentForm
        ></template>
      </b-modal>
    </template>
    <template>
      <b-modal
        id="modal-instruments-evaluation-criterias"
        :title="
          $getVisibleNames(
            'manual.criterio_de_evaluacion',
            true,
            'Criterios de Evaluación'
          )
        "
        hide-footer
        size="lg"
      >
        <InstrumentsEvaluationCriterias
          :evaluation_id="evaluation_id"
          :question_tests="questionTestsList"
          :observation_instruments="rubricsList"
        ></InstrumentsEvaluationCriterias>
      </b-modal>
    </template>
    <div class="mt-2">
      <b-button
        class="toggle-evaluation-instruments mt-2 noprint"
        size="sm"
        v-b-toggle.collapse-a.collapse-question-tests
        variant="none"
        v-if="questionTestsList.length > 0"
        >Instrumentos Escritos
      </b-button>
      <b-collapse
        v-model="collapse1_visible"
        id="collapse-question-tests"
        v-if="questionTestsList.length > 0"
        class="mt-1"
      >
        <b-tabs v-model="tabIndexTests" nav-class="noprint">
          <div
            class="empty-instrument-view"
            v-if="questionTestsList.length < 1"
          >
            No existen instrumentos escritos para esta evaluación.
          </div>
          <b-tab
            v-for="question_test in questionTestsList"
            :title="question_test.title"
            :key="question_test.id"
          >
            <TestMaker
              v-if="question_test"
              :question_test_id="question_test.id"
              :evaluation_id="evaluation_id"
              :allows_crud="allows_crud"
              :is_running="is_running"
              :section_evaluation_stats="section_evaluation_stats"
              :can_answer="can_answer"
            ></TestMaker
          ></b-tab>
          <b-button
            v-if="is_running && questionTestsList.length > 1"
            class="mt-2"
            size="sm"
            @click="tabIndexTests++"
            >Siguiente Instrumento</b-button
          >
        </b-tabs>
      </b-collapse>
    </div>
    <b-button
      class="toggle-evaluation-instruments mt-2 noprint"
      size="sm"
      v-b-toggle.collapse-a.collapse-observation-instruments
      v-if="rubricsList.length > 0"
      variant="none"
      >Instrumentos de Observación
    </b-button>
    <b-collapse
      v-model="collapse2_visible"
      id="collapse-observation-instruments"
      v-if="rubricsList.length > 0"
      class="mt-1"
    >
      <b-tabs v-model="tabIndexObservation">
        <div class="empty-instrument-view" v-if="rubrics.length < 1">
          No existen instrumentos de observación para esta evaluación.
        </div>
        <b-tab
          v-for="observation_instrument in rubricsList"
          :title="observation_instrument.title"
          :key="observation_instrument.id"
        >
          <!-- v-if="!evaluate_mode" -->
          <ObservationInstrument
            :observation_instrument_id="observation_instrument.id"
            :section_evaluation_stats="section_evaluation_stats"
            :allows_crud="allows_crud"
          ></ObservationInstrument>
          <!-- <EvaluateRubric v-else :rubric_id="observation_instrument.id"></EvaluateRubric> -->
          <!-- <b-button
            v-if="evaluation.finished && can_edit_evaluation"
            size="sm"
            class="mt-1 noprint"
            variant="primary"
            @click="evaluate_mode = !evaluate_mode"
            >{{ evaluate_mode ? "Editar" : "Evaluar" }}</b-button
          > -->
          <b-button
            v-if="is_running && rubricsList.length > 1"
            class="mt-2"
            size="sm"
            @click="tabIndexObservation++"
            >Siguiente Instrumento</b-button
          >
        </b-tab>
      </b-tabs>
    </b-collapse>
    <!-- <b-tabs>
        <b-tab title="Exámenes Escritos">
          <GenericBTable :items="questionTests" :fields="question_tests_fields">
            <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            @click="$bvModal.show(`edit-question-test-modal-${row.item.id}`)"
            v-b-tooltip.v-secondary.noninteractive="
              'Editar Examen Escrito'
            "
          ></button-edit>
          <button-delete
            @click="askForDeleteQuestionTest(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              'Eliminar Examen Escrito'
            "
          ></button-delete>
          <b-modal
            :id="`edit-question-test-modal-${row.item.id}`"
            title="Editar Examen Escrito"
            size="md"
            hide-footer
          >
            <QuestionTestForm
              :QuestionTest="row.item"
              @updated="$bvModal.hide(`edit-question-test-modal-${formation_line.id}`);"
              :evaluation_id="evaluation_id"
            ></QuestionTestForm>
          </b-modal>
        </template>
          </GenericBTable>
        </b-tab>
        <b-tab title="Instrumentos de Observación">
          <GenericBTable :items="rubrics" :fields="observation_instruments_fields">
            <template #cell(instrument_type)="row">
              <div v-if="row.item.instrument_type == 5">Rúbrica</div>
              <div v-if="row.item.instrument_type == 7">Pauta de Cotejo</div>
            </template>
          </GenericBTable>
        </b-tab>
      </b-tabs> -->
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { toast } from "@/utils/utils";

export default {
  name: "EvaluationManager",
  components: {
    TestMaker: () => import("./Test/TestMaker"),
    SpecificationTable: () => import("./SpecificationTable/SpecificationTable"),
    ObservationInstrument: () =>
      import("./ObservationInstrument/ObservationInstrument"),
    EvaluateRubric: () => import("./ObservationInstrument/EvaluateRubric"),
    ObservationInstrumentForm: () =>
      import("./ObservationInstrument/ObservationInstrumentForm"),
    QuestionTestForm: () => import("./Test/QuestionTestForm"),
    InstrumentsEvaluationCriterias: () =>
      import("./InstrumentsEvaluationCriterias"),
    // GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    evaluation_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    is_running: {
      type: Boolean,
      default: false,
    },
    question_test_id: {
      default: null,
    },
    observation_instrument_id: {
      default: null,
    },
    can_answer: {
      type: Boolean,
      default: false,
    },
    can_edit_evaluation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      evaluate_mode: false,
      instrument_types: [
        { name: "Instrumento Escrito", value: 1 },
        { name: "Instrumento de Observación", value: 2 },
      ],
      selected_instrument_type: null,
      collapse1_visible: true,
      collapse2_visible: true,
      tabIndexTests: 0,
      tabIndexObservation: 0,
      section_evaluation_stats: null,
      // question_tests_fields: [
      //   {
      //     key: "title",
      //     label: "Nombre",
      //     display_column: true,
      //   },
      //   {
      //     key: "description",
      //     label: "Descripción",
      //     display_column: true,
      //   },
      //   {
      //     key: "start_date",
      //     label: "Fecha de Inicio",
      //     thStyle: { width: "20%" },

      //     display_column: true,
      //   },
      //   {
      //     key: "due_date",
      //     label: "Fecha de Entrega",
      //     thStyle: { width: "20%" },
      //     display_column: true,
      //   },
      //   {
      //     key: "actions",
      //     label: "",
      //     display_column: true,
      //     thClass: "noprint",
      //     tdClass: "noprint",
      //   },
      // ],
      // observation_instruments_fields: [
      //   {
      //     key: "title",
      //     label: "Nombre",
      //     display_column: true,
      //   },
      //   {
      //     key: "description",
      //     label: "Descripción",
      //     display_column: true,
      //   },
      //   {
      //     key: "instrument_type",
      //     label: "Tipo de Instrumento",
      //     display_column: true,
      //   },
      //   {
      //     key: "start_date",
      //     label: "Fecha de Inicio",
      //     thStyle: { width: "20%" },

      //     display_column: true,
      //   },
      //   {
      //     key: "due_date",
      //     label: "Fecha de Entrega",
      //     thStyle: { width: "20%" },
      //     display_column: true,
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters({
      getEvaluation: names.EVALUATION,
      evaluationTypes: names.EVALUATION_TYPES,
      rubrics: names.OBSERVATION_INSTRUMENTS,
      questionTests: names.QUESTION_TESTS,
      user: "getUser",
    }),
    evaluation() {
      return this.getEvaluation(this.evaluation_id);
    },
    rubric() {
      return this.rubrics.find((x) => x.evaluation == this.evaluation_id);
    },
    questionTest() {
      return this.questionTests.find((x) => x.evaluation == this.evaluation_id);
    },
    questionTestsList() {
      if (this.user.is_student) {
        const date = new Date();
        let list = this.questionTests
          .filter((x) => x.evaluation == this.evaluation_id)
          .sort(function (a, b) {
            return new Date(a.start_date) - new Date(b.start_date);
          });
        return list.sort(function (a, b) {
          return new Date(a.due_date) - date && new Date(b.due_date) - date;
        });
      } else
        return this.questionTests
          .filter((x) => x.evaluation == this.evaluation_id)
          .sort(function (a, b) {
            return new Date(a.start_date) - new Date(b.start_date);
          });
    },
    rubricsList() {
      if (this.user.is_student) {
        const date = new Date();
        let list = this.rubrics
          .filter((x) => x.evaluation == this.evaluation_id)
          .sort(function (a, b) {
            return new Date(a.start_date) - new Date(b.start_date);
          });
        return list.sort(function (a, b) {
          return new Date(a.due_date) - date && new Date(b.due_date) - date;
        });
      } else
        return this.rubrics
          .filter((x) => x.evaluation == this.evaluation_id)
          .sort(function (a, b) {
            return new Date(a.start_date) - new Date(b.start_date);
          });
    },
  },
  methods: {
    fetchSectionEvaluationStats() {
      if (this.user.is_student && this.evaluation) {
        let section = null;
        let count = 0;
        this.evaluation.sections.forEach((element) => {
          if (this.user.sections_as_student.includes(element))
            section = element;
          count++;
          if (section && count == this.evaluation.sections.length)
            this.$store
              .dispatch(names.FETCH_SECTION_EVALUATIONS, this.evaluation_id)
              .then((response) => {
                const section_evaluation = response.find(
                  (x) =>
                    x.evaluation == this.evaluation_id && x.section == section
                );
                if (section_evaluation && section_evaluation.show_stats)
                  this.$restful
                    .Get(
                      `/teaching/section_evaluation_stats/?section=${section}&evaluation=${this.evaluation_id}`
                    )
                    .then((stats) => {
                      this.section_evaluation_stats = stats;
                    });
              });
        });
      }
    },
    // askForDeleteQuestionTest(question_test_id) {
    //   this.$swal({
    //     title: `"¿Está seguro de que desea eliminar el ${this.$getVisibleNames(
    //       "teaching.questiontest",
    //       false,
    //       "Examen Escrito"
    //     )}?"`,
    //     text: "¡Esta acción no se podrá revertir!",
    //     type: "warning",
    //     showCancelButton: true,
    //   }).then((result) => {
    //     if (result.value) {
    //       this.$store
    //         .dispatch(names.DELETE_QUESTION_TEST, question_test_id)
    //         .then(() => {
    //           toast(
    //             String(
    //               this.$getVisibleNames(
    //                 "teaching.questiontest",
    //                 false,
    //                 "Examen Escrito"
    //               ) + " eliminado."
    //             )
    //           );
    //         });
    //     }
    //   });
    // },
  },
  created() {
    this.$store.dispatch(
      names.FETCH_OBSERVATION_INSTRUMENTS,
      this.evaluation_id
    );
    this.$store.dispatch(names.FETCH_QUESTION_TESTS, this.evaluation_id);
    this.$store.dispatch(names.FETCH_EVALUATION_TYPES);
    if (this.observation_instrument_id) {
      this.collapse1_visible = false;
      const index = this.rubricsList.findIndex(
        (x) => x.id == this.observation_instrument_id
      );
      this.tabIndexObservation = index;
    }
    if (this.question_test_id) {
      this.collapse2_visible = false;
      const index = this.questionTestsList.findIndex(
        (x) => x.id == this.question_test_id
      );
      this.tabIndexTests = index;
    }
  },
  mounted() {
    this.fetchSectionEvaluationStats();
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.row-div {
  display: flex;
  flex-direction: row;
}
.btn-add-instrument {
  background-color: #044e9c !important;
  color: #fff;
}
.btn-add-instrument:hover {
  background-color: #1d60a6 !important;
}
.toggle-evaluation-instruments {
  width: 100%;
  background-color: var(--kl-secondary-button-color) !important;
  color: #fff;
  transition: all 0.3s;
}
.toggle-evaluation-instruments:hover {
  background-color: var(--kl-secondary-button-hover-color) !important;
}
.empty-instrument-view {
  padding: 2em 0;
  background: rgb(237, 237, 237);
}
</style>