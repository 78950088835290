<template>
  <div>
    <b-navbar id="nav-bar" type="dark" fixed="top">
      <div
        class="mr-0 brand-section"
        v-b-tooltip.v-secondary.noninteractive.hover="'Ir al Inicio'"
      >
        <router-link :to="{ name: 'Welcome' }">
          <b-img
            v-if="!isLoged || !institution_logo"
            v-bind:src="logo"
            :alt="app_name"
            class="logo pt-1 pb-1 mr-auto mt-1"
            :title="institution ? institution.name : ''"
            style="cursor: pointer"
          ></b-img>
          <b-img
            v-if="isLoged && institution_logo"
            thumbnail
            v-bind:src="institution_logo"
            :alt="institution ? institution.name : ''"
            class="logo mr-auto"
            :title="institution ? institution.name : ''"
          ></b-img>
          <b-navbar-brand v-if="!isLoged" class="brand-text mr-auto">
          </b-navbar-brand>
          <b-navbar-brand v-else class="brand-text mr-auto">
            {{ institution ? institution.name : "" }}
          </b-navbar-brand>
        </router-link>
      </div>
      <div
        class="user-info-container"
        v-bind:class="{ 'right-setting': !isLoged }"
      >
        <b-navbar-nav>
          <b-nav
            class="landing-nav"
            v-bind:class="{ 'not-visible': isLoged || app_name == 'GERA' }"
          >
            <div class="nav-items">
              <b-nav-item style="margin-left: 1%" :to="{ name: 'Welcome' }">
                <span :class="{ 'current-view': $route.name == 'Welcome' }">
                  Home
                </span>
              </b-nav-item>
              <b-nav-item
                style="margin-left: 1%"
                :to="{ name: 'SolutionsView' }"
              >
                <span
                  :class="{ 'current-view': $route.name == 'SolutionsView' }"
                >
                  Soluciones
                </span>
              </b-nav-item>
              <b-nav-item style="margin-left: 1%" :to="{ name: 'AboutUsView' }">
                <span :class="{ 'current-view': $route.name == 'AboutUsView' }">
                  Quiénes Somos
                </span>
              </b-nav-item>
              <b-nav-item
                style="margin-left: 1%"
                :to="{ name: 'TrainingView' }"
              >
                <span
                  :class="{ 'current-view': $route.name == 'TrainingView' }"
                >
                  Capacitación
                </span>
              </b-nav-item>
              <!-- <b-nav-item style="margin-left: 1%">
                <span :class="{ 'current-view': $route.name == 'Contact' }">
                  Contacto
                </span>
              </b-nav-item> -->
            </div>
          </b-nav>
          <HelpComponent v-if="isLoged"></HelpComponent>
          <!-- Perfil de usuario -->
          <b-nav>
            <b-nav-item-dropdown
              v-if="isLoged"
              disabled
              :class="{
                'dropdown-adjustment': isLoged,
                shown: isLoged,
                'sign-in': !isLoged,
              }"
            >
              <template slot="button-content">
                <div class="button-content">
                  <em class="user-name">{{ userName }}</em>
                  <b-icon class="user-icon" icon="person-circle"></b-icon>
                </div>
              </template>
              <b-dropdown-item :to="{ name: 'StudentCardView' }"
                >Perfil</b-dropdown-item
              >
              <b-dropdown-item @click="$logOut">Salir</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-button
              v-else
              variant="none"
              :to="{ name: 'Login' }"
              class="user-button p-0"
            >
              <div class="user-button-left mr-2 pl-3">USUARIO</div>
              <div class="user-button-right">
                <b-icon
                  class="user-icon"
                  scale="1.8"
                  icon="person-circle"
                ></b-icon>
              </div>
            </b-button>
          </b-nav>
        </b-navbar-nav>
      </div>
      <div
        class="mobile-nav"
        :class="{ 'not-visible': isLoged || app_name == 'KL' }"
      >
        <b-button
          @click="expanded = !expanded"
          v-b-toggle.navbar-toggle-collapse
          class="btn-collapse-mobile"
        >
          <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
          <b-icon v-else icon="chevron-bar-down"></b-icon>
        </b-button>
      </div>
    </b-navbar>
    <div :class="{ 'not-logged-in': !isLoged }" class="loadingBar noprint">
      <b-progress
        :value="loading_value"
        v-if="loading"
        height="7px"
        :max="loading_max"
        animated
      ></b-progress>
    </div>
    <b-collapse
      :class="{ 'not-visible': isLoged || app_name == 'KL' }"
      id="navbar-toggle-collapse"
      class="mobile-nav"
    >
      <div class="nav-btn">
        <b-button
          :to="{ name: 'Welcome' }"
          class="navigation-link m-1"
          variant="info"
        >
          Home
        </b-button>
        <b-button
          :to="{ name: 'SolutionsView' }"
          class="navigation-link m-1"
          variant="info"
        >
          Soluciones
        </b-button>
        <b-button
          :to="{ name: 'AboutUsView' }"
          class="navigation-link m-1"
          variant="info"
        >
          Quiénes Somos
        </b-button>
        <b-button
          :to="{ name: 'TrainingView' }"
          class="navigation-link m-1"
          variant="info"
        >
          Capacitación
        </b-button>
      </div>
    </b-collapse>
    <div
      v-if="isLoged"
      class="b-nav-section noprint"
      :class="{ 'nav-expanded': expanded }"
    >
      <b-nav class="resources-b-nav">
        <b-nav-item-dropdown
          size="sm"
          class="resources-drop first-drop m-0"
          offset="4"
          text="Definiciones Organizacionales"
          disabled
        >
          <b-dropdown-item
            v-if="
              this.user &&
              ([1].includes(this.user.groups[0]) || this.user.is_superuser)
            "
            :to="{ name: 'RegionView' }"
          >
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("common.region", true, "Región")
            }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              this.user &&
              ([1].includes(this.user.groups[0]) || this.user.is_superuser)
            "
            :to="{ name: 'CommuneView' }"
          >
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("common.commune", true, "Comuna")
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'CampusListView' }">
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.campus", true, "Sedes")
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'FacultyListView' }">
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.faculty", true, "Facultades")
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'PeriodView' }">
            <b-icon icon="chevron-right" scale="0.8"></b-icon>Períodos
            Académicos</b-dropdown-item
          >
          <b-dropdown-item
            v-if="this.user && this.user.is_superuser"
            :to="{ name: 'TimeAllocationView' }"
          >
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              this.$getVisibleNames(
                "mesh.timeallocation",
                true,
                "Divisiones de tiempo"
              )
            }}</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          size="sm"
          class="resources-drop second-drop m-0"
          text="Definiciones Curriculares"
          disabled
        >
          <b-dropdown-item :to="{ name: 'EgressProfileTypeView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.egressprofiletype",
                true,
                "Tipos de Perfiles de Egreso"
              )
            }}
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'ActTypeView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{ $getVisibleNames("cideu.acttype", true, "Tipos de Actas") }}
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'ModalityEgressProfileView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.modalityegressprofile",
                false,
                "Modalidad del Perfil de Egreso"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            :to="{ name: 'CyclesListView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{ $getVisibleNames("mesh.cycle", true, "Ciclos Formativos") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              !((institution && institution.id == 12) || $debug_change_duoc)
            "
            :to="{ name: 'CompetenceTypeView' }"
          >
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.competencetype",
                true,
                "Tipos de Competencias"
              )
            }}
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'FormationAreaView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon>
            {{
              $getVisibleNames("mesh.formationarea", true, "Áreas De Formación")
            }}
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'MatterTypeView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.mattertype", true, "Tipos de Asignatura")
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'ModalityView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.modality",
                false,
                "Modalidad de Asignaturas"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="institution && institution.show_matter_contribution"
            :to="{ name: 'MatterContributionView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon>Estrategias
            Curriculares</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'FormationLineView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon>
            {{
              $getVisibleNames(
                "mesh.formationline",
                true,
                "Líneas de Formación"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="institution && institution.show_study_environment_menu"
            :to="{ name: 'StudyEnvironmentView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.studyenvironment",
                true,
                "Ambientes de Aprendizajes"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'CertificationView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.certification",
                true,
                "Certificación de Credenciales"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'EvaluationSituationView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "teaching.evaluationsituation",
                true,
                "Situación Evaluativas"
              )
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'BaseMattersWrapperView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames(
                "mesh.basematterwrapper",
                false,
                "Envoltorio de Asignaturas"
              )
            }}</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-button
          class="btn-programs fourth-drop"
          size="sm"
          variant="none"
          :to="{ name: 'CareerListView' }"
          >Crear Programas</b-button
        >
        <b-nav-item-dropdown
          size="sm"
          class="resources-drop third-drop m-0 btn-curricular-products"
          text="Productos Curriculares"
          disabled
        >
          <b-dropdown-item :to="{ name: 'EgressProfilesList' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.egressprofile", true, "Perfiles de Egreso")
            }}</b-dropdown-item
          >
          <b-dropdown-item :to="{ name: 'CompetenceTable' }">
            <b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{ $getVisibleNames("mesh.competence", true, "Competencias") }}
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'MatterListView' }"
            ><b-icon icon="chevron-right" scale="0.8"></b-icon
            >{{
              $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas")
            }}</b-dropdown-item
          >
          <template
            v-if="
              (institution && institution.show_base_ra) || $debug_change_duoc
            "
          >
            <b-dropdown-item :to="{ name: 'RaBaseView' }"
              ><b-icon icon="chevron-right" scale="0.8"></b-icon
              >{{
                $getVisibleNames("teaching.basera", true, "RA Base")
              }}</b-dropdown-item
            >
          </template>
        </b-nav-item-dropdown>
        <PrintFab v-if="isLoged" class="noprint mt-1">
          <b-form-checkbox
            switch
            size="sm"
            class="mr-1 mt-1"
            v-if="visibility_crud"
            v-model="local_allows_crud"
            @change="mutateAllowsCrud"
            >Editar Formulario</b-form-checkbox
          >
          <b-button
            size="sm"
            variant="none"
            class="print-button"
            @click="generateReport"
          >
            <b-icon-printer></b-icon-printer>
          </b-button>
        </PrintFab>
      </b-nav>
      <PrintFab
        :responsive="true"
        v-if="isLoged"
        class="noprint mt-1 responsive-print-fab"
      >
        <b-form-checkbox
          switch
          size="sm"
          class="mr-1 mt-1"
          v-if="visibility_crud"
          v-model="local_allows_crud"
          @change="mutateAllowsCrud"
          >Editar Formulario</b-form-checkbox
        >
        <b-button
          size="sm"
          variant="none"
          class="print-button"
          @click="generateReport"
        >
          <b-icon-printer></b-icon-printer>
        </b-button>
      </PrintFab>
      <b-button
        class="responsive-nav-btn"
        variant="none"
        @click="expanded = !expanded"
        size="sm"
        ><b-icon
          v-if="expanded"
          class="responsive-nav-icon"
          icon="chevron-bar-up"
          scale="1.2"
        ></b-icon>
        <b-icon
          v-else
          class="responsive-nav-icon"
          icon="chevron-bar-down"
          scale="1.2"
        ></b-icon
      ></b-button>
      <div class="accordion-nav" :class="{ 'accordion-expanded': expanded }">
        <b-card no-body class="accordion-card mb-1">
          <b-card-header header-tag="header" class="p-0" role="tab">
            <b-button
              class="accordion-collapse"
              block
              v-b-toggle.accordion-1
              variant="none"
              size="sm"
              >Definiciones Organizacionales</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body
              v-if="
                this.user &&
                ([1].includes(this.user.groups[0]) || this.user.is_superuser)
              "
            >
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'RegionView' }"
                >{{
                  $getVisibleNames("common.region", true, "Región")
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                this.user &&
                ([1].includes(this.user.groups[0]) || this.user.is_superuser)
              "
            >
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'CommuneView' }"
                >{{
                  $getVisibleNames("common.commune", true, "Communa")
                }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'CampusListView' }"
                >{{ $getVisibleNames("mesh.campus", true, "Sedes") }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'FacultyListView' }"
                >{{
                  $getVisibleNames("mesh.faculty", true, "Facultades")
                }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'PeriodView' }"
                >Períodos Académicos</b-button
              >
            </b-card-body>
            <b-card-body v-if="this.user && this.user.is_superuser">
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'TimeAllocationView' }"
                >{{
                  this.$getVisibleNames(
                    "mesh.timeallocation",
                    true,
                    "Divisiones de tiempo"
                  )
                }}</b-button
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="accordion-card mb-1">
          <b-card-header header-tag="header" class="p-0" role="tab">
            <b-button
              class="accordion-collapse"
              block
              v-b-toggle.accordion-2
              :class="{ collapsed: !expanded, 'not-collapsed': expanded }"
              variant="light"
              size="sm"
              >Definiciones de Programas</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'EgressProfileTypeView' }"
                >{{
                  $getVisibleNames(
                    "mesh.egressprofiletype",
                    true,
                    "Tipos de Perfiles de Egreso"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'ActTypeView' }"
                >{{
                  $getVisibleNames("cideu.acttype", true, "Tipos de Actas")
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                !((institution && institution.id == 12) || $debug_change_duoc)
              "
            >
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'CyclesListView' }"
                >{{
                  $getVisibleNames("mesh.cycle", true, "Ciclos Formativos")
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="
                !((institution && institution.id == 12) || $debug_change_duoc)
              "
            >
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'CompetenceTypeView' }"
              >
                {{
                  $getVisibleNames(
                    "mesh.competencetype",
                    true,
                    "Tipos de Competencias"
                  )
                }}
              </b-button>
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'FormationAreaView' }"
                >{{
                  $getVisibleNames(
                    "mesh.formationarea",
                    true,
                    "Áreas De Formación"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'MatterTypeView' }"
              >
                {{
                  $getVisibleNames(
                    "mesh.mattertype",
                    true,
                    "Tipos de Asignatura"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'ModalityView' }"
                >{{
                  $getVisibleNames(
                    "mesh.modality",
                    false,
                    "Modalidad de Asignaturas"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="institution && institution.show_matter_contribution"
            >
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'MatterContributionView' }"
                >Estrategias Curriculares</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'FormationLineView' }"
              >
                {{
                  $getVisibleNames(
                    "mesh.formationline",
                    true,
                    "Líneas de Formación"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body
              v-if="institution && institution.show_study_environment_menu"
            >
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'StudyEnvironmentView' }"
                >{{
                  $getVisibleNames(
                    "mesh.studyenvironment",
                    true,
                    "Ambientes de Aprendizajes"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'CertificationView' }"
                >{{
                  $getVisibleNames(
                    "mesh.certification",
                    true,
                    "Certificación de Credenciales"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'EvaluationSituationView' }"
                >{{
                  $getVisibleNames(
                    "teaching.evaluationsituation",
                    true,
                    "Situación Evaluativas"
                  )
                }}</b-button
              >
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="accordion-card mb-1">
          <b-card-header header-tag="header" class="p-0" role="tab">
            <b-button
              class="accordion-collapse"
              block
              v-b-toggle.accordion-3
              variant="light"
              size="sm"
              >Registro de
              {{
                $getVisibleNames(
                  "mesh.egressprofile",
                  true,
                  "Perfiles de Egreso"
                )
              }}</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'CompetenceTable' }"
                >Listado Total de
                {{ $getVisibleNames("mesh.competence", true, "Competencias") }}
              </b-button>
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'MatterListView' }"
                >Listado Total de
                {{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    true,
                    "Asignaturas"
                  )
                }}</b-button
              >
            </b-card-body>
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'EgressProfilesList' }"
                >Registro de
                {{
                  $getVisibleNames(
                    "mesh.egressprofile",
                    true,
                    "Perfiles de Egreso"
                  )
                }}</b-button
              >
            </b-card-body>
            <template
              v-if="
                (institution && institution.show_base_ra) || $debug_change_duoc
              "
            >
              <b-card-body>
                <b-button
                  class="accordion-nav-link"
                  variant="light"
                  size="sm"
                  :to="{ name: 'RaBaseView' }"
                  >{{
                    $getVisibleNames("teaching.basera", true, "RA Base")
                  }}</b-button
                >
              </b-card-body>
            </template>
          </b-collapse>
        </b-card>
        <b-card no-body class="accordion-card mb-1">
          <b-card-header header-tag="header" class="p-0" role="tab">
            <b-button
              class="accordion-collapse"
              block
              v-b-toggle.accordion-4
              variant="secondary"
              size="sm"
              >Registro de Programas</b-button
            >
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-button
                class="accordion-nav-link"
                variant="light"
                size="sm"
                :to="{ name: 'CareerListView' }"
                >Crear Programas</b-button
              >
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/utils/utils";
import { APP_NAME } from "@/utils/globals";
import { mapGetters } from "vuex";
import * as names from "@/store/names";

export default {
  components: {
    HelpComponent: () => import("@/components/reusable/HelpComponent"),
    PrintFab: () => import("@/components/reusable/PrintFab"),
  },
  data() {
    return {
      sisera_logo: require("@/assets/new-page/KEY_LEARNING_LOGO.svg"),
      gera_logo: require("@/assets/logo_gera.png"),
      welcomeMessage: "",
      loading: false,
      loading_value: 0,
      loading_max: 0,
      app_name: APP_NAME,
      login_redirect: false,
      expanded: false,
      local_allows_crud: JSON.parse(localStorage.getItem("allows_crud")),
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      isLoged: "isLoged",
      institution: "getInstitution",
      institution_logo: "getInstitutionLogo",
      images: names.IMAGES,
      allows_crud: names.ALLOWS_CRUD,
      visibility_crud: names.VISIBILITY_CRUD,
    }),
    logo() {
      if (this.app_name == "GERA") return this.gera_logo;
      return this.sisera_logo;
    },
    userName() {
      let username = "Usuario";
      if (this.user) {
        username = this.user.email;
        if (this.user.first_name) username = this.user.first_name;
        if (this.user.last_name)
          username = this.user.first_name + " " + this.user.last_name;
      }
      return username;
    },
  },
  methods: {
    mutateAllowsCrud() {
      if (this.local_allows_crud != this.allows_crud) {
        this.$store.commit(names.MUTATE_ALLOWS_CRUD, this.local_allows_crud);
      }
    },
    generateReport() {
      if (!this.local_allows_crud || !this.visibility_crud) {
        if (this.institution) document.title = this.institution.name;
        else document.title = APP_NAME;
        window.print();
        document.title = APP_NAME;
      } else
        this.$swal({
          title: `Debe salir del modo edición antes de poder imprimir.`,
          type: "warning",
          showCancelButton: false,
        });
    },
  },
  created() {
    EventBus.$on("loadStarted", () => {
      this.loading = true;
    });
    EventBus.$on("loadFinished", () => {
      this.loading = false;
    });
    EventBus.$on("increment", (max) => {
      this.loading_max = max;
    });
    EventBus.$on("decrement", (value) => {
      this.loading_value = value;
    });
  },
};
</script>

<style scoped>
.button-content {
  color: #01adb7;
  opacity: 1;
}
.dropdown-adjustment >>> .dropdown-menu {
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}
.user-button:hover {
  color: white;
  opacity: 0.9;
}
.user-button {
  display: flex;
  background-color: #01adb7;
  width: 11em;
  height: 4em;
  color: white;
  border-radius: 55px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.user-button-left {
  text-align: left;
}
.user-button-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #006ec8;
  border-top-right-radius: 55px;
  border-bottom-right-radius: 55px;
}
.user-info-container {
  width: auto;
  height: 100%;
  font-size: 15px;
  margin-left: auto;
  margin-right: 3%;
  transition: 0.2s;
}
.navbar {
  background-color: white;
  box-shadow: 0 1.8px 3px -2px rgb(0, 0, 0);
  position: fixed;
  height: 4em;
}
.navbar-nav {
  float: left;
  white-space: nowrap !important;
}
.responsive-bar {
  display: none;
}
.current-view {
  color: #01adb7 !important;
}
.landing-nav {
  height: 80px;
  width: 100%;
  align-items: center;
  padding: 0 2px;
  flex-wrap: wrap;
  padding-bottom: 7%;
  margin-left: -18%;
  margin-right: 6%;
}
.navbar-nav >>> .nav-link {
  color: #a5a5a5 !important;
  max-height: 40px;
  cursor: pointer;
  margin-left: 2em;
}
.navbar-nav >>> .nav-link:hover {
  background: white;
  color: #01adb7;
  border-radius: 7px;
}
.items-dropdown >>> .dropdown-menu {
  width: 90%;
  left: 12%;
  margin-top: 0 !important;
}
.landing-nav >>> .nav-items {
  display: flex;
  flex-wrap: nowrap;
  font-size: 14pt !important;
  margin-right: 15vh;
}
.landing-nav .nav-items b-nav-item {
  margin: 0 5px;
}
.landing-nav .nav-items b-nav-item {
  color: #666;
  text-decoration: none;
  font-size: 14pt !important;
  font-weight: 500;
  padding: 8px 6px;
  border-radius: 5px;
  letter-spacing: 1px;
  transition: all 0.2s ease;
}
.landing-nav .nav-items b-nav-item.active,
.landing-nav .nav-items b-nav-item:hover {
  color: var(--header-color);
  background: #fff;
}
.landing-nav .menu-btn i {
  color: #666;
  font-size: 28px;
  cursor: pointer;
  display: none;
}
.nav-items >>> .nav-link {
  margin-left: 2em !important;
}
.brand-text {
  font-family: "Source Sans Pro", sans-serif;
  justify-content: start;
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
  margin-right: -15% !important;
  font-size: var(--secondary-font-size) - 2;
}
.dropdown-item {
  margin: 0px;
  padding: 0px;
  color: white;
  background-color: transparent;
}
.not-visible {
  display: none !important;
}
.logo {
  margin-left: 1em !important;
  max-width: 200px;
  max-height: 60px;
  min-height: 60px;
  min-width: 60px;
}
.searchInput {
  margin: 4px;
}
.loadingBar {
  position: fixed;
  top: 61px;
  left: 0;
  right: 0;
  width: 100%;
  margin-top: 30px;
}
.not-logged-in {
  position: fixed;
  top: 16px !important;
  left: 0;
  right: 0;
  width: 100%;
  margin-top: 0;
}
.loadingBar >>> .progress {
  z-index: 900 !important;
  border-radius: 0;
}
.user-name {
  font-size: 14pt;
  padding-right: 0.5em;
}
.profile-section {
  right: 0 !important;
  margin-left: 24%;
}
.profile-section:hover {
  cursor: pointer;
  background: var(--header-hover-color);
  border-radius: 6px;
}
.right-setting {
  margin-right: 0px !important;
}
.profile-section >>> .dropdown-menu {
  transform: translateY(-54%);
  right: 0;
  left: -25%;
}
.sign-in {
  height: 70px;
}

.mobile-nav {
  display: none;
}
.btn-collapse-mobile {
  background: transparent !important;
  border: none !important;
  color: #a5a5a5;
}
.card-body {
  padding: 0;
}
.nav-btn {
  z-index: 2000 !important;
  background: rgb(245, 245, 245);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.navigation-drop {
  color: #fff;
  background: #01adb7 !important;
}
.navigation-drop:hover,
.navigation-link:hover {
  background: var(--header-hover-color) !important;
  border-color: #01adb7;
}
.navigation-item {
  display: block;
  background: rgb(245, 245, 245);
  margin: 2px;
}
.navigation-item a {
  color: var(--secondary-font-color);
}
.navigation-item:hover {
  background: var(--header-hover-color);
}
.navigation-item:hover a {
  color: white;
}
.navigation-link {
  width: 98%;
  color: #fff;
  background: #01adb7 !important;
  height: min-content;
}
.shown {
  display: block !important;
}
.navbar-nav >>> .nav-link {
  margin-left: 0;
}
.resources-drop {
  flex-wrap: wrap;
  max-height: 32px !important;
  width: 20%;
  font-weight: bold;
  padding-top: 0.1em;
  color: #fff !important;
}
.resources-drop >>> .disabled {
  color: #fff !important;
}
.resources-drop:hover,
.resources-drop:hover >>> .disabled {
  background: rgb(225, 225, 225);
  cursor: pointer;
  max-height: 31px;
}
.resources-drop-item {
  width: 400px !important;
}
.resources-drop >>> .dropdown-item {
  min-width: 19em !important;
  background: var(--kl-menu-color);
  color: #fff;
}
.resources-drop >>> .dropdown-item:hover {
  background: var(--kl-menu-hover-color);
}
.resources-drop >>> .dropdown-menu {
  padding: 0 !important;
}
ul >>> .dropdown-menu li {
  margin-left: 0 !important;
}
.first-drop {
  background-color: var(--kl-menu-color) !important;
  color: #fff !important;
  transition: all 0.2s;
}
.first-drop:hover >>> .disabled {
  background-color: var(--kl-menu-hover-color) !important;
  transition: all 0.2s;
}
.second-drop {
  background-color: var(--kl-menu-color) !important;
}
.second-drop:hover >>> .disabled {
  background-color: var(--kl-menu-hover-color) !important;
  transition: all 0.2s;
}
.third-drop {
  background-color: var(--kl-menu-color) !important;
}
.third-drop:hover >>> .disabled {
  background-color: var(--kl-menu-hover-color) !important;
  transition: all 0.2s;
}
.fourth-drop {
  background-color: var(--kl-fourth-color) !important;
}
.fourth-drop:hover >>> .disabled {
  background-color: var(--kl-menu-hover-color) !important;
  transition: all 0.2s;
}
.brand-section {
  margin-left: -1.5%;
  margin-right: 1% !important;
}
.brand-text {
  margin-left: -1%;
}
.b-nav-section {
  width: 100%;
  max-height: 35px !important;
  font-size: 10pt;
  background: var(--kl-menu-color);
  position: fixed;
  padding-left: 4%;
  top: 4.4em;
  z-index: 1000 !important;
  box-shadow: 0 0.5px 4px -1px rgb(145, 145, 145);
}
.responsive-nav-btn {
  display: none;
}
.accordion-nav {
  display: none;
}
.accordion-card {
  background: #6cb2c6 !important;
}
.btn-programs {
  font-size: var(--secondary-font-size);
  font-weight: bold;
  /* transform: translateY(8%); */
  color: #fff !important;
  max-height: 32px;
  width: 20%;
  padding: 0 1.05em !important;
  padding-top: 0.6em !important;
  border-radius: 0;
}
.btn-programs:focus {
  outline: none;
  box-shadow: none;
  background-color: var(--kl-fourth-hover-color) !important;
  transition: all 0.2s;
  border-radius: 0;
}
.btn-programs:hover {
  background-color: var(--kl-fourth-hover-color) !important;
  transition: all 0.2s;
}
.btn-curricular-products {
  font-size: var(--secondary-font-size);
  background-color: #ffb700 !important;
  font-weight: bold;
  color: #fff !important;
}
.btn-curricular-products:focus {
  outline: none;
  box-shadow: none;
  background-color: #ffb700 !important;
  transition: all 0.2s;
  border-radius: 0;
}
.btn-curricular-products:hover {
  background-color: #ffb700 !important;
  transition: all 0.2s;
}
.nav li:hover >>> .dropdown-menu {
  display: block;
  margin-top: 0;
}
.print-button {
  color: white !important;
  background: #50a1ba;
  margin-left: 0.5em;
  width: 28px;
  height: 28px;
}
.print-button:hover {
  background: #62abc1;
}
.print-button > .b-icon {
  transform: translateX(-20%);
}
.responsive-print-fab {
  display: none;
}

/** ----------- Media Queries ----------- **/
@media (max-width: 1200px) {
  .user-info-container {
    margin-right: 1%;
  }
  .landing-nav {
    margin-right: 1%;
    margin-left: 10%;
  }
  .profile-section {
    margin-left: 1%;
  }
  .print-button {
    margin-left: 0;
  }
}

@media only screen and (min-width: 897px) and (max-width: 1400px) {
  .brand-text {
    font-size: var(--secondary-font-size) - 2;
  }
  .searchForm {
    display: none;
  }
  /* .logo {
    width: 8em;
    height: 8em;
  } */
  .right-setting {
    margin-right: 1% !important;
  }
  .user-name {
    font-size: 13pt;
  }
  .profile-section {
    margin-left: 1%;
    margin-bottom: 0;
  }
  .landing-nav >>> .nav-items {
    font-size: 13pt !important;
  }
  .landing-nav {
    margin-left: 10%;
    margin-right: -8%;
    padding-bottom: 10% !important;
  }
  .brand-section {
    transform: translateX(-1em);
    margin-left: 1%;
    margin-right: -20% !important;
  }
}

@media only screen and (min-width: 701px) and (max-width: 1050px) {
  .user-button {
    width: 4em;
  }
  .user-button-left {
    display: none;
  }
  .user-button-right {
    border-radius: 55px;
  }
  .brand-text {
    display: none;
  }
  .right-setting {
    margin-right: 1% !important;
  }
  .searchForm {
    display: none;
  }
  /* .logo {
    width: 8em;
    height: 8em;
  } */
  .user-name {
    display: none;
  }
  .user-icon {
    margin-top: 0.2em !important;
  }
  .profile-section {
    margin-left: 1%;
    margin-right: 1%;
  }
  .dropdown-adjustment >>> .dropdown-menu {
    right: 0 !important;
    left: -500%;
  }
  .profile-section >>> .dropdown-menu {
    right: 0;
    left: -22% !important;
  }
  .landing-nav {
    margin-left: 0;
    margin-right: -10%;
  }
  .landing-nav >>> .nav-link {
    font-size: var(--secondary-font-size);
  }
  .landing-nav >>> .nav-items {
    margin-right: 1vh;
    padding-bottom: 10%;
    display: none;
  }
  .mobile-nav {
    display: block;
    background-color: #01adb7;
  }
  .mobile-nav >>> .nav-btn {
    position: fixed;
    width: 100%;
    margin-top: -0.2rem;
  }
  .resources-b-nav {
    display: none;
  }
  .responsive-nav-btn {
    display: block;
    float: right;
    width: 30px;
    height: 29px;
    margin-top: 0.4em;
    margin-bottom: 0.1em;
    margin-right: 0.1em;
    background: #50a1ba !important;
    border: var(--kl-menu-hover-color) !important;
    color: var(--secondary-font-color) !important;
  }
  .responsive-nav-btn:focus {
    border: none;
  }
  .responsive-nav-btn:hover {
    background: #62abc1 !important;
  }
  .responsive-nav-icon {
    /* transform: translate(-15%, 10%); */
    text-align: center;
    vertical-align: middle;
  }
  .responsive-print-fab {
    display: block;
  }
  .nav-expanded {
    max-height: 800px !important;
    transition: 0.2s !important;
  }
  .accordion-nav {
    display: block;
    margin-inline: 10%;
    margin-top: -70% !important;
    transition: 0.2s;
  }
  .accordion-expanded {
    margin-top: 1% !important;
    /* transition: .4s ease-in-out; */
  }
  .b-nav-section {
    transition: 0.1s !important;
  }
  .accordion-collapse {
    width: 100% !important;
    background: #489eb8 !important;
    border: #489eb8 !important;
    color: var(--secondary-font-color) !important;
  }
  .accordion-collapse:hover {
    background: #6cb2c6 !important;
    color: white;
  }
  .accordion-collapse:focus {
    background: #6cb2c6 !important;
    border: #6cb2c6 !important;
    box-shadow: 0 0 0 0.2rem rgb(108 178 198 / 50%) !important;
  }
  .accordion-nav-link {
    width: 100%;
    margin: 0.1em 0;
  }
}
@media only screen and (max-width: 700px) {
  .user-button {
    width: 4em;
    margin-right: 10px;
  }
  .user-button-left {
    display: none;
  }
  .user-button-right {
    border-radius: 55px;
  }
  .brand-text {
    display: none;
  }
  .searchForm {
    display: none;
  }
  /* .logo {
    width: 8em;
    height: 8em;
  } */
  .user-name {
    display: none;
  }
  .nav-items {
    font-size: var(--thirth-font-size) !important;
  }
  .landing-nav {
    margin-left: 0;
    margin-right: -10%;
  }
  .landing-nav >>> .nav-link {
    font-size: var(--secondary-font-size);
  }
  .landing-nav >>> .nav-items {
    margin-right: 1vh;
    padding-bottom: 10%;
    display: none;
  }
  .mobile-nav {
    display: block;
    background-color: #01adb7;
  }
  .mobile-nav >>> .nav-btn {
    position: fixed;
    width: 100%;
    margin-top: -0.1rem;
  }
  /* .brand-section >>> .logo {
    min-width: 45px !important;
  } */
  .resources-b-nav {
    display: none;
  }
  .responsive-nav-btn {
    display: block;
    float: right;
    width: 25px;
    height: 25px;
    margin-top: 0.4em;
    margin-bottom: 0.1em;
    margin-right: 0.4em;
    background: #50a1ba !important;
    border: var(--kl-menu-hover-color) !important;
    color: var(--secondary-font-color) !important;
  }
  .responsive-nav-btn:hover {
    background: #62abc1 !important;
  }
  .responsive-nav-icon {
    transform: translate(-25%, -10%);
  }
  .nav-expanded {
    max-height: fit-content !important;
    transition: 0.2s linear;
    overflow: auto;
  }
  .accordion-nav {
    display: block;
    margin-right: 14%;
    margin-left: 6%;
    margin-top: -70% !important;
    transition: 0.3s;
  }
  .accordion-expanded {
    margin-top: 1% !important;
  }
  .accordion-collapse {
    width: 100% !important;
    background: var(--header-color) !important;
    border: var(--header-color) !important;
  }
  .accordion-collapse:hover {
    background: #14abd8 !important;
    color: white;
  }
  .accordion-collapse:focus {
    background: #14abd8 !important;
    border: #14abd8 !important;
    color: white;
    box-shadow: 0 0 0 0.2rem rgb(20 171 216 / 50%);
  }
  .accordion-nav-link {
    width: 100%;
    margin: 0.1em 0;
  }
  .dropdown-adjustment >>> .dropdown-menu {
    right: 0 !important;
    left: -500%;
  }
}
@media only screen and (max-width: 620px) {
  .user-button {
    width: 4em;
    margin-right: 10px;
  }
  .user-button-left {
    display: none;
  }
  .user-button-right {
    border-radius: 55px;
  }
  .user-name {
    display: none;
  }
  .nav-items {
    font-size: var(--thirth-font-size) !important;
  }
  .landing-nav >>> .nav-link {
    font-size: var(--secondary-font-size);
  }
  .profile-section {
    margin-right: 10%;
  }
  .brand-section {
    margin-right: 1% !important;
  }
  .mobile-nav >>> .nav-btn {
    position: fixed;
    width: 100%;
    margin-top: 0rem;
  }
  .navbar-expand .navbar-nav >>> .nav-link {
    padding: 0 !important;
  }
  .navbar-expand .navbar-nav >>> .dropdown-menu {
    font-size: 11pt;
    /* left: 1%; */
  }
  .dropdown-adjustment >>> .dropdown-menu {
    right: 0 !important;
    left: -500%;
  }
}
@media (max-width: 590px) {
  .user-button {
    width: 4em;
    margin-right: 10px;
  }
  .user-button-left {
    display: none;
  }
  .user-button-right {
    border-radius: 55px;
  }
  .user-name {
    display: none;
  }
  .nav-items {
    font-size: var(--thirth-font-size) !important;
  }
  .profile-section {
    display: none;
  }
  .brand-section {
    margin-right: 1% !important;
  }
  .landing-nav {
    margin-left: 0;
    margin-right: -10%;
  }
  .landing-nav >>> .nav-link {
    font-size: var(--secondary-font-size);
  }
  .landing-nav >>> .nav-items {
    margin-right: 1vh;
    padding-bottom: 10%;
    display: none;
  }
  li {
    margin-left: 0;
  }
  .navbar-expand .navbar-nav >>> .nav-link {
    padding: 0 !important;
    padding-top: 0.5em !important;
  }
  .dropdown-adjustment >>> .dropdown-menu {
    right: 0 !important;
    left: -700%;
  }
  .mobile-nav {
    display: block;
    background-color: #01adb7;
  }
  .mobile-nav >>> .nav-btn {
    position: fixed;
    width: 100%;
    margin-top: 0.5rem;
  }
}
</style>