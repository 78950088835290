<template>
  <div class="test-header mb-3">
    <div class="header-div px-4">
      <strong class="w-25">Título:</strong>
      <strong class="w-75">{{ Instrument ? Instrument.title : "-" }}</strong>
    </div>
    <div class="header-div px-4" v-if="!evaluation_not_started_yet">
      <strong class="w-25">Descripción:</strong>
      <div class="w-75" v-html="Instrument.description"></div>
    </div>
    <div class="header-div px-4">
      <strong class="w-25">Sub-tipo:</strong>
      <div class="w-75">{{ subType }}</div>
    </div>
    <div class="header-div px-4">
      <strong class="w-25">Evidencias de desempeño:</strong>
      <div class="w-75">
        <template v-if="instrumentProcedureEvidences.length > 0">
          <div
            v-for="procedure_evidence in instrumentProcedureEvidences"
            :key="procedure_evidence.id"
          >
            - {{ procedure_evidence.name }}
          </div>
        </template>
        <template v-else>
          <div>N/A</div>
        </template>
      </div>
    </div>
    <div class="header-div px-4 py-1">
      <strong class="w-25">Fecha de Inicio:</strong>
      <div class="question-test-date w-25">
        <b-icon icon="calendar-plus" class="mr-2"></b-icon
        >{{ Instrument.start_date | FormatToDateTime }}
      </div>
    </div>
    <div class="header-div px-4 py-1">
      <strong class="w-25">Fecha de Entrega:</strong>
      <div class="question-test-date w-25">
        <b-icon icon="calendar-check" class="mr-2"></b-icon
        >{{ Instrument.due_date | FormatToDateTime }}
      </div>
    </div>
    <div class="header-div px-4 py-1">
      <strong class="w-25">Fecha de Publicación:</strong>
      <div class="question-test-date w-25">
        <b-icon icon="calendar-check" class="mr-2"></b-icon
        >{{ Instrument.publish_date | FormatToDate }}
      </div>
      <!-- <div class="w-50">
        <div class="pl-4 question-test-publish">
          <b-form-checkbox
            switch
            v-model="Instrument.publish"
            size="sm"
            :disabled="!allows_crud"
            class="publish-switch"
            @change="patchEvaluation()"
            ><strong>Publicar</strong></b-form-checkbox
          >
        </div>
      </div> -->
    </div>
    <div class="header-div px-4 d-flex">
      <strong class="w-25"
        >Puntaje<template
          v-if="
            is_student &&
            is_finished &&
            instrumentScore &&
            !is_running &&
            instrumentScore.score_and_feedback &&
            instrumentScore.score_and_feedback.publish
          "
        >
          Obtenido</template
        >:</strong
      >
      <template v-if="!allows_crud">
        <div
          class="w-25"
          v-if="
            is_student &&
            is_finished &&
            instrumentScore &&
            !is_running &&
            instrumentScore.score_and_feedback &&
            instrumentScore.score_and_feedback.publish
          "
        >
          <strong> {{ instrumentScore.automated_score | Round }} </strong>/
          <strong>
            {{ Instrument.score | Round }}
          </strong>
        </div>
        <div class="w-25" v-else>
          <strong>
            {{ Instrument.score | Round }}
          </strong>
        </div>
      </template>
      <template v-else>
        <div class="w-75">
          <strong>
            {{ Instrument.score | Round }}
          </strong>
          <span
            v-if="Instrument.score != questions_current_score && TestForm"
            class="ml-3 text-danger"
          >
            <b-icon class="mr-2" icon="exclamation-circle"></b-icon
            ><strong
              >El puntaje del instrumento no coincide con el puntaje asignado a
              las preguntas.</strong
            >
          </span>
        </div>
      </template>
    </div>
    <div
      class="header-div px-4 d-flex"
      v-if="
        is_running ||
        (instrumentScore &&
          instrumentScore.score_and_feedback &&
          instrumentScore.score_and_feedback.publish) ||
        is_professor ||
        (Instrument && Instrument.show_attached_before_start_date)
      "
    >
      <strong class="w-25">Archivos adjuntos: </strong>
      <div class="w-75">
        <FileContainer
          :Files="Instrument.attachments"
          :allows_crud="allows_crud"
          @deleteFile="slotDeleteFile"
        ></FileContainer>
      </div>
    </div>
    <div
      class="header-div px-4 d-flex"
      v-if="
        is_student &&
        is_finished &&
        instrumentScore &&
        !is_running &&
        instrumentScore.score_and_feedback &&
        instrumentScore.score_and_feedback.publish
      "
    >
      <strong class="w-25">Nota:</strong>
      <template>
        <div class="w-25">
          <strong v-if="instrumentScore">
            {{ instrumentScore.automated_grade | Round }}
          </strong>
        </div>
      </template>
    </div>
    <div
      class="header-div px-4 d-flex"
      v-if="
        is_student &&
        is_finished &&
        instrumentScore &&
        !is_running &&
        instrumentScore.score_and_feedback &&
        instrumentScore.score_and_feedback.publish &&
        instrumentStat
      "
    >
      <strong class="w-25">Nota promedio:</strong>
      <template>
        <div class="w-25">
          <strong v-if="instrumentScore">
            {{ instrumentStat.avg_grade | Round }}
          </strong>
        </div>
      </template>
      <strong class="w-25">Desviación Estándar:</strong>
      <template>
        <div class="w-25">
          <strong v-if="instrumentScore">
            {{ instrumentStat.stdev_grade | Round }}
          </strong>
        </div>
      </template>
    </div>
    <div
      class="header-div px-4 d-flex"
      v-if="
        is_student &&
        is_finished &&
        instrumentScore &&
        !is_running &&
        instrumentScore.score_and_feedback &&
        instrumentScore.score_and_feedback.publish &&
        instrumentStat
      "
    >
      <strong class="w-25">Nota mínima:</strong>
      <template>
        <div class="w-25">
          <strong v-if="instrumentScore">
            {{ instrumentStat.min_grade | Round }}
          </strong>
        </div>
      </template>
      <strong class="w-25">Nota máxima:</strong>
      <template>
        <div class="w-25">
          <strong v-if="instrumentScore">
            {{ instrumentStat.max_grade | Round }}
          </strong>
        </div>
      </template>
    </div>
    <div
      class="header-div px-4 d-flex"
      v-if="
        is_student &&
        is_finished &&
        !is_running &&
        instrumentScore &&
        instrumentScore.score_and_feedback &&
        instrumentScore.score_and_feedback.publish &&
        instrumentScore.score_and_feedback.feedback.trim() != ''
      "
    >
      <strong class="w-25">Retroalimentación:</strong>
      <template>
        <div class="w-75">
          <strong>
            {{ instrumentScore.score_and_feedback.feedback }}
          </strong>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  components: {
    FileContainer: () => import("@/components/reusable/FileContainer"),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: false,
    },
    Instrument: {
      type: Object,
      required: true,
    },
    TestForm: {
      type: Boolean,
      required: true,
    },
    is_professor: {
      type: Boolean,
    },
    is_student: {
      type: Boolean,
    },
    is_running: {
      type: Boolean,
      default: true,
    },
    is_finished: {
      type: Boolean,
    },
    instrumentScore: {
      type: Object,
      default: null,
    },
    instrumentStat: {
      type: Object,
      default: null,
    },
    evaluation_not_started_yet: {
      type: Boolean,
    },
    questions_current_score: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      InstrumentTypes: names.INSTRUMENT_SUB_TYPES,
      procedureEvidences: names.PROCEDURE_EVIDENCES,
    }),
    subType() {
      if (this.Instrument.sub_type == null) return "N/A";
      const subType = this.InstrumentTypes.find(
        (x) => x.id == this.Instrument.sub_type
      );
      return subType ? subType.value : "N/A";
    },
    instrumentProcedureEvidences() {
      if (this.Instrument.procedure_evidences == []) return "N/A";
      return this.procedureEvidences.filter((x) =>
        this.Instrument.procedure_evidences.includes(x.id)
      );
    },
  },
  methods: {
    patchEvaluation() {
      if (this.TestForm) {
        const payload = {
          question_test_id: this.Instrument.id,
          item: {
            publish: this.Instrument.publish,
          },
        };
        this.$store.dispatch(names.PATCH_QUESTION_TEST, payload);
      } else {
        const payload = {
          observation_instrument_id: this.Instrument.id,
          item: {
            publish: this.Instrument.publish,
          },
        };
        this.$store.dispatch(names.PATCH_OBSERVATION_INSTRUMENT, payload);
      }
    },
    slotDeleteFile(file_id) {
      let index = this.Instrument.attachments.indexOf(file_id);
      this.Instrument.attachments.splice(index, 1);
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_INSTRUMENT_SUB_TYPES);
    this.$store.dispatch(names.FETCH_PROCEDURE_EVIDENCES);
  },
};
</script>

<style scoped>
.test-header {
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  background-color: var(--primary-color);
  box-shadow: 0px 1px 5px 0px #b8b8b8;
  overflow-x: auto;
}
.header-div {
  border-bottom: 1px solid var(--secondary-color);
  margin: 1px;
  padding: 2px;
  text-align: left;
  display: flex;
}
.header-div >>> p {
  margin-bottom: 0 !important;
}
.header-div:last-of-type {
  border-bottom: none;
}
.question-test-date,
.question-test-publish {
  background-color: rgb(228, 228, 228);
  padding: 2px 4px;
  border-radius: 4px;
}
.question-test-publish {
  width: fit-content;
  display: inline-block;
  transform: translateX(10%);
}
.publish-switch {
  position: relative;
  right: 10%;
}
</style>