<template>
  <div>
    <h4 class="mt-4">
      {{ $getVisibleNames("manual.matriz_1", false, "Matriz 1") }}
    </h4>

    <!-- sin ciclos -->

    <!-- <template
        v-if="
          cycles_competences_base_ra &&
          cycles_competences_base_ra.cycles_amount == 0
        "
      >
        <div>
          <b-table-simple bordered>
            <b-thead>
              <b-th class="bg-primary-color">{{
                $getVisibleNames("mesh.competence", false, "Competencia")
              }}</b-th>
              <b-th class="bg-primary-color">{{
                $getVisibleNames("teaching.basera", true, "RA Base")
              }}</b-th>
              <b-th class="bg-primary-color">Asignaturas</b-th>
            </b-thead>
            <b-tbody>
              <template
                v-for="(competence, index) in cycles_competences_base_ra.list"
              >
                <template v-if="competence.egress_profile_base_ras.length == 0">
                  <b-tr :key="`key-${competence.id}-${index}`">
                    <b-td class="align-middle text-justify w-25">
                      <b-badge :id="`competence-${competence.id}-${index}`">
                        {{ competence.order }}
                      </b-badge>
                      {{ competence.competence__full_sentence }}
                      <b-tooltip
                        :target="`competence-${competence.id}-${index}`"
                        variant="secondary"
                        noninteractive
                        placement="right"
                      >
                        {{ competence.competence__full_sentence }}
                      </b-tooltip>
                    </b-td>
                    <b-td class="align-middle text-center">-</b-td>
                    <b-td class="align-middle text-center">-</b-td>
                  </b-tr>
                </template>
                <template v-else>
                  <template
                    v-for="(
                      base_ra, index2
                    ) in competence.egress_profile_base_ras"
                  >
                    <b-tr
                      :key="`key-${competence.id}-${index}-${base_ra.id}-${index2}`"
                    >
                      <b-td
                        v-if="index2 == 0"
                        :rowspan="competence.egress_profile_base_ras.length"
                        class="align-middle text-justify w-25"
                      >
                        <b-badge
                          :id="`competence-${competence.id}-${index}-${base_ra.id}-${index2}`"
                        >
                          {{ competence.order }}
                        </b-badge>
                        {{ competence.competence__full_sentence }}
                        <b-tooltip
                          :target="`competence-${competence.id}-${index}-${base_ra.id}-${index2}`"
                          variant="secondary"
                          noninteractive
                          placement="right"
                        >
                          {{ competence.competence__full_sentence }}
                        </b-tooltip>
                      </b-td>
                      <b-td class="align-middle text-left w-75">
                        <div class="d-flex mb-2" :key="base_ra.id">
                          <div style="margin-right: auto; margin-left: 0">
                            <b-badge
                              :id="`base-ra-${base_ra.id}-${competence.id}`"
                            >
                              {{ competence.order }}.{{ base_ra.order }}
                            </b-badge>
                            {{ base_ra.full_sentence }}
                          </div>
                          <b-tooltip
                            :target="`base-ra-${base_ra.id}-${competence.id}`"
                            :key="'base-ra-tooltip-' + base_ra.id"
                            variant="secondary"
                            noninteractive
                            placement="right"
                          >
                            {{ base_ra.full_sentence }}
                          </b-tooltip>
                        </div>
                      </b-td>
                      <b-td class="align-middle text-center w-0">
                        <template
                          v-for="(
                            study_unit__matter__name, index
                          ) in base_ra.matters"
                        >
                          <b-badge
                            class="ml-1"
                            :key="`${base_ra.id}-${competence.id}-${index}`"
                            :id="`matter-${base_ra.id}-${competence.id}-${index}`"
                          >
                            {{ study_unit__matter__name }}
                          </b-badge>
                        </template>
                      </b-td>
                    </b-tr>
                  </template>
                </template>
              </template>
            </b-tbody>
          </b-table-simple>
        </div>
      </template> -->

    <!-- Con ciclos -->

    <!-- <template v-else-if="cycles_competences_base_ra">
        <template v-for="cycle in cycles_competences_base_ra.list">
          <b-table-simple :key="'b-table:' + cycle.id" bordered>
            <b-thead>
              <b-tr>
                <b-th class="bg-primary-color" colspan="3">{{
                  cycle.cycle__name
                }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="bg-secondary-color">{{
                  $getVisibleNames("mesh.competence", false, "Competencia")
                }}</b-th>
                <b-th class="bg-secondary-color">{{
                  $getVisibleNames("teaching.basera", false, "RA Base")
                }}</b-th>
                <b-th class="bg-secondary-color"> Asignaturas </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="competence in cycle.egress_profile_competences">
                <template v-if="competence.egress_profile_base_ras.length == 0">
                  <b-tr :key="`key-${competence.id}`">
                    <b-td class="align-middle text-justify w-25">
                      <b-badge :id="`competence-${competence.id}`">
                        {{ competence.order }}.{{ cycle.order }}
                      </b-badge>
                      {{ competence.competence__full_sentence }}
                    </b-td>
                    <b-td class="align-middle text-center">-</b-td>
                    <b-td class="align-middle text-center">-</b-td>
                  </b-tr>
                </template>
                <template v-else>
                  <template
                    v-for="(ra, index) in competence.egress_profile_base_ras"
                  >
                    <b-tr :key="`key-${competence.id}-${ra.id}-${index}`">
                      <b-td
                        class="align-middle text-justify w-25"
                        v-if="index == 0"
                        :rowspan="competence.egress_profile_base_ras.length"
                      >
                        <b-badge :id="`competence-${competence.id}`">
                          {{ competence.order }}.{{ cycle.order }}
                        </b-badge>
                        {{ competence.competence__full_sentence }}
                      </b-td>
                      <b-td class="align-middle text-justify w-75">
                        <b-badge :id="`base-ra-${ra.id}`">
                          {{ competence.order }}.{{ cycle.order }}.{{
                            ra.order
                          }}
                        </b-badge>
                        {{ ra.full_sentence }}
                        <b-tooltip
                          :target="`base-ra-${ra.id}`"
                          :key="'base-ra-tooltip-' + ra.id"
                          variant="secondary"
                          noninteractive
                          placement="right"
                        >
                          {{ ra.full_sentence }}
                        </b-tooltip></b-td
                      >
                      <b-td class="align-middle text-center w-0">
                        <template
                          v-for="(
                            study_unit__matter__name, index
                          ) in ra.matters"
                        >
                          <b-badge
                            :key="`${ra.id}-${competence.id}-${index}`"
                            :id="`matter-${ra.id}-${competence.id}-${index}`"
                          >
                            {{ study_unit__matter__name }}
                          </b-badge>
                        </template>
                      </b-td>
                    </b-tr>
                  </template>
                </template>
                <b-tooltip
                  :key="`key-tooltip-${competence.id}`"
                  :target="`competence-${competence.id}`"
                  variant="secondary"
                  noninteractive
                  placement="right"
                >
                  {{ competence.competence__full_sentence }}
                </b-tooltip>
              </template>
            </b-tbody>
          </b-table-simple>
        </template>
      </template> -->
    <!-- Antigua vista -->
    <template
      v-if="
        cycles_competences_base_ra &&
        cycles_competences_base_ra.cycles_amount == 0
      "
    >
      <div>
        <b-table-simple bordered>
          <b-tr>
            <b-th class="bg-primary-color">{{
              $getVisibleNames("mesh.competence", false, "Competencia")
            }}</b-th>
            <b-th class="bg-primary-color">
              <div class="d-flex">
                <span style="margin-left: auto; margin-rigth: auto">
                  {{ $getVisibleNames("teaching.basera", true, "RA Base") }}
                </span>
                <span class="mr-1" style="margin-left: auto; margin-rigth: 0">{{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    true,
                    "Asignaturas"
                  ).toUpperCase()
                }}</span>
              </div>
            </b-th>
          </b-tr>
          <template v-for="competence in cycles_competences_base_ra.list">
            <b-tr :key="competence.id">
              <b-td class="align-middle text-justify w-25">
                <b-badge :id="`competence-${competence.id}`">
                  {{ competence.order }}
                </b-badge>
                {{ competence.competence__full_sentence }}
                <b-tooltip
                  :target="`competence-${competence.id}`"
                  variant="secondary"
                  noninteractive
                  placement="right"
                >
                  {{ competence.competence__full_sentence }}
                </b-tooltip>
              </b-td>
              <b-td class="align-middle text-left w-75">
                <template v-if="competence.egress_profile_base_ras.length > 0">
                  <template
                    v-for="base_ra in competence.egress_profile_base_ras"
                  >
                    <div class="d-flex mb-2" :key="base_ra.id">
                      <div style="margin-right: auto; margin-left: 0">
                        <b-badge :id="`base-ra-${base_ra.id}-${competence.id}`">
                          {{ competence.order }}.{{ base_ra.order }}
                        </b-badge>
                        {{ base_ra.full_sentence }}
                      </div>
                      <div style="margin-right: 0; margin-left: auto">
                        <template
                          v-for="(
                            study_unit__matter__name, index
                          ) in base_ra.matters"
                        >
                          <b-badge
                            :key="`${base_ra.id}-${competence.id}-${index}`"
                            :id="`matter-${base_ra.id}-${competence.id}-${index}`"
                          >
                            {{ study_unit__matter__name }}
                          </b-badge>
                        </template>
                      </div>
                      <b-tooltip
                        :target="`base-ra-${base_ra.id}-${competence.id}`"
                        :key="'base-ra-tooltip-' + base_ra.id"
                        variant="secondary"
                        noninteractive
                        placement="right"
                      >
                        {{ base_ra.full_sentence }}
                      </b-tooltip>
                    </div>
                  </template>
                </template>
              </b-td>
            </b-tr>
          </template>
        </b-table-simple>
      </div>
    </template>
    <b-table-simple bordered v-if="cycles_competences_base_ra">
      <template v-for="cycle in cycles_competences_base_ra.list">
        <template
          v-for="(competence, index2) in cycle.egress_profile_competences"
        >
          <b-tr :key="cycle.id + '-' + competence.id">
            <b-th class="bg-primary-color" colspan="2" v-if="index2 == 0">{{
              cycle.cycle__name
            }}</b-th>
          </b-tr>
          <b-tr :key="'tr-drop-' + cycle.id + '-' + competence.id">
            <b-th class="bg-secondary-color" v-if="index2 == 0">{{
              $getVisibleNames("mesh.competence", false, "Competencia")
            }}</b-th>
            <b-th class="bg-secondary-color" v-if="index2 == 0">
              <div class="d-flex">
                <span style="margin-left: auto; margin-rigth: auto">
                  {{ $getVisibleNames("teaching.basera", true, "RA Base") }}
                </span>
                <span class="mr-1" style="margin-left: auto; margin-rigth: 0">{{
                  $getVisibleNames(
                    "mesh.egressprofilematter",
                    true,
                    "Asignaturas"
                  ).toUpperCase()
                }}</span>
              </div></b-th
            >
          </b-tr>
          <b-tr :key="'competence-cell-' + competence.id + cycle.id">
            <template>
              <b-td class="align-middle text-justify w-25">
                <b-badge :id="`competence-${competence.id}`">
                  {{ competence.order }}.{{ cycle.order }}
                </b-badge>
                {{ competence.competence__full_sentence }}
              </b-td>
              <template v-if="competence.egress_profile_base_ras.length > 0">
                <b-td class="align-middle text-left w-75">
                  <template v-for="ra in competence.egress_profile_base_ras">
                    <div class="d-flex mb-2" :key="ra.id">
                      <div style="margin-right: auto; margin-left: 0">
                        <b-badge :id="`base-ra-${ra.id}`">
                          {{ competence.order }}.{{ cycle.order }}.{{
                            ra.order
                          }}
                        </b-badge>
                        {{ ra.full_sentence }}
                      </div>
                      <div style="margin-right: 0; margin-left: auto">
                        <template
                          v-for="(
                            study_unit__matter__name, index
                          ) in ra.matters"
                        >
                          <b-badge
                            :key="`${ra.id}-${competence.id}-${index}`"
                            :id="`matter-${ra.id}-${competence.id}-${index}`"
                          >
                            {{ study_unit__matter__name }}
                          </b-badge>
                        </template>
                      </div>
                    </div>
                    <b-tooltip
                      :target="`base-ra-${ra.id}`"
                      :key="'base-ra-tooltip-' + ra.id"
                      variant="secondary"
                      noninteractive
                      placement="right"
                    >
                      {{ ra.full_sentence }}
                    </b-tooltip>
                  </template>
                </b-td>
              </template>
              <template v-else>
                <b-td></b-td>
              </template>
            </template>

            <b-tooltip
              :target="`competence-${competence.id}`"
              variant="secondary"
              noninteractive
              placement="right"
            >
              {{ competence.competence__full_sentence }}
            </b-tooltip>
          </b-tr>
        </template>
      </template>
    </b-table-simple>
    <div class="mt-2 mb-5">
      <EgressProfileCompetenceMention
        v-if="
          egress_profile_id &&
          profile_competences_mention.filter(
            (x) => x.egress_profile == egress_profile_id
          ).length > 0
        "
        :allows_crud="false"
        :egress_profile_id="egress_profile_id"
      ></EgressProfileCompetenceMention>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
export default {
  name: "Matrix1PerCycleView",
  components: {
    EgressProfileCompetenceMention: () =>
      import("@/components/mesh/EgressProfile/EgressProfileCompetenceMention"),
  },
  data() {
    return {
      egress_profile_id: Number(this.$route.params.egress_profile_id),
      cycles_competences_base_ra: null,
    };
  },
  computed: {
    ...mapGetters({
      careers: names.CAREERS,
      profile_cycles: names.PROFILE_CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
      profile_competences_mention: names.PROFILE_COMPETENCES_MENTION,
    }),
    egressProfile() {
      if (!this.egress_profile_id) return [];
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    egressProfileCycleId() {
      if (!this.egress_profile_id) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egress_profile_id) list.push(x.cycle);
      });
      return list;
    },
    competencesList() {
      if (!this.cycles_competences_base_ra) return [];
      let list = [];
      this.cycles_competences_base_ra.list.forEach((cycle) => {
        cycle.egress_profile_competences.forEach((competence) => {
          list.push(competence);
        });
      });
      return list;
    },
  },
  methods: {
    fetchCycleCompetencesBaseRA() {
      if (this.egress_profile_id) {
        this.$restful
          .Get(
            `/teaching/cycles_competences_base_ra/?egress_profile=${this.egress_profile_id}`
          )
          .then((response) => {
            if (response) {
              if (response.cycles_amount > 0) {
                response.list.forEach((cycle) => {
                  if (cycle) {
                    cycle.egress_profile_competences.sort(function (a, b) {
                      if (a.order > b.order) return 1;
                      if (a.order < b.order) return -1;
                      return 0;
                    });
                    cycle.egress_profile_competences.forEach((competence) => {
                      competence["competence_cycle"] = cycle.cycle;
                    });
                  }
                });
              }
              this.cycles_competences_base_ra = response;
            }
          });
      }
    },
  },
  mounted() {
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES_MENTION, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_COMPETENCES, this.egress_profile_id);
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.fetchCycleCompetencesBaseRA();
  },
};
</script>
<style scoped>
.bg-primary-color {
  background-color: var(--primary-color) !important;
}
.bg-secondary-color {
  background-color: var(--primary-hover-color) !important;
}
@media print {
  .badge {
    border: none;
  }
}
</style>