<template>
  <div>
    <div v-if="!matrix2_competences" class="edit-section mb-3 mt-2"></div>
    <b-breadcrumb
      v-if="!matrix2_competences"
      class="breadcrumb pb-2 pt-1 noprint"
      :items="breadcrumb_items"
    ></b-breadcrumb>
    <div style="display: flex">
      <div
        class="col"
        style="text-align: left"
        v-if="allows_cruds && user && user.is_superuser"
      >
        <button
          v-can="'mesh.add_competence'"
          class="btn btn-secondary btn-sm"
          v-b-modal.new-competence-modal
        >
          Agregar
          {{ $getVisibleNames("mesh.competence", false, "Competencia") }}
          <!-- Competencia -->
        </button>
      </div>
    </div>
    <h3 class="mt-3" v-if="!matrix2_competences">
      {{ $getVisibleNames("mesh.competence", true, "Competencias") }}
      <!-- Competencias -->
    </h3>
    <div class="table-container">
      <GenericBTable
        v-if="!isBusy"
        :key="keyGenericTable"
        :items="filteredCompetences"
        :fields="fields"
        :filter="input_search"
        :filterCustom="filterCustom"
        :show_pagination="true"
        :search_filter="true"
        :selection_mode="true"
        :columns_display="true"
        :display_id="display_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @emitChangeSearchField="changeInputSearch"
      >
        <template #head(selected)>
          <b-checkbox
            v-model="all_selected"
            @input="selectAll"
            v-b-tooltip.v-secondary="
              all_selected ? 'Deseleccionar Todos' : 'Seleccionar Todos'
            "
          ></b-checkbox>
        </template>
        <template #cell(selected)="row">
          <b-checkbox
            v-model="row.item.selected"
            @input="selectCompetence(row.item.id, $event)"
          ></b-checkbox>
        </template>
        <template #cell(cycle)="row">
          <template v-for="cycle in [competenceCycle(row.item.cycle)]">
            <span v-if="cycle" :key="cycle.id">{{ cycle.name }}</span>
            <span v-else :key="cycle">N/A</span>
          </template>
        </template>
        <template #cell(profile_competence)="row">
          <p>
            {{ profileCompetenceMention(row.item) }}
          </p>
        </template>
        <template #cell(full_sentence)="row">
          <SentenceContainer :Sentence="row.item"> </SentenceContainer>
        </template>
        <template #cell(type)="row">
          <template v-if="row.item.type && competenceType(row.item.type)">
            <template v-for="type in [competenceType(row.item.type)]">
              <span
                v-if="type"
                v-b-tooltip.v-secondary="type.description"
                :key="type.id"
                >{{ type.name }}</span
              >
            </template>
          </template>
        </template>
        <template #cell(egress_profile)="row">
          <div class="profiles-container">
            <template
              v-for="profiles in getEgressProfileCompetence(row.item.id)"
            >
              <div class="profiles-content" :key="profiles.id">
                {{ profiles.professional_title }}
                <p
                  v-if="
                    !(
                      (institution && institution.id == 12) ||
                      $debug_change_duoc
                    )
                  "
                >
                  <strong
                    >{{
                      $getVisibleNames("mesh.cycle", false, "Ciclo Formativo")
                    }}: </strong
                  >{{ profiles.cycle_name == "" ? "N/A" : profiles.cycle_name }}
                </p>
                <p v-if="profiles.mentions.length > 0">
                  <strong
                    >{{
                      $getVisibleNames(
                        "mesh.egressprofilecompetencemention",
                        profiles.mentions.length > 1,
                        "Mención"
                      )
                    }}: </strong
                  >{{
                    profiles.mentions.length > 1
                      ? "(" + profiles.mentions.join(", ") + ")"
                      : profiles.mentions[0]
                  }}
                </p>
              </div>
              <!-- <h5
              :key="`profile-header-${index}-${row.item.id}`"
              :id="`profile-header-${index}-${row.item.id}`"
            >
              <b-badge variant="secondary">{{ profiles.length }}</b-badge>
            </h5>
            <b-tooltip
              :target="`profile-header-${index}-${row.item.id}`"
              :key="`profile-tooltip-${index}-${row.item.id}`"
              triggers="hover"
              variant="secondary"
            >
              <span v-for="profile in profiles" :key="profile.id"
                >{{ profile.professional_title }} <br
              /></span>
            </b-tooltip> -->
            </template>
          </div>
        </template>
        <template #cell(ra_base)="row">
          <h5
            :key="`ra-base-header-${row.item.id}`"
            :id="`ra-base-header-${row.item.id}`"
            v-bind:class="{ 'pointer-class': allows_cruds && !selection_mode }"
            @click="
              () => {
                if (allows_cruds && !selection_mode)
                  $bvModal.show(`modal-base-ra-${row.item.id}`);
              }
            "
          >
            <b-badge class="badge" v-if="raBasesFiltered" variant="secondary">{{
              raBasesFiltered(row.item.id).length
            }}</b-badge>
          </h5>
          <b-modal
            :id="`modal-base-ra-${row.item.id}`"
            hide-footer
            :title="$getVisibleNames('teaching.basera', false, 'RA Base')"
            size="lg"
          >
            <RaBase :competence_id="row.item.id"></RaBase>
          </b-modal>
          <b-popover
            :target="`ra-base-header-${row.item.id}`"
            :key="`ra-base-tooltip-${row.item.id}`"
            triggers="hover"
          >
            <template #title>{{
              $getVisibleNames("teaching.basera", false, "RA Base")
            }}</template>
            <template v-if="raBasesFiltered(row.item.id).length > 0">
              <div class="ra-competences-container">
                <span v-for="ra in raBasesFiltered(row.item.id)" :key="ra.id">
                  <SentenceContainer :Sentence="ra"> </SentenceContainer>
                </span>
              </div>
            </template>
            <template v-else
              >No cuenta con ningún
              {{ $getVisibleNames("teaching.basera", false, "RA Base") }}
              creado
            </template>
          </b-popover>
        </template>
        <!-- <template #cell()="row">
          <div v-html="row.item.descriptor_3"></div>
        </template> -->
        <template v-if="allows_cruds" #cell(actions)="row">
          <button-edit
            v-can="'mesh.change_competence'"
            @click="$bvModal.show(`edit-competence-modal-${row.item.id}`)"
            v-b-tooltip.v-secondary.auto.noninteractive="{
              customClass: 'custom-tooltip',
            }"
            :title="`Editar ${$getVisibleNames(
              'mesh.competence',
              false,
              'Competencia'
            )}`"
          ></button-edit>
          <button-delete
            v-can="'mesh.delete_competence'"
            v-if="allows_cruds && user && user.is_superuser"
            @click="askForDeleteCompetence(row.item.id)"
            v-b-tooltip.v-secondary.auto.noninteractive="{
              customClass: 'custom-tooltip',
            }"
            :title="`Eliminar ${$getVisibleNames(
              'mesh.competence',
              false,
              'Competencia'
            )}`"
          ></button-delete>
          <b-modal
            :id="`edit-competence-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.competence',
              false,
              'Competencia'
            )}`"
            size="lg"
            @hide="resetCompetenceTable"
            hide-footer
          >
            <CompetenceForm
              :Competence="row.item"
              @updated="slotUpdatedCompetence"
              @close="slotCloseModal"
              :selected_ids="getEgressProfiles(row.item.id)"
              :show_title="false"
              @reset_competence="changeCompetenceDescriptor(row.item.id)"
              @change_middle_descriptors="
                () => {
                  changeCompetenceDescriptor(row.item.id);
                }
              "
              @reset_competence_table="countCompetenceTable"
            ></CompetenceForm>
          </b-modal>
        </template>
      </GenericBTable>
    </div>
    <div>
      <b-modal
        id="new-competence-modal"
        :title="`Crear ${$getVisibleNames(
          'mesh.competence',
          false,
          'Competencia'
        )}`"
        size="lg"
        @hide="resetCompetenceTable"
        hide-footer
      >
        <CompetenceForm
          @created="slotCreatedCompetence"
          @close="slotCloseModal"
          :show_title="false"
        ></CompetenceForm>
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "CompetenceTable",
  components: {
    CompetenceForm: () => import("./CompetenceForm"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    GenericBTable: () => import("@/components/reusable/GenericBTable.vue"),
    RaBase: () => import("@/components/reusable/RaBase"),
  },
  props: {
    matrix2_competences: {
      type: Array,
    },
    selection_mode: {
      type: Boolean,
      default: false,
    },
    selected_ids: {
      type: Array,
      default: function () {
        return [];
      },
    },
    egress_profile_id: {},
    allows_crud: {
      default: true,
    },
    display_id: {},
  },
  data() {
    return {
      all_selected: false,
      loading: 0,
      input_search: "",
      render_table: true,
      sortBy: "",
      keyGenericTable: 0,
      countChangeDescriptors: 0,
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      institution: "getInstitution",
      cycles: names.CYCLES,
      careers: names.CAREERS,
      faculties: names.FACULTIES,
      storedCompetences: names.COMPETENCES, //TODO: Renombrar.
      egressProfiles: names.EGRESS_PROFILES,
      ra_bases: names.RA_BASES,
      competenceTypes: names.COMPETENCE_TYPES,
      profileCompetences: names.PROFILE_COMPETENCES,
      profile_competences_mention: names.PROFILE_COMPETENCES_MENTION,
    }),
    allows_cruds() {
      if (!this.allows_crud) return false;
      if (
        (this.user_position &&
          [1].includes(this.user_position.position) &&
          [1].includes(this.user.groups[0])) ||
        this.user.is_superuser
      ) {
        const has_permission = this.$hasPermissions("mesh.add_competence");
        if (has_permission) {
          this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
          return has_permission;
        } else return false;
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
    isBusy() {
      if (this.loading != 0) return true;
      return false;
    },
    competenceDescriptor() {
      if (this.selection_mode) return [];
      const competence_list = this.storedCompetences.filter(
        (x) => x.descriptions.length > 0
      );
      let distinct = [];
      competence_list.forEach((competence) => {
        competence.descriptions.forEach((descriptor) => {
          if (
            distinct.filter(
              (x) => x.key == "descriptor_" + descriptor.descriptor__id
            ).length == 0
          )
            distinct.push({
              key: "descriptor_" + descriptor.descriptor__id,
              label: descriptor.descriptor__title,
              display_column: true,
              thStyle: { background: "var(--primary-color) !important" },
              tdClass: "text-left",
            });
        });
      });
      return distinct;
    },
    filteredCompetences() {
      let list = [];
      if (this.matrix2_competences) {
        this.matrix2_competences.forEach((matrix2_competence) => {
          const index = this.selected_ids.findIndex(
            (x) => x == matrix2_competence.id
          );
          matrix2_competence.selected = index != -1 ? true : false;
          list.push(matrix2_competence);
        });
      } else {
        this.storedCompetences.forEach((competence) => {
          const profile_competence = this.profileCompetences.find(
            (x) => x.competence == competence.id
          );
          // Estos atributos son solo para renderizar en la tabla.
          competence.descriptions.forEach((x) => {
            competence["descriptor_" + x.descriptor__id] = x.description;
          });
          competence.cycle = profile_competence
            ? profile_competence.cycle
            : null;
          competence.order = profile_competence
            ? profile_competence.order
            : null;

          list.push(competence);
        });
      }
      return list;
    },
    breadcrumb_items() {
      return [
        { text: "Productos Curriculares", active: true },
        {
          text: String(
            this.$getVisibleNames("mesh.competence", true, "Competencias")
          ),
          active: true,
        },
      ];
    },
    fields() {
      return [
        this.selection_mode
          ? {
              key: "selected",
              label: "Selecionar",
              display_column: true,
              thStyle: { width: "1%" },
            }
          : { key: "selected", label: "", display_column: false },
        {
          key: "full_sentence",
          label: "Redacción",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        this.filteredCompetences.filter((x) => x.type != null).length > 0
          ? {
              key: "type",
              label: this.$getVisibleNames(
                "mesh.competencetype",
                false,
                "Tipos de Competencias"
              ),
              display_column: true,
              thStyle: { background: "var(--primary-color) !important" },
              tdClass: "text-left",
            }
          : { key: "type", label: "", display_column: false },
        this.selection_mode &&
        this.filteredCompetences.filter((x) => x.cycle != null).length > 0
          ? {
              key: "cycle",
              label: this.$getVisibleNames(
                "mesh.cycle",
                false,
                "Ciclo Formativo"
              ),
              sortable: true,
              display_column: true,
              thStyle: { background: "var(--primary-color) !important" },
              tdClass: "text-left",
            }
          : { key: "cycle", label: "", display_column: false },
        this.selection_mode &&
        this.filteredCompetences.filter(
          (x) =>
            x.profile_competence != null &&
            this.profile_competences_mention.filter(
              (a) =>
                a.egress_profile_competences.includes(x.profile_competence) &&
                a.egress_profile == x.egress_profile
            ).length > 0
        ).length > 0
          ? {
              key: "profile_competence",
              label: this.$getVisibleNames(
                "mesh.egressprofilecompetencemention",
                false,
                "Mención"
              ),
              sortable: true,
              display_column: true,
              thStyle: { background: "var(--primary-color) !important" },
              tdClass: "text-left",
            }
          : { key: "profile_competence", label: "", display_column: false },
        !this.selection_mode
          ? {
              key: "egress_profile",
              label: String(
                this.$getVisibleNames("mesh.career", true, "Programas")
              ),
              display_column: true,
              thStyle: { background: "var(--primary-color) !important" },
              tdClass: "text-left",
            }
          : { key: "egress_profile", label: "", display_column: false },
        (this.institution && this.institution.show_base_ra) ||
        this.$debug_change_duoc
          ? {
              key: "ra_base",
              label: this.$getVisibleNames("teaching.basera", true, "RA Base"),
              display_column: true,
              tdClass: "text-center",
              thStyle: { background: "var(--primary-color) !important" },
            }
          : { key: "ra_base", label: "", display_column: false },
      ]
        .concat(this.competenceDescriptor)
        .concat([
          {
            key: "actions",
            label: "",
            display_column: true,
            thClass: "noprint",
            tdClass: "noprint",
          },
        ]);
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(this.getNameCycle(row.cycle), this.input_search) ||
        this.$filtered(this.getNameType(row.type), this.input_search) ||
        this.$filtered(row.sentence, this.input_search) ||
        this.profileCompetences.filter(
          (x) =>
            this.$filtered(
              this.getEgressProfile(x.egress_profile),
              this.input_search
            ) && x.competence == row.id
        ).length > 0 ||
        this.profile_competences_mention.filter(
          (x) =>
            this.$filtered(x.name, this.input_search) &&
            this.profileCompetences.filter(
              (c) =>
                c.competence == row.id &&
                x.egress_profile_competences.includes(c.id) &&
                c.egress_profile == x.egress_profile
            ).length > 0
        ).length > 0
      )
        return true;
      else return false;
    },
    raBasesFiltered(comptence_id) {
      if (!comptence_id) return [];
      return this.ra_bases.filter((x) => x.competences.includes(comptence_id));
    },
    changeCompetenceDescriptor(id) {
      this.$store.dispatch(names.PATCH_COMPETENCE, {
        competence_id: id,
      });
    },
    getNameCycle(id) {
      if (!id) return "";
      let cycle = this.cycles.find((x) => x.id == id);
      return cycle ? cycle.name : "";
    },
    getEgressProfile(id) {
      if (!id) return "";
      let egress_profile = this.egressProfiles.find((x) => x.id == id);
      return egress_profile ? egress_profile.professional_title : "";
    },
    getNameType(id) {
      if (!id) return "";
      let competence_type = this.competenceTypes.find((x) => x.id == id);
      return competence_type ? competence_type.name : "";
    },
    getMentions(profile_competence) {
      if (!profile_competence) return "";
      let mentions_name = this.profile_competences_mention
        .filter(
          (x) =>
            x.egress_profile == profile_competence.egress_profile &&
            x.egress_profile_competences.includes(profile_competence.id)
        )
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        })
        .map((x) => x.name);
      return mentions_name;
    },
    profileCompetenceMention(profile_competence) {
      if (
        profile_competence &&
        profile_competence.profile_competence &&
        profile_competence.egress_profile
      ) {
        const mentions = this.profile_competences_mention
          .filter(
            (x) =>
              x.egress_profile == profile_competence.egress_profile &&
              x.egress_profile_competences.includes(
                profile_competence.profile_competence
              )
          )
          .map((x) => x.name);
        if (mentions.length > 0)
          return mentions.length > 1 ? mentions.join(", ") : mentions[0];
        else return "Común";
      } else return null;
    },
    getEgressProfileCompetence(competence_id) {
      let list = [];
      const profile_competences = this.profileCompetences.filter(
        (x) => x.competence == competence_id
      );
      profile_competences.forEach((profile_competence) => {
        profile_competence.professional_title = this.getEgressProfile(
          profile_competence.egress_profile
        );
        profile_competence.cycle_name = this.getNameCycle(
          profile_competence.cycle
        );
        profile_competence.mentions = this.getMentions(profile_competence);
        list.push(profile_competence);
      });
      return list;
    },
    getEgressProfiles(competence_id) {
      let list = [];
      const profile_competences = this.profileCompetences.filter(
        (x) => x.competence == competence_id
      );
      profile_competences.forEach((profile_competence) => {
        const egress_profile = this.egressProfiles.find(
          (x) => x.id == profile_competence.egress_profile
        );
        const index = list.findIndex(
          (x) => x.id == profile_competence.egress_profile
        );
        if (egress_profile && index == -1) list.push(egress_profile);
      });
      return list;
    },
    resetCompetenceTable() {
      if (this.keyGenericTable != this.countChangeDescriptors) {
        this.$store.dispatch(names.FETCH_COMPETENCES).then(() => {
          this.keyGenericTable = this.countChangeDescriptors;
        });
      }
    },
    countCompetenceTable() {
      this.countChangeDescriptors += 1;
    },
    competenceType(type_id) {
      return this.competenceTypes.find((x) => x.id == type_id);
    },
    competenceCycle(cycle_id) {
      return this.cycles.find((x) => x.id == cycle_id);
    },
    selectCompetence(competence_id, event) {
      // this.render_table = false;
      if (this.matrix2_competences)
        this.matrix2_competences.find((x) => x.id == competence_id).selected =
          event;
      // else this.competences.find((x) => x.id == competence_id).selected = event;
      // this.render_table = true;
      this.$emit("competence_changed", competence_id, event);
    },
    selectAll(event) {
      if (this.matrix2_competences) {
        this.matrix2_competences.forEach((matrix2_competence) => {
          matrix2_competence.selected = event;
        });
      }
      // else {
      //   this.competences.forEach((competence) => {
      //     competence.selected = event;
      //   });
      // }
    },
    slotCreatedCompetence() {
      this.$bvModal.hide("new-competence-modal");
    },
    slotUpdatedCompetence(competence) {
      this.$bvModal.hide(`edit-competence-modal-${competence.id}`);
    },
    askForDeleteCompetence(competence_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.competence",
          false,
          "Competencia"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_COMPETENCE, competence_id)
            .then(() => {
              // const index = this.competences.findIndex(
              //   (x) => x.id == competence_id
              // );
              // if (index > -1) this.competences.splice(index, 1);
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.competence",
                    false,
                    "Competencia"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    slotCloseModal(competence_id) {
      this.$bvModal.hide(`edit-competence-modal-${competence_id}`);
      this.$bvModal.hide(`new-competence-modal`);
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
  },
  created() {
    this.loading = 5;
    this.$store.dispatch(names.FETCH_RA_BASES);
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
    this.$store.dispatch(names.FETCH_CYCLES);
    if (this.egress_profile_id != null)
      this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES_MENTION, {
        egress_profile_id: this.egress_profile_id,
      });
    else this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES_MENTION);
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES).then(() => {
      this.loading -= 1;
    });
    this.$store.dispatch(names.FETCH_CYCLES).then(() => {
      this.loading -= 1;
    });
    this.$store.dispatch(names.FETCH_COMPETENCE_TYPES).then(() => {
      this.loading -= 1;
    });
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES).then(() => {
      this.loading -= 1;
    });
    this.$store.dispatch(names.FETCH_COMPETENCES).then(() => {
      // competences.forEach((competence) => {
      //   const index = this.selected_ids.find((x) => x == competence.id);
      //   if (index) competence.selected = true;
      //   else competence.selected = false;
      // });
      // this.competences = competences;
      this.loading -= 1;
    });
  },
};
</script>

<style scoped>
.add-icon {
  margin-right: -2%;
  font-size: bold;
}
.profiles {
  background-color: var(--secondary-color);
  border-radius: 4px;
}
.custon-tooltip {
  margin-top: 10px;
  z-index: 1;
}
.custon-tooltip::after {
  margin-right: 0.2em;
  transition: 0.2s ease-out;
}
.table-container {
  margin-bottom: -3% !important;
}

.table-container >>> .b-card-style {
  background: var(--primary-color) !important;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.75em;
}
.pointer-class {
  cursor: pointer;
}
.profiles-container {
  display: flex !important;
  flex-direction: column;
}
.profiles-content {
  border-bottom: 0.1px solid var(--secondary-color);
  margin: 0 !important;
  margin-top: 0.4em !important;
  min-height: fit-content;
  min-width: fit-content;
}
.profiles-content:last-child {
  border-bottom: none;
}
.ra-competences-container {
  max-height: 500px;
  overflow-y: auto;
}
@media print {
  .badge {
    border: none;
  }
}
</style>