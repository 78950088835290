<template>
  <div v-can="'teaching.change_matter'" v-if="allows_crud">
    <!-- class="button-div" -->
    <button-edit
      @click="$bvModal.show(`modal-select-competence-${matter.id}`)"
      v-b-tooltip.v-secondary.noninteractive="
        `Editar ${$getVisibleNames('mesh.competence', true, 'Competencias')}`
      "
    ></button-edit>
    <template v-if="allows_crud">
      <CompetenceModal
        @ok="updateCompetences"
        :selected_ids="matter.competences"
        :modal_id="`modal-select-competence-${matter.id}`"
        :matrix2_competences="competencesList"
        :matrix2="true"
        :egress_profile_id="egressProfile ? egressProfile.id : null"
        :matter_id="matter.id"
      ></CompetenceModal>
    </template>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "MatterCompetencesModal",
  components: {
    CompetenceModal: () =>
      import("../CurricularResources/Competence/CompetenceModal"),
  },
  props: {
    matter: {
      required: true,
    },
    egressProfile: {
      required: true,
    },
    open_modal: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      profile_competences: names.PROFILE_COMPETENCES,
      competences: names.COMPETENCES,
      profile_cycles: names.PROFILE_CYCLES,
    }),
    egressProfileCycleId() {
      if (!this.egressProfile) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egressProfile.id) list.push(x.cycle);
      });
      return list;
    },
    competencesList() {
      if (!this.egressProfile) return [];
      if (!this.matter) return [];
      let list = [];
      let competences = [];
      let cycles = [];
      this.profile_cycles.forEach((x) => {
        if (this.matter.cycles.includes(x.id)) {
          cycles.push(x.cycle);
        }
      });
      if (this.egressProfileCycleId.length > 0 && cycles.length > 0) {
        competences = this.profile_competences.filter(
          (x) =>
            (x.egress_profile == this.egressProfile.id &&
              (cycles.includes(x.cycle) || x.cycle == null)) ||
            (x.egress_profile == this.egressProfile.id &&
              this.matter.competences.includes(x.competence))
        );
      } else {
        competences = this.profile_competences.filter(
          (x) =>
            (x.egress_profile == this.egressProfile.id && x.cycle == null) ||
            (x.egress_profile == this.egressProfile.id &&
              this.matter.competences.includes(x.competence))
        );
      }
      competences.forEach((competence) => {
        if (competence) {
          let get_competence = this.competences.find(
            (x) => x.id == competence.competence
          );
          if (get_competence) {
            list.push({
              id: competence.competence,
              order: competence.order,
              egress_profile: competence.egress_profile,
              profile_competence: competence.id,
              cycle: competence.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              full_sentence: get_competence.full_sentence,
              type: get_competence.type,
              verb: get_competence.verb,
              sentence: get_competence.sentence,
              name: get_competence.name,
              selected: get_competence.selected,
            });
          }
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.cycle > b.cycle) return 1;
        if (a.cycle < b.cycle) return -1;
        return 0;
      });
    },
  },
  methods: {
    updateCompetences(competence_ids) {
      //   this.$store
      //     .dispatch(names.UPDATE_EGRESS_PROFILE_MATTER, this.matter)
      //     .then((response) => {
      //       if (response) {
      let payload = {
        item: {
          competences: competence_ids,
        },
        egress_profile_matter_id: this.matter.id,
      };
      this.patchCompetences(payload);
      //   }
      // });
    },
    patchCompetences(payload) {
      this.$store
        .dispatch(names.PATCH_EGRESS_PROFILE_MATTER, payload)
        .then(() => {
          toast(
            String(
              this.$getVisibleNames("mesh.competence", true, "Competencias") +
                " actualizadas."
            )
          );
        });
    },
  },
  mounted() {
    if (this.open_modal)
      this.$bvModal.show(`modal-select-competence-${this.matter.id}`);
  },
};
</script>

<style>
</style>