import * as names from '@/store/names';

const state = {
    numbers_words: [
        'PRIMER',
        'SEGUNDO',
        'TERCER',
        'CUARTO',
        'QUINTO',
        'SEXTO',
        'SÉPTIMO',
        'OCTAVO',
        'NOVENO',
        'DÉCIMO',
        'DÉCIMO PRIMER',
        'DÉCIMO SEGUNDO',
        'DÉCIMO TERCER',
        'DÉCIMO CUARTO',
    ],
    allows_crud: JSON.parse(localStorage.getItem('allows_crud')) != undefined ? JSON.parse(localStorage.getItem('allows_crud')) : false,
    visibility_crud: false,
};

const getters = {
    [names.NUMBERS_TO_WORDS]: state => {
        return state.numbers_words;
    },
    [names.ALLOWS_CRUD]: state => {
        return state.allows_crud;
    },
    [names.VISIBILITY_CRUD]: state => {
        return state.visibility_crud;
    },
};

const mutations = {
    [names.MUTATE_ALLOWS_CRUD]: (state, allows_crud) => {
        state.allows_crud = allows_crud;
        localStorage.setItem("allows_crud", JSON.stringify(allows_crud));
    },
    [names.MUTATE_VISIBILITY_CRUD]: (state, visibility_crud) => {
        state.visibility_crud = visibility_crud;
    },
};

const actions = {

};

export default {
    state,
    getters,
    mutations,
    actions,
};