<template>
  <div>
    <b-form-group
      label="Nombre:"
      label-for="input-name"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-name"
        v-model="$v.learning_experience.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-feedback"
        >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción:"
      label-for="input-description"
      label-class="mt-0"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="evaluation-form-description rich-text-content mr-0 mt-1"
        v-html="$v.learning_experience.description.$model"
        @click="
          $bvModal.show(
            `edit-learning_experience-description-modal-${learning_experience.id}`
          )
        "
      ></div>
      <b-modal
        :id="`edit-learning_experience-description-modal-${learning_experience.id}`"
        title="Editar Descripción"
        size="lg"
        hide-footer
      >
        <RichTextEditor
          :Object="learning_experience"
          :Text="learning_experience.description"
          @update="patchDescription"
          @close="
            $bvModal.hide(
              `edit-learning_experience-description-modal-${learning_experience.id}`
            )
          "
        ></RichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="`${$getVisibleNames('teaching.ramicro', false, 'RA Micro')}:`"
      label-for="select-ra-micro"
      label-cols="0"
      label-cols-sm="3"
      label-class="ml-1"
      class="custom-mr"
    >
      <b-form-select
        v-if="studyUnitsList.length > 0"
        id="select-ra-micro"
        v-model="learning_experience.study_units"
        size="sm"
        multiple
        class="select-form mt-2"
        :select-size="studyUnitsList.length > 8 ? 8 : studyUnitsList.length"
      >
        <b-form-select-option
          class="select-option-micro"
          v-for="ecm in studyUnitsList"
          :value="ecm.id"
          :key="ecm.id"
        >
          N° {{ ecm.order }}: {{ ecm.full_sentence }}
        </b-form-select-option>
      </b-form-select>
      <template v-else>
        <div class="mt-1">N/A</div>
      </template>
    </b-form-group>
    <div style="text-align: right">
      <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import {
  required,
  minLength,
  //   numeric,
  //   maxValue,
} from "vuelidate/lib/validators";
import { generateUniqueId } from "@/utils/utils";
export default {
  name: "LearningExperienceForm",
  mixins: [validationMixin],
  components: {
    RichTextEditor: () => import("@/components/reusable/RichTextEditor"),
  },
  props: {
    // allows_crud: {
    //   type: Boolean,
    // },
    matter_id: {
      type: Number,
      required: true,
    },
    max_order: {
      type: Number,
      required: true,
    },
    LearningExperience: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          description: "",
          order: this.max_order,
          study_units: [],
          matter: this.matter_id,
        };
      },
    },
  },
  data() {
    return {
      learning_experience: { ...this.LearningExperience },
    };
  },
  validations: {
    learning_experience: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {},
      order: {},
      matter: {
        required,
      },
      study_units: {},
    },
  },
  computed: {
    ...mapGetters({
      studyUnits: names.STUDY_UNITS,
    }),
    studyUnitsList() {
      return this.studyUnits
        .filter((x) => x.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.learning_experience[name];
      return $dirty ? !$error : null;
    },
    patchDescription(object, text) {
      this.learning_experience.description = text;
    },
    save() {
      this.$v.learning_experience.$touch();
      if (this.$v.learning_experience.$anyError) {
        return;
      }
      if (isNaN(this.learning_experience.id)) this.create();
      else this.update();
    },
    create() {
      this.$emit("created", this.learning_experience);
    },
    update() {
      this.$emit("updated", this.learning_experience);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.evaluation-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.select-form {
  display: block !important;
}
.select-option-micro {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
}
</style>